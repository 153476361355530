"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const PostMedia_1 = require("../PostMedia");
const styles = require("./PostCardLayout.less");
const components_1 = require("modules/shared/components");
const PostCardLayout = ({ post, footer, header, back, showBack, width, height, validLite }) => (React.createElement("div", { className: styles.card },
    header,
    React.createElement(components_1.FadeComponent, { show: showBack }, back),
    React.createElement(components_1.FadeComponent, { show: !showBack },
        React.createElement("div", null,
            React.createElement(PostMedia_1.default, { post: post, width: width, height: height, validLite: validLite }),
            footer))));
exports.default = PostCardLayout;
