const TEN_YEARS_IN_MS = 315569260000;

const setCookie = ({name, value, ttl = TEN_YEARS_IN_MS}) => {
  if (typeof document === 'undefined') return;
  const today = new Date();
  today.setTime(today.getTime() + ttl);
  const expires = today.toUTCString();
  document.cookie = `${name}=${value};expires=${expires};path=/`;
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const getCookies = (names) => {
  return names.reduce((result, key) => {
    result[key] = getCookie(key);
    return result;
  }, {});
};

const deleteCookie = (name) => {
  if (typeof document === 'undefined') return;
  const expirationDate = new Date(0);
  const expires = expirationDate.toUTCString();
  document.cookie = `${name}=;expires=${expires};`;
};

const deleteCookies = (names) => names.map(deleteCookie);

module.exports = {
  setCookie,
  getCookie,
  getCookies,
  deleteCookie,
  deleteCookies
};
