"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const crispActions_1 = require("utils/crispActions");
const DeleteOptions = ({ role, userName, faceIcon, onBlurCancel, onDeleteUser, onHoverCancel, accountBalance, onCancelDelete, onPasswordChange }) => (React.createElement("div", { className: 'vf-row' },
    React.createElement("div", { className: 'col-xs-10' },
        React.createElement("div", { className: 'vf-font-big' },
            React.createElement("h4", null, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_TITLE')),
            i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_INTRO_MESSAGE'),
            ' ',
            React.createElement("a", { onClick: crispActions_1.openChat }, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_START_NEW_CONVERSATION_LINK_TEXT'))),
        React.createElement("h4", { className: 'padding-top-2x' }, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_DELETE_WARNING_TITLE')),
        role === 'advertiser' ? (React.createElement("ul", null,
            React.createElement("li", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_DELETE_ADVERTISER_WARNING_POINT_TRANSACTIONS')),
            React.createElement("li", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_DELETE_ADVERTISER_WARNING_POINT_CREDIT')),
            React.createElement("li", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_DELETE_ADVERTISER_WARNING_POINT_INFLUENCERS')),
            React.createElement("li", null,
                i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_DELETE_ADVERTISER_WARNING_POINT_MISSYOU', { userName }),
                "."))) : (React.createElement("ul", null,
            React.createElement("li", null,
                i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_DELETE_WARNING_POINT_PAYMENTS_PART_1'),
                ' ',
                "(",
                accountBalance,
                ")",
                ' ',
                i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_DELETE_WARNING_POINT_PAYMENTS_PART_2')),
            React.createElement("li", null, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_DELETE_WARNING_POINT_PERFORMANCE')),
            React.createElement("li", null, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_DELETE_WARNING_POINT_HISTORY')),
            React.createElement("li", null, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_DELETE_WARNING_POINT_SOCIAL_NETWORKS')),
            React.createElement("li", null,
                i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_DELETE_WARNING_POINT_MISSYOU'),
                ' ',
                userName,
                "."))),
        React.createElement("div", { className: 'margin-vertical-2x' },
            React.createElement("strong", null, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_TYPE_YOUR_PASSWORD'))),
        React.createElement("div", { className: 'padding-vertical-1x' },
            React.createElement(ui_library_1.Input, { type: 'password', onChange: onPasswordChange, placeholder: i18n.get('INF_SETTINGS_ACCOUNT_DELETE_PASSWORD_LABEL') })),
        React.createElement("div", null,
            React.createElement(ui_library_1.Button, { size: 'sm', color: 'default', onClick: onCancelDelete, onMouseLeave: onBlurCancel, onMouseEnter: onHoverCancel }, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_CANCEL_BUTTON_LABEL')),
            ' ',
            React.createElement(ui_library_1.Button, { size: 'sm', color: 'primary', onClick: onDeleteUser }, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_DELETE_BUTTON_LABEL')))),
    React.createElement("div", { className: 'col-xs-2' }, faceIcon)));
exports.default = DeleteOptions;
