import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import ReactDOM from 'react-dom';
import { Input } from 'ui-library';
import moment from 'moment';

const HourPicker = createClass({
  propTypes: {
    date: PropTypes.object,
    onChange: PropTypes.func
  },

  onChangeHours(evt) {
    const result = parseInt(evt.target.value, 10) || 0;

    if (result >= 24) {
      this.onClickMinutes();
      return;
    }
    if (result < 0) return;

    const newDate = moment(this.props.date).hour(result);
    if (this.props.onChange) this.props.onChange(newDate.toDate());

    if (result >= 12) {
      this.onClickMinutes();
    }
  },

  onChangeMinutes(evt) {
    const result = parseInt(evt.target.value, 10) || 0;

    if (result >= 60 || result < 0) return;

    const newDate = moment(this.props.date).minute(result);
    if (this.props.onChange) this.props.onChange(newDate.toDate());
  },

  onClickHours() {
    ReactDOM.findDOMNode(this.refs.hourInput).select();
  },

  onClickMinutes() {
    ReactDOM.findDOMNode(this.refs.minuteInput).select();
  },

  render() {
    const date = moment(this.props.date);

    const hours = date.format('H');
    const minutes = date.format('mm');

    return (
      <div className='vf-hour-picker'>
        <Input
          ref='hourInput'
          value={hours}
          inputSize='sm'
          className='vf-hour-picker-input'
          onChange={this.onChangeHours}
          onClick={this.onClickHours}
        />
        {' : '}
        <Input
          ref='minuteInput'
          value={minutes}
          inputSize='sm'
          className='vf-hour-picker-input'
          onChange={this.onChangeMinutes}
          onClick={this.onClickMinutes}
        />
      </div>
    );
  }
});

export default HourPicker;
