"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const lodash_1 = require("lodash");
const i18n = require("i18n");
const shared_1 = require("redux/modules/shared");
const users_1 = require("redux/modules/users");
const app_1 = require("redux/modules/app");
const DeleteOptions_1 = require("modules/accounts/shared/components/DeleteOptions");
const BrandSelector_1 = require("modules/accounts/advertiser/components/BrandSelector");
const ADVERTISER_ROLE = 'advertiser';
const DELETE_USER_REQUEST = 'deleteUser';
const DeleteAdvertiserUser = ({ user, accounts, requests, onCancelDelete, logout: logoutAction, showAlert: showAlertAction, deleteUser: deleteUserAction, resetRequest: resetRequestAction }) => {
    const [password, setPassword] = React.useState('');
    const [hoverCancel, sethoverCancel] = React.useState(false);
    const handleResponse = () => {
        const requestStatus = lodash_1.get(requests[DELETE_USER_REQUEST], 'status');
        const requestError = lodash_1.get(requests[DELETE_USER_REQUEST], 'response');
        if (requestStatus === 'success') {
            showAlertAction({
                type: 'success',
                message: i18n.get('INF_SETTINGS_ACCOUNT_DELETE_DELETE_SUCCESS_MESSAGE')
            });
            resetRequestAction(DELETE_USER_REQUEST);
            logoutAction();
        }
        if (requestStatus === 'fail') {
            showAlertAction({ type: 'error', message: requestError.message });
            resetRequestAction(DELETE_USER_REQUEST);
        }
    };
    const onHoverCancel = () => {
        sethoverCancel(true);
    };
    const onBlurCancel = () => {
        sethoverCancel(false);
    };
    const onDeleteUser = () => {
        const { _id: userId } = user;
        deleteUserAction(userId, password);
    };
    const onPasswordChange = evt => {
        const passwordUser = evt.target.value;
        setPassword(passwordUser);
    };
    const isBrandsOwner = () => {
        const { _id: userId } = user;
        const ownedBrands = lodash_1.filter(accounts, o => o.ownerId === userId);
        return !lodash_1.isEmpty(ownedBrands);
    };
    React.useEffect(() => {
        handleResponse();
    }, [requests]);
    const faceIcon = hoverCancel ? (React.createElement("div", { className: 'vf-icon vf-face icon-smile-o' })) : (React.createElement("div", { className: 'vf-icon vf-face icon-frown-o' }));
    return (React.createElement("div", null,
        React.createElement("div", { className: 'vf-row padding-top-2x' },
            React.createElement("div", { className: 'col-xs-12' },
                React.createElement("h2", null, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_HEADER'))),
            React.createElement("div", { className: 'col-xs-12' }, isBrandsOwner() && (React.createElement(BrandSelector_1.default, { user: user, accounts: accounts, onCancelDelete: onCancelDelete })))),
        React.createElement("div", { className: 'vf-row padding-top-2x' },
            React.createElement("div", { className: 'col-xs-12 show-xs padding-top-2x' }),
            React.createElement("div", { className: 'col-xs-12 col-sm-9 col-lg-offset-3 col-lg-6' }, !isBrandsOwner() && (React.createElement(DeleteOptions_1.default, { faceIcon: faceIcon, userName: user.name, role: ADVERTISER_ROLE, onDeleteUser: onDeleteUser, onBlurCancel: onBlurCancel, onHoverCancel: onHoverCancel, onCancelDelete: onCancelDelete, onPasswordChange: onPasswordChange }))))));
};
function mapStateToProps({ shared, entities, app }) {
    const { user } = shared;
    const { requests } = app;
    const { Account: accounts } = entities;
    return { accounts, user, requests };
}
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({ deleteUser: users_1.deleteUser, showAlert: app_1.showAlert, logout: shared_1.logout, resetRequest: app_1.resetRequest }, dispatch);
}
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(DeleteAdvertiserUser);
