import { combineReducers } from 'redux';

import app from '../modules/app';
import entities from '../modules/entities';
import shared from '../modules/shared';
import reports from '../modules/reports';
import socialAccounts from '../modules/socialAccounts';
import users from '../modules/users';
import stats from '../modules/stats';
import campaigns from '../modules/campaigns';
import chats from '../modules/chats';
import publications from '../modules/publications';

const reducers = combineReducers({
  app,
  entities,
  shared,
  reports,
  socialAccounts,
  users,
  stats,
  campaigns,
  chats,
  publications
});

export default reducers;
