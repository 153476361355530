import React from 'react';

const BadgeLogo = () => (
  <svg
    version='1.1'
    id='Capa_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 32 32'
    xmlSpace='preserve'
  >
    <g>
      <path
        id='Shape_18_'
        d='M15.1,1.6c0.6-0.3,1.3-0.3,1.9,0c3.6,1.8,8.1,2.7,10.6,3c1,0.1,1.7,0.9,1.8,1.9
        c0.3,8.2-0.9,13.5-1.6,15.3l0,0l0,0c-2.9,6.1-8.6,8.5-11.4,8.9c-0.2,0-0.5,0-0.7,0c-3.5-0.8-6.1-2.2-8-4.1
        c-1.9-1.9-3.1-4.3-3.8-6.8C2.7,15.9,2.6,9.8,2.7,6.5c0-1.1,0.9-1.8,1.9-1.9C8.8,4.4,13,2.7,15.1,1.6z M16,3.3
        C16,3.3,16,3.3,16,3.3c-2.2,1.2-6.7,3-11.3,3.3c0,0,0,0,0,0c-0.1,3.3,0,9.1,1,12.5c0.7,2.3,1.7,4.4,3.3,6c1.6,1.6,3.8,2.9,7.1,3.6
        c2.3-0.4,7.4-2.4,9.9-7.7c0.6-1.4,1.7-6.4,1.4-14.4c0,0,0,0,0,0c0,0,0,0,0,0C24.7,6.2,20,5.3,16,3.3z'
      />
      <path
        id='Shape_17_'
        d='M19.8,14.8L19.8,14.8c0.3-0.3,0.3-0.9,0-1.2c-0.3-0.3-0.8-0.3-1.1,0v0L14.4,18l-1.1-1.1
        c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.9,0,1.2l1.7,1.7c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l0,0L19.8,14.8z'
      />
      <path
        id='badgeFill'
        d='M16,8c-4.4,0-8,3.6-8,8s3.6,8,8,8c4.4,0,8-3.6,8-8S20.4,8,16,8z M16,9.7c3.5,0,6.3,2.8,6.3,6.3
        c0,3.5-2.8,6.3-6.3,6.3S9.7,19.5,9.7,16C9.7,12.5,12.5,9.7,16,9.7z'
      />
    </g>
  </svg>
);

export default BadgeLogo;
