import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

const Button = createClass({
  propTypes: {
    label: PropTypes.string,
    className: PropTypes.string
  },

  getDefaultProps: () => ({
    className: 'default-class'
  }),

  render() {
    return <div className={this.props.className}>{this.props.label}</div>;
  }
});

export default Button;
