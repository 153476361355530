import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import i18n from 'i18n';

import * as chatsActions from 'redux/modules/chats';

import SettingsDropdown from 'modules/legacy/js/components/settings-dropdown';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      archive: chatsActions.archiveChat,
      unarchive: chatsActions.unarchiveChat,
      block: chatsActions.blockChat,
      unblock: chatsActions.unblockChat
    },
    dispatch
  );
}

class ChatOptions extends Component {
  static propTypes = {
    chat: PropTypes.object.isRequired,
    archive: PropTypes.func.isRequired,
    unarchive: PropTypes.func.isRequired,
    block: PropTypes.func.isRequired,
    unblock: PropTypes.func.isRequired
  };

  archive = () => {
    this.props.archive(this.props.chat.id);
  };

  unarchive = () => {
    this.props.unarchive(this.props.chat.id);
  };

  block = () => {
    this.props.block(this.props.chat.id);
  };

  unblock = () => {
    this.props.unblock(this.props.chat.id);
  };

  buildDropdownOptions() {
    const status = get(this.props, 'chat.member.chatStatus');

    if (status === 'active') {
      return [
        { value: i18n.get('CHATS_ARCHIVE_OPTION'), callback: this.archive },
        { value: i18n.get('CHATS_BLOCK_OPTION'), callback: this.block }
      ];
    }

    if (status === 'archived') {
      return [
        {
          value: i18n.get('CHATS_UNARCHIVE_OPTION'),
          callback: this.unarchive
        }
      ];
    }

    if (status === 'blocked') {
      return [
        {
          value: i18n.get('CHATS_UNBLOCK_OPTION'),
          callback: this.unblock
        }
      ];
    }
  }

  render() {
    const status = get(this.props, 'chat.member.chatStatus');

    if (status === 'pending') return <noscript />;
    return <SettingsDropdown options={this.buildDropdownOptions()} />;
  }
}

export default connect(null, mapDispatchToProps)(ChatOptions);
