import axios from 'axios';

export function getInterestsList() {
  return axios.get('/interests')
    .then(res => res.data);
}

export function getSocialAccountConfiguration() {
  return axios.get('/config/influencers')
    .then(res => res.data);
}

export function setSocialAccountSubtype({socialAccountId, accountSubtype}) {
  return axios.put(`/social_network_accounts/${socialAccountId}`, {accountSubtype})
    .then(res => res.data);
}

export function setSocialAccountInterests({socialAccountId, interests}) {
  return axios.post(`/social-network-accounts/${socialAccountId}/interests`, {interests})
    .then(res => res.data);
}

export function setSocialAccountTags({socialAccountId, tags}) {
  return axios.post(`/social-network-accounts/${socialAccountId}/tags`, {tags})
    .then(res => res.data);
}

export function setSocialAccountSensitiveContent({socialAccountId, sensitiveContentDetail}) {
  return axios.put(`/social_network_accounts/${socialAccountId}`, {sensitiveContentDetail})
    .then(res => res.data);
}

export function setSocialAccountPostPrice({socialAccountId, desiredPostPrice}) {
  return axios.put(`/social_network_accounts/${socialAccountId}`, {desiredPostPrice})
    .then(res => res.data);
}
