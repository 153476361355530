import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { Button } from 'ui-library';
import i18n from 'i18n';
import CustomDialog from 'apps/shared/components/CustomDialog/CustomDialog';

export default createClass({
  propTypes: {
    onAccept: PropTypes.func,
    onReject: PropTypes.func,
    children: PropTypes.any,
    title: PropTypes.string,
    size: PropTypes.string,
    acceptColor: PropTypes.string,
    acceptText: PropTypes.string,
    disableAcceptButton: PropTypes.bool,
    disableCancelButton: PropTypes.bool
  },

  getDefaultProps() {
    return {
      acceptColor: 'primary',
      size: 'md',
      disableAcceptButton: false,
      disableCancelButton: false
    };
  },

  render() {
    const {
      children,
      onReject,
      onAccept,
      size,
      acceptColor,
      disableAcceptButton,
      disableCancelButton,
      acceptText = i18n.get('ACCEPT'),
      title = i18n.get('DIALOG_CONFIRMATION_TITLE')
    } = this.props;

    return (
      <CustomDialog
        size={size}
        header={<span>{title}</span>}
        content={
          <div className='vf-wrapper flex flex-justify-center flex-wrap width100'>
            <div className='flex flex-justify-center width100'>{children}</div>
          </div>
        }
        footer={
          <div className='margin-vertical-2x'>
            <div className='flex flex-justify-center padding-horizontal-2x'>
              <div className='padding-right-1x'>
                <Button onClick={onReject} disabled={disableCancelButton}>
                  {i18n.get('CANCEL')}
                </Button>
              </div>

              <div className='padding-left-1x'>
                <Button
                  data-uitest='accept-button'
                  color={acceptColor}
                  onClick={onAccept}
                  disabled={disableAcceptButton}
                >
                  {acceptText || i18n.get('ACCEPT')}
                </Button>
              </div>
            </div>
          </div>
        }
        onClose={onReject}
      />
    );
  }
});
