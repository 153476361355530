"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const styles = require("./SinglePlaceSelect.less");
const components_1 = require("apps/shared/components");
class SinglePlaceSelect extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isOpen: false
        };
        this.handleToggle = isOpen => {
            const { disabled } = this.props;
            if (!disabled)
                this.setState({ isOpen });
        };
        this.handleInputChange = event => {
            const { onSearchChange } = this.props;
            onSearchChange(event.target.value);
        };
        this.handleInputFocus = (event) => {
            event.stopPropagation();
            this.setState({ isOpen: true });
        };
        this.handleItemClick = (event) => {
            const { onPlaceClick } = this.props;
            const id = event.currentTarget.getAttribute('data-place-id');
            this.setState({ isOpen: false }, () => onPlaceClick(id));
        };
    }
    render() {
        const { isOpen } = this.state;
        const { disabled, hasError, searchValue, selectedPlace, places, placeholder, onClear } = this.props;
        const placeList = places.map(place => (React.createElement(PlaceItem, { key: place.id, place: place, onClick: this.handleItemClick })));
        return (React.createElement(components_1.SelectWrapper, { className: classnames_1.default({
                [styles.wrapper]: !hasError,
                [styles.error]: hasError
            }), isOpen: isOpen, onToggle: this.handleToggle, selectedValue: selectedPlace ? (React.createElement(SelectedPlace, { place: selectedPlace, onClear: onClear })) : (React.createElement(Input, { disabled: disabled, value: searchValue, placeholder: placeholder, onChange: this.handleInputChange, onClick: this.handleInputFocus, onFocus: this.handleInputFocus })) },
            placeList,
            places.length === 0 && searchValue !== '' && (React.createElement("div", { className: styles.item }, placeholder ===
                i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_COUNTRY_PLACEHOLDER')
                ? i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_COUNTRY_EMPTY_STATE')
                : i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_PLACE_EMPTY_STATE')))));
    }
}
exports.default = SinglePlaceSelect;
const Input = ({ disabled, value, placeholder, onChange, onClick, onFocus }) => (React.createElement("div", { className: 'flex width100' },
    React.createElement(components_1.DebounceInput, { disabled: disabled, debounceTime: 350, placeholder: placeholder, autoComplete: 'new-password', value: value, className: styles.input, onChange: onChange, onClick: onClick, onFocus: onFocus })));
const PlaceItem = ({ place, onClick }) => (React.createElement("div", { "data-place-id": place.id, className: styles.item, onClick: onClick }, place.name));
const SelectedPlace = ({ place, onClear }) => (React.createElement("div", { className: 'flex flex-justify-space-between width100', onClick: onClear },
    place.name,
    React.createElement("span", { className: styles.icon },
        React.createElement("i", { className: 'vf-icon icon-close icon-fix' }))));
