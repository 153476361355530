"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const components_1 = require("apps/shared/components");
class BrandAvatar extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false
        };
        this.onError = () => {
            this.setState({ hasError: true });
        };
    }
    render() {
        const { brandName, imageURL, width, height, className, style } = this.props;
        const { hasError } = this.state;
        return (React.createElement("div", { className: className, style: Object.assign({ width,
                height, borderRadius: '2px', overflow: 'hidden' }, style) },
            imageURL && (React.createElement(components_1.Img, { src: imageURL, width: width, height: height, className: 'image-cover image-fit-parent' })),
            (!imageURL || hasError) && (React.createElement("div", { className: 'width100 height100 flex center-xs vf-text-white vf-bg-gradient', style: { borderRadius: '2px', fontSize: width / 1.5 } }, brandName.toUpperCase().charAt(0)))));
    }
}
BrandAvatar.defaultProps = {
    brandName: 'X',
    width: 48,
    height: 48
};
exports.default = BrandAvatar;
