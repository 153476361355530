import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import i18n from 'i18n';

export const UsersSection = ({ onNext, onBack }) => (
  <div className='col center-xs margin-right-2x'>
    <h4 className='vf-font-thin'>
      {i18n.get('ADV_TOURS_BRAND_BACK_USER_SECTION_DESCRIPTION')}
    </h4>

    <div className='flex center-xs margin-vertical-1x'>
      <Button size='sm' className='margin-right-Hx' onClick={onBack}>
        {i18n.get('ADV_TOURS_BRAND_BACK_BUTTON')}
      </Button>

      <Button size='sm' color='success' onClick={onNext}>
        {i18n.get('ADV_TOURS_BRAND_NEXT_BUTTON')}
      </Button>
    </div>
  </div>
);

UsersSection.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func
};
