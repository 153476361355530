import React from 'react';
import createClass from 'create-react-class';
import * as qs from 'query-string';
import {
  getRootUrl,
  getShopifyClientId,
  getShopifyCallbackPage
} from 'config/app';

const ShopifyOAuth = createClass({
  componentDidMount() {
    const { postShopifyToken, location, currentUser } = this.props;
    const { code, shop } = qs.parse(location.search);
    const shopRegexp = /[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com/;
    if (!shop || !shopRegexp.test(shop)) {
      window.location = '/';
    } else if (!code) {
      const host = getRootUrl();
      const clientID = getShopifyClientId();
      window.location.replace(
        `https://${shop}/admin/oauth/authorize?client_id=${clientID}&scope=read_script_tags,write_script_tags&redirect_uri=${host}/shopifyoauth&state=123456&access_mode=offline_mode`
      );
    } else {
      if (!currentUser.id) {
        window.location = `/login?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`;
        return;
      }
      const params = {
        id: currentUser.id,
        code,
        url: shop
      };
      postShopifyToken(params)
        .then(() => {
          window.location = '/';
        })
        .catch(() => {
          window.location = '/';
        });
    }
  },
  render() {
    return <div />;
  }
});

export default ShopifyOAuth;
