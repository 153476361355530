"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const isNil = require("lodash/isNil");
const TagInputUI_1 = require("./TagInputUI/TagInputUI");
class TagInput extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            isValid: true
        };
        this.onAdd = (tag) => {
            const { tags, onChange } = this.props;
            onChange([...tags, tag]);
        };
        this.onRemove = (index) => {
            const { tags, onChange } = this.props;
            const newTags = [...tags];
            newTags.splice(index, 1);
            return onChange(newTags);
        };
        this.onValidate = (tag) => {
            const { validateInput } = this.props;
            if (!validateInput)
                return this.setState({ isValid: true });
            return this.setState({ isValid: validateInput(tag) });
        };
        this.canAdd = () => {
            const { maxTags, tags } = this.props;
            if (isNil(maxTags))
                return true;
            return tags.length < maxTags;
        };
    }
    render() {
        const { className, hideInputOnLimitReached, maxTags, placeholder, style, tags } = this.props;
        return (React.createElement(TagInputUI_1.default, { canAdd: this.canAdd(), className: className, hideInput: hideInputOnLimitReached && maxTags === tags.length, isValid: this.state.isValid, placeholder: placeholder, style: style, tags: tags, onAdd: this.onAdd, onRemove: this.onRemove, onValidate: this.onValidate }));
    }
}
TagInput.defaultProps = {
    tags: [],
    hideInputOnLimitReached: false
};
exports.default = TagInput;
