"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const ui_library_1 = require("ui-library");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const i18n = require("i18n");
const appActions = require("redux/modules/app");
const components_1 = require("modules/legacy/js/lib/components");
const SelectableChip_1 = require("modules/shared/components/SelectableChip/SelectableChip");
const BrandOption_1 = require("modules/accounts/advertiser/components/BrandOption/BrandOption");
const OwnershipTransferDialog_1 = require("modules/brands/advertiser/containers/OwnershipTransferDialog");
const BrandDeleteConfirmation_1 = require("modules/brands/advertiser/containers/BrandDeleteConfirmation");
const BrandSelector = ({ user, accounts, showModal, onCancelDelete }) => {
    const showOwnershipTransferDialog = brand => {
        showModal(React.createElement(OwnershipTransferDialog_1.default, { brandId: brand.id }));
    };
    const showBrandDeleteConfirmation = brand => {
        showModal(React.createElement(BrandDeleteConfirmation_1.default, { brandId: brand.id }));
    };
    const { _id: userId } = user;
    const ownedBrands = lodash_1.filter(accounts, o => o.ownerId === userId);
    const userBrands = ownedBrands.map(brand => {
        const options = [
            {
                value: i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_USER_OWNEDBRAND_DELETE_OPTION'),
                callback: () => showBrandDeleteConfirmation(brand)
            },
            {
                value: i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_USER_OWNEDBRAND_TRANSFER_OPTION'),
                callback: () => showOwnershipTransferDialog(brand)
            }
        ];
        return (React.createElement("div", { className: 'col-xs-3', key: brand.id },
            React.createElement("div", { style: {
                    top: '1rem',
                    right: '1rem',
                    position: 'absolute'
                } },
                React.createElement(components_1.SettingsDropdown, { options: options })),
            React.createElement(SelectableChip_1.default, { className: 'margin-vertical-1x' },
                React.createElement(BrandOption_1.default, { brand: brand }))));
    });
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("div", { className: 'margin-vertical-1x' },
                React.createElement("span", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_DELETE_ADVERTISER_STEP1_LABEL'))),
            React.createElement("div", { className: 'margin-vertical-1x' },
                React.createElement("span", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_DELETE_ADVERTISER_STEP2_LABEL')))),
        React.createElement("div", { className: 'col' },
            React.createElement("div", { className: 'col-md-8 vf-row margin-vertical-1x' }, userBrands),
            React.createElement("div", { className: 'col-md-4' })),
        React.createElement("div", { className: 'vf-row padding-left-2x' },
            React.createElement("div", { className: 'col-md-8 vf-row margin-vertical-1x' },
                React.createElement(ui_library_1.Button, { size: 'sm', color: 'default', onClick: onCancelDelete }, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_CANCEL_BUTTON_LABEL'))),
            React.createElement("div", { className: 'col-md-4 flex' }))));
};
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({ showModal: appActions.showModal }, dispatch);
exports.default = react_redux_1.connect(null, mapDispatchToProps)(BrandSelector);
