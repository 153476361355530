"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const isBoolean = require("lodash/isBoolean");
const styles = require("./Accordion.less");
class Accordion extends React.PureComponent {
    constructor(props) {
        super(props);
        this.toggleCollapse = () => {
            this.setState({ isCollapsed: !this.state.isCollapsed });
        };
        this.state = {
            isCollapsed: !!props.isCollapsed
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isCollapsed } = nextProps;
        if (isBoolean(isCollapsed) && !isCollapsed) {
            this.setState({ isCollapsed: false });
        }
    }
    render() {
        const { title, children, style } = this.props;
        const { isCollapsed } = this.state;
        return (React.createElement("div", { className: 'col width100', style: style },
            React.createElement("div", { className: classnames_1.default('flex between-xs vf-clickable width100 padding-1x', styles.header, {
                    [styles.expanded]: !isCollapsed
                }), onClick: this.toggleCollapse },
                React.createElement("b", { className: classnames_1.default('ellipsis', styles.title) }, title),
                React.createElement("span", { className: styles.icon, style: { transform: `rotate(${isCollapsed ? '0' : '180deg'})` } },
                    React.createElement("i", { className: 'vf-icon icon-chevron-down' }))),
            React.createElement("div", { className: classnames_1.default('vf-scrolly', styles.content), style: {
                    display: isCollapsed ? 'hidden' : 'block',
                    maxHeight: isCollapsed ? 0 : '100%'
                } }, children)));
    }
}
Accordion.defaultProps = {
    style: {}
};
exports.default = Accordion;
