import axios from 'axios';
import qs from 'qs';

export * from './activityStats';
export * from './invites';
export * from './participationRequests';

export function create({ brandId, ...data }) {
  return axios.post(`/brands/${brandId}/campaigns`, data);
}

export function update({ id, ...data }) {
  return axios.put(`/campaigns/${id}`, data);
}

export function archive({ campaignId }) {
  return axios.post(`/campaigns/${campaignId}/archive`);
}

export function unarchive({ campaignId }) {
  return axios.post(`/campaigns/${campaignId}/unarchive`);
}

export function remove({ campaignId }) {
  return axios.delete(`/campaigns/${campaignId}`);
}

export function calculateExpectedReach({ campaign, countries }) {
  return axios.post('/campaigns/calculate-segmentation', {
    ...campaign,
    countries
  });
}

export function likeProposal(proposalId) {
  return axios.post(`/promoted-messages/${proposalId}/like`);
}

export function dislikeProposal(proposalId) {
  return axios.post(`/promoted-messages/${proposalId}/dislike`);
}

export function removeProposalLike(proposalId) {
  return axios.delete(`/promoted-messages/${proposalId}/like`);
}

export function removeProposalDislike(proposalId) {
  return axios.delete(`/promoted-messages/${proposalId}/dislike`);
}

export function sendParticipationFeedback({ campaignId, ...other }) {
  return axios.post(`/campaigns/${campaignId}/feedbacks`, other);
}

export function fetchFeedbacks(id) {
  return axios.get(`campaigns/${id}/feedbacks`);
}

export function replyToFeedback({ feedbackId, comments }) {
  return axios.post(`/campaigns/feedbacks/${feedbackId}/reply`, { comments });
}

export function calculateDifficulty(data) {
  return axios.post('/campaigns/calculate-difficulty', data);
}

export function fetchConfig({ campaignDifficulty }) {
  return axios.get('/config/campaigns', { params: { campaignDifficulty } });
}

export function sendInvites(data) {
  const { brandId, campaignId, invites } = data;
  return axios.post(`/campaigns/${campaignId}/invites`, {
    brandId,
    invites
  });
}

export function fetchCampaignsByIdsAsInfluencer(ids = []) {
  return axios.get('/influencers/campaigns', { params: { ids } });
}

export function fetchInfluencerAvailableCampaigns() {
  return axios.get('/campaigns/available');
}

export function calculateParticipationTakeRate(params) {
  return axios.post('/config/calculate-take-rate', params);
}

export function sendParticipationRequest({ campaignId, ...data }) {
  return axios.post(`/campaigns/${campaignId}/participation-requests`, data);
}

export function fetchInfluencerPendingParticipationRequests(influencerId) {
  return axios.get(
    `/influencers/${influencerId}/participation-requests?status=created`
  );
}

export function fetchParticipationRequestsConstraints(ids = []) {
  return axios.get(`/participation-requests/constraints?ids=${ids.join(',')}`);
}

export function updateParticipationRequestPostPrice({ id, postPrice }) {
  return axios.put(`/participation-requests/${id}`, { postPrice });
}

export function cancelParticipationRequestPostPrice({ id }) {
  return axios.post(`/participation-requests/${id}/cancel`);
}

export function fetchParticipationRequest({ id }) {
  return axios.get(`/participation-requests/${id}`);
}

export function fetchCampaignAvailabilityForInfluencer({
  influencerId,
  campaignId
}) {
  return axios.get(
    `/influencer/${influencerId}/campaigns/${campaignId}/availability`
  );
}

export function fetchInfluencerPromotedMessages(influencerId) {
  return axios.get(`/influencers/${influencerId}/promoted_messages`);
}

export const fetchPromotedMessages = ({ id, ...params }) =>
  axios.get(`/influencers/${id}/promoted_messages`, { params });

export function setCampaignType({ id, type }) {
  return axios.post(`/campaigns/${id}/set-type`, { type });
}

export function fetchCampaignPromotedMessages(campaignId, params) {
  return axios.get(`/campaigns/${campaignId}/promoted_messages`, { params });
}

export function refreshLink({ id }) {
  return axios.get(`/campaigns/${id}/share-links/regenerate`);
}

export function getByToken({ token }) {
  return axios.get(`/campaigns/token/${token}`);
}

export function setCampaignToken({ influencerId, ...data }) {
  return axios.post(`/influencers/${influencerId}/set-campaign-token`, data);
}

export function fetchById(id) {
  return axios.get(`/campaigns/${id}`);
}

export function cancelPending({ id, ...data }) {
  return axios.post(`/campaigns/${id}/cancel_pending_invites`, data);
}

export function editProposal({ id, ...data }) {
  return axios.post(`/promoted-messages/${id}/edit-proposal`, data);
}

export function fetchPendingActivity(id) {
  return axios.get(`/campaigns/${id}/pending-activity`);
}

export function fetchListActiveCampaignsCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-active-campaigns/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListActiveCampaigns(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-active-campaigns',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListInactiveCampaignsCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-inactive-campaigns/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListInactiveCampaigns(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-inactive-campaigns',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListPublicationCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-published-post/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListPublication(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-published-post',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListActiveBrandsCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-active-brands/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListActiveBrands(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-active-brands',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListInactiveBrandsCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-inactive-brands/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListInactiveBrands(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-inactive-brands',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListUsersCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-users-participated/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListUsers(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-users-participated',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListNewUsersCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-new-accounts/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListNewUsers(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-new-accounts',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListSocialNetworksCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-new-social-network-accounts/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListSocialNetworks(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-new-social-network-accounts',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListCostsCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-transacted-amount/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListCosts(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/admin/list-transacted-amount',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListOrganizations(params) {
  return axios.get('/admin/list-organizations', {
    params,
    paramsSerializer: p => qs.stringify(p)
  });
}

export function fetchListAdvertizerActiveCampaignsCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/advertiser/list-active-campaigns/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListAdvertizerActiveCampaigns(params) {
  return axios.post('/advertiser/list-active-campaigns', null, {
    params,
    paramsSerializer: p => qs.stringify(p)
  });
}

export function fetchListAdvertizerInactiveCampaignsCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/advertiser/list-inactive-campaigns/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListAdvertizerInactiveCampaigns(params) {
  return axios.post('/advertiser/list-inactive-campaigns', null, {
    params,
    paramsSerializer: p => qs.stringify(p)
  });
}

export function fetchListAdvertizerPublicationCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/advertiser/list-published-post/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListAdvertizerPublication(params) {
  return axios.post('/advertiser/list-published-post', null, {
    params,
    paramsSerializer: p => qs.stringify(p)
  });
}

export function fetchListAdvertizerActiveBrandsCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/advertiser/list-active-brands/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListAdvertizerActiveBrands(params) {
  return axios.post('/advertiser/list-active-brands', null, {
    params,
    paramsSerializer: p => qs.stringify(p)
  });
}

export function fetchListAdvertizerInactiveBrandsCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/advertiser/list-inactive-brands/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListAdvertizerInactiveBrands(params) {
  return axios.post('/advertiser/list-inactive-brands', null, {
    params,
    paramsSerializer: p => qs.stringify(p)
  });
}

export function fetchListAdvertizerUsersCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/advertiser/list-users-participated/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListAdvertizerUsers(params) {
  return axios.post('/advertiser/list-users-participated', null, {
    params,
    paramsSerializer: p => qs.stringify(p)
  });
}

export function fetchListAdvertizerSocialNetworksCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/advertiser/list-new-social-network-accounts/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListAdvertizerSocialNetworks(params) {
  return axios.post('/advertiser/list-new-social-network-accounts', null, {
    params,
    paramsSerializer: p => qs.stringify(p)
  });
}

export function fetchListAdvertizerCostsCsv(params) {
  const { organizationId, ...otherParams } = params;
  return axios.post(
    '/advertiser/list-transacted-amount/csv',
    {
      organizationId
    },
    {
      params: {
        ...otherParams,
        paramsSerializer: p => qs.stringify(p)
      }
    }
  );
}

export function fetchListAdvertizerCosts(params) {
  return axios.post('/advertiser/list-transacted-amount', null, {
    params,
    paramsSerializer: p => qs.stringify(p)
  });
}
