import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { chain, isEmpty } from 'lodash';
import classNames from 'classnames';
import { Button } from 'ui-library';
import i18n from 'i18n';

import * as appActions from 'redux/modules/app';

import { getCurrentAdvertiserBrands } from 'modules/shared/selectors';

import { DebouncedSearch, BrandAvatar } from 'modules/shared/components';

import styles from './BrandSelector.less';

function mapStateToProps(state) {
  return {
    brands: getCurrentAdvertiserBrands(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changePage: appActions.changePage
    },
    dispatch
  );
}

class BrandSelector extends Component {
  static propTypes = {
    brands: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      filteredBrands: props.brands
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isEmpty(this.props.brands) && !isEmpty(nextProps.brands)) {
      this.setState({ filteredBrands: nextProps.brands });
    }
  }

  onFilter = query => {
    const { brands } = this.props;
    if (!query) {
      this.setState({ query, filteredBrands: brands });
    }

    const regex = new RegExp(query, 'i');
    const filteredBrands = chain(brands)
      .filter(b => regex.test(b.name))
      .orderBy('createdAt', 'desc')
      .value();

    return this.setState({ filteredBrands, query });
  };

  onCreateBrand = () => {
    this.props.changePage('/brands/new');
  };

  render = () => {
    const { brands } = this.props;
    const { filteredBrands } = this.state;

    if (isEmpty(brands)) return <EmptyState onCreate={this.onCreateBrand} />;

    const titleClassName = classNames(
      'flex middle-xs vf-font-small padding-Hx',
      styles.title
    );

    return (
      <div className={styles.container}>
        <div className={titleClassName}>
          {i18n.get('CHAT_BRAND_SELECTOR_TITLE')}
        </div>

        <DebouncedSearch
          className={styles.searcher}
          placeholder={i18n.get('CHAT_BRAND_SELECTOR_INPUT_PLACEHOLDER')}
          onChange={this.onFilter}
          value={this.state.query}
        />

        <div
          className='vf-scrolly padding-Hx'
          style={{ height: 'calc(55vh - 40px - 46px)' }}
        >
          {filteredBrands.map(brand => (
            <div
              className={styles.brand}
              key={brand._id}
              onClick={() => this.props.onSelect(brand._id)}
            >
              <BrandAvatar
                name={brand.name}
                url={brand.image}
                size='sm'
                shadow={false}
              />
              <div
                className='col margin-left-Hx vf-font-gray vf-font-small'
                style={{ width: '80%' }}
              >
                <b className='ellipsis width100' title={brand.name}>
                  {brand.name}
                </b>
                {i18n.get('ADV_BRANDS_BRAND_CARD_TOTAL_CAMPAIGNS', {
                  total: brand.campaigns.total
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
}

function EmptyState({ onCreate }) {
  const titleClassName = classNames(
    'vf-row no-margin middle-xs vf-font-small padding-Hx',
    styles.title
  );

  return (
    <div className={styles.container}>
      <div className={titleClassName}>
        <div className='col-xs-1 no-padding margin-top-Hx'>
          <i
            className='vf-icon icon-exclamation-triangle vf-text-danger'
            style={{ fontSize: '2.5rem' }}
          />
        </div>
        <div className='col-xs-11'>{i18n.get('CHAT_NO_BRAND_DISCLAIMER')}</div>
      </div>

      <div className='flex width100 center-xs margin-top-3x'>
        <Button
          size='sm'
          color='primary'
          onClick={onCreate}
          style={{ padding: '1.2rem 2.8rem' }}
        >
          <div className='flex middle-xs'>
            <i className='vf-icon icon-building margin-right-Hx' />
            <span>{i18n.get('ADV_BRANDS_CREATE_BRAND')}</span>
          </div>
        </Button>
      </div>
    </div>
  );
}

EmptyState.propTypes = {
  onCreate: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandSelector);
