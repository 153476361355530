import React, { Component } from 'react';
import qs from 'query-string';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, get, omit } from 'lodash';
import { Button } from 'ui-library';
import i18n from 'i18n';
import { getStatics, getDefaultTitle } from 'config/app';
import connectPersonalInstagramAccount from 'modules/legacy/js/lib/social-network-connections/instagram';
import connectBusinessInstagramAccount from 'modules/legacy/js/lib/social-network-connections/instagramBusiness';

import { showModal, hideModal, changePage } from 'redux/modules/app';

import { hamburgerIcon, chevronConfig } from 'assets/images';

const SOCIAL_NETWORK = 'instagram';

function mapStateToProps({ entities }) {
  const { SocialNetworkAccount = {} } = entities;
  const currentAccountId = get(window, 'config.ActiveAccountId');

  return {
    socialAccounts: filter(
      SocialNetworkAccount,
      account =>
        account.influencerId === currentAccountId &&
        account.type === SOCIAL_NETWORK
    )
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ showModal, hideModal, changePage }, dispatch);
}

function AccountFeature({ header, content, customClass }) {
  return (
    <div
      className={`margin-vertical-Hx margin-horizontal-Hx flex flex-col flex-align-top ${customClass}`}
    >
      <b className='margin-right-1x'>{header}</b>
      <span className='vf-font-normal'>{content}</span>
    </div>
  );
}

class InstagramAccountTypeSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      businessActive: true,
      personalActive: false
    };
  }

  connectToPersonalInstagramAccount = () => {
    const { onRedirect, redirectTo } = this.props;
    connectPersonalInstagramAccount({ onRedirect, redirectTo });
  };

  connectToBusinessInstagramAccount = () => {
    const { onRedirect, changePage, redirectTo } = this.props;
    const params = qs.stringify({ redirectTo });
    connectBusinessInstagramAccount({ onRedirect, redirectTo }).then(() =>
      changePage(`/social-accounts/add/instagram/business?${params}`)
    );
  };

  onMouseEnter = name => {
    if (name === 'business')
      this.setState({ businessActive: true, personalActive: false });
    if (name === 'personal')
      this.setState({ personalActive: true, businessActive: false });
  };

  handleBussinesClick = () => {
    window.open(
      i18n.get(
        'INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_BUSINESS_BULLET_1_URL_BUTTON_LINK'
      )
    );
  };

  handleProInstagramClick = () => {
    window.open(
      i18n.get(
        'INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_BULLET_2_BUTTON_LINK'
      )
    );
  };

  render() {
    const { businessActive, personalActive } = this.state;
    const { onBack } = this.props;

    const cardStyleActive = {
      'box-shadow': '0 0.4rem 2rem 0 rgba(0,0,0,0.05)',
      'border-radius': '1rem',
      background:
        getStatics().socialNetworks[SOCIAL_NETWORK].getBackgroundStyle()
          .background,
      width: '100%'
    };

    const FeatureContainerStyle = {
      background: '#fff',
      margin: '2px',
      padding: '1.5rem',
      'border-radius': '1rem',
      display: 'flex',
      'flex-direction': 'column',
      'align-items': 'flex-start'
    };

    const businessCardStyle = businessActive
      ? cardStyleActive
      : omit(cardStyleActive, 'background');

    return (
      <div>
        <div className='flex flex-col flex-space-between'>
          <div className='width100 margin-top-1x margin-bottom-2x'>
            {i18n.get('INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_GENERAL')}
          </div>
          <div className='vf-pull-right flex-align-top margin-top-Hx'>
            <i className='icon-close vf-clickable' onClick={onBack} />
          </div>
        </div>
        <div
          className='flex flex-col flex-align-top flex-justify-space-around'
          style={{ flexWrap: 'wrap' }}
        >
          <div
            className='margin-bottom-3x'
            style={businessCardStyle}
            onMouseEnter={() => this.onMouseEnter('business')}
          >
            <div style={FeatureContainerStyle}>
              <AccountFeature
                header='1.'
                content={
                  <span>
                    {i18n.get(
                      'INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_BULLET_1'
                    )}
                  </span>
                }
              />
              <AccountFeature
                header='2.'
                content={
                  <div>
                    <span className='flex flex-space-between'>
                      {i18n.get(
                        'INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_BULLET_2_P1'
                      )}
                      <div className=''>
                        <img
                          src={hamburgerIcon}
                          alt='hamburgerIcon'
                          style={{
                            height: '16px',
                            width: '16px',
                            margin: '0 4px'
                          }}
                        />
                      </div>
                      {i18n.get(
                        'INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_BULLET_2_P2'
                      )}
                    </span>
                    <SubList />
                    <a
                      style={{ fontWeight: '600' }}
                      href={i18n.get(
                        'INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_BUSINESS_BULLET_1_URL'
                      )}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {i18n.get('INF_SOCIAL_ACCOUNTS_NEED_HELP')}
                    </a>
                  </div>
                }
              />
              <AccountFeature
                header='3.'
                content={i18n.get(
                  'INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_BULLET_3'
                )}
              />
              <AccountFeature
                header='4.'
                content={i18n.get(
                  'INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_BULLET_4'
                )}
              />
              <AccountFeature
                header='5.'
                content={i18n.get(
                  'INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_BULLET_5',
                  {
                    companyName: getDefaultTitle()
                  }
                )}
                customClass='margin-bottom-1x'
              />
              <div className='flex flex-justify-center margin-bottom-Hx margin-top-1x'>
                <Button
                  color='primary'
                  onClick={this.connectToBusinessInstagramAccount}
                  className='margin-left-2x'
                  data-uitest='instagram-business-btn'
                >
                  {i18n.get(
                    'INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_BUTTON'
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const SubList = () => (
  <div className='margin-top-1x'>
    <p className='flex'>
      -{' '}
      {i18n.get(
        'INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_SUB_LIST_1_PART_1'
      )}
      <div>
        <img
          src={chevronConfig}
          alt='config'
          style={{
            height: '18px',
            width: '18px',
            margin: '5px 4px',
            paddingTop: '3px'
          }}
        />
      </div>
      {i18n.get(
        'INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_SUB_LIST_1_PART_2'
      )}
    </p>
    <p>
      - {i18n.get('INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_SUB_LIST_2')}
    </p>
    <p>
      - {i18n.get('INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_SUB_LIST_3')}
    </p>
    <p>
      - {i18n.get('INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_SUB_LIST_4')}
    </p>
    <p>
      - {i18n.get('INF_SOCIAL_ACCOUNTS_LINK_INSTAGRAM_BUSINESS_SUB_LIST_5')}
    </p>
  </div>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstagramAccountTypeSelector);
