"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const React = require("react");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const app_1 = require("config/app");
const images_1 = require("assets/images");
const styles = require("./AccountDataForm.less");
const types_1 = require("modules/shared/types");
const MusicalGenres_1 = require("./MusicalGenres/MusicalGenres");
const components_1 = require("apps/shared/components");
const CitySelect_1 = require("apps/shared/controllers/CitySelect/CitySelect");
const UploadAvatar_1 = require("apps/shared/components/UploadAvatar/UploadAvatar");
const CountrySelect_1 = require("apps/shared/controllers/CountrySelect/CountrySelect");
class AccountDataFormUI extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleChangeAvatar = (id, url) => {
            this.props.onChangeInput('profileImage', url);
        };
        this.handleChangeName = ev => {
            this.props.onChangeInput('name', ev.target.value);
        };
        this.handleChangeLastName = ev => {
            this.props.onChangeInput('lastName', ev.target.value);
        };
        this.handleChangeBirthday = (birthday) => {
            this.props.onChangeInput('birthday', birthday && birthday.toISOString());
        };
        this.handleChangeGender = ev => {
            const { value } = ev.target.dataset;
            this.props.onChangeInput('gender', value);
        };
        this.handleChangeCountry = (data) => {
            const { country } = this.props;
            const didChange = country !== data.id;
            if (!didChange)
                return;
            this.props.onChangeMultipleInput({
                city: null,
                country: data.id,
                region: null,
                timezone: null
            });
        };
        this.handleChangeCity = (city) => {
            this.props.onChangeInput('city', city.id);
        };
        this.handleChangeFirstBrand = ev => {
            this.props.onChangeInput('brandName', ev.target.value.trim());
        };
        this.handleInterestsChange = ({ currentTarget }) => {
            const { interests } = this.state;
            const { category, isSelected } = currentTarget.dataset;
            const MAX_INTERESTS = 4;
            const musicalGenres = interests || [];
            const interestSelected = JSON.parse(isSelected);
            const willAddCategory = !interestSelected;
            const hasReachedLimit = musicalGenres.length >= MAX_INTERESTS;
            if (willAddCategory && hasReachedLimit)
                return;
            if (willAddCategory) {
                musicalGenres.push(category);
            }
            else {
                const index = musicalGenres.findIndex(c => c === category);
                musicalGenres.splice(index, 1);
            }
            this.props.onChangeInput('musicalGenres', musicalGenres);
        };
        this.state = {
            interests: props.musicalGenres || []
        };
    }
    render() {
        const { interests } = this.state;
        const { city, name, role, gender, onNext, errors, canSave, country, lastName, onLogout, brandName, isLoading, profileImage, campaignLite, invitationLink, validInvitation } = this.props;
        const isInfluencer = role === types_1.UserRole.INFLUENCER;
        const isAdvertiser = role === types_1.UserRole.ADVERTISER;
        const columns = isInfluencer ? 'col-xs-12 col-md-9' : 'col-xs-12 col-md-12';
        const listInterests = interests || [];
        const { interestsLite } = app_1.getStatics();
        const selectedInterests = listInterests.reduce((result, category) => {
            result[category] = true;
            return result;
        }, {});
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'vf-row' },
                isInfluencer && (React.createElement("div", { className: 'col-xs-12 col-md-3 vf-settings-avatar', id: 'avatar-container' },
                    React.createElement("div", { className: 'flex center-xs' },
                        React.createElement(UploadAvatar_1.default, { image: profileImage, onChange: this.handleChangeAvatar })))),
                React.createElement("div", { className: columns },
                    React.createElement("div", { className: 'vf-simple-form-label' },
                        i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_FIRST_NAME'),
                        React.createElement("span", { className: 'vf-font-small vf-text-danger' }, " *")),
                    React.createElement(components_1.DebounceInput, { value: name, id: 'name-input', autoComplete: 'off', hasError: !!errors.name, onChange: this.handleChangeName, placeholder: i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_FIRST_NAME_PLACEHOLDER') }),
                    !!errors.name && (React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, errors.name)),
                    React.createElement("div", { className: 'vf-simple-form-label' },
                        i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_LAST_NAME'),
                        "\u00A0",
                        i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_OPTIONAL')),
                    React.createElement(components_1.DebounceInput, { value: lastName, autoComplete: 'off', id: 'last-name-input', onChange: this.handleChangeLastName, placeholder: i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_LAST_NAME_PLACEHOLDER') }),
                    isInfluencer && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'vf-simple-form-label' },
                            i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_BIRTH_DATE'),
                            React.createElement("span", { className: 'vf-font-small vf-text-danger' }, " *")),
                        React.createElement(components_1.Datepicker, { hasError: !!errors.birthday, onChange: this.handleChangeBirthday }),
                        !!errors.birthday && (React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, errors.birthday)),
                        React.createElement("div", { className: 'vf-simple-form-label' },
                            i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_LABEL'),
                            React.createElement("span", { className: 'vf-font-small vf-text-danger' }, " *")),
                        React.createElement(ui_library_1.Radio, { name: 'gender', id: 'male-gender', "data-value": 'male', className: 'margin-top-Hx', checked: gender === types_1.Gender.MALE, onChange: this.handleChangeGender }, i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_MALE')),
                        React.createElement(ui_library_1.Radio, { name: 'gender', id: 'female-gender', "data-value": 'female', className: 'margin-top-Hx', checked: gender === types_1.Gender.FEMALE, onChange: this.handleChangeGender }, i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_FEMALE')),
                        React.createElement(ui_library_1.Radio, { name: 'gender', id: 'other-gender', "data-value": 'other', className: 'margin-top-Hx', checked: gender === types_1.Gender.OTHER, onChange: this.handleChangeGender }, i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_OTHER')),
                        !!errors.gender && (React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, errors.gender)))),
                    React.createElement("div", { className: 'vf-row' },
                        React.createElement("div", { className: 'col-xs-12 margin-top-1x' },
                            React.createElement("div", { className: 'vf-simple-form-label' },
                                i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_COUNTRY_LABEL'),
                                React.createElement("span", { className: 'vf-font-small vf-text-danger' }, " *")),
                            React.createElement(CountrySelect_1.default, { selectedId: country || '', hasError: !!errors.country, onChange: this.handleChangeCountry }),
                            !!errors.country && (React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, errors.country)),
                            React.createElement("div", { className: 'vf-simple-form-label' },
                                i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_CITY_LABEL'),
                                React.createElement("span", { className: 'vf-font-small vf-text-danger' }, " *")),
                            React.createElement(CitySelect_1.default, { disabled: !country, cityId: city || '', countryId: country, hasError: !!errors.city, onChange: this.handleChangeCity }),
                            !!errors.city && (React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, errors.city)))),
                    isInfluencer && campaignLite && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'vf-simple-form-label' }, i18n.get('ADV_SETTINGS_ACCOUNT_MUSICAL_GENRES')),
                        React.createElement("div", { className: styles['music-container'] }, lodash_1.map(interestsLite, item => {
                            const isSelected = !!selectedInterests[item.value];
                            return (React.createElement(MusicalGenres_1.default, { key: item.value, text: item.label, isSelected: isSelected, "data-category": item.value, "data-is-selected": isSelected, onToggle: this.handleInterestsChange }));
                        })))),
                    React.createElement("div", { className: styles.alert },
                        React.createElement("img", { alt: 'warning', src: images_1.iconAlert, className: 'vf-img-warning' }),
                        i18n.get('REGISTER_DATA_EDITION_DISCLAIMER')),
                    isAdvertiser && (!validInvitation || invitationLink) && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'vf-simple-form-label' },
                            i18n.get('ADV_SETTINGS_ACCOUNT_FIRST_BRAND'),
                            React.createElement("span", { className: 'vf-font-small vf-text-danger' }, " *")),
                        React.createElement(components_1.DebounceInput, { autoComplete: 'off', value: brandName || '', id: 'firstBrand-name-input', hasError: !!errors.brandName, onChange: this.handleChangeFirstBrand, placeholder: i18n.get('ADV_SETTINGS_ACCOUNT_FIRST_BRAND_PLACEHOLDER') }),
                        !!errors.brandName && (React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, errors.brandName)))))),
            React.createElement("div", { className: 'margin-top-3x width100 flex between-xs' },
                React.createElement(ui_library_1.Button, { color: 'flat', onClick: onLogout, disabled: isLoading, className: 'vf-text-gray-dark vf-font-bold' }, i18n.get('LOGOUT')),
                React.createElement(ui_library_1.Button, { size: 'sm', color: 'primary', onClick: onNext, disabled: canSave || isLoading, style: { padding: '1rem 3rem', borderRadius: '2px' } }, validInvitation && !invitationLink
                    ? i18n.get('FINISH')
                    : i18n.get('NEXT')))));
    }
}
exports.default = AccountDataFormUI;
