/* eslint-disable no-console */
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import _ from 'lodash';

import request from 'superagent';
import CONFIG from '../../../../config/app';

const API_URL = CONFIG.getAPIHost();

// STORES

const Actions = {
  app: require('../actions/app'),
  dataStore: require('../actions/dataStore')
};

const Stores = {
  currentUser: require('../stores/currentUser'),
  dataStore: require('../stores/dataStore'),
  configStore: require('../stores/configStore')
};

const dataManager = require('./datamanager').getInstance();

const AlreadyRequested = [];

module.exports = {
  auto(endpoint, data = {}, extra = {}) {
    this[endpoint](data, (err, res) => {
      if ((err || res.code) && extra.alert !== false) {
        console.error(err, res, { endpoint, data, extra });
        Actions.app.displayAlert({
          title: 'Error',
          message: res && res.message
        });
        if (_.isFunction(extra.callback)) {
          extra.callback(err, res);
        }
      } else {
        Actions.dataStore.update(res, () => {
          if (!res.code && extra.successMessage) {
            Actions.app.displayAlert({
              title: extra.successTitle || '¡Éxito!',
              type: 'success',
              message: extra.successMessage
            });
          }

          // handle unfinished lists
          if (extra.autoLoad && res.data && res.data.length === res.limit) {
            const skip = data.skip ? data.skip + res.limit : res.limit;
            this.auto(endpoint, _.assign(data, { skip }), extra);
          }

          if (_.isFunction(extra.callback)) {
            extra.callback(err, res);
          } else if (extra.callback) {
            console.error(
              '\nCallback on API.auto is not a function',
              // eslint-disable-next-line prefer-rest-params
              arguments
            );
          }
        });
      }
    });
  },

  cache(endpoint, model, callback, autoData) {
    // eslint-disable-next-line no-void
    if (Stores.dataStore.getAll()[model] !== void 0) {
      if (callback) {
        callback({
          modelName: [model],
          data: Stores.dataStore.getAll()[model],
          limit: 100,
          skip: 0,
          total: Stores.dataStore.getAll()[model].length
        });
      }

      return;
    }

    if (autoData) {
      return this.auto(endpoint, autoData.data, autoData.config);
    }

    return this[endpoint](null, callback);
  },

  cacheItem(endpoint, model, id, callback, eachInArray) {
    const dataStore = Stores.dataStore.getAll();

    if (!id || !id.length) return;

    if (_.isArray(id)) {
      const newIds = _.reduce(
        _.uniq(id),
        (result, el) => {
          if (AlreadyRequested.indexOf(el) === -1) {
            AlreadyRequested.push(el);
            result.push(el);
          }
          return result;
        },
        []
      );

      if (eachInArray) {
        _.forEach(newIds, currentId => {
          this.auto(endpoint, { _id: currentId }, { callback });
        });
      } else if (newIds.length) {
        this.auto(endpoint, { _id: newIds }, { callback });
      }

      return;
    }

    if (dataStore[model] && dataStore[model][id]) {
      if (callback) {
        callback(null, {
          modelName: model,
          data: dataStore[model][id],
          id
        });
      }

      return;
    }

    if (AlreadyRequested.indexOf(id) === -1) {
      AlreadyRequested.push(id);
      this.auto(endpoint, { _id: id }, { callback });
    }
  },

  /** ************************************************
   * AUTH
   ************************************************* */

  /**
   * Authenticates a User using his email and password combination.
   *
   * @params args <required> [Object] {
   *      email: <required> [String],
   *      password: <required> [String]
   * }
   * @params callback <required> [Function]
   * @return callback(error, response)
   */
  signin(args, callback) {
    _data;

    if (!args.email || !args.password || !callback) {
      throw new TypeError('API.signin: missing required params');
    }

    const _data = {
      email: args.email,
      password: args.password
    };

    request
      .post(`${API_URL}/auth/signin`)
      .send(_data)
      .end((err, res) => {
        callback(err, res.ok ? res.body : res.text);
      });
  },

  /**
   * Signout the current user.
   *
   * @params args <required> [Object] {
   *      token: <required> [String]
   * }
   * @params callback <required> [Function]
   * @return callback(error:Boolean, response:Object)
   */
  signout(data, callback) {
    // !deprecated
    dataManager.post('/auth/signout/', null, null, callback);
  },

  /**
   * Attemps to register a new user based on email and password combination.
   *
   * @params args <required> [Object] {
   *      email: <required> [String],
   *      password: <required> [String],
   *      role: <optional> [String]
   * }
   * @params callback <required> [Function]
   * @return callback(error:Boolean, response:Object)
   */
  register(args, callback) {
    if (!args.email || !args.password || !callback) {
      throw new TypeError('API.register: missing required params');
    }

    const _data = {
      email: args.email,
      password: args.password
    };

    if (args.role) {
      _data.role = args.role;
    }

    request
      .post(`${API_URL}/auth/register`)
      .send(_data)
      .end((err, res) => {
        callback(err, res.ok ? res.body : res.text);
      });
  },

  /**
   * Verify that user email is valid
   *
   * @params args <required> [Object] {
   *      token: <required> [String]
   * }
   * @params callback <required> [Function]
   * @return callback(error:Boolean, response:Object)
   */
  verifyEmail(args, callback) {
    if (!args.token || !callback) {
      throw new TypeError('API.verifyEmail: missing required params');
    }

    const requestData = {
      token: args.token
    };

    dataManager.put('/auth/email/verification', requestData, null, callback);
  },

  /**
   * Verify if a SessionToken is still valid.
   *
   * @params args <required> [Object] {
   *      token: <required> [String]
   * }
   * @params callback <required> [Function]
   * @return callback(error, response)
   */
  validate(args, callback) {
    if (!args.token || !callback) {
      throw new TypeError('API.validate: missing required args.');
    }

    request
      .post(`${API_URL}/auth/validate`)
      .set('Auth', args.token)
      .end((err, res) => {
        if (err) {
          console.warn({ err });
        }

        callback(err, res.ok ? res.body : res.text);
      });
  },

  /** ************************************************
   * CAPTCHA
   ************************************************* */

  /**
   * Returns if the current IP will is required to prove humanity
   * (using Captchas) before doing request to an endpoint with Captcha Access
   * control.
   *
   * @params callback <required> [Function]
   * @return callback(error, response)
   */
  getCaptcha(callback) {
    request.get(`${API_URL}/auth/captcha`).end((err, res) => {
      callback(err, res && (res.ok ? res.body : res.text));
    });
  },

  /**
   * Validates the user response to a captcha challenge (using reCaptcha by Google).
   *
   * @params args <required> [Object] {
   *      response: <required> [String] The response returned by reCaptcha
   * }
   * @params callback <required> [Function]
   * @return callback(error:Boolean, response:Object)
   */
  postCaptcha(args, callback) {
    if (!args.response || !callback) {
      throw new TypeError('API.postCaptch: missing required args');
    }

    request
      .post(`${API_URL}/auth/captcha`)
      .send({ response: args.response })
      .end((err, res) => {
        callback(err, res.ok ? res.body : res.text);
      });
  },

  // NEED DOCUMENTATION

  sendEmailVerification(data, callback) {
    dataManager.post('/auth/email/verification/', data, null, callback);
  },

  updateCurrentUser(newData, callback, stopSave) {
    const configStore = Stores.configStore.getAll();

    if (stopSave) return;
    const params = Object.assign({}, newData, { id: configStore.ActiveUserId });
    this.updateUser(params, callback);
  },

  updateUser(data, callback) {
    dataManager.put(`/users/${data.id}`, data, null, callback);
  },

  updatePasswordUser(data, callback) {
    const configStore = Stores.configStore.getAll();
    const { displayAlert } = Actions.app;
    const config = { headers: { 'x-password': data.currentPassword } };

    if (!data.currentPassword || data.currentPassword === '') {
      displayAlert({
        message: 'Missing current password.'
      });
      return;
    }

    delete data.currentPassword;

    dataManager.put(
      `/users/${configStore.ActiveUserId}/secure`,
      data,
      config,
      callback
    );
  },

  deleteCurrentUser(data, callback) {
    const configStore = Stores.configStore.getAll();

    dataManager.del(
      `/users/${configStore.ActiveUserId}`,
      null,
      {
        headers: {
          'x-password': data.password
        }
      },
      callback
    );
  },

  updateSocialNetwork(data, callback) {
    const { _id, ...other } = data;
    dataManager.put(
      `/social_network_accounts/${_id}`,
      { ...other },
      null,
      callback
    );
  },

  updateSocialNetworkReach(data, callback) {
    const { _id, ...other } = data;
    dataManager.put(
      `/social_network_accounts/${_id}/reach`,
      { ...other },
      null,
      callback
    );
  },

  updateCurrentSocialNetwork(data, callback) {
    const configStore = Stores.configStore.getAll();
    if (!configStore.ActiveSocialNetwork) return;
    data._id = configStore.ActiveSocialNetwork;
    this.updateSocialNetwork(data, callback);
  },

  uploadImage(data, callback) {
    const requestData = {
      imageType: data.type || 'ProfileImage',
      image: {
        attach: data.file
      },
      references: data.references || []
    };

    dataManager.post('/images/upload', requestData, null, callback);
  },

  getImage(data, callback) {
    dataManager.get(`/images/${data._id}`, null, null, callback);
  },

  getImageCustom(data, callback) {
    dataManager.get('/images/', data, null, callback);
  },

  updateAccount(data, callback) {
    const { _id, ...other } = data;
    dataManager.put(`/accounts/${_id}`, { ...other }, null, callback);
  },

  getAccount(data, callback) {
    dataManager.get(`/accounts/${data._id}`, null, null, callback);
  },

  getAccountByData(data, callback) {
    dataManager.get('/accounts/', data, null, callback);
  },

  getUsers(data, callback) {
    const query = { ...data, q: encodeURIComponent(data.q || '') };
    dataManager.get('/users/', query, null, callback);
  },

  getBrandPaymentsIn({ id, ...query }, callback) {
    dataManager.get(`/accounts/${id}/payments_in`, query, null, callback);
  },

  requestSocialnetwork(data, callback) {
    const configStore = Stores.configStore.getAll();
    const { type, ...other } = data;
    const path = `/users/${configStore.ActiveUserId}/integrations/${type}`;

    other.redirectURL = `${window.location.protocol}//${window.location.host}/authenticate/${type}`;

    dataManager.get(path, { ...other }, null, callback);
  },

  authenticateSocialnetwork(data, callback) {
    const configStore = Stores.configStore.getAll();
    const influencerId = configStore.AuthenticatedAccountId;
    const requestPath = `/accounts/${influencerId}/social-network-accounts/authenticate`;
    dataManager.post(requestPath, data, null, callback);
  },

  linkSocialNetwork(data, callback) {
    const configStore = Stores.configStore.getAll();
    const influencerId = configStore.AuthenticatedAccountId;
    const requestPath = `/accounts/${influencerId}/social-network-accounts/link`;
    dataManager.post(requestPath, data, null, callback);
  },

  sendPhoneVericationCode(data, callback) {
    dataManager.post(
      `/users/${data.id}/phone/verification`,
      data,
      null,
      callback
    );
  },

  verifyPhone(data, callback) {
    dataManager.put(
      `/users/${data.id}/phone/verification`,
      data,
      null,
      callback
    );
  },

  createCampaign(data, callback) {
    const { brandId, ...other } = data;
    dataManager.post(
      `/brands/${brandId}/campaigns`,
      { ...other },
      null,
      callback
    );
  },

  updateCampaign(data, callback) {
    const { brandId, campaignId, ...other } = data;
    dataManager.put(`/campaigns/${campaignId}`, { ...other }, null, callback);
  },

  campaignShortUrl(data, callback) {
    const { _id, ...other } = data;
    const requestPath = `/campaigns/${_id}/short_urls`;
    dataManager.post(requestPath, { ...other }, null, callback);
  },

  getAccountAgencyPartenerships(data, callback) {
    const configStore = Stores.configStore.getAll();
    dataManager.get(
      `/accounts/${configStore.ActiveAccountId}/agency_partnerships/`,
      data,
      null,
      callback
    );
  },

  inviteAgencyClient(data, callback) {
    const { _id, ...other } = data;
    dataManager.put(`/brands/${_id}/users`, { ...other }, null, callback);
  },

  inviteInfluencer(data, callback) {
    const { brandId, campaignId, ...other } = data;
    dataManager.post(
      `/campaigns/${campaignId}/invites`,
      { ...other },
      null,
      callback
    );
  },

  cancelPendingInvites(data, callback) {
    const { id, ...other } = data;
    dataManager.post(
      `/campaigns/${id}/cancel_pending_invites`,
      { ...other },
      null,
      callback
    );
  },

  acceptInvite(data, callback) {
    const { _id, ...other } = data;
    dataManager.put(`/invites/${_id}/accept`, { ...other }, null, callback);
  },

  rejectInvite(data, callback) {
    const { _id, ...other } = data;
    dataManager.put(`/invites/${_id}/reject`, { ...other }, null, callback);
  },

  cancelInvite(data, callback) {
    const { _id, ...other } = data;
    dataManager.post(`/invites/${_id}/cancel`, { ...other }, null, callback);
  },

  finishInvite(data, callback) {
    const { _id, ...other } = data;
    dataManager.post(`/invites/${_id}/finish`, { ...other }, null, callback);
  },

  getCampaignParticipations(data, callback) {
    const { brandId, campaignId, ...other } = data;
    dataManager.get(
      `/campaigns/${campaignId}/invites`,
      { ...other },
      null,
      callback
    );
  },

  getAgencyPartnership(data, callback) {
    const { _id, ...other } = data;
    dataManager.get(
      `/agency_partnerships/${_id}`,
      { ...other },
      null,
      callback
    );
  },

  delAgencyPartnership(data, callback) {
    dataManager.del(`/agency_partnerships/${data._id}`, null, null, callback);
  },

  createAgencyPartnership(data, callback) {
    dataManager.post('/agency_partnerships/', data, null, callback);
  },

  acceptPartnership(data, callback) {
    dataManager.put(
      `/agency_partnerships/${data._id}/accept/`,
      null,
      null,
      callback
    );
  },

  deletePartnership(data, callback) {
    dataManager.del(`/agency_partnerships/${data._id}`, null, null, callback);
  },

  getCurrentUserSocialNetworks(data, callback) {
    const configStore = Stores.configStore.getAll();
    const accountId = _.get(data, 'accountId') || configStore.ActiveAccountId;

    if (!accountId) return;
    const newData = { removeInstagramPersonal: true, ...data };
    dataManager.get(
      `/accounts/${accountId}/social_network_accounts/`,
      newData,
      null,
      callback
    );
  },

  getCampaign(data, callback) {
    const { campaignId } = data;
    dataManager.get(`/campaigns/${campaignId}`, null, null, callback);
  },

  getCampaigns(data, callback) {
    dataManager.get('/campaigns/', data, null, callback);
  },

  getCampaignsById(data, callback) {
    const { campaignId, ...other } = data;
    dataManager.get(`/campaigns/${campaignId}`, { ...other }, null, callback);
  },

  getCampaignPromotedMessages(data, callback) {
    const { _id, ...other } = data;
    dataManager.get(
      `/campaigns/${_id}/promoted_messages`,
      { ...other },
      null,
      callback
    );
  },

  getCampaignSocialNetworkAccounts(data, callback) {
    dataManager.get(
      `/campaigns/${data._id}/social_network_accounts`,
      null,
      null,
      callback
    );
  },

  getCampaignInvitesExport(id, callback) {
    dataManager.post(`/campaigns/${id}/invites/export`, null, null, callback);
  },

  getCampaignOverviewStats(params, callback) {
    const { campaignId, ...other } = params;
    const requestPath = `/campaigns/${campaignId}/overview/export`;
    dataManager.post(requestPath, { ...other }, null, callback);
  },

  getCampaignPostsReport(params, callback) {
    const { campaignId, ...other } = params;
    const requestPath = `/campaigns/${campaignId}/posts/export`;
    dataManager.post(requestPath, { ...other }, null, callback);
  },

  getOpenCampaigns(data, callback) {
    dataManager.get('/campaigns/available', null, null, callback);
  },

  joinCampaign(data, callback) {
    const { _id, ...other } = data;
    dataManager.post(`/campaigns/${_id}/join`, data, null, callback);
  },

  getInvitesCampaigns(data, callback) {
    const currentAccount = Stores.currentUser.getAll().account;
    const currentAccountId = _.get(currentAccount, '_id', currentAccount);
    dataManager.get(
      `/influencers/${currentAccountId}/invites`,
      data,
      null,
      callback
    );
  },

  getInfluencerEarnings(data, callback) {
    const currentAccount = Stores.currentUser.getAll().account;
    const currentAccountId = _.get(currentAccount, '_id', currentAccount);
    const path = `/influencers/${currentAccountId}/earnings`;
    dataManager.get(path, null, null, callback);
  },

  createBillingInfoToCurrentAccount(data, callback) {
    const currentAccount = Stores.currentUser.getAll().account;
    const currentAccountId = _.get(currentAccount, '_id', currentAccount);
    dataManager.post(
      `/accounts/${currentAccountId}/billing_info`,
      data,
      null,
      callback
    );
  },

  updateBillingInfoToCurrentAccount(data, callback) {
    const currentAccount = Stores.currentUser.getAll().account;
    const currentAccountId = _.get(currentAccount, '_id', currentAccount);
    dataManager.put(
      `/accounts/${currentAccountId}/billing_info/${data._id}`,
      data,
      null,
      callback
    );
  },

  deleteBillingInfoToCurrentAccount(data, callback) {
    const currentAccount = Stores.currentUser.getAll().account;
    const currentAccountId = _.get(currentAccount, '_id', currentAccount);
    const requestURL = `/accounts/${currentAccountId}/billing_info/${data._id}`;
    dataManager.del(requestURL, null, null, callback);
  },

  resetPassword(data, callback) {
    dataManager.post(
      '/auth/password/recover',
      {
        email: data.email
      },
      null,
      callback
    );
  },

  setRecoverPassword(data, callback) {
    dataManager.post(
      '/auth/password/set',
      {
        token: data.token,
        password: data.password
      },
      null,
      callback
    );
  },

  getPlaces(data, callback) {
    const countryId = data.countryId || 'MX';
    const query = data.q;
    const ENDPOINT = `/places?q=${query}&limit=20&sort=-population&country.id=${countryId}`;
    dataManager.get(ENDPOINT, null, null, callback);
  },

  getCountries(data, callback) {
    const query = data.q;
    const ENDPOINT = `/places/countries?q=${query}&limit=20`;

    dataManager.get(ENDPOINT, null, null, callback);
  },

  getCities(data, callback) {
    const countryId = data.countryId || 'MX';
    const query = data.q;
    const ENDPOINT = `/places/cities?q=${query}&limit=20&country=${countryId}`;

    dataManager.get(ENDPOINT, null, null, callback);
  },

  getPlacesByData(data, callback) {
    dataManager.get('/places', data, null, callback);
  },

  getPlacesMe(data, callback) {
    dataManager.get('/places/me', null, null, callback);
  },

  getPlace(data, callback) {
    dataManager.get(`/places/${data.placeId}`, null, null, callback);
  },

  getCampaignStatsGroups(data, callback) {
    dataManager.get(
      '/campaign-stats/groups',
      data,
      { useStreamRequests: false },
      callback
    );
  },

  getCampaignPostsStats(data, callback) {
    const { id, ...other } = data;
    dataManager.get(
      `/campaigns/${id}/stats/posts`,
      { ...other },
      { useStreamRequests: false },
      callback
    );
  },

  getCampaignCostStats(data, callback) {
    const { id, ...other } = data;
    dataManager.get(
      `/campaigns/${id}/stats/cost`,
      { ...other },
      { useStreamRequests: false },
      callback
    );
  },

  promotedMessageContentReview(data, callback) {
    const { _id, ...other } = data;
    dataManager.put(
      `/promoted_messages/${_id}/content_review`,
      { ...other },
      null,
      callback
    );
  },

  acceptMessageProposal(data, callback) {
    const { _id, ...other } = data;
    dataManager.post(
      `/promoted-messages/${_id}/accept`,
      { ...other },
      null,
      callback
    );
  },

  rejectMessageProposal(data, callback) {
    const { _id, ...other } = data;
    dataManager.post(
      `/promoted-messages/${_id}/reject`,
      { ...other },
      null,
      callback
    );
  },

  promotedMessageSchedule(data, callback) {
    const { _id, ...other } = data;
    dataManager.post(
      `/promoted-messages/${_id}/schedule`,
      { ...other },
      null,
      callback
    );
  },

  getPromotedMessages(data, callback) {
    const currentAccount = Stores.currentUser.getAll().account;
    const currentAccountId = _.get(currentAccount, '_id', currentAccount);
    dataManager.get(
      `/influencers/${currentAccountId}/promoted_messages`,
      data,
      null,
      callback
    );
  },

  getPromotedMessagesPublished(data, callback) {
    const currentAccount = Stores.currentUser.getAll().account;
    const currentAccountId = _.get(currentAccount, '_id', currentAccount);
    dataManager.get(
      `/influencers/${currentAccountId}/promoted_messages/published`,
      data,
      null,
      callback
    );
  },

  getPromotedMessagesScheduled(data, callback) {
    const currentAccount = Stores.currentUser.getAll().account;
    const currentAccountId = _.get(currentAccount, '_id', currentAccount);
    dataManager.get(
      `/influencers/${currentAccountId}/promoted_messages/scheduled`,
      data,
      null,
      callback
    );
  },

  getPromotedMessagesCreated(data, callback) {
    const currentAccount = Stores.currentUser.getAll().account;
    const currentAccountId = _.get(currentAccount, '_id', currentAccount);
    dataManager.get(
      `/influencers/${currentAccountId}/promoted_messages/created`,
      data,
      null,
      callback
    );
  },

  getPromotedMessagesUnverified(data, callback) {
    dataManager.get('/promoted_messages/unverified', data, null, callback);
  },

  getAllPromotedMessages(data, callback) {
    dataManager.get('/promoted_messages/', data, null, callback);
  },

  verifyPromotedMessage(data, callback) {
    const { _id, ...other } = data;
    dataManager.put(
      `/promoted_messages/${_id}/post_verification`,
      { ...other },
      null,
      callback
    );
  },

  cancelPromotedMessage(data, callback) {
    dataManager.put(
      `/promoted_messages/${data._id}/cancel_by_influencer`,
      null,
      null,
      callback
    );
  },

  getSocialNetworkMessage(data, callback) {
    const { _id, ...other } = data;
    dataManager.get(
      `/social_network_messages/${_id}`,
      { ...other },
      null,
      callback
    );
  },

  getSocialNetworkMessages(data, callback) {
    dataManager.get('/social_network_messages/', data, null, callback);
  },

  putSocialNetworkMessages(data, callback) {
    const { _id, ...other } = data;
    dataManager.put(
      `/social_network_messages/${_id}`,
      { ...other },
      null,
      callback
    );
  },

  getSocialNetworkPromotedMessages(data, callback) {
    const { _id, ...other } = data;
    dataManager.get(
      `/social_network_accounts/${_id}/promoted_messages`,
      { ...other },
      null,
      callback
    );
  },

  postPromotedMessage(data, callback) {
    dataManager.post('/promoted_messages/', data, null, callback);
  },

  getSocialNetworkSocialMessages(data, callback) {
    const { _id, ...other } = data;
    dataManager.get(
      `/social_network_accounts/${_id}/social_network_messages`,
      { ...other },
      null,
      callback
    );
  },

  getSocialNetworkAccounts(data, callback) {
    const parsedData = {};
    Object.keys(data).reduce((prev, dataKey) => {
      prev[encodeURIComponent(dataKey)] = data[dataKey];
      return prev;
    }, parsedData);
    parsedData.removeInstagramPersonal = true;
    dataManager.get('/social_network_accounts/', data, null, callback);
  },

  getSocialNetworkAccountsWithInvitesStatus(data, callback) {
    const { campaignId, ...other } = data;
    dataManager.get(
      `/campaigns/${campaignId}/social-network-accounts/search`,
      { ...other },
      null,
      callback
    );
  },

  getSocialAccounts(data, callback) {
    console.error(
      '\ngetSocialAccounts is deprecated. Use getSocialNetworkAccountsById instead'
    );
    this.getSocialNetworkAccountsById(data, callback);
  },

  getRelatedSocialAccounts(data, callback) {
    const { _id, ...other } = data;
    dataManager.get(
      `/social_network_accounts/related_to/${_id}`,
      null,
      null,
      callback
    );
  },

  getSocialNetworkAccountsById(data, callback) {
    this.getSocialNetworkAccountsById(data, callback);
    dataManager.get(
      `/social_network_accounts/${data._id}`,
      null,
      null,
      callback
    );
  },

  reportSocialAccount(data, callback) {
    const { inviteId, ...other } = data;
    dataManager.post(
      `/pro/invites/${inviteId}/report-user`,
      { ...other },
      null,
      callback
    );
  },

  getAdvertisersActivityStatus(data, callback) {
    const { _id } = data;
    dataManager.get(`/brands/${_id}/activity_status/`, null, null, callback);
  },

  getPaymentsOut(data, callback) {
    dataManager.get('/payments_out', data, null, callback);
  },

  getPaymentsOutPaypal(data, callback) {
    const { _id, currentPassword, ...other } = data;

    const config = {
      headers: { 'x-password': currentPassword }
    };

    dataManager.post(
      `/influencers/${_id}/payments_out/paypal`,
      { ...other },
      config,
      callback
    );
  },

  getPaymentsOutPayoneer(data, callback) {
    const { _id, currentPassword, ...other } = data;

    const config = {
      headers: { 'x-password': currentPassword }
    };

    dataManager.post(
      `/influencers/${_id}/payments_out/payoneer`,
      { ...other },
      config,
      callback
    );
  },

  completePaymentOut(data, callback) {
    dataManager.put(`/payments_out/${data._id}/complete`, null, null, callback);
  },

  cancelPaymentOut(data, callback) {
    dataManager.put(`/payments_out/${data._id}/cancel`, null, null, callback);
  },

  penalizePaymentOut(data, callback) {
    dataManager.put(`/payments_out/${data._id}/penalize`, null, null, callback);
  },

  getPaymentsOutRecepit(data, callback) {
    const { _id, ...other } = data;

    dataManager.get(
      `/payments_out/${_id}/receipt`,
      { ...other },
      null,
      callback
    );
  },

  getPaymentsIn(data, callback) {
    dataManager.get('/payments_in', data, null, callback);
  },

  getPaymentsInRecepit(data, callback) {
    const { _id, ...other } = data;

    dataManager.get(
      `/payments_in/${_id}/receipt`,
      { ...other },
      null,
      callback
    );
  },

  startStripePaymentIn(data, callback) {
    const { _id, ...other } = this.state;

    dataManager.post(
      `/accounts/${_id}/payments_in/stripe`,
      { ...other },
      callback
    );
  },

  startCurrentAccountStripePaymentsIn(data, callback) {
    const configStore = Stores.configStore.getAll();

    dataManager.post(
      `/accounts/${configStore.ActiveAccountId}/payments_in/stripe`,
      data,
      null,
      callback
    );
  },

  deleteSocialNetworkAccount(data, callback) {
    const configStore = Stores.configStore.getAll();
    const { ActiveAccountId } = configStore;
    const { password, socialNetworkAccountId } = data;
    const requestUrl = `/accounts/${ActiveAccountId}/social_network_accounts/${socialNetworkAccountId}`;
    const headers = {
      headers: {
        'x-password': password
      }
    };
    dataManager.del(requestUrl, null, headers, callback);
  },

  getAdsActivity(data, callback) {
    const { _id, ...other } = data;

    dataManager.get(
      `/campaigns/${_id}/activity_status`,
      { ...other },
      null,
      callback
    );
  },

  updateRole(data, callback) {
    const currentUser = Stores.currentUser.getAll();

    dataManager.put(`/users/${currentUser._id}/account`, data, null, callback);
  },

  getUserMe(data, callback) {
    dataManager.get('/users/me', data, null, callback);
  },

  // domains actions
  addDomainToAccount(data, callback) {
    const { accountId, domain } = data;
    dataManager.put(`/accounts/${accountId}/domains`, domain, null, callback);
  },

  removeDomainFromAccount(data, callback) {
    const { accountId, domain } = data;
    dataManager.del(
      `/accounts/${accountId}/domains/${domain}`,
      domain,
      null,
      callback
    );
  },

  teamMembers(callback) {
    request.get(`${CONFIG.publicSite}/members`).end((err, res) => {
      callback(err, res.ok ? res.body : res.text);
    });
  },

  setPaypalAccount(data, callback) {
    const { id, ...other } = data;
    dataManager.put(`/user/${id}`, { ...other }, null, callback);
  },

  addSocialNetworkMessage(data, callback) {
    const { id, ...other } = data;
    dataManager.post(
      `/promoted-messages/${id}/attach-publication/`,
      { ...other },
      null,
      callback
    );
  },

  getAccountCampaigns(data, callback) {
    const { _id, ...other } = data;
    dataManager.get(`/accounts/${_id}/campaigns`, { ...other }, null, callback);
  },

  getBrandCampaigns(data, callback) {
    const { id, ...other } = data;
    dataManager.get(`/accounts/${id}/campaigns`, { ...other }, null, callback);
  },

  getSensitiveContent(data, callback) {
    dataManager.get(
      '/social_network_accounts/sensitive_content',
      data,
      null,
      callback
    );
  },

  getTimeLine(data, callback) {
    const { socialAccountId, ...other } = data;

    dataManager.get(
      `/social_network_accounts/${socialAccountId}/timeline`,
      { ...other },
      null,
      callback
    );
  },

  getPayoneerUrl(data, callback) {
    const { _id, ...other } = data;

    dataManager.get(
      `/users/${_id}/integrations/payoneer`,
      { ...other },
      null,
      callback
    );
  },

  setPaymentOut(data, callback) {
    const { _id, ...other } = data;

    dataManager.put(
      `/payments_out/${_id}/process`,
      { ...other },
      null,
      callback
    );
  },

  getRegistrationInviteByToken(data, callback) {
    dataManager.get(
      `/registration-invites/${data.token}`,
      null,
      null,
      callback
    );
  },

  convertCurrency(data, callback) {
    const { from, to, amount } = data;
    dataManager.get(`/convert/${from}/${to}`, { amount }, null, callback);
  },

  selfCancelInfluencerParticipation(data, callback) {
    const { inviteId, influencerReason } = data;

    dataManager.post(
      `/invites/${inviteId}/abort`,
      { influencerReason },
      null,
      callback
    );
  },

  markAccountAsVerified(id, callback) {
    dataManager.post(
      `/social-network-accounts/${id}/set-verified`,
      {
        isVerified: true
      },
      null,
      callback
    );
  },

  markAccountAsNotVerified(id, callback) {
    dataManager.post(
      `/social-network-accounts/${id}/set-verified`,
      {
        isVerified: false
      },
      null,
      callback
    );
  }
};
