import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { without, get } from 'lodash';
import InfluencerFilters from './containers/InfluencerFilters';
import AudienceFilters from './containers/AudienceFilters';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as placeActions from 'redux/modules/places';
import { searchTag } from 'redux/modules/socialAccounts';
import { socialAccounts } from 'services/voxfeed';

class InfluencerFilterList extends React.Component {
  state = {
    hasAceptableTypes: false,
    openAudienceFilters: false,
    openInfluencerFilters: false
  };

  componentDidMount = params => {
    if (this.props.location.search) {
      this.setState({ openInfluencerFilters: true }, _ =>
        setTimeout(this.setUrlFilters, 1000)
      );
    }
  };

  setUrlFilters = params => {
    const filters = this.getUrlParameters();
    if (filters.location || filters.city) {
      let searchString = ``;
      if (filters.location && filters.city) {
        searchString = `${filters.location}, ${filters.city}`;
      } else {
        searchString = `${filters.location ? filters.location : ''}${
          filters.city ? filters.city : ''
        }`;
      }
      this.setPlace(searchString);
    }

    if (filters.socialNetwork) {
      this.setSocialNetwork(filters.socialNetwork);
    }

    if (filters.tag) {
      this.setTag(filters.tag);
    }
  };

  setTag = tag => {
    const { fetchTags, onChange } = this.props;
    const showCanParticipateOnly = true;
    socialAccounts.searchTag(tag, showCanParticipateOnly).then(response => {
      const { data } = response;
      const tagCollection = get(response, 'data.data', []);
      return onChange('tags', [tagCollection[0]]);
    });
  };

  setSocialNetwork = socialNetwork => {
    const { onChange } = this.props;
    return onChange('type', socialNetwork);
  };

  setPlace = searchString => {
    const { fetchPlaces, onChange } = this.props;
    fetchPlaces({ q: searchString })
      .then(response => {
        if (response.data.length > 0) {
          const place = response.data[0];
          const aux = { country: [], region: [], city: [] };
          aux[place.type].push(place.id);
          return onChange(aux, null);
        }
      })
      .catch(params => {});
  };

  getUrlParameters = params => {
    let searchFilter = this.props.location.search.substring(1);
    let filters = {};

    searchFilter = searchFilter.split('&');
    searchFilter.map(currentFilter => {
      const values = currentFilter.split('=');
      if (values[1]) {
        filters[values[0]] = values[1];
      }
    });
    return filters;
  };

  handleOnChange = (term, value) => {
    const { filters, onChange } = this.props;
    if (term === 'type') {
      const types = without(value.split(','), 'instagram', 'facebook');
      const newFilters = Object.keys(filters).reduce((newf, filterKey) => {
        if (filterKey.includes('audience'))
          return { ...newf, [filterKey]: null };
        return newf;
      }, {});
      this.setState({ hasAceptableTypes: types.length === 0 });
      return types.length === 0
        ? onChange(term, value)
        : onChange({ ...newFilters, type: value });
    }

    return onChange(term, value);
  };

  toggleAudienceFilters = () => {
    this.setState({
      openAudienceFilters: !this.state.openAudienceFilters,
      openInfluencerFilters: false
    });
  };

  toggleInfluencerFilters = () => {
    this.setState({
      openInfluencerFilters: !this.state.openInfluencerFilters,
      openAudienceFilters: false
    });
  };

  render() {
    const { hasAceptableTypes, openAudienceFilters, openInfluencerFilters } =
      this.state;
    const {
      filters,
      showBlockedOption,
      showFavoriteOption,
      availableSocialNetworks
    } = this.props;

    const sn = [];
    availableSocialNetworks.map(current => sn.push(current[0]));

    return (
      <div className='padding-bottom-1x'>
        <div className='flex padding-1x between-xs'>
          <h4 className='flex vf-text-gray-dark'>
            {i18n.get('ADV_INFLUENCERS_SEARCH_FILTERS_LABEL')}
          </h4>
        </div>

        <InfluencerFilters
          filters={filters}
          onChange={this.handleOnChange}
          availableSocialNetworks={availableSocialNetworks}
          showBlockedOption={showBlockedOption}
          isOpened={openInfluencerFilters}
          onRequestToggleFilters={this.toggleInfluencerFilters}
          showFavoriteOption={showFavoriteOption}
        />

        <AudienceFilters
          hasAceptableTypes={hasAceptableTypes}
          filters={filters}
          isOpened={openAudienceFilters}
          onChange={this.handleOnChange}
          onRequestToggleFilters={this.toggleAudienceFilters}
          availableSocialNetworks={sn}
        />
      </div>
    );
  }
}

InfluencerFilterList.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  availableSocialNetworks: PropTypes.array.isRequired,
  showBlockedOption: PropTypes.bool,
  showFavoriteOption: PropTypes.bool
};

InfluencerFilterList.defaultProps = {
  showBlockedOption: false,
  showFavoriteOption: false
};

const mapStateToProps = state => ({
  places: get(state, 'entities.Place', {})
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPlaces: placeActions.fetchPlaces,
      fetchTags: searchTag
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfluencerFilterList)
);
