"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const WizardItem = props => {
    const { step, currentStep, className, title, text } = props;
    let classes = 'vf-wizard-item ';
    if (step < currentStep)
        classes += ' done';
    if (step === currentStep)
        classes += ' active';
    if (step > currentStep)
        classes += ' inactive';
    if (className)
        classes += ` ${className}`;
    const isDone = step < currentStep;
    return (React.createElement("div", { className: classes },
        React.createElement("div", { className: 'vf-wizard-item-step' }, isDone ? React.createElement(ui_library_1.Icon, { icon: 'checkmark' }) : step),
        React.createElement("div", { className: 'vf-wizard-item-title' }, title),
        text && React.createElement("div", { className: 'vf-wizard-item-text' }, text)));
};
exports.default = WizardItem;
