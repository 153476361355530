"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const styles = require("./SearchInput.less");
const SearchInput = React.forwardRef((props, ref) => {
    const { onChange, placeholder, className, style } = props, other = __rest(props, ["onChange", "placeholder", "className", "style"]);
    const wrapperClass = classnames_1.default(className, styles.wrapper, 'flex');
    return (React.createElement("div", { className: wrapperClass, style: style },
        React.createElement("button", { type: 'submit' },
            React.createElement("i", { className: 'vf-icon icon-search' })),
        React.createElement(components_1.DebounceInput, Object.assign({}, other, { value: '', type: 'search', name: 'search', autoComplete: 'off', onChange: onChange, placeholder: placeholder || i18n.get('SEARCH_PLACEHOLDER'), className: styles.input, forwardedRef: ref }))));
});
exports.default = SearchInput;
