"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const classnames_1 = require("classnames");
const styles = require("./InfoCard.less");
const Img_1 = require("apps/shared/components/Img");
const InfoCard = React.memo(({ earning, className }) => (React.createElement("div", { className: classnames_1.default('flex vf-box-shadow', className), style: { padding: '1rem', marginTop: '2rem', borderRadius: '4px' } },
    React.createElement(Img_1.default, { src: earning.campaignLogo, width: 40, height: 40, className: styles.avatar }),
    React.createElement("div", { style: { flexGrow: 1 } },
        React.createElement("p", { className: 'vf-bold' }, earning.campaignName),
        React.createElement("div", { className: 'flex' },
            React.createElement("p", null, earning.brandName),
            React.createElement("span", { className: 'vf-bold', style: { marginLeft: 'auto' } }, i18n.getMoney({
                amount: earning.amount,
                currency: earning.currency
            })))))));
exports.default = InfoCard;
