import is from 'is_js';

const versions = {
  chrome: '>=63',
  firefox: '>=58',
  safari: '>=11.1'
};

export default function currentBrowserIsSupported() {
  if (isElectron()) return true;
  if (is.desktop()) return validateDesktop();
  if (is.android()) return validateAndroid();
  if (is.ios()) return validateIOS();

  return false;
}

function isElectron() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('electron/');
}

function validateDesktop() {
  if (is.ie()) return false;

  return is.chrome(versions.chrome) ||
         is.firefox(versions.firefox) ||
         is.safari(versions.safari) ||
         is.opera() ||
         is.edge();
}

function validateAndroid() {
  return is.chrome(versions.chrome) ||
         is.firefox();
}

function validateIOS() {
  if (isIOSWebView()) return true;

  return is.chrome(versions.chrome) ||
         is.firefox() ||
         is.safari(versions.safari);
}

function isIOSWebView() {
  return !is.chrome() && !is.firefox() && !is.safari();
}
