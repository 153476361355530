import React from 'react';
import { Chart } from 'chart.js';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { get, cloneDeep } from 'lodash';

import {
  DEFAULT_BACKGROUND_COLOR,
  FLOATING_LABEL_FONT_SIZE
} from './charts-config';

const defaultChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  tooltips: { enabled: false },

  scales: {
    xAxes: [
      {
        gridLines: { display: false },
        ticks: { display: true, min: 0, autoSkip: false }
      }
    ],
    yAxes: [
      {
        gridLines: { display: true, drawBorder: false, color: '#ffffff' },
        ticks: { display: false, min: 0 }
      }
    ]
  },

  hover: { animationDuration: 0 },
  animation: {
    duration: 1,
    onComplete(options) {
      const chartInstance = this.chart;
      const { ctx } = chartInstance;

      ctx.font = Chart.helpers.fontString(
        FLOATING_LABEL_FONT_SIZE,
        Chart.defaults.global.defaultFontStyle,
        Chart.defaults.global.defaultFontFamily
      );

      ctx.fillStyle = options.fontColor || DEFAULT_BACKGROUND_COLOR;

      this.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const data = `${dataset.data[index]}${
            options.isPercentual ? '%' : ''
          }`;
          ctx.fillText(data, bar._model.x - 7, bar._model.y - 5);
        });
      });
    }
  }
};

const VerticalBarChart = ({
  data,
  title,
  yOptions,
  xOptions,
  chartOptions,
  valuesOptions
}) => {
  if (!data) return <noscript />;

  const dataValues = get(data, 'datasets[0].data', []);

  const defaultOptionsCopy = cloneDeep(defaultChartOptions);

  const maxValue = xOptions.maxValue || Math.max(0, ...dataValues);
  const canvasMargin = maxValue * 0.2;

  defaultOptionsCopy.scales.yAxes[0].ticks.max = maxValue + canvasMargin;

  defaultOptionsCopy.scales.xAxes[0] = {
    ...defaultOptionsCopy.scales.xAxes[0],
    ...xOptions
  };

  defaultOptionsCopy.scales.yAxes[0] = {
    ...defaultOptionsCopy.scales.yAxes[0],
    ...yOptions
  };

  const chartHeight = chartOptions.height || 150;

  const options = {
    ...defaultOptionsCopy,
    ...chartOptions,
    animation: {
      ...defaultOptionsCopy.animation,
      onComplete() {
        defaultOptionsCopy.animation.onComplete.call(this, valuesOptions);
      }
    }
  };

  return (
    <div style={{ height: chartHeight }}>
      {title && (
        <h5 className='vf-font-bold' style={{ marginBottom: '1rem' }}>
          {title}
        </h5>
      )}

      <Bar data={data} options={options} height={chartHeight} />
    </div>
  );
};

VerticalBarChart.propTypes = {
  xOptions: PropTypes.object,
  yOptions: PropTypes.object,
  chartOptions: PropTypes.object,
  valuesOptions: PropTypes.object,
  data: PropTypes.object,
  title: PropTypes.string
};

VerticalBarChart.defaultProps = {
  xOptions: {},
  yOptions: {},
  chartOptions: {},
  valuesOptions: {},
  data: {},
  title: ''
};

export default VerticalBarChart;
