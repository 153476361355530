import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';

import {
  participationCancelledByAdvertiserImg,
  participationCancelledByInactivityImg,
  participationRejectedByInfluencerImg
} from 'assets/images';

export default function CampaignCancelled({ invite = {} }) {
  const PARAMS_BY_REASON = {
    unresponsiveInfluencer: {
      title: i18n.get('INF_VOXEAR_CAMPAIGN_CANCELLED_REASONS_UNRESPONSIVE'),
      image: participationCancelledByInactivityImg
    },
    rejectedByInfluencer: {
      title: i18n.get(
        'INF_VOXEAR_CAMPAIGN_CANCELLED_REASONS_REJECTED_BY_INFLUENCER'
      ),
      image: participationRejectedByInfluencerImg
    },
    cancelledByAdvertiser: {
      title: i18n.get(
        'INF_VOXEAR_CAMPAIGN_CANCELLED_REASONS_CANCELLED_BY_ADVERTISER'
      ),
      image: participationCancelledByAdvertiserImg
    },
    noBudget: {
      title: i18n.get(
        'INF_VOXEAR_CAMPAIGN_CANCELLED_REASONS_CANCELLED_BY_NO_BUDGET'
      ),
      image: participationRejectedByInfluencerImg
    },
    default: {
      title: i18n.get('INF_VOXEAR_CAMPAIGN_CANCELLED_REASONS_DEFAULT'),
      image: participationRejectedByInfluencerImg
    }
  };

  const { cancellationReason, advertiserReason } = invite;

  const params =
    PARAMS_BY_REASON[cancellationReason] || PARAMS_BY_REASON.default;

  return (
    <div className='flex center-xs width100' style={{ height: '50rem' }}>
      <div className='col center-xs' style={{ maxWidth: '50rem' }}>
        <img src={params.image} alt='' />

        <h2 className='vf-font-bold margin-top-2x'>{params.title}</h2>

        {!!advertiserReason && (
          <span className='margin-top-Hx'>
            {`${i18n.get(
              'INF_VOXEAR_CAMPAIGN_CANCELLED_REASONS_CANCELLED_BY_ADVERTISER_REASON_PREFIX'
            )} "${advertiserReason}"`}
          </span>
        )}
      </div>
    </div>
  );
}

CampaignCancelled.propTypes = {
  invite: PropTypes.object.isRequired
};
