"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const short_text_1 = require("modules/legacy/js/lib/short-text");
const components_1 = require("modules/shared/components");
const social_avatar_1 = require("modules/legacy/js/components/social-avatar");
const MAX_USERNAME_LENGTH = 20;
const SocialAvatarInfluencer = ({ size, socialAccount, publicationType, className, children }) => {
    const getDisplayName = () => {
        const { _id, username = '', url } = socialAccount;
        let displayName = short_text_1.default(username, MAX_USERNAME_LENGTH);
        if (!_id)
            displayName = '...';
        if (!url)
            return React.createElement("span", { className: 'vf-text-primary' }, displayName);
        return (React.createElement("a", { href: url, "data-uitest": 'sna-username', target: '_blank' }, displayName));
    };
    const getContent = () => {
        const { _id, type, isVerified, accountType, isAuthorized, audience = 0 } = socialAccount;
        const interests = socialAccount.interests || [];
        const isIncomplete = _id && (!interests.length || !accountType);
        let content;
        if (isAuthorized === false) {
            content = i18n.get('INF_SOCIAL_ACCOUNTS_AVATAR_CONTENT_UNAUTHORIZED');
        }
        if (isIncomplete) {
            content = i18n.get('INF_SOCIAL_ACCOUNTS_AVATAR_CONTENT_INCOMPLETE');
        }
        if (children) {
            content = children;
        }
        else {
            content = i18n.get('FOLLOWERS_OR_SUBSCRIBERS', {
                followers: i18n.getNumber(audience, 0),
                type
            });
        }
        if (!_id)
            content = '...';
        return (React.createElement("div", null,
            React.createElement("div", { className: 'flex' },
                getDisplayName(),
                isVerified && React.createElement(components_1.VerificationBadge, { size: 'sm' })),
            React.createElement("div", { className: 'vf-text-gray' }, content)));
    };
    return (React.createElement(social_avatar_1.default, { size: size, className: className, socialAccount: socialAccount, publicationTypes: [publicationType] },
        React.createElement("div", { className: 'flex-column flex-start margin-left-1x' }, getContent())));
};
exports.default = SocialAvatarInfluencer;
