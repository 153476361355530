import axios from 'axios';

export function getParticipationRequests(args) {
  const { id, params } = args;
  return axios.get(`/campaigns/${id}/participation-requests`, { params });
}

export function rejectParticipationRequest(args) {
  const { id, body } = args;
  return axios.post(`/participation-requests/${id}/reject`, { ...body });
}

export function acceptParticipationRequest(args) {
  const { id } = args;
  return axios.post(`/participation-requests/${id}/accept`);
}
