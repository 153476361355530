import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'ui-library';
import classNames from 'classnames';
import { isEmpty, chain, findIndex } from 'lodash';
import FuzzySearch from 'fuse.js';
import i18n from 'i18n';

import ensureChildNodeVisibility from 'utils/ensureChildNodeVisibility';
import preventParentScroll from 'utils/preventParentScroll';

import { SearchInput } from 'apps/shared/components';
import { Pulse } from 'modules/shared/components';

import styles from './ChatsList.less';

const NO_SELECTED_CHAT_ID = '';
const INITIAL_QUERY = '';
const INITIAL_SELECTED_STATUS = 'active';
const LEFT_ARROW_KEY = 'ArrowLeft';
const RIGHT_ARROW_KEY = 'ArrowRight';
const TEXT_TYPE_ELEMENTS = ['textarea', 'input'];

export default class ChatsList extends Component {
  static propTypes = {
    chats: PropTypes.array,
    selectedChatId: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    rowComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
      .isRequired,
    queryFieldPath: PropTypes.string.isRequired,
    style: PropTypes.any
  };

  static defaultProps = {
    chats: [],
    selectedChatId: NO_SELECTED_CHAT_ID,
    style: { height: '100%' }
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedStatus: INITIAL_SELECTED_STATUS,
      query: INITIAL_QUERY,
      filteredChats: props.chats,
      selectedChatIndex: -1,
      shouldHighlightSelector: false
    };
  }

  UNSAFE_componentWillMount() {
    this.filterChats();
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyPress);
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    const didChatsChange =
      this.props.chats !== nextProps.chats && !isEmpty(nextProps.chats);

    const chatIndex = this.findSelectedChatIndex({
      id: nextProps.selectedChatId
    });

    if (this.hasNextChat() && chatIndex < 0) {
      this.onNext();
    }

    if (didChatsChange) {
      this.filterChats(nextProps.chats);
      this.checkSelectedChatStatus(nextProps.chats);
      return;
    }

    const didSelectedChatChange =
      nextProps.selectedChatId !== this.props.selectedChatId;

    if (didSelectedChatChange) {
      this.setState({
        selectedChatIndex: chatIndex
      });
    }
  };

  componentDidUpdate() {
    if (this.selectedRow) this.scrollToShowActiveRow();
  }

  onKeyPress = ev => {
    const isFromTextElement = TEXT_TYPE_ELEMENTS.includes(ev.target.type);
    const doesElementHasValue = !!ev.target.value;

    if (isFromTextElement && doesElementHasValue) return;
    if (ev.key === LEFT_ARROW_KEY && this.hasPreviousChat()) this.onPrevious();
    if (ev.key === RIGHT_ARROW_KEY && this.hasNextChat()) this.onNext();
  };

  onSearch = ev => {
    this.setState(
      {
        query: ev.target.value
      },
      this.filterChats
    );
  };

  onChangeStatus = selectedStatus => {
    this.setState({ selectedStatus }, this.filterChats);
  };

  onNext = () => {
    const { filteredChats, selectedChatIndex } = this.state;
    const nextChatId = filteredChats[selectedChatIndex + 1].id;
    this.setState(
      {
        selectedChatIndex: selectedChatIndex + 1
      },
      () => this.props.onSelect(nextChatId)
    );
  };

  onPrevious = () => {
    const { filteredChats, selectedChatIndex } = this.state;
    const previousChatId = filteredChats[selectedChatIndex - 1].id;
    this.setState(
      {
        selectedChatIndex: selectedChatIndex - 1
      },
      () => this.props.onSelect(previousChatId)
    );
  };

  scrollToShowActiveRow = () => {
    ensureChildNodeVisibility(this.selectedRow, this.container);
  };

  filterChats = (chats = this.props.chats) => {
    const filteredChats = this.getFilterChats(chats);

    return this.setState({
      filteredChats,
      selectedChatIndex: this.findSelectedChatIndex({ chats: filteredChats })
    });
  };

  getFilterChats = chats => {
    const { queryFieldPath } = this.props;
    const { query, selectedStatus } = this.state;
    const options = { keys: [queryFieldPath], threshold: 0.7, tokenize: true };
    const filteredChats = query
      ? new FuzzySearch(chats, options).search(query)
      : chats;

    return filteredChats.filter(chat => {
      const status =
        chat.member.chatStatus === 'pending'
          ? 'active'
          : chat.member.chatStatus;

      const hasValidStatus = status === selectedStatus;
      return hasValidStatus;
    });
  };

  checkSelectedChatStatus = nextChats => {
    const { selectedChatId, onSelect } = this.props;
    const { selectedStatus } = this.state;
    if (!selectedChatId) return;

    const selectedChatStatus = chain(nextChats)
      .find(c => c.id === selectedChatId)
      .get('member.chatStatus')
      .value();

    const comparableStatus =
      selectedChatStatus === 'pending' ? 'active' : selectedChatStatus;

    if (comparableStatus !== selectedStatus) {
      onSelect(NO_SELECTED_CHAT_ID);
      this.setState({ shouldHighlightSelector: true });
    }
  };

  hasNextChat = () =>
    this.state.selectedChatIndex < this.state.filteredChats.length - 1;

  hasPreviousChat = () => this.state.selectedChatIndex > 0;

  findSelectedChatIndex = (args = {}) => {
    const { chats = this.state.filteredChats, id = this.props.selectedChatId } =
      args;

    return findIndex(chats, c => c.id === id);
  };

  render = () => {
    const { onSelect, selectedChatId, style, rowComponent: Chat } = this.props;
    const { filteredChats, selectedStatus, shouldHighlightSelector } =
      this.state;
    const hasPreviousChat = this.hasPreviousChat();
    const hasNextChat = this.hasNextChat();
    const previousChatArrowClassName = classNames(styles.arrow, {
      [styles.disabled]: !hasPreviousChat
    });
    const nextChatArrowClassName = classNames(styles.arrow, {
      [styles.disabled]: !hasNextChat
    });

    return (
      <div className='col width100' style={style}>
        <div className={classNames('flex center middle-xs', styles.filters)}>
          <Pulse
            className='width100'
            show={shouldHighlightSelector}
            onPulseFinished={() =>
              this.setState({ shouldHighlightSelector: false })
            }
          >
            <div style={{ maxWidth: '60%' }}>
              <Select
                title=''
                buttonClassname='width100 flex between-xs'
                value={selectedStatus}
                onChange={ev => this.onChangeStatus(ev.target.value)}
                options={{
                  active: i18n.get('CHATS_FILTER_ACTIVE'),
                  archived: i18n.get('CHATS_FILTER_ARCHIVED'),
                  blocked: i18n.get('CHATS_FILTER_BLOCKED')
                }}
              />
            </div>
          </Pulse>
          <div className='flex'>
            <span
              className={previousChatArrowClassName}
              onClick={hasPreviousChat ? this.onPrevious : null}
            >
              <i className='vf-icon icon-arrow-left' />
            </span>
            <span
              className={nextChatArrowClassName}
              onClick={hasNextChat ? this.onNext : null}
            >
              <i className='vf-icon icon-arrow-right' />
            </span>
          </div>
        </div>
        <div className={styles['chats-container']}>
          <div className='padding-horizontal-1x margin-top-1x'>
            <SearchInput onChange={this.onSearch} />
          </div>
          <div
            className={classNames('vf-scrolly margin-top-1x', styles.chats)}
            ref={r => (this.container = r)}
            onWheel={ev => preventParentScroll(ev, this.container)}
          >
            {filteredChats.map(chat => {
              const isSelected = selectedChatId === chat.id;
              return (
                <div
                  key={chat.id}
                  ref={r => (isSelected ? (this.selectedRow = r) : undefined)}
                >
                  <Chat
                    chat={chat}
                    onSelect={() => onSelect(chat.id)}
                    isSelected={isSelected}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
}
