"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const react_router_dom_1 = require("react-router-dom");
const images_1 = require("assets/images");
const styles = require("./HeroSections.less");
const containers_1 = require("modules/shared/containers");
const HeroSections = ({ body, title, linkTo, bottom, children, buttonText, description, buttonAction, buttonPadding }) => {
    const buttonStyle = classnames_1.default(`vf-btn outline vf-btn-primary vf-btn--sm ${styles.link}`, { [styles['link--padding-x']]: buttonPadding });
    return (React.createElement("div", { className: styles.container },
        React.createElement("header", { className: `col-xs-12 ${styles.header}` },
            React.createElement("div", { className: 'flex between-xs' },
                React.createElement("img", { src: images_1.logoVFV2, alt: 'Logo VoxFeed', className: styles.logo }),
                linkTo && (React.createElement(react_router_dom_1.Link, { to: linkTo, className: buttonStyle }, buttonText)),
                buttonAction && (React.createElement(ui_library_1.Button, { color: 'primary', onClick: buttonAction, className: buttonStyle }, buttonText)))),
        React.createElement("main", { className: classnames_1.default(`vf-row no-space ${styles.main}`, {
                [styles['main--bottom']]: bottom,
                [styles['main--body']]: body
            }) },
            React.createElement("div", { className: `col-md-6 ${styles['left-section']}` }, body ? (React.createElement(React.Fragment, null, body)) : (React.createElement(React.Fragment, null,
                React.createElement("h1", null, title),
                description && React.createElement("p", null, description)))),
            React.createElement("div", { className: `col-md-6 col-xs-12 ${styles['right-section']}` }, children)),
        bottom,
        React.createElement("footer", { className: styles.footer },
            React.createElement(containers_1.SupportLinks, { noPadding: true }))));
};
exports.default = HeroSections;
