"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles = require("./StepTwo.less");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const ui_library_1 = require("ui-library");
const components_1 = require("apps/shared/components");
const StepTwo = ({ onChangeStep, isLoading, credit, selectBrand, remainingCredit, onSave }) => {
    const handleChangeStep = () => {
        onChangeStep('1');
    };
    return (React.createElement("div", { className: 'vf-bg-white-color', style: { padding: '2rem' } },
        React.createElement("h3", { className: 'vf-bold' }, i18n.get('ADV_BRANDS_TRANSFER_CREDIT_TO_BRAND', {
            credit: i18n.getMoney({
                amount: credit,
                currency: selectBrand.currency
            })
        })),
        React.createElement(BrandBox, { brand: selectBrand }),
        React.createElement("p", { className: 'margin-top-1x vf-text-gray-dark vf-bold end-xs' }, i18n.get('ADV_BRANDS_REMAINING_CREDIT', {
            credit: i18n.getMoney({
                amount: remainingCredit,
                currency: selectBrand.currency
            }),
            brand: selectBrand.name
        })),
        React.createElement("div", { className: 'flex end-xs margin-top-3x' },
            React.createElement(ui_library_1.Button, { color: 'flat', onClick: handleChangeStep }, i18n.get('GO_BACK')),
            React.createElement(ui_library_1.Button, { color: 'primary', disabled: isLoading, onClick: onSave }, i18n.get('ADV_BRANDS_TRANSFER_WITH_CREDIT', {
                credit: i18n.getMoney({
                    amount: credit,
                    currency: selectBrand.currency
                })
            })))));
};
exports.default = StepTwo;
const BrandBox = ({ brand }) => (React.createElement("div", { className: classnames_1.default('flex margin-top-1x', styles['brand-box']) },
    React.createElement(components_1.BrandAvatar, { brandName: brand.name, imageURL: brand.image, width: 24, height: 24 }),
    React.createElement("p", { className: 'vf-bold margin-left-Hx' }, brand.name)));
