"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const images_1 = require("assets/images");
const styles = require("./SocialNetworkCard.less");
const Avatar_1 = require("apps/shared/components/Avatar/Avatar");
const SocialBadge_1 = require("apps/shared/components/SocialBadge/SocialBadge");
const SocialNetworkCard = ({ network, modal = false, isStory = false, showButton = true, showParticipacionModal, isNoActiveParticipationIG = null }) => (React.createElement("div", { key: network.id, className: styles.networkContainer },
    React.createElement("div", { className: classnames_1.default({ [styles.avatar]: modal }) },
        React.createElement(Avatar_1.default, { image: network.userPhoto || images_1.defaultAvatarImg, size: 'xmd' }),
        React.createElement(SocialBadge_1.default, { size: 14, socialNetwork: network.socialNetwork, style: modal ? { margin: '-4rem 0 3rem 5.3rem' } : { marginLeft: '-3.2rem' } })),
    React.createElement("div", { className: classnames_1.default(styles.containerInfo, { [styles.modal]: modal }) },
        React.createElement("div", null,
            React.createElement("div", { className: styles.userName }, network.username),
            React.createElement("div", { className: styles.follows }, i18n.get('FOLLOWERS_OR_SUBSCRIBERS', {
                followers: Number(network.followers).toLocaleString(),
                type: network.socialNetwork
            }))),
        showButton && (React.createElement(ui_library_1.Button, { color: 'primary', className: styles.button, onClick: () => showParticipacionModal(network, isStory, isNoActiveParticipationIG) }, !isStory
            ? i18n.get('INF_LITE_ACCOUNTS_POST_BUTTON')
            : i18n.get('INF_LITE_ACCOUNTS_STORY_BUTTON'))))));
exports.default = SocialNetworkCard;
