"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const AccountSettings_1 = require("modules/shared/screens/AccountSettings");
const settings_about_1 = require("modules/legacy/js/views/settings-about");
const settings_money_1 = require("modules/legacy/js/views/settings-money");
const store_conector_1 = require("modules/legacy/js/factories/store-conector");
const SSettingsAbout = store_conector_1.default(settings_about_1.default);
const SSettingsMoney = store_conector_1.default(settings_money_1.default);
const SettingsRoutes = props => (React.createElement("div", null,
    React.createElement(react_router_dom_1.Route, { path: '/settings/account', component: AccountSettings_1.default }),
    React.createElement(react_router_dom_1.Route, { path: '/settings/about', component: SSettingsAbout }),
    React.createElement(react_router_dom_1.Route, { path: '/settings/money', component: SSettingsMoney })));
exports.default = SettingsRoutes;
