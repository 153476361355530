import axios from 'axios';

export * from './shared';
export * from './advertisers';
export * from './activityStats';

export function fetchInfluencerPosts({ influencerId, params }) {
  return axios.get(`/influencers/${influencerId}/promoted_messages`, {
    params
  });
}

export function fetchInfluencerPostById({ influencerId, postId }) {
  return axios.get(
    `/influencers/${influencerId}/promoted_messages?_id=${postId}`
  );
}

export function fetchInfluencerPayments({ influencerId, params }) {
  return axios.get(`/accounts/${influencerId}/payments_out`, { params });
}

export function fetchInfluencerEarnings({ influencerId }) {
  return axios.get(`/influencers/${influencerId}/earnings`);
}

export function fetchPaymentsOutRecepit({ id }) {
  return axios.get(`/payments_out/${id}/receipt`);
}

export function fetchInfluencerActiveInvites(
  { influencerId, ...params },
  cancelToken
) {
  return axios.get(`/influencers/${influencerId}/invites/active`, {
    params,
    cancelToken
  });
}

export function fetchInfluencerPendingInvites(
  { influencerId, ...params },
  cancelToken
) {
  return axios.get(`/influencers/${influencerId}/invites/pending-payment`, {
    params,
    cancelToken
  });
}

export function fetchInfluencerInvitesHistory(
  { influencerId, ...params },
  cancelToken
) {
  return axios.get(`/influencers/${influencerId}/invites/history`, {
    params,
    cancelToken
  });
}
