import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import { getMinInvestment } from 'config/app';
import i18n from 'i18n';

const MIN_INVESTMENT = getMinInvestment();

export const CreditSection = ({ onNext, onBack }) => {
  const minInvestment = i18n.getMoney({ amount: MIN_INVESTMENT });
  return (
    <div className='col center-xs'>
      <h4 className='vf-font-thin'>
        {i18n.get(
          'ADV_TOURS_BRAND_BACK_CREDIT_SECTION_DESCRIPTION_CREDIT_AVAILABLE'
        )}

        <p className='margin-top-Hx'>
          {i18n.get(
            'ADV_TOURS_BRAND_BACK_CREDIT_SECTION_DESCRIPTION_MIN_DEPOSIT',
            { minInvestment }
          )}
        </p>
      </h4>

      <div className='flex center-xs margin-vertical-1x'>
        <Button size='sm' className='margin-right-Hx' onClick={onBack}>
          {i18n.get('ADV_TOURS_BRAND_BACK_BUTTON')}
        </Button>

        <Button size='sm' color='success' onClick={onNext}>
          {i18n.get('ADV_TOURS_BRAND_NEXT_BUTTON')}
        </Button>
      </div>
    </div>
  );
};

CreditSection.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func
};
