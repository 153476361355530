"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProList = exports.LiteList = void 0;
exports.LiteList = [
    'REGISTER_CONTENT_CREATORS_LITE_ITEM',
    'REGISTER_STATISTICS_LITE_ITEM',
    'REGISTER_CONTENT_LITE_ITEM',
    'REGISTER_COST_PER_INTERACTION_LITE_ITEM',
    'REGISTER_CONTENT_CREATORS_PAYMENT_LITE_ITEM'
];
exports.ProList = [
    'REGISTER_CONTENT_CREATORS_PRO_ITEM',
    'REGISTER_STATISTICS_PRO_ITEM',
    'REGISTER_CONTENT_PRO_ITEM',
    'REGISTER_COST_PER_INTERACTION_PRO_ITEM',
    'REGISTER_CONTENT_CREATORS_PAYMENT_PRO_ITEM'
];
