import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { without, get } from 'lodash';
import { getSocialAccountTypes } from 'modules/shared/selectors';
import { connect } from 'react-redux';
import { FilterHeaderTags } from '../components';

// REVIEW: NOT DRY
const TYPE = 'type';
const MIN_ENGAGEMENT_RATE = 'engagementRate>';
const MAX_ENGAGEMENT_RATE = 'engagementRate<';
const MIN_CPM = 'cpm>';
const MAX_CPM = 'cpm<';
const MIN_CPE = 'cpe>';
const MAX_CPE = 'cpe<';
const MIN_SUGGESTED_POST_PRICE = 'postPrice.suggested>';
const MAX_SUGGESTED_POST_PRICE = 'postPrice.suggested<';
const MIN_RATING = 'rating>';
const TAGS = 'tags';
const MIN_AUDIENCE = 'reach>';
const MAX_AUDIENCE = 'reach<';
const MIN_EXPECTED_ENGAGEMENTS = 'expectedEngagements>';
const MAX_EXPECTED_ENGAGEMENTS = 'expectedEngagements<';
const ACCOUNT_TYPE = 'accountType';
const MIN_AGE = 'minAge';
const MAX_AGE = 'maxAge';
const GENDER = 'gender';
const FAVORITE = 'favorite';
const SHOW_BLOCKED = 'showBlocked';
const VERIFIED = 'isVerified';

class InfluencerFilterHeader extends React.Component {
  getInfluencerTags = () => {
    const { filters, genderOptions, accountTypes } = this.props;
    return [
      {
        keys: [MIN_ENGAGEMENT_RATE, MAX_ENGAGEMENT_RATE],
        value: this.minMaxTagValue(
          MIN_ENGAGEMENT_RATE,
          MAX_ENGAGEMENT_RATE,
          'ENGAGEMENT_RATE'
        )
      },
      {
        keys: [MIN_CPM, MAX_CPM],
        value: this.minMaxTagValue(MIN_CPM, MAX_CPM, 'CPM')
      },
      {
        keys: [MIN_CPE, MAX_CPE],
        value: this.minMaxTagValue(MIN_CPE, MAX_CPE, 'CPE')
      },
      {
        keys: [MIN_SUGGESTED_POST_PRICE, MAX_SUGGESTED_POST_PRICE],
        value: this.minMaxTagValue(
          MIN_SUGGESTED_POST_PRICE,
          MAX_SUGGESTED_POST_PRICE,
          'SUGGESTED_POST_PRICE'
        )
      },
      {
        keys: [MIN_EXPECTED_ENGAGEMENTS, MAX_EXPECTED_ENGAGEMENTS],
        value: this.minMaxTagValue(
          MIN_EXPECTED_ENGAGEMENTS,
          MAX_EXPECTED_ENGAGEMENTS,
          'EXPECTED_ENGAGEMENTS'
        )
      },
      {
        keys: [MIN_AUDIENCE, MAX_AUDIENCE],
        value: this.minMaxTagValue(MIN_AUDIENCE, MAX_AUDIENCE, 'FOLLOWERS')
      },
      {
        keys: [MIN_AGE, MAX_AGE],
        value: this.minMaxTagValue(MIN_AGE, MAX_AGE, 'AGE')
      },
      {
        keys: [TYPE],
        value: this.hasTypeChanged()
          ? get(filters, TYPE, '')
              .replace('none', '')
              .split(',')
              .join(` ${i18n.get('AND')} `)
          : null
      },
      {
        keys: [MIN_RATING],
        value: filters[MIN_RATING]
          ? i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_RATING_APPLIED_LABEL', {
              rating: filters[MIN_RATING]
            })
          : null
      },
      {
        keys: [TAGS],
        value: (filters.tags || []).map(t => t.name).join(` ${i18n.get('OR')} `)
      },
      {
        keys: [ACCOUNT_TYPE],
        value: (
          accountTypes.find(type => type.id === filters.accountType) || {}
        ).name
      },
      {
        keys: [GENDER],
        value: genderOptions[filters.gender]
      },
      {
        keys: [FAVORITE],
        value: filters[FAVORITE]
          ? i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_SHOW_FAVORITE_CHECKBOX')
          : null
      },
      {
        keys: [SHOW_BLOCKED],
        value: filters[SHOW_BLOCKED]
          ? i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_SHOW_HIDDEN_CHECKBOX')
          : null
      },
      {
        keys: [VERIFIED],
        value: filters[VERIFIED]
          ? i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_SHOW_VERIFIED_CHECKBOX')
          : null
      },
      ...this.getLocationTags()
    ];
  };

  getLocationTags = () => {
    const { places, selectedLocations } = this.props;
    const tags = [];

    Object.keys(selectedLocations).forEach(locationType => {
      selectedLocations[locationType].forEach(locationId => {
        if (places[locationId]) {
          tags.push({
            keys: ['location', locationType, locationId],
            value: `${i18n.get('IN')} ${places[locationId].name}${
              places[locationId].country
                ? `, ${places[locationId].country.name}`
                : ''
            }`
          });
        }
      });
    });
    return tags;
  };

  hasTypeChanged = () => {
    const { availableSocialNetworks, filters } = this.props;
    return (
      availableSocialNetworks.length !==
      get(filters, TYPE, '').replace('none', '').split(',').length
    );
  };

  minMaxTagValue = (minKey, maxKey, i18nKey) => {
    const { filters } = this.props;
    let value = '';
    if (filters[minKey])
      value = i18n.get(`ADV_INFLUENCERS_SEARCH_FILTERS_${i18nKey}_TAG_MIN`, {
        min: filters[minKey]
      });
    if (filters[maxKey])
      value = i18n.get(`ADV_INFLUENCERS_SEARCH_FILTERS_${i18nKey}_TAG_MAX`, {
        max: filters[maxKey]
      });
    if (filters[maxKey] && filters[minKey])
      value = i18n.get(`ADV_INFLUENCERS_SEARCH_FILTERS_${i18nKey}_TAG_MINMAX`, {
        max: filters[maxKey],
        min: filters[minKey]
      });
    return value;
  };

  handleRemoveFilter = tagFilterKeys => {
    const { onChange, filters } = this.props;
    const tagFilterKey = tagFilterKeys[0];

    if (tagFilterKey === 'type') return this.removeTypeFilter();
    if (tagFilterKey === 'location')
      return this.removeLocationFilter([tagFilterKeys[1], tagFilterKeys[2]]);
    if (tagFilterKeys.length > 1) return this.removeMaxMinFilter(tagFilterKeys);
    if (tagFilterKey === ACCOUNT_TYPE && filters[ACCOUNT_TYPE] === 'person') {
      return onChange({
        gender: null,
        accountType: null,
        minAge: null,
        maxAge: null
      });
    }

    return onChange(tagFilterKey, null);
  };

  removeTypeFilter = () => {
    const { onChange, availableSocialNetworks } = this.props;
    onChange('type', availableSocialNetworks.join(','));
  };

  removeLocationFilter = ([locationType, loctionId]) => {
    const { onChange, filters } = this.props;
    const newLocations = without(filters[locationType], loctionId);
    return onChange(locationType, newLocations);
  };

  removeMaxMinFilter = minMaxFilterKeys => {
    const { onChange } = this.props;
    const newFilters = minMaxFilterKeys.reduce(
      (p, c) => ({ ...p, [c]: null }),
      {}
    );
    return onChange(newFilters);
  };

  handleReset = event => {
    const { filters, onChange, availableSocialNetworks } = this.props;
    event.stopPropagation();
    const newFilters = Object.keys(filters).reduce((newf, filterKey) => {
      if (filterKey === TYPE)
        return { ...newf, [filterKey]: availableSocialNetworks.join(',') };
      if (!filterKey.includes('audience'))
        return { ...newf, [filterKey]: null };
      return newf;
    }, {});

    onChange(newFilters);
  };

  render() {
    const { isOpened, onRequestToggleFilters, children } = this.props;
    return (
      <FilterHeaderTags
        title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_BY_INFLUENCER')}
        tags={this.getInfluencerTags().filter(tag => tag.value)}
        isOpened={isOpened}
        onRequestToggleFilters={onRequestToggleFilters}
        onRequestRemoveTag={this.handleRemoveFilter}
        onRequestReset={this.handleReset}
        tooltipMessage={i18n.get(
          'ADV_INFLUENCERS_SEARCH_FILTER_INFLUENCER_TOOLTIP'
        )}
      >
        {children}
      </FilterHeaderTags>
    );
  }
}

InfluencerFilterHeader.propTypes = {
  filters: PropTypes.object.isRequired,
  availableSocialNetworks: PropTypes.array.isRequired,
  selectedLocations: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  genderOptions: PropTypes.object.isRequired,
  places: PropTypes.object,
  accountTypes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRequestToggleFilters: PropTypes.func.isRequired,
  isOpened: PropTypes.bool
};

InfluencerFilterHeader.defaultProps = {
  isOpened: false,
  places: {}
};

const mapStateToProps = state => ({
  places: state.entities.Place,
  accountTypes: getSocialAccountTypes(state)
});
export default connect(mapStateToProps)(InfluencerFilterHeader);
