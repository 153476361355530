import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import i18n from 'i18n';

import { ImagesGrid } from 'apps/shared/controllers';
import Avatar from 'apps/shared/components/Avatar/Avatar';
import { TextWithHighlightedElements } from 'modules/shared/components';

import styles from './ChatBubble.less';

import { defaultAvatarImg } from 'assets/images';

export default function ChatBubble(props) {
  const {
    text,
    attachments,
    position,
    date,
    user,
    className,
    showTail,
    username,
    showEmail,
    animate,
    header,
    ...other
  } = props;

  const containerClassNames = classNames('flex width100', className, {
    'start-xs': position === 'left',
    'end-xs': position === 'right'
  });

  const messageClassNames = classNames({
    [styles.left]: position === 'left',
    [styles.right]: position === 'right',
    [styles.animate]: !!animate,
    [styles['with-images']]: !!attachments
  });

  const tailClassNames = classNames({
    [styles['tail-left']]: position === 'left',
    [styles['tail-right']]: position === 'right',
    [styles.animate]: !!animate
  });

  return (
    <div className={containerClassNames} key={text}>
      <div className={messageClassNames} {...other}>
        {header && header}
        {!!user && (
          <UserInfo user={user} username={username} showEmail={showEmail} />
        )}

        <div className='col'>
          {attachments && (
            <div className='flex margin-bottom-Hx'>
              <ImagesGrid urls={attachments.map(a => a.url)} />
            </div>
          )}

          <TextWithHighlightedElements text={text} />
        </div>

        {!!date && <DateInfo date={date} />}

        {showTail && <div className={tailClassNames} />}
      </div>
    </div>
  );
}

ChatBubble.propTypes = {
  text: PropTypes.string,
  attachments: PropTypes.array,
  position: PropTypes.oneOf(['left', 'right']),
  date: PropTypes.string.isRequired,
  user: PropTypes.object,
  className: PropTypes.string,
  showTail: PropTypes.bool,
  username: PropTypes.string,
  showEmail: PropTypes.bool,
  animate: PropTypes.bool,
  header: PropTypes.node
};

ChatBubble.defaultProps = {
  text: '',
  position: 'right',
  showTail: true,
  showEmail: false,
  animate: false,
  header: null,
  user: null,
  className: '',
  attachments: null,
  username: ''
};

function UserInfo({ user, username, showEmail }) {
  const usernameClassName = classNames(
    'vf-font-bold width100 ellipsis',
    styles.username
  );
  const emailClassName = classNames('width100 ellipsis', styles.email);

  return (
    <div className='vf-row' style={{ margin: '0 0 0.75rem 0' }}>
      <div className='no-padding flex middle-xs start-xs'>
        <Avatar
          image={user.profileImage || defaultAvatarImg}
          width={28}
          height={28}
        />
      </div>

      <div
        className='col col-xs-9 top-xs'
        style={{ paddingLeft: '1rem', paddingRight: 0 }}
      >
        <span
          className={usernameClassName}
          title={username || user.name}
          style={{ cursor: 'default' }}
        >
          {username || user.name}
        </span>

        {showEmail && !!user.email && (
          <a href={`mailto:${user.email}`} className={emailClassName}>
            {user.email}
          </a>
        )}
      </div>
    </div>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  username: PropTypes.string,
  showEmail: PropTypes.bool
};

UserInfo.defaultProps = {
  showEmail: false,
  username: ''
};

function DateInfo({ date }) {
  const dateClassNames = classNames(
    'width100 flex end-xs vf-text-gray',
    styles.date
  );
  const didMessageWasWrittenToday =
    !!date && moment().isSame(moment(date), 'day');

  return (
    <div className={dateClassNames}>
      {didMessageWasWrittenToday && <span>{moment(date).format('HH:mm')}</span>}

      {!didMessageWasWrittenToday && (
        <span>
          {i18n.get('CHAT_MESSAGE_DATE_TIME', {
            date: i18n.getDate(new Date(date), 'medium'),
            time: moment(date).format('HH:mm')
          })}
        </span>
      )}
    </div>
  );
}

DateInfo.propTypes = {
  date: PropTypes.string.isRequired
};
