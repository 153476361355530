"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Step = void 0;
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./Stepper.less");
class Stepper extends React.Component {
    constructor() {
        super(...arguments);
        this.handleChange = event => {
            const { onStepChange } = this.props;
            const step = event.target.getAttribute('data-step');
            onStepChange(step);
        };
    }
    render() {
        const { steps, activeStep, className, style } = this.props;
        return (React.createElement("div", { className: classnames_1.default('flex', className), style: style }, steps.map((s, i) => (React.createElement(exports.Step, { disabled: true, key: s, step: i + 1, size: 30, isActive: activeStep === s, onClick: this.handleChange })))));
    }
}
exports.default = Stepper;
exports.Step = ({ step, size, style, isActive, disabled, className, onClick }) => (React.createElement("div", { "data-step": step, className: classnames_1.default(styles.step, className, {
        [styles.active]: isActive,
        [styles.disabled]: disabled
    }), style: Object.assign({ width: size, height: size }, style), onClick: disabled ? null : onClick }, step));
