"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const ui_library_1 = require("ui-library");
const brands_1 = require("redux/modules/brands");
const app_1 = require("redux/modules/app");
const selectors_1 = require("modules/shared/selectors");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const components_1 = require("modules/shared/components");
const BrandTranferCredit_1 = require("./BrandTranferCredit");
const BrandDeleteConfirmation = props => {
    const [password, setPassword] = React.useState('');
    const [brandToTransfer, setBrandToTransfer] = React.useState('');
    const { brand, hideModal, request, isOpen, onClose } = props;
    const isLoading = request.status === 'loading';
    const onSuccess = () => {
        const { showAlert } = props;
        hideModal();
        showAlert({
            type: 'success',
            message: i18n.get('ADV_BRAND_DELETE_CONFIRMATION_SUCCESS')
        });
        window.location.href = `/brands`;
    };
    const onTransferSuccess = () => {
        const { showAlert } = props;
        showAlert({
            type: 'success',
            message: i18n.get('ADV_BRANDS_TRANSFER_CREDIT_TO_BRAND_SUCCESS')
        });
    };
    const onFail = () => {
        const { showAlert } = props;
        showAlert({
            type: 'error',
            time: 8000,
            message: request.response.message
        });
    };
    const deleteBrand = () => {
        const { brandId, deleteBrand } = props;
        deleteBrand({ brandId, password }).then(onSuccess).catch(onFail);
    };
    const transferCredit = () => __awaiter(void 0, void 0, void 0, function* () {
        const { brand, transferCredit } = props;
        const params = {
            amount: Number(brand.balance),
            destinationBrandId: brandToTransfer,
            id: brand._id
        };
        try {
            yield transferCredit(params);
            onTransferSuccess();
            deleteBrand();
        }
        catch (_) {
            onFail();
        }
    });
    const onClickDeleteBrand = () => {
        if (brandToTransfer)
            transferCredit();
        else
            deleteBrand();
    };
    const content = (React.createElement("div", { className: 'padding-1x' },
        React.createElement("div", { className: 'flex width100 center-xs' },
            React.createElement(components_1.BrandAvatar, { url: brand.image, name: brand.name })),
        React.createElement("h5", { className: 'margin-top-2x margin-bottom-2x vf-font-normal' }, i18n.getHTML('ADV_BRAND_DELETE_CONFIRMATION_BODY', {
            brandName: brand.name
        })),
        brand.balance > 0 && (React.createElement(BrandTranferCredit_1.default, { brand: brand, onChangeBrand: setBrandToTransfer })),
        React.createElement("div", { className: 'flex width100 end-xs margin-top-2x' },
            React.createElement(ui_library_1.Input, { type: 'password', value: password, placeholder: i18n.get('ADV_BRAND_DELETE_CONFIRMATION_PASSWORD_INPUT_PLACEHOLDER'), onChange: ev => setPassword(ev.target.value) }),
            React.createElement(ui_library_1.Button, { color: 'danger', disabled: !password || isLoading, onClick: onClickDeleteBrand }, i18n.get('ADV_BRAND_DELETE_CONFIRMATION_DELETE_BUTTON')))));
    return (React.createElement(CustomDialog_1.default, { isOpen: isOpen, size: 'sm', dismissable: false, header: i18n.get('ADV_BRAND_DELETE_CONFIRMATION_TITLE'), content: content, onClose: onClose || hideModal }));
};
const mapStateToProps = (state, { brandId }) => {
    return {
        brand: selectors_1.getBrand(state, brandId),
        request: selectors_1.getRequest(state, 'deleteBrand')
    };
};
const mapDispatchToProps = dispatch => {
    return redux_1.bindActionCreators({
        deleteBrand: brands_1.deleteBrand,
        hideModal: app_1.hideModal,
        resetRequest: app_1.resetRequest,
        showAlert: app_1.showAlert,
        transferCredit: brands_1.transferCredit
    }, dispatch);
};
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(BrandDeleteConfirmation);
