"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const React = require("react");
const app_1 = require("app");
const Alert_1 = require("../../Alert/Alert");
const components_1 = require("modules/shared/components");
const MobileAppBar_1 = require("../../MobileAppBar/MobileAppBar");
class App extends React.Component {
    constructor() {
        super(...arguments);
        this.handlePartnerRedirect = (props = this.props) => {
            const { session } = props;
            const meta = lodash_1.get(session, 'error.meta', {});
            window.location = meta.redirectTo;
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const newRedirect = lodash_1.get(nextProps, 'session.error.meta.redirectTo');
        const currentRedirect = lodash_1.get(this.props, 'session.error.meta.redirectTo');
        if (newRedirect && newRedirect !== currentRedirect) {
            this.handlePartnerRedirect(nextProps);
        }
    }
    render() {
        const { children, partnerSettings } = this.props;
        const color = lodash_1.get(partnerSettings, 'ui.colors.primary');
        return (React.createElement(components_1.ThemeChanger, { color: color },
            React.createElement(app_1.default, null,
                children,
                React.createElement(Alert_1.default, null),
                React.createElement(MobileAppBar_1.default, null))));
    }
}
exports.default = App;
