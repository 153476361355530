import React from 'react';
import i18n from 'i18n';

const InstagramEngagements = ({ stats }) => {
  return (
    <div className='flex'>
      {!!stats.likes && (
        <div className='margin-right-2x'>
          <i className='vf-icon icon-heart' />
          <span className='margin-left-Hx'>{i18n.getNumber(stats.likes)}</span>
        </div>
      )}

      {!!stats.comments && (
        <div>
          <i className='vf-icon icon-comment' />
          <span className='margin-left-Hx'>
            {i18n.getNumber(stats.comments)}
          </span>
        </div>
      )}
    </div>
  );
};

export default InstagramEngagements;
