"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const CustomCheckbox = ({ value, onChange }) => {
    let classes = 'vf-customcheckbox vf-clickable';
    if (value)
        classes += ' active';
    return (React.createElement("div", { className: classes, onClick: onChange },
        React.createElement("div", { className: 'vf-customcheckbox-content' },
            React.createElement("div", { className: 'icon-checkmark' }))));
};
exports.default = CustomCheckbox;
