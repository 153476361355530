import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { Select } from 'ui-library';
import classNames from 'classnames';
import styles from './OrderControl.less';

class OrderControl extends React.Component {
  state = {
    selected: 'reach'
  };

  defaultOptions = {
    '-reach': `⇣ ${i18n.get('ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_AUDIENCE')}`,
    'reach': `⇡ ${i18n.get('ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_AUDIENCE')}`,
    '-engagementRate': `⇣ ${i18n.get(
      'ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_ENGAGEMENT_RATE'
    )}`,
    'engagementRate': `⇡ ${i18n.get(
      'ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_ENGAGEMENT_RATE'
    )}`,
    '-expectedEngagements': `⇣ ${i18n.get(
      'ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_EXPECTED_ENGAGEMENT'
    )}`,
    'expectedEngagements': `⇡ ${i18n.get(
      'ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_EXPECTED_ENGAGEMENT'
    )}`,
    '-suggestedPostPrice': `⇣ ${i18n.get(
      'ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_SUGGESTED_OFFER'
    )}`,
    'suggestedPostPrice': `⇡ ${i18n.get(
      'ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_SUGGESTED_OFFER'
    )}`,
    '-cpe': `⇣ ${i18n.get('ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_CPE')}`,
    'cpe': `⇡ ${i18n.get('ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_CPE')}`,
    '-cpm': `⇣ ${i18n.get('ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_CPM')}`,
    'cpm': `⇡ ${i18n.get('ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_CPM')}`,
    '-totalSuccessfulParticipations': `⇣ ${i18n.get(
      'ADV_CAMPAIGN_DASHBOARD_OPTIONS_PARTICIPATIONS'
    )}`,
    'totalSuccessfulParticipations': `⇡ ${i18n.get(
      'ADV_CAMPAIGN_DASHBOARD_OPTIONS_PARTICIPATIONS'
    )}`
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ selected: nextProps.sortKey });
  }

  extraOptions = () => {
    const { audienceSortKey } = this.props;
    if (audienceSortKey) {
      return {
        [audienceSortKey]: `⇣ ${i18n.get(
          'ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_AUDIENCE_LOACTION'
        )}`
      };
    }
    return {};
  };

  handleSelect = (event, index) => {
    const key = index.charAt(0) === '-' ? index.substring(1) : index;
    this.setState({ selected: index });
    this.props.onRequestSort({
      sortBy: { value: key },
      inverseOrder: index.charAt(0) === '-'
    });
  };

  render() {
    const { showGrid, onRequestShowList, onRequestShowGrid } = this.props;

    const options = { ...this.defaultOptions, ...this.extraOptions() };

    return (
      <div className='flex padding-right-1x flex-align-center'>
        <span
          className={classNames(styles.span, { [styles.selected]: showGrid })}
          onClick={onRequestShowGrid}
        >
          <i
            className={`vf-icon icon-ic-cardview ${styles.icon}`}
            data-uitest='cardview-icon'
          />
        </span>

        <span
          className={classNames(styles.span, { [styles.selected]: !showGrid })}
          onClick={onRequestShowList}
        >
          <i
            className={`vf-icon icon-ic-listview ${styles.icon}`}
            data-uitest='listview-icon'
          />
        </span>
        <span className={styles.span}>
          {i18n.get('ADV_INFLUENCERS_SEARCH_ORDER_BY')}
        </span>
        <Select
          title=''
          value={this.state.selected}
          onChange={this.handleSelect}
          options={options}
          buttonClassname={styles.select}
        />
      </div>
    );
  }
}

OrderControl.propTypes = {
  showGrid: PropTypes.bool,
  audienceSortKey: PropTypes.string,
  sortKey: PropTypes.string.isRequired,
  onRequestShowGrid: PropTypes.func.isRequired,
  onRequestShowList: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired
};

OrderControl.defaultProps = {
  showGrid: false,
  audienceSortKey: null
};

export default OrderControl;
