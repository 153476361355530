import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, isEqual } from 'lodash';

import * as appActions from 'redux/modules/app';
import * as brandsActions from 'redux/modules/brands';

import {
  getCurrentUser,
  getCurrentAdvertiserBrands
} from 'modules/shared/selectors';

import { LoadingState } from 'modules/shared/components';
import { FloatingChatManager as FloatingChats } from 'modules/brands/advertiser/containers';
import Menubar from 'modules/shared/advertiser/containers/Menubar/Menubar';

import LandingTour from 'modules/shared/tours/advertiser/landing';
import BrandDashboardTour from 'modules/shared/tours/advertiser/brand';

const ROUTES_WITH_SPECIAL_CHATS_STYLE = [
  /campaigns\/.+\/edit/,
  /campaigns\/create/
];

function mapStateToProps(state) {
  const { app, entities } = state;

  return {
    header: app.header,
    partnerSettings: app.partnerSettings,
    user: getCurrentUser(state),
    brandUsers: entities.BrandUser,
    brands: getCurrentAdvertiserBrands(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBrands: brandsActions.getBrands,
      changePage: appActions.changePage
    },
    dispatch
  );
}

class Layout extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    brands: PropTypes.array.isRequired,
    brandUsers: PropTypes.object,
    children: PropTypes.any,
    getBrands: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    inspectorComponent: PropTypes.any,
    header: PropTypes.object,
    params: PropTypes.object.isRequired
  };

  static defaultProps = {
    brandUsers: {},
    children: null,
    inspectorComponent: null,
    header: null
  };

  state = {
    isLoading: false
  };

  UNSAFE_componentWillMount() {
    const { user, getBrands } = this.props;
    this.setState({ isLoading: true });
    getBrands(user._id).then(this.handleBrandsSuccess);
  }

  handleBrandsSuccess = () => {
    this.setState({ isLoading: false });
  };

  UNSAFE_componentWillReceiveProps(props) {
    const { user, getBrands } = props;
    const didBrandsChange = this.didBrandsChange(props);

    if (didBrandsChange) getBrands(user._id);
  }

  didBrandsChange = props => {
    const previousBrandUsers = this.getBrandUsers();
    const newBrandUsers = this.getBrandUsers(props);

    return !isEqual(previousBrandUsers, newBrandUsers);
  };

  getBrandUsers = (props = this.props) => {
    const { user = {}, brandUsers = {} } = props;
    return filter(brandUsers, u => u.userId === user._id);
  };

  getFloatingChatsContainerStyle = () => {
    const doesChatsRequireStyle = ROUTES_WITH_SPECIAL_CHATS_STYLE.reduce(
      (requiresStyle, route) => {
        if (requiresStyle) return requiresStyle;
        return route.test(window.location.href);
      },
      false
    );

    if (!doesChatsRequireStyle) return;
    return { bottom: '7.75rem' };
  };

  render = () => {
    const {
      user,
      children,
      logout,
      inspectorComponent,
      header,
      params,
      partnerSettings
    } = this.props;
    const { isLoading } = this.state;
    const canShowTours = !!user.hasCompletePersonalInfo;
    const { validCategory } = user;

    return (
      <div>
        <Menubar
          user={user}
          logout={logout}
          inspectorComponent={inspectorComponent}
          header={header}
          partnerSettings={partnerSettings}
        />
        <div id='content'>
          {isLoading && <LoadingState />}
          {!isLoading && (
            <div>
              {children}
              <FloatingChats
                brandId={params.brandId}
                style={this.getFloatingChatsContainerStyle()}
              />

              {canShowTours && !validCategory && (
                <LandingTour nextTour={BrandDashboardTour} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
