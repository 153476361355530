import * as React from 'react';
import Loadable from 'react-loadable';
import Loading from 'modules/shared/components/LoadingState';

const LoadableComponent = Loadable({
  loader: () => import('./routes/Main'),
  loading: Loading,
  render(loaded, props) {
    const Main = loaded.default;
    return <Main {...props} />;
  }
});

const InfluencerApp = props => <LoadableComponent {...props} />;
export default InfluencerApp;
