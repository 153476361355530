"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const API = require("../lib/api");
const i18n = require("i18n");
const UploadAvatar_1 = require("apps/shared/components/UploadAvatar/UploadAvatar");
const components_1 = require("modules/shared/components");
class SettingsAccountContentReadOnly extends React.Component {
    constructor(props) {
        super(props);
        this.validateData = userData => {
            const { user } = this.props;
            const requireInfluencerInputs = user.role === 'influencer';
            let isValid = true;
            isValid = isValid && this.validateName(userData);
            if (requireInfluencerInputs) {
                isValid = isValid && this.validateBirthday(userData);
            }
            return isValid;
        };
        this.validateName = userData => {
            const { name = '' } = userData;
            return name.trim().length > 0;
        };
        this.validateGende = userData => {
            const { gender } = userData;
            return !!gender;
        };
        this.validateBirthday = userData => {
            const { birthday } = userData;
            return !!birthday;
        };
        this.onNameChange = event => {
            const name = event.target.value;
            const { userData } = this.state;
            const newUserData = Object.assign({}, userData, { name });
            this.setState({
                userData: newUserData
            });
        };
        this.onLastNameChange = event => {
            const lastName = event.target.value;
            const { userData } = this.state;
            const newUserData = Object.assign({}, userData, { lastName });
            this.setState({
                userData: newUserData
            });
        };
        this.onDateChange = birthday => {
            const { userData } = this.state;
            const newUserData = Object.assign({}, userData, { birthday });
            this.setState({
                userData: newUserData
            });
        };
        this.onGenderChange = event => {
            const state = this.state;
            state.userData.gender = event.target.getAttribute('data-value');
            this.setState(state);
        };
        this.onSelectImage = (id, image) => {
            const { userData } = this.state;
            const updateData = { id: userData.id, profileImage: image };
            const newUserData = Object.assign({}, userData, updateData);
            this.setState({
                userData: newUserData
            });
            API.auto('updateUser', updateData, {});
        };
        this.saveData = () => {
            const { userData } = this.state;
            const isValid = this.validateData(userData);
            const storeData = lodash_1.pick(userData, [
                'id',
                'name',
                'lastName',
                'birthday',
                'gender',
                'profileImage'
            ]);
            if (!isValid)
                return this.setState({ showErrors: true });
            API.auto('updateUser', storeData, {
                successMessage: 'Se ha guardado con éxito',
                callback: err => {
                    if (err)
                        return console.error(err);
                    if (lodash_1.has(this.props, 'onSave'))
                        this.props.onSave();
                }
            });
        };
        this.getGender = () => {
            const gender = lodash_1.get(this.state, 'userData.gender');
            if (!gender) {
                return i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_NOT_SPECIFIED');
            }
            const genderOptions = {
                male: i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_MALE'),
                female: i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_FEMALE'),
                other: i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_OTHER')
            };
            return genderOptions[gender];
        };
        const initialUserData = lodash_1.clone(this.props.user);
        const { _id: id, name, lastName, gender, profileImage, birthday = '' } = initialUserData;
        this.state = {
            userData: { id, name, lastName, birthday, gender, profileImage },
            avatarUrl: '',
            showErrors: false
        };
    }
    render() {
        const { userData } = this.state;
        const { name, lastName, profileImage, birthday } = userData;
        return (React.createElement("div", { className: 'vf-row' },
            React.createElement("div", { className: 'col-xs-12 col-md-4 vf-settings-avatar', id: 'avatar-container' },
                React.createElement(UploadAvatar_1.default, { image: profileImage, onChange: this.onSelectImage, label: i18n.get('INF_SETTINGS_ACCOUNT_UPLOAD_BUTTON_LABEL') })),
            React.createElement("div", { className: 'col-xs-12 col-md-4' },
                React.createElement("div", { className: 'vf-descriptor' }, i18n.get('INF_SETTINGS_ACCOUNT_PERSONAL_FIRST_NAME_LABEL')),
                React.createElement("h4", { "data-uitest": 'name' }, name),
                React.createElement("div", { className: 'vf-descriptor margin-top-1x' }, i18n.get('INF_SETTINGS_ACCOUNT_PERSONAL_BIRTHDATE_LABEL')),
                React.createElement("span", { "data-uitest": 'birthdate' },
                    React.createElement(components_1.DateLabel, { date: birthday }))),
            React.createElement("div", { className: 'col-xs-12 col-md-3' },
                React.createElement("div", { className: 'vf-descriptor' }, i18n.get('INF_SETTINGS_ACCOUNT_PERSONAL_LAST_NAME_LABEL')),
                React.createElement("h4", { "data-uitest": 'lastname' }, lastName || '--'),
                React.createElement("div", { className: 'vf-descriptor margin-top-1x' }, i18n.get('INF_SETTINGS_ACCOUNT_PERSONAL_GENDER_LABEL')),
                React.createElement("h4", { "data-uitest": 'gender' }, this.getGender()))));
    }
}
exports.default = SettingsAccountContentReadOnly;
