"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocialAvatarSM = void 0;
const React = require("react");
const classnames_1 = require("classnames");
const types_1 = require("modules/shared/types");
const styles = require("./SocialAvatar.less");
const images_1 = require("assets/images");
const SocialBadge_1 = require("../SocialBadge/SocialBadge");
const components_1 = require("modules/shared/components");
class SocialAvatar extends React.Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        const oldSA = this.props.socialAccount;
        const newSA = nextProps.socialAccount;
        if (oldSA._id !== newSA._id)
            this.setState({ hasError: false });
    }
    render() {
        const { size, className, style, socialAccount, publicationTypes, badgeSize } = this.props;
        const mStyles = Object.assign({ width: size, height: size }, style);
        const ptDefaults = publicationTypes && publicationTypes.length > 0
            ? publicationTypes
            : [types_1.PublicationType.POST];
        const hasStory = ptDefaults.includes(types_1.PublicationType.STORY);
        const containerClass = classnames_1.default(className, {
            [styles['story-border']]: hasStory
        });
        return (React.createElement("div", { className: containerClass, style: mStyles },
            React.createElement(components_1.SafeImage, { url: socialAccount.profileImageUrl || images_1.defaultAvatarImg, errorImage: images_1.defaultAvatarImg, className: 'image-cover image-fit-parent', width: size, height: size, style: { borderRadius: '50%' }, loaderDelay: 500, overlay: false }),
            React.createElement("div", { className: classnames_1.default('flex flex-justify-right', styles.list) }, ptDefaults.map((type, key) => (React.createElement(SocialBadge_1.default, { key: `${type}-${key}`, publicationType: type, socialNetwork: socialAccount.type || socialAccount.socialNetwork, size: badgeSize, style: {
                    marginLeft: '0.2rem'
                } }))))));
    }
}
exports.default = SocialAvatar;
SocialAvatar.defaultProps = {
    publicationTypes: ['post']
};
exports.SocialAvatarSM = props => (React.createElement(SocialAvatar, Object.assign({}, props, { size: 40, badgeSize: 13 })));
