import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from 'redux/modules/users';
import { getCurrentUser } from 'modules/shared/selectors';
import ShopifyOAuth from 'modules/shared/containers/ShopifyOAuth';
import { signinSuccess as onLoginSuccess } from 'modules/legacy/js/actions/auth';

function mapStateToProps(state) {
  const { user, session } = state.shared;
  return {
    user,
    session,
    onLoginSuccess,
    currentUser: getCurrentUser(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postShopifyToken: userActions.postShopifyToken
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyOAuth);
