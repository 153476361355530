"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles = require("./InterestLabel.less");
const app_1 = require("config/app");
const InterestLabel = ({ type, label }) => {
    const { interests } = app_1.getStatics();
    const interestTypes = Object.keys(interests);
    if (!interestTypes.includes(type))
        return React.createElement("noscript", null);
    const { icon: iconClass } = interests[type];
    return (React.createElement("div", { className: 'flex margin-bottom-Hx' },
        React.createElement("div", { className: styles.iconWrapper },
            React.createElement("i", { className: `${styles.icon} icon-${iconClass}` })),
        React.createElement("span", { className: styles.label }, label)));
};
exports.default = InterestLabel;
