import React from 'react';
import createClass from 'create-react-class';
import Reflux from 'reflux';
import { withRouter } from 'react-router-dom';
import i18n from 'i18n';
import { getDefaultTitle } from './../../../../config/app';

import NavigationActions from '../actions/nav';
import NavigationBar from '../components/navigation-bar';

const menuConfig = [
  {
    test: (history, location, params) =>
      params.id && location.pathname.includes('/posts/' + params.id),
    getProps: history => ({
      title: i18n.get('NAVIGATION_PUBLICATION'),
      leftButton: <i className='vf-icon icon-arrow-left'></i>,
      onClickLeftButton: () => history.push('/posts')
    })
  },
  {
    test: (history, location, params) =>
      location.pathname.includes('performance'),
    getProps: () => ({ title: i18n.get('NAVIGATION_PUBLICATION') })
  },
  {
    test: (history, location, params) =>
      params.id && location.pathname.includes('/social-accounts/' + params.id),
    getProps: history => ({
      title: i18n.get('NAVIGATION_SOCIAL_NETWORK'),
      leftButton: <i className='vf-icon icon-arrow-left'></i>,
      onClickLeftButton: () => history.push('/social-accounts')
    })
  },
  {
    test: (history, location, params) =>
      location.pathname.includes('social-accounts'),
    getProps: () => ({ title: i18n.get('NAVIGATION_SOCIAL_NETWORK') })
  },
  {
    test: (history, location, params) => location.pathname.includes('settings'),
    getProps: history => ({
      title: i18n.get('NAVIGATION_CONFIGURATION'),
      leftButton: <i className='vf-icon icon-arrow-left'></i>,
      onClickLeftButton: () => history.goBack()
    })
  },
  {
    test: (history, location, params) => location.pathname.includes('settings'),
    getProps: () => ({ title: i18n.get('NAVIGATION_CONFIGURATION') })
  },
  {
    test: (history, location, params) => location.pathname.includes('earnings'),
    getProps: () => ({ title: i18n.get('NAVIGATION_EARNINGS') })
  }
];

const getMenuProps = (history, location, params) => {
  const resultView = _.find(menuConfig, currentMenu =>
    currentMenu.test(history, location, params)
  );
  return resultView ? resultView.getProps(history, location, params) : {};
};

const NavigationContainer = createClass({
  mixins: [
    Reflux.listenTo(NavigationActions.pushView, 'pushView'),
    Reflux.listenTo(NavigationActions.popView, 'popView')
  ],

  getInitialState() {
    return {
      views: [
        {
          title: getDefaultTitle()
        }
      ]
    };
  },

  pushView(view) {
    this.state.views.unshift(view);
    this.forceUpdate();
  },

  popView() {
    if (!this.state.views.length) return;

    this.setState({
      views: this.state.views.slice(1)
    });
  },

  render() {
    const { ...other } = this.props;
    const { views } = this.state;
    const currentView = views[0];

    return (
      <NavigationBar
        {...other}
        {...currentView}
        {...getMenuProps(
          this.props.history,
          this.props.location,
          this.props.params
        )}
      />
    );
  }
});

export default withRouter(NavigationContainer);
