"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const isEqual = require("lodash/isEqual");
const appActions = require("redux/modules/app");
const ImagesGridUI_1 = require("./ImagesGridUI/ImagesGridUI");
const ERROR_STATUS = 'error';
const LOADED_STATUS = 'loaded';
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({
        showImagesGallery: appActions.showImagesGallery
    }, dispatch);
}
class ImagesGrid extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            totalErrors: 0,
            imagesStatus: {},
            isLoading: true
        };
        this.onImageLoaded = imageURL => {
            const imagesStatus = Object.assign(Object.assign({}, this.state.imagesStatus), { [imageURL]: LOADED_STATUS });
            this.setState({
                imagesStatus,
                isLoading: false
            });
        };
        this.onError = imageURL => {
            const totalErrors = this.state.totalErrors + 1;
            const imagesStatus = Object.assign(Object.assign({}, this.state.imagesStatus), { [imageURL]: ERROR_STATUS });
            this.setState({
                totalErrors,
                imagesStatus,
                isLoading: false
            });
        };
        this.showImagesGallery = startIndex => {
            const { urls, showImagesGallery } = this.props;
            showImagesGallery(urls, startIndex);
        };
    }
    UNSAFE_componentWillReceiveProps(props) {
        if (isEqual(props.urls, this.props.urls))
            return;
        this.setState({
            totalErrors: 0,
            imagesStatus: {},
            isLoading: props.urls.length > 0
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.urls !== this.props.urls || nextState !== this.state;
    }
    render() {
        const { imagesStatus, totalErrors, isLoading } = this.state;
        return (React.createElement(ImagesGridUI_1.default, Object.assign({}, this.props, { imagesStatus: imagesStatus, hasError: totalErrors > 0, isLoading: isLoading, onShowImage: this.showImagesGallery, onImageLoaded: this.onImageLoaded, onError: this.onError })));
    }
}
exports.default = react_redux_1.connect(null, mapDispatchToProps)(ImagesGrid);
