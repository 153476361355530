import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dotdotdot from 'react-dotdotdot';
import moment from 'moment';
import i18n from 'i18n';
import { get } from 'lodash';

import ensureChildNodeVisibility from 'utils/ensureChildNodeVisibility';
import preventParentScroll from 'utils/preventParentScroll';

import Avatar from 'apps/shared/components/Avatar/Avatar';
import styles from './BrandChatsList.less';

import { defaultAvatarImg } from 'assets/images';

export default class BrandChatsList extends Component {
  componentDidUpdate() {
    if (!!this.activeRow) this.scrollToShowActiveRow();
  }

  scrollToShowActiveRow = () => {
    ensureChildNodeVisibility(this.activeRow, this.container);
  };

  render = () => {
    const { chats, brandId, selectedChatId, onSelect } = this.props;
    const rows = chats.map(c => {
      const isActive = c.id === selectedChatId;

      return (
        <Row
          key={c.id}
          ref={r => (isActive ? (this.activeRow = r) : undefined)}
          chat={c}
          brandId={brandId}
          isActive={isActive}
          onSelect={() => onSelect(c.id)}
        />
      );
    });

    return (
      <div
        ref={r => (this.container = r)}
        onWheel={ev => preventParentScroll(ev, this.container)}
        className='col width100 vf-scrolly'
        style={{ height: '63vh' }}
      >
        {rows}
      </div>
    );
  };
}

BrandChatsList.propTypes = {
  chats: PropTypes.array.isRequired,
  brandId: PropTypes.string.isRequired,
  selectedChatId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
};

BrandChatsList.defaultProps = {
  chats: [],
  brandId: '',
  selectedChatId: '',
  onSelect: () => {}
};

function Row({ chat, isActive, onSelect }) {
  const lastMessageDate = get(chat, 'lastMessage.createdAt');
  const containerClassName = classNames('padding-1x', styles.row, {
    [styles.active]: isActive
  });

  return (
    <div className={containerClassName} onClick={onSelect}>
      {!!lastMessageDate && (
        <div className='flex width100 vf-font-small ellipsis end-xs'>
          {i18n.get('CHAT_LAST_MESSAGE_DATE', {
            date: getDateString(lastMessageDate)
          })}
        </div>
      )}

      <div className='vf-row top-xs width100' style={{ marginTop: '0.5rem' }}>
        <div className='col-xs-3'>
          <Avatar image={chat.influencer.profileImage || defaultAvatarImg} />
        </div>
        <div className='col-xs-9 col top-xs no-padding'>
          <div className='vf-font-bold width100 ellipsis'>
            {`${chat.influencer.name} ${chat.influencer.lastName}`}
          </div>
          <Dotdotdot className='vf-text-gray vf-font-small' clamp={2}>
            {get(chat, 'lastMessage.text')}
          </Dotdotdot>
        </div>
      </div>
    </div>
  );
}

Row.propTypes = {
  chat: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func.isRequired
};

Row.defaultProps = {
  chat: {},
  onSelect: () => {}
};

function getDateString(date) {
  const didMessageWasWrittenToday =
    !!date && moment().isSame(moment(date), 'day');
  if (didMessageWasWrittenToday) return moment(date).format('HH:mm');
  return i18n.get('CHAT_MESSAGE_DATE_TIME', {
    date: i18n.getDate(new Date(date), 'medium'),
    time: moment(date).format('HH:mm')
  });
}
