import { buildAPIRequest } from '../helpers';
import { postLite } from '../../../services/voxfeed';

// eslint-disable-next-line import/prefer-default-export
export const postLinkLite = id =>
  buildAPIRequest({
    params: id,
    id: `postLinkLite_${id}`,
    method: postLite.postLinkLite
  });
