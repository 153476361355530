import React from 'react';
import Reflux from 'reflux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createClass from 'create-react-class';
import { compose, bindActionCreators } from 'redux';

import i18n from 'i18n';
import API from 'modules/legacy/js/lib/api';
import recaptcha from 'apps/shared/hocs/recaptcha';
import AppActions from 'modules/legacy/js/actions/app';
import { InviteForm, InvalidToken } from './components';
import AuthActions from 'modules/legacy/js/actions/auth';
import PartnerFormRoute from 'apps/advertisers/PartnerForm';
import Loading from 'apps/shared/components/Loading/Loading';
import { changePage, languageChange } from 'redux/modules/app';
import { acceptRegistrationInvite } from 'redux/modules/shared';
import isAlreadyRegisteredError from './isAlreadyRegisteredError';
import { HeroSections, CardForm } from 'modules/shared/components';

const RegistrationInvite = createClass({
  mixins: [
    Reflux.listenTo(AuthActions.registerError, 'onRegisterError'),
    Reflux.listenTo(AppActions.reCAPTCHALoaded, 'updateReCaptchaWidget')
  ],

  getInitialState() {
    const { token } = this.props.params;

    return {
      token,
      email: '',
      loading: true,
      registrationInvite: null
    };
  },

  componentDidMount() {
    const { token, partnerSettings } = this.props.params;

    if (partnerSettings) return;

    API.getRegistrationInviteByToken({ token }, (err, response) => {
      this.setState({ loading: false });

      if (response.id) {
        this.setState({
          registrationInvite: response.data,
          email: response.data.email
        });
      }
    });
  },

  UNSAFE_componentWillReceiveProps({ session, user }) {
    if (session.error) return this.onRegisterError(session.error);

    if (user) {
      return AuthActions.registerSuccess({
        user,
        token: session.token,
        account: user.account
      });
    }

    return null;
  },

  onRegisterError(error) {
    const { updateReCaptchaWidget } = this.props;

    if (isAlreadyRegisteredError(error)) this.handleAlreadyRegisteredError();
    else this.handleGenericError();

    updateReCaptchaWidget();
  },

  handleAlreadyRegisteredError() {
    AppActions.displayAlert({
      type: 'error',
      title: i18n.get('DEFAULT_ERROR_TITLE'),
      message: i18n.get('REGISTER_EMAIL_ALREADY_REGISTERED')
    });
  },

  handleGenericError({ message }) {
    AppActions.displayAlert({
      message,
      type: 'error',
      title: i18n.get('DEFAULT_ERROR_TITLE')
    });
  },

  render() {
    const {
      partnerSettings,
      acceptRegistrationInvite: acceptRegistrationInviteActive
    } = this.props;

    const { token, email, loading, registrationInvite } = this.state;

    if (partnerSettings) return <PartnerFormRoute />;

    if (loading) return <Loading active />;

    return (
      <HeroSections
        buttonPadding
        linkTo='/register'
        buttonText={i18n.get('REGISTER_LOG_IN')}
        title={i18n.getHTML('REGISTER_HERO_TITLE_INV')}
        description={i18n.getHTML('REGISTER_HERO_DESCRIPTION_INV')}
        bottom={registrationInvite && <div className='margin-top-3x' />}
      >
        {registrationInvite ? (
          <CardForm subtitle={i18n.get('REGISTER_REGISTER_DESCRIPTION_INV')}>
            <InviteForm
              token={token}
              email={email}
              acceptRegistrationInviteActive={acceptRegistrationInviteActive}
            />
          </CardForm>
        ) : (
          <InvalidToken />
        )}
      </HeroSections>
    );
  }
});

function mapStateToProps({ app, shared }) {
  const { session, user } = shared;
  const { currentLanguage, partnerSettings } = app;

  return { session, user, currentLanguage, partnerSettings };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { acceptRegistrationInvite, changePage, languageChange },
    dispatch
  );
}

RegistrationInvite.propTypes = {
  user: PropTypes.object,
  params: PropTypes.object,
  session: PropTypes.object,
  changePage: PropTypes.func,
  acceptRegistrationInvite: PropTypes.func,
  currentLanguage: PropTypes.string.isRequired
};

export default compose(
  recaptcha,
  connect(mapStateToProps, mapDispatchToProps)
)(RegistrationInvite);
