import Reflux from 'reflux';
import _ from 'lodash';
import qs from 'query-string';

const Actions = {
  App: require('../actions/app'),
  Auth: require('../actions/auth')
};

const Stores = {
  dataStore: require('../stores/dataStore'),
  configStore: require('../stores/configStore'),
  currentUser: require('./currentUser'),
  currentAccount: require('./currentAccount')
};

const LOGIN_ROUTE = '/login';

module.exports = Reflux.createStore({
  dataStore: Stores.dataStore.getAll(),
  currentUser: Stores.currentUser.getAll(),
  currentAccount: Stores.currentAccount.getAll(),
  configStore: Stores.configStore.getAll(),

  init() {
    this.listenTo(
      Actions.App.redirectToDefaultRoute,
      this.redirectToDefaultRouteForUser
    );

    this.listenTo(Actions.App.gotoPage, this.redirectToPath);
  },

  setHistory(history) {
    this.history = history;
  },

  redirectToDefaultRouteForUser() {
    if (!this.isUserLoggedin()) return this.history.replace(LOGIN_ROUTE);

    if (this.shouldFetchUserData()) {
      return Actions.Auth.signinSuccess({
        token: Stores.configStore.getFromStorage()
      });
    }

    this.currentUser = Stores.currentUser.getAll();
    this.redirectToDefaultRouteByUserRole(this.currentUser.role);
  },

  isUserLoggedin() {
    const storedSession = Stores.configStore.getFromStorage();
    return storedSession !== null && storedSession !== undefined;
  },

  shouldFetchUserData() {
    const storedSession = Stores.configStore.getFromStorage();
    const activeUser = Stores.dataStore.getAll().User;

    return storedSession && _.isEmpty(activeUser);
  },

  redirectToDefaultRouteByUserRole(role) {
    this.history.push(`/${role}`);
  },

  redirectToPath(path, query, callback) {
    if (!this.history) return;
    if (!query) return this.history.push(path);

    callback = _.isFunction(query) ? query : callback;
    let search = qs.stringify(query);
    search = search ? `?${search}` : search;
    this.history.push(`${path}${search}`);

    if (_.isFunction(callback)) callback();
  }
});
