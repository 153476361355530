"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_slick_1 = require("react-slick");
const classnames_1 = require("classnames");
const styles = require("./MediaCarousel.less");
const PanelActions = require("modules/legacy/js/actions/panel");
const components_1 = require("modules/shared/components");
const PostBanner_1 = require("../PostBanner/PostBanner");
class MediaCarousel extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.settings = {
            dots: false,
            infinite: true,
            adaptiveHeight: true,
            prevArrow: React.createElement(Arrow, { isLeft: true }),
            nextArrow: React.createElement(Arrow, null)
        };
        this.handleGallery = index => {
            const { images } = this.props;
            PanelActions.displayOnGallery(images, index);
        };
    }
    render() {
        const { style, width, height, onPause, onPlay, url, link, socialNetwork, validLite, isProduct } = this.props;
        const images = this.props.images || [];
        const videos = this.props.videos || [];
        const videosUrls = this.props.videosUrls || [];
        const videosInsta = this.props.videosInsta || [];
        const redirectURL = url || link;
        const productList = isProduct && images.length < 1
            ? [
                React.createElement(components_1.SafeImage, { className: styles['carousel-item'], style: style, width: width, height: height, showProductIcon: true })
            ]
            : [];
        const imageList = images.map((img, i) => (React.createElement("div", { key: i, className: 'vf-clickable vf-bg-black-color' },
            React.createElement(components_1.SafeImage, { onClick: () => this.handleGallery(i), onClickError: () => window.open(redirectURL, '_blank'), className: styles['carousel-item'], url: img, style: style, width: width, height: height }))));
        const videoList = videos.map((vid, i) => (React.createElement(components_1.VideoPreview, { key: i, srcVideo: vid, url: redirectURL, onPause: onPause, onPlay: onPlay, zoom: false, previewStyle: Object.assign({ height: '312px', overflow: 'hidden', width: '100%' }, style) })));
        const videoIntaList = videosInsta.map((vid, i) => (React.createElement(components_1.VideoPreview, { key: i, srcVideo: vid, url: redirectURL, onPause: onPause, onPlay: onPlay, zoom: false, previewStyle: Object.assign({ height: '312px', overflow: 'hidden', width: '100%' }, style) })));
        const videoUrlsList = videosUrls.map(currentVideo => {
            return (React.createElement("div", { className: styles.urlContainer },
                React.createElement("a", { href: currentVideo, className: styles.youtubeUrl, target: '_blank' }, currentVideo)));
        });
        const postList = [
            ...imageList,
            ...videoList,
            ...videoUrlsList,
            ...productList,
            ...videoIntaList
        ];
        if (postList.length < 1 && validLite)
            return React.createElement(PostBanner_1.default, { link: link, socialNetwork: socialNetwork });
        if (postList.length <= 1)
            return React.createElement("div", null, postList);
        return React.createElement(react_slick_1.default, Object.assign({}, this.settings), postList);
    }
}
MediaCarousel.defaultProps = {
    images: [],
    videos: [],
    style: {}
};
exports.default = MediaCarousel;
const Arrow = ({ isLeft, onClick }) => (React.createElement("div", { className: classnames_1.default(styles.arrow, {
        [styles.left]: isLeft,
        [styles.right]: !isLeft
    }), onClick: onClick },
    React.createElement("i", { className: classnames_1.default('vf-icon icon-fix', {
            'icon-arrow-left': isLeft,
            'icon-arrow-right': !isLeft
        }) })));
