import React, { Component } from 'react';
import { logoImgObs } from 'assets/images';
import { loginSuccess } from 'redux/modules/shared';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ModalAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (window.addEventListener) {
      window.addEventListener('message', this.setToken, false);
    }
  }

  isAutorized = selected => {
    const { authorizedDomains } = this.props;
    const res = authorizedDomains.filter(currentValue => {
      return selected === currentValue;
    });

    return res.length > 0;
  };

  setToken = msg => {
    const { origin } = msg;

    if (this.isAutorized(origin)) {
      loginSuccess({ data: { session: { token: msg.data } } }); // Login Object Format
    }
  };

  render() {
    return (
      <div className='vf-row no-space center-xs'>
        <img src={logoImgObs} alt='' />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginSuccess
    },
    dispatch
  );
}

function mapStateToProps({ app }) {
  const { systemSettings } = app;
  const { authorizedDomains } = systemSettings;
  return { authorizedDomains };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAuth);
