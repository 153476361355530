"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const styles = require("./UserAvatarDropdownUI.less");
const Popover = require("react-popover");
const images_1 = require("assets/images");
const lodash_1 = require("lodash");
const react_router_dom_1 = require("react-router-dom");
const ui_library_1 = require("ui-library");
class UserAvatarDropdownUI extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            show: false
        };
        this.handleToggleShow = () => {
            this.setState({ show: !this.state.show });
        };
    }
    render() {
        const { show } = this.state;
        const { user, onLogout } = this.props;
        const avatar = lodash_1.get(user, 'profileImage', images_1.defaultAvatarImg);
        return (React.createElement(Popover, { isOpen: show, className: 'popover-without-padding popover-with-border', body: React.createElement(Menu, { user: user, onLogout: onLogout, onToggle: this.handleToggleShow }), onOuterAction: this.handleToggleShow },
            React.createElement("div", { onClick: this.handleToggleShow, className: styles.arrow },
                React.createElement("img", { src: avatar, alt: 'avatar user', className: styles.avatar }),
                React.createElement("i", { className: 'vf-icon icon-fix icon-caret-down-two', style: { transform: show ? 'rotate(180deg)' : 'rotate(0)' } }))));
    }
}
exports.default = UserAvatarDropdownUI;
const Menu = ({ user, onLogout, onToggle }) => (React.createElement("div", { style: { maxWidth: '300px' }, onClick: onToggle },
    React.createElement("div", { className: 'flex vf-bold vf-border-bottom', style: { padding: '2rem' } },
        React.createElement("i", { className: 'icon-user margin-right-1x', style: { fontSize: '2rem' } }),
        React.createElement("span", { className: 'margin-right-2x' }, `${user.name} ${user.lastName}`),
        React.createElement(react_router_dom_1.Link, { to: '/settings/account', style: { marginLeft: 'auto' } }, i18n.get('INF_SOCIAL_ACCOUNTS_START_CONFIGURE_BUTTON'))),
    React.createElement("div", null,
        React.createElement(ui_library_1.Button, { color: 'flat', size: 'md', className: 'vf-font-bold width100 text-align-left', style: { padding: '2rem', lineHeight: '1.5' }, onClick: onLogout }, i18n.get('LOGOUT')))));
