"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const EmailVerificationReminder_1 = require("modules/shared/screens/EmailVerificationReminder");
const Panel_1 = require("modules/shared/screens/Panel");
const Auth_1 = require("modules/shared/screens/Auth");
const store_conector_1 = require("modules/legacy/js/factories/store-conector");
const passParams_1 = require("../hocs/passParams");
const Settings_1 = require("./Settings");
const App_1 = require("apps/influencers/App");
const App_2 = require("apps/advertisers/App");
const App_3 = require("apps/admins/App");
const Settings_2 = require("modules/shared/screens/Settings");
const SAuth = store_conector_1.default(Auth_1.default);
const SPanel = store_conector_1.default(Panel_1.default);
const PPVerifyEmailReminder = passParams_1.default(EmailVerificationReminder_1.default);
const SettingChildRoutes = nprops => (React.createElement(Settings_2.default, Object.assign({}, nprops, { params: nprops.match.params }),
    React.createElement(Settings_1.default, Object.assign({}, nprops, { params: nprops.match.params }))));
const PanelRoutes = (_a) => {
    var { currentUser } = _a, props = __rest(_a, ["currentUser"]);
    const permissions = currentUser.permissions || [];
    return (React.createElement(SPanel, Object.assign({}, props, { params: props.match.params }),
        React.createElement(react_router_dom_1.Route, { path: '/settings', render: SettingChildRoutes }),
        currentUser.role === 'admin' && (React.createElement(App_3.default, Object.assign({ currentUser: currentUser }, props))),
        (currentUser.role === 'influencer' || currentUser.role === 'admin') && (React.createElement(App_1.default, Object.assign({ currentUser: currentUser }, props))),
        (currentUser.role === 'advertiser' || currentUser.role === 'admin') && (React.createElement(App_2.default, Object.assign({ currentUser: currentUser }, props))),
        React.createElement(react_router_dom_1.Switch, null,
            React.createElement(react_router_dom_1.Redirect, { exact: true, from: '/influencer', to: '/available-campaigns' }),
            React.createElement(react_router_dom_1.Redirect, { exact: true, from: '/advertiser', to: '/brands' }),
            currentUser.role === 'admin' &&
                (permissions.indexOf('kpis') > -1 ? (React.createElement(react_router_dom_1.Redirect, { exact: true, from: '/admin', to: '/dashboard/main' })) : permissions.indexOf('search-user') > -1 ? (React.createElement(react_router_dom_1.Redirect, { exact: true, from: '/admin', to: '/search/users' })) : permissions.indexOf('moderate') > -1 ? (React.createElement(react_router_dom_1.Redirect, { exact: true, from: '/admin', to: '/moderate/stats' })) : permissions.indexOf('payments') > -1 ? (React.createElement(react_router_dom_1.Redirect, { exact: true, from: '/admin', to: '/payments/influencers' })) : permissions.indexOf('console-publication') > -1 ? (React.createElement(react_router_dom_1.Redirect, { exact: true, from: '/admin', to: '/console/publications' })) : permissions.indexOf('console-participations') > -1 ? (React.createElement(react_router_dom_1.Redirect, { exact: true, from: '/admin', to: '/console/participations' })) : permissions.indexOf('console-admin') > -1 ? (React.createElement(react_router_dom_1.Redirect, { exact: true, from: '/admin', to: '/console/admin' })) : (React.createElement(react_router_dom_1.Redirect, { exact: true, from: '/admin', to: '/console/publications' }))))));
};
const AuthRoutes = props => (React.createElement(SAuth, Object.assign({}, props, { params: props.match.params }),
    React.createElement(react_router_dom_1.Switch, null,
        React.createElement(react_router_dom_1.Route, { path: '/verify-email-reminder', component: PPVerifyEmailReminder }),
        React.createElement(react_router_dom_1.Route, { path: '/', render: () => React.createElement(PanelRoutes, Object.assign({}, props)) }))));
exports.default = AuthRoutes;
