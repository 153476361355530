import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import ReactPopover from 'react-popover';
import classNames from 'classnames';

const Popover = createClass({
  timer: null,

  propTypes: {
    preferPlace: PropTypes.string,
    body: PropTypes.node,
    padding: PropTypes.bool,
    style: PropTypes.string,
    delay: PropTypes.number,
    hideWithClick: PropTypes.bool,
    isAnyPopoverShown: PropTypes.bool,
    showPopover: PropTypes.func,
    hidePopover: PropTypes.func,
    currentPopover: PropTypes.string
  },

  getDefaultProps() {
    return {
      padding: true,
      style: 'light',
      hideWithClick: false,
      delay: 200
    };
  },

  getInitialState() {
    return { showPopover: false };
  },

  showPopover() {
    this.setState({ showPopover: true });
  },

  hidePopover() {
    this.setState({ showPopover: false });
  },

  onMouseEnter() {
    clearTimeout(this.timer);
    this.showPopover();
  },

  onMouseLeave() {
    const { delay } = this.props;
    this.timer = setTimeout(this.hidePopover, delay);
  },

  render() {
    const {
      children,
      className,
      body,
      style,
      hideWithClick,
      padding,
      ...props
    } = this.props;

    const classnames = classNames(className, {
      'popover-dark': style === 'dark',
      'popover-without-padding': !padding
    });

    const bodyWrapper = (
      <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {body}
      </div>
    );

    return (
      <ReactPopover
        body={bodyWrapper}
        className={classnames}
        isOpen={this.state.showPopover}
        onOuterAction={this.hidePopover}
        {...props}
      >
        {React.cloneElement(children, {
          onMouseEnter: this.onMouseEnter,
          onMouseLeave: this.onMouseLeave
        })}
      </ReactPopover>
    );
  }
});

export default Popover;
