import React from 'react';
import * as i18n from 'i18n';
import { Select } from 'ui-library';

function genderSelector(props) {
  const GENDER_TEXTS_BY_TYPE = {
    male: i18n.get('ADV_INFLUENCER_PROFILE_MALE'),
    female: i18n.get('ADV_INFLUENCER_PROFILE_FEMALE')
  };

  const { onChange, selected, disabled } = props;

  return (
    <Select
      value={selected}
      options={GENDER_TEXTS_BY_TYPE}
      selectSize='md'
      className='full-with'
      onChange={onChange}
      disabled={disabled}
    />
  );
}

export default genderSelector;
