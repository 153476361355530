"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const app_1 = require("../../../../config/app");
const API = require("../lib/api");
const app_2 = require("../actions/app");
const MAX_IMAGE_SIZE = app_1.getMaxImageSize();
class UploadImage extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            loading: false
        };
        this.onSelectImage = event => {
            const { disabled, references, type } = this.props;
            const { loading } = this.state;
            const file = event.target.files[0];
            if (file.size > MAX_IMAGE_SIZE)
                return this.onMaxFileSizeExceeded();
            const uploadData = { file, references, type };
            if (!file || loading || disabled)
                return;
            this.setState({ loading: true });
            API.uploadImage(uploadData, (err, res) => {
                if (err)
                    this.onImageUploadFail();
                this.clearUploadInputVal();
                this.onUploadImage(res.data);
                this.setState({ loading: false });
            });
        };
        this.onImageUploadFail = () => {
            this.clearUploadInputVal();
            app_2.displayAlert({
                type: 'error',
                title: i18n.get('DEFAULT_ERROR_TITLE'),
                message: i18n.get('UPLOAD_IMAGE_UPLOAD_ERROR_MESSAGE')
            });
        };
        this.onMaxFileSizeExceeded = () => {
            this.clearUploadInputVal();
            app_2.displayAlert({
                type: 'warning',
                title: i18n.get('UPLOAD_IMAGE_UPLOAD_WARNING_TITLE'),
                message: i18n.get('UPLOAD_IMAGE_UPLOAD_WARNING_MESSAGE')
            });
        };
        this.onUploadImage = image => {
            if (this.props.onChange)
                this.props.onChange(image);
        };
        this.clearUploadInputVal = () => {
            this.uploadInput.value = null;
        };
        this.handleClick = () => {
            if (this.props.disabled)
                return;
            this.uploadInput.click();
        };
    }
    render() {
        const { className, disabled, loadingLabel = i18n.get('LOADING'), label = i18n.get('UPLOAD_IMAGE_UPLOAD_LABEL') } = this.props;
        const { loading } = this.state;
        const classes = classnames_1.default('vf-upload-image vf-no-selectable', className, {
            'disabled vf-hide': disabled
        });
        return (React.createElement("div", { onClick: this.handleClick },
            React.createElement("label", { htmlFor: 'file' },
                React.createElement("button", { className: classes },
                    React.createElement("div", null, loading ? loadingLabel : label),
                    React.createElement("input", { ref: ref => (this.uploadInput = ref), name: 'file', type: 'file', accept: 'image/jpeg,image/bmp,image/png', onChange: this.onSelectImage, disabled: disabled })))));
    }
}
UploadImage.defaultProps = {
    references: ''
};
exports.default = UploadImage;
