import axios from 'axios';

export function createBillingAddress({brandId, data}) {
  return axios.post(`/brands/${brandId}/billing-info/`, data);
}

export function updateBillingAddress({brandId, billingInfoId, data}) {
  return axios.put(`/brands/${brandId}/billing-info/${billingInfoId}`, data);
}

export function deleteBillingAddress({brandId, billingInfoId}) {
  return axios.delete(`/brands/${brandId}/billing-info/${billingInfoId}`);
}
