"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validCampaignVersion = exports.deserializeMention = exports.deserializeHashtag = exports.serializeMention = exports.serializeHashtag = exports.findSocialNetwork = exports.validateMention = exports.validateHashtag = void 0;
const types_1 = require("modules/shared/types");
exports.validateHashtag = (hashtag) => /^#?([a-zA-Z0-9._]+)$/.test(hashtag.trim());
exports.validateMention = (mention) => /^@?([a-zA-Z0-9._]{1,50})$/.test(mention.trim());
exports.findSocialNetwork = /(tiktok|instagramPost|instagramStory|facebook|youtube)/;
exports.serializeHashtag = (hashtag) => hashtag.replace(/^#/, '');
exports.serializeMention = (mention) => mention.replace(/^@/, '');
exports.deserializeHashtag = (hashtag) => {
    if (hashtag.startsWith('#'))
        return hashtag;
    return `#${hashtag}`;
};
exports.deserializeMention = (mention) => {
    if (mention.startsWith('@'))
        return mention;
    return `@${mention}`;
};
exports.validCampaignVersion = (campaignVersion) => campaignVersion === types_1.ECategoryType.LITE;
