"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const components_1 = require("apps/shared/components");
const images_1 = require("assets/images");
const usePrevious = value => {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
const Avatar = ({ image = images_1.defaultAvatarImg, badgeImage, badgeText = null, badgeTextPosition = 'right', style = 'circle', className = '', avatarClasses = '', socialNetwork = null, size = 'sm', width, heigth }) => {
    const prevImage = usePrevious(image);
    const [hasError, setHaserror] = React.useState(false);
    let bImage = badgeImage;
    let bText = badgeText;
    const sizes = {
        sm: 'small',
        xmd: 'xmedium',
        md: 'medium',
        lg: 'large',
        xl: 'xl'
    };
    const imgSizes = {
        sm: 40,
        xmd: 70,
        md: 80,
        lg: 91,
        xl: 121
    };
    const classes = classnames_1.default('vf-avatar', style, className, sizes[size], {
        'text-badge-left': badgeTextPosition === 'left',
        'text-badge-right': badgeTextPosition === 'right'
    });
    const containerStyle = { width, heigth };
    const imageStyle = {
        width,
        heigth
    };
    if (bImage || socialNetwork) {
        bImage = (React.createElement("div", { className: 'vf-avatar-badge' }, socialNetwork ? (React.createElement(components_1.SocialBadgeSM, { socialNetwork: socialNetwork })) : (React.createElement("img", { className: 'vf-avatar-badge-image', src: badgeImage, alt: '' }))));
    }
    if (bText) {
        bText = (React.createElement("div", { className: 'vf-avatar-text-badge' },
            React.createElement("span", null, badgeText)));
    }
    const onAvatarError = () => {
        setHaserror(false);
    };
    React.useEffect(() => {
        if (prevImage !== image) {
            setHaserror(false);
        }
    }, [image]);
    return (React.createElement("div", { className: classes, style: containerStyle },
        React.createElement(components_1.Img, { className: `vf-avatar-image ${avatarClasses}`, alt: 'avatar', width: width || imgSizes[size], height: heigth || imgSizes[size], src: hasError ? images_1.defaultAvatarImg : image, imgixParams: { crop: 'faces' }, htmlAttributes: {
                onError: onAvatarError,
                style: imageStyle
            } }),
        badgeImage,
        badgeText));
};
exports.default = Avatar;
