import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import i18n from 'i18n';

export const BrandsSection = ({ onNext }) => (
  <div className='col center-xs'>
    <h4 className='vf-font-thin'>
      {i18n.get('ADV_BRANDS_TOUR_BRAND_SECTION_DESCRIPTION')}
    </h4>

    <a
      href={i18n.get('ADV_BRANDS_TOUR_BRAND_CREATION_URL')}
      target='_blank'
      className='margin-top-Hx'
    >
      {i18n.get('MORE_INFO')}
    </a>

    <div className='flex center-xs margin-vertical-1x'>
      <Button size='sm' color='success' onClick={onNext}>
        {i18n.get('NEXT')}
      </Button>
    </div>
  </div>
);

BrandsSection.propTypes = {
  onNext: PropTypes.func
};
