"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const components_2 = require("apps/advertisers/PartnerForm/components");
const dist_1 = require("ui-library/dist");
const react_router_dom_1 = require("react-router-dom");
class PartnerResetPasswordUI extends React.Component {
    constructor() {
        super(...arguments);
        this.form = null;
        this.handlePasswordBlur = event => {
            const { onPasswordBlur } = this.props;
            const { name, value } = event.target;
            onPasswordBlur(name, value);
        };
        this.handleConfirmBlur = () => {
            const { elements } = this.form;
            const { onConfirmBlur } = this.props;
            const form = {
                password: elements.password.value,
                confirmPassword: elements.confirmPassword.value
            };
            onConfirmBlur(form);
        };
        this.handleSubmit = event => {
            event.preventDefault();
            const { elements } = event.target;
            const { onSubmit } = this.props;
            const form = {
                password: elements.password.value,
                confirmPassword: elements.confirmPassword.value
            };
            onSubmit(form);
        };
    }
    render() {
        const { errors } = this.props;
        return (React.createElement("div", null,
            React.createElement(components_2.default, null, i18n.get('FORGOT_RESET_YOUR_PASSWORD')),
            React.createElement("form", { action: '#', onSubmit: this.handleSubmit, ref: form => (this.form = form) },
                React.createElement(components_1.FormLabel, { htmlFor: 'password' }, i18n.get('LOGIN_PASSWORD')),
                React.createElement(components_1.FormInput, { name: 'password', type: 'password', hasError: !!errors.password, onBlur: this.handlePasswordBlur }),
                React.createElement(components_1.FormLabel, { htmlFor: 'confirmPassword' }, i18n.get('RESET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER')),
                React.createElement(components_1.FormInput, { name: 'confirmPassword', type: 'password', hasError: !!errors.confirmPassword, onBlur: this.handleConfirmBlur }),
                React.createElement("div", { className: 'vf-text-danger center-xs margin-top-1x vf-font-bold', style: { fontSize: '1.8rem' } },
                    React.createElement("div", null, errors.password),
                    React.createElement("div", null, errors.confirmPassword)),
                React.createElement(dist_1.Button, { color: 'primary', size: 'lg', className: 'width100 margin-top-1x', type: 'submit', disabled: false }, i18n.get('FORGOT_RESET_PASSWORD')),
                React.createElement(react_router_dom_1.Link, { to: '/login', className: 'width100 margin-top-1x vf-text-primary vf-btn vf-btn-flat vf-btn--lg' }, i18n.get('LOGIN_LOGIN')))));
    }
}
exports.default = PartnerResetPasswordUI;
