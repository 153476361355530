import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, get } from 'lodash';
import classNames from 'classnames';
import i18n from 'i18n';

import * as appActions from 'redux/modules/app';
import * as chatsActions from 'redux/modules/chats';

import {
  getCurrentUser,
  getChatByBrandAndInfluencerId,
  getChatMessages
} from 'modules/shared/selectors';

import { ChatTextarea } from 'modules/shared/components';
import { ChatConversation } from 'modules/shared/containers';

import styles from './ChatWithBrand.less';

const MESSAGES_PER_FETCH = 20;

function mapStateToProps(state, { brandId }) {
  const currentUser = getCurrentUser(state);
  const influencerUserId = get(currentUser, '_id');
  const chat =
    !!brandId && !!influencerUserId
      ? getChatByBrandAndInfluencerId(state, brandId, influencerUserId)
      : undefined;
  const messages = chat ? getChatMessages(state, chat.id) : [];
  const messagesRequest = chat
    ? get(state, `app.requests.fetchChatMessages_${chat.id}`)
    : undefined;

  return {
    chat,
    messages,
    messagesRequest
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchChat: chatsActions.fetchInfluencerChatWithBrand,
      sendMessage: chatsActions.sendMessageAsInfluencer,
      fetchMessages: chatsActions.fetchChatMessagesAsInfluencer,
      fetchMembers: chatsActions.fetchChatMembersAsInfluencer,
      setLastReadMessage: chatsActions.setLastReadMessageAsInfluencer,
      showAlert: appActions.showAlert
    },
    dispatch
  );
}

class ChatWithBrand extends Component {
  static propTypes = {
    brandId: PropTypes.string.isRequired,
    chat: PropTypes.object,
    messages: PropTypes.array,
    messagesRequest: PropTypes.object,
    fetchChat: PropTypes.func.isRequired,
    fetchMessages: PropTypes.func.isRequired,
    fetchMembers: PropTypes.func.isRequired,
    setLastReadMessage: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    showWrapper: PropTypes.bool,
    style: PropTypes.object,
    conversationStyle: PropTypes.object,
    className: PropTypes.string,
    showAlert: PropTypes.func.isRequired
  };

  static defaultProps = {
    chat: null,
    messages: [],
    messagesRequest: {},
    showWrapper: true,
    style: {},
    conversationStyle: {},
    className: ''
  };

  state = {
    errors: []
  };

  UNSAFE_componentWillMount() {
    const { chat, brandId } = this.props;
    const chatId = get(chat, 'id');

    if (brandId) this.props.fetchChat(brandId);
    if (chatId) this.props.fetchMembers(chatId);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const prevId = get(this.props, 'chat.id');
    const nextId = get(props, 'chat.id');
    const didChatChanged = prevId !== nextId && !!nextId;

    if (didChatChanged) {
      this.setState({ errors: [] });
      this.props.fetchMembers(nextId);
    }
  }

  onSendMessage = ({ text, attachments }) => {
    const { chat, sendMessage } = this.props;
    if (!chat) return Promise.resolve();

    const args = {
      text,
      attachments,
      chatId: chat.id
    };

    return sendMessage(args).catch(this.onSendError);
  };

  onSendError = () => {
    this.props.showAlert({
      type: 'error',
      message: i18n.get('CHAT_SENDING_ERROR')
    });
    return Promise.reject();
  };

  onFetchMessages = () => {
    const { messages, chat } = this.props;
    const chatId = get(chat, 'id');
    if (!chatId) return;

    const oldestMessageIndex = get(messages, '[0].index');

    this.props.fetchMessages({
      chatId,
      cursor: oldestMessageIndex,
      limit: MESSAGES_PER_FETCH
    });
  };

  onSetLastReadMessage = messageId => {
    const { chat } = this.props;
    if (!chat || !messageId) return;

    this.props.setLastReadMessage({
      messageId,
      chatId: chat.id
    });
  };

  onErrorsChange = errors => {
    this.setState({ errors });
  };

  shouldRenderTextarea = () => {
    const { messagesRequest, messages, chat } = this.props;
    const isLoadingMessages = messagesRequest.status === 'loading';
    const hasMessages = !isEmpty(messages);
    const status = get(chat, 'member.chatStatus');

    if (!hasMessages && isLoadingMessages) return false;
    return status !== 'blocked';
  };

  render = () => {
    const {
      chat,
      messages,
      messagesRequest,
      showWrapper,
      style,
      conversationStyle,
      className
    } = this.props;
    const isLoadingMessages = messagesRequest.status === 'loading';

    if (!isLoadingMessages && !chat) return <noscript />;

    const hasMessages = !isEmpty(messages);
    const placeholder = hasMessages
      ? i18n.get('CHAT_DEFAULT_INPUT_PLACEHOLDER')
      : i18n.get('CHAT_NEW_PARTICIPATION_INPUT_PLACEHOLDER');
    const containerClassName = classNames(
      'width100 col end-xs no-padding',
      className,
      {
        'vf-card': showWrapper
      }
    );
    const titleClassName = classNames(
      'flex start-xs width100 padding-Hx ellipsis',
      styles.title
    );

    return (
      <div className={containerClassName} style={{ height: '50vh', ...style }}>
        {showWrapper && (
          <div className={titleClassName}>
            {i18n.get('CHAT_PARTICIPATION_TITLE', {
              brandName: get(chat, 'brand.name')
            })}
          </div>
        )}

        <ChatConversation
          chatId={chat.id}
          messages={messages}
          errors={this.state.errors}
          style={conversationStyle}
          isLoading={isLoadingMessages}
          onFetchMessages={this.onFetchMessages}
          onSetLastReadMessage={this.onSetLastReadMessage}
        />

        {this.shouldRenderTextarea() && (
          <ChatTextarea
            key={chat.id}
            placeholder={placeholder}
            onSend={this.onSendMessage}
            onErrorsChange={this.onErrorsChange}
          />
        )}
      </div>
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatWithBrand);
