import React from 'react';
import getProperty from 'lodash/get';
import moment from 'moment';
import MessageFormat from 'messageformat';
import determineLocale from './determine-locale';
import getValidLocale from './get-valid-locale';

const URL_LOCALE_KEY = 'locale';
const COOKIE_LOCALE_KEY = 'locale';
const NUMBERS_LOCALE_KEY = 'NUMBERS_LOCALE';
const DEFAULT_LOCALE = 'en';
const SOURCE_LOCALE = 'es';
const DEFAULT_STRING = '';
const FORMATTERS = {};
const FALLBACK_LOCALES = [
  DEFAULT_LOCALE,
  SOURCE_LOCALE
];

let defaultCurrency = 'USD';
let globalSupportedLocales;
let globalLocales = {};
let globalLocale = determineLocale({
  urlLocaleKey: URL_LOCALE_KEY,
  cookieLocaleKey: COOKIE_LOCALE_KEY,
  defaultLocale: DEFAULT_LOCALE
});

const CUSTOM_FORMATS = {
  number: value => getNumber(value),
  decimal: (value, decimals) => getNumber(value, decimals),
  currency: (value, currency) => getMoney({ amount: value, currency: currency }),
  date: (value, locale, format) => getDate(value, format)
};

const dateFormats = {
  'short': {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  },

  'medium': {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  },

  'long': {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  },

  'full': {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  }
};

function getLocale() {
  return globalLocale;
}

function setLocale(locale) {
  globalLocale = locale;
  moment.locale(locale);
}

function setCurrency(newCurrency){
  if(newCurrency){
    defaultCurrency = newCurrency
  }
}

function getFormatter(locale) {
  if (FORMATTERS[locale]) return FORMATTERS[locale];

  const formater = new MessageFormat(locale);

  formater.addFormatters(CUSTOM_FORMATS);
  FORMATTERS[locale] = formater;

  return formater;
}

function init(args) {
  const {
    locales,
    supportedLocales,
    defaultLocale = DEFAULT_LOCALE
  } = args;

  const explicitLocale = getValidLocale(determineLocale({
    urlLocaleKey: URL_LOCALE_KEY,
    cookieLocaleKey: COOKIE_LOCALE_KEY,
    defaultLocale: DEFAULT_LOCALE
  }), supportedLocales);
  const currentLocale = explicitLocale || defaultLocale;

  moment.locale(currentLocale);

  globalLocales = locales;
  globalLocale = currentLocale;
  globalSupportedLocales = supportedLocales;
}

function getLocalizedString(locale, key, data) {
  const text = getProperty(globalLocales, [locale, key]);

  if (!text) return null;

  const formater = getFormatter(locale);
  const message = formater.compile(text);

  return message(data);
}

function get(key, data) {
  const locale = getValidLocale(globalLocale);
  const string = getLocalizedString(locale, key, data);
  const isValid = val => val !== null;

  if (isValid(string)) return string;

  const fallbackString = FALLBACK_LOCALES.reduce((result, fallbackLocale) => {
    if (isValid(result)) return result;
    return getLocalizedString(fallbackLocale, key, data);
  }, null);

  if (isValid(fallbackString)) return fallbackString;

  if (typeof window !== 'undefined') {
    console.warn(`[i18n] Unable to retrieve a value for the key: ${key}. Please make sure that the current locale (${globalLocale}) contains a definition for that key.`);
  }

  return DEFAULT_STRING;
}

/** 
  * Returns the money on the format based on localization and systemConfiguration settings
  * @param {amount} Required aount of money int value
  * @param {options} Optional object that contains the next params
  * @param {options:currency} Optional sting with currency to display default is setted on * systemSettings
  * @param {options:appendCurrencySuffix} Optional boolean indicating if we need the append suffin on money value ej. $, default, true
*/

  function getMoney({amount, currency = defaultCurrency, appendCurrencySuffix = true}) {
  const locale = getValidLocale(globalLocale);
  const options = {
    currency,
    style: appendCurrencySuffix ? 'currency' : 'decimal',
    useGrouping: true,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  const numbersLocale = getProperty(globalLocales, [locale, NUMBERS_LOCALE_KEY]);
  if (!numbersLocale || amount === undefined) return '';

  let cleanedAmount = amount;
  if(typeof amount === 'string'){
    cleanedAmount = amount.replace(/,/g, '');
  } 

  const amountStr = Number(cleanedAmount).toLocaleString(numbersLocale, options);
  return `${amountStr} ${currency.toUpperCase()}`;
}

function getNumber(amount, decimals = 0) {
  const locale = getValidLocale(globalLocale, globalSupportedLocales);
  const options = {
    useGrouping: true,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  };
  const numbersLocale = getProperty(globalLocales, [locale, NUMBERS_LOCALE_KEY]);
  if (!numbersLocale) return '';

  return Number(amount).toLocaleString(numbersLocale, options);
}

function getHTML(key, variables) {
  const msg = get(key, variables);
  if (msg) {
    const el = React.createElement('div', {
      dangerouslySetInnerHTML: {
        __html: msg
      }
    });

    // when key exists, it should still return element if there's defaultMessage() after getHTML()
    const defaultMessage = () => el;
    return Object.assign({},
      {
        defaultMessage,
        d: defaultMessage
      },
      el
    );
  }

  return '';
}

function getDate(date, format) {
  const locale = getValidLocale(globalLocale);
  const options = dateFormats[format] || dateFormats.short;
  const dateTime = new Intl.DateTimeFormat(locale, options);
  const value = dateTime.format(new Date(date));

  return value;
}

function getCurrency() {
  return defaultCurrency;
}

module.exports = {
  init,
  get,
  getDate,
  getMoney,
  getCurrency,
  getNumber,
  getHTML,
  getLocale,
  setLocale,
  setCurrency,
  determineLocale
};
