"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ReactDOM = require("react-dom");
const API = require("modules/legacy/js/lib/api");
const lodash_1 = require("lodash");
const app_1 = require("config/app");
const recaptcha = (Component) => {
    class Recaptcha extends React.Component {
        constructor() {
            super(...arguments);
            this.state = {
                captchaReference: null,
            };
            this.updateReCaptchaWidget = () => {
                const component = this;
                const grecaptcha = window.grecaptcha;
                API.getCaptcha(function getCaptcha(error, res) {
                    if (error)
                        return console.error(error);
                    if (!lodash_1.get(res, 'required', null))
                        return false;
                    if (component.captchaReference !== null) {
                        grecaptcha.reset(component.captchaReference);
                        ReactDOM.findDOMNode(component.refs.recaptcha).style.display = '';
                        return false;
                    }
                    component.captchaReference = grecaptcha.render('recaptcha', {
                        sitekey: app_1.recaptchaSiteKey,
                        theme: 'light',
                        callback: function (resGetCallback) {
                            API.postCaptcha({ response: resGetCallback }, function (err, response) {
                                if (err)
                                    return console.error(err);
                                if (response.success === true) {
                                    ReactDOM.findDOMNode(component.refs.recaptcha).innerHTML = '';
                                    return ReactDOM.findDOMNode(component.refs.recaptcha).innerHTML;
                                }
                            });
                        }
                    });
                });
            };
        }
        componentDidMount() {
            if (typeof window.grecaptcha !== 'undefined') {
                this.updateReCaptchaWidget();
            }
        }
        render() {
            return (React.createElement(Component, Object.assign({}, this.props, { updateReCaptchaWidget: this.updateReCaptchaWidget })));
        }
    }
    return Recaptcha;
};
exports.default = recaptcha;
