"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const classnames_1 = require("classnames");
const styles = require("./ResponsiveDialog.less");
const react_media_1 = require("react-media");
const ui_library_1 = require("ui-library");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
class ResponsiveDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isOpen: false
        };
        this.handleToggleDialog = () => {
            const { isOpen } = this.state;
            document.body.style.overflow = isOpen ? '' : 'hidden';
            this.setState({ isOpen: !this.state.isOpen });
        };
    }
    render() {
        const { isOpen } = this.state;
        const { children } = this.props;
        return (React.createElement(react_media_1.default, { query: '(max-width: 800px)' }, matches => matches ? (React.createElement(Mobile, { isOpen: isOpen, handleToggleDialog: this.handleToggleDialog }, children)) : (children)));
    }
}
exports.default = ResponsiveDialog;
const Mobile = ({ children, isOpen, handleToggleDialog }) => (React.createElement("div", { className: 'width100' },
    React.createElement(ui_library_1.Button, { color: 'primary', size: 'lg', style: { width: '100%' }, onClick: handleToggleDialog }, i18n.get('LOGIN_CREATE_ACOUNT')),
    React.createElement(CustomDialog_1.default, { isOpen: isOpen, header: null, className: 'flex flex-justify-center', dismissable: true, onClose: handleToggleDialog, customContainerStyle: {
            background: 'transparent',
            boxShadow: 'none',
            padding: '1rem'
        }, content: React.createElement("div", { className: 'padding-vertical-3x' },
            React.createElement("i", { className: classnames_1.default('vf-icon icon-close', styles.close), onClick: handleToggleDialog }),
            React.createElement("div", { className: 'flex flex-justify-center padding-top-3x' }, children)) })));
