"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const React = require("react");
const ReactDOM = require("react-dom");
const link = (Component) => {
    class Link extends React.Component {
        constructor() {
            super(...arguments);
            this.state = {
                validationActive: false
            };
        }
        componentDidMount() {
            this.updateValidateTypes();
        }
        createPathThree(object) {
            let pathThreeBuffer = [];
            const pathWalk = (currentObject, currentPath) => {
                if (_.isPlainObject(currentObject)) {
                    _.map(currentObject, (el, key) => {
                        pathWalk(el, currentPath ? currentPath + '.' + key : key);
                    });
                }
                else {
                    pathThreeBuffer.push(currentPath);
                }
            };
            pathWalk(object);
            return pathThreeBuffer;
        }
        ;
        linkedValidation(property, customOnChange, customClassNames) {
            const value = _.get(this.state, property);
            const linkFunction = evt => {
                this.setState(_.set(this.state, property, _.has(evt, 'target') ? _.get(evt, ['target', 'value']) : evt));
            };
            const validatedClassNames = this.state.validationActive && !this.props.validateProperty(property, value)
                ? 'error'
                : '';
            return {
                className: validatedClassNames +
                    (customClassNames ? ` ${customClassNames}` : ''),
                value,
                onChange: linkFunction
            };
        }
        ;
        linkToParent(property, customOnChange, customClassNames, modifier) {
            let value = _.get(this.props, property);
            const linkFunction = evt => {
                let value = _.has(evt, 'target')
                    ? _.get(evt, ['target', 'value'])
                    : evt;
                _.isFunction(customOnChange) && customOnChange(evt);
                this.props.onChange(property, _.isFunction(modifier) ? modifier(value) : value);
            };
            const validatedClassNames = this.state.validationActive && !this.validateProperty(property, value)
                ? 'error'
                : '';
            return {
                className: validatedClassNames +
                    (customClassNames ? ` ${customClassNames}` : ''),
                value,
                onChange: linkFunction
            };
        }
        ;
        validateProperty(property, value) {
            var _a, _b;
            if (!((_a = this.state) === null || _a === void 0 ? void 0 : _a.validateTypes))
                return true;
            const validateFunc = _.get((_b = this.state) === null || _b === void 0 ? void 0 : _b.validateTypes, property);
            if (!validateFunc)
                return true;
            return validateFunc(value, this);
        }
        ;
        updateValidateTypes() {
            var _a;
            this.validationObjects = _.reduce(this.createPathThree((_a = this.state) === null || _a === void 0 ? void 0 : _a.validateTypes), (res, el) => {
                var _a;
                res[el] = _.get((_a = this.state) === null || _a === void 0 ? void 0 : _a.validateTypes, el);
                return res;
            }, {});
        }
        ;
        validateAll() {
            this.setState({
                validationActive: true
            }, () => {
                const el = ReactDOM.findDOMNode(this).getElementsByClassName('error');
                if (el[0])
                    el[0].focus();
            });
            const result = !_.some(this.validationObjects, (el, index) => {
                return !this.validateProperty(index, _.get(this.props, index) || _.get(this.state, index));
            });
            if (!result) {
                const invalidKeys = _.reduce(this.validationObjects, (res, el, index) => {
                    if (!this.validateProperty(index, _.get(this.props, index) || _.get(this.state, index))) {
                        res.push(index);
                    }
                    return res;
                }, []);
            }
            return result;
        }
        ;
        linkedState(property) {
            const value = _.get(this.state, property);
            const linkFunction = evt => {
                this.state = _.set(this.state, property, evt.target.value);
                this.forceUpdate();
            };
            return {
                value,
                onChange: linkFunction
            };
        }
        ;
        render() {
            return (React.createElement(Component, Object.assign({}, this.props, { validateProperty: this.validateProperty, validateAll: this.validateAll, linkedValidation: this.linkedValidation })));
        }
    }
    return Link;
};
exports.default = link;
