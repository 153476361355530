import { get } from 'lodash';

import { accounts, brands } from '../../../services/voxfeed';
import {
  startStripePayment,
  startPaypalPayment
} from '../../../services/payments/startPayment';
import { sendRequest, sendRequestSuccess, sendRequestFail } from '../app';
import { updateData, replaceData, directUpdate } from '../entities';
import { handleRequestFail, buildAPIRequest } from '../helpers';

const paymentMethods = {
  stripe: startStripePayment,
  paypal: startPaypalPayment
};

export function addCredit(data) {
  const REQUEST_ID = 'addCreditToAccount';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    const { id, amount, concept, role } = data;
    return brands
      .addCredit(id, { amount, concept, role })
      .then(response => {
        if (response) dispatch(updateData(response.data));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(res =>
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            response: get(res, 'response', {})
          })
        )
      );
  };
}

export function removeCredit(params) {
  const REQUEST_ID = 'removeCreditFromAccount';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    const { id, amount, concept } = params;
    return brands
      .removeCredit(id, { amount, concept })
      .then(({ data }) => {
        if (data) {
          dispatch(
            updateData({
              modelName: 'Account',
              data: data.data,
              id: data.data.id
            })
          );
        }
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(res =>
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            response: get(res, 'response', {})
          })
        )
      );
  };
}

export function getAccount(id) {
  return dispatch =>
    accounts
      .getAccount(id)
      .then(res => dispatch(updateData(res.data)))
      .catch(() => {});
}

export function associateUser(data) {
  const REQUEST_ID = 'associateUser';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));
    const { id } = data;

    return accounts
      .associateUser(id, data)
      .then(response => {
        if (response) dispatch(updateData(response.data));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(res =>
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            response: get(res, 'response', {})
          })
        )
      );
  };
}

export const createBillingAddress = params =>
  buildAPIRequest({
    params,
    id: 'createBillingAddress',
    method: accounts.createBillingAddress
  });

export const updateBillingAddress = params =>
  buildAPIRequest({
    params,
    id: 'updateBillingAddress',
    method: accounts.updateBillingAddress
  });

export const deleteBillingAddress = params =>
  buildAPIRequest({
    params,
    id: 'deleteBillingAddress',
    method: accounts.deleteBillingAddress
  });

export const depositCredit = (data, brand, method, tokenRequestData) =>
  buildAPIRequest({
    id: 'depositCredit',
    method: paymentMethods[method],
    params: { data, tokenRequestData }
  });

export function fetchSocialAccountsByAccountId(id) {
  const REQUEST_ID = `fetchSocialAccountsByAccountId_${id}`;

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return accounts
      .getSocialAccounts(id)
      .then(response => {
        dispatch(updateData(response.data));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export const fetchInfluencerPosts = ({ options, ...params }) =>
  buildAPIRequest({
    params,
    id: `fetchInfluencerPosts_${params.influencerId}`,
    method: accounts.fetchInfluencerPosts,
    replace: options.replace
  });

export const fetchInfluencerPostById = params =>
  buildAPIRequest({
    params,
    id: `fetchInfluencerPost_${params.postId}`,
    method: accounts.fetchInfluencerPostById
  });

export function linkSocialAccount(influencerId, data) {
  const REQUEST_ID = `linkSocialAccount_${influencerId}_${data.id}`;

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return accounts
      .linkSocialAccount(influencerId, data)
      .then(response => {
        dispatch(updateData(response.data));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export const fetchInfluencerPayments = params =>
  buildAPIRequest({
    params,
    id: 'fetchInfluencerPayments',
    method: accounts.fetchInfluencerPayments,
    replace: params.replace
  });

export const fetchInfluencerEarnings = params =>
  buildAPIRequest({
    params,
    id: 'fetchInfluencerEarnings',
    method: accounts.fetchInfluencerEarnings,
    callback: ({ dispatch, data }) => {
      const { pending, currenciesTotals } = data;

      dispatch(
        updateData({
          modelName: 'Earning',
          data: pending.map(p => ({ ...p, id: p.inviteId }))
        })
      );

      dispatch(
        directUpdate({
          modelName: 'EarningsTotal',
          id: '0',
          data: currenciesTotals
        })
      );
    }
  });

export const fetchPaymentsOutRecepit = params =>
  buildAPIRequest({
    params,
    id: 'fetchPaymentsOutRecepit',
    method: accounts.fetchPaymentsOutRecepit,
    callback: ({ dispatch, data }) => {
      dispatch(
        updateData({
          modelName: 'PaymentOut',
          id: params.id,
          data: {
            pdfURL: data.url
          }
        })
      );
    }
  });

export const fetchInfluencerActiveInvites = ({
  isCancellable,
  replace,
  ...params
}) =>
  buildAPIRequest({
    params,
    id: 'fetchInfluencerActiveInvites',
    method: accounts.fetchInfluencerActiveInvites,
    isCancellable,
    callback: ({ data, dispatch }) => {
      const { data: invites } = data;

      dispatch(
        updateData({
          data: invites.map(i => ({ ...i, campaign: i.campaign.id })),
          modelName: 'Invite'
        })
      );

      dispatch(
        replaceData({
          ...data,
          modelName: 'ActiveInvite'
        })
      );
    }
  });

export const fetchInfluencerPendingInvites = ({
  isCancellable,
  replace,
  ...params
}) =>
  buildAPIRequest({
    params,
    id: 'fetchInfluencerPendingInvites',
    method: accounts.fetchInfluencerPendingInvites,
    isCancellable,
    callback: ({ data, dispatch }) => {
      const { data: invites } = data;

      dispatch(
        updateData({
          data: invites.map(i => ({ ...i, campaign: i.campaign.id })),
          modelName: 'Invite'
        })
      );

      dispatch(
        replaceData({
          ...data,
          modelName: 'PendingInvite'
        })
      );
    }
  });

export const fetchInfluencerInvitesHistory = ({
  isCancellable,
  replace,
  ...params
}) =>
  buildAPIRequest({
    params,
    id: 'fetchInfluencerInvitesHistory',
    method: accounts.fetchInfluencerInvitesHistory,
    isCancellable,
    callback: ({ data, dispatch }) => {
      const { data: invites } = data;

      dispatch(
        updateData({
          data: invites.map(i => ({ ...i, campaign: i.campaign.id })),
          modelName: 'Invite'
        })
      );

      const updater = replace ? replaceData : updateData;

      const updaterArgs = { ...data, modelName: 'HistoryInvite' };

      dispatch(updater(updaterArgs));
    }
  });
