import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { getCampaign } from 'modules/shared/selectors';

import Avatar from 'apps/shared/components/Avatar/Avatar';
import { DefaultEntityImage } from 'modules/shared/components';
import { Img } from 'apps/shared/components';

import styles from './CampaignCard.less';
import { defaultCampaignCoverImg } from 'assets/images';

function mapStateToProps(state, { campaignId }) {
  return {
    campaign: getCampaign(state, campaignId)
  };
}

class CampaignCard extends Component {
  static propTypes = {
    campaignId: PropTypes.string.isRequired,
    campaign: PropTypes.object,
    className: PropTypes.string,
    labelText: PropTypes.string,
    labelClassName: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node
  };

  static defaultProps = {
    campaign: {},
    className: '',
    labelText: '',
    labelClassName: '',
    disabled: false,
    children: <noscript />,
    onClick: () => {}
  };

  shouldComponentUpdate(nextProps) {
    return this.props.campaign !== nextProps.campaign;
  }

  renderLabel = () => {
    const { labelText, labelClassName } = this.props;
    const className = classNames(styles.label, labelClassName, {
      'vf-bg-white-color': !labelClassName,
      'vf-text-black': !labelClassName
    });

    return <div className={className}>{labelText}</div>;
  };

  render() {
    const { campaign, className, onClick, labelText, children, disabled } =
      this.props;
    const { coverImage } = campaign;
    const containerClassName = classNames(
      'padding-1x',
      styles.container,
      className,
      {
        'vf-clickable': !!onClick,
        [styles.disabled]: disabled
      }
    );

    return (
      <div className={containerClassName} onClick={onClick}>
        <Img
          className='image-cover image-fit-parent'
          sizes='600px'
          src={coverImage || defaultCampaignCoverImg}
        />
        <div className='vf-gradient' />
        <CampaignAvatar campaign={campaign} />
        {!!labelText && this.renderLabel()}
        {children}
      </div>
    );
  }
}

function CampaignAvatar({ campaign }) {
  const { brandImage, name, brandName } = campaign;
  const image = brandImage ? (
    <Avatar image={brandImage} />
  ) : (
    <DefaultEntityImage entityName={name} />
  );

  return (
    <div className='flex' style={{ maxWidth: '50%' }}>
      <div>{image}</div>
      <div className='col vf-text-white margin-left-Hx width100 ellipsis'>
        <div className='ellipsis' style={{ fontSize: '1.75rem' }}>
          {name}
        </div>
        <div className='ellipsis' style={{ fontSize: '1.5rem' }}>
          {brandName}
        </div>
      </div>
    </div>
  );
}

CampaignAvatar.propTypes = {
  campaign: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(CampaignCard);
