"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const styles = require("./PublicationPreview.less");
const components_1 = require("apps/shared/components");
const components_2 = require("modules/shared/components");
const PublicationPreview = ({ text, images, videos, videosUrl, socialAccount }) => (React.createElement("div", { className: styles.wrapper },
    React.createElement("div", { className: classnames_1.default('flex', styles.top) },
        React.createElement(components_1.SocialAvatar, { socialAccount: socialAccount, size: 50, badgeSize: 15 }),
        React.createElement("div", { className: 'margin-left-Hx' },
            React.createElement("a", { href: socialAccount.url, className: 'vf-text-primary', target: '_blank', rel: 'noreferrer' }, socialAccount.username),
            React.createElement("p", { className: 'vf-text-white' }, i18n.get('FOLLOWERS_OR_SUBSCRIBERS', {
                followers: i18n.getNumber(socialAccount.audience, 0),
                type: socialAccount.type
            })))),
    React.createElement(components_2.MediaCarousel, { videos: videos, images: images, videosUrls: videosUrl, style: { height: 'auto' } }),
    React.createElement("div", { className: classnames_1.default(styles.bottom) },
        React.createElement(components_2.TextWithHighlightedElements, { className: 'vf-text-white', text: text }))));
exports.default = PublicationPreview;
