import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from 'i18n';
import { getDefaultTitle } from 'config/app';
import styles from './VerificationBadge.less';

export default function VerificationBadge({ size, className, style }) {
  const badgeClassName = classNames('vf-bg-gradient', className, styles.badge, {
    [styles.small]: size === 'sm',
    [styles.medium]: size === 'md'
  });

  return (
    <div
      className={badgeClassName}
      title={i18n.get('VERIFIED_ACCOUNT', { companyName: getDefaultTitle() })}
      style={style}
    >
      <i className='vf-icon icon-check' />
    </div>
  );
}

VerificationBadge.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};

VerificationBadge.defaultProps = {
  size: 'md',
  className: '',
  style: null
};
