"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./MoneyInput.less");
const NumberInput_1 = require("modules/shared/components/NumberInput");
const react_redux_1 = require("react-redux");
const MoneyInput = (_a) => {
    var { className, currency, availableCurrencies } = _a, others = __rest(_a, ["className", "currency", "availableCurrencies"]);
    const selectedCurrency = availableCurrencies.filter(obj => obj.currency === currency);
    let symbol = '$';
    if (selectedCurrency.length > 0) {
        symbol = selectedCurrency[0].symbol;
    }
    const wrapperClass = classnames_1.default('flex', styles.wrapper, className);
    return (React.createElement("div", { className: wrapperClass },
        React.createElement("div", { className: styles['icon-box'] }, symbol),
        React.createElement(NumberInput_1.default, Object.assign({ className: styles.input }, others)),
        React.createElement("div", { className: styles['icon-box'] }, currency)));
};
function mapStateToProps({ app }) {
    const { systemSettings } = app;
    const { availableCurrencies } = systemSettings;
    return {
        availableCurrencies
    };
}
exports.default = react_redux_1.connect(mapStateToProps, null)(MoneyInput);
