export default function downloadFileContent({
  content,
  url,
  fileName = 'file',
  type = 'text/plain'
}) {
  if (typeof window === 'undefined') return;

  const link = document.createElement('a');

  link.setAttribute('href', url || buildHrefFromContent(content, type));
  link.setAttribute('download', fileName);

  if (!url) link.setAttribute('type', type);

  document.body.appendChild(link);
  link.click();
}

function buildHrefFromContent(content, type) {
  const blob = new Blob([content], { type });
  return window.URL.createObjectURL(blob);
}
