"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Color = require("color");
const app_1 = require("config/app");
class ThemeChanger extends React.Component {
    constructor() {
        super(...arguments);
        this.updateColor = (props = this.props) => {
            const color = props.color || ThemeChanger.DEFAULT_COLOR;
            const colorGradient = ThemeChanger.DEFAULT_GRADIENT;
            const style = document.documentElement.style;
            style.setProperty('--primary', color);
            style.setProperty('--gradientEnd', colorGradient);
            ThemeChanger.VAR_COLORS.forEach(obj => {
                style.setProperty(obj.name, this.calculateColor(color, obj.method, obj.value));
            });
        };
        this.calculateColor = (primary, method, value) => {
            const color = Color(primary);
            return this[method](color, value);
        };
        this.darken = (color, ratio) => {
            const hsl = color.hsl();
            hsl.color[2] -= ratio;
            return hsl;
        };
        this.lighten = (color, ratio) => {
            const hsl = color.hsl();
            hsl.color[2] += ratio;
            return hsl;
        };
        this.fade = (color, ratio) => {
            return color.alpha(ratio).rgb().string();
        };
    }
    componentDidMount() {
        this.updateColor();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.color === this.props.color)
            return;
        this.updateColor(nextProps);
    }
    render() {
        return React.Children.only(this.props.children);
    }
}
ThemeChanger.DEFAULT_COLOR = app_1.getDefaultColor();
ThemeChanger.DEFAULT_GRADIENT = app_1.getDefaultGradientColor();
ThemeChanger.VAR_COLORS = [
    {
        name: '--primaryD20',
        method: 'darken',
        value: 20
    },
    {
        name: '--primaryD15',
        method: 'darken',
        value: 15
    },
    {
        name: '--primaryD10',
        method: 'darken',
        value: 10
    },
    {
        name: '--primaryD05',
        method: 'darken',
        value: 5
    },
    {
        name: '--primaryL60',
        method: 'lighten',
        value: 60
    },
    {
        name: '--primaryL45',
        method: 'lighten',
        value: 45
    },
    {
        name: '--primaryL05',
        method: 'lighten',
        value: 5
    },
    {
        name: '--primaryF60',
        method: 'fade',
        value: 0.6
    },
    {
        name: '--primaryF50',
        method: 'fade',
        value: 0.5
    },
    {
        name: '--primaryF20',
        method: 'fade',
        value: 0.2
    },
    {
        name: '--primaryF10',
        method: 'fade',
        value: 0.1
    },
    {
        name: '--primaryF05',
        method: 'fade',
        value: 0.05
    }
];
exports.default = ThemeChanger;
