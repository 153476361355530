import axios from 'axios';

export const addAddressInfluencerLite = ({ address, coordinates }) =>
  axios.post('lite/participations/address', {
    address,
    coordinates
  });

export const getAddressInfluencerLite = userId =>
  axios.get(`/lite/participations/${userId}/address`);

export const updateAddressInfluencerLite = ({ address, coordinates }) =>
  axios.put('lite/participations/address', {
    address,
    coordinates
  });
