import i18n from 'i18n';

const getMonths = () => [
  i18n.get('DATES_MONTHS_JANUARY'),
  i18n.get('DATES_MONTHS_FEBRUARY'),
  i18n.get('DATES_MONTHS_MARCH'),
  i18n.get('DATES_MONTHS_APRIL'),
  i18n.get('DATES_MONTHS_MAY'),
  i18n.get('DATES_MONTHS_JUNE'),
  i18n.get('DATES_MONTHS_JULY'),
  i18n.get('DATES_MONTHS_AUGUST'),
  i18n.get('DATES_MONTHS_SEPTEMBER'),
  i18n.get('DATES_MONTHS_OCTOBER'),
  i18n.get('DATES_MONTHS_NOVEMBER'),
  i18n.get('DATES_MONTHS_DECEMBER')
];

const getWeekdaysLong = () => [
  i18n.get('DATES_WEEKDAYS_LONG_SUNDAY'),
  i18n.get('DATES_WEEKDAYS_LONG_MONDAY'),
  i18n.get('DATES_WEEKDAYS_LONG_TUESDAY'),
  i18n.get('DATES_WEEKDAYS_LONG_WEDNESDAY'),
  i18n.get('DATES_WEEKDAYS_LONG_THURSDAY'),
  i18n.get('DATES_WEEKDAYS_LONG_FRIDAY'),
  i18n.get('DATES_WEEKDAYS_LONG_SATURDAY')
];

const getWeekdaysShort = () => [
  i18n.get('DATES_WEEKDAYS_SHORT_SUNDAY'),
  i18n.get('DATES_WEEKDAYS_SHORT_MONDAY'),
  i18n.get('DATES_WEEKDAYS_SHORT_TUESDAY'),
  i18n.get('DATES_WEEKDAYS_SHORT_WEDNESDAY'),
  i18n.get('DATES_WEEKDAYS_SHORT_THURSDAY'),
  i18n.get('DATES_WEEKDAYS_SHORT_FRIDAY'),
  i18n.get('DATES_WEEKDAYS_SHORT_SATURDAY')
];

const translation = {
  getMonths,
  getWeekdaysShort,
  getFirstDayOfWeek: () => 0,
  formatDay: d =>
    `${getWeekdaysLong()[d.getDay()]}, ${d.getDate()} ${
      getMonths()[d.getMonth()]
    } ${d.getFullYear()}`,
  formatWeekdayShort: index => getWeekdaysShort()[index],
  formatWeekdayLong: index => getWeekdaysLong()[index],
  formatMonthTitle: d => `${getMonths()[d.getMonth()]} ${d.getFullYear()}`
};

export default translation;
