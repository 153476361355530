"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const team_member_1 = require("./team-member");
const column_list_1 = require("./column-list");
const TeamMemberList = ({ members }) => {
    const memberNodes = members.map(function (member, index) {
        return (React.createElement(team_member_1.default, { index: index, name: member.name, title: member.title, photo: member.photo }));
    });
    return React.createElement(column_list_1.default, { elements: memberNodes });
};
exports.default = TeamMemberList;
