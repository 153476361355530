import React from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import i18n from 'i18n';

import * as userActions from 'redux/modules/users';
import * as appActions from 'redux/modules/app';
import * as sharedActions from 'redux/modules/shared';

import { getRequest } from 'modules/shared/selectors';

import Loading from 'apps/shared/components/Loading/Loading';

const REQUEST_ID = 'sendUserEmailVerification';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showAlert: appActions.showAlert,
      changePage: appActions.changePage,
      resetRequest: appActions.resetRequest,
      login: sharedActions.loginRequest,
      sendUserEmailVerification: userActions.sendUserEmailVerification
    },
    dispatch
  );
}

function mapStateToProps(state) {
  const { user, session } = state.shared;
  const request = getRequest(state, REQUEST_ID);
  return { request, user, session };
}

class VerifyEmail extends React.Component {
  UNSAFE_componentWillMount() {
    const { location } = this.props;
    const query = qs.parse(location.search);
    const { token } = query;

    if (!token) return this.redirectToLogin();

    this.props
      .sendUserEmailVerification({ token })
      .then(this.onSuccess)
      .catch(this.onFail);
  }

  componentWillUnmount() {
    this.props.resetRequest(REQUEST_ID);
  }

  onSuccess = () => {
    const { session, login } = this.props;

    this.showSuccessMessage();

    const hasSessionToken = !!get(session, 'token');

    if (!hasSessionToken) return this.redirectToLogin();
    return login({ token: session.token, redirectTo: '/' });
  };

  onFail = () => {
    const { session, request: res } = this.props;
    const msg =
      res.code === 'DATABASE::ENTITY_NOT_FOUND'
        ? i18n.get('VERIFY_EMAIL_LINK_ERROR_ALERT_DESCRIPTION')
        : i18n.get('VERIFY_EMAIL_UNKNOWN_ERROR_ALERT_DESCRIPTION');

    this.showErrorMessage(msg);

    const hasSessionToken = !!get(session, 'token');

    if (hasSessionToken) return this.redirectToVerifyEmailReminder();
    return this.redirectToLogin();
  };

  isRequestLoading = ({ status: requestStatus }) => {
    return requestStatus === 'loading';
  };

  didRequestFail = ({ status: requestStatus }) => {
    return requestStatus === 'fail';
  };

  didRequestSucceeded = ({ status: requestStatus }) => {
    return requestStatus === 'success';
  };

  showSuccessMessage = () => {
    this.props.showAlert({
      type: 'success',
      message: i18n.get('VERIFY_EMAIL_VERIFICATION_SUCCESS_ALERT_DESCRIPTION')
    });
  };

  showErrorMessage = message => {
    this.props.showAlert({
      message,
      type: 'error'
    });
  };

  redirectToLogin = () => {
    this.props.changePage('/login');
  };

  redirectToVerifyEmailReminder = () => {
    this.props.changePage('/verify-email-reminder');
  };

  render() {
    return (
      <Loading active message={i18n.get('VERIFY_EMAIL_VERIFYING_EMAIL')} />
    );
  }
}

VerifyEmail.displayName = 'VerifyEmail';

VerifyEmail.propTypes = {
  location: PropTypes.object,
  session: PropTypes.object.isRequired,
  sendUserEmailVerification: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  showAlert: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  resetRequest: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
