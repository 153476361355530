import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Line } from 'rc-progress';
import { get } from 'lodash';
import uuidV4 from 'uuid/v4';

import { displayAlert } from '../../legacy/js/actions/app';
import { getMaxVideoSize, amazonS3PlayerHost } from '../../../config/app';
import { uploadFileAWS } from '../../../services/aws/s3/uploadFileAWS';

const MAX_VIDEO_SIZE = getMaxVideoSize();

class FileUploadAWS extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      disabled: false,
      percent: 0
    };
  }

  handleClick() {
    if (this.props.disabled) return;
    this.uploadInput.click();
  }

  onSelectVideo(event) {
    const { disabled } = this.props;
    const { loading } = this.state;

    const file = event.target.files[0];
    if (file.size > MAX_VIDEO_SIZE) return this.onMaxFileSizeExceeded();

    if (!file || loading || disabled) return 0;

    this.setState({ loading: true });

    const fileKey = uuidV4();
    const fileType = file.type;
    const url = `${amazonS3PlayerHost}${fileKey}`;

    const params = {
      fileKey,
      fileType,
      file,
      progressHanlder: this.uploadProgressHanlder.bind(this)
    };

    uploadFileAWS(params)
      .then(response => {
        if (get(response, 'status') !== 200) {
          this.onBadUpload();
        } else {
          const data = { fileType, url, name: fileKey };

          this.clearUploadInputVal();
          this.onUploadVideo(data);
          this.setState({ loading: false, percent: 0 });
        }
      })
      .catch(error => {
        console.log(error);
        this.onBadUpload();
      });
  }

  uploadProgressHanlder(progressEvent) {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    this.setState({ percent: percentCompleted });
  }

  onUploadVideo(video) {
    if (this.props.onChange) this.props.onChange(video);
  }

  onMaxFileSizeExceeded() {
    this.clearUploadInputVal();

    displayAlert({
      type: 'warning',
      title: 'Video demasiado grande',
      message: 'Asegúrate que tu video no exceda los 50Mb'
    });
  }

  onBadUpload() {
    this.clearUploadInputVal();
    this.setState({ loading: false, percent: 0 });

    displayAlert({
      type: 'error',
      title: 'Problema de conexión',
      message:
        'Ocurrio un error al cargar tu video, por favor vuelve a intentarlo.'
    });
  }

  clearUploadInputVal() {
    this.uploadInput.value = null;
  }

  render() {
    const { className, label, loadingLabel, disabled } = this.props;
    const { loading } = this.state;
    const classes = classNames('vf-upload-image vf-no-selectable', className, {
      'disabled vf-hide': disabled
    });
    const loadingStyle = {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%'
    };

    return (
      <div onClick={this.handleClick.bind(this)}>
        <label htmlFor='file'>
          <button className={classes}>
            <div>{loading ? loadingLabel : label}</div>

            <input
              ref={ref => (this.uploadInput = ref)}
              name='file'
              type='file'
              accept='video/mp4,video/x-m4v,video/*'
              onChange={this.onSelectVideo.bind(this)}
              disabled={disabled}
            />
            <Line
              style={loadingStyle}
              strokeWidth='2'
              strokeColor='#16D4E6'
              strokeLinecap='square'
              trailWidth='0'
              percent={this.state.percent}
            />
          </button>
        </label>
      </div>
    );
  }
}

FileUploadAWS.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  loadingLabel: PropTypes.any,
  references: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['ProfileImage', 'CampaignCover', 'Ad']),
  disabled: PropTypes.bool
};

export default FileUploadAWS;
