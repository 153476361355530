import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18n from 'i18n';
import { getDefaultTitle } from 'config/app';

import { hideModal } from 'redux/modules/app';

import CustomDialog from 'apps/shared/components/CustomDialog/CustomDialog';

import {
  AdvertiserFeedbackForm,
  VoxFeedFeedbackForm
} from 'modules/campaigns/influencer/components';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ hideModal }, dispatch);
}

class FeedbackDialog extends Component {
  static propTypes = {
    brand: PropTypes.object.isRequired,
    inviteId: PropTypes.string.isRequired,
    campaignId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    onDone: PropTypes.func
  };

  static defaultProps = {
    onDone: () => {}
  };

  state = {
    currentStep: 'advertiser'
  };

  stepsComponents = {
    advertiser: AdvertiserFeedbackForm,
    voxfeed: VoxFeedFeedbackForm
  };

  onFeedbackSent = () => {
    if (this.state.currentStep === 'advertiser')
      return this.onAdvertiserFeedbackSent();
    this.onVoxFeedFeedbackSent();
  };

  onAdvertiserFeedbackSent = () => {
    this.setState({ currentStep: 'voxfeed' });
    this.props.onDone();
  };

  onVoxFeedFeedbackSent = () => {
    const { onClose, hideModal } = this.props;
    return onClose ? onClose() : hideModal();
  };

  getTitle = () => {
    if (this.state.currentStep === 'advertiser')
      return i18n.get('INF_FEEDBACK_DIALOG_ADV_TITLE');
    return i18n.get('INF_FEEDBACK_DIALOG_VOXFEED_TITLE', {
      companyName: getDefaultTitle()
    });
  };

  render = () => {
    const { brand, campaignId, inviteId } = this.props;
    const { currentStep } = this.state;
    const Content = this.stepsComponents[currentStep];

    const onClose = this.props.onClose || this.props.hideModal;

    return (
      <CustomDialog
        size='sm'
        dismissable
        header={this.getTitle()}
        isOpen={this.props.isOpen}
        onClose={onClose}
        content={
          <Content
            brand={brand}
            campaignId={campaignId}
            inviteId={inviteId}
            onSent={this.onFeedbackSent}
          />
        }
      />
    );
  };
}

export default connect(null, mapDispatchToProps)(FeedbackDialog);
