import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Textarea, Button } from 'ui-library';
import i18n from 'i18n';

import { sendParticipationFeedback } from 'redux/modules/campaigns';

import { BrandAvatar, RateSelector } from 'modules/shared/components';

const REQUEST_ID = 'sendParticipationFeedback';
const INITIAL_RATE = 0;

function mapStateToProps({ app }) {
  const request = get(app, `requests.${REQUEST_ID}`, {});
  return { request };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ sendParticipationFeedback }, dispatch);
}

class AdvertiserFeedbackForm extends Component {
  constructor() {
    super();

    this.state = {
      rate: INITIAL_RATE,
      comments: ''
    };
  }

  onSend = () => {
    const { campaignId, inviteId } = this.props;
    const { rate, comments } = this.state;
    this.props.onSent();
    this.props.sendParticipationFeedback({
      campaignId,
      inviteId,
      rate,
      comments
    });
  };

  render = () => {
    const { onSent, brand } = this.props;
    const { rate, comments } = this.state;

    return (
      <div className='padding-1x width-100'>
        <span>{i18n.get('INF_FEEDBACK_DIALOG_ADV_COPY')}</span>

        <div className='vf-row middle-xs' style={{ margin: '1rem 0' }}>
          <div className='col-xs-3 no-padding'>
            <BrandAvatar url={brand.image} name={brand.name} />
          </div>

          <div className='col col-xs-9'>
            <h3 className='vf-text-primary vf-font-bold ellipsis'>
              {brand.name}
            </h3>

            <RateSelector
              rate={rate}
              onSelect={newRate => this.setState({ rate: newRate })}
            />
          </div>
        </div>

        <Textarea
          className='margin-top-1x'
          placeholder={i18n.get(
            'INF_FEEDBACK_DIALOG_ADV_TEXT_AREA_PLACEHOLDER'
          )}
          value={comments}
          onChange={ev => this.setState({ comments: ev.target.value })}
        />

        <div className='flex center-xs margin-top-1x margin-bottom-Hx'>
          <Button
            color='primary'
            disabled={rate === INITIAL_RATE}
            data-uitest='send-feedback-button'
            onClick={this.onSend}
          >
            {i18n.get('INF_FEEDBACK_DIALOG_BUTTON')}
          </Button>
        </div>
      </div>
    );
  };
}

AdvertiserFeedbackForm.propTypes = {
  brand: PropTypes.object.isRequired,
  campaignId: PropTypes.string.isRequired,
  inviteId: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired,
  onSent: PropTypes.func.isRequired
};

AdvertiserFeedbackForm.defaultProps = {
  brand: {},
  campaignId: '',
  inviteId: '',
  request: {},
  onSent: () => {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvertiserFeedbackForm);
