/* eslint-disable no-console */
import config from 'config/app';
import initHotjar from 'config/tracking/hotjar';
import initMixpanel from 'config/tracking/mixpanel';
import initGoogleAnalytics from 'config/tracking/analytics';
import initFacebookPixel from 'config/tracking/facebook';
import initGtagAnalytics from 'config/tracking/gtag';
import { registerEvent as crispRegisterEvent } from 'utils/crispActions';

const DEFAULT_ACTION = 'web';

const isProduction = config.isProduction();

let userId;

const trackMixpanelEvent = (name, properties) => {
  const { mixpanel } = window;
  if (mixpanel) mixpanel.track(name, properties);
};

const trackGoogleAnalyticsEvent = (name, properties) => {
  const { ga } = window;
  const { action = DEFAULT_ACTION, label, value } = properties || {};
  if (ga) ga('send', 'event', name, action, label, value, properties);
};

const trackFacebookEvent = (name, properties) => {
  const { fbq } = window;
  if (fbq) fbq('track', name, properties);
};

const identify = id => {
  const { ga, mixpanel } = window;
  if (mixpanel) mixpanel.identify(id);
  if (ga) ga('set', 'userId', id);
  if (!isProduction) console.log('\nIDENTIFY', id);
};

const track = (name, properties) => {
  trackMixpanelEvent(name, properties);
  trackGoogleAnalyticsEvent(name, properties);
  trackFacebookEvent(name, properties);
  crispRegisterEvent(name, properties);
  if (!isProduction) console.log('\nTRACK EVENT', name, properties);
};

const trackRegisterAttempt = ({ role }) => {
  track('ANY - Register Attempt', { role });
};

const setData = data => {
  userId = data.user_id;
  bootChat(data);
};

export default {
  init() {
    initMixpanel(config.mixpanelToken);
    initFacebookPixel(config.facebookPixelId);
    if (config.googleAnalyticsId) initGoogleAnalytics(config.googleAnalyticsId);
    if (config.googleAnalyticsGTag) {
      initGtagAnalytics(config.googleAnalyticsGTag);
    }
    initHotjar(config.hotjarToken);
  },

  setData,
  identify,
  track,
  trackRegisterAttempt
};
