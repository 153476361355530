"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const PanelActions = require("../actions/panel");
const custom_checkbox_1 = require("./custom-checkbox");
const components_1 = require("apps/shared/components");
const CoverImage = props => {
    const { style, active, className, srcImage, zoom = true, onRemove, onToggle, } = props;
    const showGallery = () => {
        PanelActions.displayOnGallery([srcImage]);
    };
    const handleRemove = (evt) => {
        evt.stopPropagation();
        if (onRemove)
            onRemove(evt);
    };
    const handleToggle = (evt) => {
        evt.stopPropagation();
        if (onToggle)
            onToggle(evt);
    };
    const containerStyle = style;
    let classes = 'vf-coverimage margin-right-Hx vf-clickable';
    if (className)
        classes += ' ' + className;
    return (React.createElement("div", { className: classes, onClick: zoom && showGallery, style: containerStyle, key: srcImage },
        React.createElement(components_1.Img, { src: srcImage, className: 'image-cover image-fit-parent', width: 135, height: 135 }),
        zoom && (React.createElement("button", { className: 'vf-coverimage-hover' },
            React.createElement("div", { className: 'icon-expand' }))),
        onRemove && (React.createElement("div", { className: 'vf-coverimage-overlay vf-clickable', onClick: handleRemove },
            React.createElement("i", { className: 'vf-icon icon-close' }))),
        onToggle && (React.createElement(custom_checkbox_1.default, { value: active, onChange: handleToggle }))));
};
exports.default = CoverImage;
