"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const types_1 = require("modules/shared/types");
const components_1 = require("apps/shared/components");
const PublicParticipationAvatar = ({ socialAccount, publicationTypes }) => (React.createElement("div", { className: 'flex', style: { maxWidth: '100%', flexGrow: 1 } },
    React.createElement(components_1.SocialAvatar, { size: 81, badgeSize: 14, socialAccount: socialAccount, publicationTypes: publicationTypes, style: { flexShrink: 0 } }),
    React.createElement("div", { className: 'start-xs margin-left-1x', style: { flexGrow: 1 } },
        React.createElement("h4", { className: 'vf-font-bold ellipsis width100', style: { whiteSpace: 'pre-wrap' } }, socialAccount.username),
        React.createElement("span", { className: 'vf-text-gray' }, i18n.get('FOLLOWERS_OR_SUBSCRIBERS', {
            followers: i18n.getNumber(socialAccount.audience, 0),
            type: socialAccount.type
        })))));
PublicParticipationAvatar.defaultProps = {
    publicationTypes: [types_1.PublicationType.POST]
};
exports.default = PublicParticipationAvatar;
