"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const React = require("react");
const ui_library_1 = require("ui-library");
const classnames_1 = require("classnames");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const getIsInstagramStory_1 = require("utils/getIsInstagramStory");
const i18n = require("i18n");
const appActions = require("redux/modules/app");
const styles = require("./CampaignBriefLite.less");
const images_1 = require("assets/images");
const containers_1 = require("modules/shared/containers");
const components_1 = require("modules/shared/components");
const ParticipationLiteActions = require("redux/modules/participationLite");
const AvailableAccountsLite_1 = require("./components/AvailableAccountsLite/AvailableAccountsLite");
const HISTORY = 'History';
const ACTIVEPOST = 'ActivePost';
const CampaignBriefLite = ({ campaign, showAlert, isParticipation, isCampaignSoldOut, getParticipationLite }) => {
    var _a, _b, _c;
    const [participations, setParticipations] = React.useState([]);
    const { socialNetworksSpecifications } = campaign;
    const hashtags = lodash_1.get(socialNetworksSpecifications, 'hashtags');
    const mentions = lodash_1.get(socialNetworksSpecifications, 'mentions');
    const link = lodash_1.get(socialNetworksSpecifications, 'link');
    const isHistory = window.localStorage.getItem(HISTORY);
    const isActivePost = window.localStorage.getItem(ACTIVEPOST);
    let getStory;
    let getStoryPost;
    if (isParticipation) {
        if (campaign.socialNetworks.instagram) {
            getStory = getIsInstagramStory_1.getIsInstagramStory(campaign);
            getStoryPost = getIsInstagramStory_1.getIsInstagramPostAndStory(campaign);
        }
    }
    const validateParticipations = () => {
        let check = false;
        participations.map(item => {
            if (item.id === campaign.id) {
                if (item.availableParticipations.length > 0) {
                    if (item.status === 'paused') {
                        check = false;
                    }
                    else {
                        check = true;
                    }
                }
            }
            return React.createElement(React.Fragment, null, check);
        });
        return check;
    };
    const onErrorSettingShippingAddress = () => {
        showAlert({
            message: i18n.get('PAY_IN_KIND_REQUIRED_SHIPPING_ADDRESS_MESSAGE'),
            type: 'warning'
        });
    };
    const onCopy = () => navigator.clipboard.writeText(`${link} ${hashtags.join(' ')} ${mentions.join(' ')}`);
    React.useEffect(() => {
        if (isParticipation) {
            getParticipationLite().then(data => setParticipations(data));
        }
    }, []);
    return (React.createElement(React.Fragment, null, ((validateParticipations() === true || !isCampaignSoldOut) &&
        !isActivePost) ||
        isActivePost ? (React.createElement("div", null,
        React.createElement("div", { className: styles.brief },
            React.createElement("div", null,
                ((_a = campaign === null || campaign === void 0 ? void 0 : campaign.productsPayment) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement("h4", { className: styles.title }, i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_REWARDS'))),
                React.createElement(containers_1.ProductsBriefLite, { currency: campaign === null || campaign === void 0 ? void 0 : campaign.currency, isParticipation: isParticipation, campaign: campaign === null || campaign === void 0 ? void 0 : campaign.productsPayment })),
            React.createElement("h4", { className: styles.title }, i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_PAY_LABEL')),
            React.createElement("div", null,
                React.createElement("h3", { className: styles.subtitle }, i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_DESCRIPTION')),
                React.createElement(components_1.TextWithHighlightedElements, { className: styles.text, text: campaign.description || campaign.brief.description })),
            (link || (hashtags === null || hashtags === void 0 ? void 0 : hashtags.length) > 0 || (mentions === null || mentions === void 0 ? void 0 : mentions.length) > 0) && (React.createElement("div", null,
                React.createElement("h4", { id: 'mandatory', className: styles.subtitle }, i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_MANDATORY_ELEMENTS')),
                React.createElement("p", { className: styles['text--gray'] }, i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_MANDATORY_ELEMENTS_DESCRIPTION')),
                React.createElement("div", { className: 'flex' },
                    link && React.createElement("b", { className: styles.hashtag }, link),
                    hashtags &&
                        hashtags.map(item => (React.createElement("b", { className: styles.hashtag }, item))),
                    mentions &&
                        mentions.map(item => (React.createElement("b", { className: styles.hashtag }, item))),
                    React.createElement(ui_library_1.Button, { color: 'flat', type: 'button', onClick: onCopy, className: `vf-text-primary ${styles.button}` }, "Copy"))))),
        isParticipation && (React.createElement(React.Fragment, null,
            ((validateParticipations() && !isActivePost) ||
                (isActivePost && !isHistory)) && (React.createElement(React.Fragment, null,
                React.createElement("h4", { className: styles.accounts }, i18n.get('INF_VOXEAR_CAMPAIGN_AVAILABLE_ACCOUNTS', {
                    brandName: campaign.brandName
                })),
                React.createElement("p", { className: styles.briefLiteInfo }, i18n.get('INF_VOXEAR_CAMPAIGN_PARTICIPATE_WITH_ONE_ACCOUNT_PER_SOCIAL_NETWORK')),
                React.createElement(AvailableAccountsLite_1.default, { getStory: getStory, campaignId: campaign.id, getStoryPost: getStoryPost, onErrorSettingShippingAddress: onErrorSettingShippingAddress, ship: (campaign === null || campaign === void 0 ? void 0 : campaign.productsPayment) &&
                        ((_c = (_b = campaign === null || campaign === void 0 ? void 0 : campaign.productsPayment[0]) === null || _b === void 0 ? void 0 : _b.deliveryInfo) === null || _c === void 0 ? void 0 : _c.type) ===
                            'ship' }))),
            !validateParticipations() && !isActivePost && !isHistory && (React.createElement("h4", { className: styles.accounts }, i18n.get('INF_VOXEAR_CAMPAIGN_CONNECT_ACCOUNTS', {
                brandName: campaign.brandName
            }))),
            !isHistory && (React.createElement(react_router_dom_1.Link, { to: '/social-accounts', className: `vf-font-bold ${styles.addProfile}` },
                React.createElement("i", { className: 'vf-icon icon-plus' }),
                i18n.get('INF_VOXEAR_CAMPAIGN_LITE_CONNECT_ACCOUNTS'))))))) : (React.createElement("div", { className: classnames_1.default(styles.containerSoldOut) },
        React.createElement("img", { src: images_1.noAvailableCampaign, alt: '' }),
        React.createElement("div", null,
            React.createElement("h3", { className: classnames_1.default(styles.disclaimerTitle, {
                    [styles.disclaimerTitle__login]: !isParticipation
                }) }, i18n.get('INF_VOXEAR_CAMPAIGN_PREVIEW_NO_BUDGET_DISCLAIMER')),
            isParticipation ? (React.createElement("h5", { className: styles.subtitle }, i18n.get('INF_VOXEAR_CAMPAIGN_PREVIEW_NO_BUDGET_TRY_AGAIN'))) : (React.createElement("h2", { className: styles.loginTitle }, i18n.get('INF_VOXEAR_CAMPAIGN_PREVIEW_NO_BUDGET_LOGIN'))))))));
};
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    showAlert: appActions.showAlert,
    getParticipationLite: ParticipationLiteActions.getParticipationLite
}, dispatch);
exports.default = react_redux_1.connect(null, mapDispatchToProps)(CampaignBriefLite);
