import { connect } from 'react-redux';
import {
  getTotalIncompleteSocialAccounts,
  getCurrentInfluencerNewInvites,
  getCurrentInfluencerTotalUnreadMessages
} from 'modules/shared/selectors';

import NavigationBar from 'modules/legacy/js/components/navigation';

function mapStateToProps(state) {
  return {
    totalIncompleteSocialAccounts: getTotalIncompleteSocialAccounts(state),
    totalPendingInvites: getCurrentInfluencerNewInvites(state).length,
    totalUnreadMessages: getCurrentInfluencerTotalUnreadMessages(state)
  };
}

export default connect(mapStateToProps, null)(NavigationBar);
