"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const React = require("react");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const react_router_dom_1 = require("react-router-dom");
const i18n = require("i18n");
const validations_1 = require("config/validations");
const app_1 = require("redux/modules/app");
const accounts_1 = require("redux/modules/accounts");
const user_1 = require("modules/legacy/js/lib/tracking/user");
const withDialog_1 = require("apps/shared/hocs/withDialog");
const app_2 = require("modules/legacy/js/actions/app");
const connectionDialogUI_1 = require("./connectionDialogUI/connectionDialogUI");
const AlreadyLinkedAccountError_1 = require("modules/accounts/influencer/components/AlreadyLinkedAccountError");
const campaignValidations_1 = require("utils/campaignValidations");
const MAX_LONG_FIELD = 50;
const SOCIAL_NETWORK = 'tiktok';
const TiktokModal = props => {
    const [errors, setErrors] = React.useState({});
    const [username, setUserName] = React.useState('');
    const [accountLink, setAccountLink] = React.useState('');
    const validations = () => {
        setErrors({});
        if (username !== '') {
            const wordNumbers = username.trim().split(' ').length;
            if (wordNumbers > 1) {
                setErrors(Object.assign(Object.assign({}, errors), { username: i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_INVALID_USERNAME') }));
            }
        }
        if (username !== '' && accountLink !== '') {
            const serializeUsername = campaignValidations_1.serializeMention(username).toLowerCase();
            const generatedURL = `tiktok.com/@${serializeUsername}`;
            const mobileURL = 'vm.tiktok';
            const inURLPosition = accountLink.toLowerCase().indexOf(generatedURL);
            let nextChar = null;
            if (inURLPosition !== -1) {
                nextChar = accountLink[inURLPosition + generatedURL.length];
            }
            const positionMobileUrl = accountLink.indexOf(mobileURL);
            let isMobileURL = false;
            if (positionMobileUrl !== -1) {
                isMobileURL = true;
            }
            if (accountLink.indexOf('tiktok') === -1) {
                setErrors(Object.assign(Object.assign({}, errors), { link: i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_INVALID_LINK') }));
            }
            else if (!validations_1.validURL(accountLink)) {
                setErrors(Object.assign(Object.assign({}, errors), { link: i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_INVALID_URL') }));
            }
            else if (inURLPosition === -1 && !isMobileURL) {
                setErrors(Object.assign(Object.assign({}, errors), { link: i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_INVALID_TIKTOK_LINK') }));
            }
            else if (nextChar &&
                nextChar !== '/' &&
                nextChar !== '?' &&
                !isMobileURL) {
                setErrors(Object.assign(Object.assign({}, errors), { link: i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_INVALID_TIKTOK_LINK') }));
            }
        }
    };
    React.useEffect(() => {
        validations();
    }, [username, accountLink]);
    const getUpdater = updaterName => {
        switch (updaterName) {
            case 'username':
                return setUserName;
            case 'link':
                return setAccountLink;
            default:
                break;
        }
    };
    const eraseError = name => {
        const resError = errors;
        delete resError[name];
        setErrors(resError);
    };
    const onInputchange = event => {
        const { value, name } = event.target;
        const updater = getUpdater(name);
        if (updater && value.length <= MAX_LONG_FIELD) {
            updater(value.trim());
        }
        eraseError(name);
    };
    const genericErrorHandler = response => {
        const message = lodash_1.get(response, 'payload.data.message');
        const redirectTo = lodash_1.get(props, 'location.pathname');
        app_2.gotoPage(redirectTo, () => {
            app_2.displayAlert({
                type: 'error',
                tittle: i18n.get('INF_SOCIAL_ACCOUNTS_LINKING_ERROR_ALERT_TITLE'),
                message
            });
        });
    };
    const alrreadyLinkedHandler = response => {
        const { showModal, hideModal } = props;
        const usernameResponse = lodash_1.get(response, 'payload.data.meta.username');
        const emailResponse = lodash_1.get(response, 'payload.data.meta.email');
        showModal(React.createElement(AlreadyLinkedAccountError_1.default, { username: usernameResponse, email: emailResponse, onClose: hideModal }));
    };
    const handleSuccess = () => {
        const redirectTo = lodash_1.get(props, 'location.pathname');
        app_2.gotoPage(redirectTo, () => {
            app_2.displayAlert({
                type: 'success',
                tittle: i18n.get('INF_SOCIAL_ACCOUNTS_LINKING_ERROR_ALERT_TITLE'),
                message: i18n.getHTML('INF_SOCIAL_ACCOUNTS_LINKING_SUCCESS_ALERT_TITLE', {
                    username: campaignValidations_1.deserializeMention(username)
                })
            });
        });
    };
    const handleResponse = response => {
        props.onClose();
        if (response && response.type === 'voxfeed/app/REQUEST_FAIL') {
            user_1.default.track('INF - Link SNAccount fail', { socialNetwork: 'tiktok' });
            const isAlrreadyLindedAccount = lodash_1.get(response.payload, 'data.code') ===
                'SOCIAL_NETWORKS::ACCOUNT_ALREADY_LINKED';
            if (isAlrreadyLindedAccount) {
                alrreadyLinkedHandler(response);
            }
            else {
                genericErrorHandler(response);
            }
        }
        else {
            handleSuccess();
        }
    };
    const onConnect = () => {
        const { linkSocialAccount, influencerId } = props;
        const data = {
            socialNetwork: SOCIAL_NETWORK,
            profileType: 'personal',
            authorization: {
                username: campaignValidations_1.serializeMention(username)
            }
        };
        linkSocialAccount(influencerId, data).then(response => handleResponse(response));
    };
    return (React.createElement(connectionDialogUI_1.default, { username: username, accountLink: accountLink, onInputchange: onInputchange, onClose: props.onClose, onConnect: onConnect, errors: errors }));
};
const mapStateToProps = state => {
    const influencerId = lodash_1.get(state, 'shared.user.account.id', null);
    return {
        influencerId
    };
};
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({ showModal: app_1.showModal, hideModal: app_1.hideModal, linkSocialAccount: accounts_1.linkSocialAccount }, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(react_router_dom_1.withRouter(withDialog_1.default(TiktokModal)));
