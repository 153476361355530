"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./CardForm.less");
const CardForm = ({ title, subtitle, children, smallPadding, subtitleBlack }) => (React.createElement("div", { className: classnames_1.default(styles.card, {
        [styles['card--small-padding']]: smallPadding
    }) },
    title && React.createElement("h2", { className: styles.title }, title),
    subtitle && (React.createElement("h3", { className: classnames_1.default(styles['sub-title'], {
            [styles['sub-title--black']]: subtitleBlack
        }) }, subtitle)),
    children));
exports.default = CardForm;
