"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const mediaUploader_1 = require("apps/shared/hocs/mediaUploader");
const components_1 = require("modules/shared/components");
const styles = require("./ChatImagesUploader.less");
class ChatImagesUploader extends React.Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { errors, onErrorsChange } = this.props;
        if (errors.length !== nextProps.errors.length) {
            onErrorsChange(nextProps.errors);
        }
    }
    render() {
        const { onFilesSelected, maxFiles, mediaType, urls, isLoading } = this.props;
        if (isLoading) {
            return (React.createElement(components_1.Spinner, { style: { width: '2.5rem', height: '2.5rem', marginBottom: '1rem' } }));
        }
        const canSelectFiles = !isLoading && urls.length < maxFiles;
        const iconClassName = classnames_1.default('vf-icon icon-plus', styles.icon, {
            [styles.disabled]: !canSelectFiles
        });
        return (React.createElement(components_1.FileInputWrapper, { allowMultipleFiles: maxFiles > 1, filesType: mediaType, canSelectFiles: canSelectFiles, onFilesSelected: files => onFilesSelected(files) },
            React.createElement("i", { className: iconClassName })));
    }
}
ChatImagesUploader.defaultProps = {
    onErrorsChange: () => { }
};
exports.default = mediaUploader_1.default(ChatImagesUploader);
