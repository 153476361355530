import React from 'react';
import Loadable from 'react-loadable';
import Loading from '../LoadingState';

const LoadableComponent = Loadable({
  loader: () => import('./PaypalButton'),
  loading: Loading,
  render(loaded, props) {
    const PaypalButton = loaded.default;
    return <PaypalButton {...props} />;
  }
});

const PaypalButtonLoadable = props => <LoadableComponent {...props} />;

export default PaypalButtonLoadable;
