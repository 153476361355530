"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles = require("../AvailableAccountsLiteUI.less");
const VerifyModalLite_1 = require("../../VerifyModalLite/VerifyModalLite");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const ParticipationModalLite_1 = require("../../ParticipationModalLite/ParticipationModalLite");
const OPENMODAL = 'OpenModal';
const ACCOUNTINFO = 'AccountInfo';
const OPENVERIFYMODAL = 'OpenVerifyModal';
const DialogContainer = ({ postLink, brandName, onCloseModal, continueToVox, participationModal }) => {
    const openModal = window.localStorage.getItem(OPENMODAL);
    const accountInfo = window.localStorage.getItem(ACCOUNTINFO);
    const openVerifyModal = window.localStorage.getItem(OPENVERIFYMODAL);
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomDialog_1.default, { size: 'xs', dismissable: false, className: styles.dialog, isOpen: participationModal || !!openModal, content: React.createElement(ParticipationModalLite_1.default, { postLink: postLink, accountInfo: accountInfo, onCloseModal: onCloseModal }) }),
        React.createElement(CustomDialog_1.default, { size: 'xs', dismissable: false, className: styles.dialog, isOpen: !!openVerifyModal, content: React.createElement(VerifyModalLite_1.default, { onCloseModal: continueToVox, brandName: brandName }) })));
};
exports.default = DialogContainer;
