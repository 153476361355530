"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const get = require("lodash/get");
const i18n = require("i18n");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const components_1 = require("modules/shared/components");
const images_1 = require("assets/images");
const components_2 = require("apps/shared/components");
const app_1 = require("config/app");
const PartnerMessageDialogUI = ({ isLoading, isOpen, partner, onContinue }) => {
    const partnerName = get(partner, 'name');
    const companyName = app_1.getDefaultTitle();
    const header = isLoading || !partner ? (React.createElement("noscript", null)) : (React.createElement("img", { src: get(partner, 'logo'), alt: 'Partner\u2019s logo', style: { maxHeight: '4rem' } }));
    const content = isLoading || !partner ? (React.createElement(components_1.LoadingState, null)) : (React.createElement("div", { className: 'padding-2x vf-text-black', style: { fontSize: '2rem' } },
        React.createElement("b", null, i18n.get('INF_PARTNERS_DIALOG_TITLE', { partnerName, companyName })),
        React.createElement("p", { className: 'margin-top-1x' }, i18n.get('INF_PARTNERS_DIALOG_MESSAGE', {
            partnerName,
            companyName
        })),
        React.createElement("div", { className: 'flex center-xs margin-top-1x' },
            React.createElement(ui_library_1.Button, { color: 'primary', className: 'flex', onClick: onContinue },
                i18n.get('INF_PARTNERS_DIALOG_BUTTON'),
                React.createElement(components_2.Img, { className: 'image-cover', src: images_1.logoVoxfeedWhiteImg, width: 24, height: 72, style: {
                        height: '3rem',
                        width: '9rem',
                        marginLeft: '0.5rem'
                    } })))));
    return (React.createElement(CustomDialog_1.default, { size: 'sm', dismissable: false, isOpen: isOpen, header: header, content: content }));
};
exports.default = PartnerMessageDialogUI;
