import React from 'react';
import PropTypes from 'prop-types';
import { isFinite } from 'lodash';
import classNames from 'classnames';
import i18n from 'i18n';
import shouldShowSocialAccountReliability from '../../../utils/shouldShowSocialAccountReliability';
import { getDefaultTitle } from 'config/app';

export default function ReliabilityPopover({ percentage, socialAccount }) {
  if (
    isFinite(percentage) &&
    shouldShowSocialAccountReliability(socialAccount)
  ) {
    return (
      <ReliabilityPopoverForInfluencersWithActivity percentage={percentage} />
    );
  }

  return <ReliabilityPopoverForInfluencersWithoutActivity />;
}

ReliabilityPopover.propTypes = {
  percentage: PropTypes.number,
  socialAccount: PropTypes.object
};

const getReliabilityProps = reliability => {
  const companyName = getDefaultTitle();
  const propsByReliability = {
    good: {
      className: 'vf-text-primary',
      text: i18n.get('RELIABILITY_POPOVER_GOOD_RELIABILITY', { companyName })
    },
    regular: {
      className: 'vf-text-warning',
      text: i18n.get('RELIABILITY_POPOVER_REGULAR_RELIABILITY', { companyName })
    },
    bad: {
      className: 'vf-text-danger',
      text: i18n.get('RELIABILITY_POPOVER_BAD_RELIABILITY', { companyName })
    }
  };

  if (reliability > 75) return propsByReliability.good;
  if (reliability > 50 && reliability < 76) return propsByReliability.regular;
  return propsByReliability.bad;
};

const ReliabilityPopoverForInfluencersWithActivity = ({ percentage = 0 }) => {
  const reliabilityProps = getReliabilityProps(percentage);
  const textClassName = classNames(
    'vf-txt-xxxl vf-font-light',
    reliabilityProps.className
  );

  return (
    <div>
      <div className='flex'>
        <div className='center-xs col-xs-12 padding-Hx'>
          <h1 className={textClassName}>{percentage}%</h1>
          <h5>{i18n.get('RELIABILITY_POPOVER_RELIABILITY')}</h5>
        </div>
      </div>

      <div className='flex'>
        <div className='col col-xs-12 center-xs padding-Hx'>
          <span>{reliabilityProps.text}</span>
        </div>
      </div>
    </div>
  );
};

ReliabilityPopoverForInfluencersWithActivity.propTypes = {
  percentage: PropTypes.number,
  score: PropTypes.object
};

const ReliabilityPopoverForInfluencersWithoutActivity = () => (
  <div>
    <div className='flex'>
      <div className='center-xs col-xs-12 padding-Hx'>
        <h2 className='margin-top-Hx'>
          {i18n.get('RELIABILITY_POPOVER_INSUFFICIENT_ACTIVITY')}
        </h2>
      </div>
    </div>

    <div className='flex'>
      <div className='col col-xs-12 center-xs padding-Hx'>
        <span>{i18n.get('RELIABILITY_POPOVER_NOT_ENOUGHT_INFO')}</span>
      </div>
    </div>
  </div>
);
