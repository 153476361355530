import React from 'react';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';

import { getCookie, setCookie } from 'utils/cookies';
import Tour from 'modules/legacy/js/components/tour';
import { matchPath, withRouter } from 'react-router-dom';

const DEFAULT_INITIAL_STEP = 0;
const DONE_STATUS = 'done';

const AdvertiserLandingTour = createClass({
  propTypes: {
    cookieName: PropTypes.string.isRequired,
    steps: PropTypes.array.isRequired,
    prevRequiredTour: PropTypes.string,
    path: PropTypes.string,
    nextTour: PropTypes.any
  },

  getInitialState() {
    const { steps, cookieName } = this.props;
    const tourCachedStep = getCookie(cookieName);
    const parsedSteps = steps.map(step => {
      const { selector, position, className, component: Component } = step;

      return {
        selector,
        position,
        className,
        content: <Component onNext={this.onNext} onBack={this.onBack} />
      };
    });

    return {
      steps: parsedSteps,
      currentStep: this.getCurrentStep(),
      tourHasNeverBeenStarted: tourCachedStep === null,
      isTourActive: tourCachedStep !== DONE_STATUS,
      didTourFinish: tourCachedStep === DONE_STATUS
    };
  },

  getCurrentStep() {
    const { cookieName, prevRequiredTour } = this.props;
    const tourCachedStep = getCookie(cookieName);
    const tourWasAlreadyFinished = tourCachedStep === DONE_STATUS;
    const prevRequiredTourStep = prevRequiredTour
      ? getCookie(prevRequiredTour)
      : DONE_STATUS;

    if (prevRequiredTourStep !== DONE_STATUS) return null;
    return !tourWasAlreadyFinished
      ? parseInt(tourCachedStep || DEFAULT_INITIAL_STEP, 10)
      : null;
  },

  onNext() {
    const { cookieName } = this.props;
    const { currentStep, steps } = this.state;
    const nextStep = currentStep + 1;
    const tourWillEnd = nextStep > steps.length - 1;

    setCookie({ name: cookieName, value: tourWillEnd ? 'done' : nextStep });

    this.setState({
      currentStep: nextStep,
      isTourActive: nextStep <= steps.length,
      tourHasNeverBeenStarted: false,
      didTourFinish: tourWillEnd
    });
  },

  onBack() {
    const { cookieName } = this.props;
    const { currentStep } = this.state;
    const nextStep = currentStep - 1;

    setCookie({ name: cookieName, value: nextStep < 0 ? 0 : nextStep });

    this.setState({
      currentStep: nextStep,
      didTourFinish: false
    });
  },

  onSkip() {
    const { cookieName } = this.props;
    const { steps } = this.state;

    setCookie({ name: cookieName, value: 'done' });

    this.setState({
      currentStep: steps.length,
      isTourActive: false,
      tourHasNeverBeenStarted: false
    });
  },

  render() {
    const { nextTour: NextTour, path } = this.props;
    const {
      currentStep,
      isTourActive,
      steps,
      tourHasNeverBeenStarted,
      didTourFinish
    } = this.state;

    let match = true;
    if (path) {
      match = matchPath(this.props.location.pathname, {
        path: path,
        exact: true,
        strict: false
      });
    }

    return (
      <span>
        <Tour
          showDots
          currentStep={currentStep}
          visible={isTourActive && !!match}
          steps={steps}
          showBeacon={!tourHasNeverBeenStarted}
          onSkip={this.onSkip}
        />
        {didTourFinish && NextTour && <NextTour />}
      </span>
    );
  }
});

export default withRouter(AdvertiserLandingTour);
