"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const sharedActions = require("redux/modules/shared");
const appActions = require("redux/modules/app");
const qs = require("query-string");
const AppUI_1 = require("./AppUI/AppUI");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const lodash_1 = require("lodash");
class App extends React.Component {
    constructor() {
        super(...arguments);
        this.fetchInfo = () => {
            const { session, loginRequest, fetchPartnerSettings, fetchSystemSettings, showLoading, hideLoading } = this.props;
            showLoading();
            const promises = [fetchPartnerSettings(), fetchSystemSettings()];
            if (session.token) {
                promises.push(loginRequest({
                    token: session.token,
                    redirectTo: this.getRedirectionParams()
                }));
            }
            Promise.all(promises).finally(hideLoading);
        };
        this.getRedirectionParams = () => {
            const { location } = this.props;
            const query = qs.parse(location.search);
            const redirectTo = lodash_1.get(query, 'redirectTo');
            const queryString = location.search;
            return redirectTo
                ? `${redirectTo}?${queryString}`
                : `${location.pathname}${location.search}${location.hash}`;
        };
    }
    componentDidMount() {
        this.fetchInfo();
    }
    render() {
        const { session, partnerSettings, children } = this.props;
        return (React.createElement(AppUI_1.default, { session: session, partnerSettings: partnerSettings }, children));
    }
}
const mapStateToProps = state => ({
    user: lodash_1.get(state, 'shared.user'),
    session: lodash_1.get(state, 'shared.session'),
    partnerSettings: lodash_1.get(state, 'app.partnerSettings')
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    loginRequest: sharedActions.loginRequest,
    fetchPartnerSettings: appActions.fetchPartnerSettings,
    fetchSystemSettings: appActions.fetchSystemSettings,
    hideLoading: appActions.hideLoading,
    showLoading: appActions.showLoading
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(App);
