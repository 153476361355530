"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const is = require("is_js");
const emailValidator = (Component) => class EmailValidator extends React.Component {
    constructor() {
        super(...arguments);
        this.isValid = (email) => {
            return is.email(email);
        };
    }
    render() {
        return (React.createElement(Component, Object.assign({}, this.props, { validateEmail: this.isValid })));
    }
};
exports.default = emailValidator;
