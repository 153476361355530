"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ReactPopover = require("react-popover");
const react_slick_1 = require("react-slick");
const get = require("lodash/get");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const types_1 = require("modules/shared/types");
const AddressLink_1 = require("apps/shared/components/AddressLink");
const components_1 = require("modules/shared/components");
const styles = require("./ProductPopoverUI.less");
class ProductPopoverUI extends React.PureComponent {
    render() {
        const { children, isLoading, isOpen, onBlur, onMouseEnter, position, products, currency } = this.props;
        return (React.createElement(ReactPopover, { body: isLoading ? (React.createElement(components_1.LoadingState, null)) : (React.createElement(Body, { onClose: onBlur, products: products, currency: currency })), className: classnames_1.default(`popover-without-padding popover-clip-${position}`, styles.container), isOpen: isOpen, onOuterAction: onBlur, preferPlace: position, tipSize: 0.01 },
            React.createElement("span", { onMouseEnter: onMouseEnter }, children)));
    }
}
ProductPopoverUI.defaultProps = {
    position: 'above'
};
exports.default = ProductPopoverUI;
const Body = ({ onClose, products, currency }) => (React.createElement("div", { style: { width: 232, overflow: 'hidden' } },
    React.createElement(react_slick_1.default, Object.assign({}, settings), products.map(p => (React.createElement(Item, { key: p.id, onClose: onClose, product: p, currency: currency }))))));
const Item = ({ onClose, product, currency }) => {
    const deliveryInfo = get(product, 'deliveryInfo');
    return (React.createElement("div", { className: styles.popover },
        React.createElement("div", { className: classnames_1.default(styles.info, 'info-section') },
            React.createElement("div", { className: 'width100 ellipsis', title: product.name },
                product.quantity,
                " ",
                product.name),
            React.createElement("p", null, i18n.getMoney({
                amount: product.price * product.quantity,
                currency
            }))),
        React.createElement("i", { className: classnames_1.default('vf-icon icon-close', styles.close), onClick: ev => {
                ev.stopPropagation();
                onClose();
            } }),
        React.createElement("span", { className: styles.address }, !deliveryInfo ||
            deliveryInfo.type === types_1.ProductDeliveryType.NOT_APPLICABLE ? (React.createElement("b", null, i18n.get('PAY_IN_KIND_ADDRESS_NO_REQUIRED'))) : (React.createElement(AddressLink_1.default, { address: deliveryInfo.address, deliveryType: deliveryInfo.type }))),
        React.createElement("div", { className: styles.overlay }),
        React.createElement(components_1.SafeImage, { url: product.images[0], width: 232, height: 184 })));
};
const Arrow = ({ isLeft, onClick }) => (React.createElement("div", { className: classnames_1.default(styles.arrow, {
        [styles.left]: isLeft,
        [styles.right]: !isLeft
    }), onClick: ev => {
        ev.stopPropagation();
        onClick();
    } },
    React.createElement("i", { className: classnames_1.default('vf-icon icon-fix', {
            'icon-arrow-left': isLeft,
            'icon-arrow-right': !isLeft
        }) })));
const settings = {
    dots: false,
    infinite: true,
    variableWidth: true,
    prevArrow: React.createElement(Arrow, { isLeft: true }),
    nextArrow: React.createElement(Arrow, null)
};
