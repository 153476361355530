"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PinkWarningBox = void 0;
const React = require("react");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const images_1 = require("assets/images");
const app_1 = require("config/app");
const styles = require("./PinkDisclaimerDialog.less");
const components_1 = require("apps/shared/components");
const PinkDisclaimerDialog = ({ isOpen, onClose, onConfirm, children, style }) => (React.createElement(components_1.Dialog, { isOpen: isOpen, onClose: onClose },
    React.createElement("div", { className: styles.dialog, style: style },
        React.createElement(components_1.DialogHeader, { onClose: onClose },
            React.createElement("h2", { className: 'flex' },
                React.createElement("i", { className: 'vf-icon icon-fix icon-bag' }),
                React.createElement("span", { className: 'margin-left-Hx' }, i18n.get('ADV_CAMPAINGS_WIZARD_PRODUCTS_TITLE')))),
        React.createElement("div", { style: { padding: '2rem' } },
            React.createElement("div", { className: classnames_1.default(styles['warning-box'], 'flex') },
                React.createElement("i", { className: 'vf-icon icon-fix icon-exclamation-triangle margin-left-1x' }),
                React.createElement("p", { className: 'vf-bold margin-left-1x', style: { fontSize: '2.2rem' } }, i18n.get('ADV_CAMPAINGS_WIZARD_PRODUCTS_WARNING_BOX', {
                    companyName: app_1.getDefaultTitle()
                }))),
            React.createElement("ul", { className: styles.list }, children),
            React.createElement("div", { className: classnames_1.default(styles.footer, 'flex'), style: { padding: '0 2rem' } },
                React.createElement("p", null, i18n.getHTML('ADV_CAMPAINGS_WIZARD_PRODUCTS_WARNING_LINK', {
                    link: i18n.get('HELP_LINKS_TERMS')
                })),
                React.createElement(ui_library_1.Button, { "data-uitest": 'pay-in-kind-disclaimer-accept', style: { marginLeft: 'auto', padding: '1rem 4rem' }, color: 'primary', onClick: onConfirm }, i18n.get('INF_PERFORMANCE_PUBLISH_UNDERSTAND_BUTTON')))))));
exports.default = PinkDisclaimerDialog;
exports.PinkWarningBox = ({ lite, onViewMore }) => (React.createElement("div", { className: classnames_1.default(styles['warning-box'], styles['warning-box__top'], 'flex padding-left-1x', {
        [styles['warning-box__top--lite']]: lite
    }) }, lite ? (React.createElement("div", { className: classnames_1.default('vf-text-warning', styles['warning-box__msg']) },
    React.createElement("img", { src: images_1.iconAlert, alt: 'warning' }),
    React.createElement("span", null, i18n.get('ADV_CAMPAIGNS_WIZARD_REWARD_WARNING')))) : (React.createElement(React.Fragment, null,
    React.createElement("i", { className: 'vf-icon icon-fix icon-exclamation-triangle', style: { fontSize: '2rem', marginRight: '1rem' } }),
    React.createElement("div", { className: 'vf-bold' },
        i18n.get('ADV_CAMPAINGS_WIZARD_PRODUCTS_MINI_WARNING'),
        ' ',
        React.createElement("span", { className: 'vf-primary-text vf-clickable', onClick: onViewMore }, i18n.get('ADV_CAMPAIGNS_VIEW_MORE')))))));
