"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./ImagePreviewUI.less");
const components_1 = require("apps/shared/components");
const ImagePreviewUI = (_a) => {
    var { src, sizes, width, height, className, onOpenGallery } = _a, attributes = __rest(_a, ["src", "sizes", "width", "height", "className", "onOpenGallery"]);
    return (React.createElement("div", Object.assign({ className: classnames_1.default(className, styles.wrapper) }, attributes),
        React.createElement(components_1.Img, { src: src, sizes: sizes, height: height, width: width, className: 'image-cover image-fit-parent' }),
        React.createElement("button", { onClick: onOpenGallery, className: classnames_1.default(styles.overlay, 'vf-clear-button') },
            React.createElement("i", { className: 'vf-icon icon-expand icon-fix' }))));
};
exports.default = ImagePreviewUI;
