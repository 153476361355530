import { merge, keys, get, debounce, map, isEmpty } from 'lodash';
import request from 'superagent';
import io from 'socket.io-client';

import { setSocketId } from 'services/voxfeed/configurator';

const nprogress = require('nprogress').configure({
  showSpinner: false,
  trickleRate: 0.08
});

let progress = 0;
let socketId;
let stream;

const debouncedDone = debounce(nprogress.done, 200);

const progressCount = change => {
  progress += change;

  if (progress > 0) {
    if (progress === 1) nprogress.inc();
  } else {
    debouncedDone();
  }
};

const notImplemented = () => {
  throw new Error('Implement me!');
};

// eslint-disable-next-line func-names
const DataManager = function () {
  const _config = {
    adapter: {
      onUpdate: notImplemented,
      onRemove: notImplemented
    },
    host: '',
    headers: {},
    streamPath: '/app'
  };

  const _onSocketConnect = () => {
    socketId = stream.id;
    setSocketId(socketId);
  };

  const _init = () => {
    const hasExistingStream = !!stream;
    stream =
      stream ||
      io.connect(`${_config.host}${_config.streamPath}`, {
        reconnection: true
      });

    if (hasExistingStream) return;

    stream.on('connect', _onSocketConnect);
    stream.on('creates', data => _config.adapter.onUpdate(null, data));
    stream.on('updates', data => _config.adapter.onUpdate(null, data));
    stream.on('deletes', data => _config.adapter.onRemove(null, data));

    window.addEventListener('beforeunload', stream.close);

    progressCount(0);
  };

  const _sendStandardRequest = (requestConfig, callback) => {
    const requestObject = request(
      requestConfig.method,
      _config.host + requestConfig.path
    );
    const attachments = [];

    const payload = keys(requestConfig.data).reduce((response, key) => {
      const value = get(requestConfig, ['data', key]);
      const attachment = get(value, ['attach']);
      if (!attachment) return Object.assign({}, response, { [key]: value });
      attachments.push({ key, data: attachment, name: attachment.name });
      return response;
    }, {});

    if (isEmpty(attachments)) {
      requestObject.send(payload);
    } else {
      keys(payload).forEach(key => requestObject.field(key, payload[key]));
    }

    attachments.forEach(attachment => {
      const { key, data, name } = attachment;
      requestObject.attach(key, data, name);
    });

    const runCallback = (err, res) => {
      progressCount(-1);
      if (callback) callback(err, res && res.body, res);
      _config.adapter.onUpdate(err, res && res.body);
    };

    if (socketId) requestObject.set('x-socket-id', socketId);

    requestObject.set(requestConfig.headers).end(runCallback);
  };

  const _buildURI = (path, data) => {
    if (!data) return path;

    const query = map(data, (el, key) => `${key}=${el}`).join('&');
    return `${path}?${query}`;
  };

  const publicMethods = {
    setConfiguration(newConfig) {
      merge(_config, newConfig);
      _init();
    },

    request(requestOptions, tmpConfig, callback) {
      progressCount(+1);

      const newConfig = merge({}, _config, tmpConfig);

      if (requestOptions.data) {
        merge(newConfig, {
          'Content-Length': JSON.stringify(requestOptions.data).length
        });
      }

      const requestConfig = {
        method: requestOptions.method,
        path: encodeURI(requestOptions.path),
        data: requestOptions.data,
        headers: newConfig.headers
      };

      _sendStandardRequest(requestConfig, callback);
    },

    get(path, data, tmpConfig, callback) {
      const requestURI = _buildURI(path, data);

      this.request(
        {
          method: 'GET',
          path: requestURI,
          data: null
        },
        tmpConfig,
        callback
      );
    },

    post(path, data, tmpConfig, callback) {
      this.request(
        {
          method: 'POST',
          path,
          data
        },
        tmpConfig,
        callback
      );
    },

    put(path, data, tmpConfig, callback) {
      this.request(
        {
          method: 'PUT',
          path,
          data
        },
        tmpConfig,
        callback
      );
    },

    del(path, data, tmpConfig, callback) {
      this.request(
        {
          method: 'DELETE',
          path,
          data
        },
        tmpConfig,
        callback
      );
    }
  };

  return publicMethods;
};

const createDataManager = () => {
  let instance;

  return {
    getInstance() {
      if (!instance) instance = new DataManager();
      return instance;
    }
  };
};

module.exports = createDataManager();
