"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const enhanceWithClickOutside = require("react-click-outside");
const styles = require("./SelectWrapper.less");
class SelectWrapper extends React.Component {
    constructor() {
        super(...arguments);
        this.handleButtonClick = () => {
            const { onToggle, isOpen, disabled } = this.props;
            if (disabled)
                return;
            onToggle(!isOpen);
        };
        this.handleClickOutside = () => {
            const { onToggle } = this.props;
            onToggle(false);
        };
    }
    render() {
        const _a = this.props, { children, className, disabled, selectedValue, isOpen, listStyle, name, selectedKey } = _a, other = __rest(_a, ["children", "className", "disabled", "selectedValue", "isOpen", "listStyle", "name", "selectedKey"]);
        const wrapperClass = classnames_1.default(styles.wrapper, className, {
            [styles['wrapper-open']]: isOpen,
            [styles.disabled]: disabled
        });
        const listClass = classnames_1.default(styles.list, {
            [styles['list-open']]: isOpen
        });
        return (React.createElement("div", Object.assign({ className: wrapperClass }, other),
            React.createElement("input", { type: 'text', name: name, value: selectedKey, style: { display: 'none' }, onChange: () => { } }),
            React.createElement("div", { className: classnames_1.default('flex', styles.button), onClick: this.handleButtonClick },
                selectedValue,
                !disabled && (React.createElement("span", { className: styles.icon },
                    React.createElement("i", { className: classnames_1.default('vf-icon', {
                            'icon-caret-down-two': !isOpen,
                            'icon-caret-up-two': isOpen
                        }) })))),
            React.createElement("div", { className: listClass, style: listStyle }, children)));
    }
}
const Comp = enhanceWithClickOutside(SelectWrapper);
exports.default = Comp;
