import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Textarea } from 'ui-library';
import { bindActionCreators } from 'redux';
import { map, get } from 'lodash';
import i18n from 'i18n';

import { getStatics } from 'config/app';

import { rejectInvite } from 'redux/modules/campaigns';
import { resetRequest } from 'redux/modules/app';

import RadioDescription from 'modules/legacy/js/components/radio-description';
import SocialAvatarInfluencer from 'modules/legacy/js/components/social-avatar-influencer';
import Confirm from 'modules/legacy/js/components/dialog-confirm';

const REQUEST_REJECT_INVITE_ID = 'rejectInvite';

function mapStateToProps({ app }) {
  const { requests } = app;
  const rejectInviteRequest = requests[REQUEST_REJECT_INVITE_ID];
  return { rejectInviteRequest };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ rejectInvite, resetRequest }, dispatch);
}

class RejectInviteDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rejectionOption: null,
      rejectionReason: '',
      textareaContent: ''
    };
  }

  UNSAFE_componentWillReceiveProps = props => {
    const { rejectInviteRequest = {} } = props;

    if (rejectInviteRequest.status === 'success') this.onRejectInvite(props);
    if (rejectInviteRequest.status === 'fail') this.onRejectInvite(props);
  };

  onRejectInvite = props => {
    const { rejectInviteRequest = {} } = props;

    this.props.resetRequest(REQUEST_REJECT_INVITE_ID);
    this.props.onConfirm(rejectInviteRequest.response);
  };

  rejectInvite = () => {
    const { rejectionReason } = this.state;
    const { rejectInvite, invite } = this.props;

    const params = {
      rejectionReason,
      _id: invite._id
    };

    rejectInvite(params);
  };

  onChangeText = () => {
    const text = ReactDOM.findDOMNode(this.refs.rejectionText).value;
    this.setState({ textareaContent: text, rejectionReason: text });
  };

  onSelectOption = key => {
    const text = get(getStatics(), `invites.rejectionReasons.${key}`);
    let pushState = { rejectionOption: key, rejectionReason: text };
    if (key === 'other') {
      pushState.rejectionReason = this.state.textareaContent;
    }
    this.setState(pushState);
  };

  getTextArea = () => {
    const { rejectionOption } = this.state;

    if (rejectionOption !== 'other') return null;
    return (
      <div className='width100'>
        <Textarea
          onChange={this.onChangeText}
          value={this.state.textareaContent}
          id='rejection-text'
          ref='rejectionText'
          size='md'
        />
      </div>
    );
  };

  render() {
    const { socialAccount, children } = this.props;
    const REJECTION_REASONS = get(getStatics(), 'invites.rejectionReasons');

    const options = map(REJECTION_REASONS, (description, key) => {
      return (
        <div key={key} className='padding-top-1x'>
          <RadioDescription
            key={key}
            active={this.state.rejectionOption === key}
            description={description}
            onClick={this.onSelectOption.bind(null, key)}
          />
        </div>
      );
    });

    return (
      <Confirm
        onAccept={this.rejectInvite}
        onReject={this.props.onCancel}
        title={i18n.get('INF_VOXEAR_CAMPAIGN_REJECT_INVITATION')}
      >
        <div className='flex flex-wrap'>
          <div className='flex-column'>
            <SocialAvatarInfluencer socialAccount={socialAccount} />
            <div className='flex flex-wrap padding-top-2x'>
              <div>{children}</div>
              <div className='flex flex-start'>
                <div>{options}</div>
              </div>
              <div className='margin-top-1x width100'>{this.getTextArea()}</div>
            </div>
          </div>
        </div>
      </Confirm>
    );
  }
}

RejectInviteDialog.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  socialAccount: PropTypes.object,
  rejectInvite: PropTypes.func,
  invite: PropTypes.object,
  rejectInviteRequest: PropTypes.object,
  resetRequest: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectInviteDialog);
