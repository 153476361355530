import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import moment from 'moment-timezone';

const DateLabel = createClass({
  propTypes: {
    date: PropTypes.any
  },

  getDateString() {
    const { date } = this.props;
    const timeZone = moment.tz.guess();

    return moment.tz(date, timeZone).format('LL');
  },

  render() {
    return <h4>{this.getDateString()}</h4>;
  }
});

export default DateLabel;
