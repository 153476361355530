import store from 'store2';
import set from 'lodash/set';

const STORAGE_PREFIX = 'vf-campaign';

function setBrandCampaignData(brandId, data) {
  const key = `${STORAGE_PREFIX}-${brandId}`;
  const dataStr = JSON.stringify(data);

  store.set(key, dataStr);
  return key;
}

export function getBrandCampaignData(brandId) {
  const key = `${STORAGE_PREFIX}-${brandId}`;
  const dataStr = store.get(key) || '{}';

  return Promise.resolve({
    id: `current_${brandId}`,
    modelName: 'Campaign',
    data: JSON.parse(dataStr)
  });
}

export function setBrandCampaign({brandId, data}) {
  setBrandCampaignData(brandId, data);

  return Promise.resolve({
    data,
    id: `current_${brandId}`,
    modelName: 'Campaign'
  });
}

export function updateBrandCampaignField(args) {
  const {brandId, fieldPath, value} = args;

  return getBrandCampaignData(brandId)
    .then((cachedCampaign) => {
      const data = cachedCampaign.data || {};
      const updatedCampaign = set(data, fieldPath, value);

      setBrandCampaignData(brandId, updatedCampaign);

      return {
        id: `current_${brandId}`,
        modelName: 'Campaign',
        data: updatedCampaign
      };
    });
}

export function discardBrandLastIncompleteCampaign(brandId) {
  const key = `${STORAGE_PREFIX}-${brandId}`;

  store.remove(key);

  return Promise.resolve();
}

export function discardAllData() {
  store.keys().forEach((key) => {
    if (key.startsWith(STORAGE_PREFIX)) {
      store.remove(key);
    }
  });
}
