import _ from 'lodash';
import Reflux from 'reflux';

import { defaultAvatarImg } from 'assets/images';

const Actions = {
  dataStore: require('../actions/dataStore'),
  app: require('../actions/app')
};

const stores = {
  currentAccount: require('../stores/currentAccount'),
  dataStore: require('../stores/dataStore'),
  configStore: require('../stores/configStore')
};

/**
 * Default data schema.
 * @const DEFAULT_DATA <private>π
 */

const DEFAULT_EXTENDED = {
  socialNetwork: {},
  avatar: defaultAvatarImg
};

const DEFAULT_DATA = {};

module.exports = Reflux.createStore({
  init() {
    this.listenTo(stores.configStore, this._onConfigUpdate);
    this.listenTo(stores.dataStore, this._onUpdate);
    this.listenTo(Actions.app.updateUserInfo, this._onUpdate);
    this.listenTo(Actions.dataStore.destroy, this._onDestroy);

    if (typeof window !== 'undefined') window.user = this._data;
  },

  _data: _.assign({}, DEFAULT_DATA),
  _extendData: _.assign({}, DEFAULT_EXTENDED),

  getAll() {
    return this._data;
  },

  _onConfigUpdate(configStore = {}) {
    const dataStore = stores.dataStore.getAll();

    if (!configStore || !dataStore) return;

    if (configStore.ActiveSocialNetwork && dataStore.SocialNetworkAccount) {
      _.assign(this._extendData, {
        socialNetwork:
          dataStore.SocialNetworkAccount[configStore.ActiveSocialNetwork]
      });
    }

    _.assign(this._data, {
      extended: this._extendData
    });
    this.trigger(this._data);
  },

  _onUpdate(dataStore = stores.dataStore.getAll()) {
    const configStore = stores.configStore.getAll();
    const userId = configStore.ActiveUserId;

    // if there is no user, or no ID, return...
    if (!userId || !dataStore.User || !dataStore.User[userId]) return null;

    this._extendData = _.assign({}, dataStore.extended);

    const userAvatarId = dataStore.User[userId].profileImage;
    if (
      userAvatarId &&
      dataStore.Image &&
      dataStore.Image[userAvatarId] &&
      dataStore.Image[userAvatarId].sizes
    ) {
      const imgSizes = dataStore.Image[userAvatarId].sizes;

      this._extendData.avatar = imgSizes['thumbnail-2x']
        ? imgSizes['thumbnail-2x'].url
        : imgSizes.original.url;
    }

    this._data = _.assign({}, dataStore.User[userId], {
      extended: this._extendData
    });
    this.trigger(this._data);
  },

  _onDestroy() {
    this._data = JSON.parse(JSON.stringify(DEFAULT_DATA));
    this.trigger(this._data);
  }
});
