import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, find } from 'lodash';
import classNames from 'classnames';
import { Button } from 'ui-library';
import i18n from 'i18n';

import getChatName from 'utils/getChatName';

import * as chatsActions from 'redux/modules/chats';

import {
  getChatMembers,
  getCurrentUser,
  getBrand
} from 'modules/shared/selectors';

import { BrandAvatar } from 'modules/shared/components';
import { ChatWithInfluencer } from 'modules/brands/advertiser/containers';
import { ChatWithBrand } from 'modules/campaigns/influencer/containers';
import BrandSelector from '../BrandSelector/BrandSelector';

import styles from './FloatingChat.less';

const MAX_UNREAD_MESSAGES = 9;

function mapStateToProps(state, { chatId, brandId }) {
  return {
    chat: get(state, `entities.Chat.${chatId}`),
    brand: getBrand(state, brandId),
    members: getChatMembers(state, chatId),
    currentUser: getCurrentUser(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      collapseChat: chatsActions.collapseFloatingChat,
      expandChat: chatsActions.expandFloatingChat,
      closeChat: chatsActions.closeFloatingChat,
      fetchChat: chatsActions.openChatWithInfluencer
    },
    dispatch
  );
}

class FloatingChat extends Component {
  static propTypes = {
    chatId: PropTypes.string,
    brandId: PropTypes.string,
    influencerId: PropTypes.string,
    chat: PropTypes.object,
    brand: PropTypes.object,
    members: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    isCollapsed: PropTypes.bool,
    className: PropTypes.string,
    collapseChat: PropTypes.func.isRequired,
    expandChat: PropTypes.func.isRequired,
    closeChat: PropTypes.func.isRequired,
    fetchChat: PropTypes.func.isRequired,
    allowToSwitchBrand: PropTypes.bool
  };

  static defaultProps = {
    chatId: '',
    brandId: '',
    brand: {},
    influencerId: '',
    isCollapsed: false,
    className: '',
    chat: null,
    allowToSwitchBrand: false
  };

  constructor(props) {
    super(props);

    this.state = {
      showBrandSelector: !props.chat,
      isFocused: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.chat && nextProps.chat !== this.props.chat) {
      this.setState({ showBrandSelector: false });
    }
  }

  onToggle = () => {
    const { isCollapsed, chatId } = this.props;
    if (isCollapsed) return this.props.expandChat(chatId);
    this.props.collapseChat(chatId);
  };

  onClose = ev => {
    ev.stopPropagation();
    this.props.closeChat(this.props.chatId);
  };

  onSelectBrand = brandId => {
    const { influencerId, chatId, fetchChat } = this.props;

    fetchChat({
      brandId,
      influencerId,
      previousChatId: chatId
    });
  };

  renderPendingMessagesBadge = () => {
    const { chat, chatId, members, currentUser } = this.props;

    if (!chat) return <noscript />;

    const currentMember = find(
      members,
      m => m.userId === currentUser._id && m.chatId === chatId
    );
    const lastReadMessageIndex = get(
      currentMember,
      'lastReadMessage.index',
      -1
    );
    const totalUnreadMessages = Math.max(
      chat.totalMessages - (lastReadMessageIndex + 1),
      0
    );

    if (!currentMember || !totalUnreadMessages) return <noscript />;

    const badgeText =
      totalUnreadMessages > MAX_UNREAD_MESSAGES
        ? `+${MAX_UNREAD_MESSAGES}`
        : totalUnreadMessages;

    return (
      <div className='col-xs-2 no-padding'>
        <div className={styles.badge}>
          <b>{badgeText}</b>
        </div>
      </div>
    );
  };

  renderConversation = () => {
    const { currentUser, chat } = this.props;
    const brandId = get(chat, 'brand.id', this.props.brandId);
    const baseProps = {
      brandId,
      chatId: chat.id
    };

    if (currentUser.role === 'advertiser') {
      return <ChatWithInfluencer {...baseProps} />;
    }

    return <ChatWithBrand {...baseProps} showWrapper={false} />;
  };

  render() {
    const {
      chat,
      brandId,
      brand,
      currentUser,
      isCollapsed,
      allowToSwitchBrand
    } = this.props;

    const brandStatus = get(brand, 'status');

    if (brandId && brandStatus !== 'active') return <noscript />;

    const { showBrandSelector, isFocused } = this.state;
    const containerClassName = classNames('col start-xs', styles.container, {
      [styles.focused]: isFocused
    });
    const headerClassName = classNames(
      'flex middle-xs between-xs padding-Hx',
      styles.header
    );
    const nameClassName = classNames('width100 ellipsis', styles.name, {
      'vf-text-primary': isFocused
    });
    const closeIconClassName = classNames('vf-icon icon-close', styles.close);

    return (
      <div
        className='margin-horizontal-1x'
        style={{ minWidth: '300px', maxWidth: '300px' }}
        onFocus={() => this.setState({ isFocused: true })}
        onBlur={() => this.setState({ isFocused: false })}
      >
        <div className={containerClassName}>
          <div className={headerClassName} onClick={this.onToggle}>
            {this.renderPendingMessagesBadge()}

            <div className='col-xs-8 no-padding'>
              <div className={nameClassName}>
                {getChatName({ chat, currentUser })}
              </div>
            </div>

            <div className='col-xs-2 flex end-xs' style={{ paddingRight: 0 }}>
              <i className={closeIconClassName} onClick={this.onClose} />
            </div>
          </div>

          {!isCollapsed && (
            <div className={styles.body}>
              {!showBrandSelector && chat && (
                <div className='width100 height100 col end-xs'>
                  {allowToSwitchBrand && (
                    <BrandSwitch
                      brand={chat.brand}
                      onChange={() =>
                        this.setState({ showBrandSelector: true })
                      }
                    />
                  )}
                  {this.renderConversation()}
                </div>
              )}

              {showBrandSelector && (
                <BrandSelector onSelect={this.onSelectBrand} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function BrandSwitch({ brand, onChange }) {
  const className = classNames(
    'vf-row flex vf-bg-white-light-color no-margin padding-Hx between-xs middle-xs vf-font-small',
    styles['brand-switch']
  );

  return (
    <div className={className}>
      <div className='col-xs-6 flex start-xs no-padding vf-font-bold'>
        {i18n.get('CHAT_CONVERSATION_FROM')}
      </div>

      <div className='col-xs-6 no-padding'>
        <div className='width100 flex end-xs'>
          <div>
            <BrandAvatar
              url={brand.profileImage}
              name={brand.name}
              size='sm'
              shadow={false}
            />
          </div>
          <div
            className='col top-xs margin-left-Hx vf-font-gray'
            style={{ width: '80%' }}
          >
            <b className='start-xs ellipsis width100' title={brand.name}>
              {brand.name}
            </b>

            <Button
              color='flat'
              className='vf-text-primary'
              style={{ padding: '0.5rem 0', fontSize: '1.3rem' }}
              onClick={onChange}
            >
              {i18n.get('CHAT_SWITCH_BRAND')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

BrandSwitch.propTypes = {
  brand: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(FloatingChat);
