"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const components_1 = require("apps/shared/components");
class NumberInput extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.onChange = ev => {
            const { onChange, allowDecimals, roundTo, allowNegative } = this.props;
            const { target } = ev;
            let inputVal = target.value;
            inputVal = inputVal === '.' ? '0.' : inputVal;
            const numberVal = Number(inputVal);
            const isInteger = numberVal % 1 === 0;
            if (isNaN(numberVal)) {
                target.value = '';
                return;
            }
            if (!allowNegative && inputVal.startsWith('-')) {
                target.value = inputVal.substring(1, inputVal.length);
                return;
            }
            if (!allowDecimals && (/\.$/.test(inputVal) || !isInteger)) {
                target.value = inputVal.substring(0, inputVal.length - 1);
                return;
            }
            if (!isInteger) {
                const roundTens = Math.pow(10, roundTo);
                const rounded = Math.round(numberVal * roundTens) / roundTens;
                if (onChange)
                    return onChange(`${rounded}`, ev);
                target.value = `${rounded}`;
                return;
            }
            return onChange ? onChange(`${inputVal}`, ev) : null;
        };
    }
    render() {
        const _a = this.props, { onChange, allowDecimals, roundTo, allowNegative } = _a, other = __rest(_a, ["onChange", "allowDecimals", "roundTo", "allowNegative"]);
        return React.createElement(components_1.FormInput, Object.assign({ onChange: this.onChange }, other));
    }
}
NumberInput.defaultProps = {
    onChange: null,
    allowDecimals: true,
    allowNegative: false,
    roundTo: 2
};
exports.default = NumberInput;
