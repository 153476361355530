"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const ui_library_1 = require("ui-library");
const SearchFilter = props => {
    const [active, setActive] = React.useState(false);
    const [container, setContainer] = React.useState(null);
    const { filters = {}, onChange = () => { }, className, text = i18n.get('SEARCH_FILTER_DEFAULT_TEXT') } = props;
    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (ev) => {
        if (!!container && !container.contains(ev.target)) {
            setActive(false);
        }
    };
    const toggle = () => {
        setActive(!active);
    };
    const _onChange = (filterName, item, key) => {
        let currentFilter = Object.assign({}, filters);
        currentFilter[filterName].items[key].checked = !item.checked;
        const activeFilters = getActiveFilters();
        if (onChange)
            onChange(currentFilter, activeFilters);
    };
    const getActiveFilters = () => {
        const { filters } = props;
        let activeFilters = filters.reduce((result, options, filter) => {
            result[filter] = options.items.reduce((actives, item) => {
                if (item.checked)
                    actives.push(item.value);
                return actives;
            }, []);
            return result;
        }, {});
        return activeFilters;
    };
    let dropDownFiltes;
    let classes = 'vf-search-filter';
    if (className)
        classes += ' ' + className;
    if (active)
        classes += ' active';
    dropDownFiltes = filters.map((options, filter) => {
        let optionItems = [];
        if (options.items.length) {
            optionItems = options.items.map((item, key) => {
                return (React.createElement("li", { key: key },
                    React.createElement(ui_library_1.Checkbox, { key: key, onChange: _onChange.bind(null, filter, item, key), checked: item.checked }, item.label)));
            });
        }
        return (React.createElement("div", { key: filter },
            React.createElement("div", { className: 'vf-search-filter-category' },
                React.createElement("span", null, options.title)),
            React.createElement("ul", { className: 'vf-clear-ul' }, optionItems)));
    });
    return (React.createElement("div", { ref: r => setContainer(r), className: classes },
        React.createElement("div", { className: 'vf-search-filter-select vf-clickable', onClick: toggle },
            text,
            React.createElement("i", { className: active
                    ? 'vf-icon icon-caret-up-two'
                    : 'vf-icon icon-caret-down-two' })),
        React.createElement("div", { className: 'vf-search-filter-dropdown padding-1x' }, dropDownFiltes)));
};
exports.default = SearchFilter;
