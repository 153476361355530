"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const app_1 = require("config/app");
const InfPinkDisclaimerDialog = props => (React.createElement(components_1.PinkDisclaimerDialog, Object.assign({}, props, { style: { maxWidth: '49rem' } }),
    React.createElement("li", null, i18n.getHTML('INF_PINK_ITEM_1')),
    React.createElement("li", null, i18n.getHTML('INF_PINK_ITEM_2')),
    React.createElement("li", null, i18n.getHTML('INF_PINK_ITEM_3', { companyName: app_1.getDefaultTitle() })),
    React.createElement("li", null, i18n.getHTML('INF_PINK_ITEM_4'))));
exports.default = InfPinkDisclaimerDialog;
