"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stepsAdv = exports.stepsInf = void 0;
const images_1 = require("assets/images");
exports.stepsInf = [
    {
        id: 1,
        icon: images_1.iconHandHeart,
        text: 'REGISTER_STEP_INF_1'
    },
    {
        id: 2,
        icon: images_1.iconGift,
        text: 'REGISTER_STEP_INF_2'
    },
    {
        id: 3,
        icon: images_1.iconExchangeDollar,
        text: 'REGISTER_STEP_INF_3'
    }
];
exports.stepsAdv = [
    {
        id: 1,
        icon: images_1.iconMentalHealth,
        text: 'REGISTER_STEP_ADV_1'
    },
    {
        id: 2,
        icon: images_1.iconGift,
        text: 'REGISTER_STEP_ADV_2'
    },
    {
        id: 3,
        icon: images_1.iconScreenshot,
        text: 'REGISTER_STEP_ADV_3'
    }
];
