"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const placeActions = require("redux/modules/places");
const LocationSelectUI_1 = require("./LocationSelectUI/LocationSelectUI");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const redux_1 = require("redux");
const react_router_dom_1 = require("react-router-dom");
class LocationSelect extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            searchValue: '',
            isLoading: false
        };
        this.fetchData = (props = this.props) => {
            const { searchValue } = this.state;
            const { fetchPlaces, selectedLocations } = props;
            const params = {
                ids: [...selectedLocations.city, ...selectedLocations.country, ...selectedLocations.region],
                replace: true
            };
            fetchPlaces(params)
                .then(() => fetchPlaces({ q: searchValue }))
                .finally(() => this.setState({ isLoading: false }));
        };
        this.handleSearchChange = searchValue => {
            this.setState({
                searchValue,
                isLoading: true
            }, this.fetchData);
        };
        this.handleLocationClick = (type, id) => {
            const { selectedLocations, places, onChange } = this.props;
            const newArray = [...selectedLocations[type], id];
            const allIds = [...selectedLocations.country, ...selectedLocations.region, ...selectedLocations.city];
            const isParent = allIds.some(sId => id === lodash_1.get(places[sId], 'country.id', '') ||
                id === lodash_1.get(places[sId], 'admin1.id', ''));
            const removeId = allIds.find(sId => sId === lodash_1.get(places[id], 'country.id', '') ||
                sId === lodash_1.get(places[id], 'admin1.id', ''));
            if (isParent)
                return this.setState({ searchValue: '' });
            const newLoactions = {
                country: lodash_1.without(selectedLocations.country, removeId),
                region: lodash_1.without(selectedLocations.region, removeId),
                city: lodash_1.without(selectedLocations.city, removeId),
                [type]: newArray
            };
            this.setState({ searchValue: '' }, () => onChange(newLoactions));
        };
        this.handleRemove = (rootId, id) => {
            if (id)
                return this.removeOne(rootId, id);
            return this.removeAllChildren(rootId);
        };
        this.removeOne = (rootId, id) => {
            const { selectedLocations, places, onChange } = this.props;
            const tree = this.getSelectedTree();
            const place = places[id];
            const type = place.type === 'admin1' ? 'region' : place.type;
            const newArray = lodash_1.without(selectedLocations[type], id);
            const isLastChild = tree[rootId].children.length === 1;
            const newLoactions = {
                country: [...selectedLocations.country],
                region: [...selectedLocations.region],
                city: [...selectedLocations.city],
                [type]: newArray
            };
            if (isLastChild)
                newLoactions.country.push(rootId);
            onChange(newLoactions);
        };
        this.removeAllChildren = rootId => {
            const { selectedLocations, onChange } = this.props;
            const tree = this.getSelectedTree();
            const childrenIds = tree[rootId].children.map(place => place.id);
            const newLoactions = {
                country: lodash_1.without(selectedLocations.country, ...childrenIds, rootId),
                region: lodash_1.without(selectedLocations.region, ...childrenIds),
                city: lodash_1.without(selectedLocations.city, ...childrenIds)
            };
            onChange(newLoactions);
        };
        this.filteredPlaces = () => {
            const { places, selectedLocations } = this.props;
            return lodash_1.chain(places)
                .omit([...selectedLocations.city, ...selectedLocations.country, ...selectedLocations.region])
                .orderBy('score', 'desc')
                .value();
        };
        this.getSelectedTree = () => {
            const { places, selectedLocations } = this.props;
            const allIds = [...selectedLocations.city, ...selectedLocations.country, ...selectedLocations.region];
            return allIds.reduce((tree, id) => {
                const place = places[id];
                if (!place)
                    return tree;
                if (!place.country)
                    return Object.assign(Object.assign({}, tree), { [id]: Object.assign(Object.assign({}, place), { children: [] }) });
                const { country } = place;
                const root = tree[country.id] || Object.assign(Object.assign({}, country), { children: [] });
                root.children.push(place);
                return Object.assign(Object.assign({}, tree), { [country.id]: root });
            }, {});
        };
    }
    componentDidMount() {
        this.fetchData();
    }
    componentDidUpdate(prevProps) {
        const { city = [], country = [], region = [] } = this.props.selectedLocations;
        const { city: ocity = [], country: ocoun = [], region: oreg = [] } = prevProps.selectedLocations;
        const hasRemovedSelectedLocation = city.length < ocity.length ||
            country.length < ocoun.length ||
            region.length < oreg.length;
        if (hasRemovedSelectedLocation) {
            this.fetchData();
        }
    }
    render() {
        const { isLoading, searchValue } = this.state;
        return (React.createElement(LocationSelectUI_1.default, { places: this.filteredPlaces(), selectedTree: this.getSelectedTree(), isLoading: isLoading, searchValue: searchValue, onRemove: this.handleRemove, onSearchChange: this.handleSearchChange, onLocationClick: this.handleLocationClick }));
    }
}
const mapStateToProps = state => ({
    places: lodash_1.get(state, 'entities.Place', {})
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    fetchPlaces: placeActions.fetchPlaces
}, dispatch);
exports.default = react_router_dom_1.withRouter(react_redux_1.connect(mapStateToProps, mapDispatchToProps)(LocationSelect));
