"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const get = require("lodash/get");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const styles = require("./TagInputUI.less");
const ENTER_KEY = 'Enter';
class TagInputUI extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.input = React.createRef();
        this.onAdd = () => {
            const { onAdd, canAdd, isValid } = this.props;
            const value = get(this.input, 'current.value');
            if (!value || !canAdd || !isValid)
                return;
            onAdd(value);
            this.input.current.value = '';
            this.input.current.focus();
            this.onValidate();
        };
        this.onRemove = ev => {
            const index = Number(ev.currentTarget.getAttribute('data-index'));
            this.props.onRemove(index);
        };
        this.onKeyPress = ev => {
            if (ev.key === ENTER_KEY) {
                this.onAdd();
            }
        };
        this.onValidate = () => {
            this.props.onValidate(get(this.input, 'current.value'));
        };
    }
    render() {
        const { canAdd, className, hideInput, isValid, placeholder, style, tags } = this.props;
        return (React.createElement("div", { className: className, style: style },
            !hideInput && (React.createElement("div", { className: 'margin-bottom-1x' },
                React.createElement(components_1.FormInput, { ref: this.input, className: styles.input, placeholder: placeholder, disabled: !canAdd, onKeyPress: this.onKeyPress, onChange: this.onValidate }),
                React.createElement(ui_library_1.Button, { className: styles.button, disabled: !canAdd || !isValid, onClick: this.onAdd }, i18n.get('TAG_ADD_BUTTON')))),
            React.createElement("div", { className: styles['tags-container'] }, tags.map((t, idx) => (React.createElement(Tag, { key: `${t}_${idx}`, text: t, onRemove: this.onRemove, "data-index": idx }))))));
    }
}
const Tag = (_a) => {
    var { text, onRemove } = _a, other = __rest(_a, ["text", "onRemove"]);
    return (React.createElement("div", Object.assign({ className: styles.tag, onClick: onRemove }, other),
        text,
        React.createElement("i", { className: 'vf-icon icon-close icon-fix margin-left-Hx' })));
};
exports.default = TagInputUI;
