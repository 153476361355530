"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_media_1 = require("react-media");
const lodash_1 = require("lodash");
const qs = require("query-string");
const social_network_connections_1 = require("modules/legacy/js/lib/social-network-connections");
const i18n = require("i18n");
const app_1 = require("../actions/app");
const components_1 = require("apps/shared/components");
const app_2 = require("config/app");
const connectionDialog_1 = require("apps/shared/components/TikTok/connectionDialog");
const SocialNetworksForm_1 = require("apps/shared/components/SocialNetworksForm/SocialNetworksForm");
const LimitationsList_1 = require("apps/influencers/shared/components/LimitationList/LimitationsList");
const FacebookAccountTypeSelector_1 = require("modules/socialAccounts/influencer/screens/FacebookAccountTypeSelector");
const InstagramAccountTypeSelector_1 = require("modules/socialAccounts/influencer/screens/InstagramAccountTypeSelector");
const TYPESOCIALNETWORK = 'TypeSocialNetwork';
const customSocialNetworkConnectors = {
    facebook: FacebookAccountTypeSelector_1.default,
    instagram: InstagramAccountTypeSelector_1.default
};
const SettingsSocialConnectAccount = ({ openModal, responsive, redirectTo, onRedirect, wrapperStyle, campaignLite, onCloseHeader, validCategory, socialNetworks, hangleAccountForm, showSocialFormModal, activeSocialNetworks, onSocialNetworkFormClose }) => {
    const [customConnector, setCustomConnector] = React.useState('');
    const [displaySocialNetworks, setDisplaySocialNetworks] = React.useState(true);
    const getCustomSocialNetworkConnector = socialNetwork => {
        const updateState = (connector, display) => {
            setCustomConnector(connector);
            setDisplaySocialNetworks(display);
        };
        const socialNetworkConnector = {
            instagram: () => updateState('instagram', false),
            facebook: () => updateState('facebook', false),
            tiktok: () => updateState('tiktok', true)
        };
        return socialNetworkConnector[socialNetwork];
    };
    const handleAccountForm = nameWork => {
        window.localStorage.setItem(TYPESOCIALNETWORK, nameWork);
        openModal();
    };
    const onCancel = () => {
        window.localStorage.removeItem(TYPESOCIALNETWORK);
        if (onCloseHeader) {
            onCloseHeader();
        }
        openModal();
    };
    const getDefaultActiveSocialNetworks = () => {
        const { socialNetworks } = app_2.getStatics();
        return lodash_1.filter(socialNetworks, (e, key) => {
            e.key = key;
            return e.active;
        }).map(sn => ({ key: sn.key, name: sn.name }));
    };
    const connectToSocialNetwork = (socialNetwork, connections) => {
        const customConnection = getCustomSocialNetworkConnector(socialNetwork);
        if (customConnection)
            return customConnection();
        const connect = connections[socialNetwork];
        const params = qs.stringify({ redirectTo });
        connect({ onRedirect, redirectTo })
            .then(() => app_1.gotoPage(`/social-accounts/add/facebook/pages?${params}`))
            .catch(error => {
            console.error('Error connecting to social network:', error);
        });
    };
    const formSocialNetwork = (socialNetwork, onAccountForm) => {
        if (responsive)
            onCloseHeader();
        const networkForm = onAccountForm(socialNetwork);
        if (networkForm)
            return networkForm();
    };
    const renderSocialNetworkButtons = () => {
        const activeSN = activeSocialNetworks || getDefaultActiveSocialNetworks();
        const connections = {
            instagram: social_network_connections_1.connectInstagram,
            facebook: social_network_connections_1.connectFacebook,
            youtube: social_network_connections_1.connectYoutube,
            tiktok: social_network_connections_1.connectInstagram
        };
        const buttons = activeSN.map(socialNetwork => (React.createElement("div", { id: socialNetwork.key, key: socialNetwork.key, className: 'margin-bottom-1x col-xs-12 col-sm-6' },
            React.createElement(SocialNetworkButton, { type: socialNetwork.key, name: socialNetwork.name, onClick: () => connectToSocialNetwork(socialNetwork.key, connections) }))));
        return React.createElement("div", { className: 'flex flex-wrap vf-row' }, buttons);
    };
    const renderSocialNetworkLite = () => {
        let activeSN = activeSocialNetworks || getDefaultActiveSocialNetworks();
        if ((socialNetworks === null || socialNetworks === void 0 ? void 0 : socialNetworks.length) > 0) {
            activeSN = activeSN.filter(item => socialNetworks.includes(item.key));
        }
        const buttons = activeSN.map(socialNetwork => (React.createElement("div", { id: socialNetwork.key, key: socialNetwork.key, className: 'margin-bottom-1x col-xs-12 col-sm-6' },
            React.createElement(SocialNetworkButton, { type: socialNetwork.key, name: socialNetwork.name, onClick: () => formSocialNetwork(socialNetwork.key, validCategory ? handleAccountForm : hangleAccountForm) }))));
        return React.createElement("div", { className: 'flex flex-wrap vf-row' }, buttons);
    };
    const onBackCustomConnectorComponent = () => {
        setCustomConnector(null);
        setDisplaySocialNetworks(true);
    };
    const CustomConnectorComponent = customSocialNetworkConnectors[customConnector];
    const selectLite = campaignLite || validCategory;
    let title = i18n.get('INF_LITE_SOCIAL_ACCOUNTS_MODAL_LINK_SOCIAL_NETWORK');
    if (validCategory) {
        title = i18n.get('INF_LITE_SOCIAL_ACCOUNTS_LINK_SOCIAL_NETWORK');
    }
    return (React.createElement(React.Fragment, null, showSocialFormModal ? (React.createElement(SocialNetworksForm_1.default, { onCancel: onCancel, showSocialFormAccount: showSocialFormModal, onSocialNetworkFormClose: onSocialNetworkFormClose })) : (React.createElement("div", { style: wrapperStyle, id: 'link-social-account-panel', className: 'vf-wrapper vf-connect-account' },
        React.createElement("div", { className: 'vf-row ' },
            React.createElement("div", { className: 'col-xs-12' },
                React.createElement(react_media_1.default, { query: '(min-width: 1025px)' },
                    React.createElement("h3", null, !selectLite
                        ? i18n.get('INF_SOCIAL_ACCOUNTS_LINK_SOCIAL_NETWORK')
                        : title)),
                !selectLite && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: 'vf-text-gray margin-vertical-1x' }, i18n.get('INF_SOCIAL_ACCOUNTS_LINK_NEW_REDIRECT_WARNING')),
                    React.createElement("div", { style: { fontSize: '1.5rem' }, className: 'flex middle-xs vf-border-warning-color vf-text-warning margin-vertical-1x padding-vertical-Hx padding-horizontal-1x' },
                        React.createElement("i", { style: { fontSize: '1.5rem', lineHeight: '1.5rem' }, className: 'vf-icon icon-exclamation-triangle margin-right-Hx' }),
                        i18n.getHTML('INF_SOCIAL_ACCOUNTS_LINK_NEW_PRIVACY_DISCLAIMER', {
                            companyName: app_2.getDefaultTitle()
                        })))))),
        React.createElement("div", { className: 'vf-row' },
            React.createElement("div", { className: 'col-xs-12 col-sm-12 col-lg-12' },
                CustomConnectorComponent && !selectLite && (React.createElement(CustomConnectorComponent, { onRedirect: onRedirect, redirectTo: redirectTo, onBack: onBackCustomConnectorComponent })),
                displaySocialNetworks &&
                    !selectLite &&
                    renderSocialNetworkButtons(),
                selectLite && renderSocialNetworkLite())),
        displaySocialNetworks && !selectLite && React.createElement(LimitationsList_1.default, null),
        !selectLite && (React.createElement(connectionDialog_1.default, { isOpen: customConnector === 'tiktok', onClose: onBackCustomConnectorComponent }))))));
};
const SocialNetworkButton = ({ type, name, onClick }) => (React.createElement("div", { onClick: onClick, style: { borderRadius: '1px' }, className: 'vf-btn flex flex-start', "data-uitest": `social-network-button-${type}` },
    React.createElement("div", { className: ' flex' },
        React.createElement(components_1.SocialBadgeXL, { socialNetwork: type, className: 'margin-right-1x', style: {
                width: '5rem',
                height: '5rem',
                lineHeight: '5rem',
                borderRadius: '5rem'
            } }),
        React.createElement("div", { style: { fontSize: '1.3em' }, className: 'vf-capitalize' }, name))));
exports.default = SettingsSocialConnectAccount;
