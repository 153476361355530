"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./MusicalGenres.less");
const MusicalGenres = (_a) => {
    var { text, icon, onToggle, isSelected } = _a, other = __rest(_a, ["text", "icon", "onToggle", "isSelected"]);
    return (React.createElement("div", Object.assign({ onClick: onToggle, className: classnames_1.default(styles.tag, 'padding-vertical-Hx padding-horizontal-1x margin-right-Hx margin-bottom-Hx', {
            'vf-font-bold vf-text-primary': isSelected,
            [styles.selected]: isSelected
        }) }, other),
        icon && React.createElement("i", { className: `vf-icon icon-${icon} margin-right-Hx` }),
        text));
};
exports.default = MusicalGenres;
