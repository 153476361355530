import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import i18n from 'i18n';

import Popover from 'modules/legacy/js/components/popover';

import {
  fbAngryImg,
  fbHahaImg,
  fbLikeImg,
  fbLoveImg,
  fbSadImg,
  fbWowImg
} from 'assets/images';

const REACTIONS_IMG = {
  likes: fbLikeImg,
  reactionsLove: fbLoveImg,
  love: fbLoveImg,
  reactionsHaha: fbHahaImg,
  haha: fbHahaImg,
  reactionsWow: fbWowImg,
  wow: fbWowImg,
  reactionsSad: fbSadImg,
  sad: fbSadImg,
  reactionsAngry: fbAngryImg,
  angry: fbAngryImg
};

const FacebookEngagements = ({ stats }) => {
  const totalReactions = get(stats, 'reactions.total', stats.reactionsTotal);

  return (
    <div className='flex'>
      {!!totalReactions && <Reactions stats={stats} />}

      {!!stats.likes && (
        <div className='margin-right-2x'>
          <i className='vf-icon icon-heart' />
          <span className='margin-left-Hx'>{i18n.getNumber(stats.likes)}</span>
        </div>
      )}

      {!!stats.comments && (
        <div className='margin-right-2x'>
          <i className='vf-icon icon-comment' />
          <span className='margin-left-Hx'>
            {i18n.getNumber(stats.comments)}
          </span>
        </div>
      )}

      {!!stats.shares && (
        <div className='margin-right-2x'>
          <i className='vf-icon icon-share' />
          <span className='margin-left-Hx'>{i18n.getNumber(stats.shares)}</span>
        </div>
      )}
    </div>
  );
};

FacebookEngagements.propTypes = {
  stats: PropTypes.object.isRequired
};

FacebookEngagements.defaultProps = {
  stats: {}
};

const Reactions = ({ stats }) => {
  const totalReactions = get(stats, 'reactions.total', stats.reactionsTotal);
  const icons = Object.keys(REACTIONS_IMG).reduce((memo, key) => {
    const stat = get(stats, `reactions.${key}`, stats[key]);

    if (!stat) return memo;

    memo.push(
      <img
        key={key}
        src={REACTIONS_IMG[key]}
        style={{ width: '1.5rem', marginRight: '2px' }}
      />
    );

    return memo;
  }, []);

  return (
    <Popover
      body={<ReactionsPopover stats={stats} />}
      preferPlace='above'
      style='dark'
    >
      <div className='flex margin-right-2x vf-clickable'>
        {icons}
        <span className='margin-left-Hx'>{i18n.getNumber(totalReactions)}</span>
      </div>
    </Popover>
  );
};

Reactions.propTypes = {
  stats: PropTypes.object.isRequired
};

Reactions.defaultProps = {
  stats: {}
};

const ReactionsPopover = ({ stats }) => {
  const icons = Object.keys(REACTIONS_IMG).reduce((memo, key) => {
    const stat = get(stats, `reactions.${key}`, stats[key]);

    if (!stat) return memo;

    memo.push(
      <div className='flex'>
        <img
          src={REACTIONS_IMG[key]}
          style={{ width: '2rem', marginRight: '0.5rem', marginTop: '0.2rem' }}
        />
        <span>{i18n.getNumber(stat)}</span>
      </div>
    );

    return memo;
  }, []);

  return <div className='col padding-1x'>{icons.map(i => i)}</div>;
};

ReactionsPopover.propTypes = {
  stats: PropTypes.object.isRequired
};

ReactionsPopover.defaultProps = {
  stats: {}
};

export default FacebookEngagements;
