"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const SharedActions = require("redux/modules/shared");
const selectors_1 = require("modules/shared/selectors");
const ProductPopoverUI_1 = require("./ProductPopoverUI/ProductPopoverUI");
const withLoading_1 = require("apps/shared/hocs/withLoading");
class ProductPopover extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            isOpen: false
        };
        this.getProducts = () => {
            const { productsPayment, products } = this.props;
            if (lodash_1.isEmpty(products))
                return [];
            return productsPayment.map(pp => (Object.assign({ quantity: pp.quantity, deliveryInfo: pp.deliveryInfo }, products[pp.productId])));
        };
        this.ensureProductDetails = (props = this.props) => {
            const { products, productsPayment } = props;
            const missingProductsIds = productsPayment.reduce((result, p) => {
                if (!products[p.productId])
                    result.push(p.productId);
                return result;
            }, []);
            if (lodash_1.isEmpty(missingProductsIds))
                return Promise.resolve();
            return this.fetchProducts(missingProductsIds);
        };
        this.fetchProducts = (ids) => {
            const { fetchProducts, setLoading } = this.props;
            setLoading(true);
            return fetchProducts(ids)
                .finally(() => setLoading(false));
        };
        this.handleMouseEnter = () => {
            this.ensureProductDetails();
            this.setState({ isOpen: true });
        };
        this.handleBlur = () => {
            this.setState({ isOpen: false });
        };
    }
    render() {
        const _a = this.props, { children, products } = _a, uiprops = __rest(_a, ["children", "products"]);
        const { isOpen } = this.state;
        return (React.createElement(ProductPopoverUI_1.default, Object.assign({ isOpen: isOpen, onBlur: this.handleBlur, onMouseEnter: this.handleMouseEnter, products: this.getProducts() }, uiprops), children));
    }
}
const mapStateToProps = (state, props) => {
    const productsPayment = lodash_1.get(props, 'productsPayment', []);
    return {
        products: selectors_1.getProductsByIds(state, productsPayment.map(p => p.productId)) || {}
    };
};
const mapDispatchToProps = (dispatch) => redux_1.bindActionCreators({
    fetchProducts: SharedActions.fetchProductsByIds
}, dispatch);
const Enhanced = withLoading_1.default(ProductPopover);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Enhanced);
