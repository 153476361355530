"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const userActions = require("redux/modules/users");
const appActions = require("redux/modules/app");
const sharedActions = require("redux/modules/shared");
const PartnerVerifyEmailUI_1 = require("./PartnerVerifyEmailUI/PartnerVerifyEmailUI");
const emailValidator_1 = require("apps/shared/hocs/emailValidator");
const errorStateHandler_1 = require("apps/shared/hocs/errorStateHandler");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const lodash_1 = require("lodash");
const selectors_1 = require("modules/shared/selectors");
class PartnerVerifyEmail extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            loading: false
        };
        this.validateForm = form => {
            const { validateEmail } = this.props;
            return {
                email: validateEmail(form.email)
                    ? null
                    : i18n.get('LOGIN_LOGIN_ERROR_INVALID_EMAIL')
            };
        };
        this.handleSubmit = (form) => {
            const { onSetErrors, currentUser } = this.props;
            const errors = this.validateForm(form);
            const isValid = lodash_1.every(errors, val => val === null);
            if (!isValid)
                return onSetErrors(errors);
            const { email } = currentUser;
            const action = email === form.email ? this.handleResend : this.handleUpdate;
            action(form);
        };
        this.handleInputBlur = () => {
            const { onSetErrors } = this.props;
            onSetErrors({ email: null });
        };
        this.handleLogout = () => {
            const { logout } = this.props;
            logout();
        };
        this.handleResend = form => {
            const { sendVerificationEmail } = this.props;
            this.setState({ loading: true });
            sendVerificationEmail(form)
                .then(this.onResendSuccess)
                .catch(this.onResendFailed)
                .finally(() => this.setState({ loading: false }));
        };
        this.handleUpdate = form => {
            const { updateUser, currentUser } = this.props;
            this.setState({ loading: true });
            updateUser(Object.assign({ id: currentUser.id }, form))
                .then(() => this.onUpdateSuccess(form))
                .catch(this.onUpdateFailed)
                .finally(() => this.setState({ loading: false }));
        };
        this.onUpdateSuccess = form => {
            const { showAlert } = this.props;
            showAlert({
                title: i18n.get('DEFAULT_SUCCESS_TITLE'),
                type: 'success',
                message: i18n.get('VERIFY_EMAIL_SAVE_REMINDER_SUCCESS_ALERT_DESCRIPTION')
            });
            return this.handleResend(form);
        };
        this.onUpdateFailed = () => {
            const { showAlert, updateRequest } = this.props;
            const { message } = updateRequest.response;
            showAlert({
                type: 'error',
                message
            });
        };
        this.onResendSuccess = () => {
            const { showAlert } = this.props;
            showAlert({
                title: i18n.get('VERIFY_EMAIL_REMAINDER_SUCCESS_ALERT_TITLE'),
                type: 'success',
                message: i18n.get('VERIFY_EMAIL_REMAINDER_SUCCESS_ALERT_DESCRIPTION')
            });
        };
        this.onResendFailed = () => {
            const { showAlert, sendRequest } = this.props;
            const { message } = sendRequest.response;
            showAlert({
                type: 'error',
                message
            });
        };
    }
    render() {
        const { loading } = this.state;
        const { errors, currentUser } = this.props;
        const { email } = currentUser;
        return (React.createElement(PartnerVerifyEmailUI_1.default, { errors: errors, loading: loading, defaultEmail: email, onSubmit: this.handleSubmit, onInputBlur: this.handleInputBlur, onLogout: this.handleLogout }));
    }
}
const WithErrors = errorStateHandler_1.default(PartnerVerifyEmail);
const mapStateToProps = state => ({
    currentUser: selectors_1.getCurrentUser(state),
    sendRequest: selectors_1.getRequest(state, 'sendVerificationEmail'),
    updateRequest: selectors_1.getRequest(state, 'updateUser')
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    sendVerificationEmail: userActions.sendVerificationEmail,
    updateUser: userActions.updateUser,
    showAlert: appActions.showAlert,
    logout: sharedActions.logout
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(emailValidator_1.default(WithErrors));
