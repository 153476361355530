"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const react_redux_1 = require("react-redux");
const Dotdotdot = require("react-dotdotdot");
const i18n = require("i18n");
const styles = require("./ProductsBriefLite.less");
const components_1 = require("modules/shared/components");
const selectors_1 = require("modules/shared/selectors");
const ProductsBriefLite = ({ details, currency, campaign, isParticipation }) => {
    return (React.createElement(React.Fragment, null, campaign &&
        campaign.map(product => {
            var _a, _b, _c, _d, _e;
            const productDetails = isParticipation ? details[product === null || product === void 0 ? void 0 : product.productId] : product;
            return (React.createElement("div", { key: (product === null || product === void 0 ? void 0 : product.productId) || (productDetails === null || productDetails === void 0 ? void 0 : productDetails.id), className: styles.product },
                React.createElement("div", { className: styles.image },
                    React.createElement("div", { className: `vf-gradient ${styles.radius}` }),
                    React.createElement(components_1.MediaCarousel, { width: 250, height: 250, style: { height: '21.75rem', borderRadius: '8px' }, images: ((_a = product === null || product === void 0 ? void 0 : product.detail) === null || _a === void 0 ? void 0 : _a.images) || (productDetails === null || productDetails === void 0 ? void 0 : productDetails.images) })),
                React.createElement("div", { className: classnames_1.default('col between-xs padding-1x', styles.details) },
                    React.createElement("div", null,
                        React.createElement("div", { className: 'flex middle-xs between-xs vf-clickable' },
                            React.createElement("h3", { className: classnames_1.default('ellipsis', styles.title) }, ((_b = product === null || product === void 0 ? void 0 : product.detail) === null || _b === void 0 ? void 0 : _b.name) || (productDetails === null || productDetails === void 0 ? void 0 : productDetails.name))),
                        React.createElement("div", { className: styles.text },
                            React.createElement(Dotdotdot, { clamp: 2 }, ((_c = product === null || product === void 0 ? void 0 : product.detail) === null || _c === void 0 ? void 0 : _c.description) || (productDetails === null || productDetails === void 0 ? void 0 : productDetails.description)))),
                    React.createElement("div", { className: 'col bottom-xs width100 margin-top-1x' },
                        React.createElement("div", { className: styles.price },
                            (((_d = product === null || product === void 0 ? void 0 : product.detail) === null || _d === void 0 ? void 0 : _d.price) > 0 ||
                                (productDetails === null || productDetails === void 0 ? void 0 : productDetails.price) > 0) && (React.createElement("b", { "data-uitest": 'product-price' },
                                i18n.get('INF_VOXEAR_PAYMENT_IN_KIND_VALUE'),
                                ":\u00A0",
                                i18n.getMoney({
                                    amount: ((_e = product === null || product === void 0 ? void 0 : product.detail) === null || _e === void 0 ? void 0 : _e.price) || (productDetails === null || productDetails === void 0 ? void 0 : productDetails.price),
                                    currency
                                }))),
                            React.createElement("span", null,
                                React.createElement("b", { className: 'margin-left-1x', "data-uitest": 'product-quantity' },
                                    i18n.get('INF_VOXEAR_LITE_PAYMENT_IN_KIND_QUANTITY'),
                                    ":\u00A0",
                                    i18n.getNumber(product === null || product === void 0 ? void 0 : product.quantity))))))));
        })));
};
function mapStateToProps(state, { campaign = [] }) {
    const productIds = campaign.map(p => p.productId);
    return {
        details: selectors_1.getProductsByIds(state, productIds)
    };
}
exports.default = react_redux_1.connect(mapStateToProps)(ProductsBriefLite);
