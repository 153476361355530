"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const ui_library_1 = require("ui-library");
class SettingsDropdown extends React.Component {
    constructor() {
        super(...arguments);
        this.handleClick = (callback, evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            callback();
        };
        this.renderOptions = () => {
            const { options } = this.props;
            return _.chain(options)
                .compact()
                .map(el => (React.createElement("div", { className: 'vf-settings-dropdown-element vf-clickable', key: el.value, onClick: this.handleClick.bind(null, el.callback) }, el.value)))
                .value();
        };
    }
    render() {
        const { className, size, icon, position, iconClassName = '' } = this.props;
        let classes = 'vf-settings-dropdown no-hover';
        if (className)
            classes += ` ${className}`;
        return (React.createElement(ui_library_1.Dropdown, { ref: 'dropdown', className: classes, menuClassName: 'vf-overflow-hidden vf-settings-dropdown-menu', size: size, title: React.createElement("div", { className: `vf-icon icon-${icon} ${iconClassName}` }), position: position, showDropdownExpandedIndicator: false },
            React.createElement("span", { "data-uitest": 'item-option' }, this.renderOptions())));
    }
}
SettingsDropdown.defaultProps = {
    icon: 'more-vertical',
    size: 'xsm',
    position: 'bottom-left'
};
;
exports.default = SettingsDropdown;
