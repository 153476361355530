"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const styles = require("./AudienceKeywordsUI.less");
class AudienceKeywordsUI extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            isOpen: false
        };
        this.handleChange = e => this.props.onChangeSearch(e.target.value);
        this.handleToggle = (isOpen) => {
            this.setState({ isOpen });
        };
        this.handleInputClick = (event) => {
            event.stopPropagation();
            this.setState({ isOpen: true });
        };
        this.handleClickSearchOption = (ev) => {
            const { onSelect } = this.props;
            this.setState({ isOpen: false });
            onSelect(lodash_1.get(ev, 'currentTarget.dataset.keyword'));
        };
        this.handleChangePercentage = (percentage) => {
            this.props.onChangePercentage(Number(percentage));
        };
    }
    render() {
        const { foundKeywords, isLoading, onRemoveKeyword, percentages, searchQuery, selectedKeyword, selectedPercentage } = this.props;
        const { isOpen } = this.state;
        return (React.createElement(React.Fragment, null,
            !!selectedKeyword && (React.createElement("div", { className: classnames_1.default(styles['selected-item'], 'vf-border-gray-color padding-horizontal-Hx padding-vertical-1x flex middle-xs') },
                selectedKeyword,
                React.createElement("i", { className: 'vf-icon icon-close icon-fix vf-clickable margin-left-1x', onClick: onRemoveKeyword }))),
            React.createElement(components_1.SelectWrapper, { isOpen: isOpen, onToggle: this.handleToggle, selectedValue: React.createElement(components_1.DebounceInput, { className: styles.input, debounceTime: 550, placeholder: i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_AUDIENCE_KEYWORDS_PLACEHOLDER'), onChange: this.handleChange, onClick: this.handleInputClick, value: searchQuery }) }, isLoading ? (React.createElement("div", { className: 'padding-Hx' }, i18n.get('SEARCHING'))) : (React.createElement(SearchResult, { keywords: foundKeywords, onClickOption: this.handleClickSearchOption, searchQuery: searchQuery }))),
            !!selectedKeyword && (React.createElement(components_1.Select, { className: 'margin-top-Hx', onChange: this.handleChangePercentage, options: percentages, selectedKey: selectedPercentage.toString() }))));
    }
}
const SearchResult = ({ keywords, onClickOption, searchQuery }) => {
    if (!!searchQuery && lodash_1.isEmpty(keywords)) {
        return React.createElement("div", { className: 'padding-Hx' }, i18n.get('NO_RESULTS'));
    }
    return (React.createElement(React.Fragment, null, keywords.map(k => (React.createElement(SearchResultItem, { "data-keyword": k.keyword, key: k.id, keyword: k, onClick: onClickOption })))));
};
const SearchResultItem = (_a) => {
    var { keyword, onClick } = _a, other = __rest(_a, ["keyword", "onClick"]);
    return (React.createElement("div", Object.assign({ className: classnames_1.default(styles['search-item'], 'flex flex-justify-space-between'), onClick: onClick }, other),
        React.createElement("span", null, keyword.keyword),
        React.createElement("span", null, i18n.getNumber(keyword.count))));
};
exports.default = AudienceKeywordsUI;
