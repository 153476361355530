"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const ui_library_1 = require("ui-library");
class EditableLabel extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            showInput: false
        };
        this.handleShowInput = event => {
            this.setState({ showInput: true });
        };
    }
    render() {
        const { showInput } = this.state;
        const _a = this.props, { defaultValue } = _a, others = __rest(_a, ["defaultValue"]);
        return (React.createElement("div", null, showInput ? (React.createElement(components_1.FormInput, Object.assign({}, others, { defaultValue: defaultValue, className: 'vf-font-bold', style: { fontSize: '2rem' } }))) : (React.createElement("div", { className: 'flex' },
            React.createElement("span", { className: 'vf-font-bold', style: { fontSize: '2rem' } }, defaultValue),
            React.createElement(ui_library_1.Button, { color: 'flat', size: 'lg', className: 'vf-text-primary', style: { marginLeft: 'auto', paddingRight: 0 }, onClick: this.handleShowInput }, i18n.get('VERIFY_EMAIL_EDIT'))))));
    }
}
exports.default = EditableLabel;
