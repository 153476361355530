import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18n';

import { getSocialNetworkAccount } from 'modules/shared/selectors';

import { SocialBadgeSM } from 'apps/shared/components';
import { CampaignCard } from 'modules/campaigns/influencer/containers';

function mapStateToProps(state, { participationRequest }) {
  return {
    socialAccount: getSocialNetworkAccount(
      state,
      participationRequest.socialNetworkAccountId
    )
  };
}

function ParticipationRequestCard(props) {
  const { participationRequest, socialAccount, ...other } = props;

  const badgeStyle = {
    position: 'absolute',
    right: '1rem',
    bottom: '1rem'
  };

  return (
    <CampaignCard
      campaignId={participationRequest.campaignId}
      labelClassName='vf-text-white vf-bg-secondary-color'
      labelText={i18n.get('INF_VOXEAR_PARTICIPATION_REQUEST_CARD_LABEL')}
      {...other}
    >
      <div
        className='flex center-xs'
        data-uitest='participation-request-card'
        style={badgeStyle}
      >
        <SocialBadgeSM socialNetwork={socialAccount.type} />

        <span className='vf-text-white margin-left-Hx'>
          {`@${socialAccount.username || ''}`}
        </span>
      </div>
    </CampaignCard>
  );
}

ParticipationRequestCard.propTypes = {
  participationRequest: PropTypes.object.isRequired,
  socialAccount: PropTypes.object
};

ParticipationRequestCard.defaultProps = {
  socialAccount: {}
};

export default connect(mapStateToProps)(ParticipationRequestCard);
