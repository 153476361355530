import axios from 'axios';
import { setAuthHeader } from '../configurator';

export function loginWithCredentials(credentials) {
  const context = buildContext();
  const { result } = context;
  const {
    sendLoginRequest,
    setTokenAsHeader,
    fetchUserInfo,
    fetchAccountInfo
  } = context.methods;

  const execute = credentials =>
    sendLoginRequest(credentials)
      .then(setTokenAsHeader)
      .then(fetchUserInfo)
      .then(fetchAccountInfo)
      .then(() => result.getData());

  return execute(credentials);
}

export function loginWithToken(token) {
  const context = buildContext();
  const { result } = context;
  const { setTokenAsHeader, fetchUserInfo, fetchAccountInfo } = context.methods;

  result.setSession({ token });
  setTokenAsHeader({ token });

  return fetchUserInfo()
    .then(fetchAccountInfo)
    .then(() => result.getData());
}

function buildContext() {
  const result = buildResult();

  const methods = {
    sendLoginRequest(credentials) {
      return axios.post('/auth/signin', credentials).then(({ data }) => {
        result.setSession(data);
        return data;
      });
    },

    setTokenAsHeader({ token }) {
      return setAuthHeader(token);
    },

    fetchUserInfo() {
      return axios.get('/users/me').then(({ data }) => {
        const { data: userData, isInvited, userCategory } = data;

        const user = { ...userData, isInvited, userCategory };
        result.setUser(user);

        return user.account;
      });
    },

    fetchAccountInfo(accountId) {
      if (!accountId) {
        result.setAccount({});
        return Promise.resolve();
      }

      return axios.get(`/accounts/${accountId}`).then(({ data }) => {
        result.setAccount(data.data);
      });
    }
  };

  return { result, methods };
}

function buildResult() {
  const data = {};

  const setSession = session => Object.assign(data, { session });
  const setUser = user => Object.assign(data, { user });
  const setAccount = account => Object.assign(data, { account });
  const getData = () => data;

  return { setSession, setUser, setAccount, getData };
}
