"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PartnerFooter_1 = require("./PartnerFooter/PartnerFooter");
Object.defineProperty(exports, "PartnerFooterController", { enumerable: true, get: function () { return PartnerFooter_1.default; } });
var PartnerLogin_1 = require("./PartnerLogin/PartnerLogin");
Object.defineProperty(exports, "PartnerLoginController", { enumerable: true, get: function () { return PartnerLogin_1.default; } });
var PartnerRegister_1 = require("./PartnerRegister/PartnerRegister");
Object.defineProperty(exports, "PartnerRegisterController", { enumerable: true, get: function () { return PartnerRegister_1.default; } });
var PartnerForgotPassword_1 = require("./PartnerForgotPassword/PartnerForgotPassword");
Object.defineProperty(exports, "PartnerForgotPasswordController", { enumerable: true, get: function () { return PartnerForgotPassword_1.default; } });
var PartnerResetPassword_1 = require("./PartnerResetPassword/PartnerResetPassword");
Object.defineProperty(exports, "PartnerResetPasswordController", { enumerable: true, get: function () { return PartnerResetPassword_1.default; } });
var PartnerForm_1 = require("./PartnerForm/PartnerForm");
Object.defineProperty(exports, "PartnerFormController", { enumerable: true, get: function () { return PartnerForm_1.default; } });
var PartnerVerifyEmail_1 = require("./PartnerVerifyEmail/PartnerVerifyEmail");
Object.defineProperty(exports, "PartnerVerifyEmailController", { enumerable: true, get: function () { return PartnerVerifyEmail_1.default; } });
