"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./SafeImage.less");
const images_1 = require("assets/images");
const Loading_1 = require("apps/shared/components/Loading/Loading");
const components_1 = require("apps/shared/components");
class SafeImage extends React.Component {
    constructor() {
        super(...arguments);
        this.timer = null;
        this.isUnMounting = false;
        this.state = {
            hasLoaded: false,
            hasError: false,
            displayLoading: false,
            source: null
        };
        this.setLoading = () => {
            if (!this.isUnMounting)
                this.setState({ displayLoading: true });
        };
        this.loadData = (props = this.props) => {
            const { loaderDelay } = this.props;
            this.setState({ hasError: false, hasLoaded: false, displayLoading: false });
            this.image = new Image();
            this.timer = setTimeout(this.setLoading, loaderDelay);
            this.image.onload = this.handleImageLoaded;
            this.image.onerror = this.handleImageError;
            this.image.src = props.url;
        };
        this.handleImageLoaded = event => {
            if (!this.isUnMounting)
                this.setState({
                    hasLoaded: true,
                    displayLoading: false,
                    source: event.target.result || event.target.src
                });
        };
        this.handleImageError = err => {
            if (!this.isUnMounting)
                this.setState({
                    hasLoaded: true,
                    hasError: true,
                    displayLoading: false
                });
        };
    }
    UNSAFE_componentWillMount() {
        this.loadData();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.url !== this.props.url)
            this.loadData(nextProps);
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
        this.isUnMounting = true;
        this.image.src = '';
    }
    render() {
        const { hasLoaded, hasError, displayLoading, source } = this.state;
        const { errorImage, className, style, cover, isVideo, children, overlay, width, height, url, showProductIcon, onClick = () => { }, onClickError = () => { } } = this.props;
        const imageUrl = hasError ? errorImage : source;
        const showImage = !isVideo && hasLoaded;
        const showVideo = isVideo && hasLoaded;
        const wrapperClass = classnames_1.default(styles.wrapper, className);
        const imageClass = classnames_1.default(styles['background-image'], { [styles['with-error']]: hasError }, { 'image-cover': cover && !hasError }, { 'image-contain': !cover });
        return (React.createElement("div", { className: wrapperClass, style: style, onClick: hasError ? onClickError : onClick },
            !hasLoaded && (React.createElement("div", { className: styles['spinner-wrapper'] },
                React.createElement(Loading_1.default, { active: displayLoading }))),
            overlay && React.createElement("div", { className: styles.overlay }),
            showProductIcon && (React.createElement("div", { className: classnames_1.default(styles['gift-icon-lite']) },
                React.createElement("img", { src: images_1.GiftIconLite }))),
            showImage &&
                imageUrl &&
                (hasError ? (React.createElement("div", { className: classnames_1.default(styles['play-icon-container']) },
                    React.createElement("i", { className: 'vf-icon icon-sna-profile icon-fix' }))) : (React.createElement(components_1.Img, { className: imageClass, src: imageUrl, width: width, height: height }))),
            showVideo && (React.createElement("video", { className: imageClass },
                React.createElement("source", { src: url, type: 'video/mp4' }))),
            children));
    }
}
SafeImage.defaultProps = {
    errorImage: images_1.noImageImg,
    loaderDelay: 150,
    isVideo: false,
    overlay: true
};
exports.default = SafeImage;
