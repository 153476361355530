"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const styles = require("./ParticipationLayout.less");
const components_1 = require("apps/shared/components");
const CampaignHeader_1 = require("../CampaignHeader/CampaignHeader");
const CampaignBriefLite_1 = require("apps/shared/components/CampaignBriefLite/CampaignBriefLite");
class ParticipationLayout extends React.Component {
    render() {
        const { right, bottom, invite, header, onBack, campaign, campaignLite, isParticipation } = this.props;
        const isCampaignSoldOut = campaign.status === 'paused';
        return (React.createElement(React.Fragment, null,
            React.createElement(CampaignHeader_1.default, { onBack: onBack, campaign: campaign, campaignLite: campaignLite }, header),
            React.createElement("div", { className: classnames_1.default(styles.grid, {
                    [styles.grid__soldout]: !isParticipation && campaignLite && isCampaignSoldOut
                }) },
                !campaignLite && (React.createElement("h1", { className: 'vf-font-normal margin-top-2x', style: { gridArea: 'btitle' } }, i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_PAY_LABEL'))),
                React.createElement("div", { className: styles.brief, style: { gridArea: 'brief' } },
                    !campaignLite ? (React.createElement(React.Fragment, null,
                        React.createElement(components_1.CampaignBrief, { isBrief: true, campaign: campaign, prepaid: (invite === null || invite === void 0 ? void 0 : invite.prepaid) || false }),
                        React.createElement("div", { style: { padding: '1rem 2rem' }, className: 'vf-card margin-top-2x vf-bold' },
                            i18n.get('INF_CAMPAIGN_SOCIAL_MEDIA_DISCLAIMER'),
                            React.createElement("a", { target: '_blank', style: { display: 'block' }, href: i18n.get('HELP_LINKS_TERMS') }, i18n.get('INF_CAMPAIGN_TERMS_CONDITIONS'))))) : (React.createElement(CampaignBriefLite_1.default, { campaign: campaign, isParticipation: isParticipation, isCampaignSoldOut: isCampaignSoldOut })),
                    bottom),
                React.createElement("div", { className: styles.right }, right))));
    }
}
exports.default = ParticipationLayout;
