"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const getMobileOS_1 = require("utils/getMobileOS");
const styles = require("./MobileAppBarUI.less");
const images_1 = require("assets/images");
class MobileAppBarUI extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { show: false };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const nUser = nextProps.currentUser;
        const oUser = this.props.currentUser;
        const os = getMobileOS_1.default();
        const isMobile = os === 'Android' || os === 'IOS' || true;
        if (isMobile && nUser && nUser.id !== oUser.id) {
            this.setState({ show: true });
        }
    }
    render() {
        const { show } = this.state;
        const { currentUser, appLinks } = this.props;
        if (!show)
            return null;
        const isAdvertiser = currentUser.role === 'advertiser';
        const thumb = isAdvertiser ? images_1.logoAppAdvertiser : images_1.logoAppCreators;
        const link = lodash_1.get(appLinks, [`${currentUser.role}s`]);
        if (window.innerWidth > 720)
            return null;
        return (React.createElement("div", { className: classnames_1.default(styles.wrapper, 'flex') },
            React.createElement("span", { className: styles.close, onClick: () => this.setState({ show: false }) },
                React.createElement("i", { className: 'vf-icon icon-close' })),
            React.createElement("img", { className: styles.thumb, src: thumb, alt: 'voxfeed logo' }),
            React.createElement("p", { className: styles.text }, i18n.get('SHARED_USE_THE_APP')),
            React.createElement("a", { className: styles.button, href: link }, i18n.get('OPEN'))));
    }
}
exports.default = MobileAppBarUI;
