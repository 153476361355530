import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from 'i18n';
import styles from './FilterHeaderTags.less';
import { IconTooltip } from 'modules/shared/components';

class FilterHeaderTags extends React.PureComponent {
  state = {
    showTip: false
  };
  render() {
    const { showTip } = this.state;
    const {
      tags,
      title,
      onRequestReset,
      onRequestRemoveTag,
      onRequestToggleFilters,
      isOpened,
      tooltipMessage,
      children
    } = this.props;

    const hasTags = tags.length > 0;

    const arrowStyle = {
      transform: isOpened ? 'rotate(90deg)' : 'rotate(-90deg)',
      height: '2rem',
      width: '2rem'
    };
    const wrapperClass = classNames('vf-bg-white-light-color');
    const titleClass = classNames(
      'flex flex-align-center flex-justify-space-between vf-clickable',
      ' vf-font-bold padding-right-1x padding-left-1x vf-bg-gray-light-color',
      { 'vf-text-primary': isOpened, 'vf-text-gray-dark': !isOpened }
    );

    const tagList = tags.map(tag => (
      <FilterTag
        key={tag.keys[tag.keys.length - 1]}
        value={tag.value}
        onRequestRemoveTag={() => onRequestRemoveTag(tag.keys)}
      />
    ));

    return (
      <div className={wrapperClass}>
        <div
          className={titleClass}
          style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
          onClick={onRequestToggleFilters}
        >
          <div className='flex'>
            {title}
            <IconTooltip message={tooltipMessage} icon='icon-info' />
          </div>

          <div className='flex flex-align-center'>
            {hasTags && (
              <div
                onClick={onRequestReset}
                className='vf-clickable margin-right-1x vf-text-secondary'
              >
                {i18n.get('ADV_INFLUENCERS_SEARCH_RESET')}
              </div>
            )}
            <div style={arrowStyle} className='vf-clickable'>
              <i className='vf-icon icon-arrow-left' />
            </div>
          </div>
        </div>

        {hasTags && (
          <div
            className={`flex flex-wrap padding-right-1x padding-left-1x vf-bg-gray-light-color ${styles.title}`}
          >
            {tagList}
          </div>
        )}

        {isOpened && <div className='vf-bg-white-light-color'>{children}</div>}
      </div>
    );
  }
}

FilterHeaderTags.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  isOpened: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onRequestReset: PropTypes.func.isRequired,
  onRequestRemoveTag: PropTypes.func.isRequired,
  onRequestToggleFilters: PropTypes.func.isRequired,
  tooltipMessage: PropTypes.string
};

FilterHeaderTags.defaultProps = {
  isOpened: false,
  tooltipMessage: ''
};

export default FilterHeaderTags;

const FilterTag = props => (
  <div className={styles.tag} onClick={props.onRequestRemoveTag}>
    {props.value}
    <span>
      <i className='vf-icon icon-close' />
    </span>
  </div>
);

FilterTag.propTypes = {
  onRequestRemoveTag: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};
