"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const classnames_1 = require("classnames");
const styles = require("./PartnerLoginUI.less");
const ui_library_1 = require("ui-library");
const components_1 = require("apps/shared/components");
const components_2 = require("apps/advertisers/PartnerForm/components");
const react_router_dom_1 = require("react-router-dom");
class PartnerLoginUI extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            remember: false
        };
        this.handleSubmit = event => {
            event.preventDefault();
            const { elements } = event.target;
            const { remember } = this.state;
            const { onSubmit } = this.props;
            const form = {
                email: elements.email.value,
                password: elements.password.value,
                remember
            };
            onSubmit(form);
        };
        this.handleInputBlur = event => {
            const { onInputBlur } = this.props;
            const { name, value } = event.target;
            onInputBlur(name, value);
        };
    }
    render() {
        const { errors } = this.props;
        return (React.createElement("div", null,
            React.createElement(components_2.default, null, i18n.get('LOGIN_WELCOME')),
            React.createElement("form", { action: '#', onSubmit: this.handleSubmit },
                React.createElement(components_1.FormLabel, { htmlFor: 'email' }, i18n.get('LOGIN_EMAIL')),
                React.createElement(components_1.FormInput, { name: 'email', type: 'email', hasError: !!errors.email, onBlur: this.handleInputBlur }),
                React.createElement(components_1.FormLabel, { htmlFor: 'password' }, i18n.get('LOGIN_PASSWORD')),
                React.createElement(components_1.FormInput, { name: 'password', type: 'password', hasError: !!errors.password, onBlur: this.handleInputBlur }),
                React.createElement("div", { className: 'margin-top-Hx flex vf-text-primary' },
                    React.createElement(react_router_dom_1.Link, { to: '/forgot', style: { marginLeft: 'auto' } }, i18n.get('LOGIN_FORGOT_PASSWORD'))),
                React.createElement("div", { className: 'vf-text-danger center-xs margin-top-1x vf-font-bold', style: { fontSize: '1.8rem' } },
                    React.createElement("div", null, errors.email),
                    React.createElement("div", null, errors.password)),
                React.createElement(ui_library_1.Button, { color: 'primary', size: 'lg', className: classnames_1.default('width100 margin-top-1x', styles.button), type: 'submit' }, i18n.get('LOGIN_LOGIN')),
                React.createElement(react_router_dom_1.Link, { to: '/register', className: 'width100 margin-top-1x vf-text-primary vf-btn vf-btn-flat vf-btn--lg' }, i18n.get('REGISTER_REGISTER')))));
    }
}
exports.default = PartnerLoginUI;
