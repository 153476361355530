"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const FuzzySearch = require("fuse.js");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const selectors_1 = require("modules/shared/selectors");
const components_1 = require("apps/advertisers/MainDashboard/controllers/CreditTransferDialog/components");
const BrandTranferCredit = props => {
    const [search, setSearch] = React.useState('');
    const [brandId, setBrandId] = React.useState('');
    const { brands } = props;
    const filterBrands = () => {
        const { brand } = props;
        const filterd = brands.filter(b => b._id !== brand._id);
        if (!search)
            return lodash_1.orderBy(filterd, 'createdAt', 'desc');
        const fuzzy = new FuzzySearch(filterd, { keys: ['name'], threshold: 0.3 }).search(search);
        return lodash_1.orderBy(fuzzy, 'createdAt', 'desc');
    };
    React.useEffect(() => {
        const { onChangeBrand } = props;
        onChangeBrand(brandId);
    }, [brandId]);
    return (React.createElement(components_1.BrandsSelect, { brands: filterBrands(), searchValue: search, selectedBrand: brands.find(b => b._id === brandId), onBrandChange: setBrandId, onInputChange: setSearch }));
};
function mapStateToProps(state, props) {
    return {
        brands: selectors_1.getBrandsByOrg(state, props.brand.organizationId)
    };
}
exports.default = react_redux_1.connect(mapStateToProps)(BrandTranferCredit);
