"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const _ = require("lodash");
const i18n = require("i18n");
const search_filter_1 = require("./search-filter");
const Search = props => {
    const [filters, setFilters] = React.useState(props.filters);
    const { value = '', onChange, className, placeholder } = props;
    const _onFilterClick = (group, selected) => {
        const currentFilter = Object.assign({}, filters);
        const optionGroup = _.get(currentFilter, group);
        const { onChangeFilter } = props;
        let index;
        if (_.isFunction(optionGroup.indexOf)) {
            index = optionGroup.indexOf(selected);
            optionGroup[index].checked = !optionGroup[index].checked;
        }
        else {
            optionGroup.checked = !optionGroup.checked;
        }
        currentFilter[group] = optionGroup;
        setFilters(currentFilter);
        if (_.isFunction(onChangeFilter))
            onChangeFilter(currentFilter);
    };
    let classes = 'vf-search';
    const placeholderValue = placeholder || i18n.get('SEARCH_PLACEHOLDER');
    if (className)
        classes += ' ' + className;
    return (React.createElement("div", { className: classes },
        React.createElement("input", { type: 'text', value: value, onChange: onChange, placeholder: placeholderValue }),
        React.createElement(ui_library_1.Icon, { icon: 'search' }),
        filters ? (React.createElement(search_filter_1.default, { onChange: _onFilterClick, filters: filters })) : null));
};
exports.default = Search;
