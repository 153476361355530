"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const components_1 = require("modules/shared/components");
const types_1 = require("modules/shared/types");
const getPostMedia_1 = require("../hocs/getPostMedia");
const PostMedia = ({ post, media, width, height, validLite }) => {
    var _a, _b, _c, _d, _e, _f;
    let redirectURL = '';
    let isInstaStoryVideo = false;
    const socialNetwork = (() => {
        const { socialNetwork } = post;
        return typeof socialNetwork === 'string'
            ? socialNetwork
            : socialNetwork.socialNetwork;
    })();
    const meta = ((_b = (_a = post === null || post === void 0 ? void 0 : post.socialNetworkMessage) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.mediaType) === 'VIDEO';
    if ((_d = (_c = post === null || post === void 0 ? void 0 : post.socialNetworkMessage) === null || _c === void 0 ? void 0 : _c.attachments) === null || _d === void 0 ? void 0 : _d.videos) {
        isInstaStoryVideo = true;
    }
    if (validLite) {
        if (media.images.length === 0 && media.videos.length === 0) {
            redirectURL = post.link;
            if (socialNetwork === types_1.SocialNetwork.TIKTOK &&
                redirectURL.includes('vm.tiktok.')) {
                media.videos = [redirectURL];
            }
        }
    }
    else {
        redirectURL = post.message.externalUrl;
    }
    return (React.createElement("div", { className: 'width100' },
        React.createElement(components_1.TextWithHighlightedElements, { className: 'margin-bottom-1x', text: media.text }),
        React.createElement(components_1.MediaCarousel, { width: width, height: height, link: redirectURL, images: (!meta || !isInstaStoryVideo) && media.images, videos: (!meta || !isInstaStoryVideo) && media.videos, videosUrls: (!meta || !isInstaStoryVideo) && media.videoUrls, socialNetwork: socialNetwork, validLite: validLite, videosInsta: meta &&
                isInstaStoryVideo && ((_f = (_e = post === null || post === void 0 ? void 0 : post.socialNetworkMessage) === null || _e === void 0 ? void 0 : _e.attachments) === null || _f === void 0 ? void 0 : _f.videos) })));
};
exports.default = getPostMedia_1.default(PostMedia);
