"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const isNil = require("lodash/isNil");
const types_1 = require("modules/shared/types");
function getAudienceAuthenticityLevelByValue(val) {
    if (isNil(val))
        return;
    if (val >= 0.75)
        return types_1.AudienceAuthenticityLevel.EXCELLENT;
    if (val >= 0.5)
        return types_1.AudienceAuthenticityLevel.GOOD;
    if (val >= 0.25)
        return types_1.AudienceAuthenticityLevel.REGULAR;
    return types_1.AudienceAuthenticityLevel.BAD;
}
exports.default = getAudienceAuthenticityLevelByValue;
