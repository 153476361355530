"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const alertActions = require("redux/modules/alerts");
const AlertUI_1 = require("./AlertUI/AlertUI");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const redux_1 = require("redux");
class Alert extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.handleDelete = id => {
            const { removeAlert } = this.props;
            removeAlert(id);
        };
    }
    render() {
        const { alerts } = this.props;
        return (React.createElement(AlertUI_1.default, { alerts: alerts, onDelete: this.handleDelete }));
    }
}
const mapStateToProps = state => ({
    alerts: lodash_1.map(lodash_1.get(state, 'entities.Alert'), error => error)
});
const mapDispatchToProp = dispatch => redux_1.bindActionCreators({
    removeAlert: alertActions.removeAlert
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProp)(Alert);
