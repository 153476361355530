import axios from 'axios';
import FormData from 'form-data';
import {amazonS3PlayerHost} from '../../../config/app';

export function uploadFileAWS(data) {
  const {fileKey, fileType, file, progressHanlder} = data;
  const bucketUrl = amazonS3PlayerHost;

  const formData = new FormData();
  formData.append('acl', 'authenticated-read');
  formData.append('key', `${fileKey}`);
  formData.append('success_action_status', '200');
  formData.append('x-amz-meta-content-type', fileType);
  formData.append('file', file);

  const config = {
    headers: {
      'enctype': 'multipart/form-data',
      'content-type': 'multipart/form-data'
    },
    onUploadProgress: progressHanlder
  };

  return axios.post(bucketUrl, formData, config);
}
