import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { has, omit, isPlainObject, isNil } from 'lodash';

import InfluencerSearchFilters from './InfluencerSearchFilters/InfluencerSearchFilters';

export default class SearchFilter extends Component {
  onChangeAdvancedSearch = (term, evtValue) => {
    if (isPlainObject(term)) return this.onChangeMultipleFilters(term);
    return this.onChangeSingleFilter(term, evtValue);
  };

  onChangeSingleFilter = (term, evtValue) => {
    const { onChangeSearchFilter } = this.props;
    const value = has(evtValue, 'target') ? evtValue.target.value : evtValue;
    const newFilters = value
      ? { ...this.props.searchFilter, [term]: value }
      : omit(this.props.searchFilter, term);

    onChangeSearchFilter(newFilters);
  };

  onChangeMultipleFilters = filters => {
    const updatedFilters = Object.keys(filters).reduce((result, filter) => {
      if (!isNil(filters[filter])) result[filter] = filters[filter];
      return result;
    }, {});

    const unsetFilters = Object.keys(filters).reduce((result, filter) => {
      if (isNil(filters[filter])) result.push(filter);
      return result;
    }, []);

    const newFilters = {
      ...omit(this.props.searchFilter, unsetFilters),
      ...updatedFilters
    };

    this.props.onChangeSearchFilter(newFilters);
  };

  render = () => {
    const {
      availableSocialNetworks,
      showBlockedOption,
      showFavoriteOption,
      searchFilter,
      onRequestClose,
      onRequestReset
    } = this.props;

    return (
      <div className='vf-card no-padding'>
        <InfluencerSearchFilters
          showBlockedOption={showBlockedOption}
          showFavoriteOption={showFavoriteOption}
          availableSocialNetworks={availableSocialNetworks}
          onChange={this.onChangeAdvancedSearch}
          filters={searchFilter}
          onRequestClose={onRequestClose}
          onRequestReset={onRequestReset}
        />
      </div>
    );
  };
}

SearchFilter.propTypes = {
  searchFilter: PropTypes.object.isRequired,
  onChangeSearchFilter: PropTypes.func.isRequired,
  showBlockedOption: PropTypes.bool,
  showFavoriteOption: PropTypes.bool,
  availableSocialNetworks: PropTypes.array
};
