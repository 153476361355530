import React from 'react';
import PropTypes from 'prop-types';
import { isFinite } from 'lodash';
import classNames from 'classnames';

import calculatePercentualReliability from '../../../../utils/calculateSocialAccountPercentualReliability';
import shouldShowSocialAccountReliability from '../../../../utils/shouldShowSocialAccountReliability';

import styles from './ReliabilityBar.less';

const ReliabilityBar = ({ socialAccount, type = 'incremental' }) => {
  const percentualRealiability = calculatePercentualReliability(socialAccount);

  return (
    <div className={getScoreBarClassNames(type, percentualRealiability)}>
      <div
        className={getPositiveScoreBarClassNames(type, percentualRealiability)}
        style={{
          width: getPositiveBarClassWidth(
            type,
            socialAccount,
            percentualRealiability
          )
        }}
      />
    </div>
  );
};

ReliabilityBar.propTypes = {
  socialAccount: PropTypes.object,
  type: PropTypes.string
};

const getScoreBarClassNames = (type, reliability) => {
  return classNames('width100 margin-top-1x', styles['score-bar'], {
    'vf-bg-danger-color': type === 'comparative' && isFinite(reliability),
    'vf-bg-gray-color':
      type === 'incremental' ||
      (type === 'comparative' && !isFinite(reliability)),
    'vf-clickable': type === 'incremental'
  });
};

const getPositiveScoreBarClassNames = (type, percentualRealiability) => {
  if (type === 'comparative')
    return classNames('vf-bg-primary-color', styles['positive-score']);

  return classNames(styles['positive-score'], {
    'vf-bg-primary-color': percentualRealiability > 75,
    'vf-bg-warning-color':
      percentualRealiability > 50 && percentualRealiability < 76,
    'vf-bg-danger-color': percentualRealiability < 51
  });
};

const getPositiveBarClassWidth = (
  type,
  socialAccount,
  percentualRealiability
) => {
  if (type === 'comparative') return `${percentualRealiability}%`;

  const hasReliability =
    shouldShowSocialAccountReliability(socialAccount) &&
    isFinite(percentualRealiability);
  return `${hasReliability ? percentualRealiability : 0}%`;
};

export default ReliabilityBar;
