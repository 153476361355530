"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const styles = require("./Item.less");
class Item extends React.Component {
    constructor() {
        super(...arguments);
        this.getType = () => {
            const { place } = this.props;
            switch (place.type) {
                case 'admin1':
                    return i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_LOCATION_REGION_OPTION_LABEL');
                case 'city':
                    return i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_LOCATION_CITY_OPTION_LABEL');
                case 'country':
                    return i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_LOCATION_COUNTRY_OPTION_LABEL');
            }
        };
        this.getName = () => {
            const { place } = this.props;
            if (place.country)
                return `${place.name}, ${place.country.name}`;
            return place.name;
        };
        this.handleClick = event => {
            const { onClick, place } = this.props;
            const type = place.type === 'admin1' ? 'region' : place.type;
            onClick(type, place.id);
        };
    }
    render() {
        return (React.createElement("div", { className: classnames_1.default(styles.item, 'flex flex-justify-space-between'), onClick: this.handleClick },
            this.getName(),
            React.createElement("span", null, this.getType())));
    }
}
exports.default = Item;
