"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const DaysForPaymentDisclaimer = ({ days }) => (React.createElement("div", null,
    React.createElement("div", { className: 'flex' },
        React.createElement("h3", { className: 'no-margin vf-bold' }, i18n.get('INF_SOCIAL_ACCOUNTS_PUBLIC_PARTICIPATION_AVAILABILITY_TITLE')),
        React.createElement("span", { className: 'vf-bold', style: { flexShrink: 0 } }, i18n.get('DATE_COUNTER_DAYS', { days }))),
    React.createElement("p", { className: 'margin-top-1x vf-text-gray-dark' }, i18n.get('INF_SOCIAL_ACCOUNTS_PUBLIC_PARTICIPATION_AVAILABILITY_EXPLAIN'))));
exports.default = DaysForPaymentDisclaimer;
