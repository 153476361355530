import i18n from 'i18n';
import { isFinite } from 'lodash';

const STATICS_BY_LOCALE = {};

function buildStatics() {
  const currentLocale = i18n.getLocale();
  if (!currentLocale) return;
  if (STATICS_BY_LOCALE[currentLocale]) return STATICS_BY_LOCALE[currentLocale];

  const statics = {
    socialNetworks: {
      instagram: {
        active: true,
        name: 'Instagram',
        characterLimitCount: 2200,
        stats: ['likes', 'comments'],
        colorHex: 'e4405f',
        colorRgb: '228,64,95',
        icon: 'instagram',
        postIcon: 'instagram',
        storyIcon: 'ic-igstory',
        disableIdentical: false,
        publicationTypes: {
          post: {
            maxHashtags: 3,
            maxMentions: 1,
            maxLinks: null
          },
          story: {
            maxHashtags: 2,
            maxMentions: 1,
            maxLinks: null
          }
        },
        getBackgroundStyle: () => ({
          background:
            'radial-gradient(circle at 33% 100%, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF)'
        })
      },
      facebook: {
        active: true,
        name: 'Facebook',
        characterLimitCount: 50000,
        stats: ['likes', 'shares', 'clicksTotal', 'comments'],
        colorHex: '3B5998',
        colorRgb: '59,89,152',
        icon: 'facebook',
        postIcon: 'facebook',
        storyIcon: 'facebook',
        disableIdentical: false,
        publicationTypes: {
          post: {
            maxHashtags: 2,
            maxMentions: 1,
            maxLinks: 1
          }
        },
        getBackgroundStyle: () => ({ backgroundColor: '#1778F2' })
      },
      youtube: {
        active: true,
        name: 'Youtube',
        characterLimitCount: 5000,
        stats: ['likes', 'videoViews', 'comments'],
        colorHex: 'd63a36',
        colorRgb: '214,58,54',
        icon: 'youtube-play',
        postIcon: 'youtube-play',
        storyIcon: 'youtube-play',
        disableIdentical: true,
        publicationTypes: {
          post: {
            maxHashtags: null,
            maxMentions: 1,
            maxLinks: 1
          }
        },
        getBackgroundStyle: () => ({ backgroundColor: '#d63a36' })
      },
      tiktok: {
        active: true,
        name: 'TikTok',
        characterLimitCount: 1000,
        stats: ['likes', 'videoViews', 'comments'],
        colorHex: '000000',
        colorRgb: '0,0,0',
        icon: 'youtube-play',
        iconImage: 'tiktokIcon',
        postIcon: 'youtube-play',
        storyIcon: 'youtube-play',
        disableIdentical: true,
        publicationTypes: {
          post: {
            maxHashtags: 3,
            maxMentions: 1,
            maxLinks: null
          }
        },
        getBackgroundStyle: () => ({ backgroundColor: '#000000' })
      }
    },
    socialNetworksLite: {
      tiktok: {
        active: true,
        name: 'TikTok',
        label: 'tiktok'
      },
      instagramPost: {
        active: true,
        name: 'Instagram Post',
        label: 'instagramPost'
      },
      instagramStory: {
        active: true,
        name: 'Instagram Story',
        label: 'instagramStory'
      },
      facebook: {
        active: true,
        name: 'Facebook',
        label: 'facebook'
      },
      youtube: {
        active: true,
        name: 'Youtube',
        label: 'youtube'
      }
    },
    getSocialNetworkName: find => {
      switch (find) {
        case 'instagram':
          return 'Instagram';
        case 'facebook':
          return 'Facebook';
        case 'youtube':
          return 'Youtube';
        case 'tiktok':
          return 'TikTok';
        default:
          return '';
      }
    },
    voxfeed: {
      phone: '01 (33) 2003 0374',
      minimunPayAmount: 50,
      suggestedPayAmount: 100,
      minCashOutAmount: 10,

      colorRgb: '23,212,230',
      colorHex: '17d4e6'
    },
    invitationCampaigns: {
      paymentDelay: 30 // in days
    },
    paypal: {
      name: 'PayPal',
      commission: 0.029,
      extraCommission: 0.3
    },
    payoneer: {
      name: 'Payoneer',
      commission: 3.0,
      extraCommission: 0
    },
    invoice: {
      name: i18n.get('STATICS_INVOICE_NAME'),
      tax: 0.16
    },
    months: [
      {
        name: i18n.get('STATICS_MONTHS_JANUARY'),
        days: 31
      },
      {
        name: i18n.get('STATICS_MONTHS_FEBRUARY'),
        days: 28
      },
      {
        name: i18n.get('STATICS_MONTHS_MARCH'),
        days: 31
      },
      {
        name: i18n.get('STATICS_MONTHS_APRIL'),
        days: 30
      },
      {
        name: i18n.get('STATICS_MONTHS_MAY'),
        days: 31
      },
      {
        name: i18n.get('STATICS_MONTHS_JUNE'),
        days: 30
      },
      {
        name: i18n.get('STATICS_MONTHS_JULY'),
        days: 31
      },
      {
        name: i18n.get('STATICS_MONTHS_AUGUST'),
        days: 31
      },
      {
        name: i18n.get('STATICS_MONTHS_SEPTEMBER'),
        days: 30
      },
      {
        name: i18n.get('STATICS_MONTHS_OCTOBER'),
        days: 31
      },
      {
        name: i18n.get('STATICS_MONTHS_NOVEMBER'),
        days: 30
      },
      {
        name: i18n.get('STATICS_MONTHS_DECEMBER'),
        days: 31
      }
    ],
    days: [
      {
        es: i18n.get('STATICS_DAY_SUNDAY'),
        db: 0
      },
      {
        es: i18n.get('STATICS_DAY_MONDAY'),
        db: 1
      },
      {
        es: i18n.get('STATICS_DAY_TUESDAY'),
        db: 2
      },
      {
        es: i18n.get('STATICS_DAY_WEDNESDAY'),
        db: 3
      },
      {
        es: i18n.get('STATICS_DAY_THURSDAY'),
        db: 4
      },
      {
        es: i18n.get('STATICS_DAY_FRIDAY'),
        db: 5
      },
      {
        es: i18n.get('STATICS_DAY_SATURDAY'),
        db: 6
      }
    ],

    userRoles: {
      INFLUENCER: {
        db: 'influencer',
        es: i18n.get('STATICS_USER_ROLE_INFLUENCER')
      },
      ADVERTISER: {
        db: 'advertiser',
        es: i18n.get('STATICS_USER_ROLE_ADVERTISER')
      },
      AGENCY: {
        db: 'agency',
        es: i18n.get('STATICS_USER_ROLE_AGENCY')
      },
      ADMIN: {
        db: 'admin',
        es: i18n.get('STATICS_USER_ROLE_ADMIN')
      }
    },

    campaignStats: {
      'reach': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_REACH'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_REACH'),
        value: v => i18n.getNumber(v)
      },
      'urlClicks': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_CLICKS'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_CLICKS'),
        value: v => i18n.getNumber(v)
      },
      'comments': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_COMMENTS'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_COMMENTS'),
        value: v => i18n.getNumber(v)
      },
      'costParticipation': {
        name: i18n.get('ADV_POST_LIST_INFLUENCER_COST_PER_POST'),
        fullName: i18n.get('ADV_POST_LIST_INFLUENCER_COST_PER_POST'),
        value: (v, c) => i18n.getMoney({ amount: v / 100, currency: c })
      },
      'costTotal': {
        name: i18n.get('ADV_CAMPAIGN_CARD_TOTAL_COST_LABEL'),
        fullName: i18n.get('ADV_CAMPAIGN_CARD_TOTAL_COST_LABEL'),
        value: (v, c) => i18n.getMoney({ amount: v / 100, currency: c })
      },
      'serviceFee': {
        name: i18n.get('ADV_CAMPAIGNS_INVITES_TOTAL_CHARGE'),
        fullName: i18n.get('ADV_CAMPAIGNS_INVITES_TOTAL_CHARGE'),
        value: (v, c) => i18n.getMoney({ amount: v / 100, currency: c })
      },
      'cpc': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_CPC'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_CPC_FULL_NAME'),
        value: (v, c) => i18n.getMoney({ amount: v / 100, currency: c })
      },
      'cpm': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_CPM'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_CPM_FULL_NAME'),
        value: (v, c) => i18n.getMoney({ amount: v / 100, currency: c })
      },
      'ctr': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_CTR'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_CTR_FULL_NAME'),
        value: (v, c) => i18n.getMoney({ amount: v, currency: c })
      },
      'cpe': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_CPE'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_CPE_FULL_NAME'),
        value: (v, c) => i18n.getMoney({ amount: v / 100, currency: c })
      },
      'cpv': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_CPV'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_CPV_FULL_NAME'),
        value: (v, c) => i18n.getMoney({ amount: v / 100, currency: c })
      },
      'cpp': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_CPP'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_CPP_FULL_NAME'),
        value: (v, c) => i18n.getMoney({ amount: v / 100, currency })
      },
      'likes': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_LIKES'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_LIKES'),
        value: v => i18n.getNumber(v)
      },
      'displayLikes': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_LIKES'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_LIKES'),
        value: v => i18n.getNumber(v)
      },
      'posts': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_POSTS'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_POSTS'),
        value: v => i18n.getNumber(v)
      },
      'shares': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_SHARES'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_SHARES'),
        value: v => i18n.getNumber(v)
      },
      'videoViews': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_VIDEO_VIEWS'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_VIDEO_VIEWS'),
        value: v => i18n.getNumber(v)
      },
      'engagements': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_ENGAGEMENTS'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_ENGAGEMENTS'),
        value: v => i18n.getNumber(v)
      },
      'engagementRate': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_ENGAGEMENT_RATE'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_ENGAGEMENT_RATE'),
        value: v => (isFinite(v) ? `${i18n.getNumber(v, 2)}%` : null)
      },
      'engRateByImpressions': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_ENG_RATE_BY_IMPRESSIONS'),
        fullName: i18n.get(
          'STATICS_CAMPAIGN_STATS_ENG_RATE_BY_IMPRESSIONS_FULL_NAME'
        ),
        value: v => (isFinite(v) ? `${i18n.getNumber(v, 2)}%` : null)
      },
      'date': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_DATE'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_DATE'),
        value: v => i18n.getDate(v)
      },
      'reactionsLove': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_LOVE'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_LOVE'),
        value: v => i18n.getNumber(v)
      },
      'reactionsWow': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_WOW'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_WOW'),
        value: v => i18n.getNumber(v)
      },
      'reactionsHaha': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_HAHA'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_HAHA'),
        value: v => i18n.getNumber(v)
      },
      'reactionsSad': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_SAD'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_SAD'),
        value: v => i18n.getNumber(v)
      },
      'reactionsAngry': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_ANGRY'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_ANGRY'),
        value: v => i18n.getNumber(v)
      },
      'reactionsTotal': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_LIKES'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_LIKES'),
        value: v => i18n.getNumber(v)
      },
      'impressions': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_IMPRESSIONS'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_IMPRESSIONS'),
        value: v => i18n.getNumber(v)
      },
      'impressionsUnique': {
        name: i18n.get('STORY_STATS_VIEWS'),
        fullName: i18n.get('STORY_STATS_VIEWS'),
        value: v => i18n.getNumber(v)
      },
      'impressionsUnique-advertiser': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_IMPRESSIONS_UNIQUE'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_IMPRESSIONS_UNIQUE'),
        value: v => i18n.getNumber(v)
      },
      'impressionsEstimated': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_ESTIMATED_IMPRESSIONS'),
        fullName: i18n.get('STATICS_CAMPAIGN_STATS_ESTIMATED_IMPRESSIONS'),
        value: v => i18n.getNumber(v)
      },
      'impressionsUniqueEstimated': {
        name: i18n.get('STATICS_CAMPAIGN_STATS_ESTIMATED_IMPRESSIONS_UNIQUE'),
        fullName: i18n.get(
          'STATICS_CAMPAIGN_STATS_ESTIMATED_IMPRESSIONS_UNIQUE'
        ),
        value: v => i18n.getNumber(v)
      }
    },

    endorsementDisclosure: {
      hashtags: {
        ad: {
          name: '#ad',
          value: 'ad'
        },
        paid: {
          name: '#paid',
          value: 'paid'
        },
        sponsored: {
          name: '#sponsored',
          value: 'sponsored'
        }
      }
    },

    accountTypes: {
      person: {
        name: i18n.get('STATICS_ACCOUNT_TYPES_PERSON_LABEL'),
        db: 'person',
        icon: 'user',
        description: i18n.get('STATICS_ACCOUNT_TYPES_PERSON_DESCRIPTION')
      },
      topic: {
        name: i18n.get('STATICS_ACCOUNT_TYPES_TOPIC_LABEL'),
        db: 'topic',
        icon: 'news',
        description: i18n.get('STATICS_ACCOUNT_TYPES_TOPIC_DESCRIPTION')
      }
    },

    gender: {
      female: {
        es: i18n.get('STATICS_GENDER_FEMALE'),
        db: 'female',
        icon: 'dashboard'
      },
      male: {
        es: i18n.get('STATICS_GENDER_MALE'),
        db: 'male',
        icon: 'search'
      }
    },

    objective: {
      link: {
        id: 0,
        description: i18n.get('STATICS_OBJECTIVE_LINK_DESCRIPTION'),
        es: i18n.get('STATICS_OBJECTIVE_LINK_LABEL'),
        placeholder: 'http://vofeed.com'
      },
      hashtag: {
        id: 1,
        description: i18n.get('STATICS_OBJECTIVE_HASHTAG_DESCRIPTION'),
        es: i18n.get('STATICS_OBJECTIVE_HASHTAG_LABEL'),
        placeholder: '#voxfeed'
      },
      mention: {
        id: 2,
        description: i18n.get('STATICS_OBJECTIVE_MENTION_DESCRIPTION'),
        es: i18n.get('STATICS_OBJECTIVE_MENTION_LABEL'),
        placeholder: '@voxfeed'
      }
    },

    rudeLanguage: {
      never: {
        es: i18n.get('STATICS_RUDE_LANGUAGE_NEVER'),
        db: 'never'
      },
      sometimes: {
        es: i18n.get('STATICS_RUDE_LANGUAGE_SOMETIMES'),
        db: 'sometimes'
      },
      always: {
        es: i18n.get('STATICS_RUDE_LANGUAGE_ALWAYS'),
        db: 'always'
      }
    },

    sensitive: {
      never: {
        es: i18n.get('STATICS_SENSITIVE_NEVER'),
        db: 'never'
      },
      sometimes: {
        es: i18n.get('STATICS_SENSITIVE_SOMETIMES'),
        db: 'sometimes'
      },
      always: {
        es: i18n.get('STATICS_SENSITIVE_ALWAYS'),
        db: 'always'
      }
    },

    hasSensitiveContent: {
      'false': {
        emoji: '🙆',
        es: i18n.get('STATICS_HAS_SENSITIVE_CONTENT_FALSE_LABEL'),
        description: i18n.get('STATICS_HAS_SENSITIVE_CONTENT_FALSE_DESCRIPTION')
      },
      'true': {
        emoji: '💩',
        es: i18n.get('STATICS_HAS_SENSITIVE_CONTENT_TRUE_LABEL'),
        description: i18n.get('STATICS_HAS_SENSITIVE_CONTENT_TRUE_DESCRIPTION')
      }
    },

    campaignStatus: [
      {
        name: i18n.get('STATICS_CAMPAIGN_STATUS_ALL'),
        value: ['active', 'paused', 'archived']
      },
      {
        name: i18n.get('STATICS_CAMPAIGN_STATUS_NOT_ARCHIVED'),
        value: ['active', 'paused']
      },
      {
        name: i18n.get('STATICS_CAMPAIGN_STATUS_ARCHIVED'),
        value: ['archived']
      }
    ],

    adgroupTypes: {
      CPC: {
        es: i18n.get('STATICS_ADGROUP_TYPES_CPC'),
        description: '',
        unitType: 'click'
      },
      CPM: {
        es: i18n.get('STATICS_ADGROUP_TYPES_CPM'),
        description: '',
        unitType: 'impression'
      },
      RP: {
        es: i18n.get('STATICS_ADGROUP_TYPES_RP'),
        description: '',
        unitType: 'message'
      }
    },

    unitTypes: {
      click: {
        es: i18n.get('STATICS_UNIT_TYPES_CLICK')
      },
      impression: {
        es: i18n.get('STATICS_UNIT_TYPES_IMPRESSION')
      }
    },

    adgroupTones: {
      free: {
        name: i18n.get('STATICS_ADGROUPS_TONES_FREE_LABEL'),
        description: i18n.get('STATICS_ADGROUPS_TONES_FREE_DESCRIPTION')
      },
      casual: {
        name: i18n.get('STATICS_ADGROUPS_TONES_CASUAL_LABEL'),
        description: i18n.get('STATICS_ADGROUPS_TONES_CASUAL_DESCRIPTION')
      },
      formal: {
        name: i18n.get('STATICS_ADGROUPS_TONES_FORMAL_LABEL'),
        description: i18n.get('STATICS_ADGROUPS_TONES_FORMAL_DESCRIPTION')
      }
    },

    invites: {
      rejectionReasons: {
        like: i18n.get('STATICS_INVITES_REJECTION_REASONS_LIKE'),
        payment: i18n.get('STATICS_INVITES_REJECTION_REASONS_PAYMENT'),
        interests: i18n.get('STATICS_INVITES_REJECTION_REASONS_INTERESTS'),
        other: i18n.get('STATICS_INVITES_REJECTION_REASONS_OTHER')
      },
      finishReasons: {
        endedCampaign: i18n.get(
          'STATICS_INVITES_FINISH_REASONS_ENDED_CAMPAIGN'
        ),
        campaignObjective: i18n.get(
          'STATICS_INVITES_FINISH_REASONS_CAMPAIGN_OBJECTIVE'
        ),
        influencerPerformance: i18n.get(
          'STATICS_INVITES_FINISH_REASONS_INFLUENCER_PERFORMANCE'
        ),
        other: i18n.get('STATICS_INVITES_FINISH_REASONS_OTHER')
      }
    },

    rejectReasons: {
      inappropriateContent: {
        name: i18n.get('STATICS_REJECT_REASONS_INAPPROPRIATE_CONTENT_LABEL'),
        description: i18n.get(
          'STATICS_REJECT_REASONS_INAPPROPRIATE_CONTENT_TEXT'
        )
      },
      repeatedContent: {
        name: i18n.get('STATICS_REJECT_REASONS_REPEATED_CONTENT_LABEL'),
        description: i18n.get('STATICS_REJECT_REASONS_REPEATED_CONTENT_TEXT')
      },
      privateAccount: {
        name: i18n.get('STATICS_REJECT_REASONS_PRIVATE_ACCOUNT_LABEL'),
        description: i18n.get('STATICS_REJECT_REASONS_PRIVATE_ACCOUNT_TEXT')
      },
      inadequateAccount: {
        name: i18n.get('STATICS_REJECT_REASONS_INADEQUATE_ACCOUNT_LABEL'),
        description: i18n.get('STATICS_REJECT_REASONS_INADEQUATE_ACCOUNT_TEXT')
      },
      stockImage: {
        name: i18n.get('STATICS_REJECT_REASONS_STOCK_IMAGE_LABEL'),
        description: i18n.get('STATICS_REJECT_REASONS_STOCK_IMAGE_TEXT')
      },
      inadequateImage: {
        name: i18n.get('STATICS_REJECT_REASONS_INADEQUATE_IMAGE_LABEL'),
        description: i18n.get('STATICS_REJECT_REASONS_INADEQUATE_IMAGE_TEXT')
      },
      other: {
        name: i18n.get('STATICS_REJECT_REASONS_OTHER_LABEL'),
        description: ''
      }
    },

    blockReasons: {
      copyPaste: {
        es: i18n.get('STATICS_BLOCK_REASONS_COPY_PASTE_LABEL'),
        text: i18n.get('STATICS_BLOCK_REASONS_COPY_PASTE_TEXT')
      },
      externalClicks: {
        es: i18n.get('STATICS_BLOCK_REASONS_EXTERNAL_CLICKS_LABEL'),
        text: i18n.get('STATICS_BLOCK_REASONS_EXTERNAL_CLICKS_TEXT')
      },
      editTweet: {
        es: i18n.get('STATICS_BLOCK_REASONS_EDIT_TWEET_LABEL'),
        text: i18n.get('STATICS_BLOCK_REASONS_EDIT_TWEET_TEXT')
      },
      inviteToClick: {
        es: i18n.get('STATICS_BLOCK_REASONS_COPY_INVITE_TO_CLICK_LABEL'),
        text: i18n.get('STATICS_BLOCK_REASONS_COPY_INVITE_TO_CLICK_TEXT')
      },
      inviteToRT: {
        es: i18n.get('STATICS_BLOCK_REASONS_INVITE_TO_RT_LABEL'),
        text: i18n.get('STATICS_BLOCK_REASONS_INVITE_TO_RT_TEXT')
      },
      decks: {
        es: i18n.get('STATICS_BLOCK_REASONS_DECKS_LABEL'),
        text: i18n.get('STATICS_BLOCK_REASONS_DECKS_TEXT')
      },
      trap: {
        es: i18n.get('STATICS_BLOCK_REASONS_TRAP_LABEL'),
        text: i18n.get('STATICS_BLOCK_REASONS_TRAP_TEXT')
      },
      multiAccounts: {
        es: i18n.get('STATICS_BLOCK_REASONS_MULTI_ACCOUNTS_LABEL'),
        text: i18n.get('STATICS_BLOCK_REASONS_MULTI_ACCOUNTS_TEXT')
      },
      groups: {
        es: i18n.get('STATICS_BLOCK_REASONS_GROUPS_LABEL'),
        text: i18n.get('STATICS_BLOCK_REASONS_GROUPS_LABEL')
      },
      other: {
        es: i18n.get('STATICS_BLOCK_REASONS_OTHER_LABEL'),
        text: ''
      }
    },

    campaignPay: {
      social: {
        es: () => i18n.get('STATICS_CAMPAIGN_PAY_SOCIAL'),
        badge: 'csl'
      },
      click: {
        es: country => i18n.get('STATICS_CAMPAIGN_PAY_CLICK', { country }),
        badge: 'ppc'
      },
      message: {
        es: socialNetwork =>
          i18n.get('STATICS_CAMPAIGN_PAY_MESSAGE', { socialNetwork }),
        badge: 'ppm'
      },
      impression: {
        es: socialNetwork =>
          i18n.get('STATICS_CAMPAIGN_PAY_IMPRESSION', { socialNetwork }),
        badge: 'ppi'
      },
      acquisition: {
        es: socialNetwork =>
          i18n.get('STATICS_CAMPAIGN_PAY_ACQUISITION', { socialNetwork }),
        badge: 'ppa'
      }
    },

    about: {
      help: 'https://voxfeed.zendesk.com/hc/es',
      blog: 'http://blog.voxfeed.com/',
      terms: 'http://voxfeed.com/terms'
    },

    shortLocale: {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'now',
        m: '%dm',
        mm: '%dm',
        h: '%dh',
        hh: '%dh',
        d: '%dd',
        dd: '%dd',
        M: '%dm',
        MM: '%dm',
        y: '%dy',
        yy: '%dy'
      }
    },

    stripeErrorCodeMessages: {
      invalidNumber: i18n.get(
        'STATICS_STRIP_ERROR_CODE_MESSAGES_INVALID_NUMBER'
      ),
      cardDeclined: i18n.get('STATICS_STRIP_ERROR_CODE_MESSAGES_CARD_DECLINED'),
      incorrectNumber: i18n.get(
        'STATICS_STRIP_ERROR_CODE_MESSAGES_INCORRECT_NUMBER'
      ),
      invalidExpiryMonth: i18n.get(
        'STATICS_STRIP_ERROR_CODE_MESSAGES_INVALID_EXPIRY_MONTH'
      ),
      invalidExpiryYear: i18n.get(
        'STATICS_STRIP_ERROR_CODE_MESSAGES_INVALID_EXPIRY_YEAR'
      ),
      invalidCvc: i18n.get('STATICS_STRIP_ERROR_CODE_MESSAGES_INVALID_CVC'),
      expiredCard: i18n.get('STATICS_STRIP_ERROR_CODE_MESSAGES_EXPIRED_CARD'),
      incorrectCvc: i18n.get('STATICS_STRIP_ERROR_CODE_MESSAGES_INCORRECT_CVC'),
      incorrectZip: i18n.get('STATICS_STRIP_ERROR_CODE_MESSAGES_INCORRECT_ZIP'),
      processingError: i18n.get(
        'STATICS_STRIP_ERROR_CODE_MESSAGES_PROCESSING_ERROR'
      )
    },

    facebookPermissions: [
      'public_profile',
      'pages_read_engagement',
      'pages_show_list',
      'pages_read_user_content',
      'read_insights'
    ],

    instagramPermissions: [
      'pages_show_list',
      'instagram_basic',
      'instagram_manage_insights',
      'business_management'
    ],

    interests: {
      ANIMALS: {
        icon: 'animals'
      },
      ART: {
        icon: 'art'
      },
      BEAUTY: {
        icon: 'beauty'
      },
      PERSONAL_CARE: {
        icon: 'personal-care'
      },
      BUSINESS: {
        icon: 'business'
      },
      GOVERNMENT: {
        icon: 'governament'
      },
      EDUCATION: {
        icon: 'education'
      },
      FASHION: {
        icon: 'fashion'
      },
      ENTERTAINMENT: {
        icon: 'entertainment'
      },
      FAMILY: {
        icon: 'family'
      },
      HEALTH: {
        icon: 'health'
      },
      FITNESS: {
        icon: 'fitness'
      },
      FOODDRINKS: {
        icon: 'food'
      },
      ALCOHOL: {
        icon: 'alcohol'
      },
      GAMING: {
        icon: 'gaming'
      },
      NEWS: {
        icon: 'news-2'
      },
      TECH: {
        icon: 'tech'
      },
      TELECOM: {
        icon: 'telecom'
      },
      MUSIC: {
        icon: 'music'
      },
      SPORTS: {
        icon: 'sports'
      },
      PHOTOGRAPHY: {
        icon: 'photography'
      },
      TOURISM_AND_TRAVEL: {
        icon: 'tourism-and-travel'
      },
      LIFESTYLE: {
        icon: 'lifestyle'
      },
      MOVIES_AND_TV: {
        icon: 'movies-and-tv'
      },
      AUTOMOTIVE: {
        icon: 'automotive'
      },
      LUXURY_PRODUCTS: {
        icon: 'luxury'
      },
      ECOMMERCE: {
        icon: 'e-commerce'
      },
      DEPARTMENT_STORES: {
        icon: 'department-store'
      },
      CONSUMER_PRODUCTS: {
        icon: 'customer-products'
      },
      ONGS: {
        icon: 'ong'
      },
      RELIGION: {
        icon: 'religion'
      },
      ADULT_CONTENT: {
        icon: 'adult-content'
      }
    },

    interestsLite: {
      alternativeOrIndie: {
        value: 'ALTERNATIVE_OR_INDIE',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_ALTERNATIVE_OR_INDIE')
      },
      blues: {
        value: 'BLUES',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_BLUES')
      },
      classical: {
        value: 'CLASSICAL',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_CLASSICAL')
      },
      electronic: {
        value: 'ELECTRONIC',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_ELECTRONIC')
      },
      gospel: {
        value: 'GOSPEL',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_GOSPEL')
      },
      HipHopOrRap: {
        value: 'HIPHOP_OR_RAP',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_HIP_HOP_OR_RAP')
      },
      instrumental: {
        value: 'INSTRUMENTAL',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_INSTRUMENTAL')
      },
      jazz: {
        value: 'JAZZ',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_JAZZ')
      },
      KPop: {
        value: 'KPOP',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_K_POP')
      },
      latin: {
        value: 'LATIN',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_LATIN')
      },
      metal: {
        value: 'METAL',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_METAL')
      },
      opera: {
        value: 'OPERA',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_OPERA')
      },
      pop: {
        value: 'POP',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_POP')
      },
      punk: {
        value: 'PUNK',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_PUNK')
      },
      RAndB: {
        value: 'R_AND_B',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_R_AND_B')
      },
      reggaeton: {
        value: 'REGGAETON',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_REGGAETON')
      },
      regionalMexican: {
        value: 'REGIONAL_MEXICAN',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_REGIONAL_MEXICAN')
      },
      rock: {
        value: 'ROCK',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_ROCK')
      },
      soundtrack: {
        value: 'SOUNDTRACK',
        label: i18n.get('STATICS_CAMPAIGN_MUSIC_GENRE_SOUNDTRACK')
      }
    },

    invitesStatuses: {
      created: i18n.get('STATICS_INVITES_STATUSES_CREATED'),
      accepted: i18n.get('STATICS_INVITES_STATUSES_ACCEPTED'),
      cancelled: i18n.get('STATICS_INVITES_STATUSES_CANCELLED'),
      finished: i18n.get('STATICS_INVITES_STATUSES_FINISHED')
    },

    reportReasons: {
      violent: i18n.get('STATICS_REPORT_REASONS_VIOLENT'),
      offensive: i18n.get('STATICS_REPORT_REASONS_OFFENSIVE'),
      political: i18n.get('STATICS_REPORT_REASONS_POLITICAL'),
      illegal: i18n.get('STATICS_REPORT_REASONS_ILLEGAL'),
      misleading: i18n.get('STATICS_REPORT_REASONS_MISLEADING'),
      other: i18n.get('STATICS_REPORT_REASONS_OTHER')
    },

    reportPostReasons: {
      correspond: i18n.get('STATICS_REPORT_POST_REASONS_CORRESPOND'),
      mandatory: i18n.get('STATICS_REPORT_POST_REASONS_MANDATORY'),
      time: i18n.get('STATICS_REPORT_POST_REASONS_TIME'),
      other: i18n.get('STATICS_REPORT_POST_REASONS_OTHER')
    },

    banReasons: {
      violent: i18n.get('STATICS_BAN_REASONS_VIOLENT'),
      offensive: i18n.get('STATICS_BAN_REASONS_OFFENSIVE'),
      political: i18n.get('STATICS_BAN_REASONS_POLITICAL'),
      illegal: i18n.get('STATICS_BAN_REASONS_ILLEGAL'),
      misleading: i18n.get('STATICS_BAN_REASONS_MISLEADING'),
      other: i18n.get('STATICS_BAN_REASONS_OTHER')
    },
    dashboardStatsInfo: {
      moderationTimeAvg: {
        desc: i18n.get('STATICS_DASHBOARD_STATS_INFO_MODERATION_TIME_AVG_DESC'),
        helpInfo: i18n.get(
          'STATICS_DASHBOARD_STATS_INFO_MODERATION_TIME_AVG_HELP_INFO'
        ),
        warningMessage: i18n.get(
          'STATICS_DASHBOARD_STATS_INFO_MODERATION_TIME_AVG_HELP_WARNING'
        ),
        minValue: 24
      },
      moderationResultRatio: {
        desc: i18n.get(
          'STATICS_DASHBOARD_STATS_INFO_MODERATION_RESULT_RATIO_DESC'
        ),
        helpInfo: i18n.get(
          'STATICS_DASHBOARD_STATS_INFO_MODERATION_RESULT_RATIO_HELP_INFO'
        ),
        warningMessage: i18n.get(
          'STATICS_DASHBOARD_STATS_INFO_MODERATION_RESULT_RATIO_HELP_WARNING'
        ),
        minValue: 75
      },
      invitesCancelledByInfluencer: {
        desc: i18n.get(
          'STATICS_DASHBOARD_STATS_INFO_INVITES_CANCELLED_RATIO_DESC'
        ),
        helpInfo: i18n.get(
          'STATICS_DASHBOARD_STATS_INFO_INVITES_CANCELLED_RATIO_HELP_INFO'
        ),
        extraLink: {
          url: i18n.get('STATICS_DASHBOARD_STATS_INFO_INVITES_CANCELLED_URL'),
          text: i18n.get(
            'STATICS_DASHBOARD_STATS_INFO_INVITES_CANCELLED_RATIO_HELP_MORE_INFO'
          )
        },
        warningMessage: i18n.get(
          'STATICS_DASHBOARD_STATS_INFO_INVITES_CANCELLED_RATIO_HELP_WARNING'
        ),
        minValue: 5
      }
    },

    advertiserPermissions: {
      layouts: {
        admin: {
          name: i18n.get('STATICS_ADVERTISER_PERMISSIONS_LAYOUTS_ADMIN'),
          permissions: {
            manageUsers: true,
            editInfo: true,
            manageCampaigns: true,
            operateCampaigns: true,
            readCampaignCosts: true
          }
        },
        campaignsEditor: {
          name: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_LAYOUTS_CAMPAIGNS_EDITOR'
          ),
          permissions: {
            manageCampaigns: true,
            operateCampaigns: true,
            readCampaignCosts: true,
            manageUsers: false,
            editInfo: false
          }
        },
        campaignOperator: {
          name: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_LAYOUTS_CAMPAIGN_OPERATOR'
          ),
          permissions: {
            operateCampaigns: true,
            readCampaignCosts: true,
            manageCampaigns: false,
            manageUsers: false,
            editInfo: false
          }
        },
        analist: {
          name: i18n.get('STATICS_ADVERTISER_PERMISSIONS_LAYOUTS_ANALIST'),
          permissions: {
            readCampaignCosts: true,
            operateCampaigns: false,
            manageCampaigns: false,
            manageUsers: false,
            editInfo: false
          }
        }
      },
      permissions: {
        manageUsers: {
          name: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_PERMISSIONS_MANAGE_USERS_NAME'
          ),
          description: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_PERMISSIONS_MANAGE_USERS_DESC'
          )
        },
        editInfo: {
          name: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_PERMISSIONS_EDIT_INFO_NAME'
          ),
          description: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_PERMISSIONS_EDIT_INFO_DESC'
          )
        },
        manageCampaigns: {
          name: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_PERMISSIONS_MANAGE_CAMPAIGNS_NAME'
          ),
          description: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_PERMISSIONS_MANAGE_CAMPAIGNS_DESC'
          )
        },
        operateCampaigns: {
          name: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_PERMISSIONS_OPERATE_CAMPAIGNS_NAME'
          ),
          description: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_PERMISSIONS_OPERATE_CAMPAIGNS_DESC'
          )
        },
        readCampaignCosts: {
          name: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_PERMISSIONS_READ_CAMPAIGN_COST_NAME'
          ),
          description: i18n.get(
            'STATICS_ADVERTISER_PERMISSIONS_PERMISSIONS_READ_CAMPAIGN_COST_DESC'
          )
        }
      }
    },
    campaignObjectives: {
      adquisition: {
        label: i18n.get('STATICS_CAMPAIGN_OBJECTIVES_ADQUISITION'),
        value: 'adquisition'
      },
      conversion: {
        label: i18n.get('STATICS_CAMPAIGN_OBJECTIVES_CONVERSION'),
        value: 'conversion'
      },
      brandAwareness: {
        label: i18n.get('STATICS_CAMPAIGN_OBJECTIVES_BRAND_AWARENESS'),
        value: 'brand_awareness'
      },
      positioning: {
        label: i18n.get('STATICS_CAMPAIGN_OBJECTIVES_POSITIONING'),
        value: 'positioning'
      },
      trading: {
        label: i18n.get('STATICS_CAMPAIGN_OBJECTIVES_TRADING'),
        value: 'trading'
      }
    },
    campaignPromote: {
      song: {
        label: i18n.get('STATICS_CAMPAIGN_PROMOTE_SONG'),
        value: 'song'
      },
      concertOrTour: {
        label: i18n.get('STATICS_CAMPAIGN_PROMOTE_CONCERT_OR_TOUR'),
        value: 'concertOrTour'
      },
      album: {
        label: i18n.get('STATICS_CAMPAIGN_PROMOTE_ALBUM'),
        value: 'album'
      },
      merch: {
        label: i18n.get('STATICS_CAMPAIGN_PROMOTE_MERCH'),
        value: 'merch'
      }
    },
    bankInputVariation: {
      SimpleLatam: 'simple_latam',
      SimpleAsia: 'simple_asia'
    },
    industries: [
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_ADVERTISING')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_AGRICULTURE')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_ARCHITECTURE')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_AUTOMOTIVE')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_COMMUNICATIONS')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_CONSUMER_GOODS')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_EDUCATION')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_ENERGY')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_ENTERTAINMENT')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_FASHION')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_FINANCE')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_FOOD')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_GAMBLING')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_GOVERNMENT')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_HEALTH')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_HOSPITALITY')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_IT')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_MANUFACTURING')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_NOT_FOR_PROFIT')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_PUBLISHING')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_REAL_STATE')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_RELIGION')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_RETAIL')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_TRANSPORT')
      },
      {
        label: i18n.get('STATICS_SETTINGS_ACCOUNT_INDUSTRY_OTHER')
      }
    ]
  };

  STATICS_BY_LOCALE[currentLocale] = statics;
  return statics;
}

export default buildStatics;
