import { buildAPIRequest } from '../helpers';
import { publications } from '../../../services/voxfeed';
import { updateData } from '../entities';
import { pick } from 'lodash';
import { sendRequest, sendRequestSuccess } from '../app';
import { fetchSocialAccounts } from './../socialAccounts';

const { getById } = publications;

const SEND_GET_PUBLICATIONS_BY_ID_REQUEST =
  'voxfeed/users/SEND_GET_PUBLICATION_BY_ID';
const SEND_GET_PUBLICATIONS_BY_ID_SUCCESS =
  'voxfeed/users/SUCCESS_GET_PUBLICATION_BY_ID';
const SEND_GET_PUBLICATIONS_BY_ID_FAIL =
  'voxfeed/users/FAIL_GET_PUBLICATION_BY_ID';

const initialState = {
  loading: false,
  data: null,
  error: false
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case SEND_GET_PUBLICATIONS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SEND_GET_PUBLICATIONS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    case SEND_GET_PUBLICATIONS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: payload
      };
    default:
      return state;
  }
}

export function getPublicationById(id) {
  return dispatch => {
    dispatch({ type: SEND_GET_PUBLICATIONS_BY_ID_REQUEST });
    getById({ id })
      .then(response => {
        const { data } = response.data;
        const { socialNetworkAccount } = data;
        dispatch({ type: SEND_GET_PUBLICATIONS_BY_ID_SUCCESS, payload: data });
        const params = { _id: socialNetworkAccount };
        dispatch(fetchSocialAccounts(params, false));
      })
      .catch(params => {
        dispatch({ type: SEND_GET_PUBLICATIONS_BY_ID_FAIL });
      });
  };
}

export const setVerification = params =>
  buildAPIRequest({
    params,
    id: `setVerification_${params.id}`,
    method: publications.setVerification
  });

export const restoreCancelled = params =>
  buildAPIRequest({
    params,
    id: `restore_cancelled_${params.id}`,
    method: publications.restoreCancelled
  });
export const restoreDeleted = params =>
  buildAPIRequest({
    params,
    id: `restore_deleted_${params.id}`,
    method: publications.restoreDelete
  });

export const attachPublication = params =>
  buildAPIRequest({
    params,
    id: `attachPublication_${params.id}`,
    method: publications.attachPublication
  });

export const updateStatsManually = params =>
  buildAPIRequest({
    params,
    id: `updateStatsManually_${params.id}`,
    method: publications.updateStatsManually,
    callback: ({ dispatch, data }) => {
      const pmId = data.data.id.replace('promoted-message-', '');
      dispatch(
        updateData({
          modelName: 'PromotedMessage',
          id: pmId,
          data: {
            campaignStats: pick(data.data, [
              'impressionsUnique',
              'impressions',
              'shares',
              'comments',
              'urlClicks'
            ])
          }
        })
      );
    }
  });

export const extractStats = params =>
  buildAPIRequest({
    params,
    id: 'extractStats',
    method: publications.extractStats,
    callback: ({ dispatch, data }) => {
      dispatch(
        updateData({
          modelName: 'PromotedMessage',
          id: params.id,
          data: { predictedStats: data }
        })
      );
    }
  });

export const scheduleMessage = params =>
  buildAPIRequest({
    params,
    id: 'scheduleMessage',
    method: publications.scheduleMessage
  });

export const getUnverifiedStats = (params, replace) =>
  buildAPIRequest({
    params,
    replace,
    id: 'getUnverifiedStats',
    method: publications.getUnverifiedStats,
    isCancellable: true
  });

export const verifyStats = params =>
  buildAPIRequest({
    params,
    id: 'verifyStats',
    method: publications.verifyStats
  });

export const markAsDeleted = params =>
  buildAPIRequest({
    params,
    id: 'markAsDeleted',
    method: publications.markAsDeleted,
    callback({ dispatch }) {
      dispatch(
        updateData({
          modelName: 'Publication',
          id: params.publicationId,
          data: {
            deletedAt: new Date()
          }
        })
      );
    }
  });

export const getInstagramMetrics = params =>
  buildAPIRequest({
    params,
    id: `publications_${params.id}`,
    method: publications.getInstagramMetrics
  });
