import axios from 'axios';
import qs from 'qs';
import { reduce } from 'lodash';

export function setAuthHeader(token) {
  axios.defaults.headers.common.auth = token;
}

export function setAPIHost(host) {
  axios.defaults.baseURL = host;
}

export function setIdentityHeader(id) {
  axios.defaults.headers.common.Identity = id;
}

export function unsetIdentityHeader() {
  delete axios.defaults.headers.common.Identity;
}

export function setSocketId(socketId) {
  axios.defaults.headers.common['x-socket-id'] = socketId;
}

// TODO: for some reason, this function gets called with invalid input (investigate who's causing this...)
export function setLocaleHeader(locale) {
  if (locale) axios.defaults.headers.common.lang = locale;
}

export function setParamsSerializer() {
  axios.defaults.paramsSerializer = params => {
    const nparams = reduce(
      params,
      (newParams, value, key) => ({
        ...newParams,
        [key]: Array.isArray(value) ? value.join(',') : value
      }),
      {}
    );

    return qs.stringify(nparams);
  };
}
