"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const styles = require("./RoleSteps.less");
const steps_1 = require("./steps");
const images_1 = require("assets/images");
const types_1 = require("modules/shared/types");
const RoleSteps = ({ role, vertical }) => {
    const steps = React.useMemo(() => (role === types_1.UserRole.INFLUENCER ? steps_1.stepsInf : steps_1.stepsAdv), [role]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classnames_1.default({
                [styles.vertical]: vertical,
                [styles.container]: !vertical,
                [styles.hidden]: role === types_1.UserRole.ADVERTISER && !vertical
            }) },
            steps.map((item, index) => (React.createElement("div", { key: item.id, className: styles.content },
                React.createElement("div", { className: styles.item },
                    React.createElement("img", { src: item.icon, className: styles.icon, alt: i18n.get(item.text) }),
                    React.createElement("p", { className: styles.text }, i18n.get(item.text))),
                steps.length - 1 !== index && (React.createElement("img", { alt: 'arrow', src: images_1.iconArrowStep, className: styles.arrow }))))),
            role === types_1.UserRole.ADVERTISER && (React.createElement("div", { className: styles.links },
                React.createElement("a", { target: '_blank', rel: 'noreferrer', href: i18n.get('REGISTER_HOW_IT_WORKS_LINK') },
                    React.createElement(ui_library_1.Button, { type: 'button', color: 'primary', className: styles.size }, i18n.get('REGISTER_HOW_IT_WORKS'))),
                React.createElement("a", { target: '_blank', rel: 'noreferrer', className: styles.size, href: i18n.get('REGISTER_SCHEDULE_A_CALL_LINK') },
                    React.createElement(ui_library_1.Button, { outline: true, type: 'button', color: 'primary', className: styles.size }, i18n.get('REGISTER_SCHEDULE_A_CALL'))))))));
};
exports.default = RoleSteps;
