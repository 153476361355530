import get from 'lodash/get';
import { campaigns, accounts } from '../../../services/voxfeed';
import { buildAPIRequest } from '../helpers';

const FETCH_CAMPAIGN_ACTIVITY_STATS =
  'voxfeed/shared/voxfeed/campaigns/FETCH_CAMPAIGN_ACTIVITY_STATS';
const FETCH_ADVERTISER_ACTIVITY_STATS =
  'voxfeed/shared/voxfeed/campaigns/FETCH_ADVERTISER_ACTIVITY_STATS';
const GET_CAMPAIGN_EXPECTED_REACH =
  'voxfeed/shared/voxfeed/campaigns/GET_CAMPAIGN_EXPECTED_REACH';
const FETCH_INFLUENCER_DEMOGRAPHICS =
  'voxfeed/shared/voxfeed/campaigns/FETCH_INFLUENCER_DEMOGRAPHICS';
const FETCH_CAMPAIGN_REACHED_AUDIENCE =
  'voxfeed/shared/voxfeed/campaigns/FETCH_CAMPAIGN_REACHED_AUDIENCE';

const initialState = { campaigns: {}, advertisers: {} };

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_CAMPAIGN_ACTIVITY_STATS: {
      return {
        ...state,
        campaigns: {
          [payload.campaignId]: payload.data
        }
      };
    }

    case FETCH_ADVERTISER_ACTIVITY_STATS: {
      return {
        ...state,
        advertisers: {
          [payload.accountId]: payload.data
        }
      };
    }

    case GET_CAMPAIGN_EXPECTED_REACH: {
      const expectedReach = { expectedReach: payload.data };
      const campaignStat = {
        [payload.campaignId]: {
          ...get(state, `campaigns.${payload.campaignId}`),
          ...expectedReach
        }
      };

      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          ...campaignStat
        }
      };
    }

    case FETCH_INFLUENCER_DEMOGRAPHICS: {
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [payload.campaignId]: {
            ...get(state, `campaigns.${payload.campaignId}`),
            influencersDemographics: payload.data
          }
        }
      };
    }

    case FETCH_CAMPAIGN_REACHED_AUDIENCE: {
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [payload.campaignId]: {
            ...get(state, `campaigns.${payload.campaignId}`),
            reachedAudience: payload.data
          }
        }
      };
    }

    default:
      return state;
  }
}

export const getCampaignActivityStatistics = campaignId =>
  buildAPIRequest({
    id: 'getCampaignActivityStatistics',
    method: campaigns.getActivityStatisticsByCampaignId,
    params: campaignId,
    callback: ({ dispatch, data }) => {
      dispatch({
        type: FETCH_CAMPAIGN_ACTIVITY_STATS,
        payload: { campaignId, data }
      });
    }
  });

export const getCampaignInfluencerDemographics = campaignId =>
  buildAPIRequest({
    id: 'getInfluencerDemographics',
    method: campaigns.getInfluencerDemographics,
    params: campaignId,
    callback: ({ dispatch, data }) => {
      dispatch({
        type: FETCH_INFLUENCER_DEMOGRAPHICS,
        payload: { campaignId, data }
      });
    }
  });

export const getCampaignExpectedReach = (campaign, countries) =>
  buildAPIRequest({
    id: 'getExpectedReach',
    method: campaigns.calculateExpectedReach,
    params: { campaign, countries },
    callback: ({ dispatch, data }) => {
      dispatch({
        type: GET_CAMPAIGN_EXPECTED_REACH,
        payload: { data, campaignId: campaign.id }
      });
    }
  });

export const getAdvertiserActivityStatistics = accountId =>
  buildAPIRequest({
    id: 'getInfluencerDemographics',
    params: accountId,
    method: accounts.getActivityStatisticsByAdvertiserId,
    callback: ({ dispatch, data }) => {
      dispatch({
        type: FETCH_ADVERTISER_ACTIVITY_STATS,
        payload: { accountId, data }
      });
    }
  });

export const fetchCampaignReachedAudience = campaignId =>
  buildAPIRequest({
    id: `fetchCampaignReachedAudience_${campaignId}`,
    method: campaigns.fetchCampaignReachedAudience,
    params: campaignId,
    callback: ({ dispatch, data }) => {
      dispatch({
        type: FETCH_CAMPAIGN_REACHED_AUDIENCE,
        payload: { campaignId, data }
      });
    }
  });

export const listActiveCampaigns = params =>
  buildAPIRequest({
    params,
    id: 'listActiveCampaigns',
    method: campaigns.fetchListActiveCampaigns
  });

export const listActiveCampaignsCsv = params =>
  buildAPIRequest({
    params,
    id: 'listActiveCampaigns',
    method: campaigns.fetchListActiveCampaignsCsv
  });

export const listInactiveCampaigns = params =>
  buildAPIRequest({
    params,
    id: 'listInactiveCampaigns',
    method: campaigns.fetchListInactiveCampaigns
  });

export const listInactiveCampaignsCsv = params =>
  buildAPIRequest({
    params,
    id: 'listInactiveCampaigns',
    method: campaigns.fetchListInactiveCampaignsCsv
  });

export const listPublication = params =>
  buildAPIRequest({
    params,
    id: 'listPublication',
    method: campaigns.fetchListPublication
  });

export const listPublicationCsv = params =>
  buildAPIRequest({
    params,
    id: 'listPublication',
    method: campaigns.fetchListPublicationCsv
  });

export const listActiveBrands = params =>
  buildAPIRequest({
    params,
    id: 'listActiveBrands',
    method: campaigns.fetchListActiveBrands
  });

export const listActiveBrandsCsv = params =>
  buildAPIRequest({
    params,
    id: 'listActiveBrands',
    method: campaigns.fetchListActiveBrandsCsv
  });

export const listInactiveBrands = params =>
  buildAPIRequest({
    params,
    id: 'listInactiveBrands',
    method: campaigns.fetchListInactiveBrands
  });

export const listInactiveBrandsCsv = params =>
  buildAPIRequest({
    params,
    id: 'listInactiveBrands',
    method: campaigns.fetchListInactiveBrandsCsv
  });

export const listUsers = params =>
  buildAPIRequest({
    params,
    id: 'listUsers',
    method: campaigns.fetchListUsers
  });

export const listUsersCsv = params =>
  buildAPIRequest({
    params,
    id: 'listUsers',
    method: campaigns.fetchListUsersCsv
  });

export const listNewUsers = params =>
  buildAPIRequest({
    params,
    id: 'listNewUsers',
    method: campaigns.fetchListNewUsers
  });

export const listNewUsersCsv = params =>
  buildAPIRequest({
    params,
    id: 'listNewUsers',
    method: campaigns.fetchListNewUsersCsv
  });

export const listSocialNetworks = params =>
  buildAPIRequest({
    params,
    id: 'listSocialNetworks',
    method: campaigns.fetchListSocialNetworks
  });

export const listSocialNetworksCsv = params =>
  buildAPIRequest({
    params,
    id: 'listSocialNetworks',
    method: campaigns.fetchListSocialNetworksCsv
  });

export const listCosts = params =>
  buildAPIRequest({
    params,
    id: 'listCosts',
    method: campaigns.fetchListCosts
  });

export const listCostsCsv = params =>
  buildAPIRequest({
    params,
    id: 'listCosts',
    method: campaigns.fetchListCostsCsv
  });

export const listOrganizations = params =>
  buildAPIRequest({
    params,
    id: 'listCosts',
    method: campaigns.fetchListOrganizations
  });

export const listAdvertizerActiveCampaigns = params =>
  buildAPIRequest({
    params,
    id: 'listActiveCampaigns',
    method: campaigns.fetchListAdvertizerActiveCampaigns
  });

export const listAdvertizerActiveCampaignsCsv = params =>
  buildAPIRequest({
    params,
    id: 'listActiveCampaigns',
    method: campaigns.fetchListAdvertizerActiveCampaignsCsv
  });

export const listAdvertizerInactiveCampaigns = params =>
  buildAPIRequest({
    params,
    id: 'listInactiveCampaigns',
    method: campaigns.fetchListAdvertizerInactiveCampaigns
  });

export const listAdvertizerInactiveCampaignsCsv = params =>
  buildAPIRequest({
    params,
    id: 'listInactiveCampaigns',
    method: campaigns.fetchListAdvertizerInactiveCampaignsCsv
  });

export const listAdvertizerPublication = params =>
  buildAPIRequest({
    params,
    id: 'listPublication',
    method: campaigns.fetchListAdvertizerPublication
  });

export const listAdvertizerPublicationCsv = params =>
  buildAPIRequest({
    params,
    id: 'listPublication',
    method: campaigns.fetchListAdvertizerPublicationCsv
  });

export const listAdvertizerActiveBrands = params =>
  buildAPIRequest({
    params,
    id: 'listActiveBrands',
    method: campaigns.fetchListAdvertizerActiveBrands
  });

export const listAdvertizerActiveBrandsCsv = params =>
  buildAPIRequest({
    params,
    id: 'listActiveBrands',
    method: campaigns.fetchListAdvertizerActiveBrandsCsv
  });

export const listAdvertizerInactiveBrands = params =>
  buildAPIRequest({
    params,
    id: 'listInactiveBrands',
    method: campaigns.fetchListAdvertizerInactiveBrands
  });

export const listAdvertizerInactiveBrandsCsv = params =>
  buildAPIRequest({
    params,
    id: 'listInactiveBrands',
    method: campaigns.fetchListAdvertizerInactiveBrandsCsv
  });

export const listAdvertizerUsers = params =>
  buildAPIRequest({
    params,
    id: 'listUsers',
    method: campaigns.fetchListAdvertizerUsers
  });

export const listAdvertizerUsersCsv = params =>
  buildAPIRequest({
    params,
    id: 'listUsers',
    method: campaigns.fetchListAdvertizerUsersCsv
  });

export const listAdvertizerSocialNetworks = params =>
  buildAPIRequest({
    params,
    id: 'listSocialNetworks',
    method: campaigns.fetchListAdvertizerSocialNetworks
  });

export const listAdvertizerSocialNetworksCsv = params =>
  buildAPIRequest({
    params,
    id: 'listSocialNetworks',
    method: campaigns.fetchListAdvertizerSocialNetworksCsv
  });

export const listAdvertizerCosts = params =>
  buildAPIRequest({
    params,
    id: 'listCosts',
    method: campaigns.fetchListAdvertizerCosts
  });

export const listAdvertizerCostsCsv = params =>
  buildAPIRequest({
    params,
    id: 'listCosts',
    method: campaigns.fetchListAdvertizerCostsCsv
  });

export const constants = {
  FETCH_CAMPAIGN_ACTIVITY_STATS,
  FETCH_ADVERTISER_ACTIVITY_STATS,
  GET_CAMPAIGN_EXPECTED_REACH,
  FETCH_INFLUENCER_DEMOGRAPHICS,
  FETCH_CAMPAIGN_REACHED_AUDIENCE
};
