"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const ui_library_1 = require("ui-library");
const MIN_LENTH_FOR_PHONE_NUMBER = 8;
const MAX_LENGTH_FOR_PHONE_NUMBER = 10;
const VERIFICATION_CODE_LENGTH = 6;
const DIGIT_REGEX = /^[0-9]*$/;
const CellPhoneVerification = props => {
    const { prefix = '', number = '', code = '' } = props;
    const hasJustDigits = (text) => {
        return DIGIT_REGEX.test(text);
    };
    const onChangeNumber = (event) => {
        const number = event.target.value;
        if (!isValidPhone(number))
            return event.preventDefault();
        const { onChangeNumber } = props;
        onChangeNumber && onChangeNumber(number);
    };
    const isValidPhone = (phone) => {
        return (hasJustDigits(phone) && phone.length <= MAX_LENGTH_FOR_PHONE_NUMBER);
    };
    const onChangeCode = (event) => {
        const code = event.target.value;
        if (!isValidVerificationCode(code))
            return event.preventDefault();
        const { onChangeCode } = props;
        onChangeCode && onChangeCode(code);
    };
    const isValidVerificationCode = (code) => {
        return hasJustDigits(code) && code.length <= VERIFICATION_CODE_LENGTH;
    };
    const submitPhone = () => {
        const { onSubmitPhone } = props;
        onSubmitPhone && onSubmitPhone();
    };
    const submitCode = () => {
        const { onSubmitCode } = props;
        onSubmitCode && onSubmitCode();
    };
    const getPhone = (prefix, number) => {
        return `+${prefix}-${number}`;
    };
    const isPhoneLengthValid = () => {
        return (number.length >= MIN_LENTH_FOR_PHONE_NUMBER &&
            number.length <= MAX_LENGTH_FOR_PHONE_NUMBER);
    };
    const renderInputPhoneView = () => {
        const { error, disabled, isVerified } = props;
        const description = i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_INPUT_DESCRIPTION');
        const prefixStr = prefix ? `+${prefix}` : '...';
        const getCodeBtn = (React.createElement(ui_library_1.Button, { size: 'sm', color: 'primary', className: 'get-code', onClick: submitPhone, disabled: !isPhoneLengthValid() }, i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_GET_CODE')));
        const verifiedStr = (React.createElement("div", { className: 'vf-text-primary', style: { marginLeft: '8.5rem' } },
            React.createElement("i", { className: 'icon-checkmark vf-primary-text margin-right-Hx' }),
            i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_VERIFIED')));
        return (React.createElement("div", null,
            React.createElement("div", { className: 'vf-row phone-number' },
                React.createElement("div", { className: 'col-xs-12 vf-descriptor' }, description)),
            React.createElement("div", { className: 'vf-row padding-top-Hx' },
                React.createElement("div", { className: 'col-xs-12 flex' },
                    React.createElement("div", { className: 'prefix-input flex' }, prefixStr),
                    React.createElement(ui_library_1.Input, { id: 'cellphone-input', className: error ? 'error' : '', onChange: onChangeNumber, placeholder: i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_INPUT_PLACEHOLDER'), disabled: disabled, value: number }))),
            React.createElement("div", { className: 'vf-row padding-top-Hx' },
                React.createElement("div", { className: 'col-xs-12' }, isVerified ? verifiedStr : getCodeBtn))));
    };
    const isCodeLengthValid = () => {
        return code.length === VERIFICATION_CODE_LENGTH;
    };
    const renderInputCodeView = () => {
        const { disabled, error, onEditNumber } = props;
        const description = (React.createElement("div", null,
            React.createElement("div", null,
                i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_TYPE_VERIFICATION_CODE_WAIT'),
                React.createElement("a", { onClick: submitPhone }, i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_REQUEST_NEW_CODE'))),
            React.createElement("div", { className: 'margin-top-1x' },
                React.createElement("span", { className: 'margin-right-Hx' }, i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_YOUR_PHONE', {
                    phone: getPhone(prefix, number)
                })),
                React.createElement("a", { onClick: onEditNumber }, i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_EDIT')))));
        const sendCodeBtn = (React.createElement(ui_library_1.Button, { size: 'sm', color: 'primary', id: 'send-verification-code-button', onClick: submitCode, disabled: !isCodeLengthValid() }, i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_SEND_CODE')));
        return (React.createElement("div", null,
            React.createElement("div", { className: 'vf-row' },
                React.createElement("div", { className: 'col-xs-12 vf-descriptor' }, description)),
            React.createElement("div", { className: 'vf-row padding-top-Hx' },
                React.createElement("div", { className: 'col-xs-12 flex' },
                    React.createElement(ui_library_1.Input, { id: 'verification_code', className: error ? 'error' : '', onChange: onChangeCode, placeholder: i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_TYPE_VERIFICATION_CODE'), disabled: disabled, value: code }))),
            React.createElement("div", { className: 'vf-row padding-top-Hx' },
                React.createElement("div", { className: 'col-xs-12' }, sendCodeBtn))));
    };
    const renderContent = () => {
        const { display } = props;
        if (display === 'code')
            return renderInputCodeView();
        return renderInputPhoneView();
    };
    return (React.createElement("div", { className: 'vf-cell-phone-verification' }, renderContent()));
};
exports.default = CellPhoneVerification;
