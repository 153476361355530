import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NavigationContainer from 'modules/legacy/js/modules/navigation-container';
import Menubar from 'modules/accounts/influencer/containers/NavigationBar';

class NonAdvertiserLayout extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    configStore: PropTypes.object.isRequired,
    dataStore: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    removeAllEntities: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired,
    currentLanguage: PropTypes.string.isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  nav = null;

  handleToggle = () => {
    const navBar = document.getElementById('mobile-nav-bar');
    navBar.classList.toggle('-open');
  };

  render() {
    const {
      currentAccount,
      currentUser,
      configStore,
      dataStore,
      logout,
      removeAllEntities,
      location,
      params,
      children,
      currentLanguage,
      className
    } = this.props;

    return (
      <div className={className}>
        <Menubar
          currentAccount={currentAccount}
          currentUser={currentUser}
          configStore={configStore}
          dataStore={dataStore}
          onLogout={() => {
            logout();
            removeAllEntities();
          }}
        />

        <div className='vf-main flex-1 flex-column flex-stretch'>
          <NavigationContainer
            onClickLeftButton={this.handleToggle}
            location={location}
            params={params}
            currentLanguage={currentLanguage}
          />
          {children}
        </div>
      </div>
    );
  }
}

export default NonAdvertiserLayout;
