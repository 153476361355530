"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const PostCardWhiteBox = ({ title, children, onRequestClose }) => (React.createElement("div", { className: 'vf-bg-white-light-color padding-horizontal-2x padding-vertical-1x width100', style: {
        marginTop: '2rem',
        borderRadius: '3px'
    } },
    React.createElement("h4", { className: 'flex vf-font-bold' },
        React.createElement("span", { className: 'margin-right-1x', style: { height: '2.6rem' } },
            React.createElement("i", { className: 'vf-icon icon-file-text' })),
        title,
        React.createElement("button", { className: 'vf-clear-button', style: { height: '2.2rem', marginLeft: 'auto', paddingLeft: '2rem' }, onClick: onRequestClose },
            React.createElement("i", { className: 'vf-icon icon-close' }))),
    children));
exports.default = PostCardWhiteBox;
