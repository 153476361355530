"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const sharedActions = require("redux/modules/shared");
const userActions = require("redux/modules/users");
const PartnerRegisterUI_1 = require("./PartnerRegisterUI/PartnerRegisterUI");
const emailValidator_1 = require("apps/shared/hocs/emailValidator");
const passwordValidator_1 = require("apps/shared/hocs/passwordValidator");
const errorStateHandler_1 = require("apps/shared/hocs/errorStateHandler");
const lodash_1 = require("lodash");
const selectors_1 = require("modules/shared/selectors");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
class PartnerRegister extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            loading: false,
            isTokenValid: true
        };
        this.onGetRegistrationInviteFailed = () => {
            this.setState({ isTokenValid: false });
        };
        this.validateNames = (value) => {
            return value.trim().length > 0;
        };
        this.validateForm = form => {
            const { validatePassword, validateEmail } = this.props;
            const isEmailValid = validateEmail(form.email);
            const isPasswordValid = validatePassword(form.password);
            const isFirstNameValid = this.validateNames(lodash_1.get(form, 'name', ''));
            return {
                email: isEmailValid ? null : i18n.get('LOGIN_LOGIN_ERROR_INVALID_EMAIL'),
                password: isPasswordValid
                    ? null
                    : i18n.get('REGISTER_INVALID_PASSWORD_LENGTH'),
                name: isFirstNameValid
                    ? null
                    : i18n.get('REGISTER_FIRST_NAME_IS_REQUIRED'),
                lastName: null
            };
        };
        this.handleInputBlur = (name, value) => {
            const { handleInputValidation } = this.props;
            const params = {
                name,
                value,
                validationFunction: this.validateForm
            };
            handleInputValidation(params);
        };
        this.handleSubmit = (form) => {
            const { currentLanguage, match, onSetErrors } = this.props;
            const errors = this.validateForm(form);
            const isValid = lodash_1.every(errors, val => val === null);
            if (!isValid)
                return onSetErrors(errors);
            const token = lodash_1.get(match, 'params.token');
            if (token)
                return this.handleAcceptInvite(Object.assign(Object.assign({}, form), { role: 'advertiser', token }));
            this.handleRegister(Object.assign(Object.assign({}, form), { role: 'advertiser', local: currentLanguage }));
        };
        this.handleRegister = form => {
            const { registrationRequest } = this.props;
            registrationRequest(form);
        };
        this.handleAcceptInvite = form => {
            const { acceptRegistrationInvite } = this.props;
            acceptRegistrationInvite(form);
        };
    }
    UNSAFE_() {
        const { match, getRegistrationInviteByToken } = this.props;
        if (lodash_1.isEmpty(match.params))
            return;
        this.setState({ loading: true });
        getRegistrationInviteByToken(match.params)
            .catch(this.onGetRegistrationInviteFailed)
            .finally(() => this.setState({ loading: false }));
    }
    render() {
        const { loading, isTokenValid } = this.state;
        const { errors, registrationInvite } = this.props;
        const defaultEmail = lodash_1.get(registrationInvite, 'email');
        return (React.createElement(PartnerRegisterUI_1.default, { isTokenValid: isTokenValid, defaultEmail: defaultEmail, loading: loading, errors: errors, onInputBlur: this.handleInputBlur, onSubmit: this.handleSubmit }));
    }
}
const mapStateToProps = (state, { match }) => {
    const token = lodash_1.get(match, 'params.token');
    const registrationInvite = lodash_1.find(lodash_1.get(state, 'entities.RegistrationInvite'), reIn => reIn.token === token);
    return {
        currentLanguage: selectors_1.getAppInfo(state, 'currentLanguage'),
        registrationInvite
    };
};
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    registrationRequest: sharedActions.registrationRequest,
    acceptRegistrationInvite: sharedActions.acceptRegistrationInvite,
    getRegistrationInviteByToken: userActions.getRegistrationInviteByToken
}, dispatch);
const WithErrors = errorStateHandler_1.default(PartnerRegister);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(passwordValidator_1.default(emailValidator_1.default(WithErrors)));
