"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const get = require("lodash/get");
const react_chartjs_2_1 = require("react-chartjs-2");
const i18n = require("i18n");
const images_1 = require("assets/images");
const CHART_HEIGHT = 200;
const MALE_COLOR = '#004f63';
const FEMALE_COLOR = '#96ecf3';
const CHART_OPTIONS = {
    maintainAspectRatio: false,
    legend: { display: false },
    cutoutPercentage: 75,
    tooltips: { enabled: false }
};
class GenderDistribution extends React.PureComponent {
    render() {
        const { stats, style } = this.props;
        const maleValue = getValueByGender(stats, 'male');
        const femaleValue = getValueByGender(stats, 'female');
        const malePercentualValue = Math.floor(maleValue * 100);
        const femalePercentualValue = Math.floor(femaleValue * 100);
        return (React.createElement("div", { className: 'col', style: style },
            React.createElement("h5", null, i18n.get('ADV_INFLUENCER_PROFILE_GENDER')),
            React.createElement("div", { style: { height: CHART_HEIGHT }, className: 'vf-row no-margin center-xs' },
                React.createElement("div", { className: 'col-xs-2 no-padding flex bottom-xs height100' },
                    React.createElement("div", { className: 'flex middle-xs' },
                        React.createElement("img", { role: 'presentation', src: images_1.genderFemaleBlueImg, className: 'margin-right-Hx', style: { fill: FEMALE_COLOR } }),
                        React.createElement("span", { className: 'vf-font-bold' },
                            femalePercentualValue,
                            "%"))),
                React.createElement("div", { className: 'col-xs-7 flex center-xs height100' },
                    React.createElement(react_chartjs_2_1.Doughnut, { options: CHART_OPTIONS, data: {
                            datasets: [
                                {
                                    data: [maleValue, femaleValue],
                                    hoverBorderColor: ['white', 'white'],
                                    backgroundColor: [MALE_COLOR, FEMALE_COLOR]
                                }
                            ]
                        } })),
                React.createElement("div", { className: 'col-xs-2 no-padding flex top-xs height100' },
                    React.createElement("div", { className: 'flex middle-xs' },
                        React.createElement("img", { role: 'presentation', src: images_1.genderMaleBlueImg, className: 'margin-right-Hx', style: { fill: MALE_COLOR } }),
                        React.createElement("span", { className: 'vf-font-bold' },
                            malePercentualValue,
                            "%"))))));
    }
}
GenderDistribution.defaultProps = {
    style: {},
    stats: {
        genders: []
    }
};
function getValueByGender(stats, gender) {
    const genderStat = stats.genders.find(g => g.id === gender);
    return get(genderStat, 'value', 0);
}
exports.default = GenderDistribution;
