"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const react_router_dom_1 = require("react-router-dom");
const emailValidator_1 = require("apps/shared/hocs/emailValidator");
const passwordValidator_1 = require("apps/shared/hocs/passwordValidator");
const errorStateHandler_1 = require("apps/shared/hocs/errorStateHandler");
const types_1 = require("modules/shared/types");
const i18n = require("i18n");
const appActions = require("redux/modules/app");
const userActions = require("redux/modules/shared");
const selectors_1 = require("modules/shared/selectors");
const InfluencerLoginUI_1 = require("./InfluencerLoginUI/InfluencerLoginUI");
class InfluencerLogin extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { isLogin: false };
        this.validateForm = form => {
            const { isLogin } = this.state;
            const { validateEmail, validatePassword } = this.props;
            const isEmailValid = validateEmail(form.email);
            const isPasswordValid = validatePassword(form.password) || isLogin;
            return {
                email: isEmailValid ? null : i18n.get('LOGIN_LOGIN_ERROR_INVALID_EMAIL'),
                password: isPasswordValid
                    ? null
                    : i18n.get('REGISTER_INVALID_PASSWORD_LENGTH'),
                confirmPassword: (isPasswordValid && form.confirmPassword) || isLogin
                    ? null
                    : i18n.get('REGISTER_PASSWORD_DO_NOT_MATCH')
            };
        };
        this.handleToggleLogin = () => {
            const { onSetErrors } = this.props;
            onSetErrors({});
            this.setState({ isLogin: !this.state.isLogin });
        };
        this.handleInputChange = (name, value) => {
            const { handleInputValidation } = this.props;
            const params = { name, value, validationFunction: this.validateForm };
            handleInputValidation(params);
        };
        this.handleSubmit = (form) => {
            const { isLogin } = this.state;
            const { onSetErrors } = this.props;
            const errors = this.validateForm(form);
            const isValid = lodash_1.every(errors, val => val === null);
            if (!isValid)
                return onSetErrors(errors);
            delete form.confirmPassword;
            const action = isLogin ? this.handleLogin : this.handleRegister;
            action(form).then(this.onSuccess).catch(this.onFailed);
        };
        this.handleLogin = (form) => {
            const { login } = this.props;
            return login(Object.assign(Object.assign({}, form), { noRedirect: true, openLoader: false }));
        };
        this.handleRegister = (form) => {
            const { registration } = this.props;
            const locale = i18n.getLocale();
            return registration(Object.assign(Object.assign({}, form), { locale, role: 'influencer' }));
        };
        this.onFailed = () => {
            const { showAlert, session, onInvalidAccount } = this.props;
            const showModalInvalidAccout = lodash_1.get(session, 'error.code') !== types_1.ErrorType.INVALID_CREDENTIAL;
            if (showModalInvalidAccout) {
                onInvalidAccount();
            }
            else {
                showAlert({ type: 'error', message: lodash_1.get(session, 'error.message', '') });
            }
        };
        this.onSuccess = () => {
            const { history, currentUser } = this.props;
            if (currentUser.role === 'advertiser') {
                history.push(`/${currentUser.role}`);
            }
            this.onFailed();
        };
    }
    render() {
        const { isLogin } = this.state;
        const { brand, errors, campaignLite, campaignLiteId, isCampaignSoldOut } = this.props;
        return (React.createElement(InfluencerLoginUI_1.default, { brand: brand, errors: errors, isLogin: isLogin, campaignLite: campaignLite, onSubmit: this.handleSubmit, campaignLiteId: campaignLiteId, isCampaignSoldOut: isCampaignSoldOut, onToggleLogin: this.handleToggleLogin, onInputChange: this.handleInputChange }));
    }
}
const Enhanced = emailValidator_1.default(passwordValidator_1.default(errorStateHandler_1.default(InfluencerLogin)));
const mapStateToProps = state => ({
    session: lodash_1.get(state, 'shared.session'),
    currentUser: selectors_1.getCurrentUser(state)
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    login: userActions.loginRequest,
    registration: userActions.registrationRequest,
    showAlert: appActions.showAlert
}, dispatch);
const Connected = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Enhanced);
exports.default = react_router_dom_1.withRouter(Connected);
