"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function initialLocale(day, month) {
    return {
        localize: {
            day: (n) => day[n],
            month: (n) => month[n]
        },
        formatLong: {
            date: () => 'mm/dd/yyyy'
        }
    };
}
exports.default = initialLocale;
