"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocialBadgeXL = exports.SocialBadgeLG = exports.SocialBadgeMD = exports.SocialBadgeSM = void 0;
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./SocialBadge.less");
const lodash_1 = require("lodash");
const app_1 = require("config/app");
const images_1 = require("assets/images");
class SocialBadge extends React.Component {
    render() {
        const { size, className, style, socialNetwork, publicationType } = this.props;
        if (!socialNetwork)
            return null;
        const config = app_1.getStatics().socialNetworks[socialNetwork];
        if (lodash_1.isNil(config) && socialNetwork !== 'twitter')
            return null;
        const mStyle = Object.assign({ display: 'inline-block', fontSize: size, width: Math.round(size * 1.6), height: Math.round(size * 1.6) }, style);
        return (React.createElement("span", { className: classnames_1.default(className, styles.badge), style: mStyle },
            React.createElement("img", { src: images_1.getSocialNetworkIcon(socialNetwork, publicationType), className: styles.icon, alt: 'logo' })));
    }
}
exports.default = SocialBadge;
SocialBadge.defaultProps = {
    publicationType: 'post'
};
exports.SocialBadgeSM = props => (React.createElement(SocialBadge, Object.assign({}, props, { size: 13 })));
exports.SocialBadgeMD = props => (React.createElement(SocialBadge, Object.assign({}, props, { size: 14 })));
exports.SocialBadgeLG = props => (React.createElement(SocialBadge, Object.assign({}, props, { size: 15 })));
exports.SocialBadgeXL = props => (React.createElement(SocialBadge, Object.assign({}, props, { size: 17 })));
