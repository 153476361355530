"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const types_1 = require("modules/shared/types");
const paypal_form_1 = require("../modules/settings-money/paypal-form");
const payoneer_form_1 = require("../modules/settings-money/payoneer-form");
const bank_account_form_1 = require("../modules/settings-money/bank-account-form/controller/bank-account-form");
const SettingsMoney = (props) => {
    const { currentUser } = props;
    const allowedPaymentMethods = lodash_1.get(props, 'systemSettings.getMinCostsByPayment.available', []).map(current => current.type);
    const paypalActive = allowedPaymentMethods.indexOf(types_1.PaymentMethod.PAYPAL) !== -1;
    const payoneerActive = allowedPaymentMethods.indexOf(types_1.PaymentMethod.PAYONEER) !== -1;
    const BankFormActive = allowedPaymentMethods.indexOf(types_1.PaymentMethod.TRANSFER) !== -1;
    return (React.createElement("div", { className: 'vf-wrapper vf-settings-money vf-bg-white-color full-viewport-height' },
        React.createElement("div", { className: 'vf-row' },
            React.createElement("div", { className: 'col-xs-12' },
                React.createElement("h3", null, i18n.get('INF_SETTINGS_MONEY_DEPOSITS')))),
        paypalActive && (React.createElement("div", { className: 'vf-row padding-top-2x' },
            React.createElement("div", { className: 'col-xs-12' },
                React.createElement(paypal_form_1.default, { currentUser: currentUser })))),
        payoneerActive && (React.createElement("div", { className: 'vf-row padding-top-2x' },
            React.createElement("div", { className: 'col-xs-12' },
                React.createElement(payoneer_form_1.default, { currentUser: currentUser })))),
        BankFormActive && (React.createElement("div", { className: 'vf-row padding-top-2x' },
            React.createElement("div", { className: 'col-xs-12' },
                React.createElement(bank_account_form_1.default, { currentUser: currentUser }))))));
};
const mapStateToProps = ({ app }) => {
    const { systemSettings } = app;
    return {
        systemSettings
    };
};
exports.default = react_redux_1.connect(mapStateToProps)(SettingsMoney);
