"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BudgetLabel = void 0;
const React = require("react");
const lodash_1 = require("lodash");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const components_1 = require("modules/campaigns/influencer/components");
const components_2 = require("apps/influencers/shared/components");
const controllers_1 = require("apps/influencers/shared/controllers");
const ProductsPayment_1 = require("apps/influencers/shared/controllers/ProductsPayment/ProductsPayment");
const components_3 = require("apps/shared/components");
const InfPinkDisclaimerDialog_1 = require("../InfPinkDisclaimerDialog");
class ParticipationCardLayout extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            showDisclaimer: false
        };
        this.handleToggleDisclaimer = () => {
            this.setState(prev => ({ showDisclaimer: !prev.showDisclaimer }));
        };
    }
    render() {
        const { showDisclaimer } = this.state;
        const { brandHighestBid, budgetCapacity, campaign, children, onPostPriceChange, onShippingAddressChange, onToggleShippingAddressEdit, postPrice, productsPayment = [], publicationType, recommendedBid, showAddressInput, socialAccount } = this.props;
        const hasProducts = productsPayment.length > 0;
        const { currency } = campaign;
        return (React.createElement(React.Fragment, null,
            React.createElement(components_2.ParticipationCardHeader, { socialAccount: socialAccount },
                React.createElement("div", { className: 'margin-top-1x' },
                    !lodash_1.isNil(brandHighestBid) && (React.createElement("p", { className: 'vf-bold', style: { whiteSpace: 'nowrap' } },
                        i18n.get('INF_VOXEAR_PUBLIC_CAMPAIGN_RECOMMENDED_BID'),
                        ":",
                        ' ',
                        i18n.getMoney({ amount: recommendedBid, currency }))),
                    React.createElement(exports.BudgetLabel, { availability: budgetCapacity }))),
            React.createElement("h3", { className: 'vf-font-bold margin-top-2x' }, i18n.get('INF_VOXEAR_CAMPAIGN_WRITE_MESSAGE_REQUIRED_ELEMENTS')),
            React.createElement(components_2.RequiredElementsSection, { campaign: campaign, socialAccount: socialAccount, publicationType: publicationType }),
            hasProducts && (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: 'vf-font-bold margin-top-2x' }, i18n.get('INF_VOXEAR_PUBLIC_CAMPAIGN_PRODUCTS_TITLE')),
                React.createElement(ProductsPayment_1.default, { productsPayment: productsPayment, onEditShippingAddress: onToggleShippingAddressEdit, currency: currency }),
                React.createElement(controllers_1.ProductsPaymentAddressInput, { className: classnames_1.default({ 'vf-hide': !showAddressInput }), onSave: onShippingAddressChange }),
                React.createElement(components_3.PinkWarningBox, { onViewMore: this.handleToggleDisclaimer }))),
            React.createElement("div", { className: 'margin-top-2x' },
                React.createElement(components_1.ParticipationRequestPostPrice, { postPrice: postPrice, recommendedBid: recommendedBid, brandHighestBid: brandHighestBid, onChange: onPostPriceChange, currency: currency })),
            children,
            React.createElement(InfPinkDisclaimerDialog_1.default, { isOpen: showDisclaimer, onClose: this.handleToggleDisclaimer, onConfirm: this.handleToggleDisclaimer })));
    }
}
exports.default = ParticipationCardLayout;
exports.BudgetLabel = ({ availability }) => {
    const textByAvailability = {
        high: i18n.get('INF_VOXEAR_PUBLIC_HIGH_BUDGET'),
        medium: i18n.get('INF_VOXEAR_PUBLIC_MEDIUM_BUDGET'),
        low: i18n.get('INF_VOXEAR_PUBLIC_LOW_BUDGET'),
        insufficient: i18n.get('INF_VOXEAR_PUBLIC_INSUFFICENT_BUDGET')
    };
    const className = classnames_1.default({
        'vf-text-success': availability === 'high',
        'vf-text-warning': availability === 'medium',
        'vf-text-danger': availability === 'low' || availability === 'insufficient'
    });
    return (React.createElement("span", { className: className }, textByAvailability[availability] || ''));
};
