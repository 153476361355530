import React, { Component } from 'react';
import qs from 'query-string';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, get } from 'lodash';
import { Button } from 'ui-library';
import i18n from 'i18n';
import { connectFacebook } from 'modules/legacy/js/lib/social-network-connections';
import { showModal, hideModal, changePage } from 'redux/modules/app';
import { getDefaultTitle } from 'config/app';

const SOCIAL_NETWORK = 'facebook';

function mapStateToProps({ entities }) {
  const { SocialNetworkAccount = {} } = entities;
  const currentAccountId = get(window, 'config.ActiveAccountId');

  return {
    socialAccounts: filter(SocialNetworkAccount, account => {
      return (
        account.influencerId === currentAccountId &&
        account.type === SOCIAL_NETWORK
      );
    })
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ showModal, hideModal, changePage }, dispatch);
}

function AccountFeature({ header, content, customClass }) {
  return (
    <div
      className={`margin-vertical-Hx margin-horizontal-Hx flex flex-col flex-align-top ${customClass}`}
    >
      <b className={'margin-right-1x'}>{header}</b>
      <span className='vf-font-normal'>{content}</span>
    </div>
  );
}

class FacebookTypeSelector extends Component {
  constructor(props) {
    super(props);
  }

  connect = () => {
    const { onRedirect, changePage, redirectTo } = this.props;
    const params = qs.stringify({ redirectTo });
    connectFacebook({ onRedirect, redirectTo }).then(() =>
      changePage(`/social-accounts/add/facebook/pages?${params}`)
    );
  };

  handleBussinesClick = () => {
    window.open(
      i18n.get(
        'INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_BUSINESS_BULLET_1_URL_BUTTON_LINK'
      )
    );
  };

  render() {
    const { onBack } = this.props;

    const cardStyleActive = {
      'box-shadow': '0 0.4rem 2rem 0 rgba(0,0,0,0.05)',
      'border-radius': '1rem',
      background: '#1778F2',
      width: '100%'
    };

    const FeatureContainerStyle = {
      background: '#fff',
      margin: '2px',
      padding: '1.5rem',
      'border-radius': '1rem',
      display: 'flex',
      'flex-direction': 'column',
      'align-items': 'flex-start'
    };

    return (
      <div>
        <div className='flex flex-col flex-space-between'>
          <div className='width100 margin-top-1x margin-bottom-2x'>
            <span>{i18n.get('INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_GENERAL')}</span>
          </div>
          <div className='vf-pull-right flex-align-top margin-top-Hx margin-right-2x'>
            <i className='icon-close vf-clickable' onClick={onBack} />
          </div>
        </div>

        <div
          className='flex flex-col flex-align-top flex-justify-space-around'
          style={{ flexWrap: 'wrap' }}
        >
          <div className='margin-bottom-3x' style={cardStyleActive}>
            <div style={FeatureContainerStyle}>
              <AccountFeature
                header='1.'
                content={
                  <span>
                    {i18n.get(
                      'INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_BUSINESS_BULLET_1'
                    )}
                  </span>
                }
              />
              <AccountFeature
                header='2.'
                content={
                  <span>
                    {i18n.get(
                      'INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_BUSINESS_BULLET_2'
                    )}
                    <br />
                    <Button
                      onClick={this.handleBussinesClick}
                      data-uitest='instagram-business-btn'
                      className='margin-top-1x'
                    >
                      {i18n.get(
                        'INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_BUSINESS_BULLET_1_URL_BUTTON'
                      )}
                    </Button>
                  </span>
                }
              />
              <AccountFeature
                header='3.'
                content={i18n.get(
                  'INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_BUSINESS_BULLET_3'
                )}
              />
              <AccountFeature
                header='4.'
                content={i18n.get(
                  'INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_BUSINESS_BULLET_4'
                )}
              />
              <AccountFeature
                header='5.'
                content={i18n.get(
                  'INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_BUSINESS_BULLET_5',
                  { companyName: getDefaultTitle() }
                )}
              />
              <div className='flex flex-justify-center margin-bottom-Hx margin-top-1x'>
                <Button
                  color='primary'
                  onClick={this.connect}
                  data-uitest='instagram-business-btn'
                  className='margin-left-2x'
                >
                  {i18n.get(
                    'INF_SOCIAL_ACCOUNTS_LINK_FACEBOOK_BUSINESS_BUTTON'
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacebookTypeSelector);
