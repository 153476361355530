/* eslint-disable global-require */
import React from 'react';
import Reflux from 'reflux';
import createClass from 'create-react-class';

const Stores = {
  configStore: require('../stores/configStore'),
  dataStore: require('../stores/dataStore'),

  currentAccount: require('../stores/currentAccount'),
  currentUser: require('../stores/currentUser')
};

const StoreConector = Element => {
  const StoreElement = createClass({
    mixins: [
      Reflux.connect(Stores.dataStore, 'dataStore'),
      Reflux.connect(Stores.currentAccount, 'currentAccount'),
      Reflux.connect(Stores.currentUser, 'currentUser'),
      Reflux.connect(Stores.configStore, 'configStore')
    ],

    getInitialState() {
      return {
        dataStore: Stores.dataStore.getAll(),
        currentAccount: Stores.currentAccount.getAll(),
        currentUser: Stores.currentUser.getAll(),
        configStore: Stores.configStore.getAll()
      };
    },

    render() {
      const { dataStore, currentAccount, currentUser, configStore } =
        this.state;

      if (!currentAccount) return null;

      return (
        <Element
          dataStore={dataStore}
          configStore={configStore}
          currentAccount={currentAccount}
          currentUser={currentUser}
          location={this.props.location}
          // eslint-disable-next-line react/no-children-prop
          children={this.props.children}
          routes={this.props.routes}
          params={this.props.params}
        />
      );
    }
  });

  return StoreElement;
};

export default StoreConector;
