import axios from 'axios';
import i18n from 'i18n';

export function getActivityStatisticsByCampaignId(campaignId) {
  return axios.get(`/campaigns/${campaignId}/stats/activity`);
}

export function getInfluencerDemographics(campaignId) {
  return axios.get(`/campaigns/${campaignId}/stats/influencer-demographics`, {
    headers: { lang: i18n.getLocale() }
  });
}

export function fetchCampaignReachedAudience(id) {
  return axios.get(`/campaigns/${id}/stats/reached-audience`);
}
