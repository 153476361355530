import axios from 'axios';

export function getInvitesByAccountId(accountId) {
  return axios.get(`/influencers/${accountId}/invites?status=created`);
}

export function getInviteById(id) {
  return axios.get(`/invites/${id}`);
}

export function fetchParticipationFeedback(id) {
  return axios.get(`/invites/${id}/feedbacks`);
}

export function acceptInvite(id) {
  return axios.post(`/invites/${id}/accept`);
}

export function rejectInvite(params) {
  const { _id } = params;
  return axios.put(`/invites/${_id}/reject`, params);
}

export function joinCampaign(params) {
  const { _id } = params;
  return axios.post(`/campaigns/${_id}/join`, params);
}

export function updateInvite(invite) {
  return axios.put(`/invites/${invite.id}`, invite);
}

export const abortInvite = ({ id, ...data }) =>
  axios.post(`/invites/${id}/abort`, data);

export function incrementTimeToSendProposals({ invitesIds = [], hours = 0 }) {
  return axios.post('/invites/increment-time-to-send-proposal', {
    hours,
    ids: invitesIds
  });
}

export function fetchActiveParticipationsByAccountId(accountId) {
  return axios.get(
    `/influencers/${accountId}/invites?status=accepted,finished`
  );
}

export function fetchHistoricalParticipationsByAccountId(accountId) {
  return axios.get(
    `/influencers/${accountId}/invites?status=cancelled,finished`
  );
}

export function setProductsShippingAddress({ inviteId, address, coordinates }) {
  return axios.post(
    `/invites/${inviteId}/products-payment/set-delivery-address`,
    { address, coordinates }
  );
}

export const sendProposal = params => axios.post('/promoted_messages/', params);

export function requestEarlyPayment({ inviteId, ...data }) {
  return axios.post(`/invites/${inviteId}/request-early-payment`, data);
}

export function fetchCampaignInvites({ campaignId, ...params }) {
  return axios.get(`/campaigns/${campaignId}/invites`, { params });
}

export function fetchCampaignInvite({ campaignId, inviteId }) {
  return axios.get(`/campaigns/${campaignId}/invites/${inviteId}`);
}

export function exportCampaignInvites({ id, ...data }) {
  return axios.post(`/campaigns/${id}/invites/export`, data);
}

export function cancelInvite({ id, ...data }) {
  return axios.post(`/invites/${id}/cancel`, data);
}

export function finishInvite({ id, ...data }) {
  return axios.post(`/invites/${id}/finish`, data);
}

export function sendPrepaidInvite({ id, password, ...data }) {
  return axios.post(`/campaigns/${id}/send-prepaid-invite`, data, {
    headers: { 'x-password': password }
  });
}

export function checkEarlyPayment({ id }) {
  return axios.post(`/invites/${id}/check-early-payment`);
}

export function restoreParticipation({ id }) {
  return axios.put(`/invites/${id}/restore-finished-participation`);
}

export function reportPost({ inviteId, ...data }) {
  return axios.post(`/pro/invites/${inviteId}/report`, data);
}
