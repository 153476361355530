"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const images_1 = require("assets/images");
const listTitles = [
    {
        id: 1,
        icon: images_1.iconGroup,
        text: 'REGISTER_CONTENT_CREATORS_TITLE'
    },
    {
        id: 2,
        icon: images_1.iconLineChart,
        text: 'REGISTER_STATISTICS_TITLE'
    },
    {
        id: 3,
        icon: images_1.iconChatHeart,
        text: 'REGISTER_CONTENT_TITLE'
    },
    {
        id: 4,
        icon: images_1.iconMoneyDollarBox,
        text: 'REGISTER_COST_PER_INTERACTION_TITLE'
    },
    {
        id: 5,
        icon: images_1.iconHandHeart,
        text: 'REGISTER_CONTENT_CREATORS_PAYMENT_TITLE'
    }
];
exports.default = listTitles;
