"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const classnames_1 = require("classnames");
const styles = require("./PartnerRegisterUI.less");
const dist_1 = require("ui-library/dist");
const lodash_1 = require("lodash");
const components_1 = require("apps/shared/components");
const components_2 = require("apps/advertisers/PartnerForm/components");
const react_router_dom_1 = require("react-router-dom");
class PartnerRegisterUI extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            terms: false
        };
        this.handleSubmit = event => {
            event.preventDefault();
            const { elements } = event.target;
            const { terms } = this.state;
            const { onSubmit } = this.props;
            const form = {
                email: elements.email.value,
                password: elements.password.value,
                name: lodash_1.get(elements, 'name.value'),
                lastName: lodash_1.get(elements, 'lastName.value'),
                terms
            };
            onSubmit(form);
        };
        this.handleTermsClick = () => {
            this.setState({ terms: !this.state.terms });
        };
        this.handleInputBlur = event => {
            const { onInputBlur } = this.props;
            const { name, value } = event.target;
            onInputBlur(name, value);
        };
    }
    render() {
        const { terms } = this.state;
        const { errors, loading, defaultEmail, isTokenValid } = this.props;
        if (loading)
            return null;
        const isInvite = !!defaultEmail;
        return (React.createElement("div", null,
            React.createElement(components_2.default, null, isTokenValid
                ? i18n.get('LOGIN_WELCOME')
                : i18n.get('REGISTER_EXPIRED_LINK')),
            isInvite && (React.createElement("p", { className: 'vf-text-gray-dark', style: { margin: '1rem 0 3rem' } }, i18n.getHTML('REGISTER_REGISTER_DESCRIPTION', {
                email: defaultEmail
            }))),
            !isTokenValid && (React.createElement("p", { className: 'vf-text-gray center-xs', style: { margin: '1rem 0 3rem' } }, i18n.get('REGISTER_EXPIRED_LINK_DESCRIPTION'))),
            isTokenValid && (React.createElement("form", { action: '#', onSubmit: this.handleSubmit },
                React.createElement(components_1.FormLabel, { htmlFor: 'name' }, i18n.get('REGISTER_FIRST_NAME_PLACEHOLDER')),
                React.createElement(components_1.FormInput, { name: 'name', type: 'text', autoComplete: 'off', hasError: !!errors.name, onBlur: this.handleInputBlur }),
                React.createElement(components_1.FormLabel, { htmlFor: 'lastName' }, i18n.get('REGISTER_LAST_NAME_PLACEHOLDER')),
                React.createElement(components_1.FormInput, { name: 'lastName', type: 'text', autoComplete: 'off', hasError: !!errors.lastName, onBlur: this.handleInputBlur }),
                React.createElement("div", { style: { display: isInvite ? 'none' : '' } },
                    React.createElement(components_1.FormLabel, { htmlFor: 'email' }, i18n.get('LOGIN_EMAIL')),
                    React.createElement(components_1.FormInput, { name: 'email', type: 'email', autoComplete: 'off', defaultValue: defaultEmail, hasError: !!errors.email, onBlur: this.handleInputBlur })),
                React.createElement(components_1.FormLabel, { htmlFor: 'password' }, i18n.get('LOGIN_PASSWORD')),
                React.createElement(components_1.FormInput, { name: 'password', type: 'password', autoComplete: 'off', hasError: !!errors.password, onBlur: this.handleInputBlur }),
                React.createElement("div", { className: 'vf-text-danger center-xs margin-top-1x vf-font-bold', style: { fontSize: '1.8rem' } },
                    React.createElement("div", null, errors.email),
                    React.createElement("div", null, errors.password),
                    React.createElement("div", null, errors.name),
                    React.createElement("div", null, errors.lastName)),
                React.createElement("div", { className: 'flex flex-start', style: { margin: '2rem 0 0' } },
                    React.createElement("input", { type: 'checkbox', className: 'margin-right-Hx', checked: terms, style: { marginTop: 4 }, onChange: this.handleTermsClick }),
                    React.createElement("div", { className: 'vf-text-secondary vf-font-bold margin-right-1x vf-clickable', onClick: this.handleTermsClick }, i18n.getHTML('REGISTER_ACCEPT_TOS'))),
                React.createElement(dist_1.Button, { color: 'primary', size: 'lg', className: classnames_1.default('width100 margin-top-1x', styles.button), type: 'submit', disabled: !terms }, i18n.get('REGISTER_REGISTER')))),
            React.createElement(react_router_dom_1.Link, { to: '/login', className: 'width100 margin-top-1x vf-text-primary vf-btn vf-btn-flat vf-btn--lg' }, i18n.get('REGISTER_BACK_TO_LOGIN'))));
    }
}
exports.default = PartnerRegisterUI;
