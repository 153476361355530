"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const styles = require("./GooglePlaceInput.less");
const appActions = require("redux/modules/app");
const ui_library_1 = require("ui-library");
const lodash_1 = require("lodash");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const google_1 = require("services/google");
const FormInput_1 = require("apps/shared/components/FormInput/FormInput");
class GooglePlaceInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.debouncedAutocomplete = null;
        this.state = { places: [] };
        this.fetchAutocomplete = input => {
            if (input.length > 0) {
                google_1.googleAutocompleteAPI({ input })
                    .then(this.onAutocompleteSuccess)
                    .catch(this.onGoogleFailed);
            }
            else {
                this.setState({ places: [] });
            }
        };
        this.onAutocompleteSuccess = results => {
            const places = results.map(res => ({
                description: res.description,
                id: res.place_id
            }));
            this.setState({ places });
        };
        this.onGoogleFailed = err => {
            const { showAlert } = this.props;
            showAlert({
                title: i18n.get('DEFAULT_ERROR_TITLE')
            });
        };
        this.onPlaceDetailSuccess = place => {
            const { onPlaceClick } = this.props;
            const deliveryInfo = {
                address: place.formatted_address,
                coordinates: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                }
            };
            this.setState({ places: [] });
            onPlaceClick(deliveryInfo);
        };
        this.handleChange = event => {
            const { onChange } = this.props;
            const { value } = event.target;
            const deliveryInfo = {
                address: value,
                coordinates: null
            };
            onChange(deliveryInfo);
            this.debouncedAutocomplete(value);
        };
        this.handlePlaceClick = place => {
            const params = {
                placeId: place.id
            };
            google_1.googleDetailAPI(params)
                .then(this.onPlaceDetailSuccess)
                .catch(this.onGoogleFailed);
        };
        this.debouncedAutocomplete = lodash_1.debounce(this.fetchAutocomplete, 500);
    }
    render() {
        const { places } = this.state;
        const { placeholder, value } = this.props;
        const placesList = places.map(place => (React.createElement(PlaceItem, { key: place.id, place: place, onClick: this.handlePlaceClick })));
        return (React.createElement(ui_library_1.InputWrapper, { className: styles['input-wrapper'] },
            React.createElement("i", { className: 'vf-icon icon-ic-location' }),
            React.createElement(FormInput_1.default, { value: value, placeholder: placeholder, style: { paddingLeft: '3.5rem' }, onChange: this.handleChange }),
            React.createElement("div", { className: styles.list }, placesList)));
    }
}
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    showAlert: appActions.showAlert
}, dispatch);
exports.default = react_redux_1.connect(null, mapDispatchToProps)(GooglePlaceInput);
const PlaceItem = ({ place, onClick }) => (React.createElement("div", { className: styles.item, onClick: () => onClick(place) }, place.description));
