"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const placeActions = require("redux/modules/places");
const lodash_1 = require("lodash");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const SinglePlaceSelect_1 = require("apps/shared/components/SinglePlaceSelect/SinglePlaceSelect");
class CitySelect extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            searchValue: '',
            isLoading: false
        };
        this.fetchData = (props = this.props) => {
            const { searchValue } = this.state;
            const { fetchCities, fetchPlace, countryId } = props;
            const params = {
                q: searchValue,
                country: countryId,
                replace: true
            };
            fetchCities(params)
                .then(() => fetchPlace({ id: countryId }))
                .finally(() => this.setState({ isLoading: false }));
        };
        this.handleInputChange = searchValue => {
            this.setState({
                searchValue,
                isLoading: true
            }, this.fetchData);
        };
        this.handleCityClick = countryId => {
            const { cities, onChange } = this.props;
            const selectedCity = cities[countryId];
            this.setState({
                searchValue: selectedCity.name
            }, () => onChange(selectedCity));
        };
        this.handleClear = () => {
            const { onChange } = this.props;
            this.setState({ searchValue: '' }, () => onChange({}));
        };
        this.getCities = () => {
            const { cities, cityId } = this.props;
            return lodash_1.chain(cities)
                .filter(city => city.id !== cityId && city.type === 'city')
                .orderBy('score', 'desc')
                .map(city => {
                const name = lodash_1.get(city, 'name', '');
                const adminName = lodash_1.get(city, 'admin1.name', '');
                return Object.assign(Object.assign({}, city), { name: `${name}, ${adminName}` });
            })
                .value();
        };
        this.getSelectedCity = () => {
            const { cities, cityId } = this.props;
            return cities[cityId];
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.countryId !== this.props.countryId) {
            this.setState({ searchValue: '' });
        }
    }
    render() {
        const { disabled, hasError } = this.props;
        const { searchValue } = this.state;
        return (React.createElement(SinglePlaceSelect_1.default, { disabled: disabled, hasError: hasError, placeholder: i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_CITY_PLACEHOLDER'), places: this.getCities(), searchValue: searchValue, selectedPlace: this.getSelectedCity(), onSearchChange: this.handleInputChange, onPlaceClick: this.handleCityClick, onClear: this.handleClear }));
    }
}
const mapStateToProps = state => ({
    cities: lodash_1.get(state, 'entities.Place', {})
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    fetchCities: placeActions.fetchCities,
    fetchPlace: placeActions.fetchPlace
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(CitySelect);
