"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const controllers_1 = require("apps/influencers/shared/controllers");
const containers_1 = require("modules/brands/advertiser/containers");
class LayoutUI extends React.PureComponent {
    render() {
        const { partnerId, children } = this.props;
        return (React.createElement("div", null,
            children,
            React.createElement(containers_1.FloatingChatManager, null),
            partnerId && (React.createElement(controllers_1.PartnerMessageDialog, { partnerId: partnerId }))));
    }
}
exports.default = LayoutUI;
