import axios from 'axios';

export { loginWithCredentials, loginWithToken } from './login';
export { logout } from './logout';
export { register } from './register';
export { acceptRegistrationInvite } from './accept-registration-invite';

export function getUserSession(userId) {
  return axios.get(`/users/${userId}/session`);
}
