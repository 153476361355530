import { get } from 'lodash';

import { getStatics } from 'config/app';

export const getActiveSNCampaign = campaign => {
  const socialNetworks = Object.keys(getStatics().socialNetworks);

  if (campaign.socialNetworks) {
    return socialNetworks.filter(
      sn =>
        get(campaign.socialNetworks[sn], 'post.active') ||
        get(campaign.socialNetworks[sn], 'story.active')
    );
  }

  return socialNetworks.filter(sn => get(campaign[sn], 'active'));
};

const getActiveSN = campaign => {
  const socialNetworks = Object.keys(getStatics().socialNetworks);

  return socialNetworks.filter(
    sn => get(campaign[sn], 'post.active') || get(campaign[sn], 'story.active')
  );
};

export default getActiveSN;
