"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const errorStateHandler = (Component) => class ErrorStateHandler extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            errors: {}
        };
        this.handleInputValidation = (params) => {
            const { name, value, validationFunction } = params;
            const { errors } = this.state;
            const form = { [name]: value };
            this.setState({ errors: Object.assign(Object.assign({}, errors), { [name]: validationFunction(form)[name] }) });
        };
        this.handleSetErrors = errors => {
            this.setState({ errors });
        };
    }
    render() {
        const { errors } = this.state;
        return (React.createElement(Component, Object.assign({}, this.props, { errors: errors, handleInputValidation: this.handleInputValidation, onSetErrors: this.handleSetErrors })));
    }
};
exports.default = errorStateHandler;
