import { createSelector } from 'reselect';
import {
  get,
  filter,
  find,
  chain,
  orderBy,
  isEmpty,
  isArray,
  pick,
  reduce,
  isNil
} from 'lodash';

import { ECategoryType } from 'modules/shared/types';

export const getCurrentUser = createSelector(
  state => state.shared || {},
  shared => {
    if (shared) {
      if (shared.user) {
        // eslint-disable-next-line no-param-reassign
        shared.user.validCategory = shared.user.category === ECategoryType.LITE;
      }
    }
    return shared.user || {};
  }
);

export const getCurrentAccountId = createSelector(getCurrentUser, user =>
  get(user, 'account._id')
);

export const getPayoneerInfo = createSelector(
  getCurrentUser,
  state => get(state, 'entities.PayoneerInfo', {}),
  (user, payoneerInfo) => get(payoneerInfo, user._id, {})
);

export const getCurrentInfluencerSocialAccounts = createSelector(
  getCurrentUser,
  state => get(state, 'entities.SocialNetworkAccount', {}),
  (user, socialAccounts) => {
    const currentAccount = get(user, 'account');
    return filter(
      socialAccounts,
      sna => sna.influencerId === currentAccount._id
    );
  }
);

export const getBrands = createSelector(
  state => get(state, 'entities.Account', {}),
  accounts => filter(accounts, b => b.type === 'advertiser')
);

export const getInfluencerSocialAccounts = createSelector(
  state => get(state, 'entities.SocialNetworkAccount', {}),
  (state, influencerId) => influencerId,
  (socialAccounts, influencerId) =>
    filter(socialAccounts, sna => sna.influencerId === influencerId)
);

export const getPendingVerificationRequests = createSelector(
  state => get(state, 'entities.UserVerificationRequest', {}),
  requests => filter(requests, req => req.status === 'created')
);

export const getCurrentAdvertiserBrands = createSelector(
  getCurrentUser,
  state => get(state, 'entities.Account', {}),
  (currentUser, accounts) => {
    const BRANDS_ACCOUNT_TYPE = 'advertiser';
    const currentAdvertiserId = get(currentUser, '_id', '');

    return chain(accounts)
      .filter(b => {
        const brandAdvertiserId = get(b, 'user.id');

        return (
          b.type === BRANDS_ACCOUNT_TYPE &&
          (b.status === 'active' || b.status === 'pending') &&
          brandAdvertiserId === currentAdvertiserId
        );
      })
      .orderBy(
        [b => get(b, 'notifications.total', 0), 'createdAt'],
        ['desc', 'desc']
      )
      .value();
  }
);

export const getCampaign = createSelector(
  state => get(state, 'entities.Campaign', {}),
  (state, campaignId) => campaignId,
  (campaigns, campaignId) => campaigns[campaignId]
);

export const getBrand = createSelector(
  state => get(state, 'entities.Account', {}),
  (state, brandId) => brandId,
  (brands, brandId) => brands[brandId]
);

export const getBrandCampaigns = createSelector(
  state => get(state, 'entities.Campaign', {}),
  (state, brandId) => brandId,
  (campaigns, brandId) => filter(campaigns, c => c.account === brandId)
);

export const getBrandCampaignsByStatuses = createSelector(
  state => get(state, 'entities.Campaign', {}),
  (state, brandId) => brandId,
  (state, brandId, statuses = []) => statuses,
  (campaigns, brandId, statuses) =>
    filter(campaigns, c => c.account === brandId && statuses.includes(c.status))
);

export const getBrandUsers = createSelector(
  state => get(state, 'entities.BrandUser', {}),
  (state, brandId) => brandId,
  (users, brandId) => filter(users, u => u.brandId === brandId)
);

export const getActiveBrandUsers = createSelector(getBrandUsers, users =>
  users.filter(u => u.status === 'active')
);

export const getInvitedBrandUsers = createSelector(getBrandUsers, users =>
  users.filter(u => u.status === 'pending')
);

export const getCampaignFeedbacks = createSelector(
  state => get(state, 'entities.CampaignFeedback', {}),
  (state, campaignId) => campaignId,
  (feedbacks, campaignId) => filter(feedbacks, f => f.campaignId === campaignId)
);

export const getInfluencerHistoricalParticipations = createSelector(
  state => get(state, 'entities.HistoricalParticipation', {}),
  (state, socialNetworkAccountId) => socialNetworkAccountId,
  (participations, socialNetworkAccountId) =>
    filter(
      participations,
      p => p.socialNetworkAccountId === socialNetworkAccountId
    )
);

export const getInfluencerPublishedPosts = createSelector(
  state => get(state, 'entities.SocialNetworkMessage', {}),
  (state, socialAccountId) => socialAccountId,
  (posts, socialAccountId) =>
    filter(posts, p => p.socialAccountId === socialAccountId)
);

export const getChatByBrandAndInfluencerId = createSelector(
  getCurrentUser,
  state => get(state, 'entities.Chat', {}),
  state => get(state, 'entities.ChatMember', {}),
  (state, brandId) => brandId,
  (state, brandId, influencerUserId) => influencerUserId,
  (currentUser, chats, members, brandId, influencerUserId) => {
    const chat =
      find(chats, c => {
        const chatBrandId = get(c, 'brand.id');
        const chatInfluencerId = get(c, 'influencer.id');
        return chatBrandId === brandId && chatInfluencerId === influencerUserId;
      }) || {};

    return {
      ...chat,
      member:
        find(
          members,
          m => m.chatId === chat.id && m.userId === currentUser._id
        ) || {}
    };
  }
);

export const getChatMessages = createSelector(
  state => get(state, 'entities.ChatMessage', {}),
  (state, chatId) => chatId,
  (messages, chatId) =>
    chain(messages)
      .filter(m => m.chatId === chatId)
      .orderBy('index', 'asc')
      .value()
);

export function getInvites(state) {
  return state.entities.Invite || {};
}

export function getSocialNetworkAccounts(state) {
  return state.entities.SocialNetworkAccount || {};
}

export const getSocialNetworkAccountsByIds = createSelector(
  getSocialNetworkAccounts,
  (state, ids) => ids,
  (socialAccounts, ids) => pick(socialAccounts, ids)
);

export function getInvite(state, id) {
  return getInvites(state)[id];
}

export function getSocialNetworkAccount(state, id) {
  return getSocialNetworkAccounts(state)[id];
}

export const getTotalIncompleteSocialAccounts = createSelector(
  getCurrentInfluencerSocialAccounts,
  socialAccounts =>
    socialAccounts.reduce((total, socialAccount) => {
      const { isAuthorized, requestCredentials, accountType, postPrice } =
        socialAccount;
      const interests = socialAccount.interests || [];
      const desiredPostPrice = get(postPrice, 'byInfluencer', 0);
      const isIncomplete =
        !interests.length || !accountType || !desiredPostPrice;
      const isUnlinked = !isAuthorized || requestCredentials;

      if (isIncomplete || isUnlinked) return total + 1;
      return total;
    }, 0)
);

export const getBrandChats = createSelector(
  getCurrentUser,
  state => get(state, 'entities.Chat', {}),
  state => get(state, 'entities.ChatMember', {}),
  (state, brandId) => brandId,
  (currentUser, chats, members, brandId) =>
    chain(chats)
      .reduce((result, chat) => {
        const isValidChat = chat.brandId === brandId && chat.totalMessages > 0;
        if (!isValidChat) return result;

        const member = find(
          members,
          m => m.chatId === chat.id && m.userId === currentUser._id
        ) || { chatStatus: 'active' };

        result.push({ ...chat, member });
        return result;
      }, [])
      .sort(sortChatsByLatestMessage)
      .value()
);

export const getChatMembers = createSelector(
  state => get(state, 'entities.ChatMember', {}),
  (state, chatId) => chatId,
  (members, chatId) =>
    Object.keys(members).reduce((result, id) => {
      // eslint-disable-next-line no-param-reassign
      if (members[id].chatId === chatId) result[id] = members[id];
      return result;
    }, {})
);

export const getBrandFloatingChats = createSelector(
  state => get(state, 'chats.floatingChats', {}),
  (state, brandId) => brandId,
  (chats, brandId) =>
    chain(chats)
      .filter(c => c.brandId === brandId)
      .orderBy('openAt', 'desc')
      .value()
);

export const getAllFloatingChats = createSelector(
  state => get(state, 'chats.floatingChats', {}),
  chats => orderBy(chats, 'openAt', 'desc')
);

export const getCurrentAdvertiserTotalUnreadMessagesByBrand = createSelector(
  getCurrentUser,
  (state, brandId) =>
    chain(state)
      .get('entities.Chat')
      .filter(c => c.brandId === brandId)
      .value(),
  state => get(state, 'entities.ChatMember'),
  (currentUser, chats, members) => {
    const NO_UNREAD_MESSAGES = 0;
    if (isEmpty(chats)) return NO_UNREAD_MESSAGES;

    return chats.reduce((total, chat) => {
      const member = find(
        members,
        m => m.userId === currentUser._id && m.chatId === chat.id
      );
      if (!member) return total + chat.totalMessages;

      const lastReadMessageIndex = get(member, 'lastReadMessage.index', -1);
      const chatUnreadMessages =
        chat.totalMessages - (lastReadMessageIndex + 1);

      return total + chatUnreadMessages;
    }, NO_UNREAD_MESSAGES);
  }
);

export function getInterests(state) {
  const interests = get(
    state,
    ['socialAccounts', 'configuration', 'interests'],
    []
  );
  return isArray(interests) ? interests : [];
}

export function getInterestsLimits(state) {
  return get(state, 'socialAccounts.configuration.interestsLimit');
}

export function getSocialAccountTypes(state) {
  const accountTypes = get(
    state,
    ['socialAccounts', 'configuration', 'accountTypes'],
    []
  );
  return isArray(accountTypes) ? accountTypes : [];
}

export const getCurrentInfluencerNewInvites = createSelector(
  getCurrentAccountId,
  state => get(state, 'entities.Invite', {}),
  (accountId, invites) =>
    chain(invites)
      .filter(inv => inv.influencer === accountId && inv.status === 'created')
      .orderBy('createdAt', 'desc')
      .value()
);

export const getCurrentInfluencerActiveInvites = createSelector(
  getCurrentAccountId,
  state => get(state, 'entities.ActiveInvite', {}),
  (accountId, invites) =>
    chain(invites)
      .filter(inv => inv.influencer === accountId)
      .orderBy(
        [
          invite => get(invite, 'pendingTasks[0]', { dueDate: null }).dueDate,
          'createdAt'
        ],
        ['asc', 'desc']
      )
      .value()
);

export const getCurrentInfluencerPendingInvites = createSelector(
  getCurrentAccountId,
  state => get(state, 'entities.PendingInvite', {}),
  (accountId, invites) =>
    chain(invites)
      .filter(inv => inv.influencer === accountId)
      .orderBy(['createdAt'], ['desc'])
      .value()
);

export const getCurrentInfluencerInvitesHistory = createSelector(
  getCurrentAccountId,
  state => get(state, 'entities.HistoryInvite', {}),
  (accountId, invites) =>
    chain(invites)
      .filter(inv => inv.influencer === accountId)
      .orderBy(['createdAt'], ['desc'])
      .value()
);

export const getAvailableCampaigns = createSelector(
  getCurrentAccountId,
  state => get(state, 'campaigns.availableCampaigns', []),
  (accountId, availableCampaigns) =>
    chain(availableCampaigns)
      .filter(c => c.influencerId === accountId)
      .orderBy('affinityScore', 'desc')
      .value()
);

export const getCurrentInfluencerActiveParticipations = createSelector(
  getCurrentUser,
  state => get(state, 'entities.Invite', {}),
  (currentUser, invites) =>
    chain(invites)
      .filter(inv => {
        const isWaitingForPayment =
          inv.status === 'finished' && inv.paymentStatus === 'pending';
        return (
          inv.influencer === currentUser.account._id &&
          (inv.status === 'accepted' || isWaitingForPayment)
        );
      })
      .orderBy(['status', 'dateForPayment'], ['asc', 'desc'])
      .value()
);

export const getCurrentInfluencerHistoricalParticipations = createSelector(
  getCurrentUser,
  state => get(state, 'entities.Invite', {}),
  (currentUser, invites) =>
    chain(invites)
      .filter(
        inv =>
          inv.influencer === currentUser.account._id &&
          (inv.status === 'cancelled' ||
            (inv.status === 'finished' && inv.paymentStatus !== 'pending'))
      )
      .orderBy(['status', 'finishedAt'], ['desc', 'desc'])
      .value()
);

export const getPublicCampaign = createSelector(
  state => get(state, 'campaigns.availableCampaigns', []),
  (state, id) => id,
  (publicCampaigns, id) => publicCampaigns.find(c => c.campaignId === id)
);

export const getCurrentInfluencerParticipationRequests = createSelector(
  getCurrentUser,
  state => get(state, 'entities.ParticipationRequest', {}),
  (currentUser, requests) =>
    chain(requests)
      .filter(
        r =>
          r.influencerId === currentUser.account._id && r.status === 'created'
      )
      .orderBy('updatedAt', 'desc')
      .value()
);

export const getParticipationRequest = createSelector(
  state => get(state, 'entities.ParticipationRequest', {}),
  (state, id) => id,
  (requests, id) => requests[id]
);

export const getRequest = createSelector(
  state => get(state, 'app.requests', {}),
  (state, id) => id,
  (requests, id) => requests[id] || {}
);

export const socialAccountsWithParticipationRequest = createSelector(
  state => state,
  state => get(state, 'entities.ParticipationRequest', {}),
  (state, parReqs) =>
    reduce(
      parReqs,
      (sas, pr) => {
        const socialAccount = get(
          state,
          `entities.SocialNetworkAccount.${pr.socialNetworkAccountId}`,
          {}
        );
        if (pr.status === 'created') {
          sas.push({
            _id: pr.socialNetworkAccountId,
            username: socialAccount.username,
            type: socialAccount.type,
            participationRequest: pr
          });
        }
        return sas;
      },
      []
    )
);

export const getParticipationPromotedMessages = createSelector(
  state => get(state, 'entities.PromotedMessage', {}),
  (state, participationId) => participationId,
  (promotedMessages, participationId) =>
    filter(promotedMessages, pm => pm.invite === participationId)
);

export const getInvitePromotedMessages = createSelector(
  state => get(state, 'entities.PromotedMessage', {}),
  (state, inviteId) => inviteId,
  (promotedMessages, inviteId) =>
    filter(promotedMessages, pm => pm.invite === inviteId)
);

export const getPromotedMessagesByStatus = createSelector(
  state => get(state, 'entities.PromotedMessage', {}),
  (state, status) => status,
  (promotedMessages, status) =>
    filter(promotedMessages, pm => pm.status === status)
);

export const getCurrentInfluencerPosts = createSelector(
  getCurrentUser,
  state => get(state, 'entities.PromotedMessage', {}),
  (currentUser, posts) =>
    chain(posts)
      .filter(p => p.influencer === currentUser.account._id)
      .orderBy('createdAt', 'desc')
      .value()
);

export const getCurrentInfluencerChats = createSelector(
  getCurrentUser,
  state => get(state, 'entities.Chat'),
  state => get(state, 'entities.ChatMember'),
  (currentUser, chats, members) =>
    chain(chats)
      .reduce((result, chat) => {
        const isValidChat =
          chat.influencerUserId === currentUser._id && chat.totalMessages > 0;
        if (!isValidChat) return result;

        const member = find(
          members,
          m => m.chatId === chat.id && m.userId === currentUser._id
        ) || { chatStatus: 'active' };
        result.push({ ...chat, member });
        return result;
      }, [])
      .sort(sortChatsByLatestMessage)
      .value()
);

export const getCurrentInfluencerChatMembers = createSelector(
  getCurrentUser,
  state => get(state, 'entities.ChatMember', {}),
  (currentUser, chatMembers) =>
    filter(chatMembers, m => m.userId === currentUser._id)
);

export const getChat = createSelector(
  getCurrentUser,
  (state, chatId) => get(state, `entities.Chat.${chatId}`, {}),
  state => get(state, 'entities.ChatMember', {}),
  (currentUser, chat, members) => ({
    ...chat,
    member:
      find(
        members,
        m => m.chatId === chat.id && m.userId === currentUser._id
      ) || {}
  })
);

export const getCurrentInfluencerTotalUnreadMessages = createSelector(
  getCurrentInfluencerChats,
  chats => {
    const NO_UNREAD_MESSAGES = 0;

    return chats.reduce((total, chat) => {
      const lastReadMessageIndex = get(
        chat,
        'member.lastReadMessage.index',
        -1
      );
      const chatUnreadMessages =
        chat.totalMessages - (lastReadMessageIndex + 1);
      return total + chatUnreadMessages;
    }, NO_UNREAD_MESSAGES);
  }
);

export const getCampaignStats = createSelector(
  state => get(state, 'stats.campaigns', {}),
  (state, campaignId) => campaignId,
  (stats, campaignId) => get(stats, campaignId, {})
);

export const getCampaignInfluencerStats = createSelector(
  getCampaignStats,
  stats => stats.influencersDemographics || {}
);

export const getCampaignReachedAudience = createSelector(
  getCampaignStats,
  stats => stats.reachedAudience || {}
);

function sortChatsByLatestMessage(a, b) {
  const currentDate = get(a, 'lastMessage.createdAt', '');
  const nextDate = get(b, 'lastMessage.createdAt', '');

  if (!nextDate || currentDate > nextDate) return -1;
  if (!currentDate || currentDate < nextDate) return 1;
  return 0;
}

export const getBrandProducts = createSelector(
  state => get(state, 'entities.Product', {}),
  (state, brandId) => brandId,
  (products, brandId) =>
    filter(products, product => product.brandId === brandId)
);

export const getProductsByIds = createSelector(
  state => get(state, 'entities.Product', {}),
  (state, ids) => ids,
  (products, ids) => pick(products, ids)
);

export const getAppInfo = createSelector(
  state => get(state, 'app'),
  (state, key) => key,
  (app, key) => app[key]
);

export const getEntityById = createSelector(
  state => get(state, 'entities'),
  (state, modelName, id) => ({ modelName, id }),
  (entities, { modelName, id }) => get(entities, [modelName, id], undefined)
);

export const getSocialAccountTimeline = createSelector(
  state => get(state, 'entities.TimelinePost', {}),
  (state, socialAccountId) => socialAccountId,
  (timelinePosts, socialAccoutId) =>
    chain(timelinePosts)
      .filter(p => p.socialNetworkAccountId === socialAccoutId)
      .orderBy('publishedAt', 'desc')
      .value()
);

export const getAvailableLanguages = createSelector(
  state => get(state, 'app', {}),
  appConfig => get(appConfig, 'availableLanguages', [])
);

export const getPartner = createSelector(
  state => get(state, 'entities.Partner', {}),
  (state, id) => id,
  (partners, id) => get(partners, id)
);

export const getOrganizationUsersByStatus = createSelector(
  state => get(state, 'entities.OrganizationUser', {}),
  (state, orgId) => orgId,
  (state, orgId, status) => (Array.isArray(status) ? status : [status]),
  (orgUsers, orgId, status) =>
    filter(
      orgUsers,
      orgUser =>
        orgUser.organizationId === orgId && status.includes(orgUser.status)
    )
);

export const getOrgsByUserStatus = createSelector(
  state => get(state, 'entities.Organization', {}),
  (state, status) => status,
  (orgs, status) => filter(orgs, org => get(org, 'status') === status)
);

export const getFirstCampaign = createSelector(
  state => get(state, 'entities.Campaign', {}),
  campaigns => find(campaigns, () => true)
);

export const getOrganization = createSelector(
  state => get(state, 'entities.Organization', {}),
  (state, id) => id,
  (organzations, id) => get(organzations, id)
);

export const getTeamsByOrg = createSelector(
  state => get(state, 'entities.Team', {}),
  (state, orgId) => orgId,
  (state, orgId, status) => (Array.isArray(status) ? status : [status]),
  (teams, orgId, status) =>
    filter(
      teams,
      team => team.organizationId === orgId && status.includes(team.status)
    )
);

export const getTeamsByBrand = createSelector(
  state => get(state, 'entities.BrandTeam', {}),
  (state, brandId) => brandId,
  (teams, brandId) => filter(teams, team => team.brandId === brandId)
);

export const getBrandsByOrg = createSelector(
  state => get(state, 'entities.Account', {}),
  (state, orgId) => orgId,
  (brands, orgId) =>
    filter(brands, brand => orgId && brand.organizationId === orgId)
);

export const getBrandTransactions = createSelector(
  state => get(state, 'entities.BrandTransaction', {}),
  (state, brandId) => brandId,
  (transactions, brandId) =>
    filter(transactions, tran => tran.brandId === brandId)
);

export const getCampaignsByBrand = createSelector(
  state => get(state, 'entities.Campaign', {}),
  (state, brandId) => brandId,
  (camps, brandId) =>
    filter(camps, camp => camp.account === brandId && camp.status !== 'deleted')
);

export function getBrandCurrentCampaign(state, brandId) {
  return get(state, `entities.Campaign.current_${brandId}`);
}

export const getMinCPEBySocialNetwork = createSelector(
  state => get(state, 'campaigns.config', {}),
  (state, socialNetwork) => socialNetwork,
  (config, socialNetwork) =>
    get(config, `minCostPerEngagement.${socialNetwork}.post`, 0)
);

export const getRecommendedCPEBySocialNetwork = createSelector(
  state => get(state, 'campaigns.config', {}),
  (state, socialNetwork) => socialNetwork,
  (config, socialNetwork) =>
    get(config, `suggestedCostPerEngagement.${socialNetwork}.post`, 0)
);

export const getTakeRate = createSelector(
  state => get(state, 'entities.TakeRate', {}),
  (state, campaignId, postPrice) => ({ campaignId, postPrice }),
  (takeRates, { campaignId, postPrice }) =>
    takeRates[`${campaignId}_${postPrice.toString().replace('.', '_')}`]
);

export const getEarningsToRelease = createSelector(
  state => get(state, 'entities.Earning', {}),
  (state, influencerId) => influencerId,
  (earnings, influencerId) =>
    filter(earnings, earning => earning.influencerId === influencerId)
);

export function getPayPalDepositFee(state, amount) {
  return get(state, `entities.PayPalDepositFee[${amount}]`, {});
}

export const getPlacesByIds = createSelector(
  state => get(state, 'entities.Place', {}),
  (state, ids) => ids,
  (places, ids) => pick(places, ids)
);

export const getAudienceKeywords = createSelector(
  state => get(state, 'entities.AudienceKeyword', {}),
  keywords => Object.values(keywords)
);

export const getCurrentUserVoxFeedPayStatus = createSelector(
  getCurrentUser,
  state => get(state, 'entities.VoxFeedPayRequest', {}),
  (user, requests) => requests[user.id]
);

export const getCampaignInvites = createSelector(
  state => get(state, 'entities.Invite', {}),
  (state, campaignId) => campaignId,
  (invites, campaignId) =>
    filter(invites, invite => invite.campaign === campaignId)
);

export const getCampaignPendingActivity = createSelector(
  state => get(state, 'entities.CampaignPendingActivity', {}),
  (state, campaignId) => campaignId,
  (activity, campaignId) => get(activity, campaignId, {})
);

export const getUnverifiedPublications = createSelector(
  state => get(state, 'entities.Publication'),
  publications =>
    filter(
      publications,
      publication =>
        !publication.statsVerification && isNil(publication.deletedAt)
    )
);
