"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./FormInput.less");
const FormInput = React.forwardRef((props, ref) => {
    const { hasError, isActive, className = '' } = props, others = __rest(props, ["hasError", "isActive", "className"]);
    return (React.createElement("input", Object.assign({}, others, { ref: ref, className: classnames_1.default(styles.input, className, {
            [styles['input-error']]: hasError,
            [styles['input-active']]: isActive
        }) })));
});
exports.default = FormInput;
