import axios from 'axios';

export function fetchInfluencerChatWithBrand(brandId) {
  return axios.get(`/chats?brandId=${brandId}`);
}

export function fetchInfluencerChats() {
  return axios.get('/chats');
}

export function sendMessageAsInfluencer({chatId, text, attachments}) {
  return axios.post(`/chats/${chatId}/messages`, {
    text,
    attachments
  });
}

export function fetchChatMessagesAsInfluencer({chatId, ...other}) {
  return axios.get(`/chats/${chatId}/messages`, {
    params: other
  });
}

export function fetchChatMembersAsInfluencer(chatId) {
  return axios.get(`/chats/${chatId}/members`);
}

export function setLastReadMessageAsInfluencer({chatId, messageId}) {
  return axios.post(`/chats/${chatId}/set-last-read`, {messageId});
}

export function fetchBrandChats(brandId) {
  return axios.get(`/brands/${brandId}/chats`);
}

export function fetchChatMembersAsAdvertiser({brandId, chatId}) {
  return axios.get(`/brands/${brandId}/chats/${chatId}/members`);
}

export function fetchChatMessagesAsAdvertiser({brandId, chatId, ...other}) {
  return axios.get(`/brands/${brandId}/chats/${chatId}/messages`, {
    params: other
  });
}

export function sendMessageAsAdvertiser(args) {
  const {
    brandId,
    chatId,
    text,
    attachments
  } = args;

  return axios.post(`/brands/${brandId}/chats/${chatId}/messages`, {
    text,
    attachments
  });
}

export function fetchInfluencerSocialAccounts({brandId, chatId}) {
  return axios.get(`/brands/${brandId}/chats/${chatId}/influencer/social-network-accounts`);
}

export function setLastReadMessageAsAdvertiser({brandId, chatId, messageId}) {
  return axios.post(`/brands/${brandId}/chats/${chatId}/set-last-read`, {messageId});
}

export function fetchAdvertiserMemberInfoInBrandChats({brandId, chatIds}) {
  const params = chatIds
    ? {chatIds: chatIds.join(',')}
    : null;

  return axios.get(`/brands/${brandId}/chats/members/me`, {params});
}

export function fetchChatByInfluencerId({brandId, influencerId}) {
  return axios.get(`/brands/${brandId}/chats?influencerId=${influencerId}`);
}

export function fetchInfluencerMemberInfoInChats() {
  return axios.get('/chats/members/me');
}

export function archive(id) {
  return axios.post(`/chats/${id}/archive`);
}

export function unarchive(id) {
  return axios.post(`/chats/${id}/unarchive`);
}

export function block(id) {
  return axios.post(`/chats/${id}/block`);
}

export function unblock(id) {
  return axios.post(`/chats/${id}/unblock`);
}

export function accept(id) {
  return axios.post(`/chats/${id}/accept`);
}

export function resolve(id) {
  return axios.post(`/chats/${id}/resolve`);
}
