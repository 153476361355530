"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const UserAvatarDropdownUI_1 = require("./UserAvatarDropdownUI/UserAvatarDropdownUI");
const react_redux_1 = require("react-redux");
const selectors_1 = require("modules/shared/selectors");
class UserAvatarDropdown extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.handleLogout = () => {
            const { logout } = this.props;
            logout();
        };
    }
    render() {
        const { user } = this.props;
        return React.createElement(UserAvatarDropdownUI_1.default, { user: user, onLogout: this.handleLogout });
    }
}
UserAvatarDropdown.defaultProps = {
    acceptedOrgs: [],
    createdOrgs: []
};
const mapStateToProps = state => ({
    user: selectors_1.getCurrentUser(state)
});
exports.default = react_redux_1.connect(mapStateToProps)(UserAvatarDropdown);
