"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const components_1 = require("apps/influencers/CampaignLinkPage/components");
const controllers_1 = require("apps/influencers/shared/controllers");
const components_2 = require("apps/influencers/shared/components");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const controllers_2 = require("apps/influencers/CampaignLinkPage/controllers");
const styles = require("../../../components/InvalidAccount/InvalidAccount.less");
const InvalidAccount_1 = require("apps/influencers/CampaignLinkPage/components/InvalidAccount/InvalidAccount");
const LayoutUI = ({ brand, isValid, campaign, partnerId, currentUser, campaignLite, campaignLiteId, onInvalidAccount, invalidAccountModal }) => {
    if (currentUser && currentUser.role === 'influencer')
        return null;
    if (!isValid)
        return React.createElement(components_1.InvalidLinkPage, null);
    return (React.createElement("div", null,
        partnerId && React.createElement(controllers_1.PartnerMessageDialog, { partnerId: partnerId }),
        React.createElement(CustomDialog_1.default, { size: 'sml', dismissable: false, isOpen: invalidAccountModal, className: styles.dialogContent, content: React.createElement(InvalidAccount_1.default, { onClose: onInvalidAccount, campaignLite: campaignLite }) }),
        React.createElement(components_2.ParticipationLayout, { campaign: campaign, campaignLite: campaignLite, header: React.createElement(controllers_1.PublicCampaignHeader, { campaign: campaign }), right: !campaignLite ? (React.createElement(components_1.ResponsiveDialog, null,
                React.createElement(controllers_2.InfluencerLogin, { brand: brand, campaignLite: campaignLite, campaignLiteId: campaignLiteId, onInvalidAccount: onInvalidAccount }))) : (React.createElement(controllers_2.InfluencerLogin, { brand: brand, campaignLite: campaignLite, campaignLiteId: campaignLiteId, onInvalidAccount: onInvalidAccount, isCampaignSoldOut: campaign.status === 'paused' })) })));
};
exports.default = LayoutUI;
