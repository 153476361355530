import Reflux from 'reflux';
import { get, includes } from 'lodash';
import moment from 'moment';
import i18n from 'i18n';

import API from 'modules/legacy/js/lib/api';
import { getInstance } from 'modules/legacy/js/lib/datamanager';

import Helpers from 'modules/legacy/js/lib/helpers';
import userTracking from 'modules/legacy/js/lib/tracking/user';
import acquisitionTracking from 'modules/legacy/js/lib/tracking/acquisition';
import { crispUpdateUser } from 'utils/crispActions';

const isProduction = process.env.NODE_ENV === 'production';

const DataManager = getInstance();
const Actions = {
  App: require('./app'),
  ConfigStore: require('./configStore'),
  DataStore: require('./dataStore')
};

const AuthActions = Reflux.createActions([
  'signinSuccess',
  'signinError',
  'signout',
  'registerSuccess',
  'registerError',
  'signinWithoutRole',
  'sendEmailVerification',
  'submitUserPhone',
  'submitUserPhoneSuccess',
  'submitUserPhoneError',
  'submitVerificationCode',
  'submitVerificationCodeSuccess',
  'submitVerificationCodeError'
]);

const strDateToTimestamp = dateStr => Math.round(new Date(dateStr) / 1000);

const trackUserData = user => {
  if (!isProduction) console.info('\nCHAT INIT', user);

  const createdAt = user.createdAt ? strDateToTimestamp(user.createdAt) : null;

  crispUpdateUser(user._id);
};

const setConfigToDataManager = ({ token }) => {
  DataManager.setConfiguration({
    headers: { auth: token }
  });
};

const setActiveSocialNetworkAccount = accountId => {
  if (!accountId) return;
  API.auto(
    'getAccount',
    { _id: accountId },
    {
      callback: (errGetAccount, resGetAccount) => {
        if (errGetAccount) return console.error(errGetAccount);

        Actions.ConfigStore.fastUpdate({
          ActiveSocialNetwork: get(resGetAccount, [
            'data',
            'socialNetworkAccounts',
            0
          ])
        });
      }
    }
  );
};

/* ====== Action Hooks ====== */
/**
* Signin Success. Subscribe to DataManager, updates ConfigStore and DataStore.
* @require Actions.ConfigStore
* @require Actions.DataStore
* @require DataManager
* @require API
* @require Helpers
* @params args <required>
{
token: <required> [String]
}
*/
AuthActions.signinSuccess.preEmit = ({ token, user, account }) => {
  if (!token || !user) return Actions.App.gotoPage('/login');
  const userId = user._id;
  const accountId = account._id;

  if ($crisp) $crisp.push(['do', 'chat:hide']);
  Actions.ConfigStore.update({ Auth: token });

  setConfigToDataManager({ token });

  userTracking.identify(userId);

  Actions.ConfigStore.fastUpdate({
    AuthenticatedAccountId: accountId,
    ActiveAccountId: accountId,
    ActiveUserId: userId,
    AuthenticatedUserId: userId
  });

  setActiveSocialNetworkAccount(accountId, user);

  Actions.DataStore.update({
    modelName: 'User',
    id: userId,
    data: user
  });

  trackUserData(user);
  userTracking.track('ANY - Start Session', { role: user.role });
  acquisitionTracking.clearData();

  if (user && user.createdAt) {
    const createdAt = moment(user.createdAt);
    const elapsedHours = moment().diff(createdAt, 'hours', true);

    if (
      elapsedHours >= 24 &&
      !includes(user.verifiedEmails, user.email.toLowerCase())
    ) {
      Actions.App.gotoPage('/verify-email-reminder');
      return false;
    }
  }
};

/**
* Handler the user signout. Delete the current user session.
* @require Helpers
* @require Actions.App
* @params args <required>
{
token: <required> [String]
}
*/
AuthActions.signout.preEmit = () => {
  setConfigToDataManager({ token: null });
  Actions.App.showLoader();
  Helpers.destroyUserSession();
};

AuthActions.registerSuccess.preEmit = args => {
  trackCampaignsData(acquisitionTracking.getData());
  userTracking.trackRegisterAttempt(args.user);
  AuthActions.signinSuccess(args);
};

AuthActions.sendEmailVerification.preEmit = email => {
  const data = { email };

  API.sendEmailVerification(data, (err, res) => {
    if (err) return console.error(err, res);

    Actions.App.displayAlert({
      type: 'success',
      message: i18n.get('VERIFY_EMAIL_REMAINDER_SUCCESS_ALERT_DESCRIPTION')
    });
  });
};

AuthActions.submitUserPhone = (userId, phoneNumber) => {
  const requestPayload = { id: userId, phone: phoneNumber };

  API.sendPhoneVericationCode(requestPayload, (error, data) => {
    if (error) return AuthActions.submitUserPhoneError(error);
    if (data && !data.code) return AuthActions.submitUserPhoneSuccess(data);

    if (data && data.code === 'BAD_REQUEST') {
      AuthActions.submitUserPhoneError();

      return Actions.App.displayAlert({
        type: 'error',
        message:
          'El celular especificado no es válido. Por favor verificalo e intenta nuevamente.'
      });
    }
  });
};

AuthActions.submitVerificationCode = (userId, code) => {
  const requestPayload = { id: userId, code };

  API.verifyPhone(requestPayload, (error, data) => {
    if (error) return AuthActions.submitVerificationCodeError(error);
    if (data && !data.code) {
      return AuthActions.submitVerificationCodeSuccess(data);
    }

    if (data && data.code === 'OBJECT_NOT_FOUND') {
      AuthActions.submitVerificationCodeError();

      return Actions.App.displayAlert({
        type: 'error',
        message: 'El código de verificación es inválido o no existe.'
      });
    }
  });
};

module.exports = AuthActions;
