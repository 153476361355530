"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const shared_1 = require("redux/modules/shared");
const selectors_1 = require("modules/shared/selectors");
const PartnerMessageDialogUI_1 = require("./PartnerMessageDialogUI");
function mapStateToProps(state, { partnerId }) {
    return {
        partner: selectors_1.getPartner(state, partnerId)
    };
}
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({
        fetchPartnerInfo: shared_1.fetchPartnerById
    }, dispatch);
}
class PartnerMessageDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleContinue = () => {
            this.setState({ isOpen: false });
        };
        this.onFinally = () => {
            const { partner } = this.props;
            this.setState({
                isLoading: false,
                isOpen: !!partner
            });
        };
        this.state = {
            isLoading: !props.partner,
            isOpen: true
        };
    }
    UNSAFE_componentWillMount() {
        const { partner, partnerId, fetchPartnerInfo } = this.props;
        if (!partner && partnerId) {
            fetchPartnerInfo(partnerId).finally(this.onFinally);
        }
    }
    render() {
        const { partner } = this.props;
        const { isOpen, isLoading } = this.state;
        return (React.createElement(PartnerMessageDialogUI_1.default, { isLoading: isLoading, isOpen: isOpen, partner: partner, onContinue: this.handleContinue }));
    }
}
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(PartnerMessageDialog);
