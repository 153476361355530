"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
class UserLocaleSelector extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.onChange = (ev) => {
            return this.props.onLocaleChange(ev.target.value);
        };
        this.getLanguagesOptions = () => {
            return this.props.availableLanguages.reduce((result, lang) => {
                result[lang.code] = lang.name;
                return result;
            }, {});
        };
    }
    render() {
        const { isLoading, locale } = this.props;
        return (React.createElement(ui_library_1.Select, { disabled: isLoading, value: locale, options: this.getLanguagesOptions(), title: i18n.get('INF_SETTINGS_ACCOUNT_LANGUAGE_SELECTOR_TITLE'), onChange: this.onChange }));
    }
}
exports.default = UserLocaleSelector;
