"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const lodash_1 = require("lodash");
const i18n = require("i18n");
const API = require("../lib/api");
const link_1 = require("apps/shared/hocs/link");
const settings_account_content_readonly_1 = require("./settings-account-content-readonly");
const UserSettingsLocationPhoneReadonly_1 = require("modules/accounts/influencer/containers/UserSettingsLocationPhoneReadonly");
const components_1 = require("modules/shared/components");
const crispActions_1 = require("utils/crispActions");
const VerificationLabel_1 = require("modules/accounts/influencer/containers/VerificationLabel/VerificationLabel");
const DeleteInfluencerUser_1 = require("modules/accounts/influencer/containers/DeleteInfluencerUser");
const passwordRegex_1 = require("utils/passwordRegex");
const app_1 = require("../actions/app");
const MIN_LENGTH_FOR_PASSWORD = 6;
const PROFILE_VERIFICATION = '/account/verification';
class SettingsAccountReadOnly extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            emailInput: '',
            changingPassword: false,
            passwordToChange: '',
            currentPassword: '',
            showDelete: false,
            hoverCancel: false,
            password: '',
            errorMessage: null,
            validateTypes: {
                password: val => val.length >= MIN_LENGTH_FOR_PASSWORD,
                passwordToChange: (val, component) => val.length >= MIN_LENGTH_FOR_PASSWORD ||
                    !component.state.changingPassword,
                currentPassword: val => val.length >= MIN_LENGTH_FOR_PASSWORD
            }
        };
        this.settingsUserInfo = React.createRef();
        this.saveUserInfo = () => {
            this.settingsUserInfo.current.saveData();
        };
        this.onEmailChange = (event) => {
            this.setState({
                emailInput: event.target.value
            });
        };
        this.onDeleteSelect = () => {
            this.setState({
                showDelete: true
            });
        };
        this.onCancelDelete = () => {
            this.setState({
                showDelete: false,
                hoverCancel: false
            });
        };
        this.isValidPassword = (passwordToChange) => {
            return passwordRegex_1.default.test(passwordToChange);
        };
        this.onShowChangePassword = () => {
            this.setState({
                changingPassword: true
            });
        };
        this.onSavePassword = () => {
            const { emailInput, changingPassword, passwordToChange, currentPassword } = this.state;
            const data = {
                email: emailInput,
                currentPassword
            };
            this.setState({ validationActive: false, errorMessage: '' });
            if (changingPassword && !this.isValidPassword(passwordToChange)) {
                this.setState({
                    validationActive: true,
                    errorMessage: i18n.get('RESET_PASSWORD_INVALID_LENGTH')
                });
                return;
            }
            if (!this.props.validateProperty.call(this, 'currentPassword', currentPassword) ||
                !this.props.validateProperty.call(this, 'passwordToChange', passwordToChange)) {
                this.setState({
                    validationActive: true
                });
                return;
            }
            if (changingPassword && passwordToChange !== '') {
                data.password = passwordToChange;
            }
            API.updatePasswordUser(data, (err, res) => {
                if (res.code) {
                    app_1.displayAlert({
                        type: 'error',
                        message: res.message
                    });
                    return;
                }
                app_1.displayAlert({
                    type: 'success',
                    message: i18n.get('INF_SETTINGS_ACCOUNT_SUCCESS_ALERT')
                });
            });
        };
        this.renderConfig = () => {
            const { user } = this.props;
            const { emailInput, changingPassword, errorMessage } = this.state;
            const lockDataMessage = (React.createElement("p", null,
                i18n.get('INF_SETTINGS_ACCOUNT_LOCK_DATA_MESSAGE_PART_1'),
                ' ',
                React.createElement("a", { href: '#', onClick: crispActions_1.openChat }, i18n.get('INF_SETTINGS_ACCOUNT_LOCK_DATA_MESSAGE_LINK_TEXT')),
                ' ',
                i18n.get('INF_SETTINGS_ACCOUNT_LOCK_DATA_MESSAGE_PART_2')));
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'vf-row padding-top-2x' },
                    React.createElement("div", { className: 'col-xs-12 col-md-offset-1' },
                        React.createElement("h2", null, i18n.get('INF_SETTINGS_ACCOUNT_PERSONAL_TITLE')),
                        " T\u00EDtulo")),
                React.createElement("div", { className: 'vf-row reverse-sm padding-top-1x' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-3' },
                        React.createElement("div", { className: 'vf-descriptor' })),
                    React.createElement("div", { className: 'col-xs-12 show-xs padding-top-2x' }),
                    React.createElement("div", { className: 'col-xs-12 col-sm-8' },
                        React.createElement(settings_account_content_readonly_1.default, { ref: this.settingsUserInfo, user: user }))),
                React.createElement("div", { className: 'vf-row padding-top-2x' },
                    React.createElement("div", { className: 'col-xs-12 col-md-offset-1' },
                        React.createElement("h2", null, i18n.get('INF_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_TITLE')))),
                React.createElement("div", { className: 'vf-row reverse-sm padding-top-1x' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-3' },
                        React.createElement("div", { className: 'vf-descriptor' })),
                    React.createElement("div", { className: 'col-xs-12 show-xs padding-top-2x' }),
                    React.createElement("div", { className: 'col-xs-12 col-sm-8' },
                        React.createElement("div", { className: 'vf-row' },
                            React.createElement("div", { className: 'col-xs-12 col-md-offset-4 col-md-8' },
                                React.createElement(UserSettingsLocationPhoneReadonly_1.default, { user: user }))))),
                React.createElement("div", { className: 'vf-row reverse-sm padding-top-1x' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-3' },
                        React.createElement("div", { className: 'vf-descriptor' })),
                    React.createElement("div", { className: 'col-xs-12 show-xs padding-top-2x' }),
                    React.createElement("div", { className: 'col-xs-12 col-sm-8' },
                        React.createElement("div", { className: 'vf-row' },
                            React.createElement("div", { className: 'col-xs-12 col-md-offset-4 col-md-7' },
                                React.createElement(components_1.CustomizableAlert, { title: i18n.get('INF_SETTINGS_ACCOUNT_LOCK_DATA_MESSAGE_TITLE'), markupContent: lockDataMessage, imageType: 'warning' }))))),
                React.createElement("div", { className: 'vf-row padding-top-3x' },
                    React.createElement("div", { className: 'col-xs-12 col-md-offset-1' },
                        React.createElement("h2", null, i18n.get('INF_SETTINGS_ACCOUNT_EMAIL_AND_PASSWORD_TITLE')))),
                React.createElement("div", { className: 'vf-row reverse-sm padding-top-1x' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-3' },
                        React.createElement("div", { className: 'vf-descriptor' })),
                    React.createElement("div", { className: 'col-xs-12 show-xs padding-top-2x' }),
                    React.createElement("div", { className: 'col-xs-12 col-sm-8' },
                        React.createElement("div", { className: 'vf-row' },
                            React.createElement("div", { className: 'col-xs-12 col-md-offset-4 col-md-5' },
                                React.createElement("div", { className: 'padding-nonlateral' },
                                    React.createElement("label", { className: 'vf-descriptor' }, i18n.get('INF_SETTINGS_ACCOUNT_EMAIL_LABEL')),
                                    React.createElement("div", null,
                                        React.createElement(ui_library_1.Input, { value: emailInput, onChange: this.onEmailChange, "data-uitest": 'email-input' }))),
                                React.createElement("div", { className: 'padding-top-1x' },
                                    React.createElement("label", { className: 'vf-descriptor vf-block' }, i18n.get('INF_SETTINGS_ACCOUNT_PASSWORD_LABEL')),
                                    changingPassword ? (React.createElement(ui_library_1.Input, Object.assign({ "data-uitest": 'password-input', type: 'password', placeholder: i18n.get('INF_SETTINGS_ACCOUNT_PASSWORD_PLACEHOLDER') }, this.props.linkedValidation.call(this, 'passwordToChange')))) : (React.createElement(ui_library_1.Button, { size: 'sm', onClick: this.onShowChangePassword, "data-uitest": 'change-password-button' }, i18n.get('INF_SETTINGS_ACCOUNT_CHANGE_PASSWORD_LABEL'))),
                                    errorMessage && (React.createElement("div", null,
                                        React.createElement("span", { className: 'vf-text-danger' }, errorMessage)))),
                                React.createElement("div", { className: 'padding-top-1x' },
                                    React.createElement("label", { className: 'vf-descriptor' }, i18n.get('INF_SETTINGS_ACCOUNT_CURRENT_PASSWORD_LABEL')),
                                    React.createElement(ui_library_1.Input, Object.assign({ "data-uitest": 'current-password-input', type: 'password', placeholder: i18n.get('INF_SETTINGS_ACCOUNT_CURRENT_PASSWORD_LABEL') }, this.props.linkedValidation.call(this, 'currentPassword')))),
                                React.createElement("div", { className: 'padding-top-3x' },
                                    React.createElement(ui_library_1.Button, { color: 'primary', onClick: this.onSavePassword, "data-uitest": 'save-changes-button' }, i18n.get('INF_SETTINGS_ACCOUNT_SAVE_BUTTON'))))))),
                React.createElement("div", { className: 'vf-row reverse-sm padding-top-3x' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-3' }),
                    React.createElement("div", { className: 'col-xs-12 col-sm-8' },
                        React.createElement("div", { className: 'vf-row' },
                            React.createElement("div", { className: 'col-xs-12 col-md-offset-4 col-md-5' },
                                React.createElement("a", { onClick: this.onDeleteSelect, "data-uitest": 'delete-account-link' }, i18n.get('INF_SETTINGS_ACCOUNT_DELETE_BUTTON'))))))));
        };
    }
    UNSAFE_componentWillMount() {
        API.getUserMe();
        const email = lodash_1.get(this.props.user, 'email');
        this.setState({ emailInput: email });
    }
    render() {
        const { account } = this.props;
        const { user, changePage, startCurrentUserVerificationRequest } = this.props;
        const { showDelete } = this.state;
        const content = showDelete ? (React.createElement(DeleteInfluencerUser_1.default, { account: account, onCancelDelete: this.onCancelDelete })) : (this.renderConfig());
        return (React.createElement("div", { className: 'vf-wrapper' },
            React.createElement(VerificationLabel_1.default, { user: user, onVerify: () => {
                    startCurrentUserVerificationRequest();
                    changePage(PROFILE_VERIFICATION);
                } }),
            content));
    }
}
;
exports.default = link_1.default(SettingsAccountReadOnly);
