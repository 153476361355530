import React from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18n from 'i18n';
import styles from './SupportLinks.less';
import { changeLanguage } from 'redux/modules/app';

function SupportLinks({
  noPadding,
  currentLanguage,
  availableLanguages,
  changeLanguage: changeLanguageAction
}) {
  const languages = availableLanguages.map(locale => {
    const className = classNames('padding-horizontal-Hx', styles.language, {
      [styles.selected]: locale.code === currentLanguage
    });

    return (
      <div
        key={locale.code}
        className={className}
        onClick={() => changeLanguageAction(locale.code)}
      >
        {locale.code.toUpperCase()}
      </div>
    );
  });

  return (
    <div
      className={classNames(`flex start-xs ${styles.container}`, {
        [styles['container--no-padding']]: noPadding
      })}
    >
      <div className='hide-xs'>
        <div className='flex padding-right-3x width80'>
          <a
            target='_blank'
            rel='noreferrer'
            href={i18n.get('LOGIN_SUPPORT_LINKS_HELP_URL')}
          >
            {i18n.get('LOGIN_SUPPORT_LINKS_HELP')}
          </a>

          <a target='_blank' rel='noreferrer' href='https://voxfeed.com/terms'>
            {i18n.get('LOGIN_SUPPORT_LINKS_TOS')}
          </a>

          <a
            target='_blank'
            rel='noreferrer'
            href='https://voxfeed.com/privacy'
          >
            {i18n.get('LOGIN_SUPPORT_LINKS_PRIVACY')}
          </a>

          <a
            target='_blank'
            rel='noreferrer'
            href='https://voxfeed.com/contact'
          >
            {i18n.get('LOGIN_SUPPORT_LINKS_CONTACT')}
          </a>
        </div>
      </div>

      <div className='flex'>{!!availableLanguages.length && languages}</div>
    </div>
  );
}

SupportLinks.propTypes = {
  noPadding: PropTypes.bool,
  changeLanguage: PropTypes.func,
  currentLanguage: PropTypes.string,
  availableLanguages: PropTypes.array
};

SupportLinks.defaultProps = {
  noPadding: false,
  currentLanguage: '',
  availableLanguages: [],
  changeLanguage: () => {}
};

function mapStateToProps({ app }) {
  return pick(app, ['currentLanguage', 'availableLanguages']);
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeLanguage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportLinks);
