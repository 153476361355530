"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const UnavailableSection = props => {
    const { size = 'vf-md-6', className = '', headerIcon = 'icon-disappointed-face', headerText = '', contentText = null, footerText = null, buttons = null, beforeHeader, } = props;
    const classes = 'vf-unavailable-section vf-wrapper vf-text-center flex-1 flex-column flex-stretch ' +
        className;
    return (React.createElement("div", { className: classes },
        React.createElement("div", { className: 'vf-row' },
            React.createElement("div", { className: 'col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8' },
                React.createElement("div", null, beforeHeader),
                React.createElement("div", { className: 'vf-unavailable-section-header' },
                    React.createElement("i", { className: 'vf-icon ' + headerIcon }),
                    React.createElement("h2", { className: 'vf-font-bold' }, headerText)),
                React.createElement("div", { className: 'vf-unavailable-section-data' },
                    React.createElement("div", { className: 'margin-top-1x' }, contentText)),
                React.createElement("div", { className: 'vf-unavailable-section-footer' }, footerText),
                React.createElement("div", { className: 'vf-unavailable-section-buttons' }, buttons)),
            React.createElement("div", { className: 'flex-1 vf-unavailable-section-content ' + size }))));
};
exports.default = UnavailableSection;
