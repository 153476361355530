import axios from 'axios';
import { get, map, isEmpty } from 'lodash';

export { fetchScoreDetailsBySocialAccountId } from './gamification';
export {
  setSocialAccountSubtype,
  setSocialAccountInterests,
  setSocialAccountTags,
  setSocialAccountSensitiveContent,
  getSocialAccountConfiguration,
  setSocialAccountPostPrice,
  getInterestsList
} from './socialAccountConfiguration';

export function link({ influencerId, ...data }) {
  return axios.post(
    `/accounts/${influencerId}/social-network-accounts/link`,
    data
  );
}

export function unlinkSocialAccount(id) {
  return axios.post(`/social-network-accounts/${id}/unlink`);
}

export function searchTag(tagInput, canParticipate) {
  const tag = encodeURIComponent(tagInput);
  const params = {
    tag,
    canParticipate
  };
  return axios.get('/social-network-accounts/tags', { params });
}

export function fetchStats(id) {
  return axios.get(`social-network-accounts/${id}/stats`);
}

export function fetchRelatedAccounts(id) {
  return axios.get(`/social-network-accounts/${id}/related-social-accounts`);
}

export function fetchPublishedPosts({ socialAccountId, params }) {
  return axios.get(
    `/social-network-accounts/${socialAccountId}/published-posts`,
    { params }
  );
}

export function fetchAudienceStats(id) {
  return axios.get(`/social-network-accounts/${id}/audience-stats`);
}

export function fetchByIds(args = {}) {
  if (isEmpty(args._id)) return Promise.resolve();
  return axios.get('/social_network_accounts', { params: args });
}

export function fetchSocialAccountsAdmin(params) {
  return axios.get('/admin/social_network_accounts', { params });
}

export function fetchBanDetailsById(id) {
  return axios.get(`/social-network-accounts/${id}/ban-details`);
}

export function banByInfluencerId(id, data) {
  return axios.post(`/influencers/${id}/social-network-accounts/ban`, data);
}

export function banById(id, data) {
  return axios.post(`/social-network-accounts/${id}/ban`, data);
}

export function unbanById(id, data) {
  return axios.post(`/social-network-accounts/${id}/unban`, data);
}

export function fetchByQuery(data) {
  const parsedData = {};
  Object.keys(data).reduce((prev, dataKey) => {
    prev[encodeURIComponent(dataKey)] = data[dataKey];
    return prev;
  }, parsedData);
  parsedData.removeInstagramPersonal = true;
  return axios.get('/social_network_accounts/', {
    params: parsedData,
    paramsSerializer: params =>
      map(params, (el, key) => `${key}=${el}`).join('&')
  });
}

export function fetchWithInvites(data) {
  const { campaignId, ...others } = data;
  const parsedData = {};
  Object.keys(others).reduce((prev, dataKey) => {
    prev[encodeURIComponent(dataKey)] = data[dataKey];
    return prev;
  }, parsedData);
  return axios.get(`/campaigns/${campaignId}/social-network-accounts/search`, {
    params: parsedData,
    paramsSerializer: params =>
      map(params, (el, key) => `${key}=${el}`).join('&')
  });
}

export function fetchTimeline(id) {
  return axios.get(`/social-network-accounts/${id}/timeline`);
}

export function addToFavorites({ brandId, socialAccountId }) {
  return axios.post(`/brands/${brandId}/favorites`, {
    socialNetworkAccountId: socialAccountId
  });
}

export function removeFromFavorites({ brandId, socialAccountId }) {
  return axios.delete(`/brands/${brandId}/favorites/${socialAccountId}`);
}

export function block({ brandId, socialAccountId }) {
  return axios.post(
    `/brands/${brandId}/social-network-accounts/${socialAccountId}/block`
  );
}

export function unblock({ brandId, socialAccountId }) {
  return axios.post(
    `/brands/${brandId}/social-network-accounts/${socialAccountId}/unblock`
  );
}

export function searchAudienceKeywords(params) {
  const query = get(params, 'q');
  if (!query) return Promise.resolve({});

  return axios.get('/social-network-accounts/audience-keywords', { params });
}

export function getCrispUrl({ id }) {
  return axios.get(`/social_network_accounts/${id}/crisp_url`);
}

export function updateSocialNetworkReach(data) {
  const { _id, reach, image } = data;
  return axios.put(`/social_network_accounts/${_id}/reach`, { reach, image });
}
