import React from 'react';
import PropTypes from 'prop-types';
import { isString, head } from 'lodash';
import styles from './DefaultEntityImage.less';

function DefaultEntityImage({ entityName }) {
  const content = isString(entityName) ? head(entityName).toUpperCase() : null;
  return <div className={`${styles.container} vf-bg-gradient`}>{content}</div>;
}

DefaultEntityImage.propTypes = {
  entityName: PropTypes.string
};

export default DefaultEntityImage;
