"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const appActions = require("redux/modules/shared");
const PartnerLoginUI_1 = require("./PartnerLoginUI/PartnerLoginUI");
const emailValidator_1 = require("apps/shared/hocs/emailValidator");
const errorStateHandler_1 = require("apps/shared/hocs/errorStateHandler");
const lodash_1 = require("lodash");
const react_redux_1 = require("react-redux");
const selectors_1 = require("modules/shared/selectors");
const redux_1 = require("redux");
class PartnerLogin extends React.Component {
    constructor() {
        super(...arguments);
        this.validateForm = form => {
            const { validateEmail } = this.props;
            return {
                email: validateEmail(form.email)
                    ? null
                    : i18n.get('LOGIN_LOGIN_ERROR_INVALID_EMAIL'),
                password: null
            };
        };
        this.handleInputBlur = (name, value) => {
            const { handleInputValidation } = this.props;
            const params = {
                name,
                value,
                validationFunction: this.validateForm
            };
            handleInputValidation(params);
        };
        this.handleSubmit = (form) => {
            const { currentLanguage } = this.props;
            this.handleLogin(Object.assign(Object.assign({}, form), { locale: currentLanguage, openLoader: false }));
        };
        this.handleLogin = form => {
            const { loginRequest, onSetErrors } = this.props;
            const errors = this.validateForm(form);
            const isValid = lodash_1.every(errors, val => val === null);
            if (!isValid)
                return onSetErrors(errors);
            loginRequest(form);
        };
    }
    render() {
        const { errors } = this.props;
        return (React.createElement(PartnerLoginUI_1.default, { errors: errors, onInputBlur: this.handleInputBlur, onSubmit: this.handleSubmit }));
    }
}
const mapStateToProps = state => ({
    currentLanguage: selectors_1.getAppInfo(state, 'currentLanguage')
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    loginRequest: appActions.loginRequest
}, dispatch);
const WithErrors = errorStateHandler_1.default(PartnerLogin);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(emailValidator_1.default(WithErrors));
