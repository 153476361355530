import cookie from '../utils/cookies';
import queryParser from 'querystring';
import getValidLocale from './get-valid-locale';

/**
 * Get the locale from the URL query.
 * Example: if URL=http://localhost?lang=en-US, then set it 'lang'
 *
 * @param {string} urlLocalKey Key to determine locale.
 * @returns {string} Determined locale.
 */
function getLocaleFromURL(urlLocaleKey) {
  if (typeof location === 'undefined' || typeof queryParser === 'undefined') {
    return undefined;
  }

  if (urlLocaleKey) {
    const query = location.search.split('?');
    if (query.length >= 2) {
      const params = queryParser.parse(query[1]);
      const urlLocale = params && params[urlLocaleKey];
      return getValidLocale(urlLocale);
    }
  }

  return undefined;
}


/**
 * Get the locale from the cookies.
 * Example: if cookie=lang:en-US, then set it 'lang'
 *
 * @param {*} cookieLocaleKey Key to determine locale.
 * @returns {string} Determined locale.
 */
function getLocaleFromCookie(cookieLocaleKey) {
  if (cookieLocaleKey && typeof document !== 'undefined') {
    const cookieLocale = cookie.getCookie(cookieLocaleKey);
    return getValidLocale(cookieLocale);
  }

  return undefined;
}


/**
 * Get the locale from the browser.
 * Example: if cookie=lang:en-US, then set it 'lang'
 *
 * @returns {string} Determined locale.
 */
function getLocaleFromBrowser() {
  if (typeof navigator === 'undefined') return undefined;

  const lang = navigator.language || navigator.userLanguage;
  const parentLocale = getParentLocale(lang);
  return getValidLocale(parentLocale);
}

function getParentLocale(lang) {
  if (!lang) return undefined;

  const [locale] = lang.split('-');
  return locale;
}

/**
 * Helper: determine user's locale via URL, cookie, and browser's language. Defaults to 'en'.
 *
 * @param {string} options.urlLocaleKey URL's query Key to determine locale.
 * @param {string} options.cookieLocaleKey Cookie's Key to determine locale.
 * @returns {string} Determined locale such as 'en-US'.
 */
export default function determineLocale(options = {}) {
  const {urlLocalKey, cookieLocaleKey, defaultLocale} = options;
  return (
    getLocaleFromURL(urlLocalKey) ||
    getLocaleFromCookie(cookieLocaleKey) ||
    getLocaleFromBrowser() ||
    defaultLocale
  );
}
