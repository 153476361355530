"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const ProductsPaymentAddressInputUI_1 = require("./ProductsPaymentAddressInputUI");
class ProductsPaymentAddressInput extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            isPristine: true,
            place: {
                address: ''
            }
        };
        this.handleAddressChange = (place) => {
            this.setState({
                isPristine: false,
                place
            });
        };
        this.handleAddressSave = () => {
            const { onSave } = this.props;
            const { place } = this.state;
            onSave(place);
        };
        this.isValid = () => !lodash_1.chain(this.state).get('place.coordinates').isEmpty().value();
    }
    render() {
        const { className, error, lite, isInputEmpty } = this.props;
        const { isPristine, place } = this.state;
        if (lite && lodash_1.chain(this.state).get('place.address').isEmpty().value()) {
            isInputEmpty();
        }
        return (React.createElement(ProductsPaymentAddressInputUI_1.default, { address: place.address, className: className, error: error, isPristine: isPristine, isValid: this.isValid(), onChange: this.handleAddressChange, onSave: this.handleAddressSave, lite: lite }));
    }
}
ProductsPaymentAddressInput.defaultProps = {
    className: '',
    lite: false
};
exports.default = ProductsPaymentAddressInput;
