import { get, uniqueId, isEmpty } from 'lodash';
import { sendRequest, sendRequestSuccess, sendRequestFail } from '../app';
import { updateData, removeData } from '../entities';
import { buildAPIRequest } from '../helpers';

import { brands } from '../../../services/voxfeed';
import downloadFileContent from '../../../utils/downloadFileContent';

const initialState = {};

export default function reducer(state = initialState, { type }) {
  switch (type) {
    default:
      return state;
  }
}

export const createBrand = params =>
  buildAPIRequest({
    params,
    id: 'createBrand',
    method: brands.createBrand
  });

export const updateBrand = params =>
  buildAPIRequest({
    params,
    id: 'updateBrand',
    method: brands.updateBrand
  });

export const getBrands = params =>
  buildAPIRequest({
    params,
    id: 'getBrands',
    method: brands.getAdvertiserBrands
  });

export const fetchBrandCampaigns = ({ replace, ...params }) =>
  buildAPIRequest({
    params,
    replace,
    id: 'fetchBrandCampaigns',
    method: brands.fetchBrandCampaigns
  });

export const fetchBrandUsers = params =>
  buildAPIRequest({
    params,
    id: 'fetchBrandUsers',
    method: brands.getBrandUsers,
    replace: true,
    callback: ({ data, dispatch }) => {
      const invitedUsers = data.data.reduce((result, user) => {
        if (user.id) return result;
        result.push({ ...user, id: uniqueId('pending') });
        return result;
      }, []);

      if (isEmpty(invitedUsers)) return;
      dispatch(updateData({ ...data, data: invitedUsers }));
    }
  });

export const inviteUser = params =>
  buildAPIRequest({
    params,
    id: 'inviteUser',
    method: brands.inviteUser
  });

export const leave = params =>
  buildAPIRequest({
    params,
    id: 'leaveBrand',
    method: brands.leave,
    callback: ({ dispatch }) => {
      dispatch(removeData({ modelName: 'Account', id: params.brandId }));
    }
  });

export const acceptInvitation = params =>
  buildAPIRequest({
    params,
    id: `acceptBrandInvitation_${params.brandId}`,
    method: brands.acceptInvitation,
    callback: ({ dispatch }) => {
      dispatch(
        updateData({
          modelName: 'Account',
          id: params.brandId,
          data: { status: 'active' }
        })
      );
    }
  });

export const rejectInvitation = params =>
  buildAPIRequest({
    params,
    id: `rejectBrandInvitation_${params.brandId}`,
    method: brands.rejectInvitation,
    callback: ({ dispatch }) => {
      dispatch(removeData({ modelName: 'Account', id: params.brandId }));
    }
  });

export function getCampaign({ campaignId }) {
  const REQUEST_ID = 'getCampaign';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return brands
      .getCampaign({ campaignId })
      .then(({ data }) => {
        dispatch(updateData(data));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(res =>
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            data: get(res, 'response', {})
          })
        )
      );
  };
}

export function removeUser({ brandId, userId }) {
  const REQUEST_ID = `removeBrandUser_${userId}`;

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return brands
      .removeUser({ brandId, userId })
      .then(() => {
        dispatch(removeData({ modelName: 'BrandUser', id: userId }));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(res =>
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            response: get(res, 'response', {})
          })
        )
      );
  };
}

export const cancelInvitation = params =>
  buildAPIRequest({
    params,
    id: 'cancelBrandInvitation',
    method: brands.cancelInvitation,
    callback: ({ dispatch }) => {
      dispatch(
        updateData({
          modelName: 'BrandUser',
          id: params.brandUserId,
          data: { status: 'deleted' }
        })
      );
    }
  });

export const searchBrandsAdmin = (params, replace) =>
  buildAPIRequest({
    params,
    replace,
    id: 'searchBrandsAdminRequest',
    method: brands.getBrands
  });

export function setUserPermissions({ brandId, userId, permissions }) {
  const REQUEST_ID = 'setBrandUserPermissions';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return brands
      .setUserPermissions({ brandId, userId, permissions })
      .then(({ data: updatedUser }) => {
        dispatch(updateData(updatedUser));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(res =>
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            response: get(res, 'response', {})
          })
        )
      );
  };
}

export const transferOwnership = ({ brand, isOwner, ...data }) =>
  buildAPIRequest({
    id: 'transferBrandOwnership',
    method: brands.transferOwnership,
    params: { brandId: brand.id, ...data },
    callback: ({ dispatch }) => {
      dispatch(fetchBrandUsers({ brandId: brand.id }));
      dispatch(
        updateData({
          modelName: 'Account',
          id: brand.id,
          data: {
            user: {
              ...brand.user,
              isOwner
            }
          }
        })
      );
    }
  });

export const deleteBrand = params =>
  buildAPIRequest({
    params,
    id: 'deleteBrand',
    method: brands.deleteBrand
  });

export function updateBrandRebateMargin({ brandId, data }) {
  const REQUEST_ID = 'updateBrandRebateMargin';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return brands
      .updateBrandRebateMargin({ brandId, data })
      .then(({ data: updatedBrand }) => {
        dispatch(updateData(updatedBrand));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(res =>
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            response: get(res, 'response', {})
          })
        )
      );
  };
}

export function getCreditHistoryReport(brandId) {
  const REQUEST_ID = 'getBrandCreditHistoryReport';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return brands
      .getCreditHistoryReport({ brandId })
      .then(({ data }) => {
        downloadFileContent({
          fileName: `credit-history-${brandId}-${Date.now()}.csv`,
          content: data
        });
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(res =>
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            response: get(res, 'response', {})
          })
        )
      );
  };
}

export function createProduct({ brandId, data }) {
  const REQUEST_ID = 'createProduct';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return brands
      .createProduct({ brandId, data })
      .then(res => {
        dispatch(updateData(res.data));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(err => {
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            response: get(err, 'response', {})
          })
        );
        return Promise.reject(err);
      });
  };
}

export function updateProduct({ brandId, productId, data }) {
  const REQUEST_ID = 'updateProduct';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return brands
      .updateProduct({ brandId, productId, data })
      .then(res => {
        dispatch(updateData(res.data));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(err => {
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            response: get(err, 'response', {})
          })
        );
        return Promise.reject(err);
      });
  };
}

export const fetchProducts = params =>
  buildAPIRequest({
    params,
    id: `fetchProducts_${params.brandId}`,
    method: brands.fetchProducts
  });

export function deleteProduct({ brandId, productId }) {
  const REQUEST_ID = 'deleteProduct';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return brands
      .deleteProduct(brandId, productId)
      .then(res => {
        dispatch(removeData(res.data));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(err => {
        dispatch(
          sendRequestFail({
            id: REQUEST_ID,
            response: get(err, 'response', {})
          })
        );
        return Promise.reject(err);
      });
  };
}

export const fetchBrandTeams = params =>
  buildAPIRequest({
    params,
    id: `fetchBrandTeams_${params.id}`,
    method: brands.getTeams
  });

export const addTeamToBrand = params =>
  buildAPIRequest({
    params,
    id: `addTeamToBrand_${params.brandId}`,
    method: brands.addTeam
  });

export const updateBrandTeam = params =>
  buildAPIRequest({
    params,
    id: `updateBrandTeam_${params.brandId}`,
    method: brands.updateTeam
  });

export const removeBrandTeam = params =>
  buildAPIRequest({
    params,
    id: `removeBrandTeam_${params.brandId}`,
    method: brands.removeTeam
  });

export const transferCredit = params =>
  buildAPIRequest({
    params,
    id: `transferCredit_${params.id}`,
    method: brands.transferCredit
  });

export const getTransactions = ({ replace, ...params }) =>
  buildAPIRequest({
    params,
    replace,
    id: `getTransactions${params.id}`,
    method: brands.getTransactions
  });

export const getPaymentsInRecepit = params =>
  buildAPIRequest({
    params,
    id: `getPaymentsInRecepit_${params.id}`,
    method: brands.getPaymentsInRecepit,
    callback: ({ dispatch, data }) => {
      dispatch(
        updateData({
          modelName: 'BrandTransaction',
          id: params.transactionId,
          data: {
            receipt: data.url
          }
        })
      );
    }
  });
