import { buildAPIRequest } from '../helpers';
import { historyPostLite } from '../../../services/voxfeed';

// eslint-disable-next-line import/prefer-default-export
export const getHistoryCampaignLite = params =>
  buildAPIRequest({
    params,
    id: 'participations',
    method: historyPostLite.getHistoryCampaignLite
  });
