"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./InfoHelper.less");
const popover_1 = require("modules/legacy/js/components/popover");
class InfoHelper extends React.Component {
    render() {
        const { body, position, style, className } = this.props;
        const classes = classnames_1.default(className, styles.circle, 'flex flex-justify-center');
        return (React.createElement(popover_1.default, { className: 'popover-dark', preferPlace: position, body: body },
            React.createElement("div", { className: classes, style: Object.assign({ paddingRight: '1px' }, style) },
                React.createElement("i", { className: 'vf-icon icon-question icon-fix' }))));
    }
}
exports.default = InfoHelper;
