"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogHeader = void 0;
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./Dialog.less");
const ReactCSSTransitionGroup = require("react-transition-group/CSSTransitionGroup");
const react_portal_1 = require("react-portal");
const components_1 = require("modules/shared/components");
class Dialog extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.handleOverlayClick = () => {
            const { onClose } = this.props;
            if (onClose)
                onClose();
        };
        this.handleContainerClick = event => {
            event.stopPropagation();
        };
    }
    render() {
        const { isOpen, className, style, children } = this.props;
        const overlayClass = classnames_1.default(styles.overlay, 'flex flex-justify-center');
        const containerClass = classnames_1.default(styles.container, className, 'vf-animation-container');
        return (React.createElement(ReactCSSTransitionGroup, { component: components_1.FirstChild, transitionName: 'vf-animation-dialog', transitionEnterTimeout: 500, transitionLeaveTimeout: 500, transitionAppearTimeout: 500, transitionAppear: true }, isOpen && (React.createElement(react_portal_1.Portal, null,
            React.createElement("div", { className: overlayClass, onClick: this.handleOverlayClick },
                React.createElement("div", { onClick: this.handleContainerClick, className: containerClass, style: Object.assign({}, style) }, children))))));
    }
}
exports.default = Dialog;
exports.DialogHeader = ({ children, style, className, onClose }) => (React.createElement("div", { className: classnames_1.default(styles.header, 'flex', className), style: style },
    children,
    onClose && (React.createElement("i", { className: 'vf-icon icon-close icon-fix', style: { marginLeft: 'auto' }, onClick: onClose }))));
