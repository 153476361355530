import axios from 'axios';
import { get } from 'lodash';
import { sendRequestFail, sendRequestSuccess, sendRequest } from './app';
import { updateData, replaceData } from './entities';
import { addAlert } from './alerts';

const { CancelToken } = axios;

export function handleRequestFail({ id, response, dispatch }) {
  const responseData = get(response, 'response', {});
  return dispatch(sendRequestFail({ id, response: responseData }));
}

export function handleRequestSuccess({ id, response, dispatch }) {
  const responseData = get(response, 'response', response);
  return dispatch(sendRequestSuccess({ id, response: responseData }));
}

export function buildAPIRequest(args) {
  const {
    id,
    params,
    method,
    replace,
    isCancellable = false,
    callback,
    ignoreCodes = [],
    showLoader = true
  } = args;
  const skipRequestActions = !showLoader;

  return (dispatch, getState) => {
    const state = getState();
    const request = get(state, `app.requests.${id}`, {});
    const isLoading = get(request, 'status', '') === 'loading';
    let cancelSource = get(request, 'cancelSource');

    if (isLoading && isCancellable && cancelSource) {
      cancelSource.cancel('CANCELED');
      cancelSource = CancelToken.source();
    }
    if (!cancelSource) cancelSource = CancelToken.source();

    if (!skipRequestActions) dispatch(sendRequest(id, cancelSource));

    return method(params, cancelSource.token)
      .then(res => {
        const data = extractResponseData(res);
        const isEntityData = !!get(data, 'modelName');
        const resolve = () => {
          if (!skipRequestActions) {
            dispatch(sendRequestSuccess({ id, response: res }));
          }
          if (callback) callback({ dispatch, data, response: res });
          return Promise.resolve(data);
        };

        if (!isEntityData) return resolve();
        if (replace) dispatch(replaceData(data));
        else dispatch(updateData(data));
        return resolve();
      })
      .catch(res => {
        if (axios.isCancel(res)) return;

        const data = get(res, 'response.data', res);
        const { code, message } = data;
        handleRequestFail({ id, response: res, dispatch });

        if (!ignoreCodes.includes(code)) {
          dispatch(
            addAlert({
              type: 'error',
              message
            })
          );
        }

        return Promise.reject(data);
      });
  };
}

function extractResponseData(res) {
  if (!res) return res;
  const requiresExtraction = !get(res, 'modelName');
  if (!requiresExtraction) return res;
  return res.data || {};
}
