"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const _1 = require(".");
const components_1 = require("apps/shared/components");
const types_1 = require("modules/shared/types");
class RequiredElementsSection extends React.Component {
    constructor() {
        super(...arguments);
        this.getMediaText = () => {
            const { campaign, socialAccount, publicationType } = this.props;
            const socialRequirements = campaign[socialAccount.type][publicationType] || {};
            switch (socialRequirements.mediaRequiredType) {
                case 'any':
                    return i18n.get('INF_VOXEAR_ANY');
                case 'image':
                    return i18n.get('INF_VOXEAR_IMAGE');
                case 'video':
                    return i18n.get('INF_VOXEAR_VIDEO');
                default: {
                    if (socialAccount.type === 'instagram')
                        return i18n.get('INF_VOXEAR_ANY');
                    return i18n.get('INF_VOXEAR_NONE');
                }
            }
        };
    }
    render() {
        const { campaign, socialAccount, publicationType } = this.props;
        const socialRequirements = campaign[socialAccount.type][publicationType] || {};
        return (React.createElement(_1.SectionWithFooter, { footer: React.createElement(Footer, { socialRequirements: socialRequirements, endorsementDisclosure: campaign.endorsementDisclosure, mediaRequiredText: this.getMediaText() }) },
            React.createElement("div", { className: 'flex' },
                React.createElement(components_1.SocialBadge, { socialNetwork: socialAccount.type, publicationType: publicationType, size: 16, className: 'margin-right-Hx', style: { flexShrink: 0 } }),
                React.createElement("h3", { className: 'no-margin vf-bold' }, publicationType === types_1.PublicationType.STORY
                    ? i18n.get('ADV_CAMPAIGNS_WIZARD_INSTAGRAM_STORIES')
                    : i18n.get('ADV_CAMPAIGNS_WIZARD_INSTAGRAM_POSTS')))));
    }
}
exports.default = RequiredElementsSection;
const Footer = ({ socialRequirements, endorsementDisclosure, mediaRequiredText }) => (React.createElement("div", null,
    React.createElement("div", { className: 'flex flex-justify-space-between' },
        React.createElement("p", { style: { fontSize: '2.2rem' } }, i18n.get('INF_VOXEAR_MEDIA_LABEL')),
        React.createElement("span", { className: 'margin-left-Hx vf-bold' }, mediaRequiredText)),
    socialRequirements.hashtags && socialRequirements.hashtags.length > 0 && (React.createElement("div", { className: 'flex flex-justify-space-between margin-top-1x' },
        React.createElement("p", { style: { fontSize: '2.2rem' } }, i18n.get('INF_VOXEAR_HASHTAGS_LABEL')),
        React.createElement("div", { className: 'margin-left-Hx vf-bold' },
            socialRequirements.hashtags.map(tag => (React.createElement("span", { key: tag, className: 'vf-text-primary' },
                "#",
                tag,
                ' '))),
            endorsementDisclosure && (React.createElement("span", { className: 'vf-text-primary' },
                "#",
                endorsementDisclosure,
                " "))))),
    socialRequirements.mentions && socialRequirements.mentions.length > 0 && (React.createElement("div", { className: 'flex flex-justify-space-between margin-top-1x' },
        React.createElement("p", { style: { fontSize: '2.2rem' } }, i18n.get('INF_VOXEAR_MENTION_LABEL')),
        React.createElement("div", { className: 'margin-left-Hx vf-bold' }, socialRequirements.mentions.map(mention => (React.createElement("span", { key: mention, className: 'vf-text-primary' },
            "@",
            mention,
            ' ')))))),
    socialRequirements.links && socialRequirements.links.length > 0 && (React.createElement("div", { className: 'flex flex-justify-space-between margin-top-1x' },
        React.createElement("p", { style: { fontSize: '2.2rem' } }, i18n.get('INF_VOXEAR_LINK_LABEL')),
        React.createElement("span", { className: 'margin-left-Hx vf-bold' }, socialRequirements.links.map(link => (React.createElement("a", { key: link, target: '_blank', href: link, className: 'vf-text-primary' }, link))))))));
