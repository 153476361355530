"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const passwordValidator = (Component) => class PasswordValidator extends React.Component {
    constructor() {
        super(...arguments);
        this.isValid = (password) => {
            const reg = new RegExp('.{6,}');
            return reg.test(password);
        };
    }
    render() {
        return (React.createElement(Component, Object.assign({}, this.props, { validatePassword: this.isValid })));
    }
};
exports.default = passwordValidator;
