import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'ui-library';

import i18n from 'i18n';
import { leave } from 'redux/modules/brands';
import {
  hideModal,
  sendRequest,
  showAlert,
  changePage
} from 'redux/modules/app';

import { getRequest, getCurrentUser } from 'modules/shared/selectors';
import { BrandAvatar } from 'modules/shared/components';
import CustomDialog from 'apps/shared/components/CustomDialog/CustomDialog';

function mapStateToProps(state) {
  return {
    request: getRequest(state, 'leaveBrand'),
    user: getCurrentUser(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      sendRequest,
      showAlert,
      leave,
      changePage
    },
    dispatch
  );
}

const Confirmation = createClass({
  propTypes: {
    brand: PropTypes.object.isRequired,
    request: PropTypes.object,
    user: PropTypes.object,
    sendRequest: PropTypes.func,
    leave: PropTypes.func,
    showAlert: PropTypes.func,
    hideModal: PropTypes.func,
    changePage: PropTypes.func
  },

  onLeaveSuccess() {
    const { showAlert, hideModal, brand } = this.props;
    hideModal();
    window.location.reload();
    showAlert({
      type: 'success',
      message: i18n.get('ADV_BRANDS_BRAND_LEAVE_SUCCESS_ALERT_MESSAGE', {
        brandName: brand.name
      })
    });
  },

  onLeaveFail() {
    this.props.showAlert({
      type: 'error',
      message: i18n.get('ADV_BRANDS_BRAND_LEAVE_ERROR_ALERT_MESSAGE')
    });
  },

  leave() {
    const { user, leave, brand } = this.props;
    leave({ brandId: brand.id, userId: user._id })
      .then(this.onLeaveSuccess)
      .catch(this.onLeaveFail);
  },

  render() {
    const { brand, hideModal, request, isOpen, onClose } = this.props;
    const isLoading = request.status === 'loading';

    const content = (
      <div className='padding-2x'>
        <div className='flex width100 center-xs'>
          <BrandAvatar url={brand.image} name={brand.name} />
        </div>

        <h5 className='margin-top-2x vf-font-normal'>
          {i18n.getHTML('ADV_BRANDS_LEAVE_BRAND_CONFIRMATION_WARNING', {
            brandName: brand.name
          })}
          <p className='margin-top-Hx'>
            {i18n.get('ADV_BRANDS_WISH_TO_CONTINUE')}
          </p>
        </h5>

        <div className='flex width100 end-xs margin-top-3x'>
          <Button className='margin-right-Hx' onClick={hideModal}>
            {i18n.get('CANCEL')}
          </Button>

          <Button color='danger' disabled={isLoading} onClick={this.leave}>
            {isLoading
              ? i18n.get('ADV_BRANDS_LEAVE_BRAND_LEAVING')
              : i18n.get('ADV_BRANDS_LEAVE_BRAND_LEAVE')}
          </Button>
        </div>
      </div>
    );

    return (
      <CustomDialog
        size='sm'
        isOpen={isOpen}
        header={i18n.get('ADV_BRANDS_BRAND_CARD_LEAVE_CONFIRMATION_HEADER')}
        content={content}
        onClose={onClose || hideModal}
      />
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
