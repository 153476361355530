"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const ui_library_1 = require("ui-library");
const styles = require("./EasyLexContract.less");
const app_1 = require("config/app");
const EASYLEX_REGISTER_LINK = 'https://easylex.com/user/register';
const EasyLexContract = ({ nextHandler, previousHandler }) => {
    const [clickContract, setClickContract] = React.useState(false);
    const companyContract = app_1.getEasyLexContract();
    const handleClickContract = () => {
        window.open(EASYLEX_REGISTER_LINK, '_newtab');
        setClickContract(true);
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.infoContainer },
            React.createElement("h3", { className: styles.mainTittle }, i18n.getHTML('REGISTER_EASYLEX_CONTRACT_TITTLE', {
                companyName: companyContract || ''
            })),
            React.createElement("p", { className: styles.instructionsLabel }, i18n.get('REGISTER_EASYLEX_CONTRACT_FOLLOW_INSTRUCTIONS')),
            React.createElement("p", { className: styles.instructions }, i18n.get('REGISTER_EASYLEX_CONTRACT_INSTRUCTIONS_1')),
            React.createElement("p", { className: styles.instructions }, i18n.getHTML('REGISTER_EASYLEX_CONTRACT_INSTRUCTIONS_2', {
                companyName: companyContract || ''
            })),
            React.createElement("p", { className: styles.instructions }, i18n.getHTML('REGISTER_EASYLEX_CONTRACT_INSTRUCTIONS_3', {
                link: i18n.get('HELP_LINKS_EASYLEX_CONTRACT')
            })),
            React.createElement("p", { className: styles.instructions }, i18n.getHTML('REGISTER_EASYLEX_CONTRACT_INSTRUCTIONS_4', {
                companyName: companyContract || ''
            }))),
        React.createElement("div", { className: styles.buttonContainer },
            React.createElement(ui_library_1.Button, { color: 'primary', onClick: handleClickContract }, i18n.get('REGISTER_EASYLEX_CONTRACT_BUTTON'))),
        React.createElement("div", { className: styles.proccesButtonContainer },
            React.createElement(ui_library_1.Button, { color: 'flat', className: styles.cancelButton, onClick: previousHandler, size: 'sm' }, i18n.get('LOGOUT')),
            React.createElement(ui_library_1.Button, { color: 'primary', onClick: nextHandler, size: 'sm', style: { padding: '1rem 3rem', borderRadius: '2px' }, disabled: !clickContract }, i18n.get('NEXT')))));
};
exports.default = EasyLexContract;
