"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const moment = require("moment");
const ui_library_1 = require("ui-library");
const lodash_1 = require("lodash");
const i18n = require("i18n");
const API = require("../lib/api");
const dateDiff_1 = require("utils/dateDiff");
const date_select_1 = require("./date-select");
const types_1 = require("modules/shared/types");
const UploadAvatar_1 = require("apps/shared/components/UploadAvatar/UploadAvatar");
const DebounceInput_1 = require("apps/shared/components/DebounceInput");
class SettingsAccountContent extends React.Component {
    constructor(props) {
        super(props);
        this.validateData = userData => {
            const { user } = this.props;
            const requireInfluencerInputs = user.role === 'influencer';
            let isValid = true;
            isValid = isValid && this.validateName(userData);
            if (requireInfluencerInputs) {
                isValid = isValid && this.validateGender(userData);
                isValid = isValid && this.validateBirthday(userData);
            }
            return isValid;
        };
        this.validateName = userData => {
            const { name = '' } = userData;
            return name.trim().length > 0;
        };
        this.validateGender = userData => {
            const { gender } = userData;
            return !!gender;
        };
        this.validateBirthday = userData => {
            const { birthday } = userData;
            if (!birthday)
                return false;
            return this.isValidAge(userData);
        };
        this.isValidAge = userData => {
            const { birthday } = userData;
            return Math.floor(dateDiff_1.default(moment().toDate(), birthday).years) >= 13;
        };
        this.onNameChange = event => {
            const name = event.target.value;
            const { userData } = this.state;
            const newUserData = Object.assign({}, userData, { name });
            this.setState({
                userData: newUserData
            });
        };
        this.onLastNameChange = event => {
            const lastName = event.target.value;
            const { userData } = this.state;
            const newUserData = Object.assign({}, userData, { lastName });
            this.setState({
                userData: newUserData
            });
        };
        this.onDateChange = birthday => {
            const { userData } = this.state;
            const newUserData = Object.assign({}, userData, { birthday });
            this.setState({
                userData: newUserData
            });
        };
        this.onGenderChange = event => {
            const state = this.state;
            state.userData.gender = event.target.getAttribute('data-value');
            this.setState(state);
        };
        this.onSelectImage = (id, image) => {
            const { userData } = this.state;
            const updateData = { id: userData.id, profileImage: image };
            const newUserData = Object.assign({}, userData, updateData);
            this.setState({
                userData: newUserData
            });
            API.auto('updateUser', updateData, {});
        };
        this.saveData = () => {
            const { userData } = this.state;
            const isValid = this.validateData(userData);
            const storeData = lodash_1.pick(userData, [
                'id',
                'name',
                'lastName',
                'gender',
                'profileImage',
                'birthday'
            ]);
            if (!isValid)
                return this.setState({ showErrors: true });
            API.auto('updateUser', storeData, {
                successMessage: i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_SAVE_SUCCESS_MESSAGE'),
                callback: err => {
                    if (err)
                        return console.error(err);
                    if (lodash_1.has(this.props, 'onSave'))
                        this.props.onSave();
                }
            });
        };
        this.getInfluencerInputs = () => {
            const { disabled } = this.props;
            const { userData, showErrors } = this.state;
            const { gender, birthday } = userData;
            const errorOnGender = showErrors && !this.validateGender(userData);
            const errorOnBirthday = showErrors && !this.validateBirthday(userData);
            return (React.createElement("div", null,
                React.createElement("div", { className: 'vf-simple-form-label margin-top-1x' }, i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_BIRTH_DATE')),
                React.createElement(date_select_1.default, { className: errorOnBirthday ? 'error' : '', initialDate: birthday, onChange: this.onDateChange, value: birthday, disabled: disabled }),
                errorOnBirthday && (React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, i18n.get('REQUIRED'))),
                React.createElement("div", { className: 'vf-simple-form-label' }, i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_LABEL')),
                React.createElement(ui_library_1.Radio, { name: 'gender', checked: gender === types_1.Gender.MALE, className: 'margin-top-Hx', "data-value": 'male', disabled: disabled, id: 'male-gender', onChange: this.onGenderChange }, i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_MALE')),
                React.createElement(ui_library_1.Radio, { checked: gender === types_1.Gender.FEMALE, className: 'margin-top-Hx', "data-value": 'female', disabled: disabled, id: 'female-gender', name: 'gender', onChange: this.onGenderChange }, i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_FEMALE')),
                React.createElement(ui_library_1.Radio, { checked: gender === types_1.Gender.OTHER, className: 'margin-top-Hx', "data-value": 'other', disabled: disabled, id: 'other-gender', name: 'gender', onChange: this.onGenderChange }, i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_GENDER_OTHER')),
                errorOnGender && (React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, i18n.get('REQUIRED')))));
        };
        const initialUserData = lodash_1.clone(props.user);
        const { _id: id, name, lastName, gender, profileImage, birthday } = initialUserData;
        this.state = {
            userData: {
                id,
                name,
                lastName,
                gender,
                profileImage,
                birthday
            },
            avatarUrl: '',
            showErrors: false
        };
    }
    render() {
        const { user, disabled } = this.props;
        const { userData, showErrors } = this.state;
        const { name, lastName } = userData;
        const userProfileImage = userData.profileImage;
        const influencerInputs = user.role === 'influencer' ? this.getInfluencerInputs() : null;
        const errorOnName = showErrors && !this.validateName(userData);
        return (React.createElement("div", { className: 'vf-row' },
            React.createElement("div", { className: 'col-xs-12 col-md-4 vf-settings-avatar', id: 'avatar-container' },
                React.createElement(UploadAvatar_1.default, { image: userProfileImage, onChange: this.onSelectImage })),
            React.createElement("div", { className: 'col-xs-12 col-md-8' },
                React.createElement("div", { className: 'vf-simple-form-label' }, i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_FIRST_NAME')),
                React.createElement(DebounceInput_1.default, { id: 'name-input', disabled: disabled, hasError: errorOnName, onChange: this.onNameChange, value: name }),
                errorOnName && (React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, i18n.get('REQUIRED'))),
                React.createElement("div", { className: 'vf-simple-form-label' },
                    i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_LAST_NAME'),
                    React.createElement("br", null),
                    React.createElement("span", { className: 'details' }, i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_OPTIONAL'))),
                React.createElement(DebounceInput_1.default, { id: 'last-name-input', disabled: disabled, onChange: this.onLastNameChange, value: lastName }),
                influencerInputs)));
    }
}
exports.default = SettingsAccountContent;
