"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const placeActions = require("redux/modules/places");
const SinglePlaceSelect_1 = require("apps/shared/components/SinglePlaceSelect/SinglePlaceSelect");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const lodash_1 = require("lodash");
class CountrySelect extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            isLoading: false,
            searchValue: ''
        };
        this.fetchData = (props = this.props) => {
            const { searchValue } = this.state;
            const { fetchCountries, fetchPlace, selectedId } = props;
            const params = {
                q: searchValue,
                replace: true
            };
            fetchCountries(params)
                .then(() => fetchPlace({ id: selectedId }))
                .finally(() => this.setState({ isLoading: false }));
        };
        this.handleInputChange = searchValue => {
            this.setState({
                searchValue,
                isLoading: true
            }, this.fetchData);
        };
        this.handleCountryClick = countryId => {
            const { countries, onChange } = this.props;
            const selectedCountry = countries[countryId];
            this.setState({
                searchValue: selectedCountry.name
            }, () => onChange(selectedCountry));
        };
        this.handleClear = () => {
            const { onChange } = this.props;
            this.setState({ searchValue: '' }, () => onChange({}));
        };
        this.getCountries = () => {
            const { countries, selectedId } = this.props;
            return lodash_1.chain(countries)
                .filter(country => country.id !== selectedId && country.type === 'country')
                .orderBy('score', 'desc')
                .value();
        };
        this.getSelectedCountry = () => {
            const { selectedId, countries } = this.props;
            return countries[selectedId];
        };
    }
    render() {
        const { hasError } = this.props;
        const { searchValue } = this.state;
        return (React.createElement(SinglePlaceSelect_1.default, { hasError: hasError, placeholder: i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_COUNTRY_PLACEHOLDER'), places: this.getCountries(), searchValue: searchValue, selectedPlace: this.getSelectedCountry(), onSearchChange: this.handleInputChange, onPlaceClick: this.handleCountryClick, onClear: this.handleClear }));
    }
}
const mapStateToProps = state => ({
    countries: lodash_1.get(state, 'entities.Place', {})
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    fetchCountries: placeActions.fetchCountries,
    fetchPlace: placeActions.fetchPlace
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(CountrySelect);
