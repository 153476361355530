"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const types_1 = require("modules/shared/types");
const components_1 = require("apps/influencers/PublicCampaignDetail/components");
const ParticipationCardHeader = ({ socialAccount, publicationTypes, children }) => (React.createElement("div", { className: 'flex flex-justify-space-between margin-bottom-1x flex-wrap' },
    React.createElement(components_1.PublicParticipationAvatar, { socialAccount: socialAccount, publicationTypes: publicationTypes }),
    children));
ParticipationCardHeader.defaultProps = {
    publicationTypes: [types_1.PublicationType.POST]
};
exports.default = ParticipationCardHeader;
