"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const i18n_1 = require("i18n");
const images_1 = require("assets/images");
const styles = require("./InvalidAccount.less");
const types_1 = require("modules/shared/types");
const components_1 = require("apps/shared/components");
const InvalidAccount = ({ onClose, campaignLite }) => {
    const profileType = campaignLite ? types_1.ECategoryType.PRO : types_1.ECategoryType.LITE;
    const campaignType = campaignLite ? types_1.ECategoryType.LITE : types_1.ECategoryType.PRO;
    return (React.createElement("div", { className: styles.dialog },
        React.createElement(components_1.DialogHeader, { className: styles.header }, i18n_1.get('INF_SOCIAL_ACCOUNTS_INVALID_TITLE')),
        React.createElement("div", { className: styles.dialogContainer },
            React.createElement("div", { className: styles.icon },
                React.createElement("img", { src: images_1.iconAlert, alt: 'warning', className: styles.iconWarning })),
            React.createElement("div", { className: styles.disclaimer }, i18n_1.get('INF_SOCIAL_ACCOUNTS_INVALID_DESCRIPTION', {
                profileType,
                campaignType
            })),
            React.createElement("div", { className: styles.buttonContainer },
                React.createElement(ui_library_1.Button, { color: 'flat', type: 'button', onClick: onClose, className: styles.button }, i18n_1.get('INF_LITE_ACCOUNTS_CANCEL_BUTTON')),
                React.createElement(ui_library_1.Button, { color: 'primary', className: styles.button, onClick: onClose }, i18n_1.get('INF_LITE_ACCOUNTS_TRY_BUTTON'))))));
};
exports.default = InvalidAccount;
