"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const get = require("lodash/get");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const components_1 = require("modules/shared/components");
class AudienceAuthenticityUI extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.handleChange = (ev) => {
            const { onChange } = this.props;
            onChange(get(ev, 'currentTarget.dataset.level'));
        };
    }
    render() {
        const { levels, selectedLevel } = this.props;
        return (React.createElement(React.Fragment, null, levels.map(l => {
            const isSelected = l.value === selectedLevel;
            return (React.createElement(ui_library_1.Radio, { checked: isSelected, className: 'margin-vertical-Hx margin-right-Hx vf-clickable', "data-level": l.value, key: l.value, onChange: this.handleChange },
                React.createElement("div", { className: 'flex' },
                    React.createElement("span", { className: classnames_1.default('vf-clickable', {
                            'vf-font-bold': isSelected
                        }) }, l.name),
                    isSelected && (React.createElement(components_1.IconTooltip, { icon: 'icon-info', message: l.description })))));
        })));
    }
}
exports.default = AudienceAuthenticityUI;
