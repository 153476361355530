"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const validateUrlSocialNetworks_1 = require("utils/validateUrlSocialNetworks");
const ParticipationModalLiteUI_1 = require("./ParticipationModalLiteUI");
const errorStateHandler_1 = require("apps/shared/hocs/errorStateHandler");
const MAX_LONG_FIELD = 200;
const INITIAL_FORM = { link: '' };
const ParticipationModalLite = ({ accountInfo, onCloseModal, postLink, errors, onSetErrors, handleInputValidation }) => {
    const [form, setForm] = React.useState(INITIAL_FORM);
    const { link } = form;
    const [videos, setVideos] = React.useState([]);
    const [images, setimages] = React.useState([]);
    const [loadingVideo, setLoadingVideo] = React.useState(false);
    const [loadingImagen, setLoadingImagen] = React.useState(false);
    const dataInfo = JSON.parse(accountInfo);
    let videoId = null;
    let img = null;
    const canUploadVideo = images.length === 0 && !loadingImagen;
    const canUploadImage = videos.length === 0 && !loadingVideo;
    if (videos.length > 0) {
        videoId = videos[0].id;
    }
    else if (images.length > 0) {
        img = images[0];
    }
    const handleVideosChange = video => {
        setVideos(video);
    };
    const onLoadingVideo = isLoadingVideo => {
        setLoadingVideo(isLoadingVideo);
    };
    const handleImagesChange = image => {
        setimages(image);
    };
    const handleLoadingImage = isLoadingImage => {
        setLoadingImagen(isLoadingImage);
    };
    const validateForm = (inputs) => {
        const validateField = (field) => {
            if (!field) {
                return i18n.get('REQUIRED');
            }
            else if (field.length > MAX_LONG_FIELD) {
                return i18n.get('MAX', { max: MAX_LONG_FIELD });
            }
            return validateUrlSocialNetworks_1.default(dataInfo.socialNetwork, field);
        };
        return {
            link: validateField(inputs.link)
        };
    };
    const hanldleInputChange = (name, value) => {
        setForm(Object.assign(Object.assign({}, form), { [name]: value }));
        handleInputValidation({ name, value, validationFunction: validateForm });
    };
    const onSave = () => {
        const args = {
            linkPost: link,
            netSocial: dataInfo.socialNetwork,
            videoId,
            img
        };
        if (images.length === 0 && videos.length === 0) {
            const errorsList = validateForm(form);
            const isValid = Object.values(errorsList).every(val => val === null);
            if (!isValid)
                return onSetErrors(errorsList);
        }
        postLink(args);
    };
    return (React.createElement(ParticipationModalLiteUI_1.default, { onSave: onSave, hanldleInputChange: hanldleInputChange, errors: errors, dataInfo: dataInfo, onCloseModal: onCloseModal, onVideosChange: handleVideosChange, videos: videos, onLoadingVideo: onLoadingVideo, onLoadingImage: handleLoadingImage, images: images, onImagesChange: handleImagesChange, canUploadImage: canUploadImage, canUploadVideo: canUploadVideo }));
};
exports.default = errorStateHandler_1.default(ParticipationModalLite);
