import React from 'react';

const bart = 'https://s3.amazonaws.com/voxfeed.assets/demo/other/bart.png';
const domainsMap = {
  fluvip: 'https://s3.amazonaws.com/voxfeed.assets/demo/other/fluvip.png',
  brandme: 'https://s3.amazonaws.com/voxfeed.assets/demo/other/brandme.png',
  crowdsquad: 'https://s3.amazonaws.com/voxfeed.assets/demo/other/crowdsquad.png'
};

const CompetitorsPanel = ({domain}) => {
  const url = domainsMap[domain] || 'google.com';

  return (
    <div className='flex center-xs'>
      <img src={bart} style={{zIndex: '2', position: 'fixed', bottom: 0}} />
      <img
        src={url}
        style={{position: 'fixed', top: '0', right: '0', left: '0', bottom: '0'}}
      />
    </div>

  );
};

export default CompetitorsPanel;
