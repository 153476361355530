import {get} from 'lodash';

export default (socialAccount) => {
  const positiveScore = get(socialAccount, 'score.positive', 0);
  const negativeScore = get(socialAccount, 'score.negative', 0);

  if (positiveScore === 0 && negativeScore === 0) return null;

  const totalScore = positiveScore + negativeScore;

  return totalScore === 0 ? 0 : Math.round((positiveScore / totalScore) * 100);
};
