import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import i18n from 'i18n';

import { fetchPlace } from 'redux/modules/shared';

import { getCurrentUser } from 'modules/shared/selectors';

import { UserLocaleSelector } from 'apps/shared/controllers';

function mapStateToProps(state) {
  const user = getCurrentUser(state);
  const cityId = get(user, 'city');
  const city = get(state, `entities.Place.${cityId}`) || {};

  return { user, city, cityId };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchPlace }, dispatch);
}

class UserSettingsLocationPhoneReadonly extends Component {
  UNSAFE_componentWillMount = () => {
    this.fetchUserLocationData();
  };

  UNSAFE_componentWillReceiveProps = newProps => {
    const didCityChange =
      !!newProps.cityId && newProps.cityId !== this.props.cityId;

    if (didCityChange) this.fetchUserLocationData(newProps);
  };

  fetchUserLocationData = (props = this.props) => {
    const { cityId, city } = props;
    if (!cityId) return;
    if (!city.id) this.props.fetchPlace(cityId);
  };

  parsePhone = phone => {
    const response = { prefix: '', number: '' };

    if (!phone) return response;

    const components = phone.split('-');
    response.prefix = components[0] || '';
    response.number = components[1] || '';

    return response;
  };

  buildCityName = () => {
    const { name, admin1 } = this.props.city;
    let cityName = '';
    if (!this.props.city) return cityName;

    cityName =
      !!admin1 && !!admin1.name ? [name, admin1.name].join(', ') : name;

    return cityName;
  };

  render = () => {
    const { city, user } = this.props;
    const cityName = this.buildCityName();
    const phone = this.parsePhone(user.phone);
    const phoneNumber = get(phone, 'number');

    return (
      <div className='vf-row'>
        <div className='col-xs-12 col-md-6'>
          <div className='vf-descriptor'>
            {i18n.get('INF_SETTINGS_ACCOUNT_LOCATION_COUNTRY_LABEL')}
          </div>
          <h4 data-uitest='country'>{get(city, 'country.name')}</h4>

          <div className='padding-top-2x'>
            <div className='vf-descriptor'>
              {i18n.get('INF_SETTINGS_ACCOUNT_PHONE_NUMBER_LABEL')}
            </div>

            {!!phoneNumber && (
              <div className='vf-h4' data-uitest='phone-number'>
                <strong>+{phone.prefix}</strong> {phone.number}
              </div>
            )}

            {!phoneNumber && <h4>{i18n.get('NOT_SPECIFIED')}</h4>}
          </div>
        </div>

        <div className='col-xs-12 col-md-6'>
          <div className='vf-descriptor'>
            {i18n.get('INF_SETTINGS_ACCOUNT_LOCATION_CITY_LABEL')}
          </div>
          <h4 data-uitest='city'>{cityName}</h4>

          <div className='padding-top-2x'>
            <div className='vf-descriptor'>
              {i18n.get('INF_SETTINGS_ACCOUNT_LANGUAGE')}
            </div>

            <UserLocaleSelector />
          </div>
        </div>
      </div>
    );
  };
}

UserSettingsLocationPhoneReadonly.propTypes = {
  user: PropTypes.object.isRequired,
  fetchPlace: PropTypes.func.isRequired,
  city: PropTypes.object,
  cityId: PropTypes.string
};

UserSettingsLocationPhoneReadonly.defaultProps = {
  user: {},
  city: { admin1: {}, country: {} }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettingsLocationPhoneReadonly);
