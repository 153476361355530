import * as Cache from '../../../services/voxfeed/campaigns/cache';
import { campaigns } from '../../../services/voxfeed';
import {
  handleRequestFail,
  handleRequestSuccess,
  buildAPIRequest
} from '../helpers';
import { sendRequest, sendRequestSuccess } from '../app';
import { removeData, replaceData, updateData } from '../entities';
import { get, omit, findIndex } from 'lodash';
import download from 'downloadjs';

const initialState = {
  config: {},
  difficulty: {},
  availableCampaigns: []
};

const CALCULATE_DIFFICULTY = 'voxfeed/campaigns/CALCULATE_DIFFICULTY';
const GET_CONFIGURATION = 'voxfeed/campaigns/GET_CONFIGURATION';
const FETCH_AVAILABLE_CAMPAIGNS = 'voxfeed/campaigns/FETCH_AVAILABLE_CAMPAIGNS';
const FETCH_CAMPAIGN_AVAILABILITY =
  'voxfeed/campaigns/FETCH_CAMPAIGN_AVAILABILITY';

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CALCULATE_DIFFICULTY:
      return {
        ...state,
        difficulty: payload
      };
    case GET_CONFIGURATION:
      return {
        ...state,
        config: payload
      };
    case FETCH_AVAILABLE_CAMPAIGNS:
      return {
        ...state,
        availableCampaigns: payload
      };
    case FETCH_CAMPAIGN_AVAILABILITY:
      return {
        ...state,
        availableCampaigns: replaceAvailableCampaign(state, payload)
      };
    default:
      return state;
  }
}

function replaceAvailableCampaign(state, campaign) {
  const newAvailableCampaigns = [...state.availableCampaigns];
  const index = findIndex(
    newAvailableCampaigns,
    camp =>
      camp.influencerId === campaign.influencerId &&
      camp.campaignId === campaign.campaignId
  );

  if (index >= 0) newAvailableCampaigns[index] = campaign;
  if (index < 0) newAvailableCampaigns.push(campaign);
  return newAvailableCampaigns;
}

export function fetchInvitesForCurrentInfluencer(accountId) {
  const REQUEST_ID = 'fetchInfluencerInvites';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .getInvitesByAccountId(accountId)
      .then(response => {
        dispatch(updateData(response.data));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
      });
  };
}

export const createCampaign = params =>
  buildAPIRequest({
    params,
    id: `createCampaign_${params.brandId}`,
    method: campaigns.create,
    callback: ({ dispatch, data }) => {
      const cacheParams = {
        brandId: params.brandId,
        fieldPath: '_id',
        value: data.id
      };
      dispatch(updateBrandCurrentCampaignField(cacheParams));
    }
  });

export const updateCampaign = params =>
  buildAPIRequest({
    params,
    id: `updateCampaign_${params.id}`,
    method: campaigns.update
  });

export const restoreParticipations = params =>
  buildAPIRequest({
    params,
    id: `restore_participation_${params.id}`,
    method: campaigns.restoreParticipation
  });

export const archive = ({ ignoreCodes, ...params }) =>
  buildAPIRequest({
    params,
    ignoreCodes,
    id: `archiveCampaign_${params.campaignId}`,
    method: campaigns.archive,
    skipRequestActions: true,
    callback({ dispatch, data }) {
      setTimeout(() => dispatch(removeData(data)));
    }
  });

export const unarchive = params =>
  buildAPIRequest({
    params,
    id: `unarchiveCampaign_${params.campaignId}`,
    method: campaigns.unarchive,
    skipRequestActions: true,
    callback({ dispatch, data }) {
      setTimeout(() => dispatch(removeData(data)));
    }
  });

export const remove = ({ ignoreCodes, ...params }) =>
  buildAPIRequest({
    params,
    ignoreCodes,
    id: `removeCampaign_${params.campaignId}`,
    method: campaigns.remove
  });

export function likeProposal(proposalId) {
  const REQUEST_ID = 'likeProposal';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .likeProposal(proposalId)
      .then(response => {
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateData(response.data));
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export function dislikeProposal(proposalId) {
  const REQUEST_ID = 'dislikeProposal';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .dislikeProposal(proposalId)
      .then(response => {
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateData(response.data));
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export function removeProposalLike(proposalId) {
  const REQUEST_ID = 'removeProposalLike';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .removeProposalLike(proposalId)
      .then(response => {
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateData(response.data));
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export function removeProposalDislike(proposalId) {
  const REQUEST_ID = 'removeProposalDislike';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .removeProposalDislike(proposalId)
      .then(response => {
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateData(response.data));
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export const sendParticipationFeedback = params =>
  buildAPIRequest({
    params,
    id: 'sendParticipationFeedback',
    method: campaigns.sendParticipationFeedback
  });

export const fetchInvite = id =>
  buildAPIRequest({
    id: `fetchInvite_${id}`,
    params: id,
    method: campaigns.getInviteById
  });

export function sendInvites(data) {
  const REQUEST_ID = `sendInvites_${data.campaignId}`;

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .sendInvites(data)
      .then(response => {
        dispatch(sendRequestSuccess(REQUEST_ID));
        return response.data;
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export const updateInvite = invite =>
  buildAPIRequest({
    id: `updateInvite_${invite._id}`,
    params: invite,
    method: campaigns.updateInvite
  });

export function fetchParticipationFeedback(id) {
  const REQUEST_ID = 'fetchParticipationFeedback';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .fetchParticipationFeedback(id)
      .then(response => {
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateData(response.data));
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export function fetchCampaignFeedbacks(campaignId) {
  const REQUEST_ID = 'fetchCampaignFeedbacks';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .fetchFeedbacks(campaignId)
      .then(response => {
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateData(response.data));
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export function replyToFeedback({ campaignId, feedbackId, comments }) {
  const REQUEST_ID = 'replyToFeedback';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .replyToFeedback({ campaignId, feedbackId, comments })
      .then(response => {
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateData(response.data));
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export const fetchConfig = params =>
  buildAPIRequest({
    params,
    id: 'fetchCampaignConfig',
    method: campaigns.fetchConfig,
    callback: ({ data, dispatch }) => {
      dispatch({
        payload: data,
        type: GET_CONFIGURATION
      });
    }
  });

export const calculateDifficulty = params =>
  buildAPIRequest({
    params,
    id: 'calculateDifficulty',
    method: campaigns.calculateDifficulty,
    callback: ({ data, dispatch }) => {
      const { value: campaignDifficulty } = data;

      dispatch(fetchConfig({ campaignDifficulty }));
      dispatch({
        payload: data,
        type: CALCULATE_DIFFICULTY
      });
    }
  });

export const acceptInvite = id =>
  buildAPIRequest({
    id: 'acceptInvite',
    method: campaigns.acceptInvite,
    params: id
  });

export function rejectInvite(params) {
  const REQUEST_ID = 'rejectInvite';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .rejectInvite(params)
      .then(response => {
        dispatch(updateData(get(response, 'data', {})));
        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export function joinCampaign(params) {
  const REQUEST_ID = 'joinCampaign';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .joinCampaign(params)
      .then(response => {
        dispatch(updateData(get(response, 'data', {})));
        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
      });
  };
}

export const incrementTimeToSendProposal = params =>
  buildAPIRequest({
    params,
    id: 'incrementTimeToSendProposals',
    method: campaigns.incrementTimeToSendProposals
  });

export const fetchCampaignsByIdsAsInfluencer = ids =>
  buildAPIRequest({
    params: ids,
    id: 'fetchCampaignsAsInfluencer',
    method: campaigns.fetchCampaignsByIdsAsInfluencer
  });

export function fetchInfluencerAvailableCampaigns(influencerId) {
  const REQUEST_ID = 'fetchInfluencerAvailableCampaigns';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    campaigns
      .fetchInfluencerAvailableCampaigns()
      .then(response => {
        const data = get(response, 'data', []);
        const campaignsData = data.map(
          parseCampaignEntityFromAvailableCampaign
        );
        const availableCampaigns = data.map(availableCampaign =>
          parseAvailableCampaign({
            availableCampaign,
            influencerId
          })
        );

        dispatch(
          updateData({
            modelName: 'Campaign',
            data: campaignsData
          })
        );

        dispatch(fetchAvailableCampaignsSuccess(availableCampaigns));

        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
      });
  };
}

function parseCampaignEntityFromAvailableCampaign(availableCampaign) {
  const { campaign } = availableCampaign;
  const { description, restrictions, tone } = campaign;

  return {
    ...campaign,
    ...campaign.socialNetworks,
    brief: {
      description,
      tone,
      donts: restrictions
    }
  };
}

function parseAvailableCampaign({ availableCampaign, influencerId }) {
  return {
    influencerId,
    campaignId: availableCampaign.campaign.id,
    ...omit(availableCampaign, 'campaign')
  };
}

function fetchAvailableCampaignsSuccess(availableCampaigns) {
  return {
    type: FETCH_AVAILABLE_CAMPAIGNS,
    payload: availableCampaigns
  };
}

export function fetchInfluencerActiveParticipations(accountId) {
  const REQUEST_ID = 'fetchInfluencerActiveParticipations';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    campaigns
      .fetchActiveParticipationsByAccountId(accountId)
      .then(response => {
        dispatch(updateData(get(response, 'data', {})));
        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
      });
  };
}

export function fetchInfluencerHistoricalParticipations(accountId) {
  const REQUEST_ID = 'fetchInfluencerHistoricalParticipations';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    campaigns
      .fetchHistoricalParticipationsByAccountId(accountId)
      .then(response => {
        dispatch(updateData(get(response, 'data', {})));
        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
      });
  };
}

export const calculateParticipationTakeRate = params =>
  buildAPIRequest({
    params,
    id: 'calculateParticipationTakeRate',
    method: campaigns.calculateParticipationTakeRate,
    callback: ({ dispatch, data }) => {
      const postPrice = get(params, 'postPrice', 0);

      dispatch(
        updateData({
          modelName: 'TakeRate',
          id: `${params.campaignId}_${postPrice.toString().replace('.', '_')}`,
          data
        })
      );
    }
  });

export const sendParticipationRequest = params =>
  buildAPIRequest({
    params,
    method: campaigns.sendParticipationRequest,
    id: 'sendParticipationRequest'
  });

export function fetchParticipationRequestsConstraints(ids) {
  const REQUEST_ID = 'fetchParticipationRequestsConstraints';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    campaigns
      .fetchParticipationRequestsConstraints(ids)
      .then(response => {
        const data = response.data.map(constrains => {
          const { participationRequestId: id, ...other } = constrains;
          return { id, ...other };
        });

        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
        dispatch(
          updateData({
            data,
            modelName: 'ParticipationRequest'
          })
        );
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
      });
  };
}

export const updateParticipationRequestPostPrice = params =>
  buildAPIRequest({
    params,
    id: 'updateParticipationRequestPostPrice',
    method: campaigns.updateParticipationRequestPostPrice
  });

export const cancelParticipationRequest = params =>
  buildAPIRequest({
    params,
    id: 'cancelParticipationRequest',
    method: campaigns.cancelParticipationRequestPostPrice
  });

export function fetchInfluencerPendingParticipationRequests(influencerId) {
  const REQUEST_ID = 'fetchPendingParticipationRequest';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    campaigns
      .fetchInfluencerPendingParticipationRequests(influencerId)
      .then(response => {
        dispatch(updateData(get(response, 'data', {})));
        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
      });
  };
}

export function fetchParticipationRequests(args, reset) {
  const REQUEST_ID = 'fetchParticipationRequests';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .getParticipationRequests(args)
      .then(response => {
        const action = reset ? replaceData : updateData;
        dispatch(action(get(response, 'data', {})));
        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
      });
  };
}

export const rejectParticipationRequest = params =>
  buildAPIRequest({
    params,
    id: `rejectParticipationRequest_${params.id}`,
    method: campaigns.rejectParticipationRequest,
    callback: ({ dispatch }) => {
      dispatch(
        updateData({
          id: params.id,
          data: { status: 'rejected' },
          modelName: 'ParticipationRequest'
        })
      );
    }
  });

export const acceptParticipationRequest = ({ ignoreCodes, ...params }) =>
  buildAPIRequest({
    params,
    ignoreCodes,
    id: `acceptParticipationRequest_${params.id}`,
    method: campaigns.acceptParticipationRequest,
    callback: ({ dispatch }) => {
      dispatch(
        updateData({
          id: params.id,
          data: { status: 'accepted' },
          modelName: 'ParticipationRequest'
        })
      );
    }
  });

export function fetchCampaignAvailabilityForInfluencer({
  influencerId,
  campaignId
}) {
  const REQUEST_ID = 'fetchCampaignAvailabilityForInfluencer';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .fetchCampaignAvailabilityForInfluencer({ influencerId, campaignId })
      .then(response => {
        const availableCampaign = get(response, 'data', {});
        const campaignData =
          parseCampaignEntityFromAvailableCampaign(availableCampaign);

        dispatch(
          updateData({
            modelName: 'Campaign',
            id: campaignId,
            data: campaignData
          })
        );

        dispatch(
          fetchCampaignAvailabilitySuccess(
            parseAvailableCampaign({
              availableCampaign,
              influencerId
            })
          )
        );

        return handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
        return Promise.reject();
      });
  };
}

function fetchCampaignAvailabilitySuccess(availableCampaign) {
  return {
    type: FETCH_CAMPAIGN_AVAILABILITY,
    payload: availableCampaign
  };
}

export function fetchInfluencerPromotedMessages(influencerId) {
  const REQUEST_ID = 'fetchInfluencerPromotedMessages';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    campaigns
      .fetchInfluencerPromotedMessages(influencerId)
      .then(response => {
        dispatch(updateData(get(response, 'data', {})));
        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
      });
  };
}

export function setCampaignType({ id, type }) {
  const REQUEST_ID = `setCampaignType_${id}`;

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .setCampaignType({ id, type })
      .then(response => {
        dispatch(updateData(get(response, 'data', {})));
        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
        return Promise.reject(response);
      });
  };
}

export function fetchCampaignPromotedMessages(
  { campaignId, params },
  reset = false
) {
  const REQUEST_ID = `fetchCampaignPromotedMessages_${campaignId}`;

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return campaigns
      .fetchCampaignPromotedMessages(campaignId, params)
      .then(response => {
        const action = reset ? replaceData : updateData;
        dispatch(action(get(response, 'data', {})));
        handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
      })
      .catch(response => {
        handleRequestFail({ dispatch, response, id: REQUEST_ID });
        return Promise.reject(response);
      });
  };
}

export const setProductsShippingAddress = params =>
  buildAPIRequest({
    params,
    id: 'setProductsShippingAddress',
    method: campaigns.setProductsShippingAddress
  });

export const refreshCampaignLink = params =>
  buildAPIRequest({
    params,
    id: `refreshCampaignLink_${params.id}`,
    method: campaigns.refreshLink
  });

export const getCampaignByToken = params =>
  buildAPIRequest({
    params,
    id: 'getCampaignByToken',
    method: campaigns.getByToken
  });

export const setCampaignToken = params =>
  buildAPIRequest({
    params,
    id: 'setCampaignToken',
    method: campaigns.setCampaignToken
  });

export const fetchPromotedMessages = (params, replace) =>
  buildAPIRequest({
    params,
    id: `fetchPromotedMessages_${params.id}`,
    method: campaigns.fetchPromotedMessages,
    replace
  });

export const abortInvite = params =>
  buildAPIRequest({
    params,
    id: `abortInvite_${params.id}`,
    method: campaigns.abortInvite
  });

export const sendProposal = params =>
  buildAPIRequest({
    params,
    id: 'sendProposal',
    method: campaigns.sendProposal
  });

export const fetchParticipationRequest = params =>
  buildAPIRequest({
    params,
    id: `fetchParticipationRequest_${params.id}`,
    method: campaigns.fetchParticipationRequest
  });

export const loadBrandCampaignFromCache = brandId =>
  buildAPIRequest({
    id: `loadBrandCampaignFromCache_${brandId}`,
    method: Cache.getBrandCampaignData,
    params: brandId,
    showLoader: false
  });

export const setBrandCurrentCampaign = params =>
  buildAPIRequest({
    params,
    id: `setBrandCurrentCampaign_${params.brandId}`,
    method: Cache.setBrandCampaign,
    showLoader: false
  });

export const updateBrandCurrentCampaignField = brandId =>
  buildAPIRequest({
    id: `updateBrandCurrentCampaignField_${brandId}`,
    method: Cache.updateBrandCampaignField,
    params: brandId,
    showLoader: false
  });

export const removeBrandCampaignFromCache = brandId =>
  buildAPIRequest({
    id: `removeBrandCampaignFromCache_${brandId}`,
    method: Cache.discardBrandLastIncompleteCampaign,
    params: brandId,
    showLoader: false,
    callback: ({ dispatch }) => {
      dispatch(
        removeData({
          id: `current_${brandId}`,
          modelName: 'Campaign'
        })
      );
    }
  });

export const fetchById = id =>
  buildAPIRequest({
    id: `fetchCampaign_${id}`,
    method: campaigns.fetchById,
    params: id
  });

export const requestEarlyPayment = params =>
  buildAPIRequest({
    id: `requestTipaltiPayment_${params.inviteId}`,
    method: campaigns.requestEarlyPayment,
    params
  });

export const fetchCampaignInvites = (params, replace) =>
  buildAPIRequest({
    params,
    replace,
    id: `fetchCampaignInvites_${params.campaignId}`,
    method: campaigns.fetchCampaignInvites
  });

export const exportCampaignInvites = params =>
  buildAPIRequest({
    params,
    id: 'exportCampaignInvites',
    method: campaigns.exportCampaignInvites,
    callback: ({ response }) => {
      const blob = new Blob([response.data]);
      download(blob, `invite/s-${params.id}.${params.fileType}`);
    }
  });

export const cancelPendingInvites = params =>
  buildAPIRequest({
    params,
    id: 'cancelPending',
    method: campaigns.cancelPending
  });

export const cancelInvite = params =>
  buildAPIRequest({
    params,
    id: 'cancelInvite',
    method: campaigns.cancelInvite
  });

export const finishInvite = params =>
  buildAPIRequest({
    params,
    id: 'finishInvite',
    method: campaigns.finishInvite
  });

export const fetchCampaignInvite = params =>
  buildAPIRequest({
    params,
    id: 'fetchCampaignInvite',
    method: campaigns.fetchCampaignInvite
  });

export const editProposal = params =>
  buildAPIRequest({
    params,
    id: 'editProposal',
    method: campaigns.editProposal
  });

export const fetchPendingActivity = id =>
  buildAPIRequest({
    id: 'fetchCampaignPendingActivity',
    method: campaigns.fetchPendingActivity,
    params: id,
    callback({ response, dispatch }) {
      dispatch(
        updateData({
          id,
          modelName: 'CampaignPendingActivity',
          data: get(response, 'data.data', {})
        })
      );
    }
  });

export const sendPrepaidInvite = params =>
  buildAPIRequest({
    params,
    id: 'sendPrepaidInvite',
    method: campaigns.sendPrepaidInvite,
    ignoreCodes: ['AUTH::INVALID_PASSWORD']
  });

export const checkEarlyPayment = params =>
  buildAPIRequest({
    params,
    id: 'checkEarlyPayment',
    method: campaigns.checkEarlyPayment,
    callback({ data, dispatch }) {
      dispatch(
        updateData({
          id: params.id,
          modelName: 'Earning',
          data
        })
      );
    }
  });

export const reportPost = params =>
  buildAPIRequest({
    params,
    id: `reportLite_${params.inviteId}`,
    method: campaigns.reportPost
  });
