"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./Badge.less");
class Badge extends React.PureComponent {
    render() {
        const { className, style, children } = this.props;
        return (React.createElement("div", { className: classnames_1.default(styles.container, className), style: style }, children));
    }
}
exports.default = Badge;
