"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const isEmpty = require("lodash/isEmpty");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const types_1 = require("modules/shared/types");
const ParticipationProducts_1 = require("modules/shared/containers/ParticipationProducts/ParticipationProducts");
const styles = require("./ProductsPaymentUI.less");
const DELIVERY_KEY_TITLES_BY_TYPE = {
    notApplicable: 'PAY_IN_KIND_DELIVERY_NOT_APPLICABLE_TITLE',
    ship: 'PAY_IN_KIND_DELIVERY_SHIP_TITLE',
    pickUp: 'PAY_IN_KIND_DELIVERY_PICKUP_TITLE'
};
class ProductsPaymentUI extends React.PureComponent {
    render() {
        const { onEditShippingAddress, productsPaymentGroups, currency } = this.props;
        const { notApplicable = [], pickUp = [], ship = [] } = productsPaymentGroups;
        return (React.createElement(React.Fragment, null,
            React.createElement(ProductsPaymentGroup, { deliveryType: types_1.ProductDeliveryType.NOT_APPLICABLE, productsPayment: notApplicable, onEditShippingAddress: onEditShippingAddress, currency: currency }),
            React.createElement(ProductsPaymentGroup, { deliveryType: types_1.ProductDeliveryType.PICKUP, productsPayment: pickUp, onEditShippingAddress: onEditShippingAddress, currency: currency }),
            React.createElement(ProductsPaymentGroup, { deliveryType: types_1.ProductDeliveryType.SHIP, productsPayment: ship, onEditShippingAddress: onEditShippingAddress, currency: currency })));
    }
}
const ProductsPaymentGroup = ({ deliveryType, onEditShippingAddress, productsPayment, currency }) => {
    if (isEmpty(productsPayment))
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("h5", { className: classnames_1.default(styles['delivery-type-title'], 'vf-text-gray vf-font-normal padding-bottom-Hx margin-vertical-2x') }, i18n.get(DELIVERY_KEY_TITLES_BY_TYPE[deliveryType])),
        React.createElement(ParticipationProducts_1.default, { products: productsPayment, onEditShippingAddress: deliveryType === types_1.ProductDeliveryType.SHIP
                ? onEditShippingAddress
                : null, currency: currency })));
};
exports.default = ProductsPaymentUI;
