"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Guidelines = exports.Tone = exports.Description = void 0;
const React = require("react");
const styles = require("./CampaignBrief.less");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const controllers_1 = require("apps/shared/controllers");
const lodash_1 = require("lodash");
const app_1 = require("config/app");
const components_1 = require("modules/shared/components");
const SponsoredCode_1 = require("./components/SponsoredCode");
const CampaignBrief = ({ campaign = {}, children, prepaid, isBrief }) => {
    const { brief = {} } = campaign;
    const { tone, description, donts: guidelines = [] } = brief;
    return (React.createElement("div", { className: 'vf-card no-padding' },
        React.createElement("div", { className: 'padding-1x' },
            !lodash_1.isEmpty(description) && (React.createElement(exports.Description, { campaign: campaign, isBrief: isBrief })),
            tone && !prepaid && React.createElement(exports.Tone, { campaign: campaign }),
            !lodash_1.isEmpty(guidelines) && !prepaid && React.createElement(exports.Guidelines, { campaign: campaign }),
            !prepaid && (React.createElement("div", { className: 'margin-top-1x' },
                React.createElement("a", { href: i18n.get('HELP_LINKS_POSTS_EXAMPLES'), target: '_blank', rel: 'noopener noreferrer', className: 'vf-font-bold' }, i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_SEE_EXAMPLES')))),
            children)));
};
exports.default = CampaignBrief;
exports.Description = ({ campaign, isBrief }) => {
    const { brief = {}, exampleImages = [] } = campaign;
    const hasOneImage = exampleImages.length === 1;
    const imageList = exampleImages.map(image => (React.createElement(controllers_1.ImagePreview, { key: image, src: image, height: 400, className: classnames_1.default(styles.img, { [styles.single]: hasOneImage }) })));
    return (React.createElement("div", null,
        React.createElement("h3", { id: 'description', style: { marginTop: 0 } }, i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_DESCRIPTION')),
        React.createElement(components_1.TextWithHighlightedElements, { text: lodash_1.get(brief, 'description', '').trim() }),
        React.createElement("div", { className: 'margin-top-1x flex flex-wrap flex-justify-space-between' }, imageList),
        isBrief && React.createElement(SponsoredCode_1.default, { campaign: campaign })));
};
exports.Tone = ({ campaign }) => {
    const statics = app_1.getStatics();
    const tone = lodash_1.get(campaign, 'brief.tone');
    return (React.createElement("div", { className: 'margin-top-1x' },
        React.createElement("h3", { id: 'message-tone' }, i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_MESSAGE_TONE')),
        React.createElement("span", { className: styles.briefInfo }, `${lodash_1.get(statics, `adgroupTones.${tone}.name`, '')}. ${lodash_1.get(statics, `adgroupTones.${tone}.description`, '')}`)));
};
exports.Guidelines = ({ campaign }) => {
    const guidelines = lodash_1.get(campaign, 'brief.donts', []);
    return (React.createElement("div", { className: 'margin-top-1x' },
        React.createElement("h3", null, i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_GUIDELINES')),
        React.createElement("ul", { className: `no-margin ${styles.briefInfo}` }, guidelines.map(guideline => (React.createElement("div", { key: guideline }, guideline))))));
};
