import React from 'react';
import Reflux from 'reflux';
import { compose } from 'redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createClass from 'create-react-class';
import { Form, InputWrapper, Button } from 'ui-library';

import i18n from 'i18n';
import emailRegex from 'utils/emailRegex';
import API from 'modules/legacy/js/lib/api';
import recaptcha from 'apps/shared/hocs/recaptcha';
import AppActions from 'modules/legacy/js/actions/app';
import AuthActions from 'modules/legacy/js/actions/auth';
import PartnerFormRoute from 'apps/advertisers/PartnerForm';
import { DebounceInput, FormLabel } from 'apps/shared/components';
import { HeroSections, CardForm } from 'modules/shared/components';

const ForgottenPassword = createClass({
  mixins: [
    Reflux.listenTo(AuthActions.registerError, 'onRegisterError'),
    Reflux.listenTo(AppActions.reCAPTCHALoaded, 'updateReCaptchaWidget')
  ],

  getInitialState() {
    return { email: '', errorMessage: '' };
  },

  onRegisterError(res) {
    const { updateReCaptchaWidget } = this.props;

    AppActions.displayAlert({ type: 'error', message: res.message });
    updateReCaptchaWidget();
  },

  onSendData(event) {
    event.preventDefault();
    const { email } = this.state;

    if (!this.validateForm()) return event.stopPropagation();

    return API.resetPassword({ email }, (err, res) => {
      if (err || res.code) {
        return AppActions.displayAlert({ type: 'error', message: res.message });
      }

      this.setState({ email: '' });

      return AppActions.displayAlert({
        type: 'success',
        message: i18n.get('FORGOT_SUCCESS_ALERT_DESCRIPTION')
      });
    });
  },

  validateForm() {
    let valid = true;
    let errorFocused = false;
    const { email } = this.state;

    const addErrorFn = el => {
      valid = false;
      el.parentNode.classList.add('error');

      if (errorFocused === false) {
        el.focus();
        errorFocused = true;
      }
    };

    if (emailRegex.test(email) === false) {
      addErrorFn(ReactDOM.findDOMNode(this.refs.emailInput));
      this.setState({ errorMessage: i18n.get('FORGOT_INVALID_EMAIL_ERROR') });
    }

    return valid;
  },

  handleEmailInputChange(e) {
    e.target.parentNode.classList.remove('error');
    this.setState({ email: e.target.value, errorMessage: '' });
  },

  render() {
    const { partnerSettings } = this.props;
    const { email, errorMessage } = this.state;

    if (partnerSettings) return <PartnerFormRoute />;

    return (
      <HeroSections
        buttonPadding
        linkTo='/login'
        buttonText={i18n.get('FORGOT_LOG_IN')}
        title={i18n.getHTML('FORGOT_RESET_YOUR_PASSWORD')}
        description={i18n.getHTML('FORGOT_RESET_YOUR_PASSWORD_DESCRIPTION')}
      >
        <CardForm subtitle={i18n.get('FORGOT_WELL_SEND_YOU_INSTRUCTIONS')}>
          <div className='form'>
            <Form onSubmit={this.onSendData}>
              <FormLabel htmlFor='email'>{i18n.get('LOGIN_EMAIL')}</FormLabel>

              <InputWrapper ref='emailWrapper' className='lg'>
                <i className='vf-icon icon-mail' />

                <DebounceInput
                  type='email'
                  name='email'
                  value={email}
                  ref='emailInput'
                  className='login-input'
                  hasError={errorMessage}
                  onChange={this.handleEmailInputChange}
                  placeholder={i18n.get('FORGOT_EMAIL_PLACEHOLDER')}
                />
              </InputWrapper>

              {errorMessage && (
                <div className='vf-text-danger margin-top-Hx'>
                  {errorMessage}
                </div>
              )}

              <div
                id='recaptcha'
                ref='recaptcha'
                className='recaptcha-container'
              />

              <Button
                size='lg'
                color='primary'
                data-uitest='send-email-button'
                className='width100 button-success margin-top-1x'
              >
                {i18n.get('FORGOT_RESET_PASSWORD')}
              </Button>
            </Form>
          </div>
        </CardForm>
      </HeroSections>
    );
  }
});

function mapStateToProps({ app }) {
  return {
    currentLanguage: app.currentLanguage,
    partnerSettings: app.partnerSettings
  };
}

ForgottenPassword.propTypes = {
  currentLanguage: PropTypes.string.isRequired
};

export default compose(recaptcha, connect(mapStateToProps))(ForgottenPassword);
