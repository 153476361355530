import { omit } from 'lodash';
import { update as updateLegacyStore } from './../../../modules/legacy/js/actions/dataStore';

const UPDATE = 'voxfeed/entities/UPDATE';
const REMOVE = 'voxfeed/entities/REMOVE';
const REMOVE_ALL = 'voxfeed/entities/REMOVE_ALL';
const REPLACE = 'voxfeed/entities/REPLACE';
const UPDATE_LEGACY = 'voxfeed/entities/UPDATE_LEGACY';
const UPDATE_DIRECT = 'voxfeed/entities/UPDATE_DIRECT';

const initialState = {};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case UPDATE:
      return runUpdater(state, payload);
    case REMOVE:
      return {
        ...state,
        [payload.modelName]: omit(state[payload.modelName], payload.id)
      };
    case REMOVE_ALL:
      return initialState;
    case REPLACE:
      return runUpdater({ ...state, ...{ [payload.modelName]: {} } }, payload);
    case UPDATE_DIRECT:
      return { ...state, [payload.modelName]: payload.data };
    default:
      return state;
  }
}

export function updateData(data) {
  return {
    type: UPDATE,
    payload: data
  };
}

export function removeData(data) {
  return {
    type: REMOVE,
    payload: data
  };
}

export function directUpdate(data) {
  return {
    type: UPDATE_DIRECT,
    payload: data
  };
}

export function removeAll() {
  return { type: REMOVE_ALL };
}

export function replaceData(data) {
  return {
    type: REPLACE,
    payload: data
  };
}

export function updateLegacy(data) {
  updateLegacyStore(data);
  return { type: UPDATE_LEGACY };
}

function runUpdater(state, payload) {
  const { data, modelName } = payload || {};

  if (data) {
    if (data.status === 'deleted' && modelName === 'Account') return state;
  }

  if (Array.isArray(data)) return updateMultipleEntries(state, payload);

  return updateSingleEntry(state, payload);
}

function updateMultipleEntries(state, { data, modelName }) {
  return data.reduce(
    (newState, newEntry) => ({
      ...newState,
      ...updateSingleEntry(newState, {
        modelName,
        data: newEntry,
        id: newEntry._id || newEntry.id
      })
    }),
    state
  );
}

function updateSingleEntry(state, response) {
  const { id, modelName, data } = response || {};
  if (!id) return state;
  const { [modelName]: currentEntityData = {} } = state;
  const { [id]: currentEntry = {} } = currentEntityData;
  return {
    ...state,
    [modelName]: {
      ...currentEntityData,
      [id]: {
        ...currentEntry,
        ...data
      }
    }
  };
}

export const constants = { UPDATE, REMOVE, REMOVE_ALL, REPLACE, UPDATE_DIRECT };
