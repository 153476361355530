"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const i18n = require("i18n");
const SocialAccountsActions = require("redux/modules/socialAccounts");
const selectors_1 = require("modules/shared/selectors");
const withLoading_1 = require("apps/shared/hocs/withLoading");
const AudienceKeywordsUI_1 = require("./AudienceKeywordsUI/AudienceKeywordsUI");
const PERCENTAGES = [
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95
];
const DEFAULT_PERCENTAGE = 35;
class AudienceKeywords extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            searchQuery: '',
            selectedPercentage: DEFAULT_PERCENTAGE
        };
        this.handleSearchChange = (q) => {
            const { setLoading } = this.props;
            setLoading(true);
            this.setState({ searchQuery: q }, this.fetchData);
        };
        this.fetchData = () => {
            const { searchKeywords } = this.props;
            const { searchQuery } = this.state;
            return searchKeywords({ q: searchQuery }).finally(this.handleSearchResponse);
        };
        this.handleSearchResponse = () => {
            this.props.setLoading(false);
        };
        this.handleSelectKeyword = (keyword) => {
            const { onChange } = this.props;
            this.setState({
                searchQuery: '',
                selectedPercentage: DEFAULT_PERCENTAGE
            }, () => {
                onChange(keyword, DEFAULT_PERCENTAGE);
            });
        };
        this.handleRemoveKeyword = () => {
            const { onChange } = this.props;
            this.setState({
                selectedPercentage: DEFAULT_PERCENTAGE
            });
            onChange(undefined);
        };
        this.getPercentageOptions = () => {
            return PERCENTAGES.reduce((result, p) => {
                result[p] = i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_MAJORITY', {
                    percentage: p
                });
                return result;
            }, {});
        };
        this.handleChangePercentage = (percentage) => {
            const { onChange, selectedKeyword } = this.props;
            this.setState({ selectedPercentage: percentage });
            onChange(selectedKeyword, percentage);
        };
    }
    render() {
        const { foundKeywords, isLoading, selectedKeyword } = this.props;
        const { searchQuery, selectedPercentage } = this.state;
        return (React.createElement(AudienceKeywordsUI_1.default, { foundKeywords: searchQuery ? foundKeywords : [], isLoading: isLoading, onChangePercentage: this.handleChangePercentage, onChangeSearch: this.handleSearchChange, onRemoveKeyword: this.handleRemoveKeyword, onSelect: this.handleSelectKeyword, percentages: this.getPercentageOptions(), searchQuery: searchQuery, selectedKeyword: selectedKeyword, selectedPercentage: selectedPercentage }));
    }
}
function mapStateToProps(state) {
    return {
        foundKeywords: selectors_1.getAudienceKeywords(state)
    };
}
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({
        searchKeywords: SocialAccountsActions.searchAudienceKeywords
    }, dispatch);
}
const Enhanced = withLoading_1.default(AudienceKeywords);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Enhanced);
