"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
class ParticipationDetailPayment extends React.PureComponent {
    render() {
        const { amount, style, totalProducts, currency } = this.props;
        return (React.createElement("h3", { className: 'vf-white-text vf-font-normal width-auto no-margin', style: style, "data-uitest": 'participation-detail-payment' },
            i18n.getMoney({ amount, currency }),
            totalProducts > 0 && (React.createElement(React.Fragment, null,
                "\u00A0+\u00A0",
                totalProducts,
                React.createElement("i", { className: 'vf-icon icon-bag margin-left-Hx', style: {
                        fontSize: '1.5rem',
                        lineHeight: '1.5rem'
                    } })))));
    }
}
ParticipationDetailPayment.defaultProps = {
    amount: 0,
    productsPayment: [],
    totalProducts: 0
};
exports.default = ParticipationDetailPayment;
