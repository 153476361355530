"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const appActions = require("redux/modules/app");
const chats_1 = require("redux/modules/chats");
const selectors_1 = require("modules/shared/selectors");
const components_1 = require("modules/shared/components");
const containers_1 = require("modules/shared/containers");
const MESSAGES_PER_FETCH = 20;
const ChatWithInfluencer = props => {
    const [errors, setErrors] = React.useState([]);
    const { chatId, messages = [], className = '', messagesRequest = {}, conversationStyle = {} } = props;
    const onFetchMembers = () => {
        const { brandId } = props;
        if (!!chatId && !!brandId) {
            props.fetchMembers({ brandId, chatId });
        }
    };
    const onFetchMessages = () => {
        const { brandId, fetchMessages } = props;
        const oldestMessageIndex = lodash_1.get(messages, '[0].index');
        if (!!chatId && !!brandId) {
            fetchMessages({
                brandId,
                chatId,
                limit: MESSAGES_PER_FETCH,
                cursor: oldestMessageIndex
            });
        }
    };
    const onSendMessage = ({ text, attachments }) => {
        const { brandId, sendMessage } = props;
        const args = {
            text,
            attachments,
            chatId,
            brandId
        };
        return sendMessage(args).catch(onSendError);
    };
    const onSendError = () => {
        const { showAlert } = props;
        showAlert({
            type: 'error',
            message: i18n.get('CHAT_SENDING_ERROR')
        });
        return Promise.reject();
    };
    const onSetLastReadMessage = messageId => {
        const { brandId, setLastReadMessage } = props;
        if (!chatId || !brandId || !messageId)
            return;
        setLastReadMessage({ messageId, chatId, brandId });
    };
    const onErrorsChange = errors => {
        setErrors(errors);
    };
    const shouldRenderTextarea = () => {
        const { chat } = props;
        const isLoadingMessages = messagesRequest.status === 'loading';
        const hasMessages = !lodash_1.isEmpty(messages);
        const status = lodash_1.get(chat, 'member.chatStatus');
        if (!hasMessages && isLoadingMessages)
            return false;
        return status !== 'blocked';
    };
    const isLoadingMessages = messagesRequest.status === 'loading';
    const containerClassName = classnames_1.default('col end-xs width100 height100 vf-bg-white-color', className);
    return (React.createElement("div", { className: containerClassName },
        React.createElement(containers_1.ChatConversation, { chatId: chatId, onFetchMembers: onFetchMembers, onFetchMessages: onFetchMessages, onSetLastReadMessage: onSetLastReadMessage, isLoading: isLoadingMessages, errors: errors, style: conversationStyle }),
        shouldRenderTextarea() && (React.createElement(components_1.ChatTextarea, { onSend: onSendMessage, onErrorsChange: onErrorsChange }))));
};
const mapStateToProps = (state, { chatId }) => {
    return {
        chat: selectors_1.getChat(state, chatId),
        messages: selectors_1.getChatMessages(state, chatId),
        messagesRequest: lodash_1.get(state, `app.requests.fetchChatMessages_${chatId}`)
    };
};
const mapDispatchToProps = (dispatch) => {
    return redux_1.bindActionCreators({
        fetchMessages: chats_1.fetchChatMessagesAsAdvertiser,
        fetchMembers: chats_1.fetchChatMembersAsAdvertiser,
        sendMessage: chats_1.sendMessageAsAdvertiser,
        setLastReadMessage: chats_1.setLastReadMessageAsAdvertiser,
        showAlert: appActions.showAlert
    }, dispatch);
};
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(ChatWithInfluencer);
