"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ReactCSSTransitionGroup = require("react-transition-group/CSSTransitionGroup");
const lodash_1 = require("lodash");
const react_portal_1 = require("react-portal");
const classnames_1 = require("classnames");
class CustomDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.leaving = null;
        this.state = {
            showDialog: lodash_1.isNil(this.props.isOpen) ? true : this.props.isOpen
        };
        this.preventDefault = event => {
            event.stopPropagation();
        };
        this.hide = event => {
            this.preventDefault(event);
            this.setState({ showDialog: false });
            document.body.style.overflow = '';
            if (this.props.onClose) {
                this.leaving = window.setTimeout(this.props.onClose, 305);
            }
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!lodash_1.isNil(nextProps.isOpen)) {
            this.setState({ showDialog: nextProps.isOpen });
        }
    }
    componentWillUnmount() {
        clearTimeout(this.leaving);
    }
    render() {
        const { showDialog } = this.state;
        const { size, header, content, footer, className, onClose, dismissable, customContainerStyle, closeIconClassName, headerSmall, refDialog } = this.props;
        let classes = 'vf-dialog';
        if (className)
            classes += ` ${className}`;
        if (size)
            classes += ` vf-dialog-${size}`;
        return (React.createElement(react_portal_1.Portal, null,
            React.createElement(ReactCSSTransitionGroup, { transitionAppear: true, transitionEnterTimeout: 500, transitionLeaveTimeout: 500, transitionAppearTimeout: 500, transitionName: 'vf-animation-dialog' }, showDialog && (React.createElement("div", { key: 'dialog', className: classes, onClick: event => dismissable && this.hide(event) },
                React.createElement("div", { "data-uitest": 'dialog', ref: 'dialogContainer', style: customContainerStyle, onClick: this.preventDefault, className: 'vf-dialog-container' },
                    header && (React.createElement("div", { ref: refDialog, className: classnames_1.default('vf-dialog-header vf-clearfix', {
                            'vf-dialog-header--small': headerSmall
                        }) },
                        React.createElement("div", { className: 'vf-dialog-header-content' }, header),
                        onClose && (React.createElement("div", { onClick: this.hide, className: `icon-close vf-close-button vf-clickable ${closeIconClassName}` })))),
                    React.createElement("div", { ref: 'dialogBody', className: 'vf-dialog-body', style: { height: 'calc(100% - 83px)' } },
                        React.createElement("div", { className: 'vf-dialog-content vf-clearfix height100' }, content),
                        footer && (React.createElement("div", { className: 'vf-dialog-footer vf-clearfix' }, this.props.footer)))))))));
    }
}
CustomDialog.defaultProps = {
    dismissable: true,
    refDialog: null,
    header: null,
    customContainerStyle: {},
    closeIconClassName: '',
    headerSmall: false,
    content: null,
    footer: null,
    className: ''
};
exports.default = CustomDialog;
