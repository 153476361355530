"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const styles = require("./PublicCampaignHeaderUI.less");
const publicCampaignHeaderUI = ({ currency, totalProducts, socialNetworks, productsTotalPrice }) => (React.createElement("div", { className: 'col' },
    React.createElement("div", { className: 'flex flex-justify-right' },
        React.createElement("p", { className: styles.participate }, i18n.get('INF_CAMPAIGN_BRIEF_PARTICIPATE_ACCOUNTS')),
        React.createElement("div", { className: styles.badges }, socialNetworks.map(item => {
            var _a;
            return (React.createElement(components_1.SocialBadgeLG, { key: (item === null || item === void 0 ? void 0 : item.name) || item, className: styles.badge, socialNetwork: ((_a = item === null || item === void 0 ? void 0 : item.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || item }));
        }))),
    totalProducts > 0 && (React.createElement("div", { className: 'flex flex-justify-right margin-top-Hx', style: { gridArea: 'products', whiteSpace: 'nowrap' } },
        React.createElement("i", { className: 'vf-icon icon-bag icon-fix margin-right-Hx' }),
        React.createElement("span", null,
            i18n.get('INF_VOXEAR_CAMPAIGN_BRIEF_PRODUCTS', {
                totalProducts
            }),
            "\u00A0 (",
            i18n.getMoney({ amount: productsTotalPrice, currency }),
            ")")))));
exports.default = publicCampaignHeaderUI;
