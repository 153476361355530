import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPopover from 'react-popover';

class IconTooltip extends React.PureComponent {
  state = { showTip: false };

  toggleTip = () => {
    this.setState({ showTip: !this.state.showTip });
  };

  render() {
    const { showTip } = this.state;
    const { message, icon } = this.props;

    const iconClass = classNames('vf-icon vf-text-black', icon);

    return (
      <ReactPopover
        body={<div className='padding-1x text-center'>{message}</div>}
        isOpen={showTip}
        preferPlace='below'
        className='popover-dark'
      >
        <span
          className='margin-left-Hx'
          style={{ height: '2.2rem' }}
          onMouseEnter={this.toggleTip}
          onMouseLeave={this.toggleTip}
        >
          <i className={iconClass} />
        </span>
      </ReactPopover>
    );
  }
}

export default IconTooltip;

IconTooltip.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};
