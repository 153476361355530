export const getIsInstagramStory = campaign => {
  const {
    socialNetworks: { instagram }
  } = campaign;
  const isStory =
    instagram.post.active === false && instagram.story.active === true;
  return isStory;
};

export const getIsInstagramPostAndStory = campaign => {
  const {
    socialNetworks: { instagram }
  } = campaign;
  const isPostStory =
    instagram.post.active === true && instagram.story.active === true;

  return isPostStory;
};
