import React from 'react';
import { Button } from 'ui-library';
import CustomDialog from 'apps/shared/components/CustomDialog/CustomDialog';
import { iconAlreadyLinkedAccountImg } from 'assets/images';
import { openChat } from 'utils/crispActions';
import i18n from 'i18n';

const AlreadyLinkedAccountError = ({ username, email, onClose = () => {} }) => {
  const header = (
    <span>{i18n.get('INF_SOCIAL_ACCOUNTS_LINK_ERROR_HEADER')}</span>
  );

  const content = (
    <div className='flex col center-xs padding-2x'>
      <img src={iconAlreadyLinkedAccountImg} />

      <div className='margin-top-1x'>
        <h4>{buildDialogMessage({ username, email })}</h4>
      </div>

      <Button color='primary' className='margin-top-2x' onClick={openChat}>
        {i18n.get('INF_SOCIAL_ACCOUNTS_OPEN_CHAT')}
      </Button>
    </div>
  );

  return (
    <CustomDialog
      size='sm'
      header={header}
      content={content}
      onClose={onClose}
    />
  );
};

const buildDialogMessage = ({ username, email }) => {
  const accountSection = username ? (
    <span>
      {i18n.get(
        'INF_SOCIAL_ACCOUNTS_LINK_ERROR_DIALOG_ACCOUNT_SECTION_TEXT_PART_1'
      )}{' '}
      <b>{username}</b>{' '}
      {i18n.get(
        'INF_SOCIAL_ACCOUNTS_LINK_ERROR_DIALOG_ACCOUNT_SECTION_TEXT_PART_2'
      )}
    </span>
  ) : (
    <span>
      {i18n.get(
        'INF_SOCIAL_ACCOUNTS_LINK_ERROR_DIALOG_ACCOUNT_SECTION_TEXT_FULL'
      )}
    </span>
  );

  const emailSection = email ? (
    <span>
      <b>{email}</b>.
    </span>
  ) : (
    <span>
      {i18n.get(
        'INF_SOCIAL_ACCOUNTS_LINK_ERROR_DIALOG_EMAIL_SECTION_OTHER_USER'
      )}
    </span>
  );

  return (
    <span>
      {accountSection} {emailSection}
      <div className='margin-top-1x'>
        {i18n.get('INF_SOCIAL_ACCOUNTS_LINK_ERROR_DIALOG_CONTACT_US')}
      </div>
    </span>
  );
};

export default AlreadyLinkedAccountError;
