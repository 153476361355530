import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'ui-library';
import i18n from 'i18n';
import classNames from 'classnames';
import { get } from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showAlert, resetRequest } from 'redux/modules/app';
import { verifyPhone, sendPhoneVericationCode } from 'redux/modules/users';

import UserPhoneEdition from 'modules/accounts/shared/containers/PhoneVerificationWidget/containers/UserPhoneEdition/UserPhoneEdition';

import styles from './PhoneVerificationWidget.less';

const VERIFICATION_CODE_LENGTH = 6;
const DIGIT_REGEX = /^[0-9]*$/;

const CODE_VERIFICATION_SECTION = 'renderVerificationCodeSection';
const EDIT_USER_PHONE_SECTION = 'renderUserPhoneEditionSection';

const sectionsByActions = {
  [CODE_VERIFICATION_SECTION]: CODE_VERIFICATION_SECTION,
  [EDIT_USER_PHONE_SECTION]: EDIT_USER_PHONE_SECTION
};

function mapStateToProps({ shared, app }) {
  const { user = {} } = shared;
  const { requests } = app;

  const verificationCodeRequestId = getVerificationCodeRequestId(user);
  const codeSendingRequestId = getCodeSendingRequestId(user);
  const verificationCodeRequest = get(requests, verificationCodeRequestId, {});
  const codeSendingRequest = get(requests, codeSendingRequestId, {});

  return { user, verificationCodeRequest, codeSendingRequest };
}

function getVerificationCodeRequestId(user) {
  return `verifyUserPhoneCode_${user._id}`;
}

function getCodeSendingRequestId(user) {
  return `sendPhoneVericationCode_${user._id}`;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showAlert,
      resetRequest,
      verifyPhone,
      sendPhoneVericationCode
    },
    dispatch
  );
}

class PhoneVerificationWidget extends Component {
  constructor(props) {
    super(props);

    const doesUserHasPhone = !!get(props, 'user.phone');

    this.state = {
      code: '',
      loading: false,
      currentSection: doesUserHasPhone
        ? CODE_VERIFICATION_SECTION
        : EDIT_USER_PHONE_SECTION
    };
  }

  UNSAFE_componentWillReceiveProps = newProps => {
    const { verificationCodeRequest, codeSendingRequest } = newProps;

    this.checkVerificationRequestStatus(verificationCodeRequest);
    this.checkCodeSendingRequestStatus(codeSendingRequest);
  };

  checkVerificationRequestStatus = request => {
    const requestStatus = get(request, 'status', '');
    if (this.didRequestFail(requestStatus)) this.onSubmitCodeError();
    if (this.didRequestSucceeded(requestStatus)) {
      this.onSubmitCodeSuccess();
    }
  };

  checkCodeSendingRequestStatus = request => {
    const requestStatus = get(request, 'status', '');
    if (this.didRequestFail(requestStatus)) this.onSubmitPhoneError();
    if (this.didRequestSucceeded(requestStatus)) {
      this.onSubmitPhoneSuccess();
    }
  };

  didRequestSucceeded = requestStatus => {
    return requestStatus === 'success';
  };

  didRequestFail = requestStatus => {
    return requestStatus === 'fail';
  };

  onSubmitCodeSuccess = () => {
    const REQUEST_ID = getVerificationCodeRequestId(this.props.user);
    this.props.showAlert({
      type: 'success',
      message: i18n.get('PHONE_VERIFICATION_RESEND_ALERT_SUCCESS_MESSAGE')
    });

    this.props.resetRequest(REQUEST_ID);
    this.resetCode();
  };

  onSubmitCodeError = () => {
    const REQUEST_ID = getVerificationCodeRequestId(this.props.user);
    this.props.resetRequest(REQUEST_ID);
    this.resetCode();

    this.props.showAlert({
      type: 'error',
      message: i18n.get('PHONE_VERIFICATION_SUBMIT_ALERT_ERROR_MESSAGE')
    });
  };

  onSubmitPhoneSuccess = () => {
    const REQUEST_ID = getCodeSendingRequestId(this.props.user);
    this.props.resetRequest(REQUEST_ID);
    this.props.showAlert({
      type: 'success',
      message: i18n.get('PHONE_VERIFICATION_RESEND_ALERT_SUCCESS_MESSAGE')
    });
  };

  onSubmitPhoneError = () => {
    const REQUEST_ID = getCodeSendingRequestId(this.props.user);
    this.props.resetRequest(REQUEST_ID);
    this.props.showAlert({
      type: 'error',
      message: i18n.get('PHONE_VERIFICATION_RESEND_ALERT_ERROR_MESSAGE')
    });
  };

  onCodeChange = ev => {
    const code = ev.target.value;

    if (!this.isValidVerificationCode(code)) return;

    const shouldSendRequest = code.length === VERIFICATION_CODE_LENGTH;

    this.setState({ code, loading: shouldSendRequest }, () => {
      if (shouldSendRequest) this.verifyCode(code);
    });
  };

  isValidVerificationCode = code => {
    return this.hasJustDigits(code) && code.length <= VERIFICATION_CODE_LENGTH;
  };

  hasJustDigits = text => {
    return DIGIT_REGEX.test(text);
  };

  verifyCode = code => {
    const { user } = this.props;
    this.props.verifyPhone(user._id, code);
  };

  resetCode = () => {
    this.setState({ code: '', loading: false });
  };

  resendCode = () => {
    const { user } = this.props;
    this.props.sendPhoneVericationCode(user._id, user.phone);
  };

  editUserPhone = () => {
    this.setState({ currentSection: EDIT_USER_PHONE_SECTION });
  };

  onPhoneChange = () => {
    this.setState({ currentSection: CODE_VERIFICATION_SECTION });
  };

  render = () => {
    const { className } = this.props;
    const containerClasses = classNames(
      'flex width100 col no-padding',
      className,
      styles.container
    );

    return (
      <div className={containerClasses}>{this.renderCurrentSection()}</div>
    );
  };

  renderCurrentSection = () => {
    const { currentSection } = this.state;
    const renderFnName = sectionsByActions[currentSection];
    return this[renderFnName]();
  };

  renderVerificationCodeSection = () => {
    const { loading, code } = this.state;
    const { user } = this.props;
    const { phone } = user;

    return (
      <div className='no-padding'>
        <div className='width100 padding-bottom-Hx padding-horizontal-2x'>
          <Input
            className='width100 no-horizontal-padding no-horizontal-margin'
            placeholder={i18n.get('PHONE_VERIFICATION_SUBMIT_PLACEHOLDER')}
            onChange={this.onCodeChange}
            value={code}
            disabled={loading}
          />
        </div>
        <div className='no-padding col vf-font-small'>
          <a onClick={this.resendCode}>
            {i18n.get('PHONE_VERIFICATION_RESEND')}
          </a>
          <div className='width100 float-left padding-top-Hx no-horizontal-padding'>
            <span className='vf-text-success'>
              {i18n.get('PHONE_VERIFICATION_SENT')}
            </span>
            <div className='flex width100 center-xs'>
              <b className='vf-text-success'>+{phone}</b>
              <i
                className='vf-clickable vf-clickable padding-left-1x vf-icon icon-edit'
                onClick={this.editUserPhone}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderUserPhoneEditionSection = () => {
    return <UserPhoneEdition onPhoneChange={this.onPhoneChange} />;
  };
}

PhoneVerificationWidget.propTypes = {
  user: PropTypes.object.isRequired,
  verificationCodeRequest: PropTypes.object.isRequired,
  codeSendingRequest: PropTypes.object.isRequired,
  className: PropTypes.string,
  showAlert: PropTypes.func.isRequired,
  resetRequest: PropTypes.func.isRequired,
  verifyPhone: PropTypes.func.isRequired,
  sendPhoneVericationCode: PropTypes.func.isRequired
};

PhoneVerificationWidget.defaultProps = {
  className: ''
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneVerificationWidget);
