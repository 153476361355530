import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './FilterWrapper.less';

class FilterWrapper extends React.Component {
  state = {
    showChildren: false
  };

  toggleChildren = () => {
    this.setState({
      showChildren: !this.state.showChildren || this.props.keepOpen
    });
  };

  render() {
    const { showChildren } = this.state;
    const { children, title, keepOpen } = this.props;

    const wrapperClass = classNames(
      'padding-left-1x padding-right-1x',
      styles.wrapper
    );

    return (
      <div className={wrapperClass}>
        <div className={styles.title} onClick={this.toggleChildren}>
          {title}
        </div>

        {(showChildren || keepOpen) && (
          <div className='padding-bottom-1x'>{children}</div>
        )}
      </div>
    );
  }
}

FilterWrapper.propTypes = {
  title: PropTypes.string,
  keepOpen: PropTypes.bool
};

export default FilterWrapper;
