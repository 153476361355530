"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const PostStatsGridUI_1 = require("./PostStatsGridUI/PostStatsGridUI");
const selectors_1 = require("modules/shared/selectors");
const lodash_1 = require("lodash");
const app_1 = require("config/app");
const react_redux_1 = require("react-redux");
const withProductsTotalizer_1 = require("apps/shared/hocs/withProductsTotalizer");
class PostStatsGrid extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.getSection1Stats = () => {
            const { post } = this.props;
            const { socialNetwork, publicationType, campaignStats } = post;
            const isPost = publicationType === 'post';
            const hasLink = !lodash_1.isNil(campaignStats.urlClicks);
            const stats = ['shares', 'comments', 'engagements'];
            if (isPost)
                stats.push('likes');
            if (socialNetwork === 'facebook') {
                stats.push('reactionsLove', 'reactionsWow', 'reactionsHaha', 'reactionsSad', 'reactionsAngry');
            }
            if (hasLink)
                stats.push('urlClicks');
            return stats;
        };
        this.getSection2Stats = () => {
            const { post } = this.props;
            const { campaignStats } = post;
            const stats = ['reach', 'videoViews'];
            stats.push(lodash_1.isNil(campaignStats.impressions) ? 'impressionsEstimated' : 'impressions');
            stats.push(lodash_1.isNil(campaignStats.impressionsUnique)
                ? 'impressionsUniqueEstimated'
                : 'impressionsUnique');
            stats.push('engagementRate', 'engRateByImpressions');
            return stats;
        };
        this.getSection3Stats = () => {
            const { post } = this.props;
            const { publicationType, campaignStats } = post;
            const isPost = publicationType === 'post';
            const hasLink = !lodash_1.isNil(campaignStats.urlClicks);
            const showCPC = hasLink || !isPost;
            const stats = [
                'costParticipation',
                'serviceFee',
                'costTotal',
                'cpe',
                'cpv',
                'cpm'
            ];
            if (showCPC)
                stats.push('cpc');
            return stats;
        };
        this.getFormatedInfo = (key, value) => {
            const { currentUser, post } = this.props;
            const { currency } = post;
            const { role } = currentUser;
            const adKey = `${key}-${role}`;
            const config = app_1.getStatics().campaignStats[adKey] || app_1.getStatics().campaignStats[key];
            return typeof value === 'number'
                ? {
                    key,
                    title: config.name,
                    detail: config.fullName,
                    info: config.value(value, currency)
                }
                : null;
        };
    }
    render() {
        const { canReadCampaignCost, totalProducts, productsPayment, post } = this.props;
        const { campaignStats } = post;
        const section1 = lodash_1.chain(campaignStats)
            .pick(this.getSection1Stats())
            .map((value, key) => this.getFormatedInfo(key, value))
            .value();
        const section2 = lodash_1.chain(campaignStats)
            .pick(this.getSection2Stats())
            .map((value, key) => this.getFormatedInfo(key, value))
            .value();
        const section3 = lodash_1.chain(campaignStats)
            .pick(this.getSection3Stats())
            .map((value, key) => this.getFormatedInfo(key, value))
            .value();
        return (React.createElement(PostStatsGridUI_1.default, { section1: section1, section2: section2, section3: section3, productQuantity: totalProducts, productsPayment: productsPayment, canReadCampaignCost: canReadCampaignCost }));
    }
}
const mapStateToProps = state => ({
    currentUser: selectors_1.getCurrentUser(state)
});
const Connect = react_redux_1.connect(mapStateToProps)(PostStatsGrid);
exports.default = withProductsTotalizer_1.default(Connect);
