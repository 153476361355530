import _ from 'lodash';
import is from 'is_js';
import ReactDOM from 'react-dom';

function createPathThree(object) {
  let pathThreeBuffer = [];

  function pathWalk(currentObject, currentPath) {
    if (_.isPlainObject(currentObject)) {
      _.map(currentObject, (el, key) => {
        pathWalk(el, currentPath ? currentPath + '.' + key : key);
      });
    } else {
      pathThreeBuffer.push(currentPath);
    }
  }

  pathWalk(object);
  return pathThreeBuffer;
}

module.exports = {
  is,

  componentDidMount() {
    this.updateValidateTypes();
  },

  getInitialState() {
    return {
      validationActive: false
    };
  },

  linkedValidation(property, customOnChange, customClassNames) {
    const value = _.get(this.state, property);

    const linkFunction = evt => {
      this.state = _.set(
        this.state,
        property,
        _.has(evt, 'target') ? _.get(evt, ['target', 'value']) : evt
      );
      this.forceUpdate();
    };

    const validatedClassNames =
      this.state.validationActive && !this.validateProperty(property, value)
        ? 'error'
        : '';

    return {
      className:
        validatedClassNames + (customClassNames ? ` ${customClassNames}` : ''),
      value,
      onChange: linkFunction
    };
  },

  linkToParent(property, customOnChange, customClassNames, modifier) {
    let value = _.get(this.props, property);

    const linkFunction = evt => {
      let value = _.has(evt, 'target') ? _.get(evt, ['target', 'value']) : evt;

      _.isFunction(customOnChange) && customOnChange(evt);

      this.props.onChange(
        property,
        _.isFunction(modifier) ? modifier(value) : value
      );
    };

    const validatedClassNames =
      this.state.validationActive && !this.validateProperty(property, value)
        ? 'error'
        : '';

    return {
      className:
        validatedClassNames + (customClassNames ? ` ${customClassNames}` : ''),
      value,
      onChange: linkFunction
    };
  },

  validateProperty(property, value) {
    if (!this.validateTypes) return true;

    const validateFunc = _.get(this.validateTypes, property);

    if (!validateFunc) return true;

    return validateFunc(value, this);
  },

  updateValidateTypes() {
    this.validationObjects = _.reduce(
      createPathThree(this.validateTypes),
      (res, el) => {
        res[el] = _.get(this.validateTypes, el);
        return res;
      },
      {}
    );
  },

  validateAll() {
    this.setState(
      {
        validationActive: true
      },
      () => {
        const el = ReactDOM.findDOMNode(this).getElementsByClassName('error');

        if (el[0]) el[0].focus();
      }
    );

    const result = !_.some(this.validationObjects, (el, index) => {
      return !this.validateProperty(
        index,
        _.get(this.props, index) || _.get(this.state, index)
      );
    });

    if (!result) {
      const invalidKeys = _.reduce(
        this.validationObjects,
        (res, el, index) => {
          if (
            !this.validateProperty(
              index,
              _.get(this.props, index) || _.get(this.state, index)
            )
          ) {
            res.push(index);
          }

          return res;
        },
        []
      );

      console.log('\nVALIDATION ERROR', invalidKeys);
    }

    return result;
  },

  linkedState(property) {
    const value = _.get(this.state, property);

    const linkFunction = evt => {
      this.state = _.set(this.state, property, evt.target.value);
      this.forceUpdate();
    };

    return {
      value,
      onChange: linkFunction
    };
  }
};
