import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import Search from '../../legacy/js/components/search';
import { debounce } from 'lodash';

const DebouncedSearch = createClass({
  propTypes: {
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
  },

  getDefaultProps() {
    return {
      className: 'default-class',
      value: ''
    };
  },

  getInitialState() {
    const { value } = this.props;

    return {
      value: value || ''
    };
  },

  componentDidMount() {
    this.debouncedSearchValue = debounce(value => {
      const { onChange } = this.props;
      onChange(value);
    }, 500);
  },

  onSearchChange(event) {
    const { value } = event.target;
    this.setState({ value });
    this.debouncedSearchValue(value);
  },

  render() {
    const { className, ...other } = this.props;
    const { value } = this.state;

    return (
      <Search
        {...other}
        value={value}
        className={className}
        onChange={this.onSearchChange}
      />
    );
  }
});

export default DebouncedSearch;
