/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import classNames from 'classnames';
import { string, bool } from 'prop-types';

import styles from './YoutubeVideoPreview.less';

const YoutubeVideoPreview = ({
  srcVideo,
  mute,
  relatedVideos,
  autoplay,
  small
}) => {
  const options = [];

  if (!relatedVideos) options.push('rel=0');
  if (mute) options.push('mute=1');
  if (autoplay) options.push('autoplay=1');

  const optionsString = options.join('&');

  return (
    <div
      className={classNames(styles.container, {
        [styles['container--small']]: small
      })}
    >
      <iframe
        className={classNames(styles.iframe, {
          [styles['iframe--small']]: small
        })}
        src={`https://www.youtube.com/embed/${srcVideo}?${optionsString}`}
        frameBorder='0'
        allow='autoplay'
        allowFullScreen='allowfullscreen'
        mozallowfullscreen='mozallowfullscreen'
        msallowfullscreen='msallowfullscreen'
        oallowfullscreen='oallowfullscreen'
        webkitallowfullscreen='webkitallowfullscreen'
      />
    </div>
  );
};

YoutubeVideoPreview.propTypes = {
  srcVideo: string.isRequired,
  mute: bool.isRequired,
  relatedVideos: bool.isRequired,
  autoplay: bool.isRequired,
  small: bool
};

YoutubeVideoPreview.defaultProps = {
  small: false
};

export default YoutubeVideoPreview;
