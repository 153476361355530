import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

const Toggle = createClass({
  propTypes: {
    value: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    lite: PropTypes.bool
  },

  getDefaultProps() {
    return {
      disabled: false
    };
  },

  onChange() {
    if (this.props.disabled) return;

    if (this.props.onClick) this.props.onClick();

    if (this.props.onChange) {
      this.props.onChange(!this.props.value);
    }
  },

  render() {
    const { value, className, disabled, lite } = this.props;

    let classes = 'vf-toggle vf-clickable';

    if (value) classes += ' on';
    if (lite) classes += ' lite';
    if (className) classes += ` ${className}`;
    if (disabled) classes += ' -disabled';

    return (
      <div onClick={this.onChange} className={classes}>
        <div className='vf-toggle-selector' />
      </div>
    );
  }
});

export default Toggle;
