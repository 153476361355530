"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const styles = require("./PostBanner.less");
const types_1 = require("modules/shared/types");
const popover_1 = require("modules/legacy/js/components/popover");
const PostBanner = ({ link, socialNetwork }) => {
    const formatInstagramURL = () => {
        let id = '';
        try {
            const regex = /\/(?:p|reel)\/([A-Za-z0-9-_]+)/;
            const matches = link.match(regex);
            id = matches[1];
        }
        catch (_a) { }
        return `https://www.instagram.com/p/${id}/embed`;
    };
    const formatFacebookDOM = () => {
        return `
      <div
        class="fb-post"
        data-href="${link}"
        data-width="500"
      ></div>
      <script
        async
        defer
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"
      ></script>
    `;
    };
    const formatYoutubeURL = () => {
        const getYouTubeVideoIdFromUrl = (url) => {
            try {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = url.match(regExp);
                return match[2];
            }
            catch (_a) {
                return '';
            }
        };
        const id = getYouTubeVideoIdFromUrl(link);
        return `https://www.youtube.com/embed/${id}`;
    };
    const formatTikTokURL = () => {
        const isVmLink = link.includes('vm.tiktok.');
        let id = '';
        try {
            const [regex, position] = isVmLink
                ? [/^https?:\/\/(?:www\.)?vm\.tiktok\.com\/([\w.-]+)/i, 1]
                : [/(@[a-zA-z0-9]*|.*)(\/.*\/|trending.?shareId=)([\d]*)/, 3];
            const matches = link.match(regex);
            id = matches[position];
        }
        catch (_a) { }
        return `https://www.tiktok.com/embed/${id}`;
    };
    const getPost = () => {
        let source = {};
        switch (socialNetwork) {
            case types_1.SocialNetwork.INSTAGRAM:
                source = { src: formatInstagramURL() };
                break;
            case types_1.SocialNetwork.FACEBOOK:
                source = { srcDoc: formatFacebookDOM() };
                break;
            case types_1.SocialNetwork.YOUTUBE:
                source = { src: formatYoutubeURL() };
                break;
            case types_1.SocialNetwork.TIKTOK:
                source = { src: formatTikTokURL() };
                break;
        }
        return React.createElement("iframe", Object.assign({ className: styles['post'] }, source));
    };
    return (React.createElement("div", { className: styles['post-banner'] },
        React.createElement("p", { className: styles['link-section'] },
            React.createElement(popover_1.default, { preferPlace: 'right', body: i18n.get('ADV_POST_BANNER_LINK_WARNING') },
                React.createElement("div", { className: styles.circle },
                    React.createElement("div", { className: styles.circle__help }, "?")))),
        React.createElement("div", { className: styles['banner'] }, getPost())));
};
exports.default = PostBanner;
