import config from '../../config/app';
import {
  setAPIHost,
  setIdentityHeader,
  setLocaleHeader,
  unsetIdentityHeader,
  setParamsSerializer
} from './configurator';

import * as auth from './auth';
import * as accounts from './accounts';
import * as reports from './reports';
import * as campaigns from './campaigns';
import * as campaignsLite from './campaignsLite';
import * as socialAccounts from './socialAccounts';
import * as users from './users';
import * as brands from './brands';
import * as brandsLite from './brandsLite';
import * as shared from './shared';
import * as chats from './chats';
import * as organizations from './organizations';
import * as teams from './teams';
import * as publications from './publications';
import * as places from './places';
import * as videos from './videos';
import * as takeRates from './takeRates';
import * as tracking from './tracking';
import * as socialNetworksLite from './socialNetworksLite';
import * as participationLite from './participationLite';
import * as activateParticipateLite from './activateParticipateLite';
import * as postLite from './postLite';
import * as activePostLite from './activePostLite';
import * as historyPostLite from './historyPostLite';
import * as socialNetworkLite from './socialNetworkLite';
import * as moderateReports from './moderateReports';
import * as blockAccountLite from './blockAccountLite';
import * as addressInfluencerLite from './addressInfluencerLite';
import * as penalizePostLite from './penalizePostLite';

setAPIHost(config.getAPIHost());
setParamsSerializer();

export {
  auth,
  accounts,
  reports,
  campaigns,
  campaignsLite,
  socialAccounts,
  users,
  brands,
  brandsLite,
  shared,
  chats,
  setIdentityHeader,
  unsetIdentityHeader,
  setLocaleHeader,
  organizations,
  teams,
  publications,
  places,
  videos,
  takeRates,
  tracking,
  socialNetworksLite,
  participationLite,
  activateParticipateLite,
  postLite,
  activePostLite,
  historyPostLite,
  socialNetworkLite,
  moderateReports,
  blockAccountLite,
  addressInfluencerLite,
  penalizePostLite
};
