import React from 'react';
import PropTypes from 'prop-types';
import { reduce, omit, debounce } from 'lodash';
import { connect } from 'react-redux';
import { getInterests } from 'modules/shared/selectors';
import i18n from 'i18n';
import classNames from 'classnames';
import styles from './SearchBar.less';

const SCROLL_TRIGGER = 5;

class SearchBar extends React.Component {
  headerHeight = 0;
  previousY = 0;
  show = true;

  static defaultProps = {
    interests: [],
    onRequestChange: () => {}
  };

  state = {
    sticky: false,
    showInterests: false,
    selectedInterest: {},
    value: ''
  };

  componentDidMount() {
    const mainHeader = document.getElementById('main-header');
    if (mainHeader === undefined) return;

    this.previousY = window.scrollY;
    this.headerHeight = mainHeader.clientHeight;
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    const mainHeader = document.getElementById('main-header');
    if (mainHeader === undefined) return;

    this.headerHeight = mainHeader.clientHeight;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  toggleInterests = () => {
    this.setState({ showInterests: !this.state.showInterests });
  };

  handleScroll = event => {
    const currentY = window.scrollY;
    const parentY = this.refs.bar.parentElement.getBoundingClientRect().y;
    if (parentY <= 0 && !this.state.sticky) {
      this.setState({ sticky: true });
    }

    if (currentY < SCROLL_TRIGGER && this.state.sticky) {
      this.setState({ sticky: false });
      this.refs.bar.style.transform = '';
    }

    if (currentY < this.previousY && this.state.sticky) {
      this.refs.bar.style.transform = `translateY(${this.headerHeight}px)`;
    }

    if (currentY > this.previousY && this.state.sticky) {
      this.refs.bar.style.transform = 'translateY(0)';
    }

    this.previousY = currentY;
  };

  handleTagClick = interest => {
    const { selectedInterest } = this.state;
    const isInterestAlreadySelected = !!selectedInterest[interest.code];
    const newInterests = isInterestAlreadySelected
      ? omit(selectedInterest, interest.code)
      : { ...selectedInterest, [interest.code]: interest };

    this.setState({ selectedInterest: newInterests });
    const interests = Object.keys(newInterests).join(',');
    this.props.onRequestChange({ interests: interests || undefined });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { value } = event.target.elements.search;
    this.props.onRequestChange({ q: value });
  };

  handleChange = event => {
    const { value } = event.target;
    debounce(() => this.props.onRequestChange({ q: value }), 500)();
    this.setState({ value });
  };

  clear = () => {
    this.setState({ value: '', showInterests: false, selectedInterest: {} });
  };

  render() {
    const { interests } = this.props;
    const { sticky, showInterests, selectedInterest, value } = this.state;

    interests.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    const rotateCaret = showInterests ? '180deg' : '0deg';
    const barClass = classNames({ [styles.sticky]: sticky });
    const searchClass = classNames(styles['search-button'], {
      [styles['stick-button']]: sticky
    });
    const caretClass = classNames(styles['caret-down'], {
      [styles['stick-button']]: sticky
    });
    const wrapperStyles = { fontSize: sticky ? '2.3rem' : '3.3rem' };

    const interestList = interests.map(interest => (
      <InterestTag
        key={interest.code}
        interest={interest}
        selected={!!selectedInterest[interest.code]}
        onRequestClick={() => this.handleTagClick(interest)}
      />
    ));

    const selectedList = reduce(
      selectedInterest,
      (result, interest) => {
        result.push(
          <InterestTag
            key={interest.code}
            interest={interest}
            selected
            showClear
            onRequestClick={() => this.handleTagClick(interest)}
          />
        );
        return result;
      },
      []
    );

    return (
      <div style={{ minHeight: 70 }}>
        <div className={barClass} ref='bar' style={{ transition: 'all 0.2' }}>
          <div className={styles.wrapper} style={wrapperStyles}>
            <form
              action='#'
              className='flex flex-align-center'
              onSubmit={this.handleSubmit}
            >
              <button className={searchClass} type='submit'>
                <i className='vf-icon icon-search' />
              </button>
              <input
                name='search'
                type='text'
                autoComplete='off'
                className={styles.input}
                value={value}
                onChange={this.handleChange}
                placeholder={i18n.get(
                  'ADV_INFLUENCERS_SEARCH_FILTER_INPUT_PLACEHOLDER'
                )}
              />
            </form>

            <div
              onClick={this.toggleInterests}
              className={styles['selector-wrapper']}
            >
              {i18n.get('ADV_INFLUENCERS_SEARCH_TABLE_HEADERS_INTERESTS')}
              <span
                className={caretClass}
                style={{ transform: `rotate(${rotateCaret})` }}
              >
                <i className='vf-icon icon-caret-down-two' />
              </span>
            </div>

            {showInterests && (
              <div className={styles['interest-wrapper']}>{interestList}</div>
            )}
          </div>

          {!showInterests && selectedList.length > 0 && (
            <div className={styles['selected-wrapper']}>{selectedList}</div>
          )}
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  onRequestChange: PropTypes.func,
  interests: PropTypes.array
};

const mapStateToProps = state => ({
  interests: getInterests(state)
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  SearchBar
);

const InterestTag = ({ interest, selected, showClear, onRequestClick }) => (
  <div
    className={classNames(styles.tag, { [styles['tag-selected']]: selected })}
    onClick={onRequestClick}
  >
    {interest.name}
    {showClear && (
      <span>
        <i className='vf-icon icon-close' />
      </span>
    )}
  </div>
);

InterestTag.propTypes = {
  showClear: PropTypes.bool,
  selected: PropTypes.bool,
  interest: PropTypes.object.isRequired,
  onRequestClick: PropTypes.func.isRequired
};

InterestTag.defaultProps = {
  showClear: false,
  selected: false
};
