import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce, isEmpty, get, filter } from 'lodash';
import { Input } from 'ui-library';
import i18n from 'i18n';

import { resetRequest } from 'redux/modules/app';
import { searchTag } from 'redux/modules/socialAccounts';

import styles from './TagsSearcher.less';

const SEARCH_DELAY_IN_MS = 500;
const REQUEST_ID = 'searchTag';

function mapStateToProps({ app }) {
  const request = get(app, `requests.${REQUEST_ID}`);
  return { request };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ searchTag, resetRequest }, dispatch);
}

class TagsSearcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      isLoading: false,
      hasError: false,
      searchResult: [],
      isSearchResultBeingShown: false,
      selectedTags: []
    };
  }

  UNSAFE_componentWillMount = () => {
    this.props.resetRequest(REQUEST_ID);
  };

  componentDidMount = () => {
    this.setupDebouncedSearch();
    this.setupClickListener();
  };

  componentWillUnmount = () => {
    this.removeClickListener();
  };

  UNSAFE_componentWillReceiveProps = props => {
    if (props.request.status === 'success')
      return this.onSuccess(props.request);
    if (props.request.status === 'loading') return this.onLoading();
    if (props.request.status === 'fail') return this.onFail();
  };

  onLoading() {
    this.setState({
      isLoading: true,
      isSearchResultBeingShown: true,
      hasError: false,
      searchResult: []
    });
  }

  onSuccess(request) {
    this.setState(
      {
        isLoading: false,
        isSearchResultBeingShown: true,
        hasError: false,
        searchResult: request.response || []
      },
      () => this.props.resetRequest(REQUEST_ID)
    );
  }

  onFail() {
    this.setState({
      isLoading: false,
      isSearchResultBeingShown: true,
      hasError: true,
      searchResult: []
    });
  }

  onQueryChange = e => {
    this.setState({ query: e.target.value }, this.search);
  };

  onTagSelect = tag => {
    const wasTagAlreadySelected = !!this.props.tags.find(t => t.id === tag.id);

    if (wasTagAlreadySelected)
      return this.setState({ isSearchResultBeingShown: false, query: '' });

    this.setState({ isSearchResultBeingShown: false, query: '' }, () => {
      const newTags = [...this.props.tags, tag];
      this.props.onChange(newTags);
    });
  };

  onTagRemove = id => {
    this.setState({ isSearchResultBeingShown: false }, () => {
      const newTags = filter(this.props.tags, t => t.id !== id);
      this.props.onChange(newTags);
    });
  };

  onInputFocus = () => {
    if (!!this.state.query) this.setState({ isSearchResultBeingShown: true });
  };

  setupClickListener = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  removeClickListener = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  handleClickOutside = e => {
    if (this.state.isSearchResultBeingShown && !this.ref.contains(e.target)) {
      this.setState({ isSearchResultBeingShown: false });
    }
  };

  setupDebouncedSearch = () => {
    this.search = debounce(() => {
      const { query } = this.state;
      this.props.resetRequest(REQUEST_ID);
      if (!query) return this.setState({ isSearchResultBeingShown: false });
      const showCanParticipateOnly = true;
      this.props.searchTag(query, showCanParticipateOnly);
    }, SEARCH_DELAY_IN_MS);
  };

  render = () => {
    const { tags = [] } = this.props;
    const {
      query,
      isLoading,
      isSearchResultBeingShown,
      hasError,
      searchResult
    } = this.state;

    return (
      <div
        className={styles.container}
        onClick={this.openInput}
        ref={r => (this.ref = r)}
      >
        <div className='flex flex-align-top'>
          <i
            className='vf-icon icon-search'
            style={{ position: 'absolute', left: '2rem' }}
          />
          <Input
            style={{ padding: '0 5rem 1rem' }}
            placeholder={i18n.get(
              'ADV_INFLUENCERS_SEARCH_FILTER_TAGS_INPUT_PLACEHOLDER'
            )}
            value={query}
            onChange={this.onQueryChange}
            onFocus={this.onInputFocus}
          />
        </div>

        {isSearchResultBeingShown && (
          <SearchResult
            isLoading={isLoading}
            hasError={hasError}
            tags={searchResult}
            onSelect={this.onTagSelect}
            isSearchResultBeingShown={isSearchResultBeingShown}
          />
        )}

        {!isEmpty(tags) && (
          <SelectedTags tags={tags} onRemove={this.onTagRemove} />
        )}
      </div>
    );
  };
}

TagsSearcher.propTypes = {
  tags: PropTypes.array.isRequired,
  request: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  resetRequest: PropTypes.func.isRequired,
  searchTag: PropTypes.func.isRequired
};

TagsSearcher.defaultProps = {
  tags: [],
  request: {},
  onChange: () => {},
  searchTag: () => {},
  resetRequest: () => {}
};

function SearchResult({
  isLoading,
  hasError,
  tags,
  onSelect,
  isSearchResultBeingShown
}) {
  const hasTagsToShow = !isEmpty(tags);

  return (
    <div className={styles['results-container']}>
      {isLoading && <div className='padding-Hx'>{i18n.get('LOADING')}</div>}

      {!isLoading && hasError && (
        <div className='padding-Hx vf-text-danger'>
          {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_TAGS_SEARCH_ERROR')}
        </div>
      )}

      {!isLoading &&
        !hasError &&
        hasTagsToShow &&
        isSearchResultBeingShown &&
        tags.map(t => (
          <div
            key={t.id}
            className={`flex between-xs ${styles['result-item']}`}
            onClick={() => onSelect(t)}
          >
            <span>{t.name}</span>
            <span className='vf-text-gray'>{t.count}</span>
          </div>
        ))}

      {!isLoading && !hasError && !hasTagsToShow && (
        <div className='padding-Hx'>
          {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_TAGS_SEARCH_NO_RESULTS')}
        </div>
      )}
    </div>
  );
}

SearchResult.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSearchResultBeingShown: PropTypes.bool.isRequired
};

SearchResult.defaultProps = {
  isLoading: false,
  tags: [],
  onSelect: () => {},
  isSearchResultBeingShown: false
};

function SelectedTags({ tags, onRemove }) {
  return (
    <div className='vf-row no-margin'>
      {tags.map(t => (
        <div key={t.id} className={styles.tag}>
          {t.name}
          <span onClick={() => onRemove(t.id)}>
            <i className='vf-icon icon-close' />
          </span>
        </div>
      ))}
    </div>
  );
}

SelectedTags.propTypes = {
  tags: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired
};

SelectedTags.defaultProps = {
  tags: [],
  onRemove: () => {}
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsSearcher);
