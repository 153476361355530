"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const app_1 = require("../../../../config/app");
const API = require("modules/legacy/js/lib/api");
const UploadAvatarUI_1 = require("apps/shared/components/UploadAvatar/UploadAvatarUI");
const app_2 = require("modules/legacy/js/actions/app");
const MAX_IMAGE_SIZE = app_1.getMaxImageSize();
class UploadAvatar extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            loading: false
        };
        this.onSelectImage = event => {
            const uploadData = {};
            this.setState({
                loading: true
            });
            if (!event.target.files[0])
                return;
            uploadData.file = event.target.files[0];
            if (uploadData.file.size > MAX_IMAGE_SIZE)
                return this.onMaxFileSizeExceeded();
            uploadData.type = this.props.type;
            API.uploadImage(uploadData, (err, res) => {
                if (err || res.code)
                    return console.error(err);
                this.setState({
                    loading: false
                });
                if (this.props.onChange)
                    this.props.onChange(res.id, res.data.url);
            });
        };
        this.onMaxFileSizeExceeded = () => {
            this.setState({ loading: false }, () => {
                this.clearUploadInputVal();
                app_2.displayAlert({
                    type: 'warning',
                    title: i18n.get('INF_SETTINGS_ACCOUNT_UPLOAD_MAX_FILE_SIZE_WARNING_TITLE'),
                    message: i18n.get('INF_SETTINGS_ACCOUNT_UPLOAD_MAX_FILE_SIZE_WARNING_MESSAGE')
                });
            });
        };
        this.clearUploadInputVal = () => {
            this.uploader.input.value = null;
        };
    }
    render() {
        const { loading } = this.state;
        const { image, value, label } = this.props;
        return (React.createElement(UploadAvatarUI_1.default, { image: image || value, label: label, loading: loading, onChange: this.onSelectImage, ref: ref => (this.uploader = ref) }));
    }
}
exports.default = UploadAvatar;
