"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const get = require("lodash/get");
const ui_library_1 = require("ui-library");
const classnames_1 = require("classnames");
const react_redux_1 = require("react-redux");
const Dotdotdot = require("react-dotdotdot");
const types_1 = require("modules/shared/types");
const controllers_1 = require("apps/influencers/shared/controllers");
const i18n = require("i18n");
const images_1 = require("assets/images");
const styles = require("./ParticipationProducts.less");
const components_1 = require("modules/shared/components");
const selectors_1 = require("modules/shared/selectors");
const AddressLink_1 = require("apps/shared/components/AddressLink");
class ParticipationProducts extends React.Component {
    constructor() {
        super(...arguments);
        this.handleRemove = (ev) => {
            const { onRemove } = this.props;
            const { id } = ev.currentTarget.dataset;
            onRemove(id);
        };
        this.renderProduct = (participationProduct) => {
            const { details, onEditShippingAddress, onEdit, onRemove, currency, lite, influencer, handleSaveAddress, addressError, setInputEmpty, addressLite, editAddress, addressSuccess, handleUpdateAddress } = this.props;
            const productDetails = details[participationProduct.productId];
            const deliveryType = get(participationProduct, 'deliveryInfo.type');
            const address = get(participationProduct, 'deliveryInfo.address');
            const isInputEmpty = () => {
                setInputEmpty(true);
            };
            if (!productDetails)
                return null;
            return (React.createElement("div", { key: participationProduct.productId, className: classnames_1.default('margin-bottom-1x', styles.product, {
                    [styles['product--lite']]: lite,
                    [styles['product--lite-influencer']]: lite && influencer
                }) },
                React.createElement("div", { className: styles.image },
                    React.createElement(components_1.MediaCarousel, { images: productDetails.images, style: { height: '21.75rem' }, width: 250, height: 250, validLite: lite, isProduct: true })),
                React.createElement("div", { className: classnames_1.default('col between-xs padding-1x', styles.details) },
                    React.createElement("div", null,
                        React.createElement("div", { className: 'flex middle-xs between-xs vf-clickable' },
                            React.createElement("h3", { className: classnames_1.default('vf-font-bold no-margin ellipsis', styles.title) }, productDetails.name),
                            onEdit && (React.createElement(ui_library_1.Button, { outline: true, color: 'default', onClick: () => onEdit(participationProduct.productId), className: styles['btn-trash'] },
                                React.createElement("img", { src: images_1.EditLine, alt: 'icon-edit' }))),
                            onRemove && (React.createElement(ui_library_1.Button, { outline: true, color: 'default', onClick: this.handleRemove, className: styles['btn-trash'], "data-id": participationProduct.productId },
                                React.createElement("img", { src: images_1.TrashLine, alt: 'icon-trash' })))),
                        React.createElement("div", { className: 'vf-text-gray-50 margin-top-Hx' },
                            React.createElement(Dotdotdot, { clamp: 2 }, productDetails.description))),
                    React.createElement("div", { className: 'col bottom-xs width100 margin-top-1x' },
                        React.createElement("div", { className: 'flex flex-justify-start width100' },
                            React.createElement("span", null, !!address && !onEditShippingAddress && (React.createElement(AddressLink_1.default, { address: address, deliveryType: deliveryType }))),
                            React.createElement("span", null,
                                React.createElement("b", { className: 'margin-right-1x', "data-uitest": 'product-quantity' },
                                    lite
                                        ? i18n.get('INF_VOXEAR_LITE_PAYMENT_IN_KIND_QUANTITY')
                                        : i18n.get('INF_VOXEAR_PAYMENT_IN_KIND_QUANTITY'),
                                    ":\u00A0",
                                    i18n.getNumber(participationProduct.quantity)),
                                productDetails.price > 0 && (React.createElement("b", { "data-uitest": 'product-price' },
                                    i18n.get('INF_VOXEAR_PAYMENT_IN_KIND_VALUE'),
                                    ":\u00A0",
                                    i18n.getMoney({
                                        amount: productDetails.price,
                                        currency
                                    }))))),
                        !!address && onEditShippingAddress && (React.createElement("div", { className: 'flex start-xs between-xs width100 margin-top-Hx' },
                            React.createElement(AddressLink_1.default, { address: address, deliveryType: deliveryType }),
                            deliveryType === types_1.ProductDeliveryType.SHIP && (React.createElement(ui_library_1.Button, { className: 'vf-primary-text vf-font-bold no-padding', color: 'flat', onClick: onEditShippingAddress, size: 'md' }, i18n.get('PAY_IN_KIND_EDIT_SHIPPING_ADDRESS')))))),
                    lite && deliveryType === types_1.ProductDeliveryType.SHIP && influencer && (React.createElement("div", { className: classnames_1.default(styles.delivery, {
                            [styles.delivery__edit]: !editAddress &&
                                addressLite.id &&
                                (!addressSuccess || addressSuccess)
                        }) }, !addressLite.id ||
                        (addressLite.id && editAddress && !addressSuccess) ? (React.createElement(controllers_1.ProductsPaymentAddressInput, { error: addressError, onSave: !editAddress ? handleSaveAddress : handleUpdateAddress, isInputEmpty: isInputEmpty, lite: true })) : (React.createElement(React.Fragment, null,
                        React.createElement(AddressLink_1.default, { address: addressLite.address, deliveryType: deliveryType }),
                        React.createElement(ui_library_1.Button, { className: 'vf-primary-text vf-font-bold no-padding', color: 'flat', onClick: onEditShippingAddress, size: 'md' }, i18n.get('PAY_IN_KIND_EDIT_SHIPPING_ADDRESS')))))))));
        };
    }
    render() {
        const { products } = this.props;
        return React.createElement(React.Fragment, null, products.map(this.renderProduct));
    }
}
function mapStateToProps(state, { products = [] }) {
    const productIds = products.map(p => p.productId);
    return {
        details: selectors_1.getProductsByIds(state, productIds)
    };
}
exports.default = react_redux_1.connect(mapStateToProps)(ParticipationProducts);
