"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const i18n = require("i18n");
const appActions = require("redux/modules/app");
const sharedActions = require("redux/modules/shared");
const usersActions = require("redux/modules/users");
const selectors_1 = require("modules/shared/selectors");
const UserLocaleSelectorUI_1 = require("./UserLocaleSelectorUI");
function mapStateToProps(state) {
    return {
        user: selectors_1.getCurrentUser(state),
        updateRequest: selectors_1.getRequest(state, 'updateUser'),
        availableLanguages: selectors_1.getAvailableLanguages(state)
    };
}
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({
        updateUser: usersActions.updateUser,
        updateCurrentUserInfo: sharedActions.updateCurrentUserInfo,
        changeLanguage: appActions.changeLanguage
    }, dispatch);
}
class UserLocaleSelector extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isLoading: false
        };
        this.onLocaleChange = (locale) => {
            const { user, updateUser } = this.props;
            this.setState({ isLoading: true }, () => {
                updateUser({ locale, id: user._id })
                    .then(this.updateAppLocale)
                    .finally(() => this.setState({ isLoading: false }));
            });
        };
        this.updateAppLocale = () => {
            const { updateCurrentUserInfo, changeLanguage, updateRequest } = this.props;
            const user = lodash_1.chain(updateRequest)
                .get('response.data')
                .omit('account')
                .value();
            changeLanguage(user.locale);
            updateCurrentUserInfo({ user });
        };
    }
    render() {
        const { user, availableLanguages } = this.props;
        return (React.createElement(UserLocaleSelectorUI_1.default, { isLoading: this.state.isLoading, locale: user.locale || i18n.getLocale(), availableLanguages: availableLanguages, onLocaleChange: this.onLocaleChange }));
    }
}
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(UserLocaleSelector);
