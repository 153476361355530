import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import classNames from 'classnames';

export default function VerifiedState({ className = '' }) {
  const containerClasses = classNames('flex', className);

  return (
    <div className={containerClasses}>
      <div className='flex vf-ok-text vf-font-bold'>
        {i18n.get('INF_SETTINGS_ACCOUNT_VERIFIED_SECTION_LABEL_TITLE')}
        &nbsp;
        <span
          style={{
            height: '1.5em',
            width: '1.5em',
            borderRadius: '50%'
          }}
          className='flex middle-xs center-xs vf-font-bold vf-white-text vf-bg-success-color icon-check'
        ></span>
      </div>
    </div>
  );
}

VerifiedState.propTypes = {
  className: PropTypes.string
};
