"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const types_1 = require("modules/shared/types");
class AddressLink extends React.PureComponent {
    render() {
        const { address, deliveryType } = this.props;
        return (React.createElement("a", { className: 'flex vf-font-bold margin-right-1x', href: encodeURI(`https://www.google.com/maps/?q=${address}`), target: '_blank', rel: 'noreferrer' },
            React.createElement("i", { className: 'vf-icon icon-ic-location', style: { margin: '2px 2px 0 0' } }),
            deliveryType === types_1.ProductDeliveryType.PICKUP &&
                i18n.get('PAY_IN_KIND_SEE_PICKUP_ADDRESS'),
            deliveryType === types_1.ProductDeliveryType.SHIP &&
                i18n.get('PAY_IN_KIND_SEE_SHIPPING_ADDRESS')));
    }
}
exports.default = AddressLink;
