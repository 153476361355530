import React from 'react';
import PropTypes from 'prop-types';

import styles from './AdvertiserChip.less';
import { defaultAvatarImg } from 'assets/images';

const Chip = ({ advertiser }) => {
  const avatar = advertiser.profileImage || defaultAvatarImg;
  const doesAdvertiserHasName = advertiser.fullName || advertiser.name;

  return (
    <div className='vf-row no-margin padding-Hx'>
      <div className='col-xs-2 no-padding'>
        <div
          className={styles.avatar}
          style={{ backgroundImage: `url(${avatar})` }}
        />
      </div>

      <div className='col start-xs col-xs-8'>
        <span className={`vf-font-bold ellipsis width100 ${styles.name}`}>
          {doesAdvertiserHasName
            ? advertiser.fullName || advertiser.name
            : advertiser.email}
        </span>
        <span className='vf-font-small ellipsis width100'>
          {doesAdvertiserHasName ? advertiser.email : ''}
        </span>
      </div>
    </div>
  );
};

Chip.propTypes = {
  advertiser: PropTypes.object.isRequired
};

export default Chip;
