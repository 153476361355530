"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const moment = require("moment");
function dateDiff(date1, date2) {
    const momentDate = moment(date1);
    return {
        years: momentDate.diff(date2, 'years', true),
        days: momentDate.diff(date2, 'days', true),
        hours: momentDate.diff(date2, 'hours', true),
        minutes: momentDate.diff(date2, 'minutes', true)
    };
}
exports.default = dateDiff;
