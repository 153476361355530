import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './SelectableChip.less';

const SelectableChip = ({
  children,
  className,
  isSelected = false,
  onClick
}) => {
  const containerClassNames = classNames(styles.container, className, {
    [styles.selected]: isSelected
  });

  return (
    <div className={containerClassNames} onClick={onClick}>
      {children}
    </div>
  );
};

SelectableChip.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
};

export default SelectableChip;
