import axios from 'axios';

const PASSWORD_HEADER = 'x-password';

export function createBrand({ advertiserId, data }) {
  return axios.post(`/users/${advertiserId}/brands`, data);
}

export function getAdvertiserBrands(advertiserId) {
  return axios.get(`/users/${advertiserId}/brands`);
}

export function updateBrand({ brandId, data }) {
  return axios.put(`/brands/${brandId}`, data);
}

export function deleteBrand({ brandId, password }) {
  return axios.delete(`/brands/${brandId}`, {
    headers: { [PASSWORD_HEADER]: password }
  });
}

export function fetchBrandCampaigns({ brandId, params }) {
  return axios.get(`/brands/${brandId}/campaigns`, { params });
}

export function getBrandUsers({ brandId, params }) {
  return axios.get(`/brands/${brandId}/users`, { params });
}

export function inviteUser({ brandId, userEmail, accessLevel }) {
  return axios.post(`/brands/${brandId}/invite`, {
    accessLevel,
    email: userEmail
  });
}

export function leave({ brandId, userId }) {
  return axios.post(`/users/${userId}/brands/${brandId}/leave`);
}

export function getCampaign({ campaignId }) {
  return axios.get(`/campaigns/${campaignId}`);
}

export function acceptInvitation({ brandId }) {
  return axios.post(`/brands/${brandId}/accept`);
}

export function rejectInvitation({ brandId }) {
  return axios.post(`/brands/${brandId}/reject`);
}

export function removeUser({ brandId, userId }) {
  return axios.delete(`/brands/${brandId}/users/${userId}`);
}

export function cancelInvitation({ brandId, email }) {
  return axios.post(`/brands/${brandId}/cancel-invite`, { email });
}

export function getBrands(params) {
  return axios.get('/brands', { params });
}

export function removeCredit(id, data) {
  return axios.post(`/brands/${id}/remove-credit`, data);
}

export function addCredit(id, data) {
  return axios.post(`/brands/${id}/add-credit`, data);
}

export function setUserPermissions({ brandId, userId, permissions }) {
  return axios.put(
    `/brands/${brandId}/users/${userId}/permissions`,
    permissions
  );
}

export function transferOwnership({ brandId, ...data }) {
  return axios.post(`/brands/${brandId}/transfer-ownership`, data);
}

export function updateBrandRebateMargin({ brandId, data }) {
  return axios.post(`/brands/${brandId}/set-rebate-margin`, data);
}

export function getCreditHistoryReport({ brandId }) {
  return axios.post(`/brands/${brandId}/credit-history/export`);
}

export function createProduct({ brandId, data }) {
  return axios.post(`/brands/${brandId}/products`, data);
}

export function fetchProducts({ brandId, ...params }) {
  return axios.get(`/brands/${brandId}/products`, { params });
}

export function updateProduct({ brandId, productId, data }) {
  return axios.put(`/brands/${brandId}/products/${productId}`, data);
}

export function deleteProduct(brandId, productId) {
  return axios.post(`/brands/${brandId}/products/${productId}/disable`);
}

export function getTeams({ id }) {
  return axios.get(`/brands/${id}/teams`);
}

export function addTeam({ brandId, teamId, ...data }) {
  return axios.post(`/brands/${brandId}/teams/${teamId}`, data);
}

export function updateTeam({ brandId, teamId, ...data }) {
  return axios.put(`/brands/${brandId}/teams/${teamId}`, data);
}

export function removeTeam({ brandId, teamId }) {
  return axios.delete(`/brands/${brandId}/teams/${teamId}`);
}

export function transferCredit({ id, ...data }) {
  return axios.post(`/brands/${id}/transfer-credit`, data);
}

export function getTransactions({ id, ...params }) {
  return axios.get(`/brands/${id}/transactions`, { params });
}

export function getPaymentsInRecepit({ id }) {
  return axios.get(`/payments_in/${id}/receipt`);
}
