import axios from 'axios';

export const getReportUsers = params =>
  axios.get('/reported-users', { params });

export const getReportPosts = params =>
  axios.get('/reported-participations', { params });

export const blockSocialAccount = ({ id }) =>
  axios.post(`/social_network_accounts/${id}/block`);

export const concludeReport = ({ id }) =>
  axios.post(`/pro/invites/${id}/report/penalize`);

export const markAsReviewed = ({ category, id }) =>
  axios.post(`/${category}/reports/${id}/review`);

export const markAsReviewedPostPro = ({ id }) =>
  axios.post(`/pro/invites/${id}/report/mark-as-reviewed`);
