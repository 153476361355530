import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, chain, isEmpty } from 'lodash';
import classNames from 'classnames';
import { Checkbox, Select } from 'ui-library';
import i18n from 'i18n';

import CustomDropdown from 'modules/shared/components/CustomDropdown/CustomDropdown';
import InfoHelper from 'modules/shared/components/InfoHelper';
import styles from './InfluencerTypeSelect.less';

const DEFAULT_ACCOUNT_TYPES = 'all';
const PERSONAL_ACCOUNT_TYPES = 'person';

function mapStateToProps({ socialAccounts }) {
  return {
    accountTypes: get(socialAccounts, 'configuration.accountTypes', [])
  };
}

class InfluencerTypeSelect extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate = newProps => {
    return (
      newProps.accountTypes.length !== this.props.accountTypes.length ||
      newProps.accountTypeValue !== this.props.accountTypeValue ||
      newProps.accountSubtypeValues !== this.props.accountSubtypeValues
    );
  };

  onAccountTypeChange = type => {
    const newType = type === DEFAULT_ACCOUNT_TYPES ? null : type;
    const newSubtypes =
      type === DEFAULT_ACCOUNT_TYPES
        ? null
        : this.getSubtypesByType(type).map(s => s.id);
    const personalAccountsFiltersToUnset =
      type !== PERSONAL_ACCOUNT_TYPES
        ? { maxAge: null, minAge: null, gender: null }
        : {};

    this.props.onChange({
      accountType: newType,
      accountSubtype: newSubtypes,
      ...personalAccountsFiltersToUnset
    });
  };

  onSubtypeToggle = subtype => {
    const { accountSubtypeValues, onAccountSubtypesChange } = this.props;

    const newSubtypes = accountSubtypeValues.includes(subtype)
      ? accountSubtypeValues.filter(s => s !== subtype)
      : [...accountSubtypeValues, subtype];

    onAccountSubtypesChange(newSubtypes);
  };

  getSubtypesByType = type => {
    return chain(this.props.accountTypes)
      .find({ id: type })
      .get('subtypes')
      .value();
  };

  buildAccountTypesOptions = () => {
    return this.props.accountTypes.reduce(
      (result, type) => {
        result[type.id] = type.name;
        return result;
      },
      { all: i18n.get('ADV_INFLUENCER_SEARCH_FILTER_ACCOUNT_TYPE_ALL') }
    );
  };

  renderAccountSubtypeSelector = () => {
    const { accountTypeValue, accountSubtypeValues, accountTypes } = this.props;
    const subtypes = this.getSubtypesByType(accountTypeValue);

    const options = subtypes.map(subtype => {
      const isActive = accountSubtypeValues.includes(subtype.id);
      const className = classNames('flex between-xs', styles.item, {
        [styles.activeItem]: isActive
      });

      return (
        <div className={className}>
          <Checkbox
            checked={isActive}
            onChange={() => this.onSubtypeToggle(subtype.id)}
          >
            <div className='flex between-xs'>{subtype.name}</div>
          </Checkbox>

          <InfoHelper
            className='vf-bg-gray-color padding-Hx'
            popoverPosition='below'
            popoverBody={
              <div className='padding-Hx'>
                <p className='margin-bottom-Hx'>{subtype.desc}</p>
              </div>
            }
          />
        </div>
      );
    });

    return (
      <CustomDropdown
        text={this.getSubtypesDropdownTitle()}
        content={options}
        className='margin-top-1x'
      />
    );
  };

  getSubtypesDropdownTitle = () => {
    const { accountTypes, accountTypeValue, accountSubtypeValues } = this.props;

    if (isEmpty(accountSubtypeValues))
      return i18n.get('ADV_INFLUENCER_SEARCH_FILTER_ACCOUNT_SUBTYPE_TITLE');

    const availableSubtypes = this.getSubtypesByType(accountTypeValue);
    const areAllSubtypesSelected =
      availableSubtypes.length === accountSubtypeValues.length;

    if (areAllSubtypesSelected)
      return i18n.get('ADV_INFLUENCER_SEARCH_FILTER_ACCOUNT_SUBTYPE_ALL');

    return accountSubtypeValues
      .map(subtype => {
        return chain(availableSubtypes)
          .find({ id: subtype })
          .get('name')
          .value();
      })
      .join(', ');
  };

  render = () => {
    const { accountTypeValue, accountSubtypeValues, onAccountTypeChange } =
      this.props;

    return (
      <div className='width100'>
        <Select
          value={
            accountTypeValue === null ? DEFAULT_ACCOUNT_TYPES : accountTypeValue
          }
          onChange={ev => this.onAccountTypeChange(ev.target.value)}
          title={i18n.get('ADV_INFLUENCER_SEARCH_FILTER_ACCOUNT_TYPE_TITLE')}
          options={this.buildAccountTypesOptions()}
          className='width100'
          selectClassname='width100'
          buttonClassname={styles.select}
        />

        {!!accountTypeValue &&
          accountTypeValue !== DEFAULT_ACCOUNT_TYPES &&
          this.renderAccountSubtypeSelector()}
      </div>
    );
  };
}

InfluencerTypeSelect.propTypes = {
  accountTypes: PropTypes.array.isRequired,
  accountTypeValue: PropTypes.string,
  accountSubtypeValues: PropTypes.array,
  onAccountTypeChange: PropTypes.func.isRequired,
  onAccountSubtypesChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

InfluencerTypeSelect.defaultProps = {
  accountTypes: [],
  accountTypeValue: null,
  accountSubtypeValues: [],
  onAccountTypeChange: () => {},
  onAccountSubtypesChange: () => {},
  onChange: () => {}
};

export default connect(mapStateToProps)(InfluencerTypeSelect);
