"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const store = require("store2");
const ui_library_1 = require("ui-library");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const i18n = require("i18n");
const wizard_item_1 = require("./wizard-item");
const user_1 = require("../lib/tracking/user");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const types_1 = require("modules/shared/types");
const brandsActions = require("redux/modules/brands");
const SharedActions = require("redux/modules/shared");
const components_1 = require("apps/shared/components");
const app_1 = require("config/app");
const ProfileType_1 = require("apps/shared/controllers/ProfileType/ProfileType");
const settings_social_connect_account_1 = require("./settings-social-connect-account");
const AccountDataForm_1 = require("apps/shared/controllers/AccountDataForm/AccountDataForm");
const VERSIONCAMPAING = 'VersionCampaing';
const INVITATION_LINK = 'invitationLink';
class Wizard extends React.Component {
    constructor(props) {
        super(props);
        this.onDialogDone = () => {
            this.props.onClose();
        };
        this.hangleAccountForm = nameWork => {
            this.props.handleAccountForm(nameWork);
        };
        this.onFinishDone = () => {
            const { invitationLink } = this.state;
            const { user, getBrands } = this.props;
            if (invitationLink) {
                getBrands(user.id)
                    .then(({ data }) => {
                    const idBrand = data[0].id;
                    const url = window.location.href;
                    const path = window.location.pathname;
                    if (idBrand) {
                        const href = `${url.split(path)[0]}/brands/${idBrand}/campaigns/create`;
                        window.location.href = href;
                    }
                })
                    .catch(error => console.error(error));
            }
            else {
                this.onDialogDone();
                location.reload();
            }
        };
        this.afterAccountDataSave = () => {
            const { user } = this.props;
            user_1.default.track('ANY - Completed Company Data', {
                name: user.name,
                email: user.email,
                role: user.role,
                eventDate: new Date()
            });
            user_1.default.track('ANY - Completed Location Data', {
                country: user.country,
                city: user.city,
                region: user.region,
                timeZone: (user === null || user === void 0 ? void 0 : user.timeZone) || user.timezone
            });
            this.onNextClick();
        };
        this.onNextClick = () => {
            this.setState({ currentDialogStep: this.state.currentDialogStep + 1 });
        };
        this.onPreviousClick = () => {
            this.setState({ currentDialogStep: this.state.currentDialogStep - 1 });
        };
        this.logoutHandler = () => {
            const { logout, onLogout } = this.props;
            onLogout();
            logout();
        };
        this.onSocialNetworkRedirect = params => {
            const SOCIAL_NETWORKS = ['facebook', 'instagram'];
            if (SOCIAL_NETWORKS.indexOf(params.socialNetwork) > -1) {
                return this.onDialogDone();
            }
        };
        this.getDialogHeader = role => {
            const { validInvitation, invitationLink, campaignLite } = this.state;
            const influencerItems = [
                {
                    title: i18n.get('REGISTER_PERSONAL_INFO_STEP_TITLE'),
                    text: i18n.get('REGISTER_PERSONAL_INFO_STEP_SUBTITLE')
                },
                {
                    title: !campaignLite
                        ? i18n.get('REGISTER_SOCIAL_NETWORKS_STEP_TITLE')
                        : i18n.get('REGISTER_CAMPAIGN_LITE_STEP_TITLE'),
                    text: !campaignLite
                        ? i18n.get('REGISTER_SOCIAL_NETWORKS_STEP_SUBTITLE')
                        : i18n.get('REGISTER_CAMPAIGN_LITE_STEP_SUBTITLE')
                }
            ];
            const advertiserItems = invitationLink
                ? [
                    {
                        title: i18n.get('REGISTER_ACCOUNT_LITE_STEP_TITLE'),
                        text: i18n.get('REGISTER_ACCOUNT_LITE_STEP_SUBTITLE')
                    },
                    {
                        title: i18n.get('REGISTER_CREATE_CAMPAIGN_LITE_STEP_TITLE'),
                        text: i18n.get('REGISTER_CREATE_CAMPAIGN_LITE_STEP_SUBTITLE')
                    },
                    {
                        title: i18n.get('REGISTER_SHARE_LITE_STEP_TITLE'),
                        text: i18n.get('REGISTER_SHARE_LITE_STEP_SUBTITLE')
                    }
                ]
                : [
                    {
                        title: i18n.get('REGISTER_ACCOUNT_STEP_TITLE'),
                        text: i18n.get('REGISTER_PERSONAL_INFO_STEP_SUBTITLE')
                    },
                    {
                        title: i18n.get('REGISTER_PROFILE_STEP_TITLE'),
                        text: i18n.get('REGISTER_PROFILE_STEP_SUBTITLE')
                    },
                    {
                        title: i18n.get('REGISTER_BRIEF_STEP_TITLE'),
                        text: i18n.get('REGISTER_BRIEF_STEP_SUBTITLE')
                    }
                ];
            if ((validInvitation || !app_1.getVoxFeedLite()) && !invitationLink) {
                advertiserItems.length = 1;
            }
            if (app_1.getEasyLexContract()) {
                influencerItems.splice(1, 0, {
                    title: i18n.get('REGISTER_EASYLEX_CONTRACT_STEP_TITLE'),
                    text: i18n.get('REGISTER_EASYLEX_CONTRACT_STEP_TEXT')
                });
            }
            if (role === 'influencer') {
                return (React.createElement("div", { className: 'flex center-xs width100 margin-bottom-1x' }, influencerItems.map((currentItem, index) => (React.createElement(wizard_item_1.default, { step: index + 1, text: currentItem.text, title: currentItem.title, currentStep: this.state.currentDialogStep })))));
            }
            if (role === 'advertiser') {
                return (React.createElement("div", { className: 'flex center-xs width100 margin-bottom-1x' }, advertiserItems.map((currentItem, index) => (React.createElement(wizard_item_1.default, { step: index + 1, text: currentItem.text, title: currentItem.title, currentStep: this.state.currentDialogStep })))));
            }
            return (React.createElement("div", { className: 'flex center-xs width100 margin-bottom-1x' },
                React.createElement(wizard_item_1.default, { step: 1, currentStep: this.state.currentDialogStep, title: i18n.get('REGISTER_ACCOUNT_STEP_TITLE'), text: i18n.get('REGISTER_PERSONAL_INFO_STEP_SUBTITLE') })));
        };
        this.getAccountInfoView = () => {
            let valid;
            const { user } = this.props;
            const { campaignLite, userCategory, invitationLink, validInvitation, campaignCategory } = this.state;
            if (user.role === 'admin') {
                valid = this.onDialogDone;
            }
            else if (user.role === 'advertiser' &&
                (validInvitation || !app_1.getVoxFeedLite() || invitationLink)) {
                valid = this.onFinishDone;
            }
            else {
                valid = this.afterAccountDataSave;
            }
            const content = (React.createElement("div", { className: 'vf-wrapper', id: 'user-info-view' },
                React.createElement(AccountDataForm_1.default, { onAfterSave: valid, campaignLite: campaignLite, userCategory: userCategory, invitationLink: invitationLink, campaignCategory: campaignCategory, validInvitation: validInvitation && app_1.getVoxFeedLite() })));
            return { content };
        };
        this.getSocialNetworksView = () => {
            const { campaignLite, socialNetworks } = this.state;
            const content = (React.createElement(React.Fragment, null,
                React.createElement(settings_social_connect_account_1.default, { campaignLite: campaignLite, socialNetworks: socialNetworks, onRedirect: this.onSocialNetworkRedirect, hangleAccountForm: this.hangleAccountForm }),
                React.createElement("div", { className: 'margin-top-3x width100 flex between-xs padding-2x' },
                    React.createElement(ui_library_1.Button, { color: 'flat', onClick: this.logoutHandler, className: 'vf-text-gray-dark vf-font-bold' }, i18n.get('LOGOUT')),
                    React.createElement("div", { className: 'flex' },
                        React.createElement(ui_library_1.Button, { size: 'sm', className: 'margin-right-1x', onClick: this.onPreviousClick, style: { padding: '1rem 3rem', borderRadius: '2px' } }, i18n.get('BACK')),
                        React.createElement(ui_library_1.Button, { size: 'sm', color: 'primary', onClick: this.onDialogDone, style: { padding: '1rem 3rem', borderRadius: '2px' } }, i18n.get('FINISH'))))));
            return { content };
        };
        this.getEasyLexContractView = () => {
            return {
                content: (React.createElement(components_1.EasyLexContract, { nextHandler: this.onNextClick, previousHandler: this.logoutHandler }))
            };
        };
        this.getCampaignView = () => {
            const content = React.createElement(ProfileType_1.default, null);
            return { content };
        };
        this.getInfluencerDialogViews = () => {
            const views = [this.getAccountInfoView(), this.getSocialNetworksView()];
            if (app_1.getEasyLexContract()) {
                views.splice(1, 0, this.getEasyLexContractView());
            }
            return views;
        };
        this.getAdvertiserDialogViews = () => {
            const { validInvitation } = this.state;
            const views = [this.getAccountInfoView(), this.getCampaignView()];
            if (validInvitation || !app_1.getVoxFeedLite()) {
                views.length = 1;
            }
            return views;
        };
        this.getDialogViews = role => {
            if (role === 'influencer')
                return this.getInfluencerDialogViews();
            if (role === 'advertiser')
                return this.getAdvertiserDialogViews();
            return [this.getAccountInfoView()];
        };
        const { stepWizard, user } = props;
        const { email, category, isInvited, userCategory, verifiedEmails, hasCompletePersonalInfo } = user;
        const versionCampaing = store.get(VERSIONCAMPAING) || {};
        const campaignLite = versionCampaing.campaignVersion === types_1.ECategoryType.LITE;
        const invitationLink = store.get(INVITATION_LINK) || false;
        const currentDialogStep = isInvited || !app_1.getVoxFeedLite() ? 1 : stepWizard;
        const verifiedEmail = verifiedEmails.length > 0 &&
            verifiedEmails.indexOf(email.toLowerCase()) !== -1;
        if (verifiedEmail && hasCompletePersonalInfo)
            this.onDialogDone();
        this.state = {
            email,
            campaignLite,
            budget: 'none',
            invitationLink,
            editEmail: false,
            currentDialogStep,
            companyType: 'none',
            advSavedProfile: false,
            advSavedLocation: false,
            validInvitation: isInvited || category,
            userCategory: userCategory || category,
            campaignCategory: versionCampaing.campaignVersion,
            socialNetworks: versionCampaing.socialNetworks || []
        };
    }
    render() {
        const { user } = this.props;
        const { currentDialogStep } = this.state;
        const dialogViews = this.getDialogViews(user.role);
        const dialogHeader = this.getDialogHeader(user.role);
        const dialogView = dialogViews[currentDialogStep - 1];
        const styleProfileType = user.role === 'advertiser' && currentDialogStep === 2
            ? { maxWidth: '860px' }
            : {};
        return (React.createElement(CustomDialog_1.default, { onClose: null, key: 'home-dialog', dismissable: false, header: dialogHeader, content: dialogView.content, customContainerStyle: styleProfileType }));
    }
}
function mapStateToProps({ app }) {
    const { systemSettings } = app;
    const { currency } = systemSettings;
    return { currency };
}
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({ logout: SharedActions.logout, getBrands: brandsActions.getBrands }, dispatch);
}
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Wizard);
