import React from 'react';
import createClass from 'create-react-class';
import i18n from 'i18n';
import is from 'is_js';
import {
  browserChromeImg,
  browserFirefoxImg,
  browserSafaryImg,
  oldBrowserImg,
  logoImgObs
} from 'assets/images';
import { getDefaultTitle } from 'config/app';

const UnsupportedBrowser = createClass({
  getInitialState() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    };
  },

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  },

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },

  handleResize() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  },

  render() {
    const { windowHeight, windowWidth } = this.state;

    return (
      <div
        className='vf-unsupported-browser'
        style={{ width: windowWidth, height: windowHeight }}
      >
        <div className='content col-xs-12'>
          <div className='col-sm-12 col-md-6 clearfix float-left'>
            {this.renderBrowserImage()}
          </div>

          <div className='col-sm-12 col-md-6 clearfix float-left'>
            {this.renderMessage()}
          </div>
        </div>
      </div>
    );
  },

  renderBrowserImage() {
    return (
      <div className='old-browser'>
        <img className='float-left' src={oldBrowserImg} />
      </div>
    );
  },

  renderMessage() {
    const companyName = getDefaultTitle();
    return (
      <div className='message padding-3x float-left'>
        <img className='logo' src={logoImgObs} />
        <h2 className='margin-vertical-2x'>
          {i18n.get('UNSUPPORTED_BROWSER_TITLE', { companyName })}
        </h2>

        <h5>
          <p>
            {i18n.get('UNSUPPORTED_BROWSER_DESCRIPTION_1', { companyName })}
          </p>
          <p>{i18n.get('UNSUPPORTED_BROWSER_DESCRIPTION_2')}</p>
        </h5>

        <div className='browsers col-xs-12 margin-top-3x'>
          {this.renderSuggestionsForClientType()}
        </div>
      </div>
    );
  },

  renderSuggestionsForClientType() {
    if (is.desktop()) return this.renderSuggestionsForDesktop();
    if (is.android()) return this.renderSuggestionsForAndroid();
    if (is.ios()) return this.renderSuggestionsForIOS();

    return null;
  },

  renderSuggestionsForDesktop() {
    return (
      <span>
        {this.renderBrowserSuggestion({
          name: i18n.get('UNSUPPORTED_BROWSER_BROWSER_CHROME'),
          version: i18n.get('UNSUPPORTED_BROWSER_VERSION', { version: '63+' }),
          iconPath: browserChromeImg,
          link: 'https://www.google.com/chrome/browser/desktop/index.html'
        })}

        {this.renderBrowserSuggestion({
          name: i18n.get('UNSUPPORTED_BROWSER_BROWSER_FIREFOX'),
          version: i18n.get('UNSUPPORTED_BROWSER_VERSION', { version: '58+' }),
          iconPath: browserFirefoxImg,
          link: 'https://www.mozilla.org/es-MX/firefox/new/#'
        })}

        {this.renderBrowserSuggestion({
          name: i18n.get('UNSUPPORTED_BROWSER_BROWSER_SAFARI'),
          version: i18n.get('UNSUPPORTED_BROWSER_VERSION', {
            version: '11.1+'
          }),
          iconPath: browserSafaryImg,
          link: 'https://support.apple.com/downloads/safari'
        })}
      </span>
    );
  },

  renderSuggestionsForAndroid() {
    return (
      <span>
        {this.renderBrowserSuggestion({
          name: i18n.get('UNSUPPORTED_BROWSER_BROWSER_CHROME'),
          version: i18n.get('UNSUPPORTED_BROWSER_VERSION', { version: '50+' }),
          iconPath: browserChromeImg,
          link: 'https://play.google.com/store/apps/details?id=com.android.chrome'
        })}

        {this.renderBrowserSuggestion({
          name: i18n.get('UNSUPPORTED_BROWSER_BROWSER_FIREFOX'),
          version: '*',
          iconPath: browserFirefoxImg,
          link: 'https://play.google.com/store/apps/details?id=org.mozilla.firefox'
        })}
      </span>
    );
  },

  renderSuggestionsForIOS() {
    return (
      <span>
        {this.renderBrowserSuggestion({
          name: i18n.get('UNSUPPORTED_BROWSER_BROWSER_CHROME'),
          version: i18n.get('UNSUPPORTED_BROWSER_VERSION', { version: '50+' }),
          iconPath: browserChromeImg,
          link: 'https://itunes.apple.com/mx/app/chrome-navegador-web-google/id535886823'
        })}

        {this.renderBrowserSuggestion({
          name: i18n.get('UNSUPPORTED_BROWSER_BROWSER_FIREFOX'),
          version: i18n.get('UNSUPPORTED_BROWSER_VERSION', { version: '*' }),
          iconPath: browserFirefoxImg,
          link: 'https://itunes.apple.com/mx/app/navegador-web-firefox/id989804926'
        })}
      </span>
    );
  },

  renderBrowserSuggestion({ name, version, iconPath, link }) {
    return (
      <div className='browser float-left'>
        <a href={link} target='_blank'>
          <img className='margin-right-1x' src={iconPath} />
          <div className='hide-xs'>
            <p className='name'>{name}</p>
            <p>{version}</p>
          </div>
        </a>
      </div>
    );
  }
});

export default UnsupportedBrowser;
