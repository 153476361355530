"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const userActions = require("redux/modules/users");
const appActions = require("redux/modules/app");
const PartnerForgotPasswordUI_1 = require("./PartnerForgotPasswordUI/PartnerForgotPasswordUI");
const emailValidator_1 = require("apps/shared/hocs/emailValidator");
const errorStateHandler_1 = require("apps/shared/hocs/errorStateHandler");
const lodash_1 = require("lodash");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const selectors_1 = require("modules/shared/selectors");
class PartnerForgotPassword extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            loading: false
        };
        this.validateForm = form => {
            const { validateEmail } = this.props;
            return {
                email: validateEmail(form.email)
                    ? null
                    : i18n.get('LOGIN_LOGIN_ERROR_INVALID_EMAIL')
            };
        };
        this.handleInputBlur = (name, value) => {
            const { handleInputValidation } = this.props;
            const params = {
                name,
                value,
                validationFunction: this.validateForm
            };
            handleInputValidation(params);
        };
        this.handleSubmit = form => {
            const { forgotPassword, onSetErrors } = this.props;
            const errors = this.validateForm(form);
            const isValid = lodash_1.every(errors, val => val === null);
            if (!isValid)
                return onSetErrors(errors);
            this.setState({ loading: true });
            forgotPassword(form)
                .then(this.onForgotSuccess)
                .catch(this.onForgotFailed)
                .finally(() => this.setState({ loading: false }));
        };
        this.onForgotSuccess = () => {
            const { showAlert } = this.props;
            showAlert({
                type: 'success',
                message: i18n.get('FORGOT_SUCCESS_ALERT_DESCRIPTION')
            });
        };
        this.onForgotFailed = () => {
            const { showAlert, request } = this.props;
            const { message } = request.response;
            showAlert({
                type: 'error',
                message
            });
        };
    }
    render() {
        const { errors } = this.props;
        const { loading } = this.state;
        return (React.createElement(PartnerForgotPasswordUI_1.default, { errors: errors, loading: loading, onInputBlur: this.handleInputBlur, onSubmit: this.handleSubmit }));
    }
}
const mapStateToProps = state => ({
    request: selectors_1.getRequest(state, 'forgotPassword')
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    forgotPassword: userActions.forgotPassword,
    showAlert: appActions.showAlert
}, dispatch);
const WithErrors = errorStateHandler_1.default(PartnerForgotPassword);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(emailValidator_1.default(WithErrors));
