import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import is from 'is_js';
import Lightbox from 'react-image-lightbox';

const GalleryOverlay = createClass({
  propTypes: {
    gallery: PropTypes.array,
    selectedImageIndex: PropTypes.number,
    onClose: PropTypes.func
  },

  getInitialState() {
    return { currentImage: this.props.selectedImageIndex || 0 };
  },

  moveImage(diff) {
    this.setState({
      currentImage:
        (this.state.currentImage + diff + this.props.gallery.length) %
        this.props.gallery.length
    });
  },

  handleClose() {
    if (this.props.onClose) this.props.onClose();
  },

  render() {
    const { gallery } = this.props;
    if (!gallery) return <noscript />;

    const { currentImage } = this.state;

    const hasOnlyOneImage = gallery.length === 1;

    const srcArray = gallery.map(el => {
      if (is.url(el)) return el;
      return el.original;
    });

    const nextImage = hasOnlyOneImage
      ? null
      : srcArray[(currentImage + 1) % srcArray.length];
    const prevImage = hasOnlyOneImage
      ? null
      : srcArray[(currentImage + srcArray.length - 1) % srcArray.length];

    return (
      <Lightbox
        clickOutsideToClose
        nextSrc={nextImage}
        prevSrc={prevImage}
        mainSrc={srcArray[currentImage]}
        onCloseRequest={this.handleClose}
        onMovePrevRequest={() => this.moveImage(-1)}
        onMoveNextRequest={() => this.moveImage(1)}
      />
    );
  }
});

export default GalleryOverlay;
