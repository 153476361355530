/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Popover from 'modules/legacy/js/components/popover';

const stylesBySize = {
  sm: { fontSize: '0.9rem', width: '1.75rem', height: '1.75rem' },
  md: { fontSize: '1rem', width: '2rem', height: '2rem' },
  lg: {}
};

function InfoHelper({
  size,
  icon,
  image,
  iconStyle,
  className,
  popoverBody,
  popoverPosition
}) {
  const style = stylesBySize[size];
  const badgeClassName = classNames('vf-badge vf-clickable', className);

  return (
    <Popover
      style='dark'
      hideWithClick
      padding={false}
      body={popoverBody}
      preferPlace={popoverPosition}
    >
      {image ? (
        <i className='vf-clickable'>{image}</i>
      ) : (
        <div className={badgeClassName} style={style}>
          <span className='vf-text-white-light'>
            <i className={`vf-icon icon-${icon} icon-fix`} style={iconStyle} />
          </span>
        </div>
      )}
    </Popover>
  );
}

InfoHelper.propTypes = {
  image: PropTypes.node,
  size: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  iconStyle: PropTypes.shape({}),
  popoverPosition: PropTypes.string,
  popoverBody: PropTypes.node.isRequired
};

InfoHelper.defaultProps = {
  size: 'md',
  image: false,
  className: '',
  iconStyle: {},
  icon: 'question',
  popoverPosition: 'above'
};

export default InfoHelper;
