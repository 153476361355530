"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_media_1 = require("react-media");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const i18n = require("i18n");
const appActions = require("redux/modules/app");
const UserActions = require("redux/modules/users");
const selectors_1 = require("modules/shared/selectors");
const errorStateHandler_1 = require("apps/shared/hocs/errorStateHandler");
const validateUrlSocialNetworks_1 = require("utils/validateUrlSocialNetworks");
const styles = require("./SocialNetworksFormUI/SocialNetworksFormUI.less");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const SocialAccountLiteActions = require("redux/modules/socialNetworkLite");
const SocialNetworksFormUI_1 = require("./SocialNetworksFormUI/SocialNetworksFormUI");
const UnlinkView_1 = require("../SocialNetworksForm/components/UnlinkView/UnlinkView");
const INITIAL_FORM = { username: '', link: '', followers: '' };
const TYPESOCIALNETWORK = 'TypeSocialNetwork';
const SocialNetworksForm = ({ user, modal, userId, errors, infoUser, onCancel, showAlert, updateUser, onSetErrors, addSocialNetworkLite, deleteAccount = false, handleInputValidation, showSocialFormAccount, updateSocialNetworkLite, deleteSocialNetworkLite, onSocialNetworkFormClose }) => {
    const [form, setForm] = React.useState(INITIAL_FORM);
    const [unlinkAccount, setUnlinkaccount] = React.useState(false);
    const [linkValueDefault, setLinkValueDefault] = React.useState(true);
    const [nameValueDefault, setNameValueDefault] = React.useState(true);
    const networkName = window.localStorage.getItem(TYPESOCIALNETWORK) || '';
    const [followersValueDefault, setFollowersValueDefault] = React.useState(true);
    const { username, followers, link } = form;
    let nameInf = null;
    let followersInf = null;
    let linkInf = null;
    if (infoUser) {
        if (infoUser.username) {
            nameInf = infoUser.username;
        }
        if (infoUser.followers) {
            followersInf = infoUser.followers;
        }
        if (infoUser.link) {
            linkInf = infoUser.link;
        }
    }
    const onCancelUnlink = () => {
        setUnlinkaccount(false);
    };
    const validateForm = (inputs) => {
        const validateNameField = (field) => {
            if (!field && (!nameInf || (nameInf && !nameValueDefault))) {
                return i18n.get('REQUIRED');
            }
            return null;
        };
        const validateFollowersField = (field) => {
            if (!field &&
                (!followersInf || (followersInf && !followersValueDefault))) {
                return i18n.get('REQUIRED');
            }
            return null;
        };
        const validateLink = (field) => {
            if (!field && (!linkInf || (linkInf && !linkValueDefault))) {
                return i18n.get('REQUIRED');
            }
            else if (field) {
                return validateUrlSocialNetworks_1.default(networkName, field);
            }
            return null;
        };
        return {
            username: validateNameField(inputs.username),
            link: validateLink(inputs.link),
            followers: validateFollowersField(inputs.followers)
        };
    };
    const hanldleInputChange = (name, value) => {
        setForm(Object.assign(Object.assign({}, form), { [name]: value }));
        handleInputValidation({ name, value, validationFunction: validateForm });
    };
    const onSuccess = () => {
        updateUser(Object.assign({}, user));
        onSocialNetworkFormClose();
        if (modal) {
            window.location.href = window.location.href;
        }
    };
    const onSucessDeleteAccount = () => {
        showAlert({
            type: 'success',
            message: i18n.get('INF_LITE_ACCOUNTS_UNlINKED_ALERT')
        });
        updateUser(Object.assign({}, user));
        onSocialNetworkFormClose();
    };
    const onDelete = () => {
        deleteSocialNetworkLite(userId)
            .then(onSucessDeleteAccount)
            .catch(error => console.error(error));
    };
    const onSave = () => {
        const socialNetworks = {
            social_network: networkName,
            username: !username ? nameInf : username.toLowerCase(),
            followers: !followers
                ? Number(followersInf)
                : parseFloat(followers.replace(/,/g, '')),
            link: !link ? linkInf : link.toLowerCase(),
            id: userId
        };
        const errorsList = validateForm(form);
        const isValid = Object.values(errorsList).every(val => val === null);
        if (!isValid)
            return onSetErrors(errorsList);
        if (!userId) {
            addSocialNetworkLite(socialNetworks)
                .then(onSuccess)
                .catch(error => console.error(error));
            delete socialNetworks.id;
        }
        else {
            updateSocialNetworkLite(socialNetworks)
                .then(onSuccess)
                .catch(error => console.error(error));
        }
    };
    return (React.createElement("div", { className: styles.center }, showSocialFormAccount && !unlinkAccount ? (React.createElement(SocialNetworksFormUI_1.default, { onSave: onSave, errors: errors, infoUser: infoUser, onCancel: onCancel, networkName: networkName, deleteAccount: deleteAccount, setUnlinkaccount: setUnlinkaccount, hanldleInputChange: hanldleInputChange, setLinkValueDefault: setLinkValueDefault, setNameValueDefault: setNameValueDefault, showSocialFormAccount: showSocialFormAccount, setFollowersValueDefault: setFollowersValueDefault })) : (React.createElement(React.Fragment, null,
        React.createElement(react_media_1.default, { query: '(min-width: 1025px)' },
            React.createElement(UnlinkView_1.default, { infoUser: infoUser, onDelete: onDelete, unlinkAccount: unlinkAccount, onCancelUnlink: onCancelUnlink })),
        React.createElement(react_media_1.default, { query: '(max-width: 800px)' },
            React.createElement(CustomDialog_1.default, { size: 'xs', dismissable: false, isOpen: unlinkAccount, className: styles.modal, content: React.createElement(UnlinkView_1.default, { modal: true, infoUser: infoUser, onDelete: onDelete, unlinkAccount: unlinkAccount, onCancelUnlink: onCancelUnlink }) }))))));
};
function mapStateToProps(state) {
    return {
        user: selectors_1.getCurrentUser(state)
    };
}
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({
        updateUser: UserActions.updateCurrentUser,
        addSocialNetworkLite: SocialAccountLiteActions.addSocialNetworkLite,
        updateSocialNetworkLite: SocialAccountLiteActions.updateSocialNetworkLite,
        deleteSocialNetworkLite: SocialAccountLiteActions.deleteSocialNetworkLite,
        showAlert: appActions.showAlert
    }, dispatch);
}
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(errorStateHandler_1.default(SocialNetworksForm));
