"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const API = require("../lib/api");
const helpers_1 = require("../lib/helpers");
const link_1 = require("apps/shared/hocs/link");
const crispActions_1 = require("utils/crispActions");
const app_1 = require("../actions/app");
const passwordRegex_1 = require("utils/passwordRegex");
const controllers_1 = require("apps/shared/controllers");
const settings_account_content_1 = require("./settings-account-content");
const user_settings_location_phone_1 = require("./user-settings-location-phone");
const cell_phone_verification_1 = require("./../modules/cell-phone-verification");
const DeleteAdvertiserUser_1 = require("modules/accounts/advertiser/containers/DeleteAdvertiserUser");
const MIN_LENGTH_FOR_PASSWORD = 6;
class SettingsAccount extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            emailInput: '',
            changingPassword: false,
            passwordToChange: '',
            currentPassword: '',
            showDelete: false,
            hoverCancel: false,
            password: '',
            errorMessage: null,
            validateTypes: {
                password: val => val.length >= MIN_LENGTH_FOR_PASSWORD,
                passwordToChange: (val, component) => val.length >= MIN_LENGTH_FOR_PASSWORD ||
                    !component.state.changingPassword,
                currentPassword: val => val.length >= MIN_LENGTH_FOR_PASSWORD
            }
        };
        this.settingsUserInfo = React.createRef();
        this.settingsLocationPhone = React.createRef();
        this.saveUserInfo = () => {
            this.settingsUserInfo.current.saveData();
        };
        this.saveUserLocationPhone = () => {
            this.settingsLocationPhone.current.saveData();
        };
        this.onEmailChange = event => {
            this.setState({ emailInput: event.target.value });
        };
        this.onDeleteSelect = () => {
            this.setState({ showDelete: true });
        };
        this.onCancelDelete = () => {
            this.setState({ showDelete: false, hoverCancel: false });
        };
        this.onHoverCancel = () => {
            this.setState({ hoverCancel: true });
        };
        this.onBlurCancel = () => {
            this.setState({ hoverCancel: false });
        };
        this.onShowChangePassword = () => {
            this.setState({ changingPassword: true });
        };
        this.isValidPassword = passwordToChange => {
            return passwordRegex_1.default.test(passwordToChange);
        };
        this.onSavePassword = () => {
            const { emailInput, changingPassword, passwordToChange, currentPassword } = this.state;
            const data = {
                email: emailInput,
                currentPassword
            };
            this.setState({ validationActive: false, errorMessage: '' });
            if (changingPassword && !this.isValidPassword(passwordToChange)) {
                this.setState({
                    validationActive: true,
                    errorMessage: i18n.get('RESET_PASSWORD_INVALID_LENGTH')
                });
                return;
            }
            if (!this.props.validateProperty.call(this, 'currentPassword', currentPassword) ||
                !this.props.validateProperty.call(this, 'passwordToChange', passwordToChange)) {
                this.setState({ validationActive: true });
                return;
            }
            if (changingPassword && passwordToChange !== '') {
                data.password = passwordToChange;
            }
            API.updatePasswordUser(data, (err, res) => {
                if (res.code)
                    return this.showError(res);
                app_1.displayAlert({
                    type: 'success',
                    message: i18n.get('ADV_SETTINGS_ACCOUNT_SAVE_SUCCESS_ALERT_MESSAGE')
                });
                this.setState({
                    currentPassword: '',
                    passwordToChange: '',
                    changingPassword: false
                });
            });
        };
        this.onDeleteUser = () => {
            const { password } = this.state;
            this.setState({ validationActive: false });
            if (!this.props.validateProperty.call(this, 'password', password)) {
                this.setState({ validationActive: true });
                return;
            }
            API.deleteCurrentUser({ password: this.state.password }, (err, res) => {
                if (res.code)
                    return app_1.displayAlert({ message: res.message });
                helpers_1.default.destroyUserSession();
            });
        };
        this.showError = ({ message }) => {
            app_1.displayAlert({ message, type: 'error' });
        };
        this.renderConfig = () => {
            const { user, partnerSettings } = this.props;
            const { emailInput, changingPassword, errorMessage } = this.state;
            const canEditUserData = true;
            const platformName = lodash_1.get(partnerSettings, 'partner.name', 'VoxFeed');
            return (React.createElement("div", null,
                React.createElement("div", { className: 'vf-row padding-top-2x' },
                    React.createElement("div", { className: 'col-xs-12' },
                        React.createElement("h2", null, i18n.get('ADV_SETTINGS_ACCOUNT_PERSONAL_INFO_TITLE')))),
                React.createElement("div", { className: 'vf-row reverse-sm padding-top-1x' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-4' },
                        React.createElement("div", { className: 'vf-descriptor' }, i18n.get('ADV_SETTINGS_ACCOUNT_PERSONAL_INFO_DESCRIPTION', {
                            name: platformName
                        }))),
                    React.createElement("div", { className: 'col-xs-12 show-xs padding-top-2x' }),
                    React.createElement("div", { className: 'col-xs-12 col-sm-8' },
                        React.createElement(settings_account_content_1.default, { user: user, ref: this.settingsUserInfo, disabled: !canEditUserData }),
                        React.createElement("div", { className: 'vf-row padding-top-2x' },
                            React.createElement("div", { className: 'col-xs-12 col-md-offset-4 col-md-8' },
                                React.createElement("div", { className: 'margin-top-1x' },
                                    React.createElement(ui_library_1.Button, { color: 'primary', onClick: this.saveUserInfo, disabled: !canEditUserData }, i18n.get('ADV_SETTINGS_ACCOUNT_SAVE'))))))),
                React.createElement("div", { className: 'vf-row padding-top-2x' },
                    React.createElement("div", { className: 'col-xs-12' },
                        React.createElement("h2", null, i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_TITLE')))),
                React.createElement("div", { className: 'vf-row reverse-sm padding-top-1x' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-4' },
                        React.createElement("div", { className: 'vf-descriptor' }, i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_DESCRIPTION'))),
                    React.createElement("div", { className: 'col-xs-12 show-xs padding-top-2x' }),
                    React.createElement("div", { className: 'col-xs-12 col-sm-8' },
                        React.createElement("div", { className: 'vf-row' },
                            React.createElement("div", { className: 'col-xs-12 col-md-offset-4 col-md-8' },
                                React.createElement(user_settings_location_phone_1.default, { ref: this.settingsLocationPhone, user: user, cellPhoneVerificationComponent: cell_phone_verification_1.default }),
                                React.createElement("div", { className: 'vf-simple-form-label' }, i18n.get('INF_SETTINGS_ACCOUNT_LANGUAGE')),
                                React.createElement(controllers_1.UserLocaleSelector, null),
                                React.createElement("div", { className: 'margin-top-2x' },
                                    React.createElement(ui_library_1.Button, { color: 'primary', onClick: this.saveUserLocationPhone }, i18n.get('ADV_SETTINGS_ACCOUNT_SAVE'))))))),
                React.createElement("div", { className: 'vf-row padding-top-3x' },
                    React.createElement("div", { className: 'col-xs-12' },
                        React.createElement("h2", null, i18n.get('ADV_SETTINGS_ACCOUNT_EMAIL_AND_PASSWORD_TITLE')))),
                React.createElement("div", { className: 'vf-row reverse-sm padding-top-1x' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-4' },
                        React.createElement("div", { className: 'vf-descriptor' }, i18n.get('ADV_SETTINGS_ACCOUNT_EMAIL_AND_PASSWORD_DESCRIPTION', {
                            name: platformName
                        }))),
                    React.createElement("div", { className: 'col-xs-12 show-xs padding-top-2x' }),
                    React.createElement("div", { className: 'col-xs-12 col-sm-8' },
                        React.createElement("div", { className: 'vf-row' },
                            React.createElement("div", { className: 'col-xs-12 col-md-offset-4 col-md-8' },
                                React.createElement("div", { className: 'padding-nonlateral' },
                                    React.createElement("label", { className: 'vf-simple-form-label' }, i18n.get('ADV_SETTINGS_ACCOUNT_EMAIL_AND_PASSWORD_EMAIL_LABEL')),
                                    React.createElement(ui_library_1.Input, { disabled: true, value: emailInput, onChange: this.onEmailChange })),
                                React.createElement("div", { className: 'padding-top-1x' },
                                    React.createElement("label", { className: 'vf-simple-form-label vf-block' }, i18n.get('ADV_SETTINGS_ACCOUNT_EMAIL_AND_PASSWORD_PASSWORD_LABEL')),
                                    changingPassword ? (React.createElement(ui_library_1.Input, Object.assign({ type: 'password', placeholder: i18n.get('ADV_SETTINGS_ACCOUNT_EMAIL_AND_PASSWORD_NEW_PASSWORD_LABEL') }, this.props.linkedValidation.call(this, 'passwordToChange')))) : (React.createElement(ui_library_1.Button, { size: 'sm', onClick: this.onShowChangePassword }, i18n.get('ADV_SETTINGS_ACCOUNT_EMAIL_AND_PASSWORD_CHANGE_PASSWORD_LABEL'))),
                                    errorMessage && (React.createElement("div", { className: 'margin-top-2x' },
                                        React.createElement("span", { className: 'vf-text-danger' }, errorMessage)))),
                                React.createElement("div", { className: 'padding-top-1x' },
                                    React.createElement("label", { className: 'vf-simple-form-label' }, i18n.get('ADV_SETTINGS_ACCOUNT_EMAIL_AND_PASSWORD_UPDATE_PASSWORD_LABEL')),
                                    React.createElement(ui_library_1.Input, Object.assign({ type: 'password', placeholder: i18n.get('ADV_SETTINGS_ACCOUNT_EMAIL_AND_PASSWORD_UPDATE_PASSWORD_LABEL') }, this.props.linkedValidation.call(this, 'currentPassword')))),
                                React.createElement("div", { className: 'padding-top-2x' },
                                    React.createElement(ui_library_1.Button, { color: 'primary', onClick: this.onSavePassword }, i18n.get('ADV_SETTINGS_ACCOUNT_SAVE'))))))),
                React.createElement("div", { className: 'vf-row padding-top-3x' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-8' },
                        React.createElement("div", { className: 'vf-row' },
                            React.createElement("div", { className: 'col-xs-12 col-md-offset-4 col-md-8' },
                                React.createElement("a", { onClick: this.onDeleteSelect }, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_LABEL'))))))));
        };
        this.renderDeleteAccount = () => {
            const { hoverCancel } = this.state;
            const { user, account, partnerSettings } = this.props;
            const faceIcon = hoverCancel ? (React.createElement("div", { className: 'vf-icon vf-face icon-smile-o' })) : (React.createElement("div", { className: 'vf-icon vf-face icon-frown-o' }));
            const platformName = lodash_1.get(partnerSettings, 'partner.name', 'VoxFeed');
            return (React.createElement("div", null,
                React.createElement("div", { className: 'vf-row padding-top-2x' },
                    React.createElement("div", { className: 'col-xs-12' },
                        React.createElement("h2", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_LABEL')))),
                React.createElement("div", { className: 'vf-row reverse-sm padding-top-2x' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-offset-1 col-sm-2' }, faceIcon),
                    React.createElement("div", { className: 'col-xs-12 show-xs padding-top-2x' }),
                    React.createElement("div", { className: 'col-xs-12 col-sm-9 col-lg-offset-3 col-lg-6' },
                        React.createElement("div", { className: 'settings-account-delete' },
                            React.createElement("div", { className: 'vf-font-big' },
                                React.createElement("h4", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_TITLE')),
                                i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_TEXT_WE_CAN_CHANGE'),
                                ' ',
                                React.createElement("a", { onClick: crispActions_1.openChat }, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_TEXT_CONTACT_LINK'))),
                            React.createElement("h4", { className: 'padding-top-2x' }, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_IF_YOU_DELETE_TITLE', { name: platformName })),
                            React.createElement("ul", null,
                                React.createElement("li", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_IF_YOU_DELETE_EARNINGS', {
                                    earnings: i18n.getMoney({ amount: account.balance || 0 })
                                })),
                                React.createElement("li", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_IF_YOU_DELETE_PERFORMANCE')),
                                React.createElement("li", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_IF_YOU_DELETE_AUDIENCE')),
                                React.createElement("li", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_IF_YOU_DELETE_UNLINK')),
                                React.createElement("li", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_IF_YOU_DELETE_MISS_YOU', { username: user.name }))),
                            React.createElement("br", null),
                            React.createElement("br", null),
                            React.createElement("b", null, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_TYPE_YOUR_PASSWORD')),
                            React.createElement("div", { className: 'padding-vertical-1x' },
                                React.createElement(ui_library_1.Input, Object.assign({ type: 'password', placeholder: i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_PASSWORD_INPUT_LABEL') }, this.props.linkedValidation.call(this, 'password')))),
                            React.createElement(ui_library_1.Button, { size: 'sm', color: 'primary', onClick: this.onDeleteUser }, i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_ACCOUNT_PAGE_DELETE_ACCOUNT')),
                            ' ',
                            React.createElement(ui_library_1.Button, { size: 'sm', color: 'default', onClick: this.onCancelDelete, onMouseEnter: this.onHoverCancel, onMouseLeave: this.onBlurCancel }, i18n.get('CANCEL')))))));
        };
    }
    UNSAFE_componentWillMount() {
        const email = lodash_1.get(this.props.user, ['email']);
        this.setState({ emailInput: email });
    }
    render() {
        const { account } = this.props;
        const { showDelete } = this.state;
        const content = showDelete ? (React.createElement(DeleteAdvertiserUser_1.default, { account: account, onCancelDelete: this.onCancelDelete })) : (this.renderConfig());
        return (React.createElement("div", { className: 'vf-wrapper' },
            React.createElement("div", { className: 'vf-row' },
                React.createElement("div", { className: 'col-xs-12' },
                    React.createElement("h3", { className: 'flex-1' }, i18n.get('ADV_SETTINGS_ACCOUNT_TITLE')))),
            content));
    }
}
const mapStateToProps = state => ({
    partnerSettings: state.app.partnerSettings
});
exports.default = redux_1.compose(react_redux_1.connect(mapStateToProps), link_1.default)(SettingsAccount);
