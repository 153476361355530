import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import { bindActionCreators } from 'redux';
import i18n from 'i18n';

import {
  getCurrentUser,
  getCurrentInfluencerSocialAccounts
} from 'modules/shared/selectors';

import shortText from 'modules/legacy/js/lib/short-text';
import Avatar from 'apps/shared/components/Avatar/Avatar';

import { defaultAvatarImg } from 'assets/images';
import SocialBadge from 'apps/shared/components/SocialBadge/SocialBadge';
import SocialAvatar from 'modules/legacy/js/components/social-avatar';
import * as SocialAccountsLiteActions from 'redux/modules/socialNetworksLite';

const MAX_USERNAME_LENGTH = 12;
const MAX_SOCIAL_ACCOUNTS_TO_SHOW = 5;

function mapStateToProps(state) {
  const currency = get(state, 'app.systemSettings.currency', {});
  return {
    user: getCurrentUser(state),
    socialAccounts: getCurrentInfluencerSocialAccounts(state),
    currency
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSocialAccountsLite: SocialAccountsLiteActions.showSocialAccountsLite
    },
    dispatch
  );
}

class ProfileCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountData: []
    };
  }

  componentDidMount() {
    const { user, showSocialAccountsLite } = this.props;
    const { validCategory } = user;
    const influencerId = user.id || user._id;
    if (validCategory) {
      showSocialAccountsLite(influencerId).then(data => {
        this.setState({
          accountData: data
        });
      });
    }
  }

  renderSocialAccounts = () => {
    const { socialAccounts } = this.props;
    const { accountData } = this.state;
    let socialAccountBadges;

    if (socialAccounts.length > 0) {
      socialAccountBadges = socialAccounts.map((acc, idx) => {
        if (idx + 1 > MAX_SOCIAL_ACCOUNTS_TO_SHOW) {
          return <noscript key={acc._id} />;
        }

        const style =
          acc.canParticipate === false ? { filter: 'grayscale(100%)' } : {};

        return (
          <div key={acc._id} style={style}>
            <SocialAvatar
              size='sm'
              className='margin-right-Hx'
              socialAccount={acc}
            />
          </div>
        );
      });
    } else if (accountData.length > 0) {
      socialAccountBadges = accountData.map((network, idx) => {
        if (idx + 1 > MAX_SOCIAL_ACCOUNTS_TO_SHOW) {
          return <noscript key={network.id} />;
        }

        if (network.active) {
          return (
            <div key={network.id}>
              <Avatar image={network.userPhoto || defaultAvatarImg} size='sm' />

              <SocialBadge
                size={13}
                style={{ marginLeft: '-1.9rem' }}
                socialNetwork={network.socialNetwork}
              />
            </div>
          );
        }
      });
    }

    const totalExtraAccounts =
      socialAccounts.length - MAX_SOCIAL_ACCOUNTS_TO_SHOW;

    return (
      <div className='flex'>
        {socialAccountBadges}
        {totalExtraAccounts > 0 && (
          <div
            key='extraAccounts'
            className='vf-text-primary'
            style={{ fontSize: '2.75rem' }}
          >
            {`+${totalExtraAccounts}`}
          </div>
        )}
      </div>
    );
  };

  renderAlertMessage = () => {
    const { socialAccounts } = this.props;
    const accountsUnableToParticipate = socialAccounts.filter(
      acc => acc.hasMinEngagements === false
    );

    if (isEmpty(accountsUnableToParticipate)) return <noscript />;

    return (
      <div
        className='flex width100 margin-top-1x'
        style={{ fontSize: '1.5rem' }}
      >
        <i className='vf-icon icon-exclamation-triangle vf-text-warning margin-right-Hx margin-top-Hx' />
        <span className='vf-text-gray'>
          {accountsUnableToParticipate.length === 1 &&
            this.getMessageForSingleAccountUnableToParticipate(
              accountsUnableToParticipate[0]
            )}
          {accountsUnableToParticipate.length > 1 &&
            this.getMessageForMultipleAccountsUnableToParticipate()}
        </span>
        &nbsp;
        <Link to='/social-accounts'>
          {i18n.get('INF_CAMPAIGNS_MY_ACCOUNT_GO_TO_SOCIAL_ACCOUNTS')}
        </Link>
      </div>
    );
  };

  getMessageForSingleAccountUnableToParticipate = account => {
    const username = shortText(
      get(account, 'username', ''),
      MAX_USERNAME_LENGTH
    );
    return i18n.getHTML(
      'INF_CAMPAIGNS_MY_ACCOUNT_SOCIAL_NETWORK_UNABLE_TO_PARTICIPATE_ALERT',
      { username }
    );
  };

  getMessageForMultipleAccountsUnableToParticipate = () =>
    i18n.get(
      'INF_CAMPAIGNS_MY_ACCOUNT_MULTIPLE_SOCIAL_NETWORKS_UNABLE_TO_PARTICIPATE_ALERT'
    );

  render = () => {
    const { user, socialAccounts, currency } = this.props;
    const { accountData } = this.state;
    const { account, createdAt, profileImage, name } = user;
    const balance = get(account, 'balance', 0);
    const { validCategory } = user;
    const currencySuffix = get(currency, 'suffix', '');
    const hasSocialAccounts = socialAccounts.length > 0;
    const hasSocialAccountsLite = accountData.length > 0;

    return (
      <div className='vf-card' style={{ height: '20rem' }}>
        <div className='vf-row no-margin width100 height100 flex middle-xs'>
          <div className='flex no-margin no-padding'>
            <div className='flex center-xs top-xs'>
              <Avatar size='lg' image={profileImage} />
            </div>

            <div className='padding-left-1x'>
              <h1 className='vf-font-bold'>
                {i18n.get('INF_CAMPAIGNS_MY_ACCOUNT_GREETING', { name })}
              </h1>
              <div className='flex width100 margin-top-Hx'>
                <div className='padding-right-1x'>
                  <h5 className='vf-font-normal'>
                    {i18n.get('INF_CAMPAIGNS_MY_ACCOUNT_SINCE_TITLE')}
                  </h5>

                  <h5 className='vf-font-normal'>
                    {i18n.get('MEDIUM_DATE', { value: new Date(createdAt) })}
                  </h5>
                </div>
                {false && (
                  <div className='padding-right-1x'>
                    <h5 className='vf-font-normal'>
                      {i18n.get('INF_CAMPAIGNS_MY_ACCOUNT_EARNINGS_TITLE')}
                    </h5>

                    <h5 className='vf-font-normal'>
                      {i18n.getMoney({
                        amount: balance,
                        currency: currencySuffix
                      })}
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='hide-xs col' style={{ marginLeft: 'auto' }}>
            {((hasSocialAccounts && !validCategory) ||
              (hasSocialAccountsLite && validCategory)) && (
              <div className='profile-card-item'>
                <h3 className='no-padding'>
                  {i18n.get('INF_CAMPAIGNS_MY_ACCOUNT_SOCIAL_NETWORKS_TITLE')}
                </h3>

                {this.renderSocialAccounts()}
                {this.renderAlertMessage()}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
}

ProfileCard.propTypes = {
  user: PropTypes.object.isRequired,
  socialAccounts: PropTypes.array.isRequired,
  showSocialAccountsLite: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
