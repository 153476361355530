"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const get = require("lodash/get");
const chatsActions = require("redux/modules/chats");
const campaignsActions = require("redux/modules/campaigns");
const accountsActions = require("redux/modules/accounts");
const selectors_1 = require("modules/shared/selectors");
const Loading_1 = require("apps/shared/components/Loading/Loading");
const LayoutUI_1 = require("./LayoutUI");
class Layout extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isLoading: true
        };
        this.fetchData = () => {
            const { user, fetchInfluencerChats, fetchInfluencerMemberInfo, fetchInvites, fetchPublicCampaigns } = this.props;
            if (user.role !== 'influencer')
                return;
            const influencerId = get(user, 'account._id');
            this.fetchSocialAccounts();
            fetchInvites(influencerId);
            fetchInfluencerChats();
            fetchInfluencerMemberInfo();
            fetchPublicCampaigns(influencerId);
        };
        this.fetchSocialAccounts = () => {
            const { user, fetchSocialAccounts } = this.props;
            this.setState({ isLoading: true }, () => {
                const influencerId = get(user, 'account._id');
                fetchSocialAccounts(influencerId).then(this.onFetchSocialAccountsSuccess);
            });
        };
        this.onFetchSocialAccountsSuccess = () => {
            this.setState({ isLoading: false });
        };
    }
    componentDidMount() {
        this.fetchData();
    }
    componentDidUpdate(prevProps) {
        const prevRole = get(prevProps, 'user.role');
        const nextRole = get(this.props, 'user.role');
        const didRoleChange = prevRole !== nextRole;
        if (didRoleChange)
            this.fetchData();
    }
    render() {
        const { location, children } = this.props;
        const { isLoading } = this.state;
        const query = qs.parse(location.search);
        if (isLoading)
            return React.createElement(Loading_1.default, null);
        return React.createElement(LayoutUI_1.default, { partnerId: get(query, 'partnerId') }, children);
    }
}
function mapStateToProps(state) {
    const user = selectors_1.getCurrentUser(state);
    return {
        user
    };
}
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({
        fetchInfluencerChats: chatsActions.fetchInfluencerChats,
        fetchInfluencerMemberInfo: chatsActions.fetchInfluencerMemberInfoInChats,
        fetchInvites: campaignsActions.fetchInvitesForCurrentInfluencer,
        fetchSocialAccounts: accountsActions.fetchSocialAccountsByAccountId,
        fetchPublicCampaigns: campaignsActions.fetchInfluencerAvailableCampaigns
    }, dispatch);
}
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Layout);
