"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReport = exports.AudienceAuthenticityLevel = exports.AudienceType = exports.PendingTaskType = exports.CampaignStatus = exports.Gender = exports.UserRole = exports.TypeOfReport = exports.reasonCancelInvitation = exports.ErrorType = exports.InviteType = exports.InvitePaymentStatus = exports.InviteFinishReason = exports.ReportStatus = exports.InviteLiteStatus = exports.InviteStatus = exports.ProductDeliveryType = exports.PaymentStatus = exports.PaymentMethod = exports.ParticipationType = exports.CampaignAccountType = exports.PublicationType = exports.SocialNetwork = exports.CampaignTypes = void 0;
var CampaignTypes;
(function (CampaignTypes) {
    CampaignTypes["PUBLIC_MODE"] = "opened";
    CampaignTypes["PRIVATE_MODE"] = "invitation";
})(CampaignTypes = exports.CampaignTypes || (exports.CampaignTypes = {}));
var SocialNetwork;
(function (SocialNetwork) {
    SocialNetwork["INSTAGRAM"] = "instagram";
    SocialNetwork["FACEBOOK"] = "facebook";
    SocialNetwork["YOUTUBE"] = "youtube";
    SocialNetwork["TIKTOK"] = "tiktok";
})(SocialNetwork = exports.SocialNetwork || (exports.SocialNetwork = {}));
var PublicationType;
(function (PublicationType) {
    PublicationType["STORY"] = "story";
    PublicationType["POST"] = "post";
})(PublicationType = exports.PublicationType || (exports.PublicationType = {}));
var CampaignAccountType;
(function (CampaignAccountType) {
    CampaignAccountType["PERSON"] = "person";
    CampaignAccountType["TOPIC"] = "topic";
})(CampaignAccountType = exports.CampaignAccountType || (exports.CampaignAccountType = {}));
var ParticipationType;
(function (ParticipationType) {
    ParticipationType["INVITATION"] = "invitation";
    ParticipationType["LINK"] = "LINK";
    ParticipationType["PUBLIC"] = "public";
})(ParticipationType = exports.ParticipationType || (exports.ParticipationType = {}));
var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["INVOICE"] = "invoice";
    PaymentMethod["PAYPAL"] = "paypal";
    PaymentMethod["OPENPAY"] = "openpay";
    PaymentMethod["PLATFORM"] = "platform";
    PaymentMethod["MANUALLY"] = "manually";
    PaymentMethod["PAYONEER"] = "payoneer";
    PaymentMethod["TIPALTI"] = "tipalti";
    PaymentMethod["ADMIN"] = "admin";
    PaymentMethod["TRANSFER"] = "transfer";
    PaymentMethod["EXTERNAL"] = "external";
})(PaymentMethod = exports.PaymentMethod || (exports.PaymentMethod = {}));
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["CREATED"] = "created";
    PaymentStatus["PROCESSING"] = "processing";
    PaymentStatus["COMPLETED"] = "completed";
    PaymentStatus["CANCELLED"] = "cancelled";
    PaymentStatus["PENALIZED"] = "penalized";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
var ProductDeliveryType;
(function (ProductDeliveryType) {
    ProductDeliveryType["NOT_APPLICABLE"] = "notApplicable";
    ProductDeliveryType["PICKUP"] = "pickUp";
    ProductDeliveryType["SHIP"] = "ship";
})(ProductDeliveryType = exports.ProductDeliveryType || (exports.ProductDeliveryType = {}));
var InviteStatus;
(function (InviteStatus) {
    InviteStatus["ACCEPTED"] = "accepted";
    InviteStatus["CANCELLED"] = "cancelled";
    InviteStatus["CREATED"] = "created";
    InviteStatus["FINISHED"] = "finished";
    InviteStatus["REPORTED"] = "reported";
    InviteStatus["PENALIZED"] = "penalized";
})(InviteStatus = exports.InviteStatus || (exports.InviteStatus = {}));
var InviteLiteStatus;
(function (InviteLiteStatus) {
    InviteLiteStatus["ACCEPTED"] = "to_post";
    InviteLiteStatus["REPORTED"] = "reported";
    InviteLiteStatus["FINISHED"] = "participation_successful";
    InviteLiteStatus["CANCELLED"] = "finalized_by_influencer_inactivity";
    InviteLiteStatus["CANCELLEDBYADVERTISER"] = "cancelled";
    InviteLiteStatus["REPORTED_PARTICIPATION"] = "reported_participation";
})(InviteLiteStatus = exports.InviteLiteStatus || (exports.InviteLiteStatus = {}));
var ReportStatus;
(function (ReportStatus) {
    ReportStatus["REPORTED_USER"] = "reported_user";
    ReportStatus["REPORTED_PARTICIPATION"] = "reported_participation";
})(ReportStatus = exports.ReportStatus || (exports.ReportStatus = {}));
var InviteFinishReason;
(function (InviteFinishReason) {
    InviteFinishReason["COMPLETED_PUBLICATIONS"] = "completedPublications";
    InviteFinishReason["FINISHED_BY_ADVERTISER"] = "finishedByAdvertiser";
    InviteFinishReason["FINISHED_BY_INFLUENCER"] = "finishedByInfluencer";
    InviteFinishReason["INFLUENCER_INACTIVITY"] = "influencerInactivity";
    InviteFinishReason["PREPAID_ACCEPTED"] = "prepaidParticipationAccepted";
})(InviteFinishReason = exports.InviteFinishReason || (exports.InviteFinishReason = {}));
var InvitePaymentStatus;
(function (InvitePaymentStatus) {
    InvitePaymentStatus["COMPLETED"] = "completed";
    InvitePaymentStatus["NOT_APPLICABLE"] = "notApplicable";
    InvitePaymentStatus["PENDING"] = "pending";
    InvitePaymentStatus["PENALIZED"] = "penalized";
})(InvitePaymentStatus = exports.InvitePaymentStatus || (exports.InvitePaymentStatus = {}));
var InviteType;
(function (InviteType) {
    InviteType["PUBLIC"] = "public";
    InviteType["PRIVATE"] = "private";
    InviteType["PREPAID"] = "prepaid";
})(InviteType = exports.InviteType || (exports.InviteType = {}));
var ErrorType;
(function (ErrorType) {
    ErrorType["INVALID_CREDENTIAL"] = "AUTH::INVALID_CREDENTIALS";
    ErrorType["NOT_A_LITE_ACCOUNT"] = "DATABASE::ENTITY_NOT_FOUND";
})(ErrorType = exports.ErrorType || (exports.ErrorType = {}));
var reasonCancelInvitation;
(function (reasonCancelInvitation) {
    reasonCancelInvitation["ENDED"] = "The campaign ended";
    reasonCancelInvitation["CHANGED"] = "The objective of the campaign changed";
    reasonCancelInvitation["INADEQUATE"] = "The influencer's performance is inadequate";
    reasonCancelInvitation["OTHER"] = "Other";
})(reasonCancelInvitation = exports.reasonCancelInvitation || (exports.reasonCancelInvitation = {}));
var TypeOfReport;
(function (TypeOfReport) {
    TypeOfReport["USERS"] = "users";
    TypeOfReport["POST"] = "post";
})(TypeOfReport = exports.TypeOfReport || (exports.TypeOfReport = {}));
var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "admin";
    UserRole["INFLUENCER"] = "influencer";
    UserRole["ADVERTISER"] = "advertiser";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
var Gender;
(function (Gender) {
    Gender["MALE"] = "male";
    Gender["FEMALE"] = "female";
    Gender["OTHER"] = "other";
})(Gender = exports.Gender || (exports.Gender = {}));
var CampaignStatus;
(function (CampaignStatus) {
    CampaignStatus["ACTIVE"] = "active";
    CampaignStatus["ARCHIVED"] = "archived";
    CampaignStatus["PAUSED"] = "paused";
    CampaignStatus["DELETED"] = "deleted";
})(CampaignStatus = exports.CampaignStatus || (exports.CampaignStatus = {}));
var PendingTaskType;
(function (PendingTaskType) {
    PendingTaskType["PENDING_PROPOSALS"] = "pendingProposals";
    PendingTaskType["PENDING_PUBLICATION"] = "pendingPublication";
    PendingTaskType["PENDING_STATS"] = "pendingToUploadStats";
    PendingTaskType["PENDING_FEEDBACK"] = "pendingFeedback";
})(PendingTaskType = exports.PendingTaskType || (exports.PendingTaskType = {}));
var AudienceType;
(function (AudienceType) {
    AudienceType["INFLUENCERS"] = "influencers";
    AudienceType["MASSIVE_FOLLOWING"] = "massiveFollowing";
    AudienceType["REAL"] = "real";
    AudienceType["SUSPICIOUS"] = "suspicious";
})(AudienceType = exports.AudienceType || (exports.AudienceType = {}));
var AudienceAuthenticityLevel;
(function (AudienceAuthenticityLevel) {
    AudienceAuthenticityLevel["EXCELLENT"] = "excellent";
    AudienceAuthenticityLevel["GOOD"] = "good";
    AudienceAuthenticityLevel["REGULAR"] = "regular";
    AudienceAuthenticityLevel["BAD"] = "bad";
})(AudienceAuthenticityLevel = exports.AudienceAuthenticityLevel || (exports.AudienceAuthenticityLevel = {}));
var EReport;
(function (EReport) {
    EReport["OTHER"] = "other";
})(EReport = exports.EReport || (exports.EReport = {}));
