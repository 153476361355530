"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDetailService = exports.getAutocompleteService = exports.getGoogleInstance = void 0;
const GoogleMapsApiLoader = require("google-maps-api-loader");
const app_1 = require("config/app");
let google = null;
let autocompleteService = null;
let detailService = null;
GoogleMapsApiLoader({
    libraries: ['places'],
    apiKey: app_1.placesToken
})
    .then(googleApi => {
    google = googleApi;
});
function getGoogleInstance() {
    return google;
}
exports.getGoogleInstance = getGoogleInstance;
function getAutocompleteService() {
    if (!autocompleteService) {
        autocompleteService = new google.maps.places.AutocompleteService();
    }
    return autocompleteService;
}
exports.getAutocompleteService = getAutocompleteService;
function getDetailService() {
    if (!detailService) {
        detailService = new google.maps.places.PlacesService(document.createElement('div'));
    }
    return detailService;
}
exports.getDetailService = getDetailService;
