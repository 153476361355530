import axios from 'axios';

import i18n from 'i18n';

export const createLite = ({ brandId, ...data }) =>
  axios.post(`/lite/brands/${brandId}/campaigns`, data);

export const updateLite = ({ id, ...data }) =>
  axios.put(`/lite/campaigns/${id}`, data);

export const updateStatusLite = id => axios.put(`/lite/campaigns/status/${id}`);

export const getCampaignStatsGroupsLite = ({ campaignId, ...params }) =>
  axios.get(`/lite/campaign/${campaignId}/stats-groups`, { params });

export const getCampaignPostsStatsLite = ({ id, ...params }) =>
  axios.get(`/lite/campaigns/${id}/stats/posts`, { params });

export const getInfluencerDemographicsLite = campaignId =>
  axios.get(`/lite/campaigns/${campaignId}/stats/influencer-demographics`, {
    headers: { lang: i18n.getLocale() }
  });

export const getParticipationsLite = ({ campaignId, ...params }) =>
  axios.get(`/lite/campaigns/${campaignId}/participations`, { params });

export const exportParticipationsLite = ({ id, ...params }) =>
  axios.get(`/lite/campaigns/${id}/participations/export`, { params });

export const getPostLite = ({ campaignId, ...params }) =>
  axios.get(`/lite/campaigns/${campaignId}/posts`, { params });

export const exportPostLite = ({ id, ...params }) =>
  axios.get(`/lite/campaigns/${id}/posts/export`, { params });

export const ratePostLite = ({ postId, ...data }) =>
  axios.post(`/lite/participations/${postId}/rate`, data);

export const reportPostLite = ({ postId, ...data }) =>
  axios.post(`/lite/participations/${postId}/report`, data);

export const exportGeneralPerformanceLite = ({ id, ...data }) =>
  axios.post(`lite/campaigns/${id}/overview/export`, data);

export const finishInviteLite = ({ id, ...data }) =>
  axios.post(`/lite/participations/${id}/cancel`, data);

export const reportUserLite = ({ id, ...data }) =>
  axios.post(`/lite/participations/${id}/report-user `, data);

export const getCampaignAvailabilityLite = id =>
  axios.get(`lite/campaigns/${id}`);
