"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const userActions = require("redux/modules/users");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const ui_library_1 = require("ui-library");
class PrivacyDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.handleAccept = () => {
            const { acceptPrivacyPolicy, user } = this.props;
            acceptPrivacyPolicy(user._id);
        };
        this.handleReject = () => {
            const { logout } = this.props;
            logout();
        };
    }
    render() {
        return (React.createElement("div", null,
            React.createElement("p", { className: 'padding-2x vf-text-gray', style: { fontSize: '2.2rem' } },
                React.createElement("a", { href: i18n.get('HELP_LINKS_TERMS'), target: '_blank', rel: 'noreferrer' }, i18n.get('PRIVACY_POLICY_DIALOG_LINK')),
                React.createElement("span", null, i18n.get('PRIVACY_POLICY_DIALOG_INFO'))),
            React.createElement("div", { className: 'flex flex-justify-right padding-1x vf-border-top' },
                React.createElement("a", { href: 'http://www.voxfeed.com', className: 'padding-horizontal-2x vf-text-secondary' }, i18n.get('NO_ACCEPT')),
                React.createElement(ui_library_1.Button, { color: 'primary', onClick: this.handleAccept }, i18n.get('ACCEPT')))));
    }
}
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    acceptPrivacyPolicy: userActions.acceptPrivacyPolicy
}, dispatch);
exports.default = react_redux_1.connect(null, mapDispatchToProps)(PrivacyDialog);
