"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const styles = require("../CampaignBrief.less");
const SponsoredCode = ({ campaign }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const isTiktok = ((_b = (_a = campaign === null || campaign === void 0 ? void 0 : campaign.tiktok) === null || _a === void 0 ? void 0 : _a.post) === null || _b === void 0 ? void 0 : _b.boostCode) && ((_d = (_c = campaign === null || campaign === void 0 ? void 0 : campaign.tiktok) === null || _c === void 0 ? void 0 : _c.post) === null || _d === void 0 ? void 0 : _d.active);
    const isInstagram = (((_f = (_e = campaign === null || campaign === void 0 ? void 0 : campaign.instagram) === null || _e === void 0 ? void 0 : _e.post) === null || _f === void 0 ? void 0 : _f.boostCode) && ((_h = (_g = campaign === null || campaign === void 0 ? void 0 : campaign.instagram) === null || _g === void 0 ? void 0 : _g.post) === null || _h === void 0 ? void 0 : _h.active)) ||
        (((_k = (_j = campaign === null || campaign === void 0 ? void 0 : campaign.instagram) === null || _j === void 0 ? void 0 : _j.story) === null || _k === void 0 ? void 0 : _k.boostCode) && ((_m = (_l = campaign === null || campaign === void 0 ? void 0 : campaign.instagram) === null || _l === void 0 ? void 0 : _l.story) === null || _m === void 0 ? void 0 : _m.active));
    return (React.createElement(React.Fragment, null, (isInstagram || isTiktok) && (React.createElement("div", null,
        React.createElement("h3", null, i18n.get('ADV_CAMPAIGNS_WIZARD_SPONSORED_CODE_LABEL')),
        isInstagram && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.boostCode },
                React.createElement("h5", { className: 'width-auto' }, i18n.get('ADV_CAMPAIGNS_WIZARD_INSTAGRAM_AD_CODE_LABEL')),
                React.createElement("a", { target: '_blank', rel: 'noreferrer', href: i18n.get('HELP_AD_CODE_INSTAGRAM') },
                    React.createElement("i", { className: 'icon-question vf-icon-badge -sm margin-left-Hx vf-bg-gray-color vf-clickable' }))),
            React.createElement("p", { className: 'margin-bottom-Hx' }, i18n.getHTML('INF_VOXEAR_CAMPAIGN_ADCODE_REQUIRE')))),
        isTiktok && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.boostCode },
                React.createElement("h5", { className: 'width-auto' }, i18n.get('ADV_CAMPAIGNS_WIZARD_TIKTOK_BOOST_CODE_LABEL')),
                React.createElement("a", { target: '_blank', rel: 'noreferrer', href: i18n.get('HELP_BOOST_CODE_TIKTOK') },
                    React.createElement("i", { className: 'icon-question vf-icon-badge -sm margin-left-Hx vf-bg-gray-color vf-clickable' }))),
            React.createElement("p", null, i18n.getHTML('INF_VOXEAR_CAMPAIGN_BOOSTCODE_REQUIRE', {
                boostCodeDays: (_o = campaign === null || campaign === void 0 ? void 0 : campaign.tiktok) === null || _o === void 0 ? void 0 : _o.post.boostCodeDays
            }))))))));
};
exports.default = SponsoredCode;
