import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from 'redux/modules/shared';
import { removeAll as removeAllEntities } from 'redux/modules/entities';
import * as SocialAccountsLiteActions from 'redux/modules/socialNetworksLite';
import PanelView from 'modules/legacy/js/views/panel';

function mapStateToProps({ shared, app }) {
  const { user, session, admin } = shared;
  return {
    user,
    session,
    admin,
    currentLanguage: app.currentLanguage
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
      removeAllEntities,
      showSocialAccountsLite: SocialAccountsLiteActions.showSocialAccountsLite
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelView);
