import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, without, pull } from 'lodash';
import { Select, Checkbox } from 'ui-library';

import i18n from 'i18n';
import { FilterWrapper } from '../components';
import * as styles from './InfluencerFilters.less';
import { userVerificationEnabled } from 'config/app';
import { IconAccountVerification } from 'assets/images';
import InfluencerFilterHeader from './InfluencerFilterHeader';
import NumberInput from 'modules/shared/components/NumberInput';
import RateSelector from 'modules/shared/components/RateSelector/RateSelector';
import LocationSelect from 'apps/shared/controllers/LocationSelect/LocationSelect';
import TagsSearcher from 'modules/campaigns/advertiser/containers/TagsSearcher/TagsSearcher';
import InfluencerTypeSelect from 'modules/campaigns/advertiser/containers/InfluencerTypeSelect/InfluencerTypeSelect';

import {
  Pulse,
  IconTooltip,
  VerificationBadge
} from 'modules/shared/components';

const TYPE = 'type';
const MIN_ENGAGEMENT_RATE = 'engagementRate>';
const MAX_ENGAGEMENT_RATE = 'engagementRate<';
const MIN_CPM = 'cpm>';
const MAX_CPM = 'cpm<';
const MIN_CPE = 'cpe>';
const MAX_CPE = 'cpe<';
const MIN_SUGGESTED_POST_PRICE = 'postPrice.suggested>';
const MAX_SUGGESTED_POST_PRICE = 'postPrice.suggested<';
const MIN_RATING = 'rating>';
const TAGS = 'tags';
const MIN_AUDIENCE = 'reach>';
const MAX_AUDIENCE = 'reach<';
const MIN_EXPECTED_ENGAGEMENTS = 'expectedEngagements>';
const MAX_EXPECTED_ENGAGEMENTS = 'expectedEngagements<';
const ACCOUNT_TYPE = 'accountType';
const ACCOUNT_SUB_TYPE = 'accountSubtype';
const MIN_AGE = 'minAge';
const MAX_AGE = 'maxAge';
const GENDER = 'gender';
const FAVORITE = 'favorite';
const SHOW_BLOCKED = 'showBlocked';
const VERIFIED = 'isVerified';
const VALIDATED = 'contactInfo.already_signed_contracts';
const LOCATIONS_FIELDS = ['country', 'region', 'city'];

class InfluencerFilters extends React.Component {
  state = { pulseAccountType: false };

  onTogglePersonalAccounts = () => {
    const onlyPersonalAccounts =
      get(this.props.filters, ACCOUNT_TYPE, false) === 'person';

    if (!onlyPersonalAccounts) {
      return this.props.onChange(ACCOUNT_TYPE, 'person');
    }

    this.props.onChange(ACCOUNT_TYPE, undefined);
    this.props.onChange(GENDER, undefined);
    this.props.onChange(MIN_AGE, undefined);
    this.props.onChange(MAX_AGE, undefined);
  };

  onToggleBlocked = () => {
    const { onChange, filters } = this.props;
    const showBlocked = get(filters, SHOW_BLOCKED);
    onChange(SHOW_BLOCKED, !showBlocked);
  };

  onToggleFavorite = () => {
    const { onChange, filters } = this.props;
    const showFavorite = get(filters, FAVORITE);
    onChange(FAVORITE, !showFavorite);
  };

  onToggleVerified = () => {
    const { onChange, filters } = this.props;
    const showVerified = get(filters, VERIFIED);
    onChange(VERIFIED, !showVerified);
  };

  onToggleValidated = () => {
    const { onChange, filters } = this.props;
    const showValidated = get(filters, VALIDATED);
    onChange(VALIDATED, !showValidated);
  };

  onToggleSocialNetworkType = socialNetwork => {
    const { onChange, filters } = this.props;
    const aFilters = get(filters, TYPE, '').replace('none', '').split(',');

    pull(aFilters, '');

    const newFilter = this.hasSocialNetworkType(socialNetwork)
      ? without(aFilters, socialNetwork).join(',')
      : [...aFilters, socialNetwork].join(',');

    onChange(TYPE, newFilter || 'none');
  };

  onPersonalInputFocus = () => {
    const { onChange, filters } = this.props;

    if (get(filters, ACCOUNT_TYPE) !== 'person') {
      this.setState(
        { pulseAccountType: true },
        onChange(ACCOUNT_TYPE, 'person')
      );
    }
  };

  getSelectedLocations = () =>
    LOCATIONS_FIELDS.reduce((selectedLocations, locationField) => {
      const locations = get(this.props.filters, locationField) || [];
      return { ...selectedLocations, [locationField]: locations };
    }, {});

  getRatingsFilterComponent = () => {
    const filterRatingOptions = [4, 3, 2, 1];
    const selectedRating = this.props.filters[MIN_RATING];

    return filterRatingOptions.map(ratingOption => {
      const containerClasses = classNames('flex vf-clickable', {
        'vf-font-bold': ratingOption === selectedRating
      });

      return (
        <div
          key={ratingOption}
          className={containerClasses}
          onClick={() => this.props.onChange(MIN_RATING, ratingOption)}
        >
          <RateSelector
            transparent
            readOnly
            rate={ratingOption}
            iconStyles={{
              fontSize: '2rem'
            }}
            type='gold'
            style={{
              width: '11rem',
              padding: '0.5rem 0.5rem 0 0',
              height: '3.5rem'
            }}
          />
          {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_RATING_OR_MORE_DESCRIPTION')}
        </div>
      );
    });
  };

  hasSocialNetworkType = socialNetwork => {
    const { filters } = this.props;
    return get(filters, TYPE, '').replace('none', '').includes(socialNetwork);
  };

  mapSocialNetworkOptions = () => {
    const { availableSocialNetworks, filters } = this.props;
    const extra = get(filters, TYPE, '').replace('none', '');
    const snFormat = [];

    availableSocialNetworks.map(current => snFormat.push(current[0]));

    const selectOptions = snFormat.reduce(
      (options, socialNetwork) => ({
        ...options,
        [socialNetwork]: socialNetwork
      }),
      {
        [snFormat.join(',')]: i18n.get(
          'ADV_INFLUENCERS_SEARCH_FILTER_SOCIAL_NETWORK_ANY'
        )
      }
    );

    return { [extra]: extra, ...selectOptions };
  };

  render() {
    const { pulseAccountType } = this.state;

    const {
      filters,
      onChange,
      showFavoriteOption,
      showBlockedOption,
      availableSocialNetworks,
      isOpened,
      onRequestToggleFilters
    } = this.props;

    const snFormat = [];
    availableSocialNetworks.map(current => snFormat.push(current[0]));

    const selectedLocations = this.getSelectedLocations();

    const genderOptions = {
      all: i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_GENDER_OPTIONS_ALL'),
      female: i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_GENDER_OPTIONS_FEMALE'),
      male: i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_GENDER_OPTIONS_MALE'),
      other: i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_GENDER_OPTIONS_OTHER')
    };

    const typeList = availableSocialNetworks.map(currentSn => (
      <Checkbox
        key={currentSn[0]}
        className='margin-top-Hx'
        checked={this.hasSocialNetworkType(currentSn[0])}
        onChange={() => this.onToggleSocialNetworkType(currentSn[0])}
      >
        {currentSn[1]}
      </Checkbox>
    ));

    return (
      <InfluencerFilterHeader
        filters={filters}
        onChange={onChange}
        isOpened={isOpened}
        genderOptions={genderOptions}
        selectedLocations={selectedLocations}
        onRequestToggleFilters={onRequestToggleFilters}
        availableSocialNetworks={snFormat}
      >
        <FilterWrapper
          title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_TAG')}
          keepOpen={!!(get(filters, TAGS) && get(filters, TAGS).length > 0)}
        >
          <TagsSearcher
            tags={get(filters, TAGS)}
            onChange={tags => onChange(TAGS, tags)}
          />
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_SOCIAL_NETWORK')}
          keepOpen={
            !!(
              get(filters, TYPE, '').replace('none', '').split(',').length !==
                availableSocialNetworks.length ||
              get(filters, MIN_AUDIENCE, '') ||
              get(
                filters,
                MAX_AUDIENCE,
                '' ||
                  get(filters, MIN_EXPECTED_ENGAGEMENTS) ||
                  get(filters, MAX_EXPECTED_ENGAGEMENTS) ||
                  get(filters, MIN_ENGAGEMENT_RATE) ||
                  get(filters, MAX_ENGAGEMENT_RATE)
              )
            )
          }
        >
          {typeList}

          <div className='vf-font-normal margin-top-2x vf-text-gray-dark'>
            {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_AUDIENCE_LABEL')}
          </div>

          <div className='flex'>
            <NumberInput
              allowDecimals={false}
              className='-sm-inline vf-text-center'
              value={get(filters, MIN_AUDIENCE, '')}
              onChange={value => onChange(MIN_AUDIENCE, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MIN_PLACEHOLDER'
              )}
            />

            <span className='center-xs padding-1x'>–</span>

            <NumberInput
              allowDecimals={false}
              className='-sm-inline vf-text-center'
              value={get(filters, MAX_AUDIENCE, '')}
              onChange={value => onChange(MAX_AUDIENCE, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MAX_PLACEHOLDER'
              )}
            />
          </div>

          <div className='vf-font-normal margin-top-2x vf-text-gray-dark'>
            {i18n.get(
              'ADV_INFLUENCERS_SEARCH_FILTER_EXPECTED_ENGAGEMENTS_LABEL'
            )}
          </div>

          <div className='flex'>
            <NumberInput
              allowDecimals={false}
              className='-sm-inline vf-text-center'
              value={get(filters, MIN_EXPECTED_ENGAGEMENTS, '')}
              onChange={value => onChange(MIN_EXPECTED_ENGAGEMENTS, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MIN_PLACEHOLDER'
              )}
            />

            <span className='center-xs padding-1x'>–</span>

            <NumberInput
              allowDecimals={false}
              className='-sm-inline vf-text-center'
              value={get(filters, MAX_EXPECTED_ENGAGEMENTS, '')}
              onChange={value => onChange(MAX_EXPECTED_ENGAGEMENTS, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MAX_PLACEHOLDER'
              )}
            />
          </div>

          <div className='vf-font-normal margin-top-2x vf-text-gray-dark'>
            {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_ENGAGEMENT_RATE_LABEL')}
          </div>

          <div className='flex'>
            <NumberInput
              className='-sm-inline vf-text-center'
              value={get(filters, MIN_ENGAGEMENT_RATE, '')}
              onChange={value => onChange(MIN_ENGAGEMENT_RATE, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MIN_PLACEHOLDER'
              )}
            />

            <span className='center-xs'>%</span>

            <span className='center-xs padding-1x'>–</span>

            <NumberInput
              className='-sm-inline vf-text-center'
              value={get(filters, MAX_ENGAGEMENT_RATE, '')}
              onChange={value => onChange(MAX_ENGAGEMENT_RATE, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MAX_PLACEHOLDER'
              )}
            />

            <span className='center-xs'>%</span>
          </div>
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_COST')}
          keepOpen={
            !!(
              get(filters, MIN_SUGGESTED_POST_PRICE) ||
              get(filters, MAX_SUGGESTED_POST_PRICE) ||
              get(filters, MIN_CPE) ||
              get(filters, MAX_CPE) ||
              get(filters, MIN_CPM) ||
              get(filters, MAX_CPM)
            )
          }
        >
          <div className='vf-font-normal vf-text-gray-dark'>
            {i18n.get(
              'ADV_INFLUENCERS_SEARCH_FILTER_SUGGESTED_POST_PRICE_LABEL'
            )}
          </div>

          <div className='flex'>
            <NumberInput
              className='-sm-inline vf-text-center'
              value={get(filters, MIN_SUGGESTED_POST_PRICE, '')}
              onChange={value => onChange(MIN_SUGGESTED_POST_PRICE, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MIN_PLACEHOLDER'
              )}
            />

            <span className='center-xs padding-1x'>–</span>

            <NumberInput
              className='-sm-inline vf-text-center'
              value={get(filters, MAX_SUGGESTED_POST_PRICE, '')}
              onChange={value => onChange(MAX_SUGGESTED_POST_PRICE, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MAX_PLACEHOLDER'
              )}
            />
          </div>

          <div className='vf-font-normal margin-top-2x vf-text-gray-dark'>
            {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_CPE_LABEL')}
          </div>

          <div className='flex'>
            <NumberInput
              className='-sm-inline vf-text-center'
              value={get(filters, MIN_CPE, '')}
              onChange={value => onChange(MIN_CPE, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MIN_PLACEHOLDER'
              )}
            />

            <span className='center-xs padding-1x'>–</span>

            <NumberInput
              className='-sm-inline vf-text-center'
              value={get(filters, MAX_CPE, '')}
              onChange={value => onChange(MAX_CPE, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MAX_PLACEHOLDER'
              )}
            />
          </div>

          <div className='vf-font-normal margin-top-2x vf-text-gray-dark'>
            {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_CPM_LABEL')}
          </div>

          <div className='flex'>
            <NumberInput
              className='-sm-inline vf-text-center'
              value={get(filters, MIN_CPM, '')}
              onChange={value => onChange(MIN_CPM, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MIN_PLACEHOLDER'
              )}
            />

            <span className='center-xs padding-1x'>–</span>

            <NumberInput
              className='-sm-inline vf-text-center'
              value={get(filters, MAX_CPM, '')}
              onChange={value => onChange(MAX_CPM, value)}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MAX_PLACEHOLDER'
              )}
            />
          </div>
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_TYPE')}
          keepOpen={!!get(filters, ACCOUNT_TYPE)}
        >
          <Pulse
            show={pulseAccountType}
            onPulseFinished={() => this.setState({ pulseAccountType: false })}
          >
            <InfluencerTypeSelect
              accountTypeValue={get(filters, ACCOUNT_TYPE)}
              onAccountTypeChange={e => onChange(ACCOUNT_TYPE, e)}
              accountSubtypeValues={get(filters, ACCOUNT_SUB_TYPE)}
              onAccountSubtypesChange={e => onChange(ACCOUNT_SUB_TYPE, e)}
              onChange={onChange}
            />
          </Pulse>
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_AGE_AND_GENDER')}
          keepOpen={
            !!(
              get(filters, MIN_AGE) ||
              get(filters, MAX_AGE) ||
              get(filters, GENDER)
            )
          }
        >
          <div className='flex vf-font-normal margin-top-2x vf-text-gray-dark'>
            {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_AGE_LABEL')}
            <IconTooltip
              message={i18n.get('ADV_INFLUENCERS_SEARCH_PERSONAL_TOOL_TIP')}
              icon='icon-info'
            />
          </div>

          <div className='flex'>
            <NumberInput
              allowDecimals={false}
              className='-sm-inline vf-text-center'
              value={get(filters, MIN_AGE, '')}
              onChange={value => onChange(MIN_AGE, value)}
              onFocus={this.onPersonalInputFocus}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MIN_PLACEHOLDER'
              )}
            />

            <span className='center-xs padding-1x'>–</span>

            <NumberInput
              allowDecimals={false}
              className='-sm-inline vf-text-center'
              value={get(filters, MAX_AGE, '')}
              onChange={value => onChange(MAX_AGE, value)}
              onFocus={this.onPersonalInputFocus}
              placeholder={i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_RANGE_MAX_PLACEHOLDER'
              )}
            />
          </div>

          <div className='flex vf-font-normal margin-top-2x vf-text-gray-dark'>
            {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_GENDER_LABEL')}
            <IconTooltip
              message={i18n.get('ADV_INFLUENCERS_SEARCH_PERSONAL_TOOL_TIP')}
              icon='icon-info'
            />
          </div>

          <Select
            title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_GENDER_DESCRIPTION')}
            options={genderOptions}
            value={get(filters, GENDER)}
            onChange={value => onChange(GENDER, value)}
            onFocus={this.onPersonalInputFocus}
            buttonClassname='flex width100 flex-justify-space-between'
            className='width100'
            selectClassname='width100'
          />
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_LOCATION_LABEL')}
          keepOpen={
            !!(
              selectedLocations.country.length > 0 ||
              selectedLocations.region.length > 0 ||
              selectedLocations.city.length > 0
            )
          }
        >
          <LocationSelect
            showTitle={false}
            placeholder={i18n.get(
              'ADV_INFLUENCERS_SEARCH_FILTER_LOCATION_PLACEHOLDER'
            )}
            onChange={onChange}
            selectedLocations={selectedLocations}
          />
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_RELIABILITY')}
          keepOpen={!!get(filters, MIN_RATING)}
        >
          {this.getRatingsFilterComponent()}
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_ACCOUNTS')}
        >
          {showFavoriteOption && (
            <Checkbox
              checked={get(filters, FAVORITE)}
              onChange={this.onToggleFavorite}
            >
              {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_SHOW_FAVORITE_CHECKBOX')}
            </Checkbox>
          )}

          {showBlockedOption && (
            <Checkbox
              checked={get(filters, SHOW_BLOCKED)}
              className='margin-top-Hx'
              onChange={this.onToggleBlocked}
            >
              {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_SHOW_HIDDEN_CHECKBOX')}
            </Checkbox>
          )}

          <Checkbox
            checked={get(filters, VERIFIED)}
            className='margin-top-Hx'
            onChange={this.onToggleVerified}
          >
            <div className='flex'>
              {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_SHOW_VERIFIED_CHECKBOX')}
              <VerificationBadge size='sm' className='margin-left-Hx' />
            </div>
          </Checkbox>

          {userVerificationEnabled() && (
            <Checkbox
              checked={get(filters, VALIDATED)}
              className='margin-top-Hx'
              onChange={this.onToggleValidated}
            >
              <div className='flex'>
                {i18n.get(
                  'ADV_INFLUENCERS_SEARCH_FILTER_SHOW_VALIDATED_CHECKBOX'
                )}
                <div className={styles.iconContainer}>
                  <IconAccountVerification />
                </div>
              </div>
            </Checkbox>
          )}
        </FilterWrapper>
      </InfluencerFilterHeader>
    );
  }
}

InfluencerFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  availableSocialNetworks: PropTypes.array.isRequired,
  showFavoriteOption: PropTypes.bool,
  showBlockedOption: PropTypes.bool,
  onRequestToggleFilters: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired
};

InfluencerFilters.defaultProps = {
  showFavoriteOption: false,
  showBlockedOption: false
};

export default InfluencerFilters;
