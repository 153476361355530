import * as i18n from 'i18n';

const validateNetworkLink = (options, field) => {
  const validLink = new RegExp(`(?:(?:https)://)?(?:www.)?(?:${options})/.*`);
  return validLink.test(field.trim()) ? null : i18n.get('INVALID_URL');
};

const validateUrlSocialNetworks = (networkName, field) => {
  const socialNetwork = {
    tiktok: validateNetworkLink('tiktok.com|vm.tiktok', field),
    instagram: validateNetworkLink(
      'instagram.com|instagr.am|instagr.com',
      field
    ),
    youtube: validateNetworkLink('youtube.com|youtu.be', field),
    facebook: validateNetworkLink('facebook.com', field)
  };

  return socialNetwork[networkName];
};
export default validateUrlSocialNetworks;
