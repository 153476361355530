"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const SelectListItem_1 = require("./SelectListItem");
const SelectWrapper_1 = require("../SelectWrapper/SelectWrapper");
class Select extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isOpen: false
        };
        this.handleChangeState = isOpen => {
            this.setState({ isOpen });
        };
        this.handleItemClick = key => {
            const { onChange } = this.props;
            onChange(key);
            this.setState({ isOpen: false });
        };
        this.buildOptions = () => {
            const { options, selectedKey } = this.props;
            return lodash_1.chain(options)
                .omit(selectedKey)
                .map((value, key) => (React.createElement(SelectListItem_1.default, { key: key, "data-uitest": `option-${key}`, onClick: () => this.handleItemClick(key) }, options[key])))
                .value();
        };
    }
    render() {
        const { isOpen } = this.state;
        const _a = this.props, { selectedKey, options, onChange, title } = _a, others = __rest(_a, ["selectedKey", "options", "onChange", "title"]);
        const selectedValue = options[selectedKey] || title;
        return (React.createElement(SelectWrapper_1.default, Object.assign({ isOpen: isOpen, selectedKey: selectedKey, selectedValue: selectedValue, onToggle: this.handleChangeState }, others), this.buildOptions()));
    }
}
exports.default = Select;
