
import {organizations} from '../../../services/voxfeed';
import {uniqueId} from 'lodash';
import {buildAPIRequest} from '../helpers';
import {updateData, replaceData} from '../entities';
import download from 'downloadjs';

export const createOrganization = params => buildAPIRequest({
  params,
  id: 'createOrganization',
  method: organizations.create
});


export const getUserOrganizations = params => buildAPIRequest({
  params,
  id: `getUserOrganizations_${params.id}`,
  method: organizations.getUserOrganizations,
  replace: true
});


export const updateOrganization = params => buildAPIRequest({
  params,
  id: `updateOrganization_${params.id}`,
  method: organizations.update
});


export const leaveOrganization = params => buildAPIRequest({
  params,
  id: `leaveOrganization_${params.id}`,
  method: organizations.leave
});


export const deleteOrganization = params => buildAPIRequest({
  params,
  id: `deleteOrganization_${params.id}`,
  method: organizations.archive
});


export const getOrganizationUsers = (params, replace = false) => buildAPIRequest({
  params,
  id: `getOrganizationUsers_${params.id}`,
  method: organizations.getUsers,
  replace,
  callback: ({dispatch, data}) => {
    const records = data.data.map(u => (!u.id ? {...u, id: uniqueId('pending')} : u));
    const parsedData = {...data, data: records};
    dispatch(updateData(parsedData));
  }
});


export const removeOrganizationUser = params => buildAPIRequest({
  params,
  id: `removeOrganizationUser_${params.userId}`,
  method: organizations.removeUser
});


export const changeRole = params => buildAPIRequest({
  params,
  id: `changeOrganizationRole_${params.userId}`,
  method: organizations.changeRole
});


export const inviteUser = params => buildAPIRequest({
  params,
  id: `inviteUser_${params.id}`,
  method: organizations.inviteUser
});


export const cancelInvite = params => buildAPIRequest({
  params,
  id: `cancelInvite_${params.id}`,
  method: organizations.cancelInvite
});


export const acceptInvite = params => buildAPIRequest({
  params,
  id: `acceptInvite_${params.id}`,
  method: organizations.acceptInvite
});


export const rejectInvite = params => buildAPIRequest({
  params,
  id: `rejectInvite_${params.id}`,
  method: organizations.rejectInvite
});

export const getCosts = ({replace, ...params}) => buildAPIRequest({
  params,
  replace,
  id: 'getOrganizationCost',
  method: organizations.getCosts,
  callback: ({dispatch, data}) => {
    const brandData = data.groups.map(gr => ({
      ...gr,
      id: gr.brandId,
      months: gr.groups.reduce((p, gData) => ({...p, [gData.month]: gData}), {})
    }));
    const action = replace ? replaceData : updateData;

    dispatch(action({
      modelName: 'OrganizationCost',
      id: data.organizationId,
      data: {
        groups: data.summary,
        summary: data.total
      }
    }));

    dispatch(action({
      modelName: 'BrandCost',
      data: brandData
    }));
  }
});

export const generateReport = params => buildAPIRequest({
  params,
  id: 'generateReport',
  method: organizations.generateReport,
  callback: ({response}) => {
    const blob = new Blob([new Uint8Array(response.data)]);
    download(blob, `cost-report-${params.id}.${params.fileType}`);
  }
});
