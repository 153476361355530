import React from 'react';
import PropTypes from 'prop-types';

export default function NavigationSideMenu({ title, children }) {
  return (
    <div className='vf-nav-sidemenu-container'>
      <h2 className='vf-nav-sidemenu-title padding-horizontal-2x dont-show-in-small'>
        {title}
      </h2>
      <div className='margin-top-1x show-in-small' />
      <div className='vf-nav-sidemenu'>{children}</div>
    </div>
  );
}

NavigationSideMenu.propTypes = {
  title: PropTypes.string,
  children: PropTypes.array
};

NavigationSideMenu.defaultProps = {
  title: ''
};
