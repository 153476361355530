"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const app_1 = require("config/app");
const components_1 = require("modules/legacy/js/lib/components");
const styles = require("./AdvertisersPermissionsSelector.less");
const DEFAULT_LAYOUT_FOR_EMPTY_PERMISSIONS = 'admin';
const DEFAULT_LAYOUT_FOR_NON_EMPTY_PERMISSIONS = 'custom';
const AdvertisersPermissionsSelector = props => {
    const [selectedLayout, setSelectedLayout] = React.useState(null);
    const [selectedPermissions, setSelectedPermissions] = React.useState({});
    const onLayoutSelected = (selectedLayout) => {
        const { onChange } = props;
        const selectedPermissions = getPermissionsByLayout(selectedLayout);
        console.log(selectedLayout, selectedPermissions);
        if (!selectedPermissions)
            return;
        setSelectedLayout(selectedLayout);
        setSelectedPermissions(selectedPermissions);
        onChange(selectedPermissions);
    };
    const onPermissionToggle = (permission) => {
        const { onChange } = props;
        const previousValue = !!selectedPermissions[permission];
        const newPermissions = Object.assign(Object.assign({}, selectedPermissions), { [permission]: !previousValue });
        const permissionsWithoutFalsyValues = lodash_1.omit(newPermissions, p => !p);
        setSelectedLayout(getLayoutByPermissions(permissionsWithoutFalsyValues));
        setSelectedPermissions(permissionsWithoutFalsyValues);
        onChange(permissionsWithoutFalsyValues);
    };
    const getPermissionsByLayout = (layoutName) => {
        const { advertiserPermissions } = app_1.getStatics();
        if (layoutName === DEFAULT_LAYOUT_FOR_NON_EMPTY_PERMISSIONS)
            return;
        return advertiserPermissions.layouts[layoutName].permissions;
    };
    const getLayoutByPermissions = (permissions) => {
        const { advertiserPermissions } = app_1.getStatics();
        const layoutName = Object.keys(advertiserPermissions.layouts).reduce((name, currentLayoutName) => {
            const currentLayoutPermissions = getPermissionsByLayout(currentLayoutName);
            if (lodash_1.isEqual(permissions, currentLayoutPermissions))
                return currentLayoutName;
            return name;
        }, DEFAULT_LAYOUT_FOR_NON_EMPTY_PERMISSIONS);
        return layoutName;
    };
    React.useEffect(() => {
        const { onChange, selectedPermissions, selectedLayout = DEFAULT_LAYOUT_FOR_EMPTY_PERMISSIONS, } = props;
        const permissions = selectedPermissions ||
            getPermissionsByLayout(DEFAULT_LAYOUT_FOR_EMPTY_PERMISSIONS);
        setSelectedLayout(selectedPermissions
            ? getLayoutByPermissions(selectedPermissions)
            : selectedLayout);
        setSelectedPermissions(permissions);
        onChange(permissions);
    }, []);
    return (React.createElement("div", null,
        React.createElement(LayoutSelector, { selectedLayout: selectedLayout, onChange: onLayoutSelected }),
        React.createElement("div", { className: 'vf-scrolly', style: { height: '45vh', padding: '0 2rem 0 0' } },
            React.createElement(Options, { selectedPermissions: selectedPermissions, onChange: onPermissionToggle })),
        React.createElement("div", { className: classnames_1.default('padding-Hx margin-top-1x', styles.disclaimer) }, i18n.get('ADV_BRANDS_USER_PERMISSIONS_ALL_USERS_CAN_SEE_STATS'))));
};
const LayoutSelector = ({ selectedLayout, onChange }) => {
    const { advertiserPermissions } = app_1.getStatics();
    const { layouts } = advertiserPermissions;
    const options = Object.keys(layouts).reduce((result, key) => {
        result[key] = layouts[key].name;
        return result;
    }, { custom: i18n.get('ADV_BRANDS_USER_PERMISSIONS_CUSTOM_LABEL') });
    return (React.createElement("div", { className: 'margin-bottom-1x' },
        React.createElement("div", { className: 'flex between-xs' },
            React.createElement("h4", { className: 'vf-font-bold width-auto' }, i18n.get('ADV_BRANDS_USER_PERMISSIONS_PERMISSIONS_LABEL')),
            React.createElement(ui_library_1.Select, { value: selectedLayout, onChange: ev => onChange(ev.target.value), buttonSize: 'sm', title: i18n.get('ADV_BRANDS_USER_PERMISSIONS_PERMISSIONS_TYPE_LABEL'), className: 'margin-left-Hx', options: options }))));
};
const Options = ({ selectedPermissions, onChange }) => {
    const { advertiserPermissions } = app_1.getStatics();
    const { permissions } = advertiserPermissions;
    const options = Object.keys(permissions).map(key => {
        return (React.createElement("div", { className: 'col margin-bottom-1x', key: key },
            React.createElement("div", { className: 'flex' },
                React.createElement("h5", null, lodash_1.get(permissions, `${key}.name`)),
                React.createElement(components_1.Toggle, { className: 'margin-Hx', value: !!selectedPermissions[key], onClick: () => onChange(key) })),
            React.createElement("div", { className: 'vf-text-gray-dark width100' }, lodash_1.get(permissions, `${key}.description`))));
    });
    return React.createElement("div", null, options);
};
exports.default = AdvertisersPermissionsSelector;
