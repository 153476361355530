import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import i18n from 'i18n';

export const NewCampaignButton = ({ onNext, onBack }) => (
  <div className='col center-xs'>
    <h4 className='vf-font-thin'>
      {i18n.get('ADV_TOURS_BRAND_BACK_NEW_CAMPAIGN_BUTTON_DESCRIPTION')}
    </h4>

    <a
      href={i18n.get('ADV_TOURS_BRAND_INFO_URL')}
      target='_blank'
      className='margin-top-Hx'
    >
      {i18n.get('ADV_TOURS_BRAND_MORE_INFO')}
    </a>

    <div className='flex center-xs margin-vertical-1x'>
      <Button size='sm' className='margin-right-Hx' onClick={onBack}>
        {i18n.get('ADV_TOURS_BRAND_BACK_BUTTON')}
      </Button>

      <Button size='sm' color='success' onClick={onNext}>
        {i18n.get('ADV_TOURS_BRAND_FINISH_BUTTON')}
      </Button>
    </div>
  </div>
);

NewCampaignButton.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func
};
