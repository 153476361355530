"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const appActions = require("redux/modules/app");
const PartnerFooterUI_1 = require("./PartnerFooterUI/PartnerFooterUI");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const selectors_1 = require("modules/shared/selectors");
class PartnerFooter extends React.Component {
    constructor() {
        super(...arguments);
        this.transformAvailableLanguages = () => {
            const { availableLanguages } = this.props;
            return availableLanguages.reduce((p, c) => (Object.assign(Object.assign({}, p), { [c.code]: c.name })), {});
        };
        this.handleLangChange = code => {
            const { changeLanguage } = this.props;
            changeLanguage(code);
        };
    }
    render() {
        const { currentLanguage } = this.props;
        return (React.createElement(PartnerFooterUI_1.default, { currentLanguage: currentLanguage, availableLanguages: this.transformAvailableLanguages(), onLangChange: this.handleLangChange }));
    }
}
PartnerFooter.defaultProps = {
    availableLanguages: []
};
const mapStateToProps = state => ({
    currentLanguage: selectors_1.getAppInfo(state, 'currentLanguage'),
    availableLanguages: selectors_1.getAppInfo(state, 'availableLanguages')
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    changeLanguage: appActions.changeLanguage
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(PartnerFooter);
