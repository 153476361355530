"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const brands_1 = require("redux/modules/brands");
const app_1 = require("redux/modules/app");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const components_1 = require("modules/brands/advertiser/components");
const REQUEST_ID = 'setBrandUserPermissions';
const UserPermissionDialog = props => {
    const [selectedPermissions, setSelectedPermissions] = React.useState(lodash_1.get(props, 'brandUser.accessLevel'));
    const { hideModal, request, isOpen, onClose } = props;
    const handleSuccess = () => {
        const { showAlert, onSuccess } = props;
        if (onSuccess)
            onSuccess();
        if (onClose)
            onClose();
        if (!onClose)
            hideModal();
        showAlert({
            type: 'success',
            message: i18n.get('ADV_BRANDS_USER_PERMISSIONS_DIALOG_SUCCESS_ALERT_MESSAGE')
        });
    };
    const handleFail = () => {
        const { showAlert } = props;
        showAlert({
            type: 'error',
            message: i18n.get('ADV_BRANDS_USER_PERMISSIONS_DIALOG_ERROR_ALERT_MESSAGE')
        });
    };
    const saveChanges = () => {
        const { brandUser, brand, setUserPermissions } = props;
        setUserPermissions({
            permissions: selectedPermissions,
            userId: lodash_1.get(brandUser, 'userId'),
            brandId: lodash_1.get(brand, 'id')
        });
    };
    React.useEffect(() => {
        props.resetRequest(REQUEST_ID);
    }, []);
    React.useEffect(() => {
        if (request.status === 'success')
            handleSuccess();
        if (request.status === 'fail')
            handleFail();
    }, [request]);
    const isLoading = request.status === 'loading';
    const content = (React.createElement("div", { className: 'padding-2x' },
        React.createElement(components_1.default, { selectedPermissions: selectedPermissions, onChange: newPermissions => setSelectedPermissions(newPermissions) }),
        React.createElement("div", { className: 'flex end-xs width100 margin-top-1x' },
            React.createElement(ui_library_1.Button, { className: 'margin-right-Hx', onClick: hideModal }, i18n.get('ADV_BRANDS_USER_CLOSE')),
            React.createElement(ui_library_1.Button, { color: 'primary', onClick: saveChanges, disabled: isLoading }, isLoading
                ? i18n.get('ADV_BRANDS_USER_PERMISSIONS_DIALOG_SAVING')
                : i18n.get('ADV_BRANDS_USER_PERMISSIONS_DIALOG_SAVE')))));
    return (React.createElement(CustomDialog_1.default, { size: 'sm', header: i18n.get('ADV_BRANDS_USER_CARD_ROW_EDIT_PERMISSIONS'), content: content, isOpen: isOpen, onClose: onClose || hideModal }));
};
const mapStateToProps = ({ app }) => {
    const { requests } = app;
    const request = requests[REQUEST_ID] || {};
    return { request };
};
const mapDispatchToProps = dispatch => {
    return redux_1.bindActionCreators({
        setUserPermissions: brands_1.setUserPermissions,
        hideModal: app_1.hideModal,
        resetRequest: app_1.resetRequest,
        sendRequest: app_1.sendRequest,
        showAlert: app_1.showAlert
    }, dispatch);
};
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(UserPermissionDialog);
