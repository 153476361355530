import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Tab.less';

const TAB_BASE_CLASSNAMES = `padding-horizontal-2x padding-vertical-Hx margin-right-1x flex ${styles.container}`;
const ACTIVE_TAB_CLASSNAMES = `active vf-font-bold vf-text-primary', ${styles.active}`;

export default function Tab(props) {
  const {
    redirectTo,
    text,
    isActive,
    badgeCount,
    onClick,
    inactiveClassName,
    activeCustomClass,
    customComponent,
    ...other
  } = props;

  if (!!redirectTo) {
    return (
      <RedirectTab
        redirectTo={redirectTo}
        text={text}
        badgeCount={badgeCount}
        inactiveClassName={inactiveClassName}
        {...other}
      />
    );
  }

  const className = classNames(
    TAB_BASE_CLASSNAMES,
    other.className,
    {
      [ACTIVE_TAB_CLASSNAMES]: isActive,
      [activeCustomClass]: isActive,
      [inactiveClassName]: !isActive
    },
    isActive ? activeCustomClass : inactiveClassName
  );

  return (
    <div className={className} onClick={onClick} {...other}>
      {text || customComponent}
      {!!badgeCount && <div className={styles.badge}>{badgeCount}</div>}
    </div>
  );
}

Tab.propTypes = {
  text: PropTypes.any.isRequired,
  redirectTo: PropTypes.string,
  isActive: PropTypes.bool,
  badgeCount: PropTypes.number,
  inactiveClassName: PropTypes.string,
  onClick: PropTypes.func,
  activeCustomClass: PropTypes.string,
  customComponent: PropTypes.object
};

Tab.defaultProps = {
  isActive: false,
  onClick: () => {},
  activeCustomClass: '',
  inactiveClassName: '',
  customComponent: {},
  badgeCount: 0,
  redirectTo: ''
};

function RedirectTab({
  redirectTo,
  badgeCount,
  text,
  inactiveClassName,
  badgeStyle,
  ...other
}) {
  const className = classNames(TAB_BASE_CLASSNAMES, inactiveClassName);
  const badgeClassName = classNames(
    styles.badge,
    badgeStyle === 'gray' ? styles['gray-badge'] : ''
  );

  return (
    <NavLink
      className={className}
      activeClassName={ACTIVE_TAB_CLASSNAMES}
      to={redirectTo}
      {...other}
    >
      {text}
      {!!badgeCount && <div className={badgeClassName}>{badgeCount}</div>}
    </NavLink>
  );
}

RedirectTab.propTypes = {
  redirectTo: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
  badgeCount: PropTypes.number,
  inactiveClassName: PropTypes.string
};

RedirectTab.defaultProps = {
  redirectTo: '',
  text: '',
  inactiveClassName: ''
};
