import Reflux from 'reflux';
import moment from 'moment';
import _ from 'lodash';
import store from 'store2';

const Actions = {
  ConfigStore: require('../actions/configStore')
};

/**
 * Default data schema.
 * @const DEFAULT_DATA <private>
 */
const DEFAULT_DATA = {
  Auth: '',
  AuthenticatedAccountId: null,
  ActiveAccountId: null,
  ActiveUserId: null,
  ActiveSocialNetwork: null,
  ActiveAdgroups: [],
  FirstDate: moment().subtract(30, 'day').toISOString(),
  EndDate: moment().toISOString(),
  activeRole: null
};

const SESSION_KEY_NAME = 'Session';
const INVITATION_LINK = 'invitationLink';
const VERSION_CAMPAING = 'VersionCampaing';

module.exports = Reflux.createStore({
  init() {
    this.listenTo(Actions.ConfigStore.update, this.onUpdate);
    this.listenTo(Actions.ConfigStore.fastUpdate, this.onFastUpdate);
    this.listenTo(Actions.ConfigStore.destroy, this.onDestroy);

    if (typeof window !== 'undefined') window.config = this._data;
  },

  _data: JSON.parse(JSON.stringify(DEFAULT_DATA)),

  getAll() {
    return this._data;
  },

  onUpdate(newData) {
    _.merge(this._data, newData);

    if (newData.Auth) {
      store.set(SESSION_KEY_NAME, newData.Auth);
    }

    this.trigger(this._data);
  },

  onFastUpdate(newData, callback) {
    _.assign(this._data, newData);

    if (callback) callback(this._data);
    this.trigger(this._data);

    if (
      _.includes(_.keys(newData), 'FirstDate') ||
      _.includes(_.keys(newData), 'EndDate')
    ) {
      Actions.ConfigStore.changeDate({
        FirstDate: this._data.FirstDate,
        EndDate: this._data.EndDate
      });
    }
  },

  onDestroy() {
    Object.assign(this._data, JSON.parse(JSON.stringify(DEFAULT_DATA)));
    store.remove(SESSION_KEY_NAME);
    store.remove(VERSION_CAMPAING);
    store.remove(INVITATION_LINK);
    this.trigger(this._data);
  },

  getToken() {
    return this.getAll().Auth;
  },

  isAuthenticated() {
    return !!this.getToken();
  },

  getFromStorage() {
    return store.get(SESSION_KEY_NAME);
  }
});
