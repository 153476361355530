"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Auth_1 = require("../routes/Auth");
const react_redux_1 = require("react-redux");
const selectors_1 = require("modules/shared/selectors");
const Auth = props => (React.createElement(Auth_1.default, Object.assign({}, props)));
const mapStateToProps = state => ({
    currentUser: selectors_1.getCurrentUser(state)
});
exports.default = react_redux_1.connect(mapStateToProps)(Auth);
