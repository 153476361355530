import React, { Component } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import i18n from 'i18n';
import Dotdotdot from 'react-dotdotdot';

import { connect } from 'react-redux';

import PhoneVerificationWidget from 'modules/accounts/shared/containers/PhoneVerificationWidget/PhoneVerificationWidget';
import EmailVerificationReminder from 'modules/accounts/shared/containers/EmailVerificationReminder';
import VerifiedState from './components/VerifiedState';
import { getDefaultTitle } from 'config/app';

import {
  iconVerificationRejectedImg,
  iconVerificationRequestedImg,
  iconVerifyImg
} from 'assets/images';

import styles from './VerificationLabel.less';

function mapStateToProps({ shared }) {
  const { user } = shared;

  return { user };
}

class VerificationLabel extends Component {
  getContainerClasses = () => {
    const classes = classNames(
      'flex col-xs-12 height100 col-md-3 padding-1x',
      styles.divider
    );

    return classes;
  };

  getParamsByProfileVerificationStatus = () => {
    const status = this.getUserVerificationStatus();
    const params = {
      'requested': {
        title: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_REQUESTED_TITLE'
        ),
        message: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_REQUESTED_MESSAGE'
        ),
        button: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_REQUESTED_BUTTON'
        ),
        icon: iconVerificationRequestedImg
      },
      'rejected': {
        title: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_REJECTED_TITLE'
        ),
        message: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_REJECTED_MESSAGE'
        ),
        button: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_REJECTED_BUTTON'
        ),
        icon: iconVerificationRejectedImg
      },
      'default': {
        title: '',
        message: '',
        button: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_DEFAULT_BUTTON'
        ),
        icon: iconVerifyImg
      }
    };

    return params[status] || params.default;
  };

  getParamsByUserVerificationStatus = () => {
    const hasCompletedAllVerifications =
      this.hasUserCompletedAllVerifications();
    const params = {
      uncomplete: {
        title: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_DEFAULT_TITLE'
        ),
        message: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_DEFAULT_MESSAGE',
          { companyName: getDefaultTitle() }
        )
      },
      completed: {
        title: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_ACCEPTED_TITLE'
        ),
        message: i18n.get(
          'INF_SETTINGS_ACCOUNT_VERIFICATION_LABEL_ACCEPTED_MESSAGE'
        )
      }
    };

    return hasCompletedAllVerifications ? params.completed : params.uncomplete;
  };

  render = () => {
    const params = this.getParamsByProfileVerificationStatus();

    return (
      <div
        className={styles.verify}
        style={{
          padding: '2rem',
          height: '26rem'
        }}
      >
        {this.renderAccountVerificationBrief(params)}
        {this.renderEmailVerification(params)}
        {this.renderPhoneVerification(params)}
      </div>
    );
  };

  getUserVerificationStatus = () =>
    get(this.props, 'user.verification.status', '');

  renderAccountVerificationBrief = () => {
    const containerClassNames = classNames(
      'top-xs',
      this.getContainerClasses()
    );
    const titleClassNames = classNames(
      'vf-font-bold vf-text-primary padding-bottom-1x'
    );
    const params = this.getParamsByUserVerificationStatus();

    return (
      <div className={containerClassNames}>
        <div className='col'>
          <h2 className={titleClassNames}>{params.title}</h2>
          {params.message}
        </div>
      </div>
    );
  };

  isCurrentEmailVerified = () => {
    const email = get(this.props, 'user.email', '');
    const verifiedEmails = get(this.props, 'user.verifiedEmails', []);
    return !!email && verifiedEmails.includes(email);
  };

  isCurrentPhoneVerified = () => {
    const phone = get(this.props, 'user.phone', '');
    const verifiedPhones = get(this.props, 'user.verifiedPhones', []);
    return !!phone && verifiedPhones.includes(phone);
  };

  hasUserCompletedAllVerifications = () =>
    this.isCurrentEmailVerified() && this.isCurrentPhoneVerified();

  renderEmailVerification = () => {
    const isCurrentEmailVerified = this.isCurrentEmailVerified();
    const containerClassNames = classNames(
      'col top-xs',
      this.getContainerClasses()
    );
    const componentToRender = isCurrentEmailVerified ? (
      <VerifiedState className='flex width100 padding-top-1x center-xs' />
    ) : (
      <EmailVerificationReminder className='flex width100 center-xs margin-top-1x padding-horizontal-1x' />
    );

    return (
      <div className={containerClassNames}>
        <span className='flex width100 center-xs padding-bottom-1x'>
          {i18n.get('INF_SETTINGS_EMAIL_VERIFICATION_LABEL_TITLE')}
        </span>
        {componentToRender}
      </div>
    );
  };

  renderPhoneVerification = () => {
    const containerClassNames = classNames('col', this.getContainerClasses());
    const isCurrentPhoneVerified = this.isCurrentPhoneVerified();
    const componentToRender = isCurrentPhoneVerified ? (
      <VerifiedState className='flex width100 padding-top-1x center-xs' />
    ) : (
      <PhoneVerificationWidget className='flex width100 center-xs middle-xs padding-horizontal-1x' />
    );

    return (
      <div className={containerClassNames}>
        <span className='padding-bottom-1x'>
          {i18n.get('INF_SETTINGS_PHONE_VERIFICATION_LABEL_TITLE')}
        </span>
        {componentToRender}
      </div>
    );
  };

  getAccountVerificationComponent = params => {
    const isUserVerified = this.isUserVerificationApproved();
    const actionComponent = this.getAccountVerificationActionComponent();
    const verification = get(this.props, 'user.verification', {});
    const { status } = verification;
    const feedback = get(verification, 'feedback', params.message);
    const messageToRender = status === 'rejected' ? feedback : params.message;
    const titleClassNames = classNames(
      'vf-font-bold center-xs padding-bottom-Hx',
      {
        'vf-text-warning': status === 'requested',
        'vf-text-danger': status === 'rejected'
      }
    );

    if (isUserVerified) {
      return (
        <VerifiedState className='flex width100 padding-top-1x center-xs' />
      );
    }

    return (
      <div className='col'>
        <h5 className={titleClassNames}>{params.title}</h5>
        <div className='no-padding no-margin'>
          <Dotdotdot clamp={3}>{messageToRender}</Dotdotdot>
        </div>
        {actionComponent}
      </div>
    );
  };
}

export default connect(mapStateToProps, null)(VerificationLabel);
