import {get, chain} from 'lodash';
import i18n from 'i18n';
import {socialAccounts} from '../../../services/voxfeed';
import {
  sendRequest,
  sendRequestFail,
  sendRequestSuccess
} from './../app/index';
import {updateData, replaceData} from '../entities';
import {displayAlert} from 'modules/legacy/js/actions/app';
import {handleRequestFail, buildAPIRequest} from '../helpers';

const FETCH_SCORE_DETAILS_SUCCESS = 'voxfeed/socialAccounts/FETCH_SCORE_DETAILS_SUCCESS';
const FETCH_CONFIGURATION = 'voxfeed/socialAccounts/FETCH_CONFIGURATION';
const UPDATE_FOLLOWERS_REQUEST = 'voxfeed/users/UPDATE_FOLLOWERS_REQUEST';

const initialState = {scoreDetails: {}, configuration: {interests: {}}};

export default function reducer(state = initialState, {type, payload}) {
  switch (type) {
    case FETCH_SCORE_DETAILS_SUCCESS:
      return buildStateAfterSuccessfulDetailsFetch(state, payload);
    case FETCH_CONFIGURATION:
      const {configuration, interests} = payload;
      return {
        ...state,
        configuration: Object.assign(configuration, {interests})
      };
    default:
      return state;
  }
}

function buildStateAfterSuccessfulDetailsFetch(state, payload) {
  const {socialAccountId: id, replaceContent = true} = payload;
  const details = replaceContent ? payload.details : [...state.scoreDetails[id], ...payload.details];

  return {
    ...state,
    scoreDetails: {...state.scoreDetails, ...{[id]: details}}
  };
}

export function fetchSocialAccountScoreDetails({socialAccountId, skip = 0, ...args}) {
  const REQUEST_ID = 'fetchSocialAccountScoreDetails';

  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    return socialAccounts.fetchScoreDetailsBySocialAccountId({skip, id: socialAccountId, ...args})
      .then((response) => {
        dispatch(fetchScoreDetailsSuccess({
          socialAccountId,
          details: response.data,
          replaceContent: !skip
        }));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(res => dispatch(sendRequestFail({
        id: REQUEST_ID,
        response: get(res, 'response', {})
      })));
  };
}

export function getSocialAccountsConfiguration() {
  let configuration;
  return dispatch => socialAccounts.getSocialAccountConfiguration()
    .then((response) => {
      configuration = response || {};
      return socialAccounts.getInterestsList();
    })
    .then((interests) => {
      dispatch(fetchSegmentationConfiguration({
        configuration,
        interests: interests.data
      }));
    });
}

export function fetchScoreDetailsSuccess(params) {
  return {
    type: FETCH_SCORE_DETAILS_SUCCESS,
    payload: params
  };
}

export function setSocialAccountSubtype(socialAccountId, accountSubtype) {
  const REQUEST_ID = 'setSocialAccountSubtype';

  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    return socialAccounts.setSocialAccountSubtype({socialAccountId, accountSubtype})
      .then((response) => {
        dispatch(onFetchSocialAccountSuccess({
          socialAccountId,
          socialAccount: response.data
        }));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function setSocialAccountInterests(socialAccountId, interests) {
  const REQUEST_ID = 'setSocialAccountInterests';

  return (dispatch) => {
    dispatch(sendRequest());
    return socialAccounts.setSocialAccountInterests({socialAccountId, interests})
      .then((response) => {
        dispatch(onFetchSocialAccountSuccess({
          socialAccountId,
          socialAccount: response.data
        }));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function setSocialAccountTags(socialAccountId, tags) {
  const REQUEST_ID = 'setSocialAccountTags';

  return (dispatch) => {
    dispatch(sendRequest());
    return socialAccounts.setSocialAccountTags({socialAccountId, tags})
      .then((response) => {
        dispatch(onFetchSocialAccountSuccess({
          socialAccountId,
          socialAccount: response.data
        }));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function setSocialAccountSensitiveContent(socialAccountId, sensitiveContentDetail) {
  const REQUEST_ID = 'setSocialAccountSensitiveContent';

  return (dispatch) => {
    dispatch(sendRequest());
    return socialAccounts.setSocialAccountSensitiveContent({socialAccountId, sensitiveContentDetail})
      .then((response) => {
        dispatch(onFetchSocialAccountSuccess({
          socialAccountId,
          socialAccount: response.data
        }));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function fetchSegmentationConfiguration({configuration, interests}) {
  return {
    type: FETCH_CONFIGURATION,
    payload: {
      configuration,
      interests
    }
  };
}

function onFetchSocialAccountSuccess({socialAccount}) {
  return (dispatch) => {
    dispatch(updateData({
      data: socialAccount,
      id: socialAccount._id,
      modelName: 'SocialNetworkAccount'
    }));
  };
}

export function unlinkSocialAccount(id) {
  const REQUEST_ID = 'unlinkSocialAccount';

  return (dispatch) => {
    dispatch(sendRequest());
    return socialAccounts.unlinkSocialAccount(id)
      .then((response) => {
        dispatch(updateData(response.data));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function searchTag(tag, canParticipate) {
  const REQUEST_ID = 'searchTag';

  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    return socialAccounts.searchTag(tag, canParticipate)
      .then((res) => {
        dispatch(sendRequestSuccess({
          id: REQUEST_ID,
          response: {data: get(res, 'data.data', {})}
        }));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export const fetchSocialAccounts = (params, replace = true) => buildAPIRequest({
  params,
  id: 'fetchSocialAccounts',
  method: socialAccounts.fetchByIds,
  replace
});

export const fetchSocialAccountsAdmin = (params) => buildAPIRequest({
  id: 'fetchSocialAccountsAdmin',
  method: socialAccounts.fetchSocialAccountsAdmin,
  params,
  replace: true
});

export function searchSocialAccounts(data, replace) {
  const REQUEST_ID = 'searchSocialAccounts';

  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    return socialAccounts.fetchByQuery(data)
      .then((response) => {
        if (replace) dispatch(replaceData(response.data));
        else dispatch(updateData(response.data));
        dispatch(sendRequestSuccess({id: REQUEST_ID, response}));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function fetchSocialAccountStats(id) {
  const REQUEST_ID = `fetchSocialAccountStats_${id}`;

  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    return socialAccounts.fetchStats(id)
      .then((response) => {
        const data = {stats: response.data};
        dispatch(updateData({id, data, modelName: 'SocialNetworkAccount'}));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function fetchSocialAccountsWithInvites(query, replace) {
  const REQUEST_ID = 'fetchSocialAccountsWithInvites';

  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    return socialAccounts.fetchWithInvites(query)
      .then((response) => {
        if (replace) dispatch(replaceData(response.data));
        else dispatch(updateData(response.data));
        dispatch(sendRequestSuccess({id: REQUEST_ID, response}));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function fetchAccountsRelatedToSocialAccount(id) {
  const REQUEST_ID = `fetchAccountsRelatedToSocialAccount_${id}`;

  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    socialAccounts.fetchRelatedAccounts(id)
      .then((response) => {
        const relatedSocialAccountsIds = chain(response)
          .get('data.data', [])
          .map(a => a._id)
          .value();

        const data = {relatedSocialAccountsIds};

        dispatch(updateData(response.data));
        dispatch(updateData({id, data, modelName: 'SocialNetworkAccount'}));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export const fetchSocialAccountPublishedPosts = ({socialAccountId, params}) => buildAPIRequest({
  id: `fetchSocialAccountPublishedPosts_${socialAccountId}`,
  method: socialAccounts.fetchPublishedPosts,
  params: {socialAccountId, params},
  replace: true
});

export function fetchSocialAccountAudienceStats(id) {
  const REQUEST_ID = `fetchSocialAccountAudienceStats_${id}`;

  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    socialAccounts.fetchAudienceStats(id)
      .then((response) => {
        const audienceStats = response.data;
        const data = {audienceStats};

        dispatch(updateData({id, data, modelName: 'SocialNetworkAccount'}));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export const setSocialAccountPostPrice = params => buildAPIRequest({
  params,
  id: 'setSocialAccountPostPrice',
  method: socialAccounts.setSocialAccountPostPrice
});

export function fetchBanDetailsBySocialAccountId(id) {
  const REQUEST_ID = `fetchBanDetailsBySocialAccountId_${id}`;
  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    return socialAccounts.fetchBanDetailsById(id)
      .then((response) => {
        const {banDetails} = response.data.data;
        const data = {banDetails};
        dispatch(updateData({id, data, modelName: 'SocialNetworkAccount'}));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function banSocialAccount(id, data) {
  const REQUEST_ID = `banSocialAccount_${id}`;
  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    return socialAccounts.banById(id, data)
      .then((response) => {
        const resData = response.data;
        dispatch(updateData({id, data: resData, modelName: 'SocialNetworkAccount'}));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function unbanSocialAccount(id, data) {
  const REQUEST_ID = `unbanSocialAccount_${id}`;
  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    return socialAccounts.unbanById(id, data)
      .then((response) => {
        const resData = response.data;
        dispatch(updateData({id, data: resData, modelName: 'SocialNetworkAccount'}));
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}

export function banMulitpleSocialAccounts(influencerId, data) {
  const REQUEST_ID = `banMulitpleSocialAccounts_${influencerId}`;
  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));

    return socialAccounts.banByInfluencerId(influencerId, data)
      .then(() => {
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(response => handleRequestFail({dispatch, response, id: REQUEST_ID}));
  };
}


export function updateSocialNetworkReach(data) {
  return (dispatch) => {
    dispatch(updateSocialNetworkReachRequest());

    return socialAccounts.updateSocialNetworkReach(data)
      .then(() => {
        const {_id,audience} = data.socialAccount
        dispatch(
          updateData({
            modelName: 'SocialNetworkAccount',
            id: _id,
            data: {audience: audience}
          }))

        displayAlert({type: 'success',message: i18n.get('DEFAULT_SUCCESS_TITLE')});
      })
      .catch((data) => {
        displayAlert({type: 'error',message: i18n.get('DEFAULT_ERROR_TITLE')});
      });
  };
}

export function updateSocialNetworkReachRequest() {
  return {type: UPDATE_FOLLOWERS_REQUEST};
}
export const linkSocialAccount = params => buildAPIRequest({
  params,
  id: 'linkSocialAccount',
  method: socialAccounts.link
});

export const fetchSocialAccountTimeline = id => buildAPIRequest({
  id: `fetchSocialAccountTimeline_${id}`,
  params: id,
  method: socialAccounts.fetchTimeline
});

export const addSocialAccountToFavorites = ({brandId, socialAccountId}) => buildAPIRequest({
  id: `addSocialAccountToFavorites_${socialAccountId}`,
  method: socialAccounts.addToFavorites,
  params: {socialAccountId, brandId},
  callback: ({dispatch}) => {
    dispatch(updateData({
      modelName: 'SocialNetworkAccount',
      id: socialAccountId,
      data: {favorite: true}
    }));
  }
});

export const removeSocialAccountFromFavorites = ({brandId, socialAccountId}) => buildAPIRequest({
  id: `removeSocialAccountFromFavorites_${socialAccountId}`,
  method: socialAccounts.removeFromFavorites,
  params: {socialAccountId, brandId},
  callback: ({dispatch}) => {
    dispatch(updateData({
      modelName: 'SocialNetworkAccount',
      id: socialAccountId,
      data: {favorite: false}
    }));
  }
});

export const blockSocialAccount = ({brandId, socialAccountId}) => buildAPIRequest({
  id: `blockSocialAccount_${socialAccountId}`,
  method: socialAccounts.block,
  params: {socialAccountId, brandId},
  callback: ({dispatch}) => {
    dispatch(updateData({
      modelName: 'SocialNetworkAccount',
      id: socialAccountId,
      data: {blocked: true}
    }));
  }
});

export const unblockSocialAccount = ({brandId, socialAccountId}) => buildAPIRequest({
  id: `unblockSocialAccount_${socialAccountId}`,
  method: socialAccounts.unblock,
  params: {socialAccountId, brandId},
  callback: ({dispatch}) => {
    dispatch(updateData({
      modelName: 'SocialNetworkAccount',
      id: socialAccountId,
      data: {blocked: false}
    }));
  }
});

export const searchAudienceKeywords = params => buildAPIRequest({
  params,
  id: 'searchAudienceKeywords',
  method: socialAccounts.searchAudienceKeywords,
  callback: ({dispatch, data}) => {
    const parsedData = chain(data)
      .get('data', [])
      .map(k => ({...k, id: k.keyword}))
      .value();

    dispatch(replaceData({
      data: parsedData,
      modelName: 'AudienceKeyword'
    }));
  }
});

export const getCrispUrl = params => buildAPIRequest({
  params,
  id: 'getCrispUrl',
  method: socialAccounts.getCrispUrl,
  callback: ({dispatch, data}) => {
    dispatch(updateData({
      modelName: 'SocialNetworkAccount',
      id: params.id,
      data: {
        crispUrl: data.data.url
      }
    }));
  }
});

export const constants = {
  FETCH_SCORE_DETAILS_SUCCESS,
  FETCH_CONFIGURATION
};
