"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const controllers_1 = require("apps/shared/controllers");
const Common_1 = require("./Common");
const AppRoutes = props => (React.createElement(controllers_1.AppController, Object.assign({}, props),
    React.createElement(Common_1.default, Object.assign({}, props))));
const Main = () => (React.createElement(react_router_dom_1.Route, { path: '/', component: AppRoutes }));
exports.default = Main;
