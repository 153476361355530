import React from 'react';

import Tour from 'modules/shared/tours/BaseTour';
import { NewCampaignButton, CreditSection, UsersSection } from './components';

const COOKIE_NAME = 'vf_tour_advertiser_brand';
const PREV_REQUIRED_TOUR = 'vf_tour_advertiser_landing';
const STEPS = [
  {
    selector: '[data-tour-adv-brand="1"]',
    position: 'left',
    className: 'margin-top-1x',
    component: CreditSection
  },
  {
    selector: '[data-tour-adv-brand="2"]',
    position: 'left',
    className: 'margin-top-1x',
    component: UsersSection
  },
  {
    selector: '[data-tour-adv-brand="3"]',
    position: 'left-below',
    className: 'margin-top-1x',
    component: NewCampaignButton
  }
];

const AdvertiserBrandTour = () => (
  <Tour
    cookieName={COOKIE_NAME}
    steps={STEPS}
    prevRequiredTour={PREV_REQUIRED_TOUR}
  />
);

export default AdvertiserBrandTour;
