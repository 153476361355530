"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const react_router_dom_1 = require("react-router-dom");
const Popover = require("react-popover");
const i18n = require("i18n");
const styles = require("./OrganizationMenuUI.less");
class OrganizationMenuUI extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            showPop: false
        };
        this.handleToggle = () => {
            this.setState(prev => ({ showPop: !prev.showPop }));
        };
    }
    render() {
        const { showPop } = this.state;
        const { acceptedOrgs, createdOrgs } = this.props;
        const isEmpty = acceptedOrgs.length === 0 && createdOrgs.length === 0;
        return (React.createElement(OrgPopover, { state: showPop, toggle: this.handleToggle, pending: createdOrgs.length > 0 }, isEmpty ? (React.createElement(react_router_dom_1.Link, { to: '/organizations/new', style: { padding: '2rem' }, onClick: this.handleToggle }, i18n.get('ORGANIZATION_CREATE_NEW'))) : (React.createElement(React.Fragment, null,
            React.createElement(react_router_dom_1.Link, { to: '/organizations/new', className: styles['create-link'], onClick: this.handleToggle }, i18n.get('CREATE')),
            React.createElement("div", { className: styles.list },
                createdOrgs.map(org => (React.createElement(OrgRow, { key: org.id, org: org, toggle: this.handleToggle, isInvite: true }))),
                acceptedOrgs.map(org => (React.createElement(OrgRow, { key: org.id, org: org, toggle: this.handleToggle }))))))));
    }
}
exports.default = OrganizationMenuUI;
const OrgPopover = React.memo(({ children, state, toggle, pending }) => (React.createElement(Popover, { isOpen: state, className: 'popover-without-padding popover-with-border', body: children, place: 'below', onOuterAction: toggle },
    React.createElement("div", { className: classnames_1.default(styles.button, 'height100'), onClick: toggle },
        pending && React.createElement("div", { className: classnames_1.default(styles.pending) }),
        React.createElement("i", { className: 'vf-icon icon-fix icon-building' }),
        i18n.get('ORGANIZATION_HEADER'),
        React.createElement("i", { className: 'vf-icon icon-fix icon-caret-down-two', style: { transform: state ? 'rotate(180deg)' : 'rotate(0)' } })))));
const OrgRow = React.memo(({ org, isInvite, toggle }) => (React.createElement(react_router_dom_1.Link, { to: `/organizations/${org.id}/${isInvite ? 'confirm' : 'users'}`, className: classnames_1.default(styles.row, 'flex'), onClick: toggle },
    isInvite && React.createElement("div", { className: styles.dot }),
    React.createElement("i", { className: 'vf-icon icon-building margin-right-1x icon-fix' }),
    React.createElement("span", { className: 'margin-right-2x ellipsis' }, org.name),
    isInvite && (React.createElement("span", { className: 'vf-bold', style: { marginLeft: 'auto' } }, i18n.get('ADV_BRANDS_BRAND_INVITATION_INVITATION_LABEL'))))));
