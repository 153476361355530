import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { throttle, get } from 'lodash';
import classNames from 'classnames';
import is from 'is_js';
import i18n from 'i18n';

const ReactTour = createClass({
  propTypes: {
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        selector: PropTypes.string,
        content: PropTypes.any,
        className: PropTypes.string,
        style: PropTypes.object,
        position: PropTypes.oneOf([
          'below',
          'above',
          'left',
          'right',
          'right-above',
          'left-below',
          'left-above'
        ])
      })
    ),
    currentStep: PropTypes.number,
    visible: PropTypes.bool,
    showDots: PropTypes.bool,
    showBeacon: PropTypes.bool,
    onSkip: PropTypes.func
  },

  getDefaultProps() {
    return {
      visible: true,
      showDots: true,
      currentStep: 0,
      steps: [],
      position: 'below'
    };
  },

  getInitialState() {
    return { showBeacon: this.props.showBeacon };
  },

  componentDidMount() {
    this.throttledOnWindowResize = throttle(this.onWindowResize, 64);
    window.addEventListener('resize', this.throttledOnWindowResize);
  },

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledOnWindowResize);
  },

  onWindowResize() {
    this.forceUpdate();
  },

  render() {
    const { currentStep, steps, visible, showDots, onSkip } = this.props;
    const { showBeacon } = this.state;

    const step = steps[currentStep] || {};
    const content = document.querySelector(step.selector);

    if (!steps || !content) return <noscript />;

    const position = get(steps[currentStep], 'position', 'below');
    const className = get(steps[currentStep], 'className');
    const containerClassName = classNames('react-tour', {
      'react-tour-hide': !visible
    });
    const contentClassName = classNames(
      'react-tour-focus-text',
      className,
      position
    );
    const focusClassName = classNames('react-tour-focus', {
      squared: is.safari()
    });

    const elementRect = content && content.getBoundingClientRect();

    if (showBeacon) {
      return (
        <Beacon
          onClick={() => this.setState({ showBeacon: false })}
          style={{
            top: elementRect.top,
            left: elementRect.left
          }}
        />
      );
    }

    return (
      <div className={containerClassName}>
        <div
          className={focusClassName}
          style={{
            top: parseInt(elementRect.top - 20, 10),
            left: parseInt(elementRect.left - 20, 10),
            height: parseInt(elementRect.height + 40, 10),
            width: parseInt(elementRect.width + 40, 10)
          }}
        >
          <div className={contentClassName}>
            {steps[currentStep] && steps[currentStep].content}

            {showDots && (
              <div className='react-tour-dots'>
                {steps.map((s, index) => (
                  <div
                    key={index}
                    className={`react-tour-dots-single ${
                      currentStep >= index ? 'active' : ''
                    }`}
                  />
                ))}
              </div>
            )}

            <a onClick={onSkip}>{i18n.get('SKIP')}</a>
          </div>
        </div>
      </div>
    );
  }
});

const Beacon = ({ style, onClick }) => (
  <div className='react-tour' onClick={onClick}>
    <span className='beacon' style={style} />
  </div>
);

Beacon.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func
};

export default ReactTour;
