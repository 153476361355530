import {getMinScoreToShowReliability} from '../config/app';

const MIN_SCORE_TO_SHOW_RELIABILITY = getMinScoreToShowReliability();

module.exports = (socialAccount = {}) => {
  const {score = {}} = socialAccount;
  const {positive = 0, negative = 0} = score;
  const totalScore = positive + negative;

  return totalScore >= MIN_SCORE_TO_SHOW_RELIABILITY;
};
