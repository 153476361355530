/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, get, sortBy } from 'lodash';
import i18n from 'i18n';

import * as appActions from 'redux/modules/app';
import tracking from 'modules/legacy/js/lib/tracking/user';

import {
  getCurrentUser,
  getAvailableCampaigns,
  getCurrentInfluencerNewInvites,
  getCurrentInfluencerParticipationRequests
} from 'modules/shared/selectors';

import { LoadingState } from 'modules/shared/components';
import {
  PublicCampaignCard,
  PublicCampaignCardLite
} from 'modules/campaigns/influencer/containers';
import * as ParticipationLiteActions from 'redux/modules/participationLite';

import { LogoPlus, logoVoxFeedLite } from 'assets/images';

const LINK_PARTICIPATION_TYPE = 'link';
const HISTORY = 'History';

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
    request: get(state, 'app.requests.fetchInfluencerAvailableCampaigns'),
    publicCampaigns: getAvailableCampaigns(state),
    invites: getCurrentInfluencerNewInvites(state),
    participationRequests: getCurrentInfluencerParticipationRequests(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changePage: appActions.changePage,
      getParticipationLite: ParticipationLiteActions.getParticipationLite
    },
    dispatch
  );
}

class PublicCampaignsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participations: []
    };
  }
  static propTypes = {
    publicCampaigns: PropTypes.array,
    request: PropTypes.object,
    changePage: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
    getParticipationLite: PropTypes.func.isRequired
  };

  static defaultProps = {
    publicCampaigns: [],
    request: {},
    currentUser: {}
  };

  componentDidMount() {
    const { getParticipationLite, currentUser } = this.props;
    const { validCategory } = currentUser;

    if (validCategory) {
      getParticipationLite().then(data => {
        this.setState({
          participations: data
        });
      });
    }
  }

  isLoading = () => {
    const { request, publicCampaigns } = this.props;
    return request.status === 'loading' && isEmpty(publicCampaigns);
  };

  render = () => {
    const { publicCampaigns, changePage, currentUser, request } = this.props;
    const { validCategory } = currentUser;
    const { participations } = this.state;

    if (this.isLoading()) return <LoadingState />;
    if (isEmpty(publicCampaigns) && !validCategory) return null;

    const AvailableParticipation = participations.filter(
      (c, i) => c.availableParticipations.length > 0
    );

    const sortedCamps = sortBy(
      publicCampaigns,
      camp => camp.participationType !== LINK_PARTICIPATION_TYPE
    );

    return (
      <div style={{ margin: '0 -13px' }}>
        <div className='flex middle-xs between-xs margin-top-2x padding-horizontal-1x'>
          {!validCategory ? (
            <React.Fragment>
              <LogoPlus alt='VoxFeed Plus logo' />
              <span className='flex'>
                <h4 className='vf-text-gray-dark vf-font-bold width-auto'>
                  {i18n.get('INF_CAMPAIGNS_VOXFEED_PLUS_TITLE')}
                </h4>
                <a
                  href={i18n.get('HELP_LINKS_VOXFEED_PLUS')}
                  className='margin-horizontal-Hx vf-text-gray-dark vf-icon icon-help'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ fontSize: '2.2rem', marginTop: '1rem' }}
                />
              </span>
            </React.Fragment>
          ) : (
            <img
              src={logoVoxFeedLite}
              alt='VoxFeed Lite logo'
              className='margin-right-Hx'
            />
          )}
        </div>

        <div className='flex flex-wrap width100 margin-top-Hx'>
          {validCategory &&
            AvailableParticipation &&
            AvailableParticipation.map(c => (
              <CampaignLite key={c.id} campaign={c} changePage={changePage} />
            ))}
          {!validCategory &&
            sortedCamps.map(c => (
              <Campaign
                key={c.campaignId}
                campaign={c}
                changePage={changePage}
                request={request.response}
              />
            ))}
        </div>
      </div>
    );
  };
}

function Campaign({ campaign, changePage, request }) {
  return (
    <div
      className='col-xs-12 col-sm-4 padding-1x'
      data-uitest='public-invite'
      key={campaign._id}
    >
      <PublicCampaignCard
        campaignId={campaign.campaignId}
        publicCampaign={campaign}
        onClick={() => {
          tracking.track('INF - Click Open Campaign');
          changePage(`/public-campaigns/${campaign.campaignId}`);
        }}
        request={request}
      />
    </div>
  );
}
function CampaignLite({ campaign, changePage }) {
  window.localStorage.removeItem(HISTORY);
  return (
    <div
      className='col-xs-12 col-sm-4 padding-1x'
      data-uitest='public-invite'
      key={campaign.id}
    >
      <PublicCampaignCardLite
        campaignId={campaign.id}
        AvailableParticipation={campaign.availableParticipations}
        campaign={campaign}
        onClick={() => {
          tracking.track('INF - Click Open Campaign');
          changePage(`/public-campaigns/${campaign.id}`);
        }}
      />
    </div>
  );
}

Campaign.propTypes = {
  campaign: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
  request: PropTypes.array.isRequired
};

CampaignLite.propTypes = {
  campaign: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicCampaignsList);
