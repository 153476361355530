"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const moment = require("moment");
const day_picker_1 = require("../components/day-picker");
const DateRangePicker = props => {
    const { value = {}, defaultMonth } = props;
    const onStartDate = (date) => {
        var _a;
        const { onChange } = props;
        onChange({
            startDate: moment(date).startOf('day').toDate(),
            endDate: (_a = value) === null || _a === void 0 ? void 0 : _a.endDate
        });
    };
    const onEndDate = (date) => {
        var _a;
        const { onChange } = props;
        onChange({
            startDate: (_a = value) === null || _a === void 0 ? void 0 : _a.startDate,
            endDate: moment(date).endOf('day').toDate()
        });
    };
    const { startDate, endDate } = value;
    return (React.createElement("div", { className: 'flex flex-align-top' },
        React.createElement("div", null,
            React.createElement(day_picker_1.default, { value: startDate, toRange: endDate, defaultMonth: defaultMonth, onChange: onStartDate, disableDaysAfter: endDate })),
        React.createElement("div", { className: 'margin-left-2x' },
            React.createElement(day_picker_1.default, { value: endDate, toRange: startDate, defaultMonth: defaultMonth, onChange: onEndDate, disableDaysBefore: startDate }))));
};
exports.default = DateRangePicker;
