"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const redux_1 = require("redux");
const i18n = require("i18n");
const UserActions = require("redux/modules/users");
const AccountDataFormUI_1 = require("./AccountDataFormUI");
const SharedActions = require("redux/modules/shared");
const selectors_1 = require("modules/shared/selectors");
const types_1 = require("modules/shared/types");
const errorStateHandler_1 = require("apps/shared/hocs/errorStateHandler");
const withLoading_1 = require("apps/shared/hocs/withLoading");
const VERSIONCAMPAING = 'VersionCampaing';
const INVITATION_LINK = 'invitationLink';
class AccountDataForm extends React.Component {
    constructor(props) {
        super(props);
        this.validateForm = () => {
            const { user, validInvitation } = this.props;
            const isInfluencer = user.role === types_1.UserRole.INFLUENCER;
            const isAdvertiser = user.role === types_1.UserRole.ADVERTISER;
            const { city, country, birthday, name = '', gender = '', brandName = '' } = this.state.form;
            return {
                name: !name ? i18n.get('REQUIRED') : null,
                city: !city ? i18n.get('REQUIRED') : null,
                country: !country ? i18n.get('REQUIRED') : null,
                gender: isInfluencer && !gender ? i18n.get('REQUIRED') : null,
                birthday: isInfluencer && !birthday ? i18n.get('REQUIRED') : null,
                brandName: isAdvertiser && !validInvitation && !brandName
                    ? i18n.get('REQUIRED')
                    : null
            };
        };
        this.handleChangeInput = (name, value) => {
            const { userCategory, campaignLite, validInvitation, campaignCategory, handleInputValidation } = this.props;
            this.setState(prev => ({ form: Object.assign(Object.assign({}, prev.form), { [name]: value }) }), () => {
                handleInputValidation({
                    name,
                    value,
                    validationFunction: this.validateForm
                });
            });
            if (validInvitation || campaignLite) {
                this.setState(prev => ({
                    form: Object.assign(Object.assign({}, prev.form), { category: campaignLite ? campaignCategory : userCategory })
                }));
            }
        };
        this.handleChangeMultipleInput = (fieldsObject) => {
            const { onSetErrors } = this.props;
            this.setState(prev => ({ form: Object.assign(Object.assign({}, prev.form), fieldsObject) }), () => {
                onSetErrors(this.validateForm());
            });
        };
        this.handleNext = () => {
            const { setLoading, updateUser, user, onAfterSave, onSetErrors } = this.props;
            const { form } = this.state;
            const errors = this.validateForm();
            const isValid = !lodash_1.isEmpty(errors) && Object.values(errors).every(val => val === null);
            if (!isValid)
                return onSetErrors(errors);
            setLoading(true);
            updateUser(Object.assign(Object.assign({}, user), form))
                .then(onAfterSave)
                .catch(error => console.error(error))
                .finally(this.handleSaveResolve);
        };
        this.handleSaveResolve = () => this.props.setLoading(false);
        this.onLogOut = () => {
            const { logout } = this.props;
            window.localStorage.removeItem(VERSIONCAMPAING);
            window.localStorage.removeItem(INVITATION_LINK);
            logout();
        };
        this.state = {
            form: lodash_1.pick(props.user, [
                'birthday',
                'city',
                'country',
                'gender',
                'lastName',
                'name',
                'profileImage',
                'region',
                'timezone',
                'brandName',
                'musicalGenres'
            ])
        };
    }
    render() {
        const { form } = this.state;
        const hasData = lodash_1.isEmpty(form);
        const { user, errors, isLoading, campaignLite, invitationLink, validInvitation } = this.props;
        return (React.createElement(AccountDataFormUI_1.default, Object.assign({ errors: errors, role: user.role, canSave: hasData, isLoading: isLoading, onLogout: this.onLogOut, onNext: this.handleNext, campaignLite: campaignLite, invitationLink: invitationLink, validInvitation: validInvitation, onChangeInput: this.handleChangeInput, onChangeMultipleInput: this.handleChangeMultipleInput }, form)));
    }
}
function mapStateToProps(state) {
    return { user: selectors_1.getCurrentUser(state) };
}
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({ logout: SharedActions.logout, updateUser: UserActions.updateCurrentUser }, dispatch);
}
exports.default = redux_1.compose(withLoading_1.default, errorStateHandler_1.default, react_redux_1.connect(mapStateToProps, mapDispatchToProps))(AccountDataForm);
