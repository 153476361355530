"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./BrandsSelect.less");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const components_2 = require("apps/shared/components");
const BrandsSelect = props => {
    const [showSelect, setShowSelect] = React.useState(false);
    const handleBrandClick = event => {
        const { onBrandChange } = props;
        const id = event.currentTarget.getAttribute('data-brandId');
        setShowSelect(false);
        onBrandChange(id);
    };
    const handleInputClick = (event) => {
        event.stopPropagation();
        setShowSelect(true);
    };
    const handleInputChange = event => {
        const { onInputChange } = props;
        onInputChange(event.target.value);
    };
    const handleClear = () => {
        const { onInputChange, onBrandChange } = props;
        setShowSelect(false);
        onInputChange('');
        onBrandChange(null);
    };
    const { searchValue, brands, selectedBrand, } = props;
    const buildSelectedValue = () => {
        if (brands.length < 1)
            return i18n.get('ADV_BRANDS_TRANSFER_DIALOG_NO_BRANDS');
        if (showSelect)
            return (React.createElement(InputItem, { initialValue: searchValue, onClick: handleInputClick, onChange: handleInputChange, onClear: handleClear }));
        if (selectedBrand)
            return React.createElement(SelectItem, { brand: selectedBrand });
        return i18n.get('ADV_BRANDS_TRANSFER_DIALOG_SELECT_BRAND');
    };
    return (React.createElement(components_1.SelectWrapper, { isOpen: showSelect, selectedValue: buildSelectedValue(), onToggle: setShowSelect }, brands.map(obrand => (React.createElement(SelectItem, { key: obrand.id, brand: obrand, onClick: handleBrandClick })))));
};
exports.default = BrandsSelect;
const SelectItem = ({ brand, onClick }) => (React.createElement("div", { "data-brandId": brand.id, className: classnames_1.default('flex', styles['select-item'], {
        [styles['select-value']]: !onClick
    }), onClick: onClick },
    React.createElement(components_2.BrandAvatar, { imageURL: brand.image, brandName: brand.name, width: 24, height: 24 }),
    React.createElement("p", { className: 'vf-bold margin-left-Hx' }, brand.name),
    React.createElement("span", { style: { marginLeft: 'auto' } }, i18n.getMoney({ amount: brand.balance, currency: brand.currency }))));
const InputItem = ({ initialValue, onClick, onChange, onClear }) => (React.createElement("div", { className: 'flex width100' },
    React.createElement(components_1.DebounceInput, { value: initialValue, className: styles.input, onClick: onClick, onChange: onChange }),
    React.createElement("span", { className: styles.icon, onClick: onClear },
        React.createElement("i", { className: 'vf-icon icon-fix icon-close' }))));
