"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const app_1 = require("modules/legacy/js/actions/app");
const selectors_1 = require("modules/shared/selectors");
const PostLinkLiteActions = require("redux/modules/postLite");
const AvailableAccountsLiteUI_1 = require("./AvailableAccountsLiteUI");
const ActiveCampaignLiteActions = require("redux/modules/activePostLite");
const HistoryCampaignLiteActions = require("redux/modules/historyPostLite");
const ParticipationLiteActions = require("redux/modules/participationLite");
const ActiveParticipationLiteActions = require("redux/modules/activateParticipateLite");
const STORY = 'Story';
const ACTIVEPOST = 'ActivePost';
const OPENVERIFYMODAL = 'OpenVerifyModal';
const AvailableAccountsLite = ({ getParticipationLite, toPostLite, postLinkLite, getActiveCampaignLite, campaignId, getHistoryCampaignLite, getStory, getStoryPost, onErrorSettingShippingAddress, ship }) => {
    const [participations, setParticipations] = React.useState([]);
    const [participationModal, setParticipationModal] = React.useState(false);
    const [verifyModal, setVerifyModal] = React.useState(false);
    const [activeParticipations, setActiveParticipations] = React.useState([]);
    const [historyParticipations, setHistoryParticipations] = React.useState([]);
    const isActivePost = window.localStorage.getItem(ACTIVEPOST);
    let brandName = null;
    let AvailableParticipation;
    let activePost;
    let isActiveStory;
    if (activeParticipations.length > 0 && isActivePost) {
        brandName = activeParticipations[0].brandName;
    }
    else if (participations.length > 0 && !isActivePost) {
        brandName = participations[0].brandName;
    }
    if (!isActivePost) {
        AvailableParticipation = participations.filter((c, i) => c.availableParticipations.length > 0);
    }
    else {
        AvailableParticipation = activeParticipations.filter((c, i) => c.availableParticipations.length > 0);
    }
    const onCloseModal = () => {
        setParticipationModal(false);
        app_1.gotoPage('/voxear/active');
        window.localStorage.removeItem(STORY);
    };
    const openVerifyModal = () => {
        window.localStorage.setItem(OPENVERIFYMODAL, 'true');
        window.localStorage.removeItem(STORY);
        setParticipationModal(false);
        setVerifyModal(true);
    };
    const continueToVox = () => {
        app_1.gotoPage('/voxear/history');
    };
    const onSuccess = () => {
        openVerifyModal();
    };
    const postLink = ({ linkPost, netSocial, videoId, img }) => {
        const args = {
            id: campaignId,
            type: 'story',
            social_network: netSocial
        };
        if (videoId !== null) {
            args.video_id = videoId;
        }
        else if (img !== null) {
            args.image = img;
        }
        else if (linkPost != null) {
            args.link = linkPost;
            delete args.type;
        }
        postLinkLite(args).then(onSuccess);
    };
    const storyCheck = () => {
        let check = 'post';
        if (activeParticipations.length > 0 && isActivePost) {
            activeParticipations.map((item, i) => {
                if (item.id === campaignId) {
                    item.availableParticipations.map((c, i) => {
                        check = c.type;
                        return React.createElement(React.Fragment, null, check);
                    });
                }
                return React.createElement(React.Fragment, null, check);
            });
        }
        else if (participations.length > 0 && !isActivePost) {
            if (getStory) {
                check = 'story';
                return check;
            }
            else if (getStoryPost) {
                if (activeParticipations.length > 0) {
                    activeParticipations.map((item, i) => {
                        if (item.id === campaignId) {
                            item.availableParticipations.map((c, i) => {
                                activePost = c.type;
                                isActiveStory = c.type;
                                if (c.type === 'story') {
                                    check = 'post';
                                }
                                else {
                                    check = 'story';
                                }
                                return React.createElement(React.Fragment, null, check);
                            });
                        }
                    });
                }
                if (historyParticipations.length > 0) {
                    historyParticipations.map((item, i) => {
                        if (item.id === campaignId) {
                            item.availableParticipations.map((c, i) => {
                                activePost = c.type;
                                isActiveStory = c.type;
                                if (c.type === 'story') {
                                    check = 'post';
                                }
                                else {
                                    check = 'story';
                                }
                                return React.createElement(React.Fragment, null, check);
                            });
                        }
                    });
                }
            }
        }
        else if (historyParticipations.length > 0) {
            historyParticipations.map((item, i) => {
                if (item.id === campaignId) {
                    item.availableParticipations.map((c, i) => {
                        check = c.type;
                        return React.createElement(React.Fragment, null, check);
                    });
                }
                return React.createElement(React.Fragment, null, check);
            });
        }
        return check;
    };
    const onPost = (netSocial, socialId, typeIg) => {
        const args = {
            id: campaignId,
            social_network: netSocial,
            social_network_id: socialId,
            type: typeIg || storyCheck()
        };
        if (netSocial !== 'instagram') {
            delete args.type;
        }
        toPostLite(args);
    };
    const network = AvailableParticipation &&
        AvailableParticipation.map(item => {
            if (item.id === campaignId) {
                storyCheck();
                return (React.createElement(AvailableAccountsLiteUI_1.default, { participationNetworks: item.availableParticipations, activePost: activePost === 'post', setParticipationModal: setParticipationModal, participationModal: participationModal, onPost: onPost, postLink: postLink, onCloseModal: onCloseModal, continueToVox: continueToVox, isActivePost: isActivePost, isActiveStory: isActiveStory === 'story', brandName: brandName, getStory: getStory, getStoryPost: getStoryPost, ship: ship, onErrorSettingShippingAddress: onErrorSettingShippingAddress }));
            }
            return null;
        });
    React.useEffect(() => {
        getParticipationLite().then(data => setParticipations(data));
        getActiveCampaignLite().then(data => setActiveParticipations(data));
        getHistoryCampaignLite().then(data => setHistoryParticipations(data));
    }, []);
    return React.createElement(React.Fragment, null, network);
};
const mapStateToProps = (state) => ({
    user: selectors_1.getCurrentUser(state)
});
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({
        getParticipationLite: ParticipationLiteActions.getParticipationLite,
        toPostLite: ActiveParticipationLiteActions.toPostLite,
        postLinkLite: PostLinkLiteActions.postLinkLite,
        getActiveCampaignLite: ActiveCampaignLiteActions.getActiveCampaignLite,
        getHistoryCampaignLite: HistoryCampaignLiteActions.getHistoryCampaignLite
    }, dispatch);
}
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(AvailableAccountsLite);
