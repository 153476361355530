"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const ProfileTypeUI_1 = require("./ProfileTypeUI");
const UserActions = require("redux/modules/users");
const SharedActions = require("redux/modules/shared");
const brandsActions = require("redux/modules/brands");
const selectors_1 = require("modules/shared/selectors");
const ProfileType = ({ user, logout, getBrands, updateUser }) => {
    const { id } = user;
    const [idBrand, setIdBrand] = React.useState(null);
    const [selectProfile, setSelectProfile] = React.useState('');
    React.useEffect(() => {
        getBrands(id).then(({ data }) => setIdBrand(data[0].id));
    }, []);
    const handleSelectProfile = React.useCallback((type) => {
        setSelectProfile(type);
    }, [setSelectProfile]);
    const success = () => {
        const url = window.location.href;
        const path = window.location.pathname;
        const href = `${url.split(path)[0]}/brands/${idBrand}/campaigns/create`;
        window.location.href = href;
    };
    const handleOnFinishDone = () => {
        if (selectProfile === '') {
            return;
        }
        updateUser(Object.assign(Object.assign({}, user), { category: selectProfile })).then(success);
    };
    return (React.createElement(ProfileTypeUI_1.default, { logout: logout, selectProfile: selectProfile, onFinishDone: handleOnFinishDone, handleSelectProfile: handleSelectProfile }));
};
const mapStateToProps = (state) => ({
    user: selectors_1.getCurrentUser(state)
});
const mapDispatchToProps = (dispatch) => redux_1.bindActionCreators({
    logout: SharedActions.logout,
    getBrands: brandsActions.getBrands,
    updateUser: UserActions.updateCurrentUser
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(ProfileType);
