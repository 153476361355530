"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const images_1 = require("assets/images");
const styles = require("./VerifyModalLite.less");
const components_1 = require("apps/shared/components");
const VerifyModalLite = ({ brandName, onCloseModal }) => (React.createElement("div", { className: styles.dialog },
    React.createElement(components_1.DialogHeader, { className: styles.header, onClose: onCloseModal }, i18n.get('INF_LITE_ACCOUNTS_PARTICIPATION_TITLE')),
    React.createElement("div", { className: styles.dialogContainer },
        React.createElement(components_1.Img, { src: images_1.iconCheckImg, style: { margin: '0 auto 5%' }, width: 50 }),
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.title }, i18n.get('INF_LITE_ACCOUNTS_SUCCEFUL_PARTICIPATION_TITLE', {
                brandName
            }))),
        React.createElement("div", { className: styles.buttonContainer },
            React.createElement(ui_library_1.Button, { color: 'primary', onClick: onCloseModal, className: styles.button }, i18n.get('CONTINUE'))))));
exports.default = VerifyModalLite;
