import { BackButton } from 'apps/influencers/Earnings/components';
import { Step } from 'apps/influencers/Earnings/types';
import { Button, Select } from 'ui-library';
import React from 'react';
import { get, isUndefined } from 'lodash';
import i18n from 'i18n';
import { FormInput } from 'apps/shared/components';

const RUC_MAX_LENGHT = 11;

export default function BankAccountForm(props) {
  const adminView = get(props, 'adminView', false);
  const {
    extraClass,
    onChangeInput,
    justLecture,
    loading,
    isModal,
    buttonState,
    doValidations,
    bankAccount,
    onChangeStep,
    showErrors,
    getErrors,
    updatedfields
  } = props;
  const { onChangeAccountType } = props;
  let mode = 'new';
  if (justLecture) {
    mode = 'update';
  }

  const BankAccountType = {
    current: i18n.get('INF_SETTINGS_MONEY_BANK_ACCOUNT_TYPE_CHECKING'),
    saving: i18n.get('INF_SETTINGS_MONEY_BANK_ACCOUNT_TYPE_SAVINGS')
  };

  const fullNameError = getErrors('fullName');
  const personalIncomeTaxCodeError = getErrors('personalIncomeTaxCode');
  const bankNameError = getErrors('bankName');
  const branchAccountError = getErrors('branchAccount');
  const accountNameError = getErrors('accountName');

  const inputChange = (event, limit = 100) => {
    onChangeInput(event, mode, limit);
  };

  const getValue = field => {
    return isUndefined(updatedfields[field])
      ? bankAccount[field]
      : updatedfields[field];
  };

  const hasToUpdate = !Object.keys(updatedfields).length;

  const display_fullname = getValue('fullName');
  const display_personalIncomeTaxCode = getValue('personalIncomeTaxCode');
  const display_bankName = getValue('bankName');
  const display_branchAccount = getValue('branchAccount');
  const display_account_type = getValue('accountType');

  return (
    <div className={extraClass}>
      <div className='vf-simple-form-no-margin'>
        {!justLecture && (
          <span className='vf-simple-form-label-info'>
            {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_PENDING_FOR_PAYMENT')}
          </span>
        )}
        <h4 className='vf-simple-form-label'>
          {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_ACCOUNT_HOLDER_LABEL')}
        </h4>
        <div className='vf-simple-form-inner-row'>
          <div className='vf-simple-form-group col-md-6'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_FULL_NAME_LABEL')}
            </span>
            <FormInput
              id='name-input'
              name='fullName'
              autoComplete='off'
              hasError={showErrors && fullNameError !== ''}
              onChange={inputChange}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_FULL_NAME_PLACEHOLDER'
              )}
              value={display_fullname}
              disabled={adminView}
            />
            {showErrors && fullNameError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {fullNameError}
              </div>
            )}
          </div>
          <div className='vf-simple-form-group col-md-6'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_RUC')}
            </span>
            <FormInput
              id='personalIncomeTaxCode-input'
              name='personalIncomeTaxCode'
              autoComplete='off'
              onChange={event => {
                inputChange(event, RUC_MAX_LENGHT);
              }}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_PERSONAL_TAX_CODE_PLACEHOLDER'
              )}
              value={display_personalIncomeTaxCode}
              disabled={adminView}
              type='text'
              pattern='^[a-zA-Z0-9-_]+$'
              hasError={showErrors && personalIncomeTaxCodeError !== ''}
            />
            {showErrors && personalIncomeTaxCodeError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {personalIncomeTaxCodeError}
              </div>
            )}
          </div>
        </div>
        <h4 className='vf-simple-form-label'>
          {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_BANK_INFORMATION_LABEL')}
        </h4>
        <div className='vf-simple-form-inner-row'>
          <div className='vf-simple-form-group col-md-6'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_BANK_NAME_LABEL')}
            </span>
            <FormInput
              id='bankName-input'
              name='bankName'
              autoComplete='off'
              hasError={showErrors && bankNameError !== ''}
              onChange={inputChange}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_BANK_NAME_PLACEHOLDER'
              )}
              value={display_bankName}
              disabled={adminView}
            />
            {showErrors && bankNameError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {bankNameError}
              </div>
            )}
          </div>
          <div className='vf-simple-form-group col-md-6'>
            <span className='vf-simple-form-group-label'>
              {i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_ACCOUNT_NUMBER_LABEL'
              )}
            </span>
            <FormInput
              id='branchAccount-input'
              name='branchAccount'
              autoComplete='off'
              hasError={showErrors && branchAccountError !== ''}
              onChange={event => {
                inputChange(event, 30);
              }}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_ACCOUNT_NUMBER_PLACEHOLDER'
              )}
              value={display_branchAccount}
              disabled={adminView}
              type='text'
              pattern='[0-9]*'
            />
            {showErrors && branchAccountError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {branchAccountError}
              </div>
            )}
          </div>
        </div>
        <div className='vf-simple-form-inner-row'>
          <div className='vf-simple-form-group col-md-6'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_ACCOUNT_TYPE_LABEL')}
            </span>
            <Select
              value={display_account_type}
              selectSize='md'
              disabled={adminView}
              onChange={event => {
                onChangeAccountType(event, mode);
              }}
              options={BankAccountType}
            />
            {showErrors && accountNameError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {accountNameError}
              </div>
            )}
          </div>
        </div>
        {!adminView && (
          <div className='vf-simple-form-inner-row'>
            <div className='vf-simple-form-group col-md-8'>
              {!justLecture && (
                <span className='vf-simple-form-label-info'>
                  {i18n.get(
                    'INF_SETTINGS_MONEY_DEPOSITS_FORM_PENDING_FOR_PAYMENT_BOTTOM'
                  )}
                </span>
              )}
            </div>
            <div className='vf-simple-form-group col-md-4'>
              {!justLecture && !loading && (
                <Button
                  color='primary'
                  disabled={!buttonState()}
                  onClick={doValidations}
                >
                  {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_BUTTON')}
                </Button>
              )}
              {!hasToUpdate && (
                <Button
                  color='primary'
                  disabled={hasToUpdate}
                  onClick={doValidations}
                  className='margin-bottom-1x'
                >
                  {i18n.get('UPDATE')}
                </Button>
              )}
              {loading && (
                <span>{i18n.get('INF_SETTINGS_MONEY_DEPOSITS_SENDING')}</span>
              )}
              {isModal && justLecture && (
                <div className='flex flex-justify-space-between'>
                  <BackButton onClick={onChangeStep} />
                  <Button
                    color='primary'
                    onClick={onChangeStep}
                    data-step={Step.SUMMARY}
                  >
                    {i18n.get('NEXT')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
