function importLocalesFromDir(webpackRequire) {
  return webpackRequire
    .keys()
    .map(webpackRequire)
    .reduce((result, locales) => {
      return {...result, ...locales};
    }, {});
}

module.exports = {
  es: importLocalesFromDir(require.context('./es', true, /\.json$/)),
  en: importLocalesFromDir(require.context('./en', true, /\.json$/)),
  id: importLocalesFromDir(require.context('./id', true, /\.json$/)),
  vi: importLocalesFromDir(require.context('./vi', true, /\.json$/)),
  th: importLocalesFromDir(require.context('./th', true, /\.json$/)),
};
