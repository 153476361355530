import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './AutoHideHeader.less';

const SCROLL_TRIGGER = 5;
export default class Header extends Component {
  show = false;
  previousY = 0;
  contentNode = null;

  componentDidMount() {
    this.previousY = window.scrollY;
    this.contentNode = document.getElementById('content');
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = event => {
    const currentY = window.scrollY;
    const didPass = window.scrollY > this.refs.header.clientHeight;

    if (didPass) {
      this.refs.header.classList.add(styles.fixed);
      this.refs.header.style.top = `-${this.refs.header.clientHeight}px`;
      this.contentNode.style.paddingTop = `${this.refs.header.clientHeight}px`;
      this.handleHeaderAnimation(currentY);
    } else if (currentY < SCROLL_TRIGGER) {
      this.resetHeaderPosition();
    }

    this.previousY = currentY;
  };

  handleHeaderAnimation = currentY => {
    if (currentY < this.previousY && !this.show) {
      this.show = true;
      this.refs.header.style.transform = 'translateY(100%)';
    }

    if (currentY > this.previousY && this.show) {
      this.show = false;
      this.refs.header.style.transform = 'translateY(0)';
    }
  };

  resetHeaderPosition = () => {
    this.show = false;
    this.refs.header.classList.remove(styles.fixed);
    this.refs.header.style.top = '';
    this.contentNode.style.paddingTop = '';
    this.refs.header.style.transform = '';
  };

  render() {
    const { children, anchor, offset, style = {}, ...other } = this.props;

    return (
      <header
        id='main-header'
        ref='header'
        className={styles.header}
        {...other}
      >
        {children}
      </header>
    );
  }
}

Header.propTypes = {
  anchor: PropTypes.number,
  offset: PropTypes.number,
  children: PropTypes.any
};

Header.defaultProps = {
  anchor: 0,
  offset: 0,
  children: null
};
