import Reflux from 'reflux';

module.exports = Reflux.createActions([
  'hideLoader',
  'displayAlert',
  'showLoader',
  'gotoPage',
  'reCAPTCHALoaded',
  'manageAccount',
  'updateUserInfo',
  'redirectToDefaultRoute'
]);
