"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const styles = require("./InviteDetailHeaderUI.less");
const lodash_1 = require("lodash");
const types_1 = require("modules/shared/types");
const components_1 = require("apps/shared/components");
const components_2 = require("apps/influencers/shared/components");
class InviteDetailHeader extends React.Component {
    render() {
        const { invite, socialAccount, statusText, totalProducts } = this.props;
        if (!socialAccount)
            return null;
        const { currency } = invite;
        return (React.createElement("div", { className: styles.grid },
            React.createElement(components_2.ParticipationDetailPayment, { amount: lodash_1.get(invite, 'influencerPay.total', 0), productsPayment: lodash_1.get(invite, 'productsPayment', []), style: { gridArea: 'price' }, totalProducts: totalProducts, currency: currency }),
            React.createElement("div", { className: 'flex', style: { gridArea: 'user' } },
                React.createElement(components_1.SocialBadge, { className: 'margin-right-Hx', socialNetwork: socialAccount.type, size: 14 }),
                socialAccount.username),
            invite.prepaid
                ? i18n.get('INF_CAMPAIGNS_DIRECT_PAYMENT')
                : !invite.prepaid &&
                    invite.publications[0].type === types_1.PublicationType.STORY
                    ? i18n.get('PARTICIPATION_SECTION_STORY', {
                        quantity: invite.totalMessages
                    })
                    : i18n.get('PARTICIPATION_SECTION_POST', {
                        quantity: invite.totalMessages
                    }),
            React.createElement("div", { style: { gridArea: 'time', whiteSpace: 'pre-wrap' } }, statusText)));
    }
}
exports.default = InviteDetailHeader;
