import { BackButton } from 'apps/influencers/Earnings/components';
import DateSelect from 'modules/legacy/js/components/date-select';
import GenderSelector from '../../../components/gender-selector';
import UploadImage from '../../../components/upload-image';
import { Step } from 'apps/influencers/Earnings/types';
import moment from 'moment-timezone';
import { Button } from 'ui-library';
import React from 'react';
import { get, isUndefined } from 'lodash';
import i18n from 'i18n';
import { FormInput } from 'apps/shared/components';
import {
  MAX_LENGTH_FOR_PHONE_NUMBER,
  LENGTH_CODE_PHONE_NUMBER
} from 'config/app';

export default function BankAccountForm(props) {
  const adminView = get(props, 'adminView', false);
  const {
    extraClass,
    onChangeInput,
    justLecture,
    onChangeGender,
    loading,
    isModal,
    buttonState,
    doValidations,
    onChangeDate,
    onChangeImage,
    bankAccount,
    onChangeStep,
    showErrors,
    getErrors,
    updatedfields
  } = props;
  const {
    fullName,
    personalIdFront,
    personalIdBack,
    date,
    address,
    personalIncomeTaxCode,
    personalIdNumber,
    bankName,
    branchAccount,
    branchName,
    accountName,
    gender,
    phoneNumber
  } = bankAccount;

  let mode = 'new';
  if (justLecture) {
    mode = 'update';
  }

  let dateString = '';
  if (justLecture) {
    dateString = moment(date.split('T')[0]).format('MM/DD/YYYY');
  }

  const fullNameError = getErrors('fullName');
  const dateError = getErrors('date');
  const addressError = getErrors('address');
  const phoneNumberError = getErrors('phoneNumber');
  const personalIdFrontError = getErrors('personalIdFront');
  const personalIdBackError = getErrors('personalIdBack');
  const personalIncomeTaxCodeError = getErrors('personalIncomeTaxCode');
  const personalIdNumberError = getErrors('personalIdNumber');
  const bankNameError = getErrors('bankName');
  const branchAccountError = getErrors('branchAccount');
  const branchNameError = getErrors('branchName');
  const accountNameError = getErrors('accountName');

  const imageChange = (id, img) => {
    onChangeImage(id, img.url, mode);
  };

  const inputChange = (event, limit = 100) => {
    onChangeInput(event, mode, limit);
  };

  const genderChange = (event, index) => {
    onChangeGender(index, mode);
  };

  const changeDate = date => {
    onChangeDate(date, mode);
  };

  const getValue = field => {
    return isUndefined(updatedfields[field])
      ? bankAccount[field]
      : updatedfields[field];
  };

  const hasToUpdate = !Object.keys(updatedfields).length;

  const display_personalIdFront = getValue('personalIdFront');
  const display_personalIdBack = getValue('personalIdBack');
  const display_fullname = getValue('fullName');
  const display_address = getValue('address');
  const display_phoneNumber = getValue('phoneNumber');
  const display_personalIdNumber = getValue('personalIdNumber');
  const display_personalIncomeTaxCode = getValue('personalIncomeTaxCode');
  const display_bankName = getValue('bankName');
  const display_branchName = getValue('branchName');
  const display_branchAccount = getValue('branchAccount');
  const display_accountName = getValue('accountName');
  const display_gender = getValue('gender');

  return (
    <div className={extraClass}>
      <div className='vf-simple-form-no-margin'>
        {!justLecture && (
          <span className='vf-simple-form-label-info'>
            {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_PENDING_FOR_PAYMENT')}
          </span>
        )}
        <h4 className='vf-simple-form-label'>
          {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_ACCOUNT_HOLDER_LABEL')}
        </h4>
        <div className='vf-simple-form-inner-row'>
          <div className='vf-simple-form-group col-md-6'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_FULL_NAME_LABEL')}
            </span>
            <FormInput
              id='name-input'
              name='fullName'
              autoComplete='off'
              hasError={showErrors && fullNameError !== ''}
              onChange={inputChange}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_FULL_NAME_PLACEHOLDER'
              )}
              value={display_fullname}
              disabled={adminView}
            />
            {showErrors && fullNameError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {fullNameError}
              </div>
            )}
          </div>
          <div className='vf-simple-form-group col-md-2'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_GENDER_LABEL')}
            </span>
            <GenderSelector
              onChange={genderChange}
              selected={display_gender}
              disabled={adminView}
            />
          </div>
          <div className='vf-simple-form-group col-md-4'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_BIRTH_DATE_LABEL')}
            </span>
            {!adminView && (
              <DateSelect
                initialDate={date}
                onChange={changeDate}
                finalYearGap={1}
              />
            )}
            {showErrors && dateError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {dateError}
              </div>
            )}
            {adminView && <span>{dateString}</span>}
          </div>
        </div>
        <div className='vf-simple-form-inner-row'>
          <div className='vf-simple-form-group col-md-7'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_ADDRESS_LABEL')}
            </span>
            <FormInput
              id='address-input'
              name='address'
              autoComplete='off'
              hasError={showErrors && addressError !== ''}
              onChange={inputChange}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_ADDRESS_PLACEHOLDER'
              )}
              value={display_address}
              disabled={adminView}
            />
            {showErrors && addressError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {addressError}
              </div>
            )}
          </div>
          <div className='vf-simple-form-group col-md-5'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_PHONE_NUMBER_LABEL')}
            </span>
            <FormInput
              id='phoneNumber-input'
              name='phoneNumber'
              autoComplete='off'
              hasError={showErrors && phoneNumberError !== ''}
              onChange={event => {
                inputChange(
                  event,
                  MAX_LENGTH_FOR_PHONE_NUMBER + LENGTH_CODE_PHONE_NUMBER
                );
              }}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_PHONE_NUMBER_PLACEHOLDER'
              )}
              value={display_phoneNumber}
              disabled={adminView}
              type='text'
              pattern='[0-9]*'
            />
            {showErrors && phoneNumberError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {phoneNumberError}
              </div>
            )}
          </div>
        </div>
        <div className='vf-simple-form-inner-row'>
          <div className='vf-simple-form-group col-md-7'>
            <span className='vf-simple-form-group-label'>
              {i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_PERSONAL_ID_NUMBER_LABEL'
              )}
            </span>
            <FormInput
              id='personalIdNumber-input'
              name='personalIdNumber'
              autoComplete='off'
              hasError={false}
              onChange={event => {
                inputChange(event, 30);
              }}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_PERSONAL_ID_NUMBER_PLACEHOLDER'
              )}
              value={display_personalIdNumber}
              disabled={adminView}
              type='text'
              pattern='[0-9]*'
              hasError={showErrors && personalIdNumberError !== ''}
            />
            {showErrors && personalIdNumberError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {personalIdNumberError}
              </div>
            )}
          </div>
          <div className='vf-simple-form-group col-md-5'>
            <span className='vf-simple-form-group-label'>
              {i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_PERSONAL_TAX_CODE_LABEL'
              )}
            </span>
            <FormInput
              id='personalIncomeTaxCode-input'
              name='personalIncomeTaxCode'
              autoComplete='off'
              onChange={event => {
                inputChange(event, 30);
              }}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_PERSONAL_TAX_CODE_PLACEHOLDER'
              )}
              value={display_personalIncomeTaxCode}
              disabled={adminView}
              type='text'
              pattern='[0-9]*'
              hasError={showErrors && personalIncomeTaxCodeError !== ''}
            />
            {showErrors && personalIncomeTaxCodeError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {personalIncomeTaxCodeError}
              </div>
            )}
          </div>
        </div>
        {(!adminView || display_personalIdFront) && (
          <div className='vf-simple-form-inner-row'>
            <div className='vf-simple-form-group col-md-6'>
              <span className='vf-simple-form-group-label'>
                {i18n.get(
                  'INF_SETTINGS_MONEY_DEPOSITS_FORM_PERSONAL_ID_FRONT_PHOTO_LABEL'
                )}
              </span>
              <div className='flex'>
                {!adminView && (
                  <UploadImage
                    onChange={img => imageChange('personalIdFront', img)}
                    label={
                      display_personalIdFront
                        ? i18n.get(
                            'INF_SETTINGS_MONEY_DEPOSITS_FORM_IMAGE_REPLACE'
                          )
                        : i18n.get(
                            'INF_SETTINGS_MONEY_DEPOSITS_FORM_IMAGE_UPLOAD'
                          )
                    }
                    type='BankId'
                    className='vf-upload-image-small'
                    disabled={false}
                  />
                )}

                {display_personalIdFront && (
                  <a
                    href={`${display_personalIdFront}`}
                    target='_blank'
                    className='width100 margin-left-1x'
                  >
                    Link
                  </a>
                )}
              </div>
              {showErrors && personalIdFrontError !== '' && (
                <div className='col vf-text-danger margin-top-1x'>
                  {personalIdFrontError}
                </div>
              )}
            </div>
            <div className='vf-simple-form-group col-md-6'>
              <span className='vf-simple-form-group-label'>
                {i18n.get(
                  'INF_SETTINGS_MONEY_DEPOSITS_FORM_PERSONAL_ID_BACK_PHOTO_LABEL'
                )}
              </span>
              <div className='flex'>
                {!adminView && (
                  <UploadImage
                    onChange={img => imageChange('personalIdBack', img)}
                    label={
                      display_personalIdBack
                        ? i18n.get(
                            'INF_SETTINGS_MONEY_DEPOSITS_FORM_IMAGE_REPLACE'
                          )
                        : i18n.get(
                            'INF_SETTINGS_MONEY_DEPOSITS_FORM_IMAGE_UPLOAD'
                          )
                    }
                    type='BankId'
                    className='vf-upload-image-small'
                  />
                )}
                {display_personalIdBack && (
                  <a
                    href={`${display_personalIdBack}`}
                    target='_blank'
                    className='width100 margin-left-1x'
                  >
                    Link
                  </a>
                )}
              </div>
              {showErrors && personalIdBackError !== '' && (
                <div className='col vf-text-danger margin-top-1x'>
                  {personalIdBackError}
                </div>
              )}
            </div>
          </div>
        )}
        <h4 className='vf-simple-form-label'>
          {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_BANK_INFORMATION_LABEL')}
        </h4>
        <div className='vf-simple-form-inner-row'>
          <div className='vf-simple-form-group col-md-6'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_BANK_NAME_LABEL')}
            </span>
            <FormInput
              id='bankName-input'
              name='bankName'
              autoComplete='off'
              hasError={showErrors && bankNameError !== ''}
              onChange={inputChange}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_BANK_NAME_PLACEHOLDER'
              )}
              value={display_bankName}
              disabled={adminView}
            />
            {showErrors && bankNameError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {bankNameError}
              </div>
            )}
          </div>
          <div className='vf-simple-form-group col-md-6'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_BRANCH_NAME_LABEL')}
            </span>
            <FormInput
              id='branchName-input'
              name='branchName'
              autoComplete='off'
              hasError={showErrors && branchNameError !== ''}
              onChange={inputChange}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_BRANCH_NAME_PLACEHOLDER'
              )}
              value={display_branchName}
              disabled={adminView}
            />
            {showErrors && branchNameError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {branchNameError}
              </div>
            )}
          </div>
        </div>
        <div className='vf-simple-form-inner-row'>
          <div className='vf-simple-form-group col-md-7'>
            <span className='vf-simple-form-group-label'>
              {i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_ACCOUNT_NUMBER_LABEL'
              )}
            </span>
            <FormInput
              id='branchAccount-input'
              name='branchAccount'
              autoComplete='off'
              hasError={showErrors && branchAccountError !== ''}
              onChange={event => {
                inputChange(event, 30);
              }}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_ACCOUNT_NUMBER_PLACEHOLDER'
              )}
              value={display_branchAccount}
              disabled={adminView}
              type='text'
              pattern='[0-9]*'
            />
            {showErrors && branchAccountError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {branchAccountError}
              </div>
            )}
          </div>
          <div className='vf-simple-form-group col-md-5'>
            <span className='vf-simple-form-group-label'>
              {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_ACCOUNT_NAME_LABEL')}
            </span>
            <FormInput
              id='accountName-input'
              name='accountName'
              autoComplete='off'
              hasError={showErrors && accountNameError !== ''}
              onChange={inputChange}
              placeholder={i18n.get(
                'INF_SETTINGS_MONEY_DEPOSITS_FORM_ACCOUNT_NAME_PLACEHOLDER'
              )}
              value={display_accountName}
              disabled={adminView}
            />
            {showErrors && accountNameError !== '' && (
              <div className='col vf-text-danger margin-top-1x'>
                {accountNameError}
              </div>
            )}
          </div>
        </div>
        {!adminView && (
          <div className='vf-simple-form-inner-row'>
            <div className='vf-simple-form-group col-md-8'>
              {!justLecture && (
                <span className='vf-simple-form-label-info'>
                  {i18n.get(
                    'INF_SETTINGS_MONEY_DEPOSITS_FORM_PENDING_FOR_PAYMENT_BOTTOM'
                  )}
                </span>
              )}
            </div>
            <div className='vf-simple-form-group col-md-4'>
              {!justLecture && !loading && (
                <Button
                  color='primary'
                  disabled={!buttonState()}
                  onClick={doValidations}
                >
                  {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FORM_BUTTON')}
                </Button>
              )}
              {!hasToUpdate && (
                <Button
                  color='primary'
                  disabled={hasToUpdate}
                  onClick={doValidations}
                  className='margin-bottom-1x'
                >
                  {i18n.get('UPDATE')}
                </Button>
              )}
              {loading && (
                <span>{i18n.get('INF_SETTINGS_MONEY_DEPOSITS_SENDING')}</span>
              )}
              {isModal && justLecture && (
                <div className='flex flex-justify-space-between'>
                  <BackButton onClick={onChangeStep} />
                  <Button
                    color='primary'
                    onClick={onChangeStep}
                    data-step={Step.SUMMARY}
                  >
                    {i18n.get('NEXT')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
