import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Textarea, Button } from 'ui-library';
import classNames from 'classnames';
import i18n from 'i18n';

import { sendVoxFeedFeedback } from 'redux/modules/shared';

import { BrandAvatar } from 'modules/shared/components';

import styles from './VoxFeedFeedbackForm.less';
import { toRateLogo } from 'assets/images';
import { getDefaultTitle } from 'config/app';

const REQUEST_ID = 'sendVoxFeedFeedback';
const FEEDBACK_TYPE = 'campaignParticipation';
const INITIAL_REACTION = null;

function mapStateToProps({ app }) {
  const request = get(app, `requests.${REQUEST_ID}`, {});
  return { request };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ sendVoxFeedFeedback }, dispatch);
}

class VoxFeedFeedbackForm extends Component {
  constructor() {
    super();

    this.state = {
      reaction: INITIAL_REACTION,
      comments: ''
    };
  }

  onSend = () => {
    const { inviteId } = this.props;
    const { reaction, comments } = this.state;
    this.props.onSent();
    this.props.sendVoxFeedFeedback({
      reaction,
      comments,
      inviteId,
      type: FEEDBACK_TYPE
    });
  };

  render = () => {
    const { reaction, comments } = this.state;
    const positiveButtonClassName = classNames('vf-row', styles.button, {
      [styles.selected]: reaction === 'like'
    });

    const negativeButtonClassName = classNames('vf-row', styles.button, {
      [styles.selected]: reaction === 'dislike'
    });

    return (
      <div className='padding-1x width-100'>
        <span>
          {i18n.get('INF_FEEDBACK_DIALOG_VOXFEED_COPY', {
            companyName: getDefaultTitle()
          })}
        </span>

        <div className='vf-row middle-xs' style={{ margin: '1rem 0' }}>
          <div className='col-xs-3 no-padding'>
            <BrandAvatar name='VoxFeed' url={toRateLogo} />
          </div>

          <div className='col col-xs-9 no-padding'>
            <h3 className='vf-text-primary vf-font-bold ellipsis'>
              {getDefaultTitle()}
            </h3>

            <div className='flex'>
              <Button
                size='sm'
                className={positiveButtonClassName}
                style={{ margin: '0 0.5rem 0 0' }}
                onClick={() => this.setState({ reaction: 'like' })}
              >
                <i className='vf-icon icon-like col-xs-1 no-padding' />
                <span className='col-xs-11 ellipsis'>
                  {i18n.get('INF_FEEDBACK_DIALOG_VOXFEED_POSTITIVE_BUTTON')}
                </span>
              </Button>

              <Button
                size='sm'
                className={negativeButtonClassName}
                style={{ margin: '0 0.5rem' }}
                onClick={() => this.setState({ reaction: 'dislike' })}
              >
                <i className='vf-icon icon-dislike col-xs-1 no-padding' />
                <span className='col-xs-11 ellipsis'>
                  {i18n.get('INF_FEEDBACK_DIALOG_VOXFEED_NEGATIVE_BUTTON')}
                </span>
              </Button>
            </div>
          </div>
        </div>

        <Textarea
          className='margin-top-1x'
          placeholder={i18n.get(
            'INF_FEEDBACK_DIALOG_VOXFEED_TEXT_AREA_PLACEHOLDER'
          )}
          value={comments}
          onChange={ev => this.setState({ comments: ev.target.value })}
        />

        <div className='flex center-xs margin-top-1x margin-bottom-Hx'>
          <Button
            color='primary'
            disabled={reaction === INITIAL_REACTION}
            data-uitest='send-feedback-button'
            onClick={this.onSend}
          >
            {i18n.get('INF_FEEDBACK_DIALOG_BUTTON')}
          </Button>
        </div>
      </div>
    );
  };
}

VoxFeedFeedbackForm.propTypes = {
  request: PropTypes.object.isRequired,
  inviteId: PropTypes.string.isRequired,
  onSent: PropTypes.func.isRequired,
  sendVoxFeedFeedback: PropTypes.func.isRequired
};

VoxFeedFeedbackForm.defaultProps = {
  request: {},
  inviteId: '',
  onSent: () => {},
  sendVoxFeedFeedback: () => {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoxFeedFeedbackForm);
