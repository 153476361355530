import i18n from 'i18n';

export default function getChatName({chat, currentUser}) {
  if (!chat) return i18n.get('CHAT_DEFAULT_TITLE_FOR_FLOATING_CHAT');

  const {influencer, brand} = chat;

  if (currentUser.role === 'advertiser') {
    return influencer.name || influencer.lastName
      ? `${influencer.name || ''} ${influencer.lastName || ''}`
      : i18n.get('CHAT_DEFAULT_TITLE_FOR_FLOATING_CHAT');
  }

  return brand.name ? brand.name : i18n.get('CHAT_DEFAULT_TITLE_FOR_FLOATING_CHAT');
}
