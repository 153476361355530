import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import styles from './BrandOption.less';
import { defaultAvatarImg } from 'assets/images';
import { Img } from 'apps/shared/components';

const Chip = ({ brand, options = [] }) => {
  const avatar = brand.image || defaultAvatarImg;

  return (
    <div className='vf-row no-margin padding-Hx flex-justify-center'>
      <div className=''>
        <Img width={96} height={96} className={styles.avatar} src={avatar} />
      </div>
      <div className='col start-xs col-xs-9 no-padding'>
        <span
          className={`vf-font-bold ellipsis width100 text-center margin-top-Hx ${styles.name}`}
        >
          {brand.name ||
            i18n.get('ADV_SETTINGS_ACCOUNT_DELETE_BRAND_WITHOUT_NAME_DEFAULT')}
        </span>
      </div>
    </div>
  );
};

Chip.propTypes = {
  brand: PropTypes.object.isRequired
};

export default Chip;
