"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const PostMiniReactions = ({ reactions }) => (React.createElement("div", { className: 'flex' },
    typeof reactions.likes === 'number' && [
        React.createElement("i", { key: 'k', className: 'vf-icon icon-heart icon-fix margin-left-1x', style: { marginRight: '2px' } }),
        i18n.getNumber(reactions.likes)
    ],
    typeof reactions.comments === 'number' && [
        React.createElement("i", { key: 'k', className: 'vf-icon icon-comment icon-fix margin-left-1x', style: { marginRight: '2px' } }),
        i18n.getNumber(reactions.comments)
    ],
    typeof reactions.views === 'number' && [
        React.createElement("i", { key: 'k', className: 'vf-icon icon-eye-1 icon-fix margin-left-1x', style: { marginRight: '2px' } }),
        i18n.getNumber(reactions.views)
    ],
    typeof reactions.shares === 'number' && [
        React.createElement("i", { key: 'k', className: 'vf-icon icon-share icon-fix margin-left-1x', style: { marginRight: '2px' } }),
        i18n.getNumber(reactions.shares)
    ]));
exports.default = PostMiniReactions;
