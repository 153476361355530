import React from 'react';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';
import i18n from 'i18n';

import {
  campaignIconLockedImg,
  campaignIconPublicationsImg,
  iconAlertBoxWarningImg,
  iconInvitesImg
} from 'assets/images';

const CustomizableAlert = createClass({
  propTypes: {
    title: PropTypes.string,
    markupContent: PropTypes.any,
    imageType: PropTypes.string
  },

  getInitialState() {
    return {
      title: '',
      markupContent: '',
      imageType: 'lock'
    };
  },

  render() {
    const ICONS = {
      lock: {
        img: campaignIconLockedImg,
        alt: i18n.get('CUSTOMIZABLE_ALERT_LOCKED_ICON_ALT_TEXT')
      },
      limit: {
        img: campaignIconPublicationsImg,
        alt: i18n.get('CUSTOMIZABLE_ALERT_LIMIT_ICON_ALT_TEXT')
      },
      warning: {
        img: iconAlertBoxWarningImg,
        alt: i18n.get('CUSTOMIZABLE_ALERT_WARNING_BLUE_ICON_ALT_TEXT')
      },
      invite: {
        img: iconInvitesImg,
        alt: i18n.get('CUSTOMIZABLE_ALERT_INVITE_ICON_ALT_TEXT')
      }
    };
    const { title, markupContent, imageType } = this.props;

    return (
      <div className='vf-confirm-participation-restriction margin-top-2x flex flex-justify-center'>
        <div className='vf-confirm-participation-restriction-icon'>
          <img alt={ICONS[imageType].alt} src={ICONS[imageType].img} />
        </div>
        <div className='vf-confirm-participation-restriction-content'>
          <span>
            <div>
              <h5>{title}</h5>
              {markupContent}
            </div>
          </span>
        </div>
        <div className='vf-clearfix' />
      </div>
    );
  }
});

export default CustomizableAlert;
