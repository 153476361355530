"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withLoading = (Component, initState) => class WithLoading extends React.Component {
    constructor() {
        super(...arguments);
        this.isUnmounting = false;
        this.state = {
            isLoading: initState
        };
        this.handleSetLoading = (isLoading, callback) => {
            if (this.isUnmounting)
                return;
            this.setState({ isLoading }, callback);
        };
    }
    componentWillUnmount() {
        this.isUnmounting = true;
    }
    render() {
        return (React.createElement(Component, Object.assign({}, this.props, { isLoading: this.state.isLoading, setLoading: this.handleSetLoading })));
    }
};
exports.default = withLoading;
