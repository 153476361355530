import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import Reflux from 'reflux';
import { Link, withRouter } from 'react-router-dom';
import { get, isFunction, includes } from 'lodash';
import { Tab, Dropdown } from 'ui-library';
import i18n from 'i18n';
import { isWhiteLabel } from 'config/app';

import NotificationsStore from '../stores/notificationStore';
import AuthActions from '../actions/auth';
import NavActions from '../actions/nav';

import Avatar from 'apps/shared/components/Avatar/Avatar';
import NavigationTab from './navigation-tab';
import { logo } from 'assets/images';
import { openChat } from 'utils/crispActions';

const HELP_PAGE_URL = 'https://voxfeed.zendesk.com/hc/es';
const ADVERTISERS_HELP_PAGE = 'https://voxfeed.zendesk.com/hc/es';
const MAX_UNREAD_MESSAGES = 99;
const USERDATA = 'UserData';

const Navigation = createClass({
  displayName: 'Navigation',

  propTypes: {
    currentUser: PropTypes.object,
    currentAccount: PropTypes.object,
    configStore: PropTypes.object,
    dataStore: PropTypes.object,
    onLogout: PropTypes.func
  },

  mixins: [
    Reflux.listenTo(NotificationsStore, 'onNotify'),
    Reflux.listenTo(NavActions.changeView, 'onChangeView')
  ],

  getInitialState() {
    return {
      show: false,
      notificationsCount: NotificationsStore.getAll(),
      socialNetworkFailCount: 0,
      pendingInvites: 0
    };
  },

  componentDidMount() {
    this.nav = document.getElementById('mobile-nav-bar');
  },

  logoutHandler() {
    this.props.onLogout();
    AuthActions.signout();
    NavActions.changeView();
  },

  onSocialNetworkFail(count) {
    this.setState({ socialNetworkFailCount: count });
  },

  showSettings(path) {
    const userMenu = this.menu;
    userMenu.toggleDropdown();

    this.props.history.push(path);

    NavActions.changeView();
  },

  showHelp() {
    const { currentUser } = this.props;
    const url =
      currentUser.role === 'advertiser' ? ADVERTISERS_HELP_PAGE : HELP_PAGE_URL;
    window.open(url, '_blank').focus();
  },

  onNotify(count) {
    this.setState({
      notificationsCount: count
    });
  },

  onChangeView() {
    this.closeNav();
  },

  toggleNav() {
    this.nav.classList.toggle('-open');
  },

  showNav() {
    this.nav.classList.add('-open');
    window.localStorage.removeItem(USERDATA);
  },

  closeNav() {
    this.nav.classList.remove('-open');
  },

  getTabsByUserRole() {
    const { totalIncompleteSocialAccounts, totalPendingInvites, currentUser } =
      this.props;
    const { validCategory } = currentUser;
    const totalUnreadMessages = Math.min(
      this.props.totalUnreadMessages,
      MAX_UNREAD_MESSAGES
    );
    const whiteLabel = !!isWhiteLabel();

    const tabMessages = validCategory
      ? []
      : [
          <NavigationTab
            key='messages'
            linkTo='/messages'
            icon='message'
            label={i18n.get('NAVIGATION_MESSAGES')}
            badge={Math.max(totalUnreadMessages, 0)}
          />
        ];

    const tabsByRole = {
      influencer: state => [
        <NavigationTab
          key='ic-campaigns'
          linkTo='/available-campaigns'
          icon='ic-campaigns'
          label={
            validCategory
              ? i18n.get('NAVIGATION_AVAILABLE')
              : i18n.get('NAVIGATION_CAMPAIGNS')
          }
          badge={totalPendingInvites}
        />,
        <NavigationTab
          key='influencers'
          linkTo='/voxear'
          icon='influencers'
          label={
            validCategory
              ? i18n.get('NAVIGATION_IN_PROGRESS')
              : i18n.get('NAVIGATION_VOXEAR')
          }
        />,
        ...tabMessages,
        <NavigationTab
          key='settings-social'
          linkTo='/social-accounts'
          icon='email'
          label={i18n.get('NAVIGATION_ACCOUNTS')}
          badge={totalIncompleteSocialAccounts}
        />,
        <React.Fragment>
          {!validCategory && (
            <NavigationTab
              key='earnings'
              linkTo='/earnings'
              icon='earnings'
              label={i18n.get('NAVIGATION_EARNINGS')}
            />
          )}
        </React.Fragment>
      ],
      admin: [
        <NavigationTab
          key='stats'
          linkTo='/dashboard'
          icon='dashboard'
          label={i18n.get('NAVIGATION_STATS')}
        />,
        <NavigationTab
          key='search'
          linkTo='/search'
          icon='search'
          label={i18n.get('NAVIGATION_SEARCH')}
        />,
        <NavigationTab
          key='platform'
          linkTo='/moderate'
          icon='glasses'
          label={i18n.get('NAVIGATION_MODERATE')}
        />,
        <NavigationTab
          key='payments'
          linkTo='/payments'
          icon='earnings'
          label={i18n.get('NAVIGATION_PAYMENTS')}
        />,
        <NavigationTab
          key='console'
          linkTo='/console'
          icon='settings'
          label={i18n.get('NAVIGATION_CONSOLE')}
        />
      ]
    };

    let tabs = [];
    const permissions = this.props.currentUser.permissions || [];
    if (
      this.props.currentUser.role === 'admin' &&
      permissions.indexOf('super-admin') === -1
    ) {
      if (permissions.indexOf('kpis') > -1) {
        tabs.push(
          <NavigationTab
            key='stats'
            linkTo='/dashboard'
            icon='dashboard'
            label={i18n.get('NAVIGATION_STATS')}
          />
        );
      }
      if (permissions.indexOf('search-user') > -1) {
        tabs.push(
          <NavigationTab
            key='search'
            linkTo='/search'
            icon='search'
            label={i18n.get('NAVIGATION_SEARCH')}
          />
        );
      }
      if (permissions.indexOf('moderate') > -1) {
        tabs.push(
          <NavigationTab
            key='platform'
            linkTo='/moderate/stats'
            icon='glasses'
            label={i18n.get('NAVIGATION_MODERATE')}
          />
        );
      }
      if (permissions.indexOf('payments') > -1) {
        tabs.push(
          <NavigationTab
            key='payments'
            linkTo='/payments'
            icon='earnings'
            label={i18n.get('NAVIGATION_PAYMENTS')}
          />
        );
      }
      if (
        permissions.indexOf('console-publication') > -1 ||
        permissions.indexOf('console-participations') > -1 ||
        permissions.indexOf('console-admin') > -1
      ) {
        tabs.push(
          <NavigationTab
            key='console'
            linkTo='/console'
            icon='settings'
            label={i18n.get('NAVIGATION_CONSOLE')}
          />
        );
      }
    } else {
      tabs = tabsByRole[this.props.currentUser.role];
    }
    return isFunction(tabs) ? tabs(this.state) : tabs;
  },

  getMenu() {
    const whiteLabel = !!isWhiteLabel();
    const {
      currentUser: { validCategory }
    } = this.props;

    const paymentsOption = validCategory
      ? []
      : [
          {
            icon: 'deposit',
            label: i18n.get('NAVIGATION_MONEY'),
            roles: ['influencer'],
            path: '/settings/money'
          }
        ];

    const options = [
      {
        icon: 'user',
        label: i18n.get('NAVIGATION_ACCOUNT'),
        path: '/settings/account'
      },
      ...paymentsOption,
      {
        icon: 'info',
        label: i18n.get('NAVIGATION_ABOUT_US'),
        path: '/settings/about',
        white: 'hidden'
      }
    ];

    return options.map((option, index) => {
      if (
        option.roles &&
        !includes(option.roles, this.props.currentUser.role)
      ) {
        return <noscript key={index} />;
      }
      if (whiteLabel && option.white && option.white == 'hidden') {
        return <noscript key={index} />;
      }
      return (
        <li key={index} onClick={this.showSettings.bind(null, option.path)}>
          <i className={`vf-icon icon-${option.icon}`} /> {option.label}
        </li>
      );
    });
  },

  render() {
    const { currentUser } = this.props;
    const avatar = get(currentUser, 'profileImage');

    const classes = 'vf-navigation flex flex-stretch';
    const _isWhiteLabel = !!isWhiteLabel();
    return (
      <div className={classes} onClick={this.closeNav} id='mobile-nav-bar'>
        <div className='menu-container flex-column flex-stretch'>
          <Link
            to={`/${currentUser.role}`}
            className='vf-navigation-logo-container'
            onClick={this.closeNav}
          >
            <div className='vf-navigation-logo flex'>
              {!_isWhiteLabel && (
                <div className='vf-navigation-logo-icon icon-voxfeed' />
              )}
              {_isWhiteLabel && <img src={logo} alt='brand logotype' />}
            </div>
          </Link>

          <div className='tabs' style={{ height: '450px', overflowY: 'auto' }}>
            {this.getTabsByUserRole()}
          </div>

          <div className='bottom-fixed' style={{ marginTop: 'auto' }}>
            <Tab icon='help' label='' onClick={this.showHelp} />

            <div className='width100 flex center-xs padding-Hx'>
              <div
                onClick={openChat}
                className='width100 intercom-launcher'
                title={i18n.get('INTERCOM_LAUNCHER')}
              >
                <i
                  className='vf-icon icon-intercom vf-font-bold vf-text-white'
                  style={{ fontSize: '1.75rem', lineHeight: '1.75rem' }}
                />
              </div>
            </div>

            <div className='bottom-fixed__user'>
              <Dropdown
                title={<Avatar style='circle' image={avatar} />}
                position='top'
                ref={menu => (this.menu = menu)}
                showDropdownExpandedIndicator={false}
              >
                <div className='flex between-xs bottom-xs margin-vertical-1x margin-horizontal-2x'>
                  <div>
                    <b>{currentUser.name || 'Bienvenido'}</b>
                  </div>
                  <div>
                    <a
                      className='vf-navigation-logout vf-icon icon-logout vf-pull-right vf-clickable'
                      onClick={this.logoutHandler}
                    />
                  </div>
                </div>

                <div className='divider' />

                <ul className='vf-clear-ul'>{this.getMenu()}</ul>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default withRouter(Navigation);
