export default function preventParentScroll(ev, childNode) {
  const {scrollTop, scrollHeight} = childNode;
  const {height} = childNode.getBoundingClientRect();
  const delta = ev.nativeEvent.wheelDelta;
  const didScrollUp = delta > 0;
  const didScrollBelowBottom = !didScrollUp && -delta > scrollHeight - height - scrollTop;
  const didScrollAboveTop = didScrollUp && delta > scrollTop;

  if (didScrollBelowBottom) {
    childNode.scrollTop = scrollHeight;
    return preventScroll(ev);
  } else if (didScrollAboveTop) {
    childNode.scrollTop = 0;
    return preventScroll(ev);
  }
}

function preventScroll(ev) {
  ev.stopPropagation();
  ev.preventDefault();
  ev.returnValue = false;
  return false;
}
