"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const moment = require("moment");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const styles = require("./RenderBadges.less");
const components_1 = require("apps/shared/components");
const RenderBadges = ({ history, campaign, products, vox = false, setNumberOfPost, AvailableParticipation }) => {
    if (!vox) {
        if (AvailableParticipation.length === 1) {
            const { socialNetwork, username } = AvailableParticipation[0];
            return (React.createElement("div", { key: socialNetwork, className: classnames_1.default(styles.img, { [styles.imgVox]: vox }) },
                React.createElement(components_1.SocialBadgeSM, { socialNetwork: socialNetwork }),
                React.createElement("span", { className: vox ? styles.active : 'vf-text-white margin-left-Hx', style: { lineHeight: 1 } }, username || 'User')));
        }
        else if (AvailableParticipation.length > 1) {
            const totalSocialNetworks = lodash_1.countBy(AvailableParticipation, 'socialNetwork');
            const arrayOfNetworks = Object.entries(totalSocialNetworks);
            const networks = arrayOfNetworks.map(item => (React.createElement("div", { className: styles.badge, key: item[0] },
                React.createElement(components_1.SocialBadgeSM, { socialNetwork: item[0] }),
                React.createElement("div", { className: styles.counting }, item[1]))));
            return React.createElement("div", { className: styles.bcontainer }, networks);
        }
    }
    else {
        return (React.createElement(React.Fragment, null, campaign &&
            campaign.map(item => (React.createElement("div", { key: item.socialNetwork },
                setNumberOfPost(campaign.length),
                history && (React.createElement("div", { className: styles.terminated },
                    React.createElement("p", null, i18n.get('INF_VOXEAR_CAMPAIGN_LITE_CARD_INVITE_STATUS_COMPLETED_PAYED')),
                    React.createElement("p", { className: styles.date }, moment(item.availableParticipations).format('YYYY/MM/DD')))),
                React.createElement("div", { className: styles.voxContainer },
                    React.createElement("div", { className: classnames_1.default(styles.img, { [styles.imgVox]: vox }) },
                        React.createElement(components_1.SocialBadgeSM, { socialNetwork: item.socialNetwork }),
                        React.createElement("span", { style: { lineHeight: 1 }, className: vox ? styles.active : 'vf-text-white margin-left-Hx' }, item.username || 'User')),
                    Boolean(products) && !history && (React.createElement("div", { className: styles.rewards },
                        React.createElement("i", { className: 'vf-icon icon-fix icon-bag' }),
                        React.createElement("p", { className: styles.currency }, `+ ${products}`)))))))));
    }
};
exports.default = RenderBadges;
