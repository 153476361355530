"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const ui_library_1 = require("ui-library");
const moment = require("moment-timezone");
const i18n = require("i18n");
const app_1 = require("config/app");
const OLDEST_YEAR = 1905;
const CURRENT_YEAR = new Date().getFullYear();
const DEFAULT_STATE = {
    day_input: -1,
    month_input: -1,
    year_input: -1,
    day: null,
    month: null,
    year: null,
    inicialized: false
};
const yearOptions = lodash_1.range(CURRENT_YEAR, OLDEST_YEAR, -1);
const DateSelect = props => {
    const [dayInput, setDayInput] = React.useState(0);
    const [monthInput, setMonthInput] = React.useState(0);
    const [yearInput, setYearInput] = React.useState(0);
    const [day, setDay] = React.useState(0);
    const [month, setMonth] = React.useState(0);
    const [year, setYear] = React.useState(0);
    const [inicialized, setInicialized] = React.useState(false);
    const { initialDate, className, disabled = false, finalYearGap = 0 } = props;
    const updateState = (newState = DEFAULT_STATE) => {
        const { day_input, month_input, year_input, day: newDay, month: newMonth, year: newYear, inicialized: newInicialized } = newState;
        setDayInput(day_input);
        setMonthInput(month_input);
        setYearInput(year_input + 1);
        setDay(newDay);
        setMonth(newMonth);
        setYear(newYear);
        setInicialized(newInicialized);
    };
    const getInitialYearIndex = initialYear => {
        let index = 0;
        while (yearOptions[index] && yearOptions[index] !== initialYear) {
            index++;
        }
        return index;
    };
    const initState = () => {
        if (!initialDate)
            return updateState();
        const dateWithoutTime = initialDate.split('T')[0];
        const date = moment(dateWithoutTime).toDate();
        const initialYearIndex = getInitialYearIndex(date.getFullYear());
        updateState({
            day_input: date.getDate() - 1,
            month_input: date.getMonth(),
            year_input: initialYearIndex - 1,
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
            inicialized: true
        });
    };
    const dateChange = ({ day, month, year }) => {
        const { onChange } = props;
        const isLeapYear = year ? year % 4 === 0 : false;
        let newDay = day;
        if (month) {
            const { months } = app_1.getStatics();
            const lastMonthDay = month === 1 ? (isLeapYear ? 29 : 28) : months[month].days;
            if (day > lastMonthDay) {
                setDayInput(() => Number(lastMonthDay - 1));
                setDay(() => lastMonthDay);
                newDay = lastMonthDay;
            }
        }
        if (!(year !== null && month !== null && day !== null)) {
            return;
        }
        const newDate = new Date(year, month, newDay);
        onChange(newDate);
    };
    const onDayChange = (_, index, value) => {
        const newDay = parseInt(value, 10);
        setDayInput(index);
        setDay(newDay);
        dateChange({ day: newDay, month, year });
    };
    const onMonthChange = (_, index) => {
        const newMonth = parseInt(index, 10);
        setMonthInput(index);
        setMonth(newMonth);
        dateChange({ day, month: newMonth, year });
    };
    const onYearChange = (_, index, value) => {
        const newYear = parseInt(value, 10);
        setYearInput(index);
        setYear(newYear);
        dateChange({ day, month, year: newYear });
    };
    const buildMaxDays = () => {
        let x;
        const days = [];
        for (x = 1; x <= 31; x++)
            days.push(x);
        return days;
    };
    const buildMonths = () => {
        const { months } = app_1.getStatics();
        return months.map(item => item.name);
    };
    React.useEffect(() => {
        initState();
    }, []);
    React.useEffect(() => {
        if (inicialized || !initialDate)
            return;
        initState();
    }, [initialDate]);
    const dayOptions = buildMaxDays();
    const monthOptions = buildMonths();
    const selectsClassName = `${className} margin-right-Hx`;
    const yearFiltered = yearOptions.slice(finalYearGap, yearOptions.length);
    return (React.createElement("div", { className: 'vf-dateselect vf-clearfix' },
        React.createElement(ui_library_1.Select, { id: 'day-select', value: dayInput, disabled: disabled, options: dayOptions, onChange: onDayChange, className: selectsClassName, title: i18n.get('DATE_SELECT_DAY') }),
        React.createElement(ui_library_1.Select, { id: 'month-select', value: monthInput, disabled: disabled, options: monthOptions, onChange: onMonthChange, className: selectsClassName, title: i18n.get('DATE_SELECT_MONTH') }),
        React.createElement(ui_library_1.Select, { id: 'year-select', value: yearInput, disabled: disabled, options: yearFiltered, onChange: onYearChange, className: selectsClassName, title: i18n.get('DATE_SELECT_YEAR') })));
};
exports.default = DateSelect;
