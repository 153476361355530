"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles = require("./FillBar.less");
class Fillbar extends React.PureComponent {
    render() {
        const { color, label, percentage } = this.props;
        return (React.createElement("div", { className: styles.container },
            React.createElement("span", { className: styles.label }, label),
            React.createElement("div", { className: `flex ${styles.chart}` },
                React.createElement("span", { className: `margin-right-Hx ${styles.percentage}` },
                    percentage,
                    "%"),
                React.createElement("div", { className: styles.total },
                    React.createElement("div", { className: styles.progress, style: {
                            width: `${percentage}%`,
                            backgroundColor: color
                        } })))));
    }
}
Fillbar.defaultProps = {
    percertage: 0
};
exports.default = Fillbar;
