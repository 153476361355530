import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

const RadioDescription = createClass({
  propTypes: {
    title: PropTypes.any,
    details: PropTypes.any,
    description: PropTypes.any,
    type: PropTypes.oneOf(['radio', 'checkbox']),
    active: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  },

  getDefaultProps() {
    return {
      type: 'radio',
      active: false,
      disabled: false
    };
  },

  handleClick() {
    if (this.props.onClick && !this.props.disabled) this.props.onClick();
  },

  getContainerClassNames() {
    const { disabled } = this.props;

    return [
      'vf-input-description',
      'vf-clickable',
      disabled ? '-disabled' : null
    ].join(' ');
  },

  getTitleClassNames() {
    const { highlighted, disabled } = this.props;

    return [
      disabled
        ? 'vf-input-description-description'
        : 'vf-input-description-title',
      highlighted ? 'vf-text-primary' : null
    ].join(' ');
  },

  render() {
    const { title, description, type, active, disabled } = this.props;

    return (
      <div className={this.getContainerClassNames()} onClick={this.handleClick}>
        <input
          className='vf-input-description-input'
          type={type}
          checked={active}
          readOnly
          disabled={disabled}
        />
        <div className='vf-input-description-body'>
          <div className={this.getTitleClassNames()}>{title}</div>

          <div className='vf-input-description-description'>{description}</div>
        </div>
      </div>
    );
  }
});

export default RadioDescription;
