"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Step = exports.PaymentOptions = void 0;
var PaymentOptions;
(function (PaymentOptions) {
    PaymentOptions["PAYPAL"] = "paypal";
    PaymentOptions["PAYONEER"] = "payoneer";
    PaymentOptions["TRANSFER"] = "transfer";
    PaymentOptions["EXTERNAL"] = "external";
})(PaymentOptions = exports.PaymentOptions || (exports.PaymentOptions = {}));
var Step;
(function (Step) {
    Step["CHOICE_METHOD"] = "CHOICE_METHOD";
    Step["VALIDATE_INFO"] = "VALIDATE_INFO";
    Step["SUMMARY"] = "SUMMARY";
})(Step = exports.Step || (exports.Step = {}));
