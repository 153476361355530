import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { range } from 'lodash';
import styles from './RateSelector.less';

const RATE_RANGE_START = 1;
const RATE_RANGE_END = 6;

export default class RateSelector extends Component {
  static propTypes = {
    rate: PropTypes.number,
    readOnly: PropTypes.bool,
    transparent: PropTypes.bool,
    onSelect: PropTypes.func,
    iconStyles: PropTypes.object,
    type: PropTypes.oneOf(['primary', 'gold'])
  };

  static defaultProps = {
    rate: 0,
    readOnly: false,
    transparent: false,
    type: 'primary',
    iconStyles: {},
    onSelect: null
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRate: props.rate,
      hoverRate: props.rate,
      isUserHovering: false,
      hasUserSelectRate: props.readOnly
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      selectedRate: nextProps.rate,
      hoverRate: nextProps.rate
    });
  }

  onRateHover(rate) {
    this.setState({
      isUserHovering: true,
      hoverRate: rate
    });
  }

  onRateLeave() {
    this.setState({
      hoverRate: this.state.selectedRate,
      isUserHovering: false
    });
  }

  onRateSelect(rate) {
    const { onSelect } = this.props;

    if (onSelect) onSelect(rate);

    this.setState({
      selectedRate: rate,
      hoverRate: rate,
      hasUserSelectRate: true
    });
  }

  renderRate = () => {
    const { readOnly, type, iconStyles } = this.props;
    const { hoverRate, hasUserSelectRate, isUserHovering } = this.state;

    return range(RATE_RANGE_START, RATE_RANGE_END).map(rate => {
      const className = classNames('vf-icon icon-star', {
        'vf-clickable': !readOnly,
        [styles.highlighted]: !readOnly && isUserHovering && rate <= hoverRate,
        [styles[`active-${type}`]]:
          !isUserHovering && hasUserSelectRate && rate <= hoverRate
      });

      return (
        <i
          key={rate}
          style={iconStyles}
          datatype={hoverRate}
          className={className}
          onMouseEnter={() => !readOnly && this.onRateHover(rate)}
          onClick={() => !readOnly && this.onRateSelect(rate)}
        />
      );
    });
  };

  render = () => {
    const { transparent, iconStyles, rate, ...other } = this.props;

    const { readOnly, isUserHovering } = this.state;

    const containerClassName = classNames(styles.container, {
      [styles.hovered]: !readOnly && isUserHovering,
      [styles.transparent]: transparent
    });

    return (
      <div
        className={containerClassName}
        onMouseLeave={() => !this.props.readOnly && this.onRateLeave()}
        {...other}
      >
        {this.renderRate()}
      </div>
    );
  };
}
