"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_datepicker_1 = require("react-datepicker");
const i18n = require("i18n");
const initialLocale_1 = require("./initialLocale");
const datePickerTranslation_1 = require("utils/datePickerTranslation");
const components_1 = require("apps/shared/components");
const DatePickerComponent = ({ onChange, hasError }) => {
    const month = datePickerTranslation_1.default.getMonths();
    const day = datePickerTranslation_1.default.getWeekdaysShort();
    const [birthday, setBirthday] = React.useState(null);
    const handleBirthday = (date) => {
        setBirthday(date);
        onChange(date);
    };
    const lockedKeyboard = (e) => {
        e.preventDefault();
    };
    return (React.createElement(react_datepicker_1.default, { isClearable: true, showYearDropdown: true, showMonthDropdown: true, selected: birthday, maxDate: new Date(), dropdownMode: 'select', showPopperArrow: false, onChange: handleBirthday, dateFormat: 'dd MMMM yyyy', onKeyDown: lockedKeyboard, locale: initialLocale_1.default(day, month), customInput: React.createElement(components_1.DebounceInput, { value: birthday, hasError: hasError }), placeholderText: i18n.get('ADV_SETTINGS_ACCOUNT_CONTENT_BIRTH_DATE_PLACEHOLDER') }));
};
exports.default = DatePickerComponent;
