import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { NumberInput } from 'modules/shared/components';

const MoneyInput = ({ className, onChange, currency, symbol, ...others }) => (
  <div className='flex no-padding no-margin'>
    <span>{symbol}</span>
    <NumberInput
      {...others}
      className={classNames(className, 'text-center margin-horizontal-Hx')}
      onChange={onChange}
      data-uitest='money-input'
    />
    <span>{currency}</span>
  </div>
);

MoneyInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  currency: PropTypes.object
};

MoneyInput.defaultProps = {
  className: '',
  onChange: null
};

export default MoneyInput;
