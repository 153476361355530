/* eslint-disable import/prefer-default-export */
import { videos } from '../../../services/voxfeed';
import { buildAPIRequest } from '../helpers';

export const convertVideo = params =>
  buildAPIRequest({
    params,
    id: params.name,
    method: videos.convertVideo
  });
