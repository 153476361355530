const DeepLinkState = {
  linkState(path) {
    function resolvePath(obj, names) {
      if (typeof names === 'string') {
        names = names.split('.');
      }

      const lastIndex = names.length - 1;
      let current = obj;

      for (let i = 0; i < lastIndex; i++) {
        const name = names[i];
        current = current[name];
      }
      return {
        obj: current,
        name: names[lastIndex]
      };
    }

    function setPath(obj, path, value) {
      const leaf = resolvePath(obj, path);
      leaf.obj[leaf.name] = value;
    }

    function getPath(obj, path) {
      const leaf = resolvePath(obj, path);
      return leaf.obj[leaf.name];
    }

    return {
      value: getPath(this.state, path),
      requestChange: function (newValue) {
        setPath(this.state, path, newValue);
        this.forceUpdate();
      }.bind(this)
    };
  }
};

module.exports = DeepLinkState;
