"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const ui_library_1 = require("ui-library");
const ConfirmationDialog = ({ onCancelClick, onConfirm, description, confirm, isOpen, header, onClose }) => (React.createElement(CustomDialog_1.default, { size: 'sm', isOpen: isOpen, header: header, dismissable: true, onClose: onClose, content: React.createElement("div", { className: 'padding-1x' },
        React.createElement("p", { className: 'margin-bottom-3x' }, description),
        React.createElement("div", { className: 'flex' },
            React.createElement(ui_library_1.Button, { size: 'md', color: 'flat', style: { marginLeft: 'auto' }, onClick: onCancelClick }, i18n.get('CANCEL')),
            React.createElement(ui_library_1.Button, { size: 'md', color: 'danger', onClick: onConfirm }, confirm))) }));
exports.default = ConfirmationDialog;
