"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const React = require("react");
const react_media_1 = require("react-media");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const NumericFormat = require("react-number-format");
const images_1 = require("assets/images");
const i18n = require("i18n");
const types_1 = require("modules/shared/types");
const styles = require("./SocialNetworksFormUI.less");
const handleKeyPressSpace_1 = require("utils/handleKeyPressSpace");
const components_1 = require("apps/shared/components");
const SocialNetworksFormUI = ({ hanldleInputChange, networkName, errors, onSave, showSocialFormAccount, onCancel, infoUser, setNameValueDefault, setFollowersValueDefault, setLinkValueDefault, deleteAccount, setUnlinkaccount }) => {
    const handleChangeUsername = ({ target }) => {
        if (infoUser) {
            if (infoUser.username) {
                setNameValueDefault(false);
            }
        }
        hanldleInputChange('username', target.value);
    };
    const handleChangeLink = ({ target }) => {
        if (infoUser) {
            if (infoUser.link) {
                setLinkValueDefault(false);
            }
        }
        hanldleInputChange('link', target.value);
    };
    const handleChangeFollowers = ({ target }) => {
        if (infoUser) {
            if (infoUser.followers) {
                setFollowersValueDefault(false);
            }
        }
        hanldleInputChange('followers', target.value);
    };
    const usernameError = lodash_1.get(errors, 'username');
    const linkError = lodash_1.get(errors, 'link');
    const followersError = lodash_1.get(errors, 'followers');
    return (React.createElement("div", { className: styles.dialog },
        React.createElement(components_1.DialogHeader, { className: classnames_1.default(styles.header, {
                [styles.instaback]: networkName === types_1.SocialNetwork.INSTAGRAM,
                [styles.youtubeback]: networkName === types_1.SocialNetwork.YOUTUBE,
                [styles.fbkback]: networkName === types_1.SocialNetwork.FACEBOOK
            }) },
            React.createElement("img", { alt: `${networkName} icon`, className: styles.headerIcon, src: images_1.getSocialNetworkIcon(networkName) })),
        React.createElement("div", { className: classnames_1.default(styles.dialogContainer, {
                [styles.containerAccount]: showSocialFormAccount
            }) },
            showSocialFormAccount && (React.createElement(react_media_1.default, { query: '(min-width: 1025px)' },
                React.createElement("div", { className: styles.imgContainer },
                    React.createElement("img", { alt: 'Content Creator icon', className: styles.iconCreator, src: images_1.accountTypeContentCreatorImg })))),
            React.createElement("div", { className: 'vf-simple-form-group col-md-12' },
                React.createElement("div", { className: styles.labelContainer },
                    React.createElement("span", { className: styles.formLabel }, i18n.get('INF_LITE_ACCOUNTS_LINK_USERNAME')),
                    React.createElement("b", { className: styles.asterisk }, "*")),
                React.createElement(ui_library_1.InputWrapper, { className: 'lg' },
                    React.createElement("i", { className: 'vf-icon icon-ic-acounts' }),
                    React.createElement(components_1.FormInput, { hasError: !!usernameError, onKeyPress: handleKeyPressSpace_1.default, placeholder: i18n.get('INF_LITE_ACCOUNTS_WRITE_USERNAME'), className: styles.paddin, defaultValue: infoUser ? infoUser.username : '', onChange: handleChangeUsername })),
                React.createElement("span", { className: styles.errorContainer },
                    React.createElement("p", { className: styles.errorLabel }, usernameError))),
            React.createElement("div", { className: 'vf-simple-form-group col-md-12' },
                React.createElement("div", { className: styles.labelContainer },
                    React.createElement("span", { className: styles.formLabel }, i18n.get('INF_LITE_ACCOUNTS_FOLLOWERS', { type: networkName })),
                    React.createElement("b", { className: styles.asterisk }, "*")),
                React.createElement("div", null,
                    React.createElement(NumericFormat, { defaultValue: infoUser ? infoUser.followers : '', onChange: handleChangeFollowers, placeholder: i18n.get('INF_LITE_ACCOUNTS_WRITE_FOLLOWERS', {
                            type: networkName
                        }), thousandSeparator: ',', allowNegative: false, className: classnames_1.default(styles.input, {
                            [styles['input-error']]: followersError
                        }) })),
                React.createElement("span", { className: styles.errorContainer },
                    React.createElement("p", { className: styles.errorLabel }, followersError))),
            React.createElement("div", { className: 'vf-simple-form-group col-md-12' },
                React.createElement("div", { className: styles.labelContainer },
                    React.createElement("span", { className: styles.formLabel }, i18n.get('INF_LITE_ACCOUNTS_LINK_URL_PASTE')),
                    React.createElement("b", { className: styles.asterisk }, "*"),
                    React.createElement("a", { href: 'https://support.voxfeed.com/hc/en-us/articles/8547505111949', className: styles.instruction, target: '__blank' }, i18n.get('INF_LITE_ACCOUNTS_LINK_HOW'))),
                React.createElement(components_1.FormInput, { hasError: !!linkError, defaultValue: infoUser ? infoUser.link : '', onChange: handleChangeLink, placeholder: i18n.get('INF_SOCIAL_ACCOUNTS_WRITE_PROFILE') }),
                React.createElement("span", { className: styles.errorContainer },
                    React.createElement("p", { className: styles.errorLabel }, linkError))),
            React.createElement("div", { className: styles.disclaimer },
                React.createElement("i", { className: 'vf-icon icon-exclamation-triangle margin-right-Hx', style: { fontSize: '1.5rem', lineHeight: '1.5rem' } }),
                i18n.get('DISCLAIMER_PUBLIC_ACCOUNT')),
            deleteAccount && (React.createElement("div", null,
                React.createElement(ui_library_1.Button, { color: 'flat', onClick: () => setUnlinkaccount(true), className: styles.buttonUnlink }, i18n.get('INF_LITE_ACCOUNTS_UNlINK_BUTTON')))),
            React.createElement("div", { className: classnames_1.default(styles.buttonContainer, {
                    [styles.buttonContainer_modal]: !showSocialFormAccount
                }) },
                showSocialFormAccount && (React.createElement(ui_library_1.Button, { color: 'flat', className: styles.buttonCancel, onClick: onCancel }, i18n.get('INF_LITE_ACCOUNTS_CANCEL_BUTTON'))),
                React.createElement(ui_library_1.Button, { color: 'primary', className: styles.button, onClick: onSave }, i18n.get('INF_LITE_ACCOUNTS_SAVE_BUTTON'))))));
};
exports.default = SocialNetworksFormUI;
