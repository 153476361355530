"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const ui_library_1 = require("ui-library");
const images_1 = require("assets/images");
const components_1 = require("apps/shared/components");
class UploadAvatarUI extends React.Component {
    constructor() {
        super(...arguments);
        this.handleChange = evt => {
            if (this.props.loading)
                return;
            if (this.props.onChange)
                this.props.onChange(evt);
        };
    }
    render() {
        const { image, label, loading } = this.props;
        const defaultLabel = i18n.get('INF_SETTINGS_ACCOUNT_UPLOAD_BUTTON_LABEL');
        return (React.createElement("div", { className: 'vf-upload-avatar' },
            React.createElement(components_1.Img, { className: 'vf-upload-avatar-image image-cover vf-bg-gray-color', src: image || images_1.defaultAvatarImg, width: 100, height: 100 }),
            React.createElement("div", { className: 'vf-upload-avatar-upload' },
                loading ? (React.createElement(ui_library_1.Button, { disabled: true, size: 'sm', style: { borderRadius: 0 } }, i18n.get('LOADING'))) : (React.createElement(ui_library_1.Button, { className: 'vf-bg-white-light-color vf-text-gray-dark', onClick: () => this.input.click(), size: 'sm', style: { borderRadius: 0 } }, label || defaultLabel)),
                React.createElement("input", { type: 'file', accept: 'image/jpeg,image/bmp,image/png', className: 'vf-upload-avatar-input', onChange: this.handleChange, ref: ref => (this.input = ref) }))));
    }
}
exports.default = UploadAvatarUI;
