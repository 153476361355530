import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loginRequest } from 'redux/modules/shared';
import Auth from 'modules/legacy/js/views/auth-container';

function mapStateToProps({ shared }) {
  const { user, session } = shared;
  return { user, session };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loginRequest }, dispatch);
}

const AuthContainer = connect(mapStateToProps, mapDispatchToProps)(Auth);
export default AuthContainer;
