import axios from 'axios';

export function attachPublication({ id, ...data }) {
  return axios.post(`/promoted-messages/${id}/attach-publication`, data);
}

export function extractStats(data) {
  return axios.post('/publications/extract-screenshot-stats', data);
}

export function updateStatsManually({ id, ...data }) {
  return axios.post(`/publications/${id}/stats`, data);
}

export function scheduleMessage({ id, ...data }) {
  return axios.post(`/promoted-messages/${id}/schedule`, data);
}

export function getUnverifiedStats(params, cancelToken) {
  return axios.get('/publications/unverified-stats', { params, cancelToken });
}

export function verifyStats({ id, ...data }) {
  return axios.post(`/publications/${id}/verify-stats`, data);
}

export function markAsDeleted({ id }) {
  return axios.put(`/promoted_messages/${id}/post_verification`, {
    status: 'rejected'
  });
}

export function getById({ id }) {
  return axios.get(`/promoted_messages/${id}/`);
}

export function setVerification({ id, ...data }) {
  return axios.put(
    `/promoted_messages/${id}/verify-publication-manually`,
    data
  );
}

export function restoreCancelled({ id }) {
  return axios.put(`/promoted_messages/${id}/restore-canceled-publication`);
}

export function restoreDelete({ id }) {
  return axios.put(`/promoted_messages/${id}/restore-deleted-publication`);
}

export function getInstagramMetrics(id) {
  return axios.get(`/publications/${id}/stats`);
}
