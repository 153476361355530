import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Avatar from 'apps/shared/components/Avatar/Avatar';
import i18n from 'i18n';

import { stopAccountManagement } from 'redux/modules/shared';

function mapStateToProps({ shared }) {
  const { user, admin } = shared;
  return { user, admin };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ stopAccountManagement }, dispatch);
}

const InspectAccounts = createClass({
  propTypes: {
    user: PropTypes.object,
    admin: PropTypes.object,
    stopAccountManagement: PropTypes.func
  },

  render() {
    const { user, admin, stopAccountManagement } = this.props;
    const adminUser = get(admin, 'user');

    return (
      <div className='vf-inspect-accounts flex vf-inspect-admin'>
        <div className='-element -breadcrum'>
          <Avatar
            image={adminUser.profileImage}
            size='sm'
            className='vf-inline-block'
          />
          <span className='margin-left-1x'>{adminUser.name}</span>
        </div>

        <div className='-element flex-1'>
          {i18n.get('ADM_INSPECT_ACCOUNTS_SEEING_AS')}: <b>{user.email}</b>
        </div>

        <div
          className='padding-horizontal-2x back'
          onClick={() => stopAccountManagement(admin)}
        >
          <div className='flex vf-clickable'>
            <i className='icon-arrow-left' />
            <div>{i18n.get('ADM_INSPECT_ACCOUNTS_BACK_TO_ADMIN')}</div>
          </div>
        </div>
      </div>
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectAccounts);
