"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Avatar_1 = require("apps/shared/components/Avatar/Avatar");
const components_1 = require("modules/shared/components");
const CampaignAvatar = ({ campaign }) => {
    const { brandImage, name, brandName } = campaign;
    const image = brandImage ? (React.createElement(Avatar_1.default, { image: brandImage })) : (React.createElement(components_1.DefaultEntityImage, { entityName: name }));
    return (React.createElement("div", { className: 'flex', style: { maxWidth: '50%' } },
        React.createElement("div", null, image),
        React.createElement("div", { className: 'col vf-text-white margin-left-Hx width100 ellipsis' },
            React.createElement("div", { className: 'ellipsis', style: { fontSize: '1.75rem' } }, name),
            React.createElement("div", { className: 'ellipsis', style: { fontSize: '1.5rem' } }, brandName))));
};
exports.default = CampaignAvatar;
