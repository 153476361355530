"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const orgActions = require("redux/modules/organizations");
const OrganizationMenuUI_1 = require("./OrganizationMenuUI/OrganizationMenuUI");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const selectors_1 = require("modules/shared/selectors");
class OrganizationMenu extends React.PureComponent {
    componentDidMount() {
        const { getUserOrganizations, user } = this.props;
        const { _id: id } = user;
        this.setState({ loading: true });
        getUserOrganizations({ id, status: 'accepted,created' }).finally(() => this.setState({ loading: false }));
    }
    render() {
        const { acceptedOrgs, createdOrgs } = this.props;
        return (React.createElement(OrganizationMenuUI_1.default, { acceptedOrgs: acceptedOrgs, createdOrgs: createdOrgs }));
    }
}
OrganizationMenu.defaultProps = {
    acceptedOrgs: [],
    createdOrgs: []
};
const mapStateToProps = (state, props) => ({
    acceptedOrgs: selectors_1.getOrgsByUserStatus(state, 'accepted'),
    createdOrgs: selectors_1.getOrgsByUserStatus(state, 'created'),
    user: selectors_1.getCurrentUser(state)
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    getUserOrganizations: orgActions.getUserOrganizations
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(OrganizationMenu);
