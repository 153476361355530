import axios from 'axios';

export const addSocialNetworkLite = ({ ...data }) =>
  axios.post('/lite/social-networks/', data);

export const updateSocialNetworkLite = ({ id, ...data }) =>
  axios.put(`/lite/social-networks/${id}`, data);

export const getSocialNetworkLite = id =>
  axios.get(`/lite/social-networks/${id}`);

export const deleteSocialNetworkLite = id =>
  axios.delete(`/lite/social-networks/${id}`);
