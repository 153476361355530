"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirstChild = exports.FadeComponent = void 0;
const React = require("react");
const styles = require("./FadeComponent.less");
const react_transition_group_1 = require("react-transition-group");
exports.FadeComponent = ({ children, show }) => (React.createElement(react_transition_group_1.CSSTransitionGroup, { component: exports.FirstChild, transitionEnterTimeout: 500, transitionLeaveTimeout: 500, transitionName: {
        enter: styles.enter,
        enterActive: styles['enter-active'],
        leave: styles.leave,
        leaveActive: styles['leave-active']
    } }, show ? children : null));
exports.FirstChild = props => {
    const childrenArray = React.Children.toArray(props.children);
    return childrenArray[0] || null;
};
