import _ from 'lodash';
import Reflux from 'reflux';
import Favico from 'favico.js';

const Stores = {
  currentAccount: require('../stores/currentAccount')
};

module.exports = Reflux.createStore({
  init() {
    this.notificationsCount = 0;
    this.listenTo(Stores.currentAccount, this.handleNotification);
    this.initFavicon();
  },

  initFavicon() {
    try {
      this.favicon = new Favico({
        type: 'rectangle',
        animation: 'slide'
      });
    } catch (e) {
      console.error('Favicon.js fail', e);
    }
  },

  updateFaicon() {
    this.favicon && this.favicon.badge(this.notificationsCount);
  },

  getAll() {
    return this.notificationsCount;
  },

  notify(notificationsCount) {
    this.notificationsCount = notificationsCount;
    this.trigger(this.notificationsCount);
    this.updateFaicon();
  },

  handleNotification(currentAccount) {
    const notificationsCount = _.get(
      currentAccount,
      ['notifications', 'total'],
      0
    );
    if (notificationsCount !== this.notificationsCount) {
      this.notify(notificationsCount);
    }
  }
});
