import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';
import { Button, Input } from 'ui-library';
import i18n from 'i18n';

import { showAlert, resetRequest } from 'redux/modules/app';
import { fetchPlace } from 'redux/modules/shared';
import { updateUserPhone } from 'redux/modules/users';

import {
  MIN_LENTH_FOR_PHONE_NUMBER,
  MAX_LENGTH_FOR_PHONE_NUMBER,
  DIGIT_REGEX
} from 'config/app';

import styles from './UserPhoneEdition.less';

function mapStateToProps({ shared, app, entities }) {
  const { user } = shared;
  const { requests } = app;
  const requestId = `updateUserPhone_${user._id}`;
  const userPhoneUpdateRequest = requests[requestId];
  const country = get(entities, `Place.${user.country}`);

  return {
    user,
    userPhoneUpdateRequest,
    country,
    requestId
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showAlert,
      updateUserPhone,
      resetRequest,
      fetchPlace
    },
    dispatch
  );
}

class UserPhoneEdition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: '',
      loading: false,
      error: false
    };
  }

  UNSAFE_componentWillMount = () => {
    const { user, country } = this.props;
    if (user.phone) this.loadUserPhone(user.phone);
    if (!country && user.country) this.props.fetchPlace(user.country);
  };

  UNSAFE_componentWillReceiveProps = newProps => {
    const { userPhoneUpdateRequest } = newProps;
    this.checkUpdateUserPhoneRequest(userPhoneUpdateRequest);
  };

  checkUpdateUserPhoneRequest = request => {
    const requestStatus = get(request, 'status', '');
    if (this.didRequestFail(requestStatus)) this.onUpdatePhoneError();
    if (this.didRequestSucceeded(requestStatus)) {
      this.onUpdatePhoneSuccess();
    }
  };

  didRequestSucceeded = requestStatus => {
    return requestStatus === 'success';
  };

  didRequestFail = requestStatus => {
    return requestStatus === 'fail';
  };

  onUpdatePhoneSuccess = () => {
    const { country, requestId } = this.props;
    const { number } = this.state;
    const { phone } = this.buildPhoneInfo(country.phoneCode, number);

    this.props.resetRequest(requestId);
    this.props.showAlert({
      type: 'success',
      title: i18n.get('PHONE_VERIFICATION_RESEND_ALERT_SUCCESS_TITLE'),
      message: i18n.get('PHONE_VERIFICATION_RESEND_ALERT_SUCCESS_MESSAGE')
    });
    this.props.onPhoneChange(phone);
  };

  onUpdatePhoneError = () => {
    this.props.resetRequest(this.props.requestId);
    this.props.showAlert({
      type: 'error',
      title: i18n.get('PHONE_VERIFICATION_RESEND_ALERT_ERROR_TITLE'),
      message: i18n.get('PHONE_VERIFICATION_RESEND_ALERT_ERROR_MESSAGE')
    });
  };

  loadUserPhone = phone => {
    const parsedPhone = this.parsePhone(phone);
    this.setState(parsedPhone);
  };

  parsePhone = phone => {
    const response = { prefix: '', number: '' };

    if (!phone) return response;

    const components = phone.split('-');
    response.prefix = components[0] || '';
    response.number = components[1] || '';

    return response;
  };

  onChangeNumber = event => {
    const number = event.target.value;
    if (!this.isValidPhone(number)) return;
    this.setState({ number });
  };

  isValidPhone = phone => {
    return (
      this.hasJustDigits(phone) && phone.length <= MAX_LENGTH_FOR_PHONE_NUMBER
    );
  };

  hasJustDigits = text => {
    return DIGIT_REGEX.test(text);
  };

  editUserPhone = () => {
    const { user, country } = this.props;
    const { number } = this.state;
    const { phoneCode } = country;
    const { phone } = this.buildPhoneInfo(phoneCode, number);

    this.setState({ loading: true }, () => {
      this.props.updateUserPhone(user._id, phone);
    });
  };

  buildPhoneInfo = (prefix, previousPhone) => {
    const number = (previousPhone.match(/\d+/g) || []).join('');
    const phone = `${prefix}-${number}`;

    return { prefix, number, phone };
  };

  render = () => {
    const { country } = this.props;
    const { number, loading } = this.state;
    const { phoneCode } = country;
    const prefixStr = phoneCode ? `+${phoneCode}` : '...';
    const containerClasses = classNames(
      'width100 col padding-horizontal-1x',
      styles.container
    );
    const prefixClasses = classNames('flex padding-Hx', styles['prefix-input']);
    const isPhoneReadyForUpdate =
      number.length >= MIN_LENTH_FOR_PHONE_NUMBER &&
      number.length <= MAX_LENGTH_FOR_PHONE_NUMBER;

    return (
      <div className={containerClasses}>
        <div className='flex width100'>
          <div className={prefixClasses}>{prefixStr}</div>
          <Input
            className='width100 margin-left-1x'
            onChange={this.onChangeNumber}
            placeholder={i18n.get('PHONE_VERIFICATION_CHANGE_PLACEHOLDER')}
            disabled={loading}
            value={number}
          />
        </div>
        <div className='width100 no-padding vf-font-small'>
          <Button
            className='vf-btn-flat vf-text-primary'
            size='sm'
            onClick={this.editUserPhone}
            disabled={loading || !isPhoneReadyForUpdate}
          >
            {i18n.get('PHONE_VERIFICATION_CHANGE')}
          </Button>
        </div>
      </div>
    );
  };
}

UserPhoneEdition.propTypes = {
  user: PropTypes.object,
  country: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onPhoneChange: PropTypes.func,
  showAlert: PropTypes.func,
  updateUserPhone: PropTypes.func,
  resetRequest: PropTypes.func,
  userPhoneUpdateRequest: PropTypes.object,
  fetchPlace: PropTypes.func,
  requestId: PropTypes.string
};

UserPhoneEdition.defaultProps = {
  user: {},
  country: '',
  onPhoneChange: () => {},
  fetchPlace: () => {}
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPhoneEdition);
