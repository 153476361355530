import axios from 'axios';


export const create = ({id, ...data}) => {
  return axios.post(`/organizations/${id}/teams`, data);
};

export const getByOrgId = ({id, ...params}) => {
  return axios.get(`/organizations/${id}/teams`, {params});
};

export const leave = ({id}) => {
  return axios.post(`teams/${id}/leave`);
};

export const archive = ({id}) => {
  return axios.delete(`/teams/${id}`);
};

export const revokeUser = ({teamId, userId}) => {
  return axios.delete(`/teams/${teamId}/users/${userId}`);
};

export const addUser = ({teamId, userId}) => {
  return axios.post(`/teams/${teamId}/users/${userId}`);
};

export const update = ({id, ...data}) => {
  return axios.put(`/teams/${id}`, data);
};
