"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const RoleFormInputs = ({ email, password, showPassword, disabledEmail, onInputChange, onShowPassword, confirmPassword, showConfirmPassword, onShowConfirmPassword }) => (React.createElement("div", { className: 'vf-input-group' },
    React.createElement(components_1.FormLabel, { htmlFor: 'email' }, i18n.get('LOGIN_EMAIL')),
    React.createElement(ui_library_1.InputWrapper, { className: 'lg margin-bottom-2x' },
        React.createElement("i", { className: 'vf-icon icon-mail' }),
        React.createElement(components_1.DebounceInput, { type: 'email', value: email.value, autoComplete: 'nope', className: 'login-input', disabled: disabledEmail, hasError: email.hasError, onChange: event => onInputChange(event, 'email'), placeholder: i18n.get('REGISTER_EMAIL_PLACEHOLDER') })),
    React.createElement(components_1.FormLabel, { htmlFor: 'password' }, i18n.get('LOGIN_PASSWORD')),
    React.createElement(ui_library_1.InputWrapper, { className: 'lg margin-bottom-2x' },
        React.createElement("i", { className: 'vf-icon icon-locked' }),
        React.createElement(components_1.DebounceInput, { value: password.value, className: 'login-input', autoComplete: 'new-password', hasError: password.hasError, type: showPassword ? 'text' : 'password', onChange: event => onInputChange(event, 'password'), placeholder: i18n.get('REGISTER_PASSWORD_PLACEHOLDER') }),
        React.createElement("i", { className: classnames_1.default('show-password', {
                'icon-eye-disabled': showPassword,
                'icon-eye-1': !showPassword
            }), onClick: onShowPassword })),
    React.createElement(components_1.FormLabel, { htmlFor: 'password' }, i18n.get('REGISTER_CONFIRM_PASSWORD')),
    React.createElement(ui_library_1.InputWrapper, { className: 'lg' },
        React.createElement("i", { className: 'vf-icon icon-locked' }),
        React.createElement(components_1.DebounceInput, { className: 'login-input', autoComplete: 'new-password', value: confirmPassword.value, hasError: confirmPassword.hasError, type: showConfirmPassword ? 'text' : 'password', onChange: event => onInputChange(event, 'confirmPassword'), placeholder: i18n.get('REGISTER_CONFIRM_PASSWORD_PLACEHOLDER') }),
        React.createElement("i", { className: classnames_1.default('show-password', {
                'icon-eye-disabled': showConfirmPassword,
                'icon-eye-1': !showConfirmPassword
            }), onClick: onShowConfirmPassword }))));
exports.default = RoleFormInputs;
