import { get, isPlainObject } from 'lodash';
import NProgress from 'nprogress';
import i18n from 'i18n';
import { getDefaultCurrency, getDefaultSymbol } from './../../../config/app';
import cookie from 'utils/cookies';
import {
  displayAlert,
  gotoPage,
  showLoader,
  hideLoader
} from './../../../modules/legacy/js/actions/app';
import { displayOnGallery } from './../../../modules/legacy/js/actions/panel';
import { getSocialAccountsConfiguration } from './../socialAccounts';
import { shared, setLocaleHeader } from '../../../services/voxfeed';

const SHOW_MODAL = 'voxfeed/app/SHOW_MODAL';
const HIDE_MODAL = 'voxfeed/app/HIDE_MODAL';
const SHOW_ALERT = 'voxfeed/app/SHOW_ALERT';
const CHANGE_PAGE = 'voxfeed/app/CHANGE_PAGE';
const SHOW_IMAGES_GALLERY = 'voxfeed/app/SHOW_IMAGES_GALLERY';
const RESET_REQUEST = 'voxfeed/app/RESET_REQUEST';
const SEND_REQUEST = 'voxfeed/app/SEND_REQUEST';
const REQUEST_SUCCESS = 'voxfeed/app/REQUEST_SUCCESS';
const REQUEST_FAIL = 'voxfeed/app/REQUEST_FAIL';
const SHOW_POPOVER = 'voxfeed/app/SHOW_POPOVER';
const HIDE_POPOVER = 'voxfeed/app/HIDE_POPOVER';
const SHOW_LOADING = 'voxfeed/app/SHOW_LOADING';
const HIDE_LOADING = 'voxfeed/app/HIDE_LOADING';
const SET_HEADER = 'voxfeed/app/SET_HEADER';
const UNSET_HEADER = 'voxfeed/app/UNSET_HEADER';
const CHANGE_LANGUAGE = 'voxfeed/app/CHANGE_LANGUAGE';
const FETCH_LANGUAGES_SUCCESS = 'voxfeed/app/FETCH_LANGUAGES_SUCCESS';
const PARTNER_SETTINGS_SUCCESS = 'voxfeed/app/PARTNER_SETTINGS_SUCCESS';
const SYSTEM_SETTINGS_SUCCESS = 'voxfeed/app/SYSTEM_SETTINGS_SUCCESS';

const initialState = {
  modal: null,
  currentPopover: null,
  requests: {},
  header: null,
  currentLanguage: i18n.getLocale(),
  partnerSettings: null,
  systemSettings: {
    availableCurrency: ['USD', 'MXN', 'VND'],
    allowedPaymentMethods: [],
    authorizedDomains: [],
    currency: {
      symbol: getDefaultSymbol(),
      suffix: getDefaultCurrency()
    }
  }
};

NProgress.configure({ showSpinner: false, trickleRate: 0.08 });

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SHOW_MODAL:
      return {
        ...state,
        modal: payload
      };
    case HIDE_MODAL:
      return {
        ...state,
        modal: null
      };
    case SHOW_ALERT:
      return state;
    case CHANGE_PAGE:
      return state;
    case RESET_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          [payload.id]: { status: '' }
        }
      };
    case SEND_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          [payload.id]: {
            ...state.requests[payload.id],
            cancelSource: payload.cancelSource,
            status: 'loading'
          }
        }
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          [payload.id]: {
            status: 'success',
            cancelSource: payload.cancelSource,
            response: payload.data || {}
          }
        }
      };
    case REQUEST_FAIL:
      return {
        ...state,
        requests: {
          ...state.requests,
          [payload.id]: {
            status: 'fail',
            cancelSource: payload.cancelSource,
            response: payload.data
          }
        }
      };
    case SHOW_POPOVER:
      return {
        ...state,
        currentPopover: payload.id
      };
    case HIDE_POPOVER:
      return {
        ...state,
        currentPopover: null
      };
    case SHOW_LOADING:
      return state;
    case HIDE_LOADING:
      return state;
    case SET_HEADER:
      return {
        ...state,
        header: payload
      };
    case UNSET_HEADER:
      return {
        ...state,
        header: null
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        currentLanguage: payload
      };
    case FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        availableLanguages: payload
      };
    case PARTNER_SETTINGS_SUCCESS:
      return {
        ...state,
        partnerSettings: payload
      };
    case SYSTEM_SETTINGS_SUCCESS:
      const { systemSettings } = state;
      const newState = {
        ...state,
        systemSettings: { ...systemSettings, ...payload }
      };
      return newState;
    default:
      return state;
  }
}

export function showModal(modal) {
  return {
    type: SHOW_MODAL,
    payload: modal
  };
}

export function hideModal() {
  return { type: HIDE_MODAL };
}

export function showAlert(options) {
  displayAlert(options);
  return { type: SHOW_ALERT };
}

export function changePage(options) {
  gotoPage(options);
  return { type: CHANGE_PAGE };
}

export function showImagesGallery(images, startIndex) {
  displayOnGallery(images, startIndex);
  return { type: SHOW_IMAGES_GALLERY };
}

export function resetRequest(id) {
  return { type: RESET_REQUEST, payload: { id } };
}

export function sendRequestSuccess(request) {
  if (typeof document !== 'undefined') NProgress.done();

  const payload = isPlainObject(request)
    ? buildPayloadForSuccessfulRequest(request)
    : { id: request };

  return {
    payload,
    type: REQUEST_SUCCESS
  };
}

function buildPayloadForSuccessfulRequest(request) {
  return {
    id: get(request, 'id', ''),
    data: get(request, 'response.data', {})
  };
}

export function sendRequestFail({ id, response }) {
  if (typeof document !== 'undefined') NProgress.done();
  return {
    type: REQUEST_FAIL,
    payload: {
      id,
      data: get(response, 'data', {})
    }
  };
}

export function sendRequest(id, cancelSource) {
  if (typeof document !== 'undefined') NProgress.start();
  return { type: SEND_REQUEST, payload: { id, cancelSource } };
}

export function showPopover(id) {
  return { type: SHOW_POPOVER, payload: { id } };
}

export function hidePopover() {
  return { type: HIDE_POPOVER };
}

export function showLoading() {
  showLoader();
  return { type: SHOW_LOADING };
}

export function hideLoading() {
  hideLoader();
  return { type: HIDE_LOADING };
}

export function setHeader(header) {
  return { type: SET_HEADER, payload: header };
}

export function unsetHeader() {
  return { type: UNSET_HEADER };
}

export function fetchAvailableLanguages() {
  return dispatch => {
    shared
      .fetchAvailableLanguages()
      .then(res => dispatch(fetchLanguagesSuccess(res.data)));
  };
}

export function fetchLanguagesSuccess(langs) {
  return {
    type: FETCH_LANGUAGES_SUCCESS,
    payload: langs
  };
}

export function changeLanguage(locale) {
  return dispatch => {
    i18n.setLocale(locale);
    cookie.setCookie({ name: 'locale', value: locale });
    setLocaleHeader(locale);

    // TODO: for some reason, the function below gets called during testing... why?
    if (!process.env.CI) {
      dispatch(getSocialAccountsConfiguration());
    }

    dispatch(languageChangeSuccess(locale));
  };
}

function languageChangeSuccess(locale) {
  return {
    type: CHANGE_LANGUAGE,
    payload: locale
  };
}

export function fetchPartnerSettings() {
  const REQUEST_ID = 'fetchPartnerSettings';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return shared.fetchPartnerSettings().then(response =>
      dispatch({
        type: PARTNER_SETTINGS_SUCCESS,
        payload: response.data.data
      })
    );
  };
}

export function sendFirebaseToken(token) {
  const REQUEST_ID = 'fetchFirebaseToken';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return shared
      .sendFirebaseToken(token)
      .then(response => {
        sendRequestSuccess(response);
      })
      .catch(error => {
        sendRequestFail({ REQUEST_ID, error });
      });
  };
}

export function fetchSystemSettings() {
  const REQUEST_ID = 'fetchSystemSettings';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return shared.fetchSystemSettings().then(response =>
      dispatch({
        type: SYSTEM_SETTINGS_SUCCESS,
        payload: response.data
      })
    );
  };
}

export const constants = {
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_ALERT,
  CHANGE_PAGE,
  RESET_REQUEST,
  SEND_REQUEST,
  REQUEST_SUCCESS,
  REQUEST_FAIL,
  SHOW_POPOVER,
  HIDE_POPOVER,
  SHOW_LOADING,
  HIDE_LOADING,
  SET_HEADER,
  UNSET_HEADER,
  CHANGE_LANGUAGE,
  FETCH_LANGUAGES_SUCCESS
};
