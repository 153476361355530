"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const Loading = ({ active, className }) => {
    if (!active)
        return null;
    const containerClassNames = classnames_1.default('vf-spinner', className, { active });
    return (React.createElement("div", { className: containerClassNames },
        React.createElement("div", { className: 'vf-double-bounce1' }),
        React.createElement("div", { className: 'vf-double-bounce2' })));
};
exports.default = Loading;
