"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const styles = require("./CardProfile.less");
const images_1 = require("assets/images");
const types_1 = require("modules/shared/types");
const CardProfile = ({ list, type, selectProfile, handleSelectProfile }) => {
    const isSelected = selectProfile === type;
    const validType = type === types_1.ECategoryType.LITE;
    const cardType = classnames_1.default(styles.card, {
        [styles['card--lite']]: validType,
        [styles.card__selected]: isSelected,
        [styles['card__selected--lite']]: isSelected && validType
    });
    const buttonType = classnames_1.default(styles.card__button, {
        [styles['card__button--lite']]: validType
    });
    const buttonColor = validType ? 'success' : 'primary';
    const icon = validType ? images_1.logoVoxFeedLite : images_1.logoVoxFeedPro;
    return (React.createElement("div", { className: cardType, onClick: () => handleSelectProfile(type) },
        React.createElement("div", { className: styles.card__content },
            React.createElement("img", { src: icon, alt: 'Logo VoxFeed' }),
            React.createElement("p", null, i18n.get('REGISTER_JOIN_FREE'))),
        React.createElement("ul", { className: `${styles.card__list} vf-text-gray-dark` }, list.map((item, key) => (React.createElement("li", { key: `${key}-${item}` }, i18n.getHTML(item))))),
        React.createElement("div", { className: styles.card__content },
            React.createElement(ui_library_1.Button, { size: 'sm', color: buttonColor, className: buttonType, onClick: () => handleSelectProfile(type) }, i18n.get('REGISTER_PROFILE_CHOOSE_TYPE', {
                type: type.toLocaleLowerCase()
            })))));
};
exports.default = CardProfile;
