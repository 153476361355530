"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const VIDEO_TYPES = 'video/mp4,video/x-m4v,video/*';
const IMAGE_TYPES = 'image/jpeg,image/bmp,image/png';
class FileInputWrapper extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.fileInput = null;
        this.getAcceptedTypes = () => {
            const { filesType } = this.props;
            if (filesType === 'video') {
                return VIDEO_TYPES;
            }
            return IMAGE_TYPES;
        };
        this.onSelectFiles = () => {
            if (this.props.canSelectFiles)
                this.fileInput.click();
        };
        this.onFilesSelected = event => {
            const { canSelectFiles, onFilesSelected } = this.props;
            event.preventDefault();
            if (!canSelectFiles)
                return;
            const files = event.target.files || event.dataTransfer.files;
            onFilesSelected(files);
        };
        this.handleDragOver = e => {
            e.preventDefault();
            const { onDragEnter } = this.props;
            if (onDragEnter)
                onDragEnter();
        };
    }
    render() {
        const { allowMultipleFiles, children, style, className, onDragEnter, onDragLeave } = this.props;
        return (React.createElement("div", { className: className, style: style, onClick: this.onSelectFiles, onDrop: this.onFilesSelected, onDragOver: e => e.preventDefault(), onDragEnter: onDragEnter, onDragLeave: onDragLeave },
            React.createElement("input", { multiple: allowMultipleFiles, ref: input => (this.fileInput = input), type: 'file', style: { display: 'none' }, accept: this.getAcceptedTypes(), onChange: this.onFilesSelected }),
            children));
    }
}
exports.default = FileInputWrapper;
