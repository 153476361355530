"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const uuidV4 = require("uuid/v4");
const isEmpty = require("lodash/isEmpty");
const i18n = require("i18n");
const videoActions = require("redux/modules/videos");
const api_1 = require("modules/legacy/js/lib/api");
const app_1 = require("config/app");
const uploadFileAWS_1 = require("services/aws/s3/uploadFileAWS");
const lodash_1 = require("lodash");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const app_2 = require("config/app");
const VIDEO_TYPES = 'video/mp4,video/x-m4v,video/quicktime,video/*';
const IMAGE_TYPES = 'image/jpeg,image/bmp,image/png';
const MAX_IMAGE_SIZE = app_2.getMaxImageSize();
function mediaUploader(Component) {
    class Uploader extends React.PureComponent {
        constructor() {
            super(...arguments);
            this.state = {
                isLoading: false,
                errors: []
            };
            this.onFilesSelected = (files) => {
                const separatedFiles = this.separateFiles(files);
                const { valid: validFiles } = separatedFiles;
                if (!isEmpty(validFiles))
                    this.uploadFiles(validFiles);
                this.setState({
                    errors: this.getErrors(separatedFiles, files) || []
                });
            };
            this.onRemoveFile = (fileIndex) => {
                const { urls, onFilesChange } = this.props;
                const newFiles = [...urls];
                newFiles.splice(fileIndex, 1);
                onFilesChange(newFiles);
            };
            this.separateFiles = (fileList) => {
                const { mediaType, maxSizeInMB, maxFiles, urls } = this.props;
                const files = Array.from(fileList);
                const types = mediaType === 'image' ? IMAGE_TYPES : VIDEO_TYPES;
                const hasExceededMaxFiles = files.length + urls.length > maxFiles;
                const initialSeparatedFiles = {
                    valid: [],
                    invalidType: [],
                    invalidSize: []
                };
                if (hasExceededMaxFiles)
                    return initialSeparatedFiles;
                return files.reduce((result, file) => {
                    const hasValidType = file.type && types.includes(file.type);
                    if (!hasValidType) {
                        result.invalidType.push(file);
                        return result;
                    }
                    const hasValidSize = this.bytesToMb(file.size) <= maxSizeInMB;
                    if (!hasValidSize) {
                        result.invalidSize.push(file);
                        return result;
                    }
                    result.valid.push(new File([file], uuidV4(), { type: file.type }));
                    return result;
                }, initialSeparatedFiles);
            };
            this.bytesToMb = bytes => bytes / Math.pow(10, 6);
            this.uploadFiles = (files) => {
                const { mediaType } = this.props;
                this.setLoadingState(true);
                const action = mediaType === 'image' ? this.uploadSingleImage : this.uploadSingleVideo;
                Promise.all(files.map(file => action(file)))
                    .then(this.onUploadSuccess)
                    .finally(() => this.setLoadingState(false));
            };
            this.uploadSingleImage = (file) => {
                const { sizeType = 'Ad' } = this.props;
                const data = { file, type: sizeType };
                return new Promise((resolve, reject) => {
                    api_1.uploadImage(data, (err, res) => {
                        if (err)
                            return reject(err);
                        return resolve(res);
                    });
                });
            };
            this.uploadSingleVideo = (file) => {
                const { convertVideo } = this.props;
                const fileKey = uuidV4();
                const fileType = file.type;
                const url = `${app_1.amazonS3PlayerHost}${fileKey}`;
                const params = { fileKey, fileType, file };
                return uploadFileAWS_1.uploadFileAWS(params)
                    .then(response => {
                    if (lodash_1.get(response, 'status') !== 200) {
                        this.onVideoFailed();
                    }
                    else {
                        return convertVideo({ name: fileKey });
                    }
                })
                    .then(() => {
                    const vid = lodash_1.find(this.props.videos, v => v.video.name === fileKey);
                    return { data: { fileType, url, name: fileKey, id: vid.video.id } };
                })
                    .catch(error => {
                    this.onVideoFailed();
                });
            };
            this.setLoadingState = (isLoading) => {
                const { onLoading } = this.props;
                if (onLoading)
                    onLoading(isLoading);
                this.setState({ isLoading });
            };
            this.onUploadSuccess = res => {
                const { onFilesChange, urls, mediaType } = this.props;
                const uploadedUrls = res.map(({ data }) => mediaType === 'image' ? data.url : data);
                onFilesChange([...urls, ...uploadedUrls]);
            };
            this.onVideoFailed = () => {
                this.setState({
                    errors: [i18n.get('UPLOAD_VIDEO_UPLOAD_ERROR_MESSAGE')]
                });
            };
            this.getErrors = (seperatedFiles, files) => {
                const { maxFiles, maxSizeInMB, urls } = this.props;
                const { invalidType, invalidSize } = seperatedFiles;
                const hasExceededMaxFiles = files.length + urls.length > maxFiles;
                if (hasExceededMaxFiles) {
                    return [
                        i18n.get('UPLOAD_IMAGE_UPLOAD_MAX_FILES_EXCEEDED', {
                            total: maxFiles
                        })
                    ];
                }
                if (!isEmpty(invalidType)) {
                    const names = invalidType.map(file => file.name);
                    return [
                        i18n.getHTML('UPLOAD_IMAGE_UPLOAD_INCOMPATIBLE', {
                            total: names.length,
                            name: names.join(', ')
                        })
                    ];
                }
                if (!isEmpty(invalidSize)) {
                    const names = invalidSize.map(file => file.name);
                    return [
                        i18n.getHTML('UPLOAD_IMAGE_UPLOAD_EXCEEDED_SIZE', {
                            total: names.length,
                            name: names.join(', '),
                            size: maxSizeInMB
                        })
                    ];
                }
            };
        }
        render() {
            const { isLoading, errors } = this.state;
            return (React.createElement(Component, Object.assign({}, this.props, { isLoading: isLoading, errors: errors, onFilesSelected: this.onFilesSelected, onRemoveFile: this.onRemoveFile })));
        }
    }
    Uploader.defaultProps = {
        maxSizeInMB: MAX_IMAGE_SIZE
    };
    const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
        convertVideo: videoActions.convertVideo
    }, dispatch);
    const mapStatToProps = state => ({
        videos: lodash_1.get(state, 'entities.Video', {})
    });
    return react_redux_1.connect(mapStatToProps, mapDispatchToProps)(Uploader);
}
exports.default = mediaUploader;
