"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const ui_library_1 = require("ui-library");
const styles = require("./ProductsPaymentAddressInput.less");
const containers_1 = require("modules/shared/containers");
class ProductsPaymentAddressInputUI extends React.PureComponent {
    render() {
        const { address, className, error, isPristine, isValid, onChange, onSave, lite } = this.props;
        return (React.createElement("div", { className: className },
            React.createElement("div", { className: classnames_1.default('flex margin-top-1x', {
                    [styles.lite]: lite
                }) },
                React.createElement("div", { className: 'flex-1 margin-right-2x' },
                    React.createElement(containers_1.GooglePlaceInput, { value: address, onChange: onChange, onPlaceClick: onChange, placeholder: i18n.get('PAY_IN_KIND_SHIPPING_ADDRESS_PLACEHOLDER') })),
                React.createElement(ui_library_1.Button, { className: classnames_1.default('vf-font-bold', {
                        'vf-text-primary': isValid,
                        [styles.button]: lite
                    }), color: 'flat', disabled: !isValid, onClick: onSave }, i18n.get('SAVE'))),
            error && React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, error),
            !error && !isPristine && !isValid && (React.createElement("div", { className: 'vf-text-danger margin-top-Hx' }, i18n.get('PAY_IN_KIND_REQUIRED_SHIPPING_ADDRESS_MESSAGE')))));
    }
}
exports.default = ProductsPaymentAddressInputUI;
