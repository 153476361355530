"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const brands_1 = require("redux/modules/brands");
const app_1 = require("redux/modules/app");
const selectors_1 = require("modules/shared/selectors");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const Confirmation = props => {
    const { brandUser, hideModal, request } = props;
    const onRemoveSuccess = () => {
        const { showAlert, onSuccess } = props;
        if (onSuccess)
            onSuccess();
        hideModal();
        showAlert({
            type: 'success',
            message: i18n.get('ADV_BRANDS_USER_INVITE_DELETE_REMOVE_SUCCESS_ALERT_MESSAGE', {
                userEmail: brandUser.email
            })
        });
    };
    const onRemoveFail = () => {
        const { showAlert } = props;
        showAlert({
            type: 'error',
            message: i18n.get('ADV_BRANDS_USER_INVITE_DELETE_REMOVE_ERROR_ALERT_MESSAGE')
        });
    };
    const remove = () => {
        const { cancelInvitation } = props;
        const args = {
            brandUserId: brandUser.id,
            brandId: brandUser.brandId,
            email: brandUser.email
        };
        cancelInvitation(args).then(onRemoveSuccess).catch(onRemoveFail);
    };
    const isLoading = request.status === 'loading';
    const content = (React.createElement("div", { className: 'padding-2x' },
        React.createElement("h5", { className: 'vf-font-normal' },
            i18n.get('ADV_BRANDS_USER_INVITE_DELETE_DESCRIPTION'),
            ' ',
            React.createElement("b", null, brandUser.email),
            ".",
            React.createElement("p", { className: 'margin-top-Hx' }, i18n.get('ADV_BRANDS_USER_WISH_TO_CONTINUE'))),
        React.createElement("div", { className: 'flex width100 end-xs margin-top-2x' },
            React.createElement(ui_library_1.Button, { className: 'margin-right-Hx', onClick: hideModal }, i18n.get('ADV_BRANDS_USER_CLOSE')),
            React.createElement(ui_library_1.Button, { className: 'margin-right-Hx', color: 'danger', disabled: isLoading, onClick: remove }, isLoading
                ? i18n.get('ADV_BRANDS_USER_INVITE_DELETE_CANCELING_INVITE')
                : i18n.get('ADV_BRANDS_USER_INVITE_DELETE_CANCEL_INVITE')))));
    return (React.createElement(CustomDialog_1.default, { size: 'sm', header: i18n.get('ADV_BRANDS_USER_CARD_ROW_WARNING_HEADER'), content: content, onClose: hideModal }));
};
const mapStateToProps = state => ({
    request: selectors_1.getRequest(state, 'cancelBrandInvitation')
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    cancelInvitation: brands_1.cancelInvitation,
    hideModal: app_1.hideModal,
    resetRequest: app_1.resetRequest,
    sendRequest: app_1.sendRequest,
    showAlert: app_1.showAlert
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Confirmation);
