import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import LocationSelect from 'apps/shared/controllers/LocationSelect/LocationSelect';
import AudienceFilterHeader from './AudicenceFilterHeader/AudienceFilterHeader';
import splitWithLimit from 'utils/splitWithLimit';
import get from 'lodash/get';
import { Select, Checkbox } from 'ui-library';
import { FilterWrapper } from '../components';
import {
  AudienceAuthenticity,
  AudienceKeywords
} from 'apps/advertisers/InfluencerSearchFilters/controllers';

const GENDER_FILTER = gender => `audience.genders.${gender}>`;
const GENDERS = ['male', 'female'];

const AGE_RANGE_FILTER = ageRange =>
  `audience.ages.${ageRange.replace(/\s/g, '')}>`;
const AGE_RANGES = [
  '13 - 17',
  '18 - 24',
  '25 - 34',
  '35 - 44',
  '45 - 64',
  '65 +'
];

const LOCATION_FILTER = (type, location) => `audience.${type}.${location}>`;
const LOCATION_TYPES = ['countries', 'regions', 'cities'];

const AGE_FILTER_PERCENTAGE = '0.3';
const GENDER_FILTER_PERCENTAGE = '0.5';
const LOCATION_FILTER_PERCENTAGE = '0.1';
const MAJORITY_PERCENTAGES = [
  1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95
];

class AudienceFilters extends React.Component {
  MAJORITY_OPTIONS = MAJORITY_PERCENTAGES.reduce((p, c) => ({
    ...p,
    [c]: i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_MAJORITY', { percentage: c })
  }));

  getLocationKeys = () => {
    const { filters } = this.props;
    const locations = Object.keys(filters).filter(value =>
      LOCATION_TYPES.reduce(
        (p, type) => p || value.includes(`audience.${type}`),
        false
      )
    );
    return locations;
  };

  getSelectedLocation = () => {
    const locations = this.getLocationKeys();

    const selectedLocations = LOCATION_TYPES.reduce(
      (p, type) => ({ ...p, [this.changeToSingluar(type)]: [] }),
      {}
    );
    locations.forEach(audLocation => {
      const result = splitWithLimit(audLocation, '.', 2);
      const type = result[1];
      const location = result[2].slice(0, -1);
      selectedLocations[this.changeToSingluar(type)].push(location);
    });
    return selectedLocations;
  };

  getGenderValue = () => {
    const { filters } = this.props;
    return GENDERS.find(option => get(filters, GENDER_FILTER(option))) || 'all';
  };

  changeToSingluar = locationType => {
    const singluar = {
      countries: 'country',
      regions: 'region',
      cities: 'city'
    };
    return singluar[locationType];
  };

  changeToPural = locationType => {
    const pural = {
      country: 'countries',
      region: 'regions',
      city: 'cities'
    };
    return pural[locationType];
  };

  handleGenderChange = (value, percentage = GENDER_FILTER_PERCENTAGE) => {
    const { onChange } = this.props;

    const newFilter = GENDERS.reduce(
      (filter, gender) => ({
        ...filter,
        [GENDER_FILTER(gender)]: gender === value ? percentage : null
      }),
      {}
    );
    return onChange(newFilter);
  };

  handleToggleAge = (ageRange, percentage = AGE_FILTER_PERCENTAGE, reset) => {
    const { onChange } = this.props;
    const ageRangeKey = AGE_RANGE_FILTER(ageRange);
    return reset
      ? onChange(ageRangeKey, null)
      : onChange(ageRangeKey, percentage);
  };

  handleLocationChange = (
    newLocations,
    percentage = LOCATION_FILTER_PERCENTAGE
  ) => {
    const { filters, onChange } = this.props;
    const locations = Object.keys(filters).filter(value =>
      LOCATION_TYPES.reduce(
        (p, type) => p || value.includes(`audience.${type}`),
        false
      )
    );

    const newFilter = locations.reduce(
      (p, filter) => ({ ...p, [filter]: null }),
      {}
    );
    Object.keys(newLocations).forEach(singluarType => {
      newLocations[singluarType].forEach(id => {
        const puralType = this.changeToPural(singluarType);
        newFilter[LOCATION_FILTER(puralType, id)] =
          newFilter[LOCATION_FILTER(puralType, id)] === null
            ? null
            : percentage;
      });
    });
    return onChange(newFilter);
  };

  handleLocationPercentageChange = event => {
    const { onChange } = this.props;
    const location = this.getLocationKeys()[0];
    return onChange(location, event.target.value / 100);
  };

  getSelectedKeyword = () => {
    const { filters } = this.props;
    const filterKey = Object.keys(filters).find(f =>
      f.includes('audience.keywords')
    );

    if (!filterKey) return '';

    const keywordWithOperator = filterKey.split('.')[2];
    return keywordWithOperator.slice(0, keywordWithOperator.length - 1);
  };

  handleKeywordChange = (keyword, percentage) => {
    const { onChange } = this.props;
    const newFilters = this.omitKeyFromFilters('audience.keywords');

    if (percentage)
      newFilters[`audience.keywords.${keyword}>`] = percentage / 100;
    onChange(newFilters);
  };

  omitKeyFromFilters = keyToOmit => {
    const { filters } = this.props;
    return Object.keys(filters).reduce((result, key) => {
      result[key] = key.includes(keyToOmit) ? undefined : filters[key];

      return result;
    }, {});
  };

  handleAuthenticityChange = range => {
    const { onChange } = this.props;
    const newFilters = this.omitKeyFromFilters('audience.authenticity');

    newFilters['audience.authenticity>'] = range ? range.min / 100 : undefined;
    newFilters['audience.authenticity<'] = range ? range.max / 100 : undefined;

    onChange(newFilters);
  };

  render() {
    const {
      filters,
      onChange,
      availableSocialNetworks,
      hasAceptableTypes,
      isOpened,
      onRequestToggleFilters
    } = this.props;

    const genderOptions = {
      all: i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_GENDER_OPTIONS_ALL'),
      female: i18n.get(
        'ADV_INFLUENCERS_SEARCH_FILTER_GENDER_MAJORITY_OPTIONS_FEMALE'
      ),
      male: i18n.get(
        'ADV_INFLUENCERS_SEARCH_FILTER_GENDER_MAJORITY_OPTIONS_MALE'
      )
    };

    const genderValue = this.getGenderValue();
    const genderPercentage =
      genderValue === 'all'
        ? null
        : get(filters, GENDER_FILTER(genderValue), 0) * 100;
    const locationValue = this.getSelectedLocation();

    return (
      <AudienceFilterHeader
        filters={filters}
        onChange={onChange}
        isOpened={isOpened}
        hasAceptableTypes={hasAceptableTypes}
        availableSocialNetworks={availableSocialNetworks}
        onRequestToggleFilters={onRequestToggleFilters}
      >
        <FilterWrapper
          title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_GENDER_LABEL')}
          keepOpen={GENDERS.reduce(
            (p, v) => p || !!get(filters, GENDER_FILTER(v)),
            false
          )}
        >
          <Select
            title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_GENDER_DESCRIPTION')}
            options={genderOptions}
            value={genderValue}
            onChange={e => this.handleGenderChange(e.target.value)}
            buttonClassname='flex width100 flex-justify-space-between'
            className='width100'
            selectClassname='width100'
          />
          {genderValue !== 'all' && (
            <Select
              title=''
              options={this.MAJORITY_OPTIONS}
              value={genderPercentage.toFixed(0)}
              onChange={e =>
                this.handleGenderChange(genderValue, e.target.value / 100)
              }
              buttonClassname='flex width100 flex-justify-space-between'
              className='width100 padding-top-Hx'
              selectClassname='width100'
            />
          )}
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get(
            'ADV_INFLUENCERS_SEARCH_FILTER_AUDIENCE_AGE_RANGE_LABEL'
          )}
          keepOpen={AGE_RANGES.reduce(
            (p, v) => p || !!get(filters, AGE_RANGE_FILTER(v)),
            false
          )}
        >
          {AGE_RANGES.map(ageRange => (
            <div
              key={ageRange}
              className='flex flex-justify-space-between'
              style={{ padding: '2px 0', minHeight: 42 }}
            >
              <Checkbox
                checked={get(filters, AGE_RANGE_FILTER(ageRange), false)}
                onChange={() =>
                  this.handleToggleAge(
                    ageRange,
                    AGE_FILTER_PERCENTAGE,
                    get(filters, AGE_RANGE_FILTER(ageRange))
                  )
                }
              >
                {ageRange}
              </Checkbox>

              {get(filters, AGE_RANGE_FILTER(ageRange), false) && (
                <Select
                  title=''
                  options={this.MAJORITY_OPTIONS}
                  value={(
                    get(filters, AGE_RANGE_FILTER(ageRange), 0) * 100
                  ).toFixed(0)}
                  onChange={e =>
                    this.handleToggleAge(ageRange, e.target.value / 100)
                  }
                  buttonClassname='flex flex-justify-space-between'
                />
              )}
            </div>
          ))}
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_LOCATION_LABEL')}
          keepOpen={false}
        >
          <LocationSelect
            showTitle={false}
            placeholder={i18n.get(
              'ADV_INFLUENCERS_SEARCH_FILTER_LOCATION_PLACEHOLDER'
            )}
            selectedLocations={locationValue}
            onChange={this.handleLocationChange}
          />

          {(locationValue.city.length > 0 ||
            locationValue.region.length > 0 ||
            locationValue.country.length > 0) && (
            <Select
              title=''
              options={this.MAJORITY_OPTIONS}
              value={(get(filters, this.getLocationKeys()[0], 0) * 100).toFixed(
                0
              )}
              onChange={this.handleLocationPercentageChange}
              buttonClassname='flex width100 flex-justify-space-between'
              className='width100 padding-top-Hx'
              selectClassname='width100'
            />
          )}
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get(
            'ADV_INFLUENCERS_SEARCH_FILTER_AUDIENCE_KEYWORDS_LABEL'
          )}
          keepOpen={false}
        >
          <AudienceKeywords
            selectedKeyword={this.getSelectedKeyword()}
            onChange={this.handleKeywordChange}
          />
        </FilterWrapper>

        <FilterWrapper
          title={i18n.get('ADV_INFLUENCER_PROFILE_AUTHENTICITY')}
          keepOpen={false}
        >
          <AudienceAuthenticity
            max={get(filters, 'audience.authenticity<')}
            min={get(filters, 'audience.authenticity>')}
            onChange={this.handleAuthenticityChange}
          />
        </FilterWrapper>
      </AudienceFilterHeader>
    );
  }
}

AudienceFilters.propTypes = {
  hasAceptableTypes: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  availableSocialNetworks: PropTypes.array.isRequired,
  onRequestToggleFilters: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired
};

export default AudienceFilters;
