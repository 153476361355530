import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from 'redux/modules/shared';
import { languageChange } from 'redux/modules/app';
import VerificationReminderView from 'modules/legacy/js/views/verify-email-reminder';

function mapStateToProps({ app }) {
  const { currentLanguage, partnerSettings } = app;
  return { currentLanguage, partnerSettings };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logout, languageChange }, dispatch);
}

const EmailVerificationReminderScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationReminderView);
export default EmailVerificationReminderScreen;
