import React from 'react';
import i18n from 'i18n';

const YoutubeEngagements = ({ stats }) => {
  return (
    <div className='flex'>
      {!!stats.videoViews && (
        <div className='margin-right-2x'>
          <i className='vf-icon icon-eye' />
          <span className='margin-left-Hx'>
            {i18n.getNumber(stats.videoViews)}
          </span>
        </div>
      )}

      {!!stats.likes && (
        <div className='margin-right-2x'>
          <i className='vf-icon icon-thumbs-up' />
          <span className='margin-left-Hx'>{i18n.getNumber(stats.likes)}</span>
        </div>
      )}

      {!!stats.comments && (
        <div className='margin-right-2x'>
          <i className='vf-icon icon-comment' />
          <span className='margin-left-Hx'>
            {i18n.getNumber(stats.comments)}
          </span>
        </div>
      )}
    </div>
  );
};

export default YoutubeEngagements;
