import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import { environment } from 'config/app';

const reduxEnhancersByEnvironment = {
  'default': applyMiddleware(thunkMiddleware),
  'development': compose(
    applyMiddleware(thunkMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
};

export default function configureStore() {
  return createStore(
    rootReducer,
    reduxEnhancersByEnvironment[environment] ||
      reduxEnhancersByEnvironment.default
  );
}
