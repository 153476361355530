"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const API = require("modules/legacy/js/lib/api");
const team_member_list_1 = require("./team-member-list");
const app_1 = require("config/app");
const images_1 = require("assets/images");
const SettingsAbout = () => {
    const [teamMembers, setTeamMembers] = React.useState([]);
    React.useEffect(() => {
        loadMembersFromServer();
    }, []);
    const loadMembersFromServer = () => {
        API.teamMembers((err, data) => {
            if (err)
                console.log(err);
            else
                setTeamMembers(data);
        });
    };
    const currentYear = new Date().getFullYear();
    const { about } = app_1.getStatics();
    const companyName = app_1.getDefaultTitle();
    return (React.createElement("div", { className: 'vf-wrapper vf-settings-about padding-top-2x' },
        React.createElement("div", { className: 'vf-row' },
            React.createElement("div", { className: 'col-xs-12' },
                React.createElement("h3", null, i18n.get('INF_SETTINGS_ABOUT_ABOUT')))),
        React.createElement("div", { className: 'vf-row padding-top-2x' },
            React.createElement("div", { className: 'col-xs-12 col-sm-5 col-lg-6' },
                React.createElement("h2", { className: 'voxfeed' },
                    React.createElement("img", { className: 'thumb', src: images_1.logoVoxfeedThumbnailBlackImg }),
                    ' ',
                    "VoxFeed ",
                    React.createElement("small", null, "v3.2")),
                i18n.getHTML('INF_SETTINGS_ABOUT_COPYRIGHT', {
                    currentYear,
                    companyName
                }),
                i18n.getHTML('INF_SETTINGS_ABOUT_ADDRESS'),
                React.createElement("p", null,
                    React.createElement("a", { href: about.help, target: '_blank' }, i18n.get('INF_SETTINGS_ABOUT_HELP')),
                    ' | ',
                    React.createElement("a", { href: about.blog, target: '_blank' }, i18n.get('INF_SETTINGS_ABOUT_BLOG')),
                    ' | ',
                    React.createElement("a", { href: about.terms, target: '_blank' }, i18n.get('INF_SETTINGS_ABOUT_TOS')),
                    React.createElement("br", null),
                    React.createElement("a", { href: 'mailto:contacto@voxfeed.com' }, "contacto@voxfeed.com"),
                    ' | ',
                    React.createElement("strong", null, "01 (33) 2003 0374"))),
            React.createElement("div", { className: 'col-xs-12 col-sm-7 col-lg-6 team' },
                React.createElement("h2", null, i18n.get('INF_SETTINGS_TEAM_VOXFEED', { companyName })),
                React.createElement("div", null,
                    React.createElement(team_member_list_1.default, { members: teamMembers }))))));
};
exports.default = SettingsAbout;
