import {get, omit} from 'lodash';

import {chats} from '../../../services/voxfeed';

import {updateData} from '../entities';
import {sendRequest, sendRequestSuccess} from '../app';
import {buildAPIRequest} from '../helpers';

const COLLAPSE_FLOATING_CHAT = 'voxfeed/chats/COLLAPSE_FLOATING_CHAT';
const EXPAND_FLOATING_CHAT = 'voxfeed/chats/EXPAND_FLOATING_CHAT';
const OPEN_FLOATING_CHAT = 'voxfeed/chats/OPEN_FLOATING_CHAT';
const CLOSE_FLOATING_CHAT = 'voxfeed/chats/CLOSE_FLOATING_CHAT';
const CLOSE_ALL_FLOATING_CHATS = 'voxfeed/chats/CLOSE_ALL_FLOATING_CHATS';
const OPEN_CHAT_BRAND_SELECTOR = 'voxfeed/chats/OPEN_CHAT_BRAND_SELECTOR';
const REPLACE_OPEN_CHAT = 'voxfeed/chats/REPLACE_OPEN_CHAT';

const initialState = {
  floatingChats: {}
};

export default function reducer(state = initialState, {type, payload}) {
  switch (type) {
    case COLLAPSE_FLOATING_CHAT:
      return {
        ...state,
        floatingChats: {
          ...state.floatingChats,
          [payload.chatId]: {
            ...get(state, `floatingChats.${payload.chatId}`, {}),
            isCollapsed: true
          }
        }
      };
    case EXPAND_FLOATING_CHAT:
      return {
        ...state,
        floatingChats: {
          ...state.floatingChats,
          [payload.chatId]: {
            ...get(state, `floatingChats.${payload.chatId}`, {}),
            isCollapsed: false
          }
        }
      };
    case OPEN_FLOATING_CHAT:
      return {
        ...state,
        floatingChats: {
          ...state.floatingChats,
          [payload.chatId]: {
            id: payload.chatId,
            brandId: payload.brandId,
            isCollapsed: false,
            openAt: new Date().toISOString()
          }
        }
      };
    case CLOSE_FLOATING_CHAT:
      return {
        ...state,
        floatingChats: omit(state.floatingChats, payload.chatId)
      };
    case CLOSE_ALL_FLOATING_CHATS:
      return {
        ...state,
        floatingChats: {}
      };
    case OPEN_CHAT_BRAND_SELECTOR:
      return {
        ...state,
        floatingChats: {
          ...state.floatingChats,
          [payload.id]: {
            id: payload.id,
            brandId: null,
            influencerId: payload.influencerId,
            isCollapsed: false,
            allowToSwitchBrand: true,
            openAt: new Date().toISOString()
          }
        }
      };
    case REPLACE_OPEN_CHAT:
      return {
        ...state,
        floatingChats: {
          ...omit(state.floatingChats, payload.previousId),
          [payload.newId]: {
            ...state.floatingChats[payload.previousId],
            ...payload.chatData,
            id: payload.newId
          }
        }
      };
    default:
      return state;
  }
}

export const fetchInfluencerChatWithBrand = brandId => buildAPIRequest({
  id: `fetchChatWithBrand_${brandId}`,
  method: chats.fetchInfluencerChatWithBrand,
  params: brandId
});

export const fetchInfluencerChats = () => buildAPIRequest({
  id: 'fetchInfluencerChats',
  method: chats.fetchInfluencerChats
});

export const sendMessageAsInfluencer = params => buildAPIRequest({
  params,
  id: `sendMessage_${params.chatId}`,
  method: chats.sendMessageAsInfluencer
});

export const fetchChatMessagesAsInfluencer = params => buildAPIRequest({
  params,
  id: `fetchChatMessages_${params.chatId}`,
  method: chats.fetchChatMessagesAsInfluencer
});

export const fetchChatMembersAsInfluencer = chatId => buildAPIRequest({
  id: `fetchChatMembers_${chatId}`,
  method: chats.fetchChatMembersAsInfluencer,
  params: chatId
});

export const setLastReadMessageAsInfluencer = params => buildAPIRequest({
  params,
  id: `setLastReadMessage_${params.chatId}`,
  method: chats.setLastReadMessageAsInfluencer
});

export const fetchBrandChats = brandId => buildAPIRequest({
  id: `fetchBrandChats_${brandId}`,
  method: chats.fetchBrandChats,
  params: brandId
});

export const sendMessageAsAdvertiser = params => buildAPIRequest({
  params,
  id: `sendMessage_${params.chatId}`,
  method: chats.sendMessageAsAdvertiser
});

export const fetchChatMessagesAsAdvertiser = params => buildAPIRequest({
  params,
  id: `fetchChatMessages_${params.chatId}`,
  method: chats.fetchChatMessagesAsAdvertiser
});

export const fetchChatMembersAsAdvertiser = params => buildAPIRequest({
  params,
  id: `fetchChatMembers_${params.chatId}`,
  method: chats.fetchChatMembersAsAdvertiser
});

export const fetchChatInfluencerSocialAccounts = params => buildAPIRequest({
  params,
  id: `fetchChatInfluencerSocialAccounts_${params.chatId}`,
  method: chats.fetchInfluencerSocialAccounts
});

export const setLastReadMessageAsAdvertiser = params => buildAPIRequest({
  params,
  id: `setLastReadMessage_${params.chatId}`,
  method: chats.setLastReadMessageAsAdvertiser
});

export const fetchAdvertiserMemberInfoInBrandChats = params => buildAPIRequest({
  params,
  id: `fetchAdvertiserMemberInfoInBrandChats_${params.brandId}`,
  method: chats.fetchAdvertiserMemberInfoInBrandChats
});

export function openChatWithInfluencer(args) {
  const {brandId, influencerId, previousChatId} = args;
  const REQUEST_ID = `fetchChatByInfluencerId_${influencerId}`;

  return (dispatch) => {
    dispatch(sendRequest(REQUEST_ID));
    chats.fetchChatByInfluencerId({brandId, influencerId})
      .then((res) => {
        const chatId = get(res, 'data.data[0].id');

        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateData(res.data));

        if (previousChatId) {
          return dispatch(replaceOpenChat({
            previousId: previousChatId,
            newId: chatId,
            chatData: {brandId}
          }));
        }

        dispatch(openFloatingChat({chatId, brandId}));
      });
  };
}

export const fetchInfluencerMemberInfoInChats = () => buildAPIRequest({
  id: 'fetchInfluencerMemberInfoInChats',
  method: chats.fetchInfluencerMemberInfoInChats
});

export const archiveChat = chatId => buildAPIRequest({
  id: `archiveChat_${chatId}`,
  method: chats.archive,
  params: chatId
});

export const unarchiveChat = chatId => buildAPIRequest({
  id: `unarchiveChat_${chatId}`,
  method: chats.unarchive,
  params: chatId
});

export const blockChat = chatId => buildAPIRequest({
  id: `blockChat_${chatId}`,
  method: chats.block,
  params: chatId
});

export const unblockChat = chatId => buildAPIRequest({
  id: `unblockChat_${chatId}`,
  method: chats.unblock,
  params: chatId
});

export const acceptChat = chatId => buildAPIRequest({
  id: `acceptChat_${chatId}`,
  method: chats.accept,
  params: chatId
});

export const resolveChat = chatId => buildAPIRequest({
  id: `resolveChat_${chatId}`,
  method: chats.resolve,
  params: chatId
});

export function collapseFloatingChat(chatId) {
  return {
    type: COLLAPSE_FLOATING_CHAT,
    payload: {chatId}
  };
}

export function expandFloatingChat(chatId) {
  return {
    type: EXPAND_FLOATING_CHAT,
    payload: {chatId}
  };
}

export function openFloatingChat({chatId, brandId}) {
  return {
    type: OPEN_FLOATING_CHAT,
    payload: {chatId, brandId}
  };
}

export function closeFloatingChat(chatId) {
  return {
    type: CLOSE_FLOATING_CHAT,
    payload: {chatId}
  };
}

export function closeAllFloatingChats() {
  return {
    type: CLOSE_ALL_FLOATING_CHATS
  };
}

export function openChatBrandSelector({id, influencerId}) {
  return {
    type: OPEN_CHAT_BRAND_SELECTOR,
    payload: {id, influencerId}
  };
}

export function replaceOpenChat({previousId, newId, chatData}) {
  return {
    type: REPLACE_OPEN_CHAT,
    payload: {previousId, newId, chatData}
  };
}

export const constants = {
  COLLAPSE_FLOATING_CHAT,
  EXPAND_FLOATING_CHAT,
  OPEN_FLOATING_CHAT,
  CLOSE_FLOATING_CHAT,
  CLOSE_ALL_FLOATING_CHATS,
  OPEN_CHAT_BRAND_SELECTOR,
  REPLACE_OPEN_CHAT
};
