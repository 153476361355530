import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { chain, includes } from 'lodash';
import classNames from 'classnames';
import i18n from 'i18n';
import { isWhiteLabel } from 'config/app';

import { unsetHeader } from 'redux/modules/app';

import NavSideMenu from 'modules/legacy/js/components/nav-menu';
import SidemenuItem from 'apps/shared/components/SidemenuItem/SidemenuItem';

function mapStateToProps({ shared, app }) {
  return {
    user: shared.user,
    currentLanguage: app.currentLanguage
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ unsetHeader }, dispatch);
}

class Settings extends Component {
  componentDidMount() {
    this.props.unsetHeader();
  }

  getOptions() {
    const whiteLabel = !!isWhiteLabel();
    const { user } = this.props;
    const { validCategory } = user;

    const depositOption = validCategory
      ? []
      : [
          {
            key: 'deposit',
            label: i18n.get('INF_SETTINGS_DEPOSITS_LABEL'),
            icon: 'deposit',
            path: '/settings/money',
            roles: ['influencer']
          }
        ];

    const options = [
      {
        key: 'account',
        label: i18n.get('INF_SETTINGS_ACCOUNT_LABEL'),
        icon: 'user',
        path: '/settings/account'
      },
      ...depositOption,
      {
        key: 'about',
        label: i18n.get('INF_SETTINGS_ABOUT_US_LABEL'),
        icon: 'info',
        path: '/settings/about',
        white: 'hidden'
      }
    ];

    return chain(options)
      .filter(option => !option.roles || includes(option.roles, user.role))
      .filter(
        option => !whiteLabel || !option.white || option.white != 'hidden'
      )
      .map((option, index) => (
        <SidemenuItem
          label={option.label}
          linkTo={option.path}
          icon={option.icon}
          key={index}
        />
      ))
      .value();
  }

  render = () => {
    const showNav = !!this.props.children;
    const className = classNames('view view-white flex flex-stretch', {
      'vf-shownav-active': showNav,
      'vf-shownav-inactive': !showNav
    });
    return (
      <div className={className}>
        <NavSideMenu title={i18n.get('INF_SETTINGS_CONFIG_LABEL')}>
          {this.getOptions()}
        </NavSideMenu>

        <div className='flex-1 vf-scrolly view-white vf-shownav-content'>
          {this.props.children}
        </div>
      </div>
    );
  };
}

Settings.propTypes = {
  user: PropTypes.object,
  currentLanguage: PropTypes.string,
  unsetHeader: PropTypes.func,
  children: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
