"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const app_1 = require("redux/modules/app");
const users_1 = require("redux/modules/users");
const settings_account_readonly_1 = require("modules/legacy/js/components/settings-account-readonly");
const mapDispatchToProps = (dispatch) => {
    return redux_1.bindActionCreators({ startCurrentUserVerificationRequest: users_1.startCurrentUserVerificationRequest, changePage: app_1.changePage, showAlert: app_1.showAlert }, dispatch);
};
exports.default = react_redux_1.connect(null, mapDispatchToProps)(settings_account_readonly_1.default);
