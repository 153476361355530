import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { countBy, get } from 'lodash';

import { SocialBadgeSM } from 'apps/shared/components';
import { CampaignCard } from 'modules/campaigns/influencer/containers';

class PublicCampaignCard extends Component {
  static propTypes = {
    publicCampaign: PropTypes.object.isRequired,
    request: PropTypes.array.isRequired
  };

  renderSingleSocialNetworkBadge = ({ socialNetwork, text }) => (
    <div key={socialNetwork} className='margin-right-1x flex'>
      <SocialBadgeSM socialNetwork={socialNetwork} />

      <span className='vf-text-white margin-left-Hx' style={{ lineHeight: 1 }}>
        {text}
      </span>
    </div>
  );

  renderSocialAccountBadges = () => {
    const availableParticipations = get(
      this.props,
      'publicCampaign.availableParticipations',
      []
    ).filter(p => !!p.canSendRequest);

    const totalBySocialNetwork = countBy(
      availableParticipations,
      'socialNetwork'
    );

    if (availableParticipations.length === 1) {
      const { socialNetwork, username } = availableParticipations[0];
      return this.renderSingleSocialNetworkBadge({
        socialNetwork,
        text: `@${username || ''}`
      });
    }

    return Object.keys(totalBySocialNetwork).map(socialNetwork =>
      this.renderSingleSocialNetworkBadge({
        socialNetwork,
        text: totalBySocialNetwork[socialNetwork]
      })
    );
  };

  render = () => {
    const { request, publicCampaign, ...other } = this.props;

    const hasAnAccountToParticipate =
      publicCampaign.availableParticipations.some(p => p.canSendRequest);

    return (
      <CampaignCard
        disabled={!hasAnAccountToParticipate}
        campaignId={publicCampaign.campaignId}
        {...other}
      >
        {hasAnAccountToParticipate && (
          <div
            className='flex end-xs middle-xs width100'
            style={{ position: 'absolute', bottom: '1rem', right: 0 }}
          >
            {this.renderSocialAccountBadges()}
          </div>
        )}
      </CampaignCard>
    );
  };
}

export default PublicCampaignCard;
