"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const nuka_carousel_1 = require("nuka-carousel");
const i18n = require("i18n");
const Loading_1 = require("apps/shared/components/Loading/Loading");
const mediaUploader_1 = require("apps/shared/hocs/mediaUploader");
const components_1 = require("modules/shared/components");
const styles = require("./MediaUploaderCarousel.less");
class MediaUploaderCarousel extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            slide: 0,
            isDragging: false
        };
        this.handleNextSlide = () => {
            const { urls } = this.props;
            const { slide } = this.state;
            this.setState({
                slide: slide + 1 < urls.length ? slide + 1 : 0
            });
        };
        this.handlePreviousSlide = () => {
            const { urls } = this.props;
            const { slide } = this.state;
            this.setState({
                slide: slide - 1 >= 0 ? slide - 1 : urls.length - 1
            });
        };
        this.handleToggleDrag = () => {
            this.setState(prev => ({ isDragging: !prev.isDragging }));
        };
        this.handleFilesSelected = files => {
            const { onFilesSelected } = this.props;
            onFilesSelected(files);
            this.setState({ isDragging: false });
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.urls.length !== this.props.urls.length) {
            this.setState({ slide: 0 });
        }
    }
    render() {
        const { maxFiles, mediaType, mediaClassName, wrapperClassName, wrapperStyle, style, className, urls, errors, isLoading, contain, onRemoveFile, lite } = this.props;
        const { slide, isDragging } = this.state;
        const isImage = mediaType === 'image';
        const canSelectFiles = !isLoading && urls.length < maxFiles;
        const iconClassName = classnames_1.default(!lite ? 'vf-upload-image-icon vf-text-secondary' : styles.lite, {
            'icon-camera-1': isImage,
            'icon-video': !isImage
        });
        const stringUrls = isImage ? urls : urls.map(data => data.url);
        const errorList = errors.map((error, i) => (React.createElement("div", { key: i, className: 'vf-text-danger margin-vertical-Hx' }, error)));
        const urlList = stringUrls.map(url => (React.createElement("div", { key: url, className: mediaClassName },
            React.createElement(components_1.SafeImage, { url: url, isVideo: !isImage, cover: !contain }))));
        return (React.createElement(components_1.FileInputWrapper, { className: wrapperClassName, style: wrapperStyle, allowMultipleFiles: maxFiles > 1, filesType: mediaType, canSelectFiles: canSelectFiles, onFilesSelected: this.handleFilesSelected, onDragEnter: this.handleToggleDrag, onDragLeave: this.handleToggleDrag },
            React.createElement("div", { style: style, className: classnames_1.default(className, 'vf-clickable') },
                React.createElement("div", { className: classnames_1.default(mediaClassName, styles.back, 'flex flex-justify-center height100 vf-bg-gray-color', { [styles.highlight]: isDragging }) }, isLoading ? (React.createElement(Loading_1.default, { active: true })) : (React.createElement("span", { style: !lite ? { height: '3.5rem' } : { height: '6rem' } },
                    React.createElement("i", { className: iconClassName }),
                    lite && (React.createElement("p", { className: styles.litevideo }, i18n.get('INF_LITE_ACCOUNTS_VIDEO_PASTE')))))),
                urlList.length > 0 && !isLoading && (React.createElement("div", { className: styles['carousel-wrapper'] },
                    React.createElement("div", { className: styles['buttons-wrapper'] },
                        urls.length < maxFiles && (React.createElement("button", { className: styles.button },
                            React.createElement("i", { className: 'vf-icon icon-plus' }))),
                        React.createElement("button", { className: styles.button, onClick: ev => {
                                ev.stopPropagation();
                                onRemoveFile(slide);
                            } },
                            React.createElement("i", { className: 'vf-icon icon-trash-b' }))),
                    React.createElement(MediaCarousel, { slide: slide, nextSlide: this.handleNextSlide, previousSlide: this.handlePreviousSlide, onClick: ev => ev.stopPropagation() }, urlList))),
                React.createElement("div", { style: { cursor: 'default' }, onClick: ev => ev.stopPropagation() }, errorList))));
    }
}
const MediaCarousel = ({ children, slide, previousSlide, nextSlide, onClick }) => {
    if (children.length === 1)
        return children[0];
    return (React.createElement("div", { className: styles['carousel-wrapper'], onClick: onClick },
        React.createElement(nuka_carousel_1.default, { slideIndex: slide, framePadding: '0', wrapAround: true, renderBottomCenterControls: null, renderCenterLeftControls: () => (React.createElement(Arrow, { isLeft: true, onClick: previousSlide })), renderCenterRightControls: () => (React.createElement(Arrow, { onClick: nextSlide, isLeft: false })) }, children)));
};
const Arrow = ({ isLeft, onClick }) => (React.createElement("div", { className: styles.arrow, onClick: onClick },
    React.createElement("i", { className: `vf-icon ${isLeft ? 'icon-arrow-left' : 'icon-arrow-right'}` })));
exports.default = mediaUploader_1.default(MediaUploaderCarousel);
