import SUPPORTED_LOCALES from './get-supported-locales';

function getValidLocale(locale, globalSupportedLocales = SUPPORTED_LOCALES) {
  const validLocale =
    globalSupportedLocales.includes(locale)
      ? locale
      : undefined;

  return validLocale;
}

module.exports = getValidLocale;
