import React from 'react';
import replaceString from 'react-string-replace';
import { container } from './TextWithHighlightedElements.less';
import classNames from 'classnames';

const URL_REGEX = /(https?:\/\/\S+)/g;
const HASHTAGS_REGEX = /(#[a-z\d_áéíóúñüÜÁÉÍÓÚÑ]+)/gi;
const MENTIONS_REGEX = /(@[a-z\d_áéíóúñüÜÁÉÍÓÚÑ.]+)/gi;

const Text = ({ text, className, contained = true }) => {
  const textWithParsedLinks = parseTextLinks(text);
  const textWithParsedHashtags = parseHashtags(textWithParsedLinks);
  const textWithParsedMentions = parseMentions(textWithParsedHashtags);
  const containerClassnames = classNames(className, { [container]: contained });

  return <div className={containerClassnames}>{textWithParsedMentions}</div>;
};

const parseTextLinks = text => {
  return replaceString(text, URL_REGEX, (match, i) => (
    <a href={match} target='_blank' key={`${match}${i}`}>
      {match}
    </a>
  ));
};

const parseHashtags = text => {
  return replaceString(text, HASHTAGS_REGEX, (match, i) => (
    <span className='vf-primary-text' key={`${match}${i}`}>
      {match}
    </span>
  ));
};

const parseMentions = text => {
  return replaceString(text, MENTIONS_REGEX, (match, i) => (
    <span className='vf-primary-text' key={`${match}${i}`}>
      {match}
    </span>
  ));
};

export default Text;
