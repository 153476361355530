import axios from 'axios';
import {isEmpty} from 'lodash';


export function fetchPlaceById({id, ...params}) {
  if (!id) return Promise.resolve();
  return axios.get(`/places/${id}`, {params});
}

export function fetchPlaces(params) {
  if (isEmpty(params.ids) && isEmpty(params.q)) {
    return Promise.resolve({
      data: {
        modelName: 'Place',
        data: []
      }
    });
  }
  return axios.get('/places', {params});
}

export function fetchCities(params) {
  return axios.get('/places/cities', {params});
}

export function fetchCountries(params) {
  return axios.get('/places/countries', {params});
}
