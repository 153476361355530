import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import classNames from 'classnames';
import { Button } from 'ui-library';
import { gotoPage } from 'modules/legacy/js/actions/app';

import { ChevronLeft } from 'assets/images';

const BLOCKED = 'Blocked';
const USERDATA = 'UserData';

export default createClass({
  displayName: 'NavigationBar',

  propTypes: {
    title: PropTypes.any,
    className: PropTypes.string,

    leftButton: PropTypes.any,
    onClickLeftButton: PropTypes.func,

    rightButton: PropTypes.any,
    onClickRightButton: PropTypes.func,

    returnButton: PropTypes.any,

    onClose: PropTypes.func,
    options: PropTypes.object
  },

  getDefaultProps() {
    return {
      title: 'VoxFeed',
      rightButton: <i className='vf-icon icon-hamburger' />,
      returnButton: (
        <img
          src={ChevronLeft}
          alt='chevronConfig'
          style={{
            height: '16px',
            width: '16px',
            margin: '0 4px'
          }}
        />
      ),
      options: {
        showLeftButton: true,
        showRightButton: false
      }
    };
  },

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  },

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  },

  handleClickOutside(ev) {
    if (
      !!this.props.onClose &&
      !!this.container &&
      !this.container.contains(ev.target)
    ) {
      this.props.onClose();
    }
  },

  renderReturntButton() {
    const { returnButton } = this.props;
    return (
      returnButton || <i className='vf-icon icon-hamburger show-in-small' />
    );
  },

  renderLeftButton() {
    const { leftButton } = this.props;
    return leftButton || <i className='vf-icon icon-hamburger show-in-small' />;
  },

  onClickReturnButton() {
    window.localStorage.removeItem(BLOCKED);
    window.localStorage.removeItem(USERDATA);
    gotoPage('/social-accounts');
  },

  render() {
    const {
      rightButton,
      onClickRightButton,
      onClickLeftButton,
      title,
      className,
      options
    } = this.props;
    const containerClassName = classNames('vf-navigation-bar', className);

    const isBlockedLite = window.localStorage.getItem(BLOCKED);

    return (
      <div ref={r => (this.container = r)} className={containerClassName}>
        {options.showLeftButton && (
          <Button
            onClick={
              !isBlockedLite ? onClickLeftButton : this.onClickReturnButton
            }
            className='vf-navigation-bar-button-left no-border vf-btn--no-shado'
            style={{ height: '100%' }}
          >
            {!isBlockedLite
              ? this.renderLeftButton()
              : this.renderReturntButton()}
          </Button>
        )}

        {options.showRightButton && (
          <Button
            onClick={onClickRightButton}
            className='vf-navigation-bar-button-right vf-btn--no-shado'
            style={{ height: '100%' }}
          >
            {rightButton}
          </Button>
        )}

        <h2>{title}</h2>
      </div>
    );
  }
});
