import { buildAPIRequest } from '../helpers';
import { socialNetworksLite } from '../../../services/voxfeed';

// eslint-disable-next-line import/prefer-default-export
export const showSocialAccountsLite = params =>
  buildAPIRequest({
    params,
    id: `showSocialAccountsLite_${params.id}`,
    method: socialNetworksLite.getsocialsAccountsLite
  });
