"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const passwordRegex_1 = require("utils/passwordRegex");
const components_1 = require("apps/shared/components");
const components_2 = require("../../Registration/containers/RoleForm/components");
class InviteForm extends React.Component {
    constructor(props) {
        super(props);
        this.validateForm = () => {
            const state = {};
            const { name, password, confirmPassword } = this.state;
            if (!passwordRegex_1.default.test(confirmPassword.value) ||
                confirmPassword.value !== password.value) {
                state.confirmPassword = { value: confirmPassword.value, hasError: true };
                state.errorMessage = i18n.get('REGISTER_PASSWORD_DO_NOT_MATCH');
            }
            if (!passwordRegex_1.default.test(password.value)) {
                state.password = { value: password.value, hasError: true };
                state.errorMessage = i18n.get('REGISTER_INVALID_PASSWORD_LENGTH');
            }
            if (!name.value) {
                state.name = { value: name.value, hasError: true };
                state.errorMessage = i18n.get('REGISTER_FIRST_NAME_IS_REQUIRED');
            }
            if (lodash_1.isEmpty(state))
                return true;
            this.setState(state);
            return false;
        };
        this.onSendRegistrationData = event => {
            if (event)
                event.preventDefault();
            if (!this.validateForm()) {
                if (event)
                    return event.stopPropagation();
            }
            const { name, lastName, password } = this.state;
            const { token, email, acceptRegistrationInviteActive } = this.props;
            const params = {
                email,
                token,
                name: name.value,
                role: 'advertiser',
                lastName: lastName.value,
                password: password.value
            };
            return acceptRegistrationInviteActive(params);
        };
        this.handleInputChange = (event, key) => {
            const { value } = event.target;
            this.setState({ [key]: { value, hasError: false }, errorMessage: '' });
        };
        this.handleShowPassword = () => {
            const { showPassword } = this.state;
            this.setState({ showPassword: !showPassword });
        };
        this.handleConfirmPassword = () => {
            const { showConfirmPassword } = this.state;
            this.setState({ showConfirmPassword: !showConfirmPassword });
        };
        const { email } = props;
        this.state = {
            errorMessage: '',
            showPassword: false,
            showConfirmPassword: false,
            name: { value: '', hasError: false },
            lastName: { value: '', hasError: false },
            email: { value: email, hasError: false },
            password: { value: '', hasError: false },
            confirmPassword: { value: '', hasError: false }
        };
    }
    render() {
        const { name, email, lastName, password, errorMessage, showPassword, confirmPassword, showConfirmPassword } = this.state;
        return (React.createElement(ui_library_1.Form, { onSubmit: this.onSendRegistrationData },
            React.createElement("div", { className: 'form' },
                React.createElement("div", { className: 'vf-input-group' },
                    React.createElement(components_1.FormLabel, { htmlFor: 'name' }, i18n.get('REGISTER_FIRST_NAME')),
                    React.createElement(ui_library_1.InputWrapper, { className: 'lg margin-bottom-2x' },
                        React.createElement("i", { className: 'vf-icon icon-user' }),
                        React.createElement(components_1.DebounceInput, { type: 'text', value: name.value, className: 'login-input', hasError: name.hasError, onChange: event => this.handleInputChange(event, 'name'), placeholder: i18n.get('REGISTER_FIRST_NAME_PLACEHOLDER') })),
                    React.createElement(components_1.FormLabel, { htmlFor: 'lastName' }, i18n.get('REGISTER_LAST_NAME')),
                    React.createElement(ui_library_1.InputWrapper, { className: 'lg margin-bottom-2x' },
                        React.createElement("i", { className: 'vf-icon icon-user' }),
                        React.createElement(components_1.DebounceInput, { type: 'text', value: lastName.value, className: 'login-input', hasError: lastName.hasError, placeholder: i18n.get('REGISTER_LAST_NAME_PLACEHOLDER'), onChange: event => this.handleInputChange(event, 'lastName') }))),
                React.createElement(components_2.RoleFormInputs, { disabledEmail: true, email: email, password: password, showPassword: showPassword, confirmPassword: confirmPassword, onInputChange: this.handleInputChange, onShowPassword: this.handleShowPassword, showConfirmPassword: showConfirmPassword, onShowConfirmPassword: this.handleConfirmPassword }),
                React.createElement("div", { className: 'vf-text-danger margin-top-1x' }, errorMessage),
                React.createElement("div", { id: 'recaptcha', ref: 'recaptcha', className: 'recaptcha-container' }),
                React.createElement("div", { className: 'terms' }, i18n.getHTML('REGISTER_ACCEPT_TOS')),
                React.createElement(ui_library_1.Button, { type: 'submit', color: 'primary', className: 'width100 button-success' }, i18n.get('REGISTER_REGISTER_ME')))));
    }
}
exports.default = InviteForm;
