import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import isNil from 'lodash/isNil';
import splitWithLimit from 'utils/splitWithLimit';
import getAudienceAuthenticityLevel from 'utils/getAudienceAuthenticityLevelByValue';
import { connect } from 'react-redux';
import { FilterHeaderTags } from '../../components';
import styles from './AudienceFilterHeader.less';

const GENDER_FILTER = gender => `audience.genders.${gender}>`;
const AGE_RANGE_FILTER = ageRange =>
  `audience.ages.${ageRange.replace(/\s/g, '')}>`;
const AGE_RANGES = [
  '13 - 17',
  '18 - 24',
  '25 - 34',
  '35 - 44',
  '45 - 64',
  '65 +'
];
const LOCATION_TYPES = ['countries', 'regions', 'cities'];
const SOCIAL_NETWORKS_WITH_AUDIENCE_STATS = ['facebook', 'instagram'];

class AudienceFilterHeader extends React.Component {
  getAudienceTags = () => {
    const { filters } = this.props;

    return [
      {
        keys: [GENDER_FILTER('male')],
        value: filters[GENDER_FILTER('male')]
          ? i18n.get(
              'ADV_INFLUENCERS_SEARCH_FILTER_GENDER_MAJORITY_OPTIONS_MALE'
            )
          : null
      },
      {
        keys: [GENDER_FILTER('female')],
        value: filters[GENDER_FILTER('female')]
          ? i18n.get(
              'ADV_INFLUENCERS_SEARCH_FILTER_GENDER_MAJORITY_OPTIONS_FEMALE'
            )
          : null
      },
      ...this.getAgeRangeTags(),
      ...this.getLocationTags(),
      ...this.getKeywordsTags(),
      ...this.getAuthenticityTags()
    ];
  };

  getAgeRangeTags = () => {
    const { filters } = this.props;
    const results = [];
    let keys = [];
    const ageFilters = AGE_RANGES.map(ageRange => AGE_RANGE_FILTER(ageRange));
    ageFilters.forEach(ageFilter => {
      if (filters[ageFilter]) {
        keys.push(ageFilter);
      } else if (keys.length > 0) {
        results.push(this.getAgeRangeTag(keys));
        keys = [];
      }
    });

    if (keys.length > 0) results.push(this.getAgeRangeTag(keys));

    return results;
  };

  getAgeRangeTag = keys => {
    const [, , minKey] = keys[0].split('.');
    const [, , maxKey] = keys[keys.length - 1].split('.');
    const [min] = minKey.slice(0, -1).split('-');
    const [last, max] = maxKey.slice(0, -1).split('-');

    if (min === '65+') return { keys, value: min };
    if (last === '65+') return { keys, value: `${min} - ${last}` };
    return { keys, value: `${min} - ${max}` };
  };

  getLocationTags = () => {
    const { filters, places } = this.props;
    const locationKeys = Object.keys(filters).filter(value =>
      LOCATION_TYPES.reduce(
        (p, type) => p || value.includes(`audience.${type}`),
        false
      )
    );

    const results = [];
    locationKeys.forEach(key => {
      const split = splitWithLimit(key, '.', 2);
      const id = split[2].slice(0, -1);
      if (places[id]) {
        results.push({
          keys: [key],
          value: `${i18n.get('IN')} ${places[id].name}${
            places[id].country ? `, ${places[id].country.name}` : ''
          }`
        });
      }
    });

    return results;
  };

  getKeywordsTags = () => {
    const { filters } = this.props;
    const filterKey = Object.keys(filters).find(f =>
      f.includes('audience.keywords')
    );
    if (!filterKey) return [];

    const keywordWithOperator = filterKey.split('.')[2];
    const keyword = keywordWithOperator.slice(
      0,
      keywordWithOperator.length - 1
    );

    return [
      {
        keys: [filterKey],
        value: keyword
      }
    ];
  };

  getAuthenticityTags = () => {
    const { filters } = this.props;
    const authenticity = filters['audience.authenticity>'];
    if (isNil(authenticity)) return [];

    const level = this.getAuthenticityLevelByValue(authenticity);

    return [
      {
        keys: ['audience.authenticity<', 'audience.authenticity>'],
        value: `${i18n.get('ADV_INFLUENCER_PROFILE_AUTHENTICITY')} - ${level}`
      }
    ];
  };

  getAuthenticityLevelByValue = val => {
    const level = getAudienceAuthenticityLevel(val);

    if (!level) return '';
    if (level === 'excellent')
      return i18n.get('ADV_INFLUENCER_PROFILE_AUTHENTICITY_EXCELLENT');
    if (level === 'good')
      return i18n.get('ADV_INFLUENCER_PROFILE_AUTHENTICITY_GOOD');
    if (level === 'regular')
      return i18n.get('ADV_INFLUENCER_PROFILE_AUTHENTICITY_REGULAR');
    return i18n.get('ADV_INFLUENCER_PROFILE_AUTHENTICITY_BAD');
  };

  getAceptableTypes = () => {
    const { availableSocialNetworks } = this.props;
    return availableSocialNetworks.filter(sn =>
      SOCIAL_NETWORKS_WITH_AUDIENCE_STATS.includes(sn)
    );
  };

  handleRemoveTag = keys => {
    const { onChange } = this.props;
    const newFilters = keys.reduce(
      (filter, key) => ({ ...filter, [key]: null }),
      {}
    );
    onChange(newFilters);
  };

  handleUnderstood = event => {
    const { onChange } = this.props;
    event.preventDefault();
    onChange('type', this.getAceptableTypes().join(','));
  };

  handleReset = event => {
    const { filters, onChange } = this.props;
    if (event) event.stopPropagation();

    const newFilters = Object.keys(filters).reduce((newf, filterKey) => {
      if (filterKey.includes('audience')) return { ...newf, [filterKey]: null };
      return newf;
    }, {});

    onChange(newFilters);
  };

  render() {
    const { children, hasAceptableTypes, isOpened, onRequestToggleFilters } =
      this.props;

    if (this.getAceptableTypes().length === 0) return null;

    return (
      <FilterHeaderTags
        isOpened={isOpened}
        title={i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_BY_AUDIENCE')}
        tags={this.getAudienceTags().filter(tag => tag.value)}
        onRequestRemoveTag={this.handleRemoveTag}
        onRequestReset={this.handleReset}
        onRequestToggleFilters={onRequestToggleFilters}
        tooltipMessage={i18n.get(
          'ADV_INFLUENCERS_SEARCH_FILTER_AUDIENCE_TOOLTIP'
        )}
      >
        {!hasAceptableTypes ? (
          <div style={{ minHeight: 80 }}>
            <div className={styles.popup}>
              {i18n.get(
                'ADV_INFLUENCERS_SEARCH_FILTER_BY_AUDIENCE_POPUP_MESSAGE'
              )}
              <a href='/no' onClick={this.handleUnderstood}>
                {i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_UNDERSTOOD')}
              </a>
            </div>
          </div>
        ) : (
          children
        )}
      </FilterHeaderTags>
    );
  }
}

AudienceFilterHeader.propTypes = {
  hasAceptableTypes: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRequestToggleFilters: PropTypes.func.isRequired,
  availableSocialNetworks: PropTypes.array.isRequired,
  isOpened: PropTypes.bool,
  places: PropTypes.object
};

AudienceFilterHeader.defaultProps = {
  places: {},
  isOpened: false
};

const mapStateToProps = state => ({
  places: state.entities.Place
});
export default connect(mapStateToProps)(AudienceFilterHeader);
