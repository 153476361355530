import axios from 'axios';

export function sendVerificationRequest(verificationData) {
  return axios.post('/users/me/verify', verificationData);
}

export function fetchVerificationRequests(params = {}) {
  return axios.get('/users/verification-requests?status=created', {params});
}

export function approveVerificationRequest(id) {
  return axios.post(`/users/verification-requests/${id}/accept`);
}

export function rejectVerificationRequest(id, feedback) {
  return axios.post(`/users/verification-requests/${id}/reject`, {feedback});
}
