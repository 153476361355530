import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Img } from 'apps/shared/components';
import styles from './BrandAvatar.less';

function Avatar(props) {
  const { url, canRemoveImage, onRemove, className, name, size, shadow } =
    props;
  const brandName = name.toUpperCase();
  const imageClassName = classNames(styles.image, className, 'vf-bg-gradient', {
    'vf-clickable': canRemoveImage,
    [styles.sm]: size === 'sm',
    [styles.md]: size === 'md',
    [styles.shadow]: shadow
  });

  const sizes = {
    sm: 32,
    md: 56
  };

  return (
    <div className={imageClassName} style={{ flexShrink: 0 }}>
      {url && (
        <Img
          src={url}
          className='image-cover image-fit-parent'
          width={sizes[size]}
          height={sizes[size]}
        />
      )}
      {!url && (
        <div className='width100 height100 flex center-xs vf-font-bigger vf-text-white'>
          {brandName.charAt(0) || 'X'}
        </div>
      )}

      {canRemoveImage && (
        <div className={styles.overlay} onClick={onRemove}>
          <i className='vf-icon icon-close' />
        </div>
      )}
    </div>
  );
}

Avatar.propTypes = {
  url: PropTypes.string,
  canRemoveImage: PropTypes.bool,
  onRemove: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
  shadow: PropTypes.bool
};

Avatar.defaultProps = {
  url: '',
  canRemoveImage: false,
  onRemove: () => {},
  name: 'X',
  className: '',
  size: 'md',
  shadow: true
};

export default Avatar;
