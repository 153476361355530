"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const styles = require("./LocationSelectUI.less");
const components_1 = require("apps/shared/components");
const components_2 = require("../components");
const lodash_1 = require("lodash");
class LocationSelectUI extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isOpen: false
        };
        this.handleToggleSelect = isOpen => {
            this.setState({ isOpen });
        };
        this.handleInputClick = (event) => {
            event.stopPropagation();
            this.setState({ isOpen: true });
        };
        this.handleLocationClick = (type, id) => {
            const { onLocationClick } = this.props;
            this.setState({ isOpen: false }, () => onLocationClick(type, id));
        };
        this.handleInputChange = event => {
            const { onSearchChange } = this.props;
            onSearchChange(event.target.value);
        };
    }
    render() {
        const { isOpen } = this.state;
        const { places, selectedTree, searchValue, isLoading, onRemove } = this.props;
        const placeList = places.map(place => (React.createElement(components_2.default, { key: place.id, place: place, onClick: this.handleLocationClick })));
        const treeList = lodash_1.map(selectedTree, root => (React.createElement(CountryItem, { key: root.id, country: root, onRemove: onRemove })));
        return (React.createElement(React.Fragment, null,
            !lodash_1.isEmpty(selectedTree) && (React.createElement("div", { className: 'vf-border-gray-color', style: { padding: '0.5rem 0' } }, treeList)),
            React.createElement(components_1.SelectWrapper, { isOpen: isOpen, onToggle: this.handleToggleSelect, selectedValue: React.createElement(components_1.DebounceInput, { debounceTime: 800, placeholder: i18n.get('ADV_INFLUENCERS_SEARCH_FILTER_LOCATION_PLACEHOLDER'), value: searchValue, className: styles.input, onChange: this.handleInputChange, onClick: this.handleInputClick }) }, isLoading ? (React.createElement("div", { className: 'padding-Hx' }, i18n.get('SEARCHING'))) : (placeList))));
    }
}
exports.default = LocationSelectUI;
const CountryItem = ({ country, onRemove }) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: classnames_1.default(styles['tree-item'], 'vf-bold flex padding-horizontal-Hx') },
        country.name,
        React.createElement("span", { onClick: () => onRemove(country.id) },
            React.createElement("i", { className: 'vf-icon icon-fix icon-close' }))),
    country.children.map(place => (React.createElement("div", { key: place.id, className: classnames_1.default(styles['tree-item'], 'flex padding-horizontal-1x') },
        place.name,
        React.createElement("span", { onClick: () => onRemove(country.id, place.id) },
            React.createElement("i", { className: 'vf-icon icon-fix icon-close' })))))));
