import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import classNames from 'classnames';
import { get } from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showAlert, resetRequest } from 'redux/modules/app';
import { sendVerificationEmail } from 'redux/modules/users';

const REQUEST_ID = 'sendVerificationEmail';

function mapStateToProps({ shared, app }) {
  const { user } = shared;
  const { requests } = app;

  const verificationEmailSendingRequest = get(requests, REQUEST_ID, {});

  return { user, verificationEmailSendingRequest };
}

function mapDispatchToProps(dispatch) {
  const actions = { showAlert, resetRequest, sendVerificationEmail };
  return bindActionCreators(actions, dispatch);
}

class EmailVerificationReminder extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps = newProps => {
    const { verificationEmailSendingRequest } = newProps;
    this.checkEmailSendingRequestStatus(verificationEmailSendingRequest);
  };

  checkEmailSendingRequestStatus = request => {
    const requestStatus = get(request, 'status', '');
    if (this.didRequestFail(requestStatus)) {
      this.onSendEmailVerificationError();
    }
    if (this.didRequestSucceeded(requestStatus)) {
      this.onSendEmailVerificationSuccess();
    }
  };

  didRequestSucceeded = requestStatus => {
    return requestStatus === 'success';
  };

  didRequestFail = requestStatus => {
    return requestStatus === 'fail';
  };

  onSendEmailVerificationSuccess = () => {
    this.props.resetRequest(REQUEST_ID);
    this.props.showAlert({
      type: 'success',
      message: i18n.get('VERIFY_EMAIL_REMAINDER_SUCCESS_ALERT_DESCRIPTION')
    });
  };

  onSendEmailVerificationError = () => {
    this.props.resetRequest(REQUEST_ID);
    this.props.showAlert({
      type: 'error',
      message: i18n.get(
        'VERIFY_EMAIL_REMAINDER_UNKNOWN_ERROR_ALERT_DESCRIPTION'
      )
    });
  };

  resendEmail = () => {
    const { user } = this.props;
    const { email } = user;
    this.props.sendVerificationEmail({ email });
  };

  render = () => {
    const { className, user } = this.props;
    const containerClasses = classNames(
      'flex middle-xs width100 col no-padding vf-font-big',
      className
    );

    return (
      <div className={containerClasses}>
        <div className='width100 flex col-xs-12 padding-bottom-Hx padding-horizontal-2x'>
          <h5 className='width100 center-xs no-margin vf-ellipsis no-padding'>
            {user.email}
          </h5>
        </div>
        <div className='no-padding flex col-xs-12 center vf-font-small'>
          <a onClick={this.resendEmail}>
            {i18n.get('VERIFY_EMAIL_SEND_BUTTON_TITLE')}
          </a>
        </div>
      </div>
    );
  };
}

EmailVerificationReminder.propTypes = {
  user: PropTypes.object.isRequired,
  verificationEmailSendingRequest: PropTypes.object.isRequired,
  className: PropTypes.string,
  showAlert: PropTypes.func.isRequired,
  resetRequest: PropTypes.func.isRequired,
  sendVerificationEmail: PropTypes.func.isRequired
};

EmailVerificationReminder.defaultProps = {
  user: {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerificationReminder);
