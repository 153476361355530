import { isFunction } from 'lodash';
import { authenticateSocialnetwork } from 'modules/legacy/js/lib/api';
import tracking from 'modules/legacy/js/lib/tracking/user';
import { displayAlert } from 'modules/legacy/js/actions/app';
import i18n from 'i18n';

const PERSONAL_TYPE = 'personal';

const connectInstagram = ({ redirectTo, onRedirect }) =>
  new Promise((resolve, reject) => {
    const onSocialAccountLinkingAttempt = () => {
      tracking.track('INF - Link SNAccount Attempt', { type: 'instagram' });
    };

    const showLinkSocialNetworkError = () => {
      displayAlert({
        type: 'error',
        title: i18n.get('INF_SOCIAL_ACCOUNTS_LINK_NEW_LINK_ERROR_ALERT_TITLE'),
        message: i18n.get(
          'INF_SOCIAL_ACCOUNTS_LINK_NEW_LINK_ERROR_ALERT_MESSAGE'
        )
      });
    };

    const execute = () => {
      const { protocol, host } = window.location;
      const socialNetwork = 'instagram';
      const profileType = PERSONAL_TYPE;
      const redirectURL = `${protocol}//${host}/authenticate/${socialNetwork}${
        redirectTo ? `?redirectTo=${redirectTo}` : ''
      }`;
      const responseType = 'code';
      const payload = {
        socialNetwork,
        profileType,
        metaData: { redirectURL, responseType }
      };

      onSocialAccountLinkingAttempt();

      authenticateSocialnetwork(payload, (err, res) => {
        const { redirect } = res || {};

        if (!redirect) {
          showLinkSocialNetworkError();
          return reject();
        }

        if (isFunction(onRedirect)) onRedirect({ socialNetwork: 'instagram' });

        window.location.href = redirect;
      });
    };

    execute();
  });

export default connectInstagram;
