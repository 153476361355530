"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const SharedActions = require("redux/modules/shared");
const selectors_1 = require("modules/shared/selectors");
function withProductsTotalizer(Component) {
    class WrappedComponent extends React.Component {
        constructor(props) {
            super(props);
            this.ensureProductDetails = (props = this.props) => {
                const { fetchProducts, productsDetails, productsPayment } = props;
                const missingProductsIds = productsPayment.reduce((result, p) => {
                    if (!productsDetails[p.productId])
                        result.push(p.productId);
                    return result;
                }, []);
                if (lodash_1.isEmpty(missingProductsIds))
                    return Promise.resolve();
                return fetchProducts(missingProductsIds);
            };
            this.calculateTotals = () => {
                const { productsDetails, productsPayment } = this.props;
                const totals = productsPayment.reduce((result, p) => {
                    const { quantity = 0 } = p;
                    const price = quantity * lodash_1.get(productsDetails[p.productId], 'price', 0);
                    return {
                        totalProducts: result.totalProducts + quantity,
                        productsTotalPrice: result.productsTotalPrice + price
                    };
                }, { totalProducts: 0, productsTotalPrice: 0 });
                return totals;
            };
            this.ensureProductDetails();
        }
        render() {
            return (React.createElement(Component, Object.assign({}, this.props, this.calculateTotals())));
        }
    }
    WrappedComponent.defaultProps = {
        productsPayment: []
    };
    return react_redux_1.connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
}
function mapStateToProps(state, props) {
    const productIds = lodash_1.get(props, 'productsPayment', []).map(p => p.productId);
    return {
        productsDetails: selectors_1.getProductsByIds(state, productIds)
    };
}
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({
        fetchProducts: SharedActions.fetchProductsByIds
    }, dispatch);
}
exports.default = withProductsTotalizer;
