import {updateData, removeData} from '../entities';

const buildAlert = args => ({
  modelName: 'Alert',
  data: args,
  id: args.message
});

export const removeAlert = (message) => {
  const data = buildAlert({message});
  return dispatch => dispatch(removeData(data));
};

export const addAlert = (args) => {
  return (dispatch) => {
    const data = buildAlert({id: args.message, ...args});
    setTimeout(() => dispatch(removeData(data)), args.time || 5000);
    dispatch(updateData(data));
  };
};
