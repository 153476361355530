import { buildAPIRequest } from '../helpers';
import { activateParticipateLite } from '../../../services/voxfeed';

// eslint-disable-next-line import/prefer-default-export
export const toPostLite = id =>
  buildAPIRequest({
    params: id,
    id: `toPostLite_${id}`,
    method: activateParticipateLite.toPostLite
  });
