import { buildAPIRequest } from '../helpers';
import { socialNetworkLite } from '../../../services/voxfeed';

export const addSocialNetworkLite = params =>
  buildAPIRequest({
    params,
    id: 'addSocialNetworkLite',
    method: socialNetworkLite.addSocialNetworkLite
  });

export const updateSocialNetworkLite = params =>
  buildAPIRequest({
    params,
    id: `updateSocialNetworkLite_${params.id}`,
    method: socialNetworkLite.updateSocialNetworkLite
  });

export const getSocialNetworkLite = params =>
  buildAPIRequest({
    params,
    id: `getSocialNetworkLite_${params.id}`,
    method: socialNetworkLite.getSocialNetworkLite
  });

export const deleteSocialNetworkLite = params =>
  buildAPIRequest({
    params,
    id: `deleteSocialNetworkLite_${params.id}`,
    method: socialNetworkLite.deleteSocialNetworkLite
  });
