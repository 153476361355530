import { get } from 'lodash';
import Helpers from 'modules/legacy/js/lib/helpers';
import {
  update as updateDataStore,
  remove as removeDataFromDataStore
} from 'modules/legacy/js/actions/dataStore';

const UNAUTHORIZED_CODE = 401;
const USER_MODEL_NAME = 'User';
const ACCOUNT_MODEL_NAME = 'Account';

export default function getDataManagerAdapter(params = {}) {
  const {
    currentUser,
    updateReduxData,
    removeReduxData,
    updateReduxCurrentUserData,
    changeLanguage,
    logout
  } = params;

  const didCurrentUserWasUpdated = res => {
    const modelName = get(res, 'modelName');
    if (modelName !== USER_MODEL_NAME) return false;

    const modelId = get(res, 'id');
    const currentUserId = get(currentUser, '_id');

    return currentUserId === modelId;
  };

  const didCurrentAccountWasUpdated = res => {
    const modelName = get(res, 'modelName');
    if (modelName !== ACCOUNT_MODEL_NAME) return false;

    const modelId = get(res, 'id');
    const currentAccountId = get(currentUser, 'account._id');

    return currentAccountId === modelId;
  };

  const onUpdateError = err => {
    const code = get(err, 'status');
    if (code === UNAUTHORIZED_CODE) {
      logout();
      Helpers.destroyUserSession();
    }
  };

  const onUpdate = (err, res) => {
    if (err) return onUpdateError(err);

    const newData = get(res, 'data');

    updateDataStore(res);
    updateReduxData(res);

    if (didCurrentUserWasUpdated(res)) {
      changeLanguage(newData.locale);
      return updateReduxCurrentUserData({ user: newData });
    }

    if (didCurrentAccountWasUpdated(res)) {
      return updateReduxCurrentUserData({ account: newData });
    }
  };

  const onRemove = (err, res) => {
    removeDataFromDataStore(res);
    removeReduxData(res);
  };

  return { onUpdate, onRemove };
}
