import axios from 'axios';
const passwordHeader = 'x-password';

export function deleteUser(userId, password) {
  const config = {
    headers: {
      [passwordHeader]: password
    }
  };

  return axios.delete(`/users/${userId}`, config);
}
