import Reflux from 'reflux';
import { debounce, isArray, isEmpty, get, set } from 'lodash';
import DataStoreActions from '../actions/dataStore';

const DEFAULT_DATA = { User: {} };
const isProduction = process.env.NODE_ENV === 'production';

module.exports = Reflux.createStore({
  init() {
    this.listenTo(DataStoreActions.update, this._onUpdate);
    this.listenTo(DataStoreActions.remove, this._onRemove);
    this.listenTo(DataStoreActions.destroy, this._onDestroy);

    if (!isProduction && typeof window !== 'undefined')
      window.data = this._data;

    this.debouncedTrigger = debounce(this.trigger, 128, { leading: true });
  },

  _data: JSON.parse(JSON.stringify(DEFAULT_DATA)),

  getAll() {
    return this._data;
  },

  _onRemove(data) {
    const entities = this._data[data.modelName];
    if (entities) delete entities[data.id];
    this.trigger(this._data);
  },

  _onUpdate(newData, callback) {
    const { data, modelName, id } = newData;
    const lastUpdatedAt = get(this._data, `${modelName}.${id}.updatedAt`);

    if (data.updatedAt && lastUpdatedAt === data.updatedAt) return;
    if (!isProduction) console.log('\nUPDATING:', modelName, data);

    if (isArray(data)) this._updateMultipleEntries({ modelName, data });
    else this._updateSingleDataEntry({ id, modelName, data });

    callback && callback();
  },

  _updateMultipleEntries({ modelName, data }) {
    if (isEmpty(data)) return;

    data.forEach(element => {
      this._updateSingleDataEntry({
        modelName,
        id: element._id,
        data: element
      });
    });
  },

  _updateSingleDataEntry({ modelName, id, data }) {
    const existingRecord = get(this._data, `${modelName}.${id}`, {});
    const newValue = Object.assign(existingRecord, data);

    set(this._data, `${modelName}.${id}`, newValue);
    this.debouncedTrigger(this._data);
  },

  _onDestroy() {
    Object.assign(this._data, JSON.parse(JSON.stringify(DEFAULT_DATA)));
    this.trigger(this._data);
  }
});
