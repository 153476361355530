"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const appActions = require("redux/modules/app");
const ImagePreviewUI_1 = require("./ImagePreviewUI/ImagePreviewUI");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
class ImagePreview extends React.Component {
    constructor() {
        super(...arguments);
        this.handleOpenGallery = () => {
            const { src, showImagesGallery } = this.props;
            showImagesGallery([src]);
        };
    }
    render() {
        const _a = this.props, { showImagesGallery } = _a, uiProps = __rest(_a, ["showImagesGallery"]);
        return (React.createElement(ImagePreviewUI_1.default, Object.assign({}, uiProps, { onOpenGallery: this.handleOpenGallery })));
    }
}
const mapDispatchToProp = dispatch => redux_1.bindActionCreators({
    showImagesGallery: appActions.showImagesGallery
}, dispatch);
exports.default = react_redux_1.connect(null, mapDispatchToProp)(ImagePreview);
