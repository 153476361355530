"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogProps = void 0;
const React = require("react");
const Dialog_1 = require("../components/Dialog/Dialog");
Object.defineProperty(exports, "DialogProps", { enumerable: true, get: function () { return Dialog_1.DialogProps; } });
const withDialog = (Component) => class WithDialog extends React.Component {
    render() {
        return (React.createElement(Dialog_1.default, Object.assign({}, this.props),
            React.createElement(Component, Object.assign({}, this.props))));
    }
};
exports.default = withDialog;
