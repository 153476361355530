/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Reflux from 'reflux';
import qs from 'query-string';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, get } from 'lodash';
import { Link } from 'react-router-dom';
import createClass from 'create-react-class';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import { Form, InputWrapper, Checkbox, Button } from 'ui-library';

import i18n from 'i18n';
import emailRegex from 'utils/emailRegex';
import passwordRegex from 'utils/passwordRegex';
import App from './../../legacy/js/actions/app';
import Auth from './../../legacy/js/actions/auth';
import recaptcha from 'apps/shared/hocs/recaptcha';
import PartnerFormRoute from 'apps/advertisers/PartnerForm';
import { DebounceInput, FormLabel } from 'apps/shared/components';
import { HeroSections, CardForm } from 'modules/shared/components';

const Actions = { App, Auth };

const Login = createClass({
  displayName: 'LoginView',

  propTypes: {
    user: PropTypes.object,
    session: PropTypes.object,
    location: PropTypes.object,
    partnerSettings: PropTypes.object,
    loginRequest: PropTypes.func.isRequired,
    currentLanguage: PropTypes.string.isRequired
  },

  mixins: [
    LinkedStateMixin,
    Reflux.listenTo(Actions.Auth.signinError, 'onAuthFailed'),
    Reflux.listenTo(Actions.App.reCAPTCHALoaded, 'updateReCaptchaWidget')
  ],

  emailInput: React.createRef(),
  passwordInput: React.createRef(),

  getInitialState() {
    return {
      email: '',
      password: '',
      logged: false,
      errorMessage: '',
      showErrors: false,
      showPassword: false,
      isEmailValid: false,
      isPasswordValid: false,
      rememberAccount: false
    };
  },

  UNSAFE_componentWillReceiveProps(props) {
    const { user, session, location } = props;
    const { error } = session || {};

    const didUserChange = !this.props.user && !!user;
    const query = qs.parse(location.search);

    const hasError = !!error;
    const hasRedirect = get(session, 'error.meta.redirectTo');

    if (hasError && !hasRedirect) {
      return this.onAuthFailed(get(error, 'message'));
    }

    if (!didUserChange) return null;

    const payloadOfSuccesfulLoginEvent = {
      user,
      account: user.account,
      token: session.token
    };

    return Actions.Auth.signinSuccess(
      payloadOfSuccesfulLoginEvent,
      query.redirectTo
    );
  },

  onAuthFailed(message) {
    Actions.App.hideLoader();
    Actions.App.displayAlert({
      type: 'error',
      message
    });

    this.props.updateReCaptchaWidget();
  },

  onSendLoginData(ev) {
    ev.preventDefault();

    this.checkAutoComplete();

    if (!this.isFormValid()) return this.setState({ showErrors: true });

    return this.sendLoginRequest();
  },

  isFormValid() {
    const { isEmailValid, isPasswordValid } = this.state;
    return isEmailValid && isPasswordValid;
  },

  onToggleRemember() {
    this.setState({ rememberAccount: !this.state.rememberAccount });
  },

  handleEmailChange(ev) {
    const email = ev.target.value;
    this.setEmail(email);
  },

  setEmail(email) {
    const isEmailValid = emailRegex.test(email);

    this.setState({ email, isEmailValid });
  },

  handlePasswordChange(ev) {
    const password = ev.target.value;
    this.setPassword(password);
  },

  setPassword(password) {
    const isPasswordValid = passwordRegex.test(password);

    this.setState({ isPasswordValid, password });
  },

  handleToggleShowPassword() {
    this.setState(prev => ({ showPassword: !prev.showPassword }));
  },

  sendLoginRequest() {
    const { loginRequest, location } = this.props;
    const { email, password } = this.state;
    const query = qs.parse(location.search);
    const { redirectTo } = query;

    loginRequest({ email, password, redirectTo, openLoader: false });
  },

  checkAutoComplete() {
    const email = this.emailInput.current.value;
    const pass = this.passwordInput.current.value;

    if (isEmpty(this.state.email) && isEmpty(this.state.password)) {
      if (!isEmpty(pass) && !isEmpty(email)) {
        this.setEmail(email);
        this.setPassword(pass);
      }
    }
  },

  render() {
    const {
      showErrors,
      showPassword,
      isEmailValid,
      rememberAccount,
      isPasswordValid
    } = this.state;

    const { partnerSettings } = this.props;

    if (partnerSettings) return <PartnerFormRoute />;

    return (
      <HeroSections
        linkTo='/register'
        title={i18n.getHTML('LOGIN_HERO_TITLE')}
        buttonText={i18n.get('LOGIN_DONT_HAVE_ACCOUNT')}
      >
        <CardForm
          title={i18n.get('LOGIN_WELCOME')}
          subtitle={i18n.get('LOGIN_EMAIL_AND_PASSWORD')}
        >
          <Form onSubmit={this.onSendLoginData}>
            <div className='form'>
              <div className='vf-input-group'>
                <FormLabel htmlFor='email'>{i18n.get('LOGIN_EMAIL')}</FormLabel>

                <InputWrapper className='lg margin-bottom-2x'>
                  <i className='vf-icon icon-mail' />

                  <DebounceInput
                    type='email'
                    name='email'
                    ref={this.emailInput}
                    className='login-input'
                    onChange={this.handleEmailChange}
                    hasError={showErrors && !isEmailValid}
                    placeholder={i18n.get('LOGIN_EMAIL_PLACEHOLDER')}
                  />
                </InputWrapper>

                <FormLabel htmlFor='password'>
                  {i18n.get('LOGIN_PASSWORD')}
                </FormLabel>

                <InputWrapper className='lg'>
                  <i className='vf-icon icon-locked' />

                  <DebounceInput
                    name='password'
                    className='login-input'
                    ref={this.passwordInput}
                    onChange={this.handlePasswordChange}
                    type={showPassword ? 'text' : 'password'}
                    hasError={showErrors && !isPasswordValid}
                    placeholder={i18n.get('LOGIN_PASSWORD_PLACEHOLDER')}
                  />

                  <i
                    className={classNames('show-password', {
                      'icon-eye-disabled': showPassword,
                      'icon-eye-1': !showPassword
                    })}
                    onClick={this.handleToggleShowPassword}
                  />
                </InputWrapper>

                <InputWrapper hidden />
              </div>

              {showErrors && (!isEmailValid || !isPasswordValid) && (
                <div className='col vf-text-danger margin-top-1x'>
                  {!isEmailValid && (
                    <div>{i18n.get('LOGIN_LOGIN_ERROR_INVALID_EMAIL')}</div>
                  )}

                  {!isPasswordValid && (
                    <div>{i18n.get('LOGIN_LOGIN_ERROR_PASSWORD_LENGTH')}</div>
                  )}
                </div>
              )}

              <div
                id='recaptcha'
                ref='recaptcha'
                className='recaptcha-container'
              />

              <div className='vf-row width100 no-margin'>
                <div className='col-xs-6 flex start-xs checkbox'>
                  <Checkbox
                    checked={rememberAccount}
                    onChange={this.onToggleRemember}
                  >
                    {i18n.get('LOGIN_REMEMBER_ME')}
                  </Checkbox>
                </div>

                <div className='col-xs-6 flex end-xs'>
                  <Link to='/forgot' className='vf-font-bold'>
                    {i18n.get('LOGIN_FORGOT_PASSWORD')}
                  </Link>
                </div>
              </div>

              <Button
                size='lg'
                color='primary'
                onClick={this.onSendLoginData}
                className='width100 button-success margin-top-2x'
              >
                {i18n.get('LOGIN_LOGIN')}
              </Button>
            </div>
          </Form>
        </CardForm>
      </HeroSections>
    );
  }
});

export default recaptcha(Login);
