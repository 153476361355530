import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loginRequest } from 'redux/modules/shared';
import {
  showLoading,
  hideLoading,
  showAlert,
  languageChange
} from 'redux/modules/app';
import LoginForm from 'modules/shared/containers/Login';
import { signinSuccess as onLoginSuccess } from 'modules/legacy/js/actions/auth';

function mapStateToProps({ app, shared }) {
  const { user, session } = shared;
  const { currentLanguage, partnerSettings } = app;
  return { user, session, currentLanguage, partnerSettings, onLoginSuccess };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginRequest,
      showAlert,
      showLoading,
      hideLoading,
      languageChange
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
