import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import i18n from 'i18n';

import * as chatsActions from 'redux/modules/chats';

import styles from './FloatingChatTrigger.less';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openChat: chatsActions.openChatWithInfluencer,
      openChatBrandSelector: chatsActions.openChatBrandSelector
    },
    dispatch
  );
}

class FloatingChatTrigger extends Component {
  static propTypes = {
    influencerId: PropTypes.string.isRequired,
    brandId: PropTypes.string,
    className: PropTypes.string,
    openChat: PropTypes.func.isRequired,
    openChatBrandSelector: PropTypes.func.isRequired,
    onRequestOpen: PropTypes.func,
    size: PropTypes.string
  };

  static defaultProps = {
    brandId: '',
    className: '',
    size: 'md',
    onRequestOpen: () => {}
  };

  onOpenChat = ev => {
    ev.stopPropagation();
    const { brandId, influencerId, openChat, onRequestOpen } = this.props;

    onRequestOpen();
    if (!brandId) return this.openChatBrandSelector();
    openChat({ brandId, influencerId });
  };

  openChatBrandSelector = () => {
    const { openChatBrandSelector, influencerId } = this.props;
    const provisionalChatId = `unknown_${influencerId}`;

    openChatBrandSelector({
      influencerId,
      id: provisionalChatId
    });
  };

  render = () => {
    const { className, size } = this.props;
    const messageIconClassName = classNames(
      'vf-icon icon-message',
      className,
      styles.icon,
      {
        [styles.sm]: size === 'sm',
        [styles.md]: size === 'md'
      }
    );

    return (
      <i
        title={i18n.get('ADV_CAMPAIGNS_INVITES_OPEN_CHAT')}
        className={messageIconClassName}
        onClick={this.onOpenChat}
      />
    );
  };
}

export default connect(null, mapDispatchToProps)(FloatingChatTrigger);
