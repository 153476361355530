import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { flatten, map, findIndex, get } from 'lodash';
import classNames from 'classnames';

const Table = createClass({
  propTypes: {
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        render: PropTypes.any.isRequired, // the content to display
        sort: PropTypes.object, // is this column sortable?
        tooltip: PropTypes.string // helper text
      })
    ),
    data: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          render: PropTypes.any,
          value: PropTypes.any,
          className: PropTypes.any
        })
      )
    ),
    footers: PropTypes.array,
    onRequestSort: PropTypes.func,
    onRowClick: PropTypes.func,
    sortBy: PropTypes.string,
    inverseOrder: PropTypes.bool
  },

  getInitialState() {
    return this.updateSortBy(this.props);
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.updateSortBy(nextProps));
  },

  updateSortBy(props) {
    const { headers, sortBy = null, inverseOrder = false } = props;

    const headerIndex = findIndex(headers, header => {
      const headerSortValue = get(header, 'sort.value');
      return headerSortValue === sortBy;
    });

    const headerWasFound = headerIndex !== -1;

    return {
      sortBy: headerWasFound ? headerIndex : null,
      inverseOrder: headerWasFound ? inverseOrder : false
    };
  },

  sortBy(index) {
    this.setState(
      {
        sortBy: index,
        inverseOrder:
          index === this.state.sortBy ? !this.state.inverseOrder : false
      },
      () => {
        if (this.props.onRequestSort) {
          this.props.onRequestSort({
            sortBy: this.props.headers[this.state.sortBy].sort,
            inverseOrder: this.state.inverseOrder
          });
        }
      }
    );
  },

  onRowClick(index, event) {
    if (this.props.onRowClick) {
      this.props.onRowClick(event, index);
    }
  },

  render() {
    const { data, headers, footers } = this.props;
    const { sortBy, inverseOrder } = this.state;

    const TableComponent = (
      <table className='vf-table'>
        <thead>
          <tr>
            {map(headers, (element, index) => {
              const arrowClassNames = classNames(
                'vf-table-toggle',
                'icon-chevron-up',
                {
                  active: index === sortBy,
                  inversed: index === sortBy && !!inverseOrder
                }
              );

              return (
                <td
                  className={element.sort ? 'sortable' : ''}
                  onClick={!!element.sort && this.sortBy.bind(null, index)}
                  key={index}
                >
                  <div className={element.className || 'flex'}>
                    {element.render}
                    {element.sort ? <span className={arrowClassNames} /> : null}
                  </div>
                </td>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {flatten(
            map(data, (element, index) => {
              return [
                <tr
                  className='tr-item'
                  key={index}
                  onClick={this.onRowClick.bind(null, index)}
                >
                  {map(element, (childElement, childIndex) => {
                    return (
                      <td
                        className=''
                        key={childIndex}
                        title={childElement.title}
                      >
                        <div
                          className={`flex height100 ${
                            childElement.className || ''
                          }`}
                        >
                          {typeof childElement.render === 'number'
                            ? childElement.render.toLocaleString()
                            : childElement.render}
                        </div>
                      </td>
                    );
                  })}
                </tr>,
                footers && footers[index] && (
                  <tr key={'footers-' + index}>
                    <td className='-footer' colSpan={headers.length}>
                      {footers[index]}
                    </td>
                  </tr>
                )
              ];
            })
          )}
        </tbody>
      </table>
    );

    return TableComponent;
  }
});

export default Table;
