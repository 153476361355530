import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, find, get } from 'lodash';
import classNames from 'classnames';

import getChatName from 'utils/getChatName';

import { openFloatingChat } from 'redux/modules/chats';

import {
  getBrandFloatingChats,
  getAllFloatingChats,
  getCurrentUser
} from 'modules/shared/selectors';

import Popover from 'modules/legacy/js/components/popover';
import FloatingChat from '../FloatingChat/FloatingChat';

import styles from './Main.less';

const MAX_CHATS_TO_SHOW = 3;
const ARRAY_START_INDEX = 0;

function mapStateToProps(state, { brandId }) {
  const floatingChats = brandId
    ? getBrandFloatingChats(state, brandId)
    : getAllFloatingChats(state);

  return {
    floatingChats,
    currentUser: getCurrentUser(state),
    chats: get(state, 'entities.Chat'),
    members: get(state, 'entities.ChatMember')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openFloatingChat }, dispatch);
}

class Main extends Component {
  static propTypes = {
    brandId: PropTypes.string,
    floatingChats: PropTypes.array.isRequired,
    chats: PropTypes.object,
    currentUser: PropTypes.object.isRequired,
    members: PropTypes.object,
    openFloatingChat: PropTypes.func.isRequired,
    isVisible: PropTypes.bool,
    style: PropTypes.object
  };

  static defaultProps = {
    isVisible: true,
    brandId: '',
    chats: null,
    members: null,
    style: {}
  };

  renderChats = () => {
    const { floatingChats, brandId, currentUser } = this.props;

    return floatingChats
      .slice(ARRAY_START_INDEX, MAX_CHATS_TO_SHOW)
      .map(chat => (
        <FloatingChat
          key={`${chat.id}_${chat.openAt}_${brandId}`}
          chatId={chat.id}
          brandId={chat.brandId || brandId}
          influencerId={chat.influencerId}
          isCollapsed={chat.isCollapsed}
          allowToSwitchBrand={currentUser.role === 'advertiser' && !brandId}
        />
      ));
  };

  renderExtraChatsContainer = () => {
    const { floatingChats } = this.props;
    const extraChats = floatingChats.slice(MAX_CHATS_TO_SHOW);

    if (isEmpty(extraChats)) return <noscript />;

    const body = this.renderExtraChatsPopover(extraChats);

    return (
      <Popover hideWithClick body={body} preferPlace='above' padding={false}>
        <div className='col-xs-1 flex end-xs'>
          <div className={styles['extra-chats']}>
            <i className='vf-icon icon-message' />
          </div>
        </div>
      </Popover>
    );
  };

  renderExtraChatsPopover = extraChats => {
    const { chats, currentUser, members, brandId } = this.props;

    const rows = extraChats.map(c => {
      const chat = chats[c.id];
      const member = find(
        members,
        m => m.chatId === chat.id && m.userId === currentUser._id
      );

      return (
        <ExtraChat
          key={c.id}
          className={styles['extra-chat-row']}
          chat={chat}
          member={member}
          currentUser={currentUser}
          onClick={() =>
            this.props.openFloatingChat({
              brandId,
              chatId: c.id
            })
          }
        />
      );
    });

    return <div>{rows}</div>;
  };

  render = () => {
    const { floatingChats, isVisible, style } = this.props;

    const containerClassNames = classNames(
      'vf-row no-margin',
      styles.container,
      {
        'vf-hide': !isVisible
      }
    );
    const rowClassNames = classNames(
      'col-xs-11 flex start-xs bottom-xs',
      styles.row
    );

    return (
      <div
        id='floating-chats-bar'
        className={containerClassNames}
        style={style}
      >
        {!isEmpty(floatingChats) && (
          <div className={rowClassNames}>
            {this.renderChats()}
            {this.renderExtraChatsContainer()}
          </div>
        )}
      </div>
    );
  };
}

function ExtraChat(props) {
  const { chat, member, currentUser, onClick } = props;
  const lastReadMessageIndex = get(member, 'lastReadMessage.index', -1);
  const totalUnreadMessages = Math.max(
    chat.totalMessages - (lastReadMessageIndex + 1),
    0
  );
  const unreadMessagesText =
    totalUnreadMessages > 0 ? `(${totalUnreadMessages})` : '';

  return (
    <div className={styles['extra-chat-row']} onClick={onClick}>
      {`${getChatName({ currentUser, chat })} ${unreadMessagesText}`}
    </div>
  );
}

ExtraChat.propTypes = {
  chat: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
