"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./BudgetCapacityCard.less");
const BudgetCapacityCard = ({ budgetCapacity, canSendRequest, className, style, onClick, children }) => {
    const cardClassName = classnames_1.default('vf-card', styles.card, className, {
        [styles['low-border']]: canSendRequest && budgetCapacity === 'low',
        [styles['medium-border']]: canSendRequest && budgetCapacity === 'medium',
        [styles['high-border']]: canSendRequest && budgetCapacity === 'high',
        [styles.disabled]: !canSendRequest,
        [styles.hover]: canSendRequest,
        'vf-clickable': canSendRequest
    });
    return (React.createElement("div", { className: cardClassName, style: style, onClick: onClick }, children));
};
exports.default = BudgetCapacityCard;
