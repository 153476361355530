"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles = require("./PostStatsGridUI.less");
const i18n = require("i18n");
const lodash_1 = require("lodash");
const images_1 = require("assets/images");
const ProductPopover_1 = require("../../ProductPopover/ProductPopover");
class PostStatsGridUI extends React.PureComponent {
    render() {
        const { section1, section2, section3, canReadCampaignCost, productQuantity, productsPayment } = this.props;
        const section1List = section1.map(stat => {
            return stat ? (React.createElement(Info, { key: stat.key, title: stat.title, detail: stat.detail, info: stat.info, image: PostStatsGridUI.LOGOS[stat.key], "data-uitest": `stats-${lodash_1.kebabCase(stat.key)}` })) : null;
        });
        const section2List = section2.map(stat => {
            return stat ? (React.createElement(Info, { key: stat.key, title: stat.title, detail: stat.detail, info: stat.info, "data-uitest": `stats-${lodash_1.kebabCase(stat.key)}` })) : null;
        });
        const section3List = section3.map(stat => {
            return stat ? (React.createElement(Info, { key: stat.key, title: stat.title, detail: stat.detail, info: stat.info, "data-uitest": `stats-${lodash_1.kebabCase(stat.key)}` })) : null;
        });
        if (productQuantity > 0) {
            section3List.push(React.createElement(ProductInfo, { key: 'product', icon: 'icon-e-commerce', title: i18n.get('PAY_IN_KIND_PRODUCTS'), detail: i18n.get('PAY_IN_KIND_PRODUCTS'), info: productQuantity, productsPayment: productsPayment }));
        }
        return (React.createElement("div", { className: 'padding-top-2x', style: { marginBottom: '-16px' } },
            section1List.length > 0 && [
                React.createElement("div", { key: 'div', className: styles['stats-grid'] }, section1List),
                React.createElement("hr", { key: 'hr' })
            ],
            React.createElement("div", { className: styles['stats-grid'] }, section2List),
            canReadCampaignCost && (React.createElement(React.Fragment, null,
                React.createElement("hr", { key: 'hr' }),
                React.createElement("div", { key: 'grid', className: styles['stats-grid'] }, section3List)))));
    }
}
PostStatsGridUI.LOGOS = {
    likes: images_1.fbLikeImg,
    reactionsLove: images_1.fbLoveImg,
    reactionsHaha: images_1.fbHahaImg,
    reactionsWow: images_1.fbWowImg,
    reactionsSad: images_1.fbSadImg,
    reactionsAngry: images_1.fbAngryImg
};
exports.default = PostStatsGridUI;
const Info = (_a) => {
    var { title, info, image, icon, detail } = _a, others = __rest(_a, ["title", "info", "image", "icon", "detail"]);
    return (React.createElement("div", Object.assign({ className: 'text-center padding-horizontal-1x' }, others),
        React.createElement("div", { className: 'flex vf-font-bold flex-justify-center', title: detail },
            icon && (React.createElement("span", { style: { fontSize: '2.2rem', height: 24 } },
                React.createElement("i", { className: `vf-icon ${icon}` }))),
            title,
            image && (React.createElement("img", { src: image, alt: 'reaction', className: styles['reaction-image'] }))),
        React.createElement("p", { className: styles.info }, info)));
};
const ProductInfo = (_a) => {
    var { productsPayment } = _a, others = __rest(_a, ["productsPayment"]);
    return (React.createElement(ProductPopover_1.default, { productsPayment: productsPayment },
        React.createElement(Info, Object.assign({}, others))));
};
