"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const lodash_1 = require("lodash");
class DebounceTextarea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: lodash_1.get(this.props, 'value', '')
        };
        this.debouncedChange = null;
        this.handleChange = event => {
            event.persist();
            const { value } = event.target;
            this.setState({ value }, () => this.debouncedChange(event));
        };
        this.debouncedChange = lodash_1.debounce(props.onChange, 200);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value });
        }
    }
    render() {
        const _a = this.props, { value } = _a, others = __rest(_a, ["value"]);
        return (React.createElement(ui_library_1.Textarea, Object.assign({}, others, { value: this.state.value, onChange: this.handleChange })));
    }
}
exports.default = DebounceTextarea;
