"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const orderBy = require("lodash/orderBy");
const i18n = require("i18n");
const GridlessCharts_1 = require("modules/shared/components/GridlessCharts");
const BARS_COLORS = [
    '#8fdee4',
    '#5bd9e5',
    '#40acb6',
    '#1a8994',
    '#0e4e64',
    '#1f2626'
];
class AgeRanges extends React.PureComponent {
    render() {
        const { stats, style } = this.props;
        const ages = orderBy(stats.ages, ['name'], ['asc']);
        return (React.createElement("div", { style: style },
            React.createElement(GridlessCharts_1.VerticalBarChart, { data: {
                    labels: getLabels(ages),
                    datasets: [
                        {
                            backgroundColor: BARS_COLORS,
                            categoryPercentage: 0.5,
                            barPercentage: 1.0,
                            barThickness: 20,
                            data: getValues(ages)
                        }
                    ]
                }, title: i18n.get('ADV_INFLUENCER_PROFILE_AUDIENCE_AGE_RANGES'), xOptions: {
                    ticks: { fontStyle: 'bold' }
                }, chartOptions: { height: 200 }, valuesOptions: { isPercentual: true, fontColor: '#767676' } })));
    }
}
AgeRanges.defaultProps = {
    style: {},
    stats: {
        ages: []
    }
};
function getLabels(ages = []) {
    return ages.map(age => `${age.min}${!!age.max ? `-${age.max}` : ''}`);
}
function getValues(ages = []) {
    return ages.map(age => Math.floor(age.value * 100));
}
exports.default = AgeRanges;
