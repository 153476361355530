"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const styles = require("./PartnerFooterUI.less");
const classnames_1 = require("classnames");
const components_1 = require("apps/shared/components");
const dist_1 = require("ui-library/dist");
const PartnerFooterUI = ({ currentLanguage, availableLanguages, onLangChange }) => (React.createElement("div", { className: classnames_1.default('margin-top-3x center-xs', styles.footer) },
    React.createElement(components_1.PowerByVoxfeed, null),
    React.createElement("div", { className: 'flex margin-top-1x flex-justify-space-around padding-horizontal-Hx' },
        React.createElement("a", { target: '_blank', href: i18n.get('HELP_LINKS_CENTER') }, i18n.get('LOGIN_SUPPORT_LINKS_HELP')),
        React.createElement("a", { target: '_blank', href: 'https://voxfeed.com/terms' }, i18n.get('LOGIN_SUPPORT_LINKS_TOS')),
        React.createElement("a", { target: '_blank', href: 'https://voxfeed.com/privacy' }, i18n.get('LOGIN_SUPPORT_LINKS_PRIVACY')),
        React.createElement("a", { target: '_blank', href: 'https://voxfeed.com/contact' }, i18n.get('LOGIN_SUPPORT_LINKS_CONTACT')),
        React.createElement(dist_1.Select, { title: '', value: currentLanguage, options: availableLanguages, onChange: (e, code) => onLangChange(code), customComponent: React.createElement("button", { className: classnames_1.default('vf-clear-button flex', styles['select-button']) },
                availableLanguages[currentLanguage],
                React.createElement("span", { className: 'margin-left-Hx', style: { height: '17px' } },
                    React.createElement("i", { className: 'vf-icon icon-caret-down-two' }))) }))));
exports.default = PartnerFooterUI;
