import { isFunction } from 'lodash';
import store from 'store2';
import i18n from 'i18n';

import { getStatics } from 'config/app';
import tracking from 'modules/legacy/js/lib/tracking/user';
import { displayAlert } from 'modules/legacy/js/actions/app';

const SOCIAL_NETWORK = 'instagram';

const connectFacebook = (params = {}) =>
  new Promise((resolve, reject) => {
    const longLivedToken = store.get('fbLongLivedToken');
    if (longLivedToken) return resolve();

    const { onRedirect } = params;

    const facebookPermissionsAreValid = grantedPermissions => {
      const { instagramPermissions } = getStatics();
      return instagramPermissions.every(permission =>
        grantedPermissions.includes(permission)
      );
    };

    const showFacebookPermissionsError = () => {
      displayAlert({
        type: 'error',
        title: i18n.get(
          'INF_SOCIAL_ACCOUNTS_LINK_NEW_PERMISSIONS_ERROR_ALERT_TITLE'
        ),
        message: i18n.get(
          'INF_SOCIAL_ACCOUNTS_LINK_NEW_PERMISSIONS_ERROR_ALERT_MESSAGE'
        )
      });
    };

    const showLinkSocialNetworkError = () => {
      displayAlert({
        type: 'error',
        title: i18n.get('INF_SOCIAL_ACCOUNTS_LINK_NEW_LINK_ERROR_ALERT_TITLE'),
        message: i18n.get(
          'INF_SOCIAL_ACCOUNTS_LINK_NEW_LINK_ERROR_ALERT_MESSAGE'
        )
      });
    };

    const onValidFacebookUser = response => {
      const grantedPermissions = (response.grantedScopes || '').split(',');

      if (!facebookPermissionsAreValid(grantedPermissions)) {
        showFacebookPermissionsError();
        return reject();
      }

      if (isFunction(onRedirect)) onRedirect({ socialNetwork: SOCIAL_NETWORK });

      resolve();
    };

    const onSocialAccountLinkingAttempt = () => {
      tracking.track('INF - Link SNAccount Attempt', { type: SOCIAL_NETWORK });
    };

    const onFacebookLoginResponse = response => {
      if (response.authResponse) {
        return onValidFacebookUser(response.authResponse);
      }
      showLinkSocialNetworkError();
      reject();
    };

    const execute = () => {
      onSocialAccountLinkingAttempt();

      const { instagramPermissions } = getStatics();
      const options = {
        scope: instagramPermissions.join(','),
        return_scopes: true,
        auth_type: 'rerequest'
      };

      FB.login(onFacebookLoginResponse, options);
    };

    execute();
  });

export default connectFacebook;
