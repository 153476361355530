import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import classNames from 'classnames';
import { defaultCampaignCoverImg } from 'assets/images';

const FallbackVideoImage = createClass({
  propTypes: {
    title: PropTypes.string,
    markupContent: PropTypes.any,
    imageType: PropTypes.string
  },

  getInitialState() {
    return {
      title: '',
      markupContent: '',
      imageType: 'lock'
    };
  },

  render() {
    const { title, markupContent, imageType } = this.props;

    const cardClasses = classNames('col-xs-12 col-sm-12 padding-3x');

    const classes = classNames('card', cardClasses, {
      rounded: true,
      depth: true,
      bordered: true
    });

    const style = { backgroundImage: `url('${defaultCampaignCoverImg}')` };

    return (
      <div style={style} className={classes}>
        <div className='vf-gradient'></div>
        <i className='icon icon-video vf-upload-image-icon flex flex-justify-center flex-stretch padding-3x' />
        <span className='vf-text-white '>{markupContent}</span>
      </div>
    );
  }
});

export default FallbackVideoImage;
