import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import i18n from 'i18n';

export const ProfileSection = ({ onNext, onBack }) => (
  <div
    className='col center-xs'
    style={{ marginTop: '1rem', marginRight: '8rem' }}
  >
    <h4 className='vf-font-thin'>
      {i18n.get('ADV_BRANDS_PROFILE_SECTION_DESCRIPTION')}
    </h4>
    <div className='flex center-xs margin-vertical-1x'>
      <Button size='sm' className='margin-right-Hx' onClick={onBack}>
        {i18n.get('BACK')}
      </Button>

      <Button size='sm' color='success' onClick={onNext}>
        {i18n.get('NEXT')}
      </Button>
    </div>
  </div>
);

ProfileSection.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func
};
