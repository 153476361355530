import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { get, find } from 'lodash';
import { Button } from 'ui-library';
import i18n from 'i18n';

import { showModal } from 'redux/modules/app';
import { fetchParticipationFeedback } from 'redux/modules/campaigns';

import { LoadingState, RateSelector } from 'modules/shared/components';
import FeedbackDialog from '../ParticipationFeedbackDialog';

import styles from './ParticipationFeedbackCard.less';

const REQUEST_ID = 'fetchParticipationFeedback';

function mapStateToProps({ entities, app }, { inviteId }) {
  const invite = get(entities, `Invite.${inviteId}`, {});
  const campaign = get(entities, `Campaign.${invite.campaign}`, {});
  const request = get(app, `requests.${REQUEST_ID}`, {});
  const feedbacks = get(entities, 'CampaignFeedback', {});
  const feedback = find(feedbacks, f => f.inviteId === inviteId);

  return {
    invite,
    campaign,
    feedback,
    request
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchParticipationFeedback,
      showModal
    },
    dispatch
  );
}

class ParticipationFeedbackCard extends Component {
  constructor(props) {
    super(props);

    const { inviteId, fetchParticipationFeedback } = props;

    fetchParticipationFeedback(inviteId).then(() =>
      this.checkIfFeedbackIsRequired()
    );
  }

  checkIfFeedbackIsRequired = () => {
    const { feedback, location } = this.props;
    const isSpecifiedInURL = !!get(
      qs.parse(location.search.slice(1)),
      'showFeedbackDialog',
      false
    );

    if (!isSpecifiedInURL) return;
    if (!feedback) this.showFeedbackDialog();
  };

  showFeedbackDialog = () => {
    const { inviteId, invite, campaign, showModal } = this.props;

    showModal(
      <FeedbackDialog
        inviteId={inviteId}
        campaignId={invite.campaign}
        onDone={() => this.props.fetchParticipationFeedback(inviteId)}
        brand={{
          name: campaign.brandName,
          image: campaign.brandImage
        }}
      />
    );
  };

  renderBody = () => {
    const { request, feedback } = this.props;
    const isLoading = request.status === 'loading';
    const hasFeedback = !!feedback;

    if (isLoading) return <LoadingState />;
    if (hasFeedback) return <ExistingFeedback feedback={feedback} />;
    return <PendingFeedback onShowDialog={this.showFeedbackDialog} />;
  };

  render = () => {
    return (
      <div className='vf-card flex start-xs width100 padding-1x'>
        {this.renderBody()}
      </div>
    );
  };
}

ParticipationFeedbackCard.propTypes = {
  inviteId: PropTypes.string.isRequired,
  invite: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  feedback: PropTypes.string,
  request: PropTypes.object.isRequired,
  fetchParticipationFeedback: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

ParticipationFeedbackCard.defaultProps = {
  feedback: ''
};

function PendingFeedback({ onShowDialog }) {
  return (
    <div>
      {i18n.get('INF_FEEDBACK_CARD_PENDING_FEEDBACK_BODY')}
      <div className='flex center-xs width100'>
        <Button
          size='sm'
          color='primary'
          className='margin-top-1x margin-bottom-Hx'
          onClick={onShowDialog}
        >
          {i18n.get('INF_FEEDBACK_CARD_PENDING_FEEDBACK_CTA')}
        </Button>
      </div>
    </div>
  );
}

PendingFeedback.propTypes = {
  onShowDialog: PropTypes.func.isRequired
};

function ExistingFeedback({ feedback }) {
  const { rate, influencerMessage = {}, advertiserMessage = {} } = feedback;
  const { content: influencerComments } = influencerMessage;
  const { content: advertiserReply } = advertiserMessage;

  return (
    <div className='col'>
      <span className='vf-font-bold' style={{ fontSize: '1.75rem' }}>
        {i18n.get('INF_FEEDBACK_CARD_EXISTING_FEEDBACK_TITLE')}
      </span>

      <div className='flex center-xs width100 margin-top-1x'>
        <RateSelector readOnly rate={rate} />
      </div>

      {!!influencerComments && (
        <span
          className='margin-top-1x'
          style={{
            whiteSpace: 'pre-line',
            wordBreak: 'break-word'
          }}
        >
          "{influencerComments}"
        </span>
      )}

      {!!advertiserReply && (
        <div className='col margin-top-2x'>
          <span className='vf-font-bold' style={{ fontSize: '1.75rem' }}>
            {i18n.get('INF_FEEDBACK_CARD_ADVERTISER_REPLY_TITLE')}
          </span>

          <div className='flex end-xs'>
            <div
              className='flex end-xs vf-bg-gray-light-color margin-top-Hx margin-bottom-1x padding-1x'
              style={{
                borderRadius: '1rem',
                borderBottomRightRadius: '0',
                whiteSpace: 'pre-line',
                wordBreak: 'break-word'
              }}
            >
              {advertiserReply}
              <div className={styles['globe-tail']} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

ExistingFeedback.propTypes = {
  feedback: PropTypes.object.isRequired
};

const Enhanced = withRouter(ParticipationFeedbackCard);
export default connect(mapStateToProps, mapDispatchToProps)(Enhanced);
