import getStatics from './statics';

const MAX_IMAGE_SIZE = 10 * 1024 * 1024;
const MAX_VIDEO_SIZE = 100 * 1024 * 1024;
const PLAYABLE_VIDEO_TYPE = 'video/mp4';
const MIN_SCORE_TO_SHOW_RELIABILITY = 10;
const DEFAULT_CAMPAIGN_DIFFICULTY = 0.5;
const MIN_INVESTMENT = 10;
const ASSETS_CDN_URL = 'https://assets.voxfeed.com/app';
const CHECK_SWIFT_URL = 'https://transferwise.com/us/swift-codes/countries';
const VOXFFED_PAY_SITE_URL = 'https://join.voxfeed.com/es/pay/';
const MAX_CHAT_ATTACHMENTS = 4;
const MAX_CAMPAIGN_GUIDELINES = 6;
const MAX_CAMPAIGN_GUIDELINE_LENGTH = 60;
const MAX_CAMPAIGN_BRIEF_LENGTH = 1200;
const MAX_CAMPAIGN_EXAMPLE_IMAGES = 4;
const MIN_HOURS_TO_SEND_PROPOSAL = 24;
const MAX_HOURS_TO_SEND_PROPOSAL = 24 * 30;
const RECOMMENDED_HOURS_TO_SEND_PROPOSALS = 24 * 3;
const MAX_PUBLICATIONS_PER_PARTICIPATION = 5;
const MIN_LENTH_FOR_PHONE_NUMBER = 8;
const MAX_LENGTH_FOR_PHONE_NUMBER = 11;
const LENGTH_CODE_PHONE_NUMBER = 2;
const DIGIT_REGEX = /^[0-9]*$/;

const getRootUrl = () => {
  const { protocol, host } = window.location;
  return `${protocol}//${host}`;
};

function getRealCampaignDifficulty(
  currentDifficulty = DEFAULT_CAMPAIGN_DIFFICULTY
) {
  const realDifficulty = (Number(currentDifficulty) - 0.2) / (0.8 - 0.2);
  return Math.max(Math.round(realDifficulty * 100) / 100, 0);
}

module.exports = {
  environment: process.env.NODE_ENV,

  getRootUrl,
  getRealCampaignDifficulty,
  getStatics,
  getBankVersion: () => process.env.BANK_VARIATION || 'simple_asia',
  getBankPaymentTime: () => process.env.BANK_MAX_DAYS_PAYMENT || 45,
  getCrispId: () => process.env.CRISP_WEB_ID,
  getDefaultCurrency: () => process.env.CURRENCY || 'MNX',
  getDefaultSymbol: () => process.env.CURRENCY_SYMBOL || '$',
  isWhiteLabel: () => process.env.WHITELABEL,
  getEasyLexContract: () => process.env.EASYLEX_CONTRACT || null,
  getVoxFeedLite: () => process.env.VOXFEED_LITE || null,
  advertisersAddCreditEnabled: () => {
    const enviromentValue = process.env.ADVERTISERS_ADD_CREDIT;
    if (enviromentValue) {
      return /true/i.test(enviromentValue);
    }
    return true;
  },
  userVerificationEnabled: () => {
    const enviromentValue = process.env.USER_VERIFICATION;
    if (enviromentValue) {
      return /true/i.test(enviromentValue);
    }
    return false;
  },
  getDefaultColor: () => process.env.WHITELABEL_COLOR || '#16D4E6',
  getDefaultGradientColor: () => process.env.WHITELABEL_GRADIENT || '#ace500',
  getDefaultTitle: () => process.env.WHITELABEL_TITLE || 'VoxFeed',
  isProduction: () => process.env.NODE_ENV === 'production',
  isTestEnv: () => process.env.NODE_ENV === 'test',
  getAPIHost: () => process.env.API_HOST,
  getHost: () => window.location.host,
  publicSite: process.env.PUBLIC_SITE_URL,
  dataManagerHost: process.env.DATA_MANAGER_HOST,
  placesApiAuthToken: process.env.PLACES_API_AUTH_TOKEN,

  videoUploadHost: process.env.VIDEO_UPLOAD_HOST,
  amazonS3PlayerHost: process.env.VIDEO_PLAYER_HOST,
  recaptchaSiteKey: process.env.RECAPTCHA_SITE_KEY,
  stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
  typeformInvoicesUrl: process.env.TYPEFORM_INVOICES_URL,
  paypalClient: process.env.PAYPAL_CLIENT_ID,
  paypalEnvironment:
    process.env.NODE_ENV === 'production' ? 'production' : 'sandbox',

  facebookAppId: process.env.FACEBOOK_APP_ID,
  facebookPixelId: process.env.FACEBOOK_PIXEL_ID,
  mixpanelToken: process.env.MIXPANEL_TOKEN,
  googleAnalyticsId: process.env.GOOGLE_ANALYTICS_ID,
  googleAnalyticsGTag: process.env.GOOGLE_GTAG_ID,
  placesToken: process.env.PLACES_TOKEN,
  hotjarToken: { hjid: process.env.HOTJAR_ID, hjsv: process.env.HOTJAR_SV },

  getMaxImageSize: () => MAX_IMAGE_SIZE,
  getMaxVideoSize: () => MAX_VIDEO_SIZE,
  getPlayableVideoType: () => PLAYABLE_VIDEO_TYPE,
  getMinScoreToShowReliability: () => MIN_SCORE_TO_SHOW_RELIABILITY,
  getMinInvestment: () => MIN_INVESTMENT,
  getAssetsCdnUrl: () => ASSETS_CDN_URL,
  getMaxChatAttachments: () => MAX_CHAT_ATTACHMENTS,
  getCheckSwiftUrl: () => CHECK_SWIFT_URL,
  getMaxCampaignGuidelines: () => MAX_CAMPAIGN_GUIDELINES,
  getMaxCampaignGuidelineLength: () => MAX_CAMPAIGN_GUIDELINE_LENGTH,
  getMaxCampaignBriefLength: () => MAX_CAMPAIGN_BRIEF_LENGTH,
  getRecommendedHoursToSendProposals: () => RECOMMENDED_HOURS_TO_SEND_PROPOSALS,
  getMaxCampaignExampleImages: () => MAX_CAMPAIGN_EXAMPLE_IMAGES,
  getMinHoursToSendProposal: () => MIN_HOURS_TO_SEND_PROPOSAL,
  getMaxHoursToSendProposal: () => MAX_HOURS_TO_SEND_PROPOSAL,
  getVoxFeedPaySiteURL: () => VOXFFED_PAY_SITE_URL,
  getMaxPublicationsPerParticipations: () => MAX_PUBLICATIONS_PER_PARTICIPATION,
  getShopifyClientId: () => process.env.SHOPIFY_CLIENT_ID || '',
  getShopifyCallbackPage: () => process.env.SHOPIFY_CALLBACK_PAGE || '',
  getShopifyStoreLink: () => process.env.SHOPIFY_STORE_LINK || '',
  MIN_LENTH_FOR_PHONE_NUMBER,
  MAX_LENGTH_FOR_PHONE_NUMBER,
  DIGIT_REGEX,
  LENGTH_CODE_PHONE_NUMBER
};
