"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const react_router_dom_1 = require("react-router-dom");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const images_1 = require("assets/images");
const app_1 = require("config/app");
const styles = require("./InfluencerLoginUI.less");
const components_1 = require("apps/shared/components");
class InfluencerLoginUI extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { remember: false, showPassword: false, showConfirmPassword: false };
        this.handleSubmit = event => {
            event.preventDefault();
            const { elements } = event.target;
            const { onSubmit, campaignLiteId, campaignLite, isLogin } = this.props;
            const form = {
                email: elements.email.value,
                password: elements.password.value,
                campaignId: campaignLiteId,
                category: campaignLite ? 'LITE' : 'PRO'
            };
            if (!isLogin) {
                form.confirmPassword =
                    elements.password.value === elements.confirmPassword.value;
            }
            onSubmit(form);
        };
        this.handleToggleRemember = () => {
            this.setState({ remember: !this.state.remember });
        };
        this.handleInputChange = event => {
            const { onInputChange } = this.props;
            const { name, value } = event.target;
            onInputChange(name, value);
        };
        this.handleToggleShowPassword = () => {
            const { showPassword } = this.state;
            this.setState({ showPassword: !showPassword });
        };
        this.handleToggleShowConfirmPassword = () => {
            const { showConfirmPassword } = this.state;
            this.setState({ showConfirmPassword: !showConfirmPassword });
        };
    }
    render() {
        const { remember, showPassword, showConfirmPassword } = this.state;
        const { brand, errors, isLogin, campaignLite, onToggleLogin, isCampaignSoldOut } = this.props;
        const hasBrand = !!brand;
        let liteLoginText;
        if (campaignLite) {
            if (!isCampaignSoldOut) {
                liteLoginText = i18n.getHTML('REGISTER_VIA_CAMPAIGN_LINK', {
                    name: brand.name,
                    companyName: app_1.getDefaultTitle()
                });
            }
        }
        else {
            liteLoginText = i18n.getHTML('REGISTER_VIA_CAMPAIGN_LINK', {
                name: brand.name,
                companyName: app_1.getDefaultTitle()
            });
        }
        const cardClass = classnames_1.default(styles.card, 'padding-top-2x padding-horizontal-1x padding-bottom-1x');
        return (React.createElement("div", { className: cardClass },
            hasBrand ? (React.createElement("h1", { className: 'center-xs' }, isLogin
                ? i18n.getHTML('LOGIN_VIA_CAMPAIGN_LINK', { name: brand.name })
                : liteLoginText)) : (React.createElement("img", { src: images_1.logoImgObs, alt: 'logo', className: styles.logo })),
            !isLogin && (React.createElement("p", { className: classnames_1.default(`margin-top-1x ${styles.soldOut}`) }, !campaignLite
                ? i18n.get('REGISTER_DESCRIPTION')
                : i18n.get('REGISTER_DESCRIPTION_LITE'))),
            React.createElement("form", { action: '#', onSubmit: this.handleSubmit },
                React.createElement(components_1.FormLabel, { htmlFor: 'email' }, i18n.get('LOGIN_EMAIL')),
                React.createElement(ui_library_1.InputWrapper, { className: styles.password },
                    React.createElement("i", { className: 'vf-icon icon-mail' }),
                    React.createElement(components_1.DebounceInput, { value: '', type: 'text', name: 'email', className: styles.input, onChange: this.handleInputChange, placeholder: i18n.get('LOGIN_EMAIL_PLACEHOLDER') })),
                React.createElement(components_1.FormLabel, { htmlFor: 'password' }, i18n.get('LOGIN_PASSWORD')),
                React.createElement(ui_library_1.InputWrapper, { className: styles.password },
                    React.createElement("i", { className: 'vf-icon icon-locked' }),
                    React.createElement(components_1.DebounceInput, { value: '', name: 'password', className: styles.input, onChange: this.handleInputChange, type: showPassword ? 'text' : 'password', style: { paddingLeft: '6rem', fontSize: '2rem' }, placeholder: i18n.get('REGISTER_PASSWORD_PLACEHOLDER') }),
                    React.createElement("i", { className: classnames_1.default(styles.showPassword, {
                            'icon-eye-disabled': showPassword,
                            'icon-eye-1': !showPassword
                        }), onClick: this.handleToggleShowPassword })),
                !isLogin && (React.createElement(React.Fragment, null,
                    React.createElement(components_1.FormLabel, { htmlFor: 'confirmPassword' }, i18n.get('REGISTER_CONFIRM_PASSWORD')),
                    React.createElement(ui_library_1.InputWrapper, { className: styles.password },
                        React.createElement("i", { className: 'vf-icon icon-locked' }),
                        React.createElement(components_1.DebounceInput, { value: '', name: 'confirmPassword', className: styles.input, onChange: this.handleInputChange, type: showConfirmPassword ? 'text' : 'password', style: { paddingLeft: '6rem', fontSize: '2rem' }, placeholder: i18n.get('REGISTER_CONFIRM_PASSWORD_PLACEHOLDER') }),
                        React.createElement("i", { className: classnames_1.default(styles.showPassword, {
                                'icon-eye-disabled': showConfirmPassword,
                                'icon-eye-1': !showConfirmPassword
                            }), onClick: this.handleToggleShowConfirmPassword })))),
                isLogin ? (React.createElement(Remember, { remember: remember, handleToggleRemember: this.handleToggleRemember })) : (React.createElement(Terms, null)),
                React.createElement("div", { style: { fontSize: '1.6rem' }, className: 'vf-text-danger center-xs margin-top-1x margin-bottom-2x vf-font-bold' },
                    React.createElement("div", null, errors.email),
                    React.createElement("div", null, errors.password),
                    React.createElement("div", null, errors.confirmPassword)),
                React.createElement(ui_library_1.Button, { size: 'lg', type: 'submit', color: 'primary', className: classnames_1.default('width80 margin-vertical-1x', styles.button) }, isLogin ? i18n.get('LOGIN_LOGIN') : i18n.get('REGISTER_REGISTER'))),
            React.createElement(ui_library_1.Button, { size: 'md', color: 'flat', className: classnames_1.default('width80 padding-top-1x vf-text-primary vf-font-bold', styles.button), onClick: onToggleLogin }, isLogin
                ? i18n.get('LOGIN_DONT_HAVE_ACCOUNT')
                : i18n.get('REGISTER_BACK_TO_LOGIN'))));
    }
}
exports.default = InfluencerLoginUI;
const Terms = () => (React.createElement("div", { className: 'flex flex-start margin-top-1x' },
    React.createElement("div", { className: styles.terms }, i18n.getHTML('REGISTER_ACCEPT_TOS'))));
const Remember = ({ remember, handleToggleRemember }) => (React.createElement("div", { className: 'flex margin-top-1x' },
    React.createElement("input", { type: 'checkbox', checked: remember, className: 'margin-right-Hx', onChange: handleToggleRemember }),
    React.createElement("div", { className: 'vf-clickable', onClick: handleToggleRemember, style: { lineHeight: 'initial' } }, i18n.get('LOGIN_REMEMBER_ME')),
    React.createElement(react_router_dom_1.Link, { to: '/forgot', className: 'vf-text-primary vf-bold', style: { lineHeight: 'initial', marginLeft: 'auto' } }, i18n.get('LOGIN_FORGOT_PASSWORD'))));
