import React, {Component} from 'react';
import * as userActions from 'redux/modules/users';
import {displayAlert} from 'modules/legacy/js/actions/app';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {get, isUndefined} from 'lodash';
import i18n from 'i18n';
import BankAccountFormAsiaUI from '../bank-account-form-asia-UI';
import BankAccountFormLatamUI from '../bank-account-form-latam-UI';
import { getBankVersion, getStatics } from 'config/app';

/** Form for saving and display data bank account (limited to vietnam) */
class BankAccountController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bankAccount: {
        fullName: '',
        gender: 'male',
        date: '',
        phoneNumber: '',
        address: '',
        personalIncomeTaxCode: '',
        personalIdNumber: '',
        personalIdFront: null,
        personalIdBack: null,
        bankName: '', // Bank Name
        branchAccount: '', // Bank account Number
        branchName: '', // Sucursal name
        accountName: '', // Account name
        accountType: 'current'
      },
      updatedFields:{},
      errors: [],
      displayErrors: false,
      justLecture: false,
      loading: false, 
    };
  }

  /**
  * Controlls image upload
  */
  
  onChangeImage(field, value, mode = 'new') {
    const {state} = this;
    const {bankAccount} = state;
    if(mode === 'new'){
      this.setState({...state, bankAccount: {...bankAccount, [field]: value}});
    }
    else if(mode === 'update'){
      const {updatedFields} = this.state;
      this.setState({...state, updatedFields: {...updatedFields,[field]: value}});
    }
    this.deleteError(field);
  }

  /**
  * text fields
  */
  
  onChangeInput(event, mode = 'new', limit = 100) {
    const {state} = this;
    const field = get(event, 'target.name', 'field');
    const value = get(event, 'target.value', 'value');
    
    if (event.target.validity.valid && value.length <= limit) {
      if(mode === 'new'){
        const {bankAccount} = state;
        this.setState({...state, bankAccount: {...bankAccount, [field]: value}});
      } else if (mode === 'update') {
        const {updatedFields} = this.state;
        this.setState({...state, updatedFields: {...updatedFields,[field]: value}}, () => this.cleanUpdate(field));
      }
      this.deleteError(field);
    }
  }

  /**
  * gender change
  */
  onChangeGender = (index, mode = 'new') => {
    const {state} = this;
    
    if(mode === 'new'){
      const {bankAccount} = state;
      this.setState({...state, bankAccount: {...bankAccount, gender: index}});
    }
    else if(mode === 'update'){
      const {updatedFields} = this.state;
      this.setState({...state, updatedFields: {...updatedFields, gender: index}}, () => this.cleanUpdate('gender'));
    }
  }
  /**
  * account type selector
  */
  setAccountype = (event, mode = 'new') => { 
    const { value } = event.target;
    const { bankAccount } = this.state;

    if(mode === 'new'){
      this.setState({bankAccount:{...bankAccount, accountType: value}});
    }
    else if(mode === 'update'){
      const {updatedFields} = this.state;
      this.setState({updatedFields:{...updatedFields, accountType: value}},() => this.cleanUpdate('accountType'));
    }
  }


  /**
   * controlls Next button state
   */
  buttonState = () => {
    const {
      fullName, date, address, personalIncomeTaxCode, personalIdNumber, personalIdFront, personalIdBack, bankName, branchAccount, branchName, accountName, phoneNumber
    } = this.state.bankAccount;
    return fullName || date || address || personalIncomeTaxCode || personalIdNumber || personalIdFront || personalIdBack || bankName || branchAccount || branchName || accountName || phoneNumber;
  }

  getErrorStatus = (varName) => {
    let result = '';
    const errorCollection = this.state.errors;
    const CurrentSelection = errorCollection.filter(element => element.field === varName);

    if (CurrentSelection.length > 0) {
      result = CurrentSelection[0].message;
    }
    return result;
  }

  onChangeDate = (date, mode  = 'new') => {
    const {state} = this;
    if(mode === 'new'){
      const {bankAccount} = this.state;
      this.setState({...state, bankAccount: {...bankAccount, date: date.toISOString()}});
    } else if(mode ==='update') {
      const {updatedFields} = this.state;
      this.setState({...state, updatedFields: {...updatedFields, date: date.toISOString()}}, () => this.cleanUpdate('date'));
    }
  }

  cleanUpdate = (fieldname) => {
    const {updatedFields, bankAccount} = this.state; 
    if(updatedFields[fieldname] === bankAccount[fieldname]){
      delete updatedFields[fieldname];
      this.setState({...this.state, updatedFields});
    }
  }

  validateAndSave = (params) => {
    const uiVersion = getBankVersion(); 
    const inputVariation = getStatics().bankInputVariation;


    if(uiVersion === inputVariation.SimpleAsia){
      this.asiaValidations();
    } else if(uiVersion === inputVariation.SimpleLatam){
      this.latamValidations();
    }

  }

  latamValidations = () => {
    let validFormat = true; 
    const { getValue } = this;
    const requiredText = i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FIELD_REQUIRED');
    const errors = [];

    if (getValue('fullName').length === 0) {
      errors.push({field: 'fullName', text: requiredText});
      validFormat = false;
    }
    if (getValue('bankName').length === 0) {
      errors.push({field: 'bankName', text: requiredText});
      validFormat = false;
    }
    if (getValue('branchAccount').length === 0) {
      errors.push({field: 'branchAccount', text: requiredText});
      validFormat = false;
    }

    this.setState({errors,displayErrors:true});

    if (validFormat) {
      this.saveInfoLatam();
    }

  }

  asiaValidations = () => {
    let validFormat = true;
    const errors = [];

    this.setState({displayErrors: true});
    const { getValue } = this;
    const { date } = this.state.bankAccount;
    const requiredText = i18n.get('INF_SETTINGS_MONEY_DEPOSITS_FIELD_REQUIRED');

    if (getValue('fullName').length === 0) {
      errors.push({field: 'fullName', text: requiredText});
      validFormat = false;
    }
    if (date.length === 0) {
      errors.push({field: 'date', text: requiredText});
      validFormat = false;
    }
    if (getValue('address').length === 0) {
      errors.push({field: 'address', text: requiredText});
      validFormat = false;
    }
    if (getValue('phoneNumber').length === 0) {
      errors.push({field: 'phoneNumber', text: requiredText});
      validFormat = false;
    }
    if (getValue('personalIdNumber').length === 0) {
      errors.push({field: 'personalIdNumber', text: requiredText});
      validFormat = false;
    }
    if (getValue('personalIncomeTaxCode').length === 0) {
      errors.push({field: 'personalIncomeTaxCode', text: requiredText});
      validFormat = false;
    }
    if (getValue('bankName').length === 0) {
      errors.push({field: 'bankName', text: requiredText});
      validFormat = false;
    }
    if (getValue('branchAccount').length === 0) {
      errors.push({field: 'branchAccount', text: requiredText});
      validFormat = false;
    }
    if (getValue('branchName').length === 0) {
      errors.push({field: 'branchName', text: requiredText});
      validFormat = false;
    }
    if (getValue('accountName').length === 0) {
      errors.push({field: 'accountName', text: requiredText});
      validFormat = false;
    }

    this.setState({errors});

    if (validFormat) {
      this.saveInfoAsia();
    }
  }

  componentDidMount() {
    this.getUserInfo();
  }

  componentDidUpdate() {
    this.getUserInfo();
  }

  getUserInfo = (params) => {
    const hasBankAccount = this.props.bankAccount && this.props.bankAccount.bankName;
    const bankAccount = hasBankAccount ? this.props.bankAccount : get(this.props, 'currentUser.bankAccount', null);

    if (!bankAccount) { return; }

    const localBankAccount = this.state.bankAccount;

    const {state} = this;
    if (localBankAccount !== bankAccount) {
      this.setState({...state, bankAccount, justLecture: true});
    }
  }

  getErrors = (fieldName) => {
    const {errors} = this.state;
    let errorString = '';
    const filteredErrors = errors.filter(element => element.field === fieldName);
    if (filteredErrors.length > 0) {
      errorString = filteredErrors[0].text;
    }

    return errorString;
  }

  deleteError = (field) => {
    const {errors} = this.state;
    const filteredErrors = errors.filter(currentError => field !== currentError.field);
    this.setState({errors: filteredErrors});
  }


  getValue = (field) => {
    return isUndefined(this.state.updatedFields[field]) ? this.state.bankAccount[field] : this.state.updatedFields[field];
  }



  saveInfoLatam = () => {
    const {updateUser, currentUser} = this.props;

    const params = {
      id: currentUser.id,
      bankAccount: {
        fullName: this.getValue('fullName'),
        personalIncomeTaxCode: this.getValue('personalIncomeTaxCode'),
        bankName: this.getValue('bankName'),
        branchAccount: this.getValue('branchAccount'),
        accountType: this.getValue('accountType')
      }
    };

    this.setState({loading: true});
    return updateUser(params)
      .then((res) => {
        displayAlert({type: 'success', message: i18n.get('DEFAULT_SUCCESS_TITLE')});
        this.setState({justLecture: true, loading: false, updatedFields:{}});
      })
      .catch((params) => {
        displayAlert({type: 'error', message: i18n.get('DEFAULT_ERROR_TITLE')});
        this.setState({justLecture: true, loading: false});
      });
  }

  saveInfoAsia = () => {
    const {updateUser, currentUser} = this.props;

    const params = {
      id: currentUser.id,
      bankAccount: {
        fullName: this.getValue('fullName'),
        gender: this.getValue('gender'),
        date: this.getValue('date'),
        phoneNumber: this.getValue('phoneNumber'),
        address: this.getValue('address'),
        personalIncomeTaxCode: this.getValue('personalIncomeTaxCode'),
        personalIdNumber: this.getValue('personalIdNumber'),
        bankName: this.getValue('bankName'),
        branchAccount: this.getValue('branchAccount'),
        accountName: this.getValue('accountName'),
        branchName: this.getValue('branchName'),
        personalIdFront: this.getValue('personalIdFront'),
        personalIdBack: this.getValue('personalIdBack')
      }
    };

    this.setState({loading: true});
    return updateUser(params)
      .then((res) => {
        displayAlert({type: 'success', message: i18n.get('DEFAULT_SUCCESS_TITLE')});
        this.setState({justLecture: true, loading: false, updatedFields:{}});
      })
      .catch((params) => {
        displayAlert({type: 'error', message: i18n.get('DEFAULT_ERROR_TITLE')});
        this.setState({justLecture: true, loading: false});
      });
  }

  render() {
    const uiVersion = getBankVersion(); 
    const inputVariation = getStatics().bankInputVariation;

    const {justLecture, loading, displayErrors, updatedFields} = this.state;
    const {isModal, onChangeStep, adminView} = this.props;
    const ExtraClass = get(this.props, 'classname', '');

    let BankUi = BankAccountFormLatamUI; 
    if(uiVersion === inputVariation.SimpleAsia){
      BankUi = BankAccountFormAsiaUI;
    }

    return (
      <BankUi
        extraClass={ExtraClass}
        onChangeInput={this.onChangeInput.bind(this)}
        bankAccount={this.state.bankAccount}
        justLecture={justLecture}
        onChangeGender={this.onChangeGender}
        loading={loading}
        isModal={isModal}
        buttonState={this.buttonState}
        doValidations={this.validateAndSave}
        onChangeDate={this.onChangeDate}
        onChangeImage={this.onChangeImage.bind(this)}
        onChangeStep={onChangeStep}
        adminView={adminView}
        showErrors={displayErrors}
        getErrors={this.getErrors.bind(this)}
        updatedfields = {updatedFields}
        onChangeAccountType = {this.setAccountype}
      />
    );
  }
}
BankAccountController.propTypes = {
  currentUser: PropTypes.object,
  bankAccount: PropTypes.object,
  classname: PropTypes.string,
  footer: PropTypes.bool,
  isModal: PropTypes.bool
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateUser: userActions.updateCurrentUser
}, dispatch);

export default connect(null, mapDispatchToProps)(BankAccountController);
