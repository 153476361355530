"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const types_1 = require("modules/shared/types");
const components_1 = require("apps/shared/components");
const BudgetCapacityCard_1 = require("../BudgetCapacityCard/BudgetCapacityCard");
class PublicationTypeItem extends React.Component {
    constructor() {
        super(...arguments);
        this.handleClick = () => {
            const { onClick, participationSetting } = this.props;
            onClick(participationSetting.type);
        };
    }
    render() {
        const { participationSetting, socialAccountType, canSendRequest, currency } = this.props;
        const isPost = participationSetting.type === types_1.PublicationType.POST;
        return (React.createElement("span", { "data-uitest": isPost ? 'post-publication-card' : 'story-publication-card' },
            React.createElement(BudgetCapacityCard_1.default, { budgetCapacity: participationSetting.budgetCapacity, canSendRequest: canSendRequest, style: { padding: '2rem' }, onClick: this.handleClick },
                React.createElement("div", { className: 'flex vf-bold' },
                    React.createElement(components_1.SocialBadge, { size: 13, socialNetwork: socialAccountType, className: 'social-badge margin-right-Hx', publicationType: participationSetting.type }),
                    React.createElement("span", { style: { fontSize: '2rem' } }, isPost
                        ? i18n.get('ADV_CAMPAIGNS_WIZARD_INSTAGRAM_POSTS')
                        : i18n.get('ADV_CAMPAIGNS_WIZARD_INSTAGRAM_STORIES')),
                    React.createElement("p", { style: { marginLeft: 'auto' } }, i18n.getMoney({
                        amount: participationSetting.recommendedBid,
                        currency
                    }))),
                React.createElement("p", { className: 'margin-top-Hx vf-text-gray' }, isPost
                    ? i18n.get('INF_VOXEAR_PUBLIC_CAMPAIGN_POST_DESCRIPTION')
                    : i18n.get('INF_VOXEAR_PUBLIC_CAMPAIGN_STORY_DESCRIPTION')))));
    }
}
exports.default = PublicationTypeItem;
