import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import Reflux from 'reflux';
import _ from 'lodash';

import i18n from 'i18n';
import API from '../lib/api';

import CitySelect from 'apps/shared/controllers/CitySelect/CitySelect';
import CountrySelect from 'apps/shared/controllers/CountrySelect/CountrySelect';
import deepLinkState from '../mixins/deepLinkState';
import Mixins from '../lib/mixins';

const Actions = {
  dataStore: require('../actions/dataStore'),
  app: require('../actions/app'),
  auth: require('../actions/auth')
};

const MIN_LENGTH_FOR_PHONE_NUMBER_WITH_PREFIX = 11;
const MAX_LENGTH_FOR_PHONE_NUMBER_WITH_PREFIX = 13;

const SettingsAccountContent = createClass({
  mixins: [
    deepLinkState,
    Mixins.linkMixin,

    Reflux.listenTo(
      Actions.auth.submitUserPhoneSuccess,
      'onSubmitPhoneSuccess'
    ),
    Reflux.listenTo(Actions.auth.submitUserPhoneError, 'onSubmitPhoneError'),
    Reflux.listenTo(
      Actions.auth.submitVerificationCodeSuccess,
      'onSubmitCodeSuccess'
    ),
    Reflux.listenTo(
      Actions.auth.submitVerificationCodeError,
      'onSubmitCodeError'
    )
  ],

  propTypes: {
    user: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    cellPhoneVerificationComponent: PropTypes.object,
    onCountryChange: PropTypes.func,
    onPlaceChange: PropTypes.func,
    onPhoneChange: PropTypes.func,
    requiresCellphoneVerification: PropTypes.bool
  },

  componentDidMount() {
    this.getInitialPlace();
  },

  getInitialPlace() {
    const { city } = this.state.userData;
    if (!city) return this.findUserLocationByIP();

    API.getPlace({ placeId: city }, this.onLocationInitialLoad);
  },

  getDefaultProps() {
    return {
      onCountryChange: () => {},
      onPhoneChange: () => {},
      onPlaceChange: () => {}
    };
  },

  findUserLocationByIP() {
    API.getPlacesMe({}, this.onLocationInitialLoad);
  },

  onLocationInitialLoad(error, response) {
    const { data: place } = response;
    if (error || response.code || !place || !place.country) return false;

    API.getPlace({ placeId: place.country.id });
    Actions.dataStore.update(response);

    const placeInfo = this.getPlaceInfo(place);
    const { country } = place;
    const { phoneCode, id: countryId } = country;
    const { userData, formData } = this.state;
    const { prefix, number, phone } = this.buildPhoneInfo(
      phoneCode,
      formData.phone.number
    );

    const newUserData = {
      phone,
      country: countryId,
      city: placeInfo.value,
      timezone: placeInfo.timezone,
      region: placeInfo.regionId
    };

    const newFormData = {
      country: country.name,
      city: placeInfo.label,
      phone: { prefix, number }
    };

    this.setState({
      userData: { ...userData, ...newUserData },
      formData: { ...formData, ...newFormData }
    });
  },

  getInitialState() {
    const user = _.clone(this.props.user);

    return {
      userData: {
        id: user._id,
        country: user.country,
        city: user.city,
        region: user.region,
        timezone: user.timezone,
        phone: user.phone
      },
      formData: {
        country: '',
        region: '',
        city: '',
        phone: this.parsePhone(user.phone),
        code: ''
      },
      showErrors: false,
      phoneDisplay: 'phone'
    };
  },

  parsePhone(phone) {
    const response = { prefix: '', number: '' };

    if (phone) {
      const components = phone.split('-');
      response.prefix = components[0] || '';
      response.number = components[1] || '';
    }

    return response;
  },

  buildPhoneInfo(prefix, previousPhone) {
    const number = (previousPhone.match(/\d+/g) || []).join('');
    const phone = number ? `${prefix}-${number}` : null;

    return { prefix, number, phone };
  },

  isUserDataValid(userData) {
    return (
      this.isCountryValid(userData) &&
      this.isCityValid(userData) &&
      this.isPhoneValid(userData)
    );
  },

  isCountryValid(userData) {
    return !!userData.country;
  },

  isCityValid(userData) {
    return !!userData.city;
  },

  isPhoneValid(userData) {
    const { cellPhoneVerificationComponent } = this.props;
    const { phone } = userData;

    if (!cellPhoneVerificationComponent) return true;
    if (!phone) return true;
    if (this.currentUserIsAdmin()) return true;

    return this.isPhoneLengthValid(phone);
  },

  currentUserIsAdmin() {
    const currentAccountRole = _.get(window, 'account.role');
    return currentAccountRole === 'admin';
  },

  isPhoneLengthValid(number = '') {
    return (
      !_.isNil(number) &&
      number.length >= MIN_LENGTH_FOR_PHONE_NUMBER_WITH_PREFIX &&
      number.length <= MAX_LENGTH_FOR_PHONE_NUMBER_WITH_PREFIX
    );
  },

  isPhoneVerified() {
    const { user } = this.props;
    const { userData } = this.state;
    const { verifiedPhones = [] } = user;

    return _.includes(verifiedPhones, userData.phone);
  },

  onCountryChange({ id: value, label, phoneCode }) {
    const {
      onCountryChange,
      onPlaceChange,
      onPhoneChange,
      cellPhoneVerificationComponent
    } = this.props;
    const { userData, formData } = this.state;
    let newUserData = {};
    const isCountryChanged = userData.country !== value;

    const { prefix, number, phone } = this.buildPhoneInfo(
      phoneCode,
      formData.phone.number
    );

    if (isCountryChanged) {
      newUserData = {
        city: null,
        region: null,
        timezone: null,
        country: value,
        phone: cellPhoneVerificationComponent ? phone : null
      };
    }

    const newFormData = {
      phone: { prefix, number }
    };

    this.setState(
      {
        userData: Object.assign({}, userData, newUserData),
        formData: Object.assign({}, formData, newFormData)
      },
      () => {
        onCountryChange({ value, label });
        onPhoneChange({ prefix, number, phone });
        isCountryChanged && onPlaceChange(null);
      }
    );
  },

  onCityChange({ id: value, label }) {
    const { onPlaceChange } = this.props;
    const newUserData = { city: value };

    this.setState(
      {
        userData: { ...this.state.userData, ...newUserData }
      },
      () => {
        onPlaceChange({ value, label });
        this.saveData();
      }
    );
  },

  onPlaceChange(placeId, selectedOptions) {
    const { onPlaceChange } = this.props;
    const { userData, formData } = this.state;
    const [placeSelected] = selectedOptions;
    const newUserData = {
      city: placeId,
      region: _.get(placeSelected, 'regionId', null),
      timezone: _.get(placeSelected, 'timezone', null)
    };
    const newFormData = {
      city: _.get(placeSelected, 'label', null)
    };

    this.setState(
      {
        userData: Object.assign({}, userData, newUserData),
        formData: Object.assign({}, formData, newFormData)
      },
      () => {
        onPlaceChange(placeId);
      }
    );
  },

  getPlaceInfo(place) {
    const { timezone, id: placeId, name: cityLabel } = place;
    let regionLabel = '';
    let regionId = '';

    if (place.admin1) {
      regionLabel = place.admin1.name;
      regionId = place.admin1.id;
    }

    const placeLabel = regionLabel
      ? [cityLabel, regionLabel].join(', ')
      : cityLabel;
    return {
      timezone,
      regionId,
      value: placeId,
      city: cityLabel,
      region: regionLabel,
      label: placeLabel,
      population: parseInt(place.population, 10)
    };
  },

  onChangeNumber(newNumber) {
    const { onPhoneChange } = this.props;
    const { userData, formData } = this.state;
    const { prefix, number, phone } = this.buildPhoneInfo(
      formData.phone.prefix,
      newNumber
    );

    const newUserData = { phone };
    const newFormData = { phone: { prefix, number } };

    this.setState(
      {
        userData: Object.assign({}, userData, newUserData),
        formData: Object.assign({}, formData, newFormData)
      },
      () => {
        onPhoneChange({ prefix, number, phone });
      }
    );
  },

  onChangeVerificationCode(code) {
    const { formData } = this.state;

    this.setState({
      formData: Object.assign({}, formData, { code })
    });
  },

  submitPhone() {
    const { user } = this.props;
    const { userData } = this.state;

    if (!this.isPhoneLengthValid(userData.phone))
      return this.setState({ showErrors: true });

    Actions.auth.submitUserPhone(user._id, userData.phone);
  },

  onSubmitPhoneSuccess() {
    this.passiveStoreData();
    this.setState({ phoneDisplay: 'code' });

    Actions.app.displayAlert({
      type: 'success',
      title: i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_SUBMIT_SUCCESS_ALERT_TITLE'),
      message: i18n.get(
        'ADV_SETTINGS_ACCOUNT_PHONE_SUBMIT_SUCCESS_ALERT_MESSAGE'
      )
    });
  },

  onSubmitPhoneError() {
    Actions.app.displayAlert({
      type: 'error',
      title: i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_SUBMIT_ERROR_ALERT_TITLE'),
      message: i18n.get('ADV_SETTINGS_ACCOUNT_PHONE_SUBMIT_ERROR_ALERT_MESSAGE')
    });
  },

  submitCode() {
    const { user } = this.props;
    const { formData } = this.state;

    Actions.auth.submitVerificationCode(user._id, formData.code);
  },

  onSubmitCodeSuccess() {
    this.passiveStoreData();
    this.setState({ phoneDisplay: 'phone' });

    Actions.app.displayAlert({
      type: 'success',
      title: i18n.get(
        'ADV_SETTINGS_ACCOUNT_PHONE_SUBMIT_CODE_SUCCESS_ALERT_TITLE'
      ),
      message: i18n.get(
        'ADV_SETTINGS_ACCOUNT_PHONE_SUBMIT_CODE_SUCCESS_ALERT_MESSAGE'
      )
    });
  },

  omitFilterOptions(options = []) {
    return options;
  },

  onSubmitCodeError() {
    Actions.app.displayAlert({
      type: 'error',
      title: i18n.get(
        'ADV_SETTINGS_ACCOUNT_PHONE_SUBMIT_CODE_ERROR_ALERT_TITLE'
      ),
      message: i18n.get(
        'ADV_SETTINGS_ACCOUNT_PHONE_SUBMIT_CODE_ERROR_ALERT_MESSAGE'
      )
    });
  },

  changeToPhoneEditionMode() {
    this.setState({ phoneDisplay: 'phone' });
  },

  passiveStoreData() {
    const { userData } = this.state;
    const storeData = _.pick(userData, [
      'id',
      'country',
      'region',
      'city',
      'timezone',
      'phone'
    ]);

    if (this.isCountryValid(userData)) storeData.country = userData.country;
    if (this.isCityValid(userData)) storeData.city = userData.city;
    if (this.isPhoneValid(userData)) storeData.phone = userData.phone;
    if (Object.keys(storeData).length === 0) return false;

    API.auto('updateUser', storeData);
  },

  saveData(callback) {
    const { onSave } = this.props;
    const { userData } = this.state;
    const { phone } = userData;
    const storeData = _.pick(userData, [
      'id',
      'country',
      'city',
      'timezone',
      'region'
    ]);
    storeData.phone =
      phone && phone.length >= MIN_LENGTH_FOR_PHONE_NUMBER_WITH_PREFIX
        ? phone
        : null;

    if (!this.isUserDataValid(userData))
      return this.setState({ showErrors: true });

    API.updateUser(storeData, err => {
      if (err) return console.error(err);
      if (onSave) onSave();
      if (callback) callback();

      Actions.app.displayAlert({
        type: 'success',
        message: i18n.get('ADV_SETTINGS_ACCOUNT_SAVE_SUCCESS_ALERT_MESSAGE')
      });
    });
  },

  render() {
    const { showErrors, userData, formData, phoneDisplay } = this.state;

    const countryHasError = showErrors && !this.isCountryValid(userData);
    const cityHasError = showErrors && !this.isCityValid(userData);
    const phoneHasError = showErrors && !this.isPhoneValid(userData);

    const CellPhoneVerification = this.props.cellPhoneVerificationComponent;

    return (
      <div className='vf-row'>
        <div className='col-xs-12'>
          <div className='vf-simple-form-label'>
            {i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_COUNTRY_LABEL')}
          </div>
          <CountrySelect
            hasError={countryHasError}
            onChange={this.onCountryChange}
            selectedId={userData.country || ''}
          />

          <div className='vf-simple-form-label'>
            {i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_CITY_LABEL')}
          </div>
          <CitySelect
            hasError={cityHasError}
            disabled={!userData.country}
            onChange={this.onCityChange}
            cityId={userData.city || ''}
            countryId={userData.country}
          />
          {cityHasError && (
            <div className='vf-text-danger margin-top-1x'>
              {i18n.get('ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_ERROR_CITY')}
            </div>
          )}

          {CellPhoneVerification && (
            <div className='padding-top-2x'>
              <div className='vf-simple-form-label'>
                {i18n.get(
                  'ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_PHONE_LABEL'
                )}
              </div>
              <CellPhoneVerification
                number={formData.phone.number}
                prefix={formData.phone.prefix}
                code={formData.code}
                onChangeNumber={this.onChangeNumber}
                onChangeCode={this.onChangeVerificationCode}
                onSubmitPhone={this.submitPhone}
                onSubmitCode={this.submitCode}
                onEditNumber={this.changeToPhoneEditionMode}
                display={phoneDisplay}
                isVerified={this.isPhoneVerified()}
                disabled={!userData.country}
                error={phoneHasError}
              />
              {phoneHasError && (
                <div className='vf-text-danger margin-top-1x'>
                  {i18n.get(
                    'ADV_SETTINGS_ACCOUNT_LOCATION_AND_PHONE_ERROR_PHONE'
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
});

export default SettingsAccountContent;
