import Reflux from 'reflux';
import _ from 'lodash';

const Stores = {
  dataStore: require('../stores/dataStore'),
  configStore: require('../stores/configStore')
};

const Actions = {
  dataStore: require('../actions/dataStore'),
  app: require('../actions/app')
};

module.exports = Reflux.createStore({
  init() {
    this.listenTo(Stores.configStore, this._onConfigStoreUpdate);
    this.listenTo(Stores.dataStore, this._onUpdate);
    this.listenTo(Actions.app.updateUserInfo, this._onUpdate);
    this.listenTo(Actions.dataStore.destroy, this._onDestroy);
  },

  _data: {},
  _accountId: null,

  getAll() {
    return this._data;
  },

  _onConfigStoreUpdate(configStore) {
    if (this._accountId !== configStore.ActiveAccountId) {
      const dataStore = Stores.dataStore.getAll();
      this._accountId = configStore.ActiveAccountId;

      this._onUpdate(dataStore);
    }
  },

  _onUpdate(dataStore = Stores.dataStore.getAll()) {
    const configStore = Stores.configStore.getAll();
    this._data = _.get(dataStore, ['Account', configStore.ActiveAccountId], {});
    this.trigger(this._data);
    window.account = this._data;
  },

  _onDestroy() {
    this._data = {};
    this.trigger(this._data);
  }
});
