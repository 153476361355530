"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavTab = void 0;
const React = require("react");
const styles = require("./Tab.less");
const classnames_1 = require("classnames");
const react_router_dom_1 = require("react-router-dom");
class Tab extends React.PureComponent {
    render() {
        const _a = this.props, { children, className, isActive } = _a, others = __rest(_a, ["children", "className", "isActive"]);
        const tabClass = classnames_1.default(className, styles.tab, {
            [styles.active]: isActive
        });
        return (React.createElement("div", Object.assign({ className: tabClass }, others), children));
    }
}
exports.default = Tab;
exports.NavTab = ({ to, exact, children, className }) => (React.createElement(react_router_dom_1.NavLink, { to: to, exact: exact, className: classnames_1.default(styles.tab, className), activeClassName: styles.active }, children));
