"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const lodash_1 = require("lodash");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const selectors_1 = require("modules/shared/selectors");
const types_1 = require("modules/shared/types");
const LayoutUI_1 = require("./LayoutUI/LayoutUI");
const appActions = require("redux/modules/app");
const campActions = require("redux/modules/campaigns");
const getActiveSN_1 = require("utils/getActiveSN");
const VERSIONCAMPAING = 'VersionCampaing';
class Layout extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { isValid: true, invalidAccountModal: false };
        this.fetchData = (props = this.props) => {
            const { getCampaignByToken, match } = props;
            getCampaignByToken(match.params).then(this.onSuccess).catch(this.onFailed);
        };
        this.getBrand = () => {
            const { campaign } = this.props;
            return {
                id: '',
                _id: '',
                name: campaign.brandName,
                image: campaign.brandImage
            };
        };
        this.onSuccess = data => {
            const { campaign } = this.props;
            if (data.id) {
                const res = {
                    modelName: data.modelName,
                    campaignVersion: data.data.campaignVersion,
                    socialNetworks: getActiveSN_1.getActiveSNCampaign(campaign)
                };
                window.localStorage.setItem(VERSIONCAMPAING, JSON.stringify(res));
            }
        };
        this.onFailed = () => {
            this.setState({ isValid: false });
        };
        this.handleSetCampaignToken = (props = this.props) => {
            const { setCampaignToken, match, currentUser, hideLoading } = props;
            const shopify = Boolean(qs.parse(location.search).shopify);
            const params = {
                shopify,
                token: match.params.token,
                influencerId: currentUser.account._id
            };
            if (!shopify) {
                delete params.shopify;
            }
            if (currentUser.role !== 'influencer')
                return;
            setCampaignToken(params)
                .then(this.onSetSuccess)
                .catch(this.onSetFailed)
                .finally(hideLoading);
        };
        this.onSetSuccess = () => {
            const { history, campaign, location } = this.props;
            const { partnerId } = qs.parse(location.search);
            const params = `?${qs.stringify({ partnerId })}`;
            history.push(`/public-campaigns/${campaign._id}${params}`);
        };
        this.onSetFailed = () => {
            const { history, showAlert, setTokenRequest } = this.props;
            history.push('/available-campaigns');
            const res = setTokenRequest.response;
            showAlert({ type: 'error', message: res.message, time: 10000 });
        };
        this.onInvalidAccount = () => {
            const { invalidAccountModal } = this.state;
            this.setState({ invalidAccountModal: !invalidAccountModal });
        };
    }
    UNSAFE_componentWillMount() {
        const { currentUser } = this.props;
        this.fetchData();
        if (!lodash_1.isEmpty(currentUser))
            this.handleSetCampaignToken();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const nextUser = nextProps.currentUser;
        const cUser = this.props.currentUser;
        if (cUser._id !== nextUser._id)
            this.handleSetCampaignToken(nextProps);
    }
    render() {
        const { isValid, invalidAccountModal } = this.state;
        const { campaign, location, currentUser } = this.props;
        const campaignLite = campaign.campaignVersion === types_1.ECategoryType.LITE;
        const campaignLiteId = campaign._id;
        const { partnerId } = qs.parse(location.search);
        return (React.createElement(LayoutUI_1.default, { isValid: isValid, campaign: campaign, partnerId: partnerId, brand: this.getBrand(), currentUser: currentUser, campaignLite: campaignLite, campaignLiteId: campaignLiteId, onInvalidAccount: this.onInvalidAccount, invalidAccountModal: invalidAccountModal }));
    }
}
Layout.defaultProps = { campaign: {} };
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    getCampaignByToken: campActions.getCampaignByToken,
    setCampaignToken: campActions.setCampaignToken,
    hideLoading: appActions.hideLoading,
    showLoading: appActions.showLoading,
    showAlert: appActions.showAlert
}, dispatch);
const mapStateToProps = state => ({
    campaign: selectors_1.getFirstCampaign(state),
    currentUser: selectors_1.getCurrentUser(state),
    session: lodash_1.get(state, 'shared.session'),
    setTokenRequest: selectors_1.getRequest(state, 'setCampaignToken')
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Layout);
