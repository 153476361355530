"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const Popover = require("react-popover");
const images_1 = require("assets/images");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const styles = require("./CampaignHeader.less");
const components_2 = require("modules/shared/components");
class CampaignHeader extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { isPopoverOpen: false };
        this.handleTogglePopover = () => {
            this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
        };
    }
    render() {
        const { campaign, children, onBack, campaignLite } = this.props;
        const wrapperClass = classnames_1.default(styles.wrapper);
        return (React.createElement("div", { className: wrapperClass },
            React.createElement(components_1.Img, { sizes: '100vw', className: 'image-cover image-fit-parent', src: campaign.coverImage || images_1.defaultCampaignCoverImg }),
            React.createElement("div", { className: styles.overlay }),
            !onBack && (React.createElement("img", { className: styles.logo, src: images_1.logoImg, alt: 'voxfeed-logo' })),
            React.createElement("div", { className: styles.info },
                React.createElement("div", { className: 'flex' },
                    onBack && (React.createElement("i", { onClick: onBack, style: { fontSize: '3rem' }, className: 'icon icon-arrow-left vf-clickable back margin-top-Hx' })),
                    React.createElement(components_1.Img, { width: 64, height: 64, src: campaign.brandImage || images_1.defaultAvatarImg, className: classnames_1.default(styles.avatar, 'image-cover') })),
                React.createElement("div", { className: 'ellipsis' },
                    React.createElement("h1", { className: 'ellipsis', title: campaign.name }, campaign.name),
                    React.createElement("p", null, campaign.brandName)),
                React.createElement(Popover, { preferPlace: 'below', body: React.createElement("div", { className: 'padding-Hx' }, i18n.get('INF_VOXEAR_CAMPAIGN_RAITING_POPOVER')) }, !campaignLite && (React.createElement("div", { onMouseEnter: this.handleTogglePopover, onMouseLeave: this.handleTogglePopover, className: classnames_1.default(styles.stars, 'start-xs') },
                    React.createElement(components_2.RateSelector, { readOnly: true, transparent: true, style: { width: '16rem' }, rate: campaign.averageRate })))),
                React.createElement("div", { style: { gridArea: 'child' } }, children))));
    }
}
CampaignHeader.defaultProps = { campaign: {} };
exports.default = CampaignHeader;
