"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./Spinner.less");
const Spinner = ({ style = {}, className = '' }) => (React.createElement("div", { className: classnames_1.default(className, styles['sk-circle']), style: style },
    React.createElement("div", { className: `${styles['sk-circle1']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle2']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle3']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle4']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle5']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle6']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle7']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle8']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle9']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle10']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle11']} ${styles['sk-child']}` }),
    React.createElement("div", { className: `${styles['sk-circle12']} ${styles['sk-child']}` })));
exports.default = Spinner;
