import React from 'react';
import Reflux from 'reflux';
import get from 'lodash/get';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createClass from 'create-react-class';
import { compose, bindActionCreators } from 'redux';

import i18n from 'i18n';
import RoleForm from './containers';
import RoleSteps from './components';
import recaptcha from 'apps/shared/hocs/recaptcha';
import { languageChange } from 'redux/modules/app';
import rolesHeroSections from './rolesHeroSections';
import AppActions from 'modules/legacy/js/actions/app';
import AuthActions from 'modules/legacy/js/actions/auth';
import { HeroSections } from 'modules/shared/components';
import { registrationRequest } from 'redux/modules/shared';
import PartnerFormRoute from 'apps/advertisers/PartnerForm';
import { UserRole, ECategoryType } from 'modules/shared/types';
import { PartnerMessageDialog } from 'apps/influencers/shared/controllers';

const INVITATION_LINK = 'invitationLink';

const Registration = createClass({
  mixins: [
    Reflux.listenTo(AuthActions.registerError, 'onRegisterError'),
    Reflux.listenTo(AppActions.reCAPTCHALoaded, 'updateReCaptchaWidget')
  ],

  UNSAFE_componentWillMount() {
    const query = qs.parse(this.props.location.search);
    const { role, brand } = query;

    this.setState({ role });

    if (brand) {
      this.setState({ role: 'advertiser', brand });
    }
  },

  UNSAFE_componentWillReceiveProps({ session, user }) {
    if (session.error) return this.onRegisterError(session.error);

    if (user) {
      return AuthActions.registerSuccess({
        user,
        token: session.token,
        account: user.account
      });
    }

    return null;
  },

  onRegisterError(error) {
    this.showError(error);
    this.props.updateReCaptchaWidget();
  },

  onSendRegistrationData(form) {
    const { role, brand } = this.state;
    const { currentLanguage: locale } = this.props;

    const { email, password } = form;
    const params = { email, password, role, locale };

    if (role === 'advertiser') {
      const { name, lastName } = form;
      Object.assign(params, { name, lastName });

      if (brand) {
        window.localStorage.setItem(INVITATION_LINK, 'true');
        Object.assign(params, { category: ECategoryType.LITE });
      }
    }

    return this.props.registrationRequest(params);
  },

  showError({ message }) {
    AppActions.displayAlert({ type: 'error', message });
  },

  render() {
    const { role } = this.state;
    const { partnerSettings, location } = this.props;

    const query = qs.parse(location.search);
    const partnerId = get(query, 'partnerId');

    const { title = 'REGISTER_HERO_TITLE', description = '' } =
      rolesHeroSections[role] || {};

    if (partnerSettings) return <PartnerFormRoute />;

    return (
      <HeroSections
        buttonPadding
        linkTo='/login'
        title={i18n.getHTML(title)}
        buttonText={i18n.get('REGISTER_LOG_IN')}
        bottom={role && <RoleSteps role={role} />}
        description={description && i18n.get(description)}
        body={
          role === UserRole.ADVERTISER && <RoleSteps role={role} vertical />
        }
      >
        <RoleForm
          role={role}
          query={query}
          onSubmit={this.onSendRegistrationData}
          onChangeRole={r => this.setState({ role: r })}
        />

        {partnerId && role === UserRole.INFLUENCER && (
          <PartnerMessageDialog partnerId={partnerId} />
        )}
      </HeroSections>
    );
  }
});

function mapStateToProps({ app, shared }) {
  const { session, user } = shared;
  const { currentLanguage, partnerSettings } = app;
  return { session, user, currentLanguage, partnerSettings };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ registrationRequest, languageChange }, dispatch);
}

Registration.propTypes = {
  user: PropTypes.object,
  session: PropTypes.object,
  location: PropTypes.object,
  partnerSettings: PropTypes.object,
  registrationRequest: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

Registration.defaultProps = {
  user: {},
  session: {},
  location: {},
  partnerSettings: null
};

export default compose(
  recaptcha,
  connect(mapStateToProps, mapDispatchToProps)
)(Registration);
