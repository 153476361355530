import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Pulse.less';

class Pulse extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    onPulseFinished: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    show: false,
    className: ''
  };

  timer = null;

  componentDidMount() {
    const { show, onPulseFinished } = this.props;
    if (show) {
      this.timer = setTimeout(onPulseFinished, 4000);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show, onPulseFinished } = this.props;
    if (!show && nextProps.show) {
      clearTimeout(this.timer);
      this.timer = setTimeout(onPulseFinished, 4000);
    }
  }

  render() {
    const { children, show, className } = this.props;

    return (
      <div className={classNames(className, { [styles.pulse]: show })}>
        {children}
      </div>
    );
  }
}

export default Pulse;
