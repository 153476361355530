import React from 'react';
import Reflux from 'reflux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import createClass from 'create-react-class';
import { withRouter } from 'react-router-dom';

import { dataManagerHost } from 'config/app';
import getDataManagerAdapter from 'config/get-data-manager-adapter';

import { homeCoverImg } from 'assets/images';
import { addAlert } from 'redux/modules/alerts';
import { updateData, removeData } from 'redux/modules/entities';
import { updateCurrentUserInfo, logout } from 'redux/modules/shared';
import { fetchAvailableLanguages, changeLanguage } from 'redux/modules/app';

import tracking from 'modules/legacy/js/lib/tracking/user';
import Loading from 'apps/shared/components/Loading/Loading';
import { getInstance as buildDataManager } from 'modules/legacy/js/lib/datamanager';

import { initCrispConfig } from 'utils/crispActions';
import AppActions from 'modules/legacy/js/actions/app';
import PanelActions from 'modules/legacy/js/actions/panel';
import configStore from 'modules/legacy/js/actions/configStore';
import redirectionStore from 'modules/legacy/js/stores/redirectionStore';

const Actions = { PanelActions, configStore };
const dataManager = buildDataManager();

function mapStateToProps({ app, shared }) {
  const { session, user } = shared;
  const { modal, availableLanguages, currentLanguage } = app;
  return { modal, session, user, availableLanguages, currentLanguage };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateData,
      removeData,
      addAlert,
      logout,
      updateCurrentUserInfo,
      fetchAvailableLanguages,
      changeLanguage
    },
    dispatch
  );
}

const App = createClass({
  propTypes: {
    children: PropTypes.node,
    user: PropTypes.object,
    updateData: PropTypes.func.isRequired,
    removeData: PropTypes.func.isRequired,
    updateCurrentUserInfo: PropTypes.func,
    modal: PropTypes.object,
    availableLanguages: PropTypes.array,
    session: PropTypes.object,
    logout: PropTypes.func,
    fetchAvailableLanguages: PropTypes.func,
    changeLanguage: PropTypes.func,
    currentLanguage: PropTypes.string
  },

  mixins: [
    Reflux.listenTo(AppActions.hideLoader, 'onActionHideLoader'),
    Reflux.listenTo(AppActions.showLoader, 'onActionShowLoader'),
    Reflux.listenTo(AppActions.displayAlert, 'onActionDisplayAlert'),
    Reflux.listenTo(AppActions.manageAccount, 'onManageAccount')
  ],

  getInitialState() {
    return { loading: true };
  },

  componentDidMount() {
    this.setupApp();
    initCrispConfig();
  },

  UNSAFE_componentWillReceiveProps(props) {
    const previousUserId = get(this.props, 'user._id');
    const newUserId = get(props, 'user._id');

    if (previousUserId !== newUserId) this.updateDataManagerAdapter(props);
  },

  setupApp() {
    const { availableLanguages, fetchAvailableLanguages } = this.props;

    this.updateDataManagerAdapter();
    redirectionStore.setHistory(this.props.history);
    tracking.init();

    if (isEmpty(availableLanguages)) fetchAvailableLanguages();
  },

  updateDataManagerAdapter(props = this.props) {
    const {
      changeLanguage,
      logout,
      user: currentUser,
      updateData: updateReduxData,
      removeData: removeReduxData,
      updateCurrentUserInfo: updateReduxCurrentUserData
    } = props;

    dataManager.setConfiguration({
      host: dataManagerHost,
      streamPath: '/private',
      adapter: getDataManagerAdapter({
        currentUser,
        updateReduxData,
        removeReduxData,
        updateReduxCurrentUserData,
        changeLanguage,
        logout
      })
    });
  },

  onActionShowLoader() {
    this.setState({ loading: true });
  },

  onActionHideLoader() {
    this.setState({ loading: false });
  },

  onManageAccount(
    { token, userId: ActiveUserId, accountId: ActiveAccountId },
    callback
  ) {
    Actions.configStore.fastUpdate({ ActiveAccountId, ActiveUserId }, () => {
      dataManager.setConfiguration({ headers: { auth: token } });
      AppActions.updateUserInfo();

      if (callback) callback();
    });
  },

  onActionDisplayAlert(args) {
    const type = args.type || 'error';
    this.props.addAlert({ ...args, type });
  },

  render() {
    const { loading } = this.state;
    const { children, modal, user } = this.props;

    const loadingStyle = {
      backgroundImage: `linear-gradient(45deg, rgba(0,0,0,0.1), rgba(0,0,0,0.1)),linear-gradient(45deg, rgba(0,0,0,0.75) 0, rgba(0,0,0,0.8) 100%),url('${homeCoverImg}')`
    };

    const loadingClassName = classNames('app-loader vf-font-thin', {
      active: loading
    });

    const containerStyle = !user ? { backgroundColor: '#F2F2F2' } : {};

    return (
      <div className='height100' style={containerStyle}>
        {!loading && children}

        {modal}

        <div className={loadingClassName} style={loadingStyle}>
          <Loading active />
        </div>
      </div>
    );
  }
});

/**
 * Google's reCaptcha onLoad callback.
 * This function should be found on the global :scope, so reCaptcha can run in.
 * The action this function invokes after we get the callback is to let any
 * component know the `grecaptcha` global variable is available and that can be
 * used. Some components needs to kwon this because we are explicitly rendering
 * the widget. (https://developers.google.com/recaptcha/docs/display)
 */
window.reCaptchaOnloadCallback = function recaptcha() {
  AppActions.reCAPTCHALoaded();
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
