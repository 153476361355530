import { buildAPIRequest } from '../helpers';
import { activePostLite } from '../../../services/voxfeed';

// eslint-disable-next-line import/prefer-default-export
export const getActiveCampaignLite = params =>
  buildAPIRequest({
    params,
    id: 'participations',
    method: activePostLite.getActiveCampaignLite
  });
