"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const withProductsTotalizer_1 = require("apps/shared/hocs/withProductsTotalizer");
const getActiveSN_1 = require("utils/getActiveSN");
const PublicCampaignHeaderUI_1 = require("./PublicCampaignHeaderUI/PublicCampaignHeaderUI");
class PublicCampaignHeader extends React.PureComponent {
    render() {
        const { campaign, productsTotalPrice, totalProducts } = this.props;
        const { currency } = campaign;
        return (React.createElement(PublicCampaignHeaderUI_1.default, { currency: currency, totalProducts: totalProducts, productsTotalPrice: productsTotalPrice, socialNetworks: getActiveSN_1.getActiveSNCampaign(campaign) }));
    }
}
PublicCampaignHeader.defaultProps = {
    campaign: {}
};
exports.default = withProductsTotalizer_1.default(PublicCampaignHeader);
