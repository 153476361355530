"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.googleDetailAPI = exports.googleAutocompleteAPI = void 0;
const google_1 = require("config/google");
function googleAutocompleteAPI(params) {
    return new Promise((resolve, reject) => {
        const service = google_1.getAutocompleteService();
        service.getQueryPredictions(params, (results, status) => {
            if (status === 'OK' || status === 'ZERO_RESULTS') {
                results = results ? results : [];
                resolve(results);
            }
            else {
                status = status.replace('_', ' ');
                reject(status);
            }
        });
    });
}
exports.googleAutocompleteAPI = googleAutocompleteAPI;
function googleDetailAPI(params) {
    return new Promise((resolve, reject) => {
        const service = google_1.getDetailService();
        service.getDetails(params, (place, status) => {
            if (status === 'OK') {
                resolve(place);
            }
            else {
                status = status.replace('_', ' ');
                reject(status);
            }
        });
    });
}
exports.googleDetailAPI = googleDetailAPI;
