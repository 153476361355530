/* eslint-disable global-require */
import React from 'react';
import store from 'store2';
import Reflux from 'reflux';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, includes } from 'lodash';
import { Redirect } from 'react-router-dom';
import createClass from 'create-react-class';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import i18n from 'i18n';
import { getVoxFeedLite } from 'config/app';
import { finalizeChat } from 'utils/crispActions';
import { ECategoryType } from 'modules/shared/types';
import { PrivacyDialog } from 'modules/shared/containers';
import Competitors from 'modules/shared/screens/CompetitorsPanel';
import AdvertiserLayout from 'modules/shared/advertiser/screens/Layout';
import NonAdvertiserLayout from 'modules/shared/screens/NonAdvertiserLayout';
import InspectAccountsAdmin from 'modules/legacy/js/components/inspect-accounts-admin';
import SocialNetworksForm from 'apps/shared/components/SocialNetworksForm/SocialNetworksForm';

import { Wizard, GalleryOverlay } from 'modules/legacy/js/lib/components';

import CustomDialog from 'apps/shared/components/CustomDialog/CustomDialog';

const VERSIONCAMPAING = 'VersionCampaing';
const SESSION_EXPIRES_AT = 'ExpiresAt';
const TYPESOCIALNETWORK = 'TypeSocialNetwork';
const COMPETITORS = ['brandme', 'crowdsquad'];

const getEmailDomain = user => {
  const email = get(user, 'email', '');
  const fullDomain = email.split('@')[1] || '';
  const domainName = fullDomain.split('.')[0];
  return domainName.toLowerCase();
};

const Actions = {
  Panel: require('../actions/panel'),
  App: require('../actions/app'),
  auth: require('../actions/auth')
};

const Panel = createClass({
  displayName: 'PanelView',

  mixins: [
    Reflux.listenTo(Actions.Panel.displayOnGallery, 'displayOnGallery'),
    Reflux.listenTo(
      Actions.auth.submitVerificationCodeSuccess,
      'onSubmitCodeSuccess'
    )
  ],

  propTypes: {
    dataStore: PropTypes.object.isRequired,
    configStore: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    logout: PropTypes.func,
    removeAllEntities: PropTypes.func,
    params: PropTypes.object,
    children: PropTypes.any,
    user: PropTypes.object,
    session: PropTypes.object,
    currentLanguage: PropTypes.string,
    inspectorComponent: PropTypes.node,
    showSocialAccountsLite: PropTypes.func
  },

  getInitialState() {
    const { user } = this.props;
    const versionCampaing = store.get(VERSIONCAMPAING) || {};
    const campaignLite = versionCampaing.campaignVersion === ECategoryType.LITE;
    let valid;

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!user) {
      if (user.role === 'advertiser') {
        if (getVoxFeedLite()) {
          valid = user.hasCompletePersonalInfo && !!user.category;
        } else {
          valid = user.hasCompletePersonalInfo;
        }
      } else {
        valid = user.hasCompletePersonalInfo;
      }
    }

    return {
      showWizard: !valid,
      firstTimeRequestedWizard: true,
      galleryElements: null,
      selectedImageIndex: 0,
      activeTab: 0,
      stepWizard: !!user && !!user.country ? 2 : 1,
      showAccountForm: false,
      networkName: '',
      campaignLite
    };
  },

  componentDidMount() {
    const { user, showSocialAccountsLite } = this.props;

    const expiresAt = store.get(SESSION_EXPIRES_AT);
    if (user) {
      if (user.id) {
        if (user.role === 'influencer' && user.validCategory) {
          showSocialAccountsLite(user.id).then(data => {
            this.setState({
              showAccountForm: data.length === 0
            });
          });
        }
      }
    }

    if (performance.navigation.type === 1 && !!expiresAt) {
      store.remove(SESSION_EXPIRES_AT);
      const url = window.location.href;
      const path = window.location.pathname;
      window.history.pushState({}, '', '/search/users');
      window.location.href = `${url.split(path)[0]}/search/users`;
    }
  },

  UNSAFE_componentWillReceiveProps(props) {
    const previousUserId = get(this.props, 'user._id');
    const newUserId = get(props, 'user._id');

    if (previousUserId !== newUserId) this.checkIfShowWizard(props.user);
  },

  onSubmitCodeSuccess() {
    Actions.App.displayAlert({
      type: 'success',
      title: i18n.get('PANEL_CODE_SUBMIT_SUCCESS_ALERT_TITLE'),
      message: i18n.get('PANEL_CODE_SUBMIT_SUCCESS_ALERT_DESCRIPTION')
    });
  },

  checkIfShowWizard(user = this.props.user) {
    if (!this.isFirstTimeRequestedWizard()) return;

    const requiresData = !user.hasCompletePersonalInfo;

    this.setState({
      firstTimeRequestedWizard: false,
      showWizard: requiresData
    });
  },

  isFirstTimeRequestedWizard() {
    const { firstTimeRequestedWizard } = this.state;
    return firstTimeRequestedWizard;
  },

  onDialogClose() {
    this.setState({ showWizard: false, showAccountForm: false });
  },

  onSocialNetworkFormClose() {
    store.remove(VERSIONCAMPAING);
    store.remove(TYPESOCIALNETWORK);
    this.setState({ showAccountForm: false });
  },

  handleAccountForm(nameWork) {
    window.localStorage.setItem(TYPESOCIALNETWORK, nameWork);
    this.onDialogClose();
    this.setState({ showAccountForm: true, networkName: nameWork });
  },

  displayOnGallery(elements, selectedImageIndex) {
    this.setState({ selectedImageIndex, galleryElements: elements });
  },

  onGalleryClose() {
    this.setState({ galleryElements: false });
  },

  getAccountInspector() {
    const { session } = this.props;
    const isManagedAccount = get(session, 'isManagedAccount');
    if (isManagedAccount) return <InspectAccountsAdmin />;
  },

  renderWizard() {
    const { showWizard, stepWizard } = this.state;
    if (!showWizard) return null;
    const { user, currentAccount, logout, removeAllEntities } = this.props;

    return (
      <Wizard
        key='home-wizard'
        user={user}
        account={currentAccount}
        onClose={this.onDialogClose}
        onLogout={() => {
          logout();
          finalizeChat();
          removeAllEntities();
        }}
        stepWizard={stepWizard}
        handleAccountForm={this.handleAccountForm}
      />
    );
  },

  render() {
    const {
      showWizard,
      campaignLite,
      galleryElements,
      showAccountForm,
      selectedImageIndex
    } = this.state;

    const { dataStore, configStore, logout, user, admin } = this.props;

    const userRole = get(user, 'role');
    const inspectAccountRender = this.getAccountInspector();

    const containerClassNames = classNames(
      'vf-panel voxfeed height100 flex-column flex-stretch ',
      {
        'account-inspector-active': !!inspectAccountRender
      }
    );

    if (!userRole || !configStore || !dataStore) return null;

    const createdAt = moment(user.createdAt);
    const elapsedHours = moment().diff(createdAt, 'hours', true);
    const typeSocialNetwork = window.localStorage.getItem(TYPESOCIALNETWORK);

    const showSocialNetworkModal =
      user.role !== 'advertiser' &&
      campaignLite &&
      showAccountForm &&
      user.hasCompletePersonalInfo &&
      typeSocialNetwork &&
      !showWizard;

    if (
      elapsedHours >= 24 &&
      !includes(user.verifiedEmails, user.email.toLowerCase()) &&
      !admin
    ) {
      return <Redirect to='/verify-email-reminder' />;
    }

    const domain = getEmailDomain(user);
    if (COMPETITORS.includes(domain)) {
      return <Competitors domain={domain} />;
    }

    return (
      <div className={containerClassNames}>
        <div>
          {this.renderWizard()}

          {this.renderLayoutByUserRole({
            inspectorComponent: inspectAccountRender
          })}

          {showSocialNetworkModal && (
            <CustomDialog
              dismissable={false}
              size='md'
              content={
                <SocialNetworksForm
                  modal
                  onSocialNetworkFormClose={this.onSocialNetworkFormClose}
                  onCancel={() => {
                    window.localStorage.removeItem(TYPESOCIALNETWORK);
                    this.forceUpdate();
                  }}
                  showSocialFormAccount={showSocialNetworkModal}
                />
              }
            />
          )}

          {user.shouldAgreePolicies && !admin && (
            <CustomDialog
              dismissable={false}
              header={i18n.get('PRIVACY_POLICY_TITLE')}
              size='sm'
              content={
                <PrivacyDialog
                  user={user}
                  logout={() => {
                    logout();
                    Actions.auth.signout();
                    finalizeChat();
                  }}
                />
              }
            />
          )}

          <ReactCSSTransitionGroup
            transitionName='vf-animation-overlay'
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
            transitionAppear
            transitionAppearTimeout={500}
          >
            {galleryElements && (
              <GalleryOverlay
                key='gallery'
                gallery={galleryElements}
                selectedImageIndex={selectedImageIndex}
                onClose={() => this.onGalleryClose()}
              />
            )}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    );
  },

  renderLayoutByUserRole(params) {
    const role = get(this.props, 'user.role');
    if (role === 'advertiser') return this.renderAdvertiserLayout(params);
    return this.renderNonAdvertisersLayout(params);
  },

  renderAdvertiserLayout({ inspectorComponent }) {
    const { logout, user, children, params } = this.props;

    return (
      <AdvertiserLayout
        user={user}
        params={params}
        inspectorComponent={inspectorComponent}
        logout={() => {
          logout();
          Actions.auth.signout();
          finalizeChat();
        }}
      >
        {children}
      </AdvertiserLayout>
    );
  },

  renderNonAdvertisersLayout({ inspectorComponent }) {
    const {
      dataStore,
      user,
      currentAccount,
      configStore,
      logout,
      removeAllEntities,
      params,
      children,
      location,
      currentLanguage
    } = this.props;

    return (
      <div>
        {inspectorComponent && (
          <div
            style={{
              top: 0,
              left: 0,
              right: 0,
              position: 'fixed',
              zIndex: 9999
            }}
          >
            {inspectorComponent}
          </div>
        )}

        <NonAdvertiserLayout
          className='flex-1 flex flex-stretch'
          currentAccount={currentAccount}
          currentUser={user}
          configStore={configStore}
          dataStore={dataStore}
          logout={() => {
            logout();
            finalizeChat();
          }}
          removeAllEntities={removeAllEntities}
          location={location}
          params={params}
          currentLanguage={currentLanguage}
        >
          {children}
        </NonAdvertiserLayout>
      </div>
    );
  }
});

Panel.contextTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default Panel;
