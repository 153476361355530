"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const components_1 = require("apps/shared/components");
const components_2 = require("apps/advertisers/PartnerForm/components");
const dist_1 = require("ui-library/dist");
const react_router_dom_1 = require("react-router-dom");
class PartnerForgotPasswordUI extends React.Component {
    constructor() {
        super(...arguments);
        this.handleInputBlur = event => {
            const { onInputBlur } = this.props;
            const { name, value } = event.target;
            onInputBlur(name, value);
        };
        this.handleSubmit = event => {
            event.preventDefault();
            const { onSubmit } = this.props;
            const { elements } = event.target;
            const email = elements.email.value;
            onSubmit({ email });
        };
    }
    render() {
        const { errors, loading } = this.props;
        return (React.createElement("div", null,
            React.createElement(components_2.default, null, i18n.get('FORGOT_RESET_YOUR_PASSWORD')),
            React.createElement("h5", { className: 'center-xs vf-text-gray', style: { margin: '1rem 0 3rem' } }, i18n.get('FORGOT_WELL_SEND_YOU_INSTRUCTIONS')),
            React.createElement("form", { action: '#', onSubmit: this.handleSubmit },
                React.createElement(components_1.FormLabel, { htmlFor: 'email' }, i18n.get('LOGIN_EMAIL')),
                React.createElement(components_1.FormInput, { name: 'email', type: 'email', hasError: !!errors.email, onBlur: this.handleInputBlur }),
                React.createElement("div", { className: 'vf-text-danger center-xs margin-top-1x vf-font-bold', style: { fontSize: '1.8rem' } },
                    React.createElement("div", null, errors.email)),
                React.createElement(dist_1.Button, { color: 'primary', size: 'lg', className: 'width100 margin-top-1x', type: 'submit', disabled: loading }, i18n.get('FORGOT_RESET_PASSWORD')),
                React.createElement(react_router_dom_1.Link, { to: '/login', className: 'width100 margin-top-1x vf-text-primary vf-btn vf-btn-flat vf-btn--lg' }, i18n.get('LOGIN_LOGIN')))));
    }
}
exports.default = PartnerForgotPasswordUI;
