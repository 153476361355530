"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const styles = require("./PowerByVoxfeed.less");
const images_1 = require("assets/images");
class PowerByVoxfeed extends React.PureComponent {
    render() {
        const { style } = this.props;
        return (React.createElement("p", { className: styles.label, style: style },
            i18n.get('POWER_BY'),
            " ",
            React.createElement("img", { src: images_1.logoVoxfeedWhiteImg, alt: 'Voxfeed' })));
    }
}
exports.default = PowerByVoxfeed;
