"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const styles = require("./RolesSelect.less");
const components_1 = require("modules/shared/components");
const images_1 = require("assets/images");
const RolesSelect = ({ onChangeRole }) => (React.createElement(components_1.CardForm, { subtitleBlack: true, smallPadding: true, subtitle: i18n.get('REGISTER_CAPTION') },
    React.createElement("div", { className: styles['roles-select'] },
        React.createElement("div", { className: styles.card, "data-uitest": 'influencer-card', onClick: () => onChangeRole('influencer') },
            React.createElement("img", { src: images_1.iconInfluencerImg, alt: 'influencer' }),
            React.createElement("div", null,
                React.createElement("h3", null, i18n.get('REGISTER_INFLUENCER')),
                React.createElement("p", null, i18n.get('REGISTER_I_WANT_TO_PARTICIPATE_IN_CAMPAIGNS')))),
        React.createElement("div", { className: styles.card, "data-uitest": 'advertiser-card', onClick: () => onChangeRole('advertiser') },
            React.createElement("img", { src: images_1.iconBrandImg, alt: 'advertiser' }),
            React.createElement("div", null,
                React.createElement("h3", null, i18n.get('REGISTER_BRAND')),
                React.createElement("p", null, i18n.get('REGISTER_I_WANT_TO_PROMOTE_MY_BRAND')))))));
exports.default = RolesSelect;
