import {buildAPIRequest} from '../helpers';
import {reports} from '../../../services/voxfeed';
import downloadFileContent from '../../../utils/downloadFileContent';

const UTF_8_BYTE_ORDER_MARK = '%EF%BB%BF';

const initialState = {reports: {error: null}};

export default function reducer(state = initialState, {type}) {
  switch (type) {
    default:
      return state;
  }
}

export const generatePaymentCSV = params => buildAPIRequest({
  params,
  id: 'generatePaymentCSV',
  method: reports.generatePaymentCSV,
  callback: ({data}) => {
    const url = `data:text/csv;charset=utf-8,${UTF_8_BYTE_ORDER_MARK}${encodeURI(data)}`;
    downloadFileContent({
      fileName: `tipalti-payment-${params.id}`,
      url
    });
  }
});
