import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'apps/shared/components/Loading/Loading';

const LoadingState = ({ height = '30rem', className = '' }) => (
  <div className='width100' style={{ height }}>
    <Loading active className={className} />
  </div>
);

LoadingState.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string
};

LoadingState.defaultProps = {
  height: '',
  className: ''
};

export default LoadingState;
