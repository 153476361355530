"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./PartnerFormUI.less");
const controllers_1 = require("apps/advertisers/PartnerForm/controllers");
const components_1 = require("apps/shared/components");
const PartnerFormUI = ({ children, logo }) => (React.createElement("div", { className: styles.wrapper },
    React.createElement(components_1.Img, { className: classnames_1.default('image-contain', styles.logo), height: 112, src: logo }),
    React.createElement("div", { className: styles.card }, children),
    React.createElement("div", { className: styles.footer },
        React.createElement(controllers_1.PartnerFooterController, null))));
exports.default = PartnerFormUI;
