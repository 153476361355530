import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, InputWrapper } from 'ui-library';

import i18n from 'i18n';
import API from '../lib/api';
import App from '../actions/app';
import Auth from '../actions/auth';
import emailRegex from 'utils/emailRegex';
import { getCurrentUser } from 'modules/shared/selectors';
import PartnerFormRoute from 'apps/advertisers/PartnerForm';
import { DebounceInput, FormLabel } from 'apps/shared/components';
import { HeroSections, CardForm } from 'modules/shared/components';

const A_DAY = 24;

class VerifyEmailReminder extends React.Component {
  constructor(props) {
    super(props);
    const { email } = props.currentUser;
    this.state = { email, editEmail: false, isEmailValid: false };
  }

  componentDidMount() {
    App.hideLoader();
  }

  componentDidUpdate(nextProps) {
    const { currentUser } = nextProps;

    if (currentUser && currentUser.createdAt && currentUser.verifiedEmails) {
      const createdAt = moment(currentUser.createdAt);
      const elapsedHours = moment().diff(createdAt, 'hours', true);

      const { verifiedEmails, email } = currentUser;
      const emailIsVerfied = verifiedEmails.indexOf(email.toLowerCase()) >= 0;

      if (elapsedHours < A_DAY || emailIsVerfied) {
        App.gotoPage('/');
      }
    }
  }

  sendEmailVerification = () => {
    const { email, editEmail } = this.state;

    const data = { email };

    const message = editEmail
      ? 'VERIFY_EMAIL_SAVE_REMINDER_SUCCESS_ALERT_DESCRIPTION'
      : 'VERIFY_EMAIL_REMAINDER_SUCCESS_ALERT_DESCRIPTION';

    API.sendEmailVerification(data, (err, res) => {
      if (err) return App.displayAlert({ message: res.message });

      this.setState({ editEmail: false });

      return App.displayAlert({
        type: 'success',
        message: i18n.get(message),
        title: i18n.get('VERIFY_EMAIL_REMAINDER_SUCCESS_ALERT_TITLE')
      });
    });
  };

  saveAndSendEmailVerification = () => {
    const { email } = this.state;

    if (!emailRegex.test(email)) {
      const elem = ReactDOM.findDOMNode(this.refs.emailInput);
      elem.parentNode.classList.add('error');
      elem.focus();

      this.setState({ isEmailValid: true });

      return;
    }

    API.updateCurrentUser({ email }, (err, res) => {
      if (err) return App.displayAlert({ message: res.message });
      this.setState({ email: res.data.email });

      return this.sendEmailVerification();
    });
  };

  logoutHandler = () => {
    this.props.logout();
    Auth.signout();
  };

  handleEmailInputChange = e => {
    e.target.parentNode.classList.remove('error');

    this.setState({
      editEmail: true,
      isEmailValid: false,
      email: e.target.value
    });
  };

  render() {
    const { partnerSettings } = this.props;
    const { email, editEmail, isEmailValid } = this.state;

    if (partnerSettings) return <PartnerFormRoute />;

    return (
      <HeroSections
        buttonPadding
        buttonAction={this.logoutHandler}
        buttonText={i18n.get('VERIFY_EMAIL_EMAIL_SIGN_OUT')}
        description={i18n.get('VERIFY_EMAIL_YOU_MUST_VERIFY_YOUR_ACCOUNT')}
        title={i18n.getHTML('VERIFY_EMAIL_VERIFY_YOUR_ACCOUNT_TO_CONTINUE')}
      >
        <CardForm subtitle={i18n.get('VERIFY_EMAIL_INSTRUCTIONS')}>
          <div className='form'>
            <FormLabel htmlFor='email'>{i18n.get('LOGIN_EMAIL')}</FormLabel>

            <InputWrapper className='lg'>
              <i className='vf-icon icon-mail' />

              <DebounceInput
                type='email'
                name='email'
                value={email}
                ref='emailInput'
                className='login-input'
                hasError={isEmailValid}
                onChange={this.handleEmailInputChange}
                placeholder={i18n.get('LOGIN_EMAIL_PLACEHOLDER')}
              />
            </InputWrapper>

            {isEmailValid && (
              <div className='col vf-text-danger margin-top-Hx'>
                {i18n.get('LOGIN_LOGIN_ERROR_INVALID_EMAIL')}
              </div>
            )}

            <Button
              size='lg'
              color='primary'
              data-uitest='send-email-button'
              className='width100 button-success margin-top-2x'
              onClick={
                editEmail
                  ? this.saveAndSendEmailVerification
                  : this.sendEmailVerification
              }
            >
              {editEmail
                ? i18n.get('VERIFY_EMAIL_SAVE_AND_VERIFY')
                : i18n.get('VERIFY_EMAIL_SEND_AGAIN')}
            </Button>
          </div>
        </CardForm>
      </HeroSections>
    );
  }
}

VerifyEmailReminder.propTypes = {
  logout: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  partnerSettings: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state)
  };
}

export default connect(mapStateToProps)(VerifyEmailReminder);
