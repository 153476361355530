"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const react_router_dom_1 = require("react-router-dom");
const lodash_1 = require("lodash");
const classnames_1 = require("classnames");
const AutoHideHeader_1 = require("modules/shared/components/AutoHideHeader/AutoHideHeader");
const components_1 = require("apps/shared/components");
const controllers_1 = require("apps/advertisers/Header/controllers");
const styles = require("./Menubar.less");
const images_1 = require("assets/images");
const crispActions_1 = require("utils/crispActions");
const ADVERTISERS_HELP_URL = 'https://support.voxfeed.com/hc/es';
const HEADER_HEIGHT = 56;
class Menubar extends React.Component {
    constructor() {
        super(...arguments);
        this.getHeaderStyle = () => {
            const color = lodash_1.get(this.props, 'header.color');
            if (color) {
                return {
                    background: `linear-gradient(180deg, #272727 0%, ${color} 100%)`
                };
            }
            return {};
        };
    }
    render() {
        const _a = this.props, { user, logout, inspectorComponent, header, offset, partnerSettings } = _a, other = __rest(_a, ["user", "logout", "inspectorComponent", "header", "offset", "partnerSettings"]);
        const { validCategory } = user;
        const headerComponent = lodash_1.get(header, 'component');
        const hasImage = !!lodash_1.get(header, 'image');
        const className = classnames_1.default('flex between-xs', styles.menu);
        const logoImage = lodash_1.get(partnerSettings, 'ui.logo', images_1.logoImg);
        return (React.createElement("div", null,
            React.createElement(AutoHideHeader_1.default, Object.assign({ offset: offset, anchor: header ? HEADER_HEIGHT : 0 }, other),
                hasImage && (React.createElement(components_1.Img, { className: 'image-cover image-fit-parent', src: lodash_1.get(header, 'image'), sizes: '100vw' })),
                inspectorComponent,
                React.createElement("div", { className: classnames_1.default({ 'vf-bg-secondary-color': !hasImage }), style: this.getHeaderStyle() },
                    hasImage && React.createElement("div", { className: styles.overlay }),
                    React.createElement("div", { className: className },
                        React.createElement("div", { className: 'flex height100' },
                            React.createElement(react_router_dom_1.Link, { to: '/brands', className: 'height100 margin-right-3x vf-clickable', style: { padding: '1.5rem 0' } },
                                React.createElement("img", { alt: 'presentation', src: logoImage, className: 'height100' })),
                            React.createElement(Options, { validCategory: validCategory })),
                        React.createElement("div", { className: 'flex height100' },
                            React.createElement(controllers_1.OrganizationMenu, null),
                            partnerSettings && (React.createElement(components_1.PowerByVoxfeed, { style: { width: '150px', transform: 'scale(0.8)' } })),
                            React.createElement("a", { onClick: crispActions_1.openChat, className: `vf-badge margin-right-1x ${styles['intercom-launcher']}`, title: i18n.get('INTERCOM_LAUNCHER'), "data-tour-adv-landing": '5' },
                                React.createElement("i", { className: 'vf-icon icon-intercom vf-font-bold vf-text-white', style: { fontSize: '1.5rem', lineHeight: '1.5rem' } })),
                            React.createElement("a", { href: ADVERTISERS_HELP_URL, className: `vf-badge margin-right-1x ${styles.help}`, target: '_blank', title: i18n.get('ADV_BRANDS_HEADER_HELP_CENTER'), "data-tour-adv-landing": '3', rel: 'noreferrer' },
                                React.createElement("span", { className: 'vf-text-secondary vf-font-bold' }, "?")),
                            React.createElement("span", { "data-tour-adv-landing": '4' },
                                React.createElement(controllers_1.UserAvatarDropdown, { logout: logout })))),
                    headerComponent && (React.createElement("div", { className: styles.header }, headerComponent))))));
    }
}
const Options = ({ validCategory }) => (React.createElement("div", { className: 'flex height100' },
    React.createElement(Option, { "data-uitest": 'my-brands-tab', title: i18n.get('ADV_BRANDS_HEADER_BRANDS'), icon: 'ic-brands-menu', className: 'margin-right-2x', redirectTo: '/brands', isActive: /^\/brands/.test(window.location.pathname), "data-tour-adv-landing": '1' }),
    !validCategory && (React.createElement(Option, { title: i18n.get('ADV_BRANDS_HEADER_INFLUENCERS'), icon: 'influencers', redirectTo: '/influencers/social-networks', className: 'margin-right-2x', isActive: /^\/influencers\/social-networks/.test(window.location.pathname), "data-tour-adv-landing": '2' }))));
const Option = (_a) => {
    var { title, redirectTo, icon, className, isActive } = _a, other = __rest(_a, ["title", "redirectTo", "icon", "className", "isActive"]);
    return (React.createElement(react_router_dom_1.Link, Object.assign({ to: redirectTo, className: classnames_1.default('flex center-xs', styles.option, className, {
            [styles.active]: isActive
        }) }, other),
        React.createElement("i", { className: `vf-icon icon-${icon} margin-right-1x`, style: { fontSize: '2.25rem', lineHeight: '2.25rem' } }),
        React.createElement("span", null, title)));
};
exports.default = Menubar;
