import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from 'i18n';
import { isNil } from 'lodash';
import { MoneyInput } from 'apps/shared/components';

export default function ParticipationRequestPostPrice(props) {
  const { postPrice, recommendedBid, brandHighestBid, onChange, currency } =
    props;

  const numericPostPrice = Number(postPrice) || 0;
  const isOfferGreaterThanRecommended =
    !isNil(brandHighestBid) && numericPostPrice > recommendedBid;
  const isOfferGreaterThanMax =
    !isNil(brandHighestBid) && numericPostPrice > brandHighestBid;
  const inputClassName = classNames('start-xs', {
    error: isOfferGreaterThanMax,
    warning: !isOfferGreaterThanMax && isOfferGreaterThanRecommended
  });
  const messageClassName = classNames('margin-top-Hx', {
    'vf-text-danger': isOfferGreaterThanMax,
    'vf-text-warning': !isOfferGreaterThanMax && isOfferGreaterThanRecommended
  });

  return (
    <div className='width100'>
      <h3 className='vf-font-bold'>
        {i18n.get('INF_VOXEAR_PUBLIC_CAMPAIGN_PARTICIPATION_BID')}
      </h3>

      <MoneyInput
        value={postPrice}
        className={inputClassName}
        onChange={onChange}
        data-uitest='money-input'
        currency={currency}
      />

      <div className={messageClassName}>
        {isOfferGreaterThanMax && (
          <span>{i18n.get('INF_VOXEAR_PUBLIC_BID_GREATER_THAN_MAX')}</span>
        )}

        {isOfferGreaterThanRecommended && !isOfferGreaterThanMax && (
          <span>
            {i18n.get('INF_VOXEAR_PUBLIC_BID_GREATER_THAN_RECOMMENDED')}
          </span>
        )}

        {!isNil(brandHighestBid) &&
          !isOfferGreaterThanRecommended &&
          !isOfferGreaterThanMax && (
            <span>
              {i18n.get('INF_VOXEAR_PUBLIC_CAMPAIGN_MAX_BID')}{' '}
              {i18n.getMoney({ amount: brandHighestBid, currency })}
            </span>
          )}
      </div>
    </div>
  );
}

ParticipationRequestPostPrice.propTypes = {
  postPrice: PropTypes.string,
  recommendedBid: PropTypes.number,
  brandHighestBid: PropTypes.number,
  onChange: PropTypes.func
};
