import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { influencerSearchNoResultsImg } from 'assets/images';

const SearchEmptyState = ({ onClearFilters }) => (
  <div className='flex center-xs width100' style={{ height: '40vh' }}>
    <div className='col center-xs' style={{ width: '35rem' }}>
      <img src={influencerSearchNoResultsImg} alt='Empty State' />

      <h2 className='vf-font-bold margin-top-1x'>
        {i18n.get('ADV_CAMPAIGNS_INVITES_TABLE_EMPTY_STATE_TITLE')}
      </h2>

      <span className=' margin-top-Hx'>
        {i18n.get('ADV_CAMPAIGNS_INVITES_TABLE_EMPTY_STATE_MESSAGE')}
        &nbsp;
        <button className='vf-link vf-clear-button' onClick={onClearFilters}>
          {i18n.get('ADV_CAMPAIGNS_INVITES_TABLE_EMPTY_STATE_CTA')}
        </button>
      </span>
    </div>
  </div>
);

SearchEmptyState.propTypes = {
  onClearFilters: PropTypes.func.isRequired
};

export default SearchEmptyState;
