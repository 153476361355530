"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const getPostMedia = (Component) => {
    class Enhancer extends React.Component {
        constructor() {
            super(...arguments);
            this.getPostMedia = () => {
                const { post } = this.props;
                const message = lodash_1.get(post, 'message', { publishableText: '', text: '' });
                const socialNetworkMessage = lodash_1.get(post, 'socialNetworkMessage', {});
                const videos = lodash_1.get(message, 'videos', []).map(vid => lodash_1.get(vid, 'versions.vHigh.url'));
                const videoUrls = lodash_1.get(message, 'videoUrls', []);
                const images = lodash_1.isObject(socialNetworkMessage) && !lodash_1.isEmpty(socialNetworkMessage)
                    ? lodash_1.get(socialNetworkMessage, 'attachments.images', [])
                    : lodash_1.get(message, 'images', []);
                return {
                    videos,
                    images,
                    videoData: lodash_1.get(message, 'videos', []),
                    text: message.publishableText || message.text,
                    videoUrls
                };
            };
        }
        render() {
            return React.createElement(Component, Object.assign({}, this.props, { media: this.getPostMedia() }));
        }
    }
    return Enhancer;
};
exports.default = getPostMedia;
