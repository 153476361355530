"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const qs = require("qs");
const userActions = require("redux/modules/users");
const appActions = require("redux/modules/app");
const sharedActions = require("redux/modules/shared");
const PartnerResetPasswordUI_1 = require("./PartnerResetPasswordUI/PartnerResetPasswordUI");
const passwordValidator_1 = require("apps/shared/hocs/passwordValidator");
const errorStateHandler_1 = require("apps/shared/hocs/errorStateHandler");
const lodash_1 = require("lodash");
const selectors_1 = require("modules/shared/selectors");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
class PartnerResetPassword extends React.Component {
    constructor() {
        super(...arguments);
        this.validateForm = form => {
            const { validatePassword } = this.props;
            const isPasswordValid = validatePassword(form.password);
            const isConfirmValid = form.password === form.confirmPassword;
            return {
                password: isPasswordValid
                    ? null
                    : i18n.get('RESET_PASSWORD_INVALID_LENGTH'),
                confirmPassword: isConfirmValid
                    ? null
                    : i18n.get('RESET_PASSWORD_PASSWORDS_MISMATCH')
            };
        };
        this.handlePasswordBlur = (name, value) => {
            const { handleInputValidation } = this.props;
            const params = {
                name,
                value,
                validationFunction: this.validateForm
            };
            handleInputValidation(params);
        };
        this.handleConfirmBlur = form => {
            const { onSetErrors } = this.props;
            const errors = this.validateForm(form);
            onSetErrors(errors);
        };
        this.handleSubmit = form => {
            const { onSetErrors, setPassword, location } = this.props;
            const errors = this.validateForm(form);
            const isValid = lodash_1.every(errors, val => val === null);
            onSetErrors(errors);
            if (!isValid)
                return;
            const params = qs.parse(location.search.slice(1));
            setPassword(Object.assign(Object.assign({}, form), { token: params.token }))
                .then(this.onSetSuccess)
                .catch(this.onSetFailed);
        };
        this.onSetSuccess = () => {
            const { showAlert, request, loginRequest } = this.props;
            loginRequest(request.response);
            showAlert({
                title: i18n.get('DEFAULT_SUCCESS_TITLE'),
                type: 'success',
                message: i18n.get('RESET_PASSWORD_PASSWORD_RESET_SUCCESS_ALERT_DESCRIPTION')
            });
        };
        this.onSetFailed = () => {
            const { showAlert, request } = this.props;
            const { message } = request.response;
            showAlert({
                type: 'error',
                message
            });
        };
    }
    render() {
        const { errors } = this.props;
        return (React.createElement(PartnerResetPasswordUI_1.default, { errors: errors, onSubmit: this.handleSubmit, onPasswordBlur: this.handlePasswordBlur, onConfirmBlur: this.handleConfirmBlur }));
    }
}
const mapStateToProps = state => ({
    request: selectors_1.getRequest(state, 'setPassword')
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    setPassword: userActions.setPassword,
    loginRequest: sharedActions.loginRequest,
    showAlert: appActions.showAlert
}, dispatch);
const WithErrors = errorStateHandler_1.default(PartnerResetPassword);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(passwordValidator_1.default(WithErrors));
