"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const components_1 = require("modules/shared/components");
const components_2 = require("apps/shared/components");
const styles = require("./ImagesGridUI.less");
const DEFAULT_IMAGES_TO_SHOW = 4;
const ERROR_STATUS = 'error';
const MIN_CONTAINER_WIDTH = 300;
class ImagesGridUI extends React.Component {
    constructor() {
        super(...arguments);
        this.container = null;
    }
    getTotalImagesNotShown() {
        const { urls, maxImagesToShow } = this.props;
        return urls.length - (maxImagesToShow - 1);
    }
    renderImages() {
        const { urls, maxImagesToShow, imagesStatus, isLoading, onShowImage, onImageLoaded, onError } = this.props;
        const imagesToShow = urls[0] !== undefined ? urls.slice(0, maxImagesToShow) : [];
        const totalImagesNotShow = this.getTotalImagesNotShown();
        const containerWidth = this.container ? this.container.offsetWidth : 0;
        const isSmallContainer = containerWidth < MIN_CONTAINER_WIDTH;
        const imageHeight = isLoading ? '0' : isSmallContainer ? '48px' : '104px';
        return imagesToShow.map((url, index) => {
            const isLastImage = maxImagesToShow - 1 === index && maxImagesToShow < urls.length;
            return (React.createElement("div", { key: url, onClick: () => onShowImage(index), className: classnames_1.default('margin-bottom-Hx', {
                    'vf-hide': imagesStatus[url] === ERROR_STATUS
                }), style: {
                    height: imageHeight,
                    width: imagesToShow.length === 1 ? '100%' : '49%'
                } },
                React.createElement("div", { className: styles.image },
                    React.createElement(components_2.Img, { className: 'width100 height100 image-cover', src: url, sizes: imageHeight, htmlAttributes: {
                            onLoad: () => onImageLoaded(url),
                            onError: () => onError(url)
                        } }),
                    !isLastImage && (React.createElement("div", { className: styles['zoom-overlay'] },
                        React.createElement("i", { className: 'vf-icon icon-expand' }))),
                    totalImagesNotShow > 0 && isLastImage && (React.createElement("div", { className: styles.overlay, style: { fontSize: isSmallContainer ? '2rem' : '4rem' } }, `+${totalImagesNotShow}`)))));
        });
    }
    renderErrorMessage() {
        return (React.createElement("div", { className: 'vf-text-danger padding-bottom-1x flex width100 center-xs' },
            React.createElement("div", { className: 'col', style: { width: '50%' } },
                React.createElement("i", { className: 'vf-icon icon-exclamation-triangle' }),
                React.createElement("div", null, i18n.get('PHOTO_GRID_NOT_LOADED_IMAGES_MESSAGE')))));
    }
    render() {
        const { className, hasError, isLoading, style } = this.props;
        const contentClassName = classnames_1.default('flex between-xs flex-grow-1 flex-wrap width100', {
            hidden: !!isLoading,
            'fade-in': !isLoading
        });
        return (React.createElement("div", { className: classnames_1.default('width100', className), style: style, ref: r => (this.container = r) },
            isLoading && (React.createElement("div", { className: 'flex center-xs width100' },
                React.createElement(components_1.Spinner, { style: { height: '5rem', width: '5rem', margin: '3rem 0' } }))),
            React.createElement("div", { className: contentClassName },
                hasError && this.renderErrorMessage(),
                this.renderImages())));
    }
}
ImagesGridUI.defaultProps = {
    className: '',
    style: {},
    imageStyle: {},
    maxImagesToShow: DEFAULT_IMAGES_TO_SHOW
};
exports.default = ImagesGridUI;
