import { isFunction } from 'lodash';
import { authenticateSocialnetwork } from 'modules/legacy/js/lib/api';
import tracking from 'modules/legacy/js/lib/tracking/user';

const YOUTUBE = 'youtube';
const PERSONAL_TYPE = 'personal';

const connectYoutube = ({ redirectTo, onRedirect }) =>
  new Promise((resolve, reject) => {
    const onSocialAccountLinkingAttempt = callback => {
      tracking.track('INF - Link SNAccount Attempt', { type: YOUTUBE });
    };

    const execute = () => {
      const { protocol, host } = window.location;
      const socialNetwork = YOUTUBE;
      const profileType = PERSONAL_TYPE;
      const redirectURL = `${protocol}//${host}/authenticate/${socialNetwork}${
        redirectTo ? `?redirectTo=${redirectTo}` : ''
      }`;
      const payload = { socialNetwork, profileType, metaData: { redirectURL } };
      onSocialAccountLinkingAttempt();

      authenticateSocialnetwork(payload, (err, res) => {
        const { redirect } = res || {};
        if (!redirect) {
          return reject();
        }

        if (isFunction(onRedirect)) onRedirect({ socialNetwork });

        window.location.href = redirect;
      });
    };

    execute();
  });

export default connectYoutube;
