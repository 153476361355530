import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

export default createClass({
  displayName: 'Tooltip',

  propTypes: {
    classValue: PropTypes.string
  },

  render() {
    const { classValue, children } = this.props;
    const className = classValue
      ? 'vf-simple-tooltip ' + classValue
      : 'vf-simple-tooltip';

    return <span className={className}>{children}</span>;
  }
});
