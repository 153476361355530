"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const settings_account_1 = require("modules/legacy/js/components/settings-account");
const AccountInfo_1 = require("modules/accounts/influencer/containers/AccountInfo");
const BODY_COMPONENTS_BY_ROLE = {
    influencer: AccountInfo_1.default,
    default: settings_account_1.default
};
const SettingsAccount = ({ currentUser, currentAccount }) => {
    const leftDays = 0;
    const Component = BODY_COMPONENTS_BY_ROLE[currentUser.role] ||
        BODY_COMPONENTS_BY_ROLE.default;
    return (React.createElement("div", { className: 'vf-bg-white-color full-viewport-height' },
        React.createElement(Component, { user: currentUser, account: currentAccount, updateDays: leftDays })));
};
const mapStateToProps = ({ shared }) => {
    const currentUser = lodash_1.get(shared, 'user');
    const currentAccount = lodash_1.get(shared, 'user.account');
    return {
        currentUser,
        currentAccount
    };
};
exports.default = react_redux_1.connect(mapStateToProps)(SettingsAccount);
