import cookies from '../../../../../utils/cookies';

const parameterNames = [
  'utmSource',
  'utmMedium',
  'utmCampaign',
  'utmTerm',
  'utmContent'
];

const getData = () => cookies.getCookies(parameterNames);

const clearData = () => cookies.deleteCookies(parameterNames);

export default {
  getData,
  clearData
};
