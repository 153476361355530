"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const classnames_1 = require("classnames");
const styles = require("./AlertUI.less");
const ReactCSSTransitionGroup = require("react-transition-group/CSSTransitionGroup");
const ui_library_1 = require("ui-library");
class AlertUI extends React.Component {
    render() {
        const { alerts, onDelete } = this.props;
        const alertList = alerts.map(alert => (React.createElement(AlertBox, Object.assign({ key: alert.message, onDelete: () => onDelete(alert.message) }, alert))));
        return (React.createElement("div", { className: styles.container },
            React.createElement(ReactCSSTransitionGroup, { transitionName: 'vf-animation-faderight', transitionEnterTimeout: 300, transitionLeaveTimeout: 300 }, alertList)));
    }
}
AlertUI.defaultProps = {
    alerts: []
};
exports.default = AlertUI;
const AlertBox = ({ type, message, title, onDelete }) => {
    const iconClass = classnames_1.default('vf-icon', {
        'icon-checkmark': type === 'success',
        'icon-exclamation': type === 'warning',
        'icon-exclamation-triangle': type === 'error'
    });
    const boxClass = classnames_1.default(styles.box, 'flex', {
        [styles.success]: type === 'success',
        [styles.warning]: type === 'warning',
        [styles.error]: type === 'error'
    });
    const headerTitle = type === 'success'
        ? i18n.get('DEFAULT_SUCCESS_TITLE')
        : type === 'error'
            ? i18n.get('DEFAULT_ERROR_TITLE')
            : i18n.get('DEFAULT_WARNING_TITLE');
    return (React.createElement("div", { className: boxClass },
        React.createElement("span", { className: 'margin-horizontal-2x', style: { height: '3.5rem' } },
            React.createElement("i", { className: iconClass, style: { fontSize: '3.5rem' } })),
        React.createElement("div", { className: 'padding-vertical-2x' },
            React.createElement("h4", { className: 'vf-font-semi-bold no-margin' }, title || headerTitle),
            React.createElement("div", { className: styles.message }, message)),
        React.createElement(ui_library_1.Button, { color: 'flat', className: styles.close, onClick: onDelete },
            React.createElement("i", { className: 'vf-icon icon-close' }))));
};
