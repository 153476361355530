"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const React = require("react");
const react_redux_1 = require("react-redux");
const MobileAppBarUI_1 = require("./MobileAppBarUI/MobileAppBarUI");
const types_1 = require("modules/shared/types");
const selectors_1 = require("modules/shared/selectors");
const MobileAppBar = ({ currentUser, appLinks, campaign }) => {
    const { validCategory } = currentUser;
    const campaignLite = validCategory || (campaign === null || campaign === void 0 ? void 0 : campaign.campaignVersion) === types_1.ECategoryType.LITE;
    if (!campaignLite) {
        return React.createElement(MobileAppBarUI_1.default, { currentUser: currentUser, appLinks: appLinks });
    }
    return null;
};
const mapStateToProps = state => ({
    campaign: selectors_1.getFirstCampaign(state),
    currentUser: selectors_1.getCurrentUser(state),
    appLinks: lodash_1.get(state, 'socialAccounts.configuration.appsLinks')
});
exports.default = react_redux_1.connect(mapStateToProps)(MobileAppBar);
