"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const API = require("modules/legacy/js/lib/api");
const images_1 = require("assets/images");
const app_1 = require("config/app");
const companyName = app_1.getDefaultTitle();
const PayoneerForm = props => {
    const payoneerRegister = () => {
        const { currentUser } = props;
        API.getPayoneerUrl({
            _id: currentUser._id,
            callbackURL: `${window.location.origin}/settings/money`
        }, (err, res) => {
            if (err || res.code)
                return console.error(err, res);
            window.location.href = res.redirect;
        });
    };
    const getContent = () => {
        const { currentUser } = props;
        const { payoneerStatus } = currentUser;
        const messagesPayoneer = getMessages();
        if (payoneerStatus) {
            const message = messagesPayoneer[payoneerStatus];
            return (React.createElement("div", null,
                React.createElement("p", { className: 'text-justify' }, message === null || message === void 0 ? void 0 : message.l1),
                React.createElement("p", { className: 'text-justify margin-top-1x' }, message === null || message === void 0 ? void 0 : message.l2)));
        }
        return renderPayoneerForm();
    };
    const getMessages = () => {
        return {
            pending: {
                es: i18n.get('PAYONNER_STATUS_MESSAGES_PENDING_LABEL'),
                l1: i18n.get('PAYONNER_STATUS_MESSAGES_PAYONNER_DESCRIPTION', {
                    companyName
                }),
                l2: i18n.get('PAYONNER_STATUS_MESSAGES_PENDING_TEXT')
            },
            declined: {
                es: i18n.get('PAYONNER_STATUS_MESSAGES_DECLINED_LABEL'),
                l1: i18n.get('PAYONNER_STATUS_MESSAGES_PAYONNER_DESCRIPTION', {
                    companyName
                }),
                l2: i18n.get('PAYONNER_STATUS_MESSAGES_DECLINED_TEXT', { companyName })
            },
            approved: {
                es: i18n.get('PAYONNER_STATUS_MESSAGES_APPROVED_LABEL'),
                l1: i18n.get('PAYONNER_STATUS_MESSAGES_PAYONNER_DESCRIPTION', {
                    companyName
                }),
                l2: i18n.get('PAYONNER_STATUS_MESSAGES_APPROVED_TEXT')
            }
        };
    };
    const renderPayoneerForm = () => {
        return (React.createElement("div", null,
            React.createElement("h5", null, i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYONEER_LINK_PAYONEER')),
            React.createElement("p", { className: 'text-justify' }, i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYONEER_PAYONNER_NEEDED')),
            React.createElement("p", { className: 'margin-top-1x' }, i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYONEER_RETURN_TO_VF', {
                companyName
            })),
            React.createElement("p", { className: 'margin-top-1x' },
                React.createElement("a", { href: app_1.getCheckSwiftUrl(), target: '_blank', "data-uitest": 'find-your-swift-link' }, i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYONEER_FIND_SWIFT'))),
            React.createElement("div", { className: 'margin-top-1x' },
                React.createElement(ui_library_1.Button, { color: 'default', size: 'sm', onClick: payoneerRegister, "data-uitest": 'go-to-payoneer' }, i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYONEER_GO_TO_PAYONEER')))));
    };
    const getDescription = () => {
        const { currentUser } = props;
        const { payoneerStatus } = currentUser;
        const messagesPayoneer = getMessages();
        if (payoneerStatus) {
            const message = messagesPayoneer[payoneerStatus];
            return i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYONEER_BILLING_STATEMENT', { message: (message === null || message === void 0 ? void 0 : message.es) || '' });
        }
        return (React.createElement("div", { "data-uitest": 'payoneer-status-message' },
            React.createElement("p", null,
                " ",
                i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYONEER_NO_ACCOUNT'),
                " "),
            React.createElement("p", { className: 'margin-top-1x' }, i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYONEER_WAIT_FOR_UPDATE'))));
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: 'vf-row' },
            React.createElement("div", { className: 'col-xs-12' },
                React.createElement("h4", null, i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYONEER_DEPOSIT')))),
        React.createElement("div", { className: 'vf-row padding-top-1x' },
            React.createElement("div", { className: 'col-xs-12 col-sm-2' },
                React.createElement("div", null,
                    React.createElement("img", { src: images_1.logoPayoneerImg, alt: 'Logo Payoneer', width: 120 }))),
            React.createElement("div", { className: 'col-xs-12 col-sm-10' },
                React.createElement("div", { className: 'vf-row reverse-sm' },
                    React.createElement("div", { className: 'col-xs-12 col-sm-4' },
                        React.createElement("div", { className: 'vf-descriptor' },
                            React.createElement("div", null, getDescription()))),
                    React.createElement("div", { className: 'col-xs-12 padding-top-1x show-xs' }),
                    React.createElement("div", { className: 'col-xs-12 col-sm-8' }, getContent()))))));
};
exports.default = PayoneerForm;
