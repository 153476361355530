"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const classnames_1 = require("classnames");
const react_textarea_autosize_1 = require("react-textarea-autosize");
const isEmpty = require("lodash/isEmpty");
const i18n = require("i18n");
const app_1 = require("config/app");
const appActions = require("redux/modules/app");
const components_1 = require("modules/shared/components");
const components_2 = require("apps/shared/components");
const styles = require("./ChatTextarea.less");
const ENTER_KEY = 'Enter';
const MAX_ATTACHMENTS = app_1.getMaxChatAttachments();
const MAX_IMAGE_SIZE_IN_MB = 10;
function mapDispatchToProps(dispatch) {
    return redux_1.bindActionCreators({
        showImagesGallery: appActions.showImagesGallery
    }, dispatch);
}
class ChatTextarea extends React.Component {
    constructor() {
        super(...arguments);
        this.textareaContainer = null;
        this.textarea = null;
        this.state = {
            currentText: '',
            images: [],
            isLoadingImages: false,
            isSending: false
        };
        this.onKeyPress = ev => {
            if (ev.key !== ENTER_KEY)
                return;
            ev.preventDefault();
            if (this.canSendMessage())
                this.onSend();
        };
        this.onSend = () => {
            const { onSend } = this.props;
            const { images, currentText } = this.state;
            const attachments = images.map(url => ({ url, type: 'image' }));
            const args = Object.assign({ text: currentText.trim() }, (isEmpty(attachments) ? {} : { attachments }));
            this.setState({ isSending: true }, () => {
                onSend(args).then(this.onSendSuccess).catch(this.onSendFail);
            });
        };
        this.onSendSuccess = () => {
            this.setState({
                currentText: '',
                images: [],
                isSending: false
            }, this.forceTextareaResize);
        };
        this.onSendFail = () => {
            this.setState({ isSending: false });
        };
        this.onImagesChange = images => {
            this.setState({ images }, () => this.forceTextareaResize());
        };
        this.onRemoveImage = index => {
            const { images } = this.state;
            images.splice(index, 1);
            this.setState({ images }, () => {
                if (isEmpty(images))
                    this.forceTextareaResize();
            });
        };
        this.canSendMessage = () => {
            const { images, currentText, isLoadingImages, isSending } = this.state;
            const hasContent = currentText.trim() || !isEmpty(images);
            return !isSending && !isLoadingImages && hasContent;
        };
    }
    forceTextareaResize() {
        setTimeout(() => this.forceUpdate(), 500);
    }
    render() {
        const DEFAULT_PLACEHOLDER = i18n.get('CHAT_DEFAULT_INPUT_PLACEHOLDER');
        const { placeholder, className, showImagesGallery, onErrorsChange } = this.props;
        const { images, currentText } = this.state;
        const hasImages = !isEmpty(images);
        const canSend = this.canSendMessage();
        const sendMessageIconClassName = classnames_1.default('vf-icon icon-send-message', styles.icon, {
            [styles.disabled]: !canSend
        });
        return (React.createElement("div", { className: classnames_1.default('width100 vf-bg-white-light-color', className) },
            hasImages && (React.createElement("div", { style: { marginTop: '1px' }, className: classnames_1.default('flex padding-top-Hx flex-grow-1 vf-bg-white-light-color', styles['images-container']) }, images.map((url, idx) => (React.createElement(ImagePreview, { key: `${url}{idx}`, url: url, onClick: () => showImagesGallery(images, idx), onRemove: () => this.onRemoveImage(idx) }))))),
            React.createElement(react_textarea_autosize_1.default, { ref: r => (this.textareaContainer = r), inputRef: r => (this.textarea = r), className: classnames_1.default(styles.textarea, {
                    [styles['with-images']]: hasImages
                }), value: currentText, maxRows: 4, placeholder: placeholder || DEFAULT_PLACEHOLDER, onChange: ev => this.setState({ currentText: ev.target.value }), onKeyPress: this.onKeyPress }),
            React.createElement("div", { className: styles['icon-container'], style: { right: '4.5rem' }, title: i18n.get('CHAT_SEND_MESSAGE') },
                React.createElement("i", { className: sendMessageIconClassName, onClick: canSend ? this.onSend : null })),
            React.createElement("div", { className: styles['icon-container'], style: { right: '1.5rem' }, title: i18n.get('CHAT_ATTACH_IMAGE') },
                React.createElement(components_1.ChatImagesUploader, { urls: images, maxFiles: MAX_ATTACHMENTS, maxSizeInMB: MAX_IMAGE_SIZE_IN_MB, mediaType: 'image', sizeType: 'Ad', onFilesChange: this.onImagesChange, onLoading: isLoadingImages => this.setState({ isLoadingImages }), onErrorsChange: onErrorsChange }))));
    }
}
ChatTextarea.defaultProps = {
    placeholder: ''
};
const ImagePreview = ({ url, onClick, onRemove }) => (React.createElement("div", { className: classnames_1.default('margin-left-Hx margin-bottom-Hx', styles.image), onClick: () => onClick() },
    React.createElement(components_2.Img, { src: url, className: 'image-cover image-fit-parent', sizes: '60px' }),
    React.createElement("div", { className: styles['image-overlay'] },
        React.createElement("i", { className: 'vf-icon icon-close', onClick: ev => {
                ev.stopPropagation();
                onRemove();
            } }))));
exports.default = react_redux_1.connect(null, mapDispatchToProps)(ChatTextarea);
