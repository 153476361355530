import React from 'react';
import PropTypes from 'prop-types';
import OrderControl from 'modules/accounts/advertiser/search/socialNetworks/components/OrderControl/OrderControl';
import SearchFilter from 'modules/campaigns/advertiser/containers/InfluencerListFilterContainer';
import SearchBar from 'modules/accounts/advertiser/search/containers/SearchBar/SearchBar';
import splitWithLimit from 'utils/splitWithLimit';
import i18n from 'i18n';
import { Paginator } from 'apps/shared/components';
import { getStatics } from 'config/app';
import { withRouter } from 'react-router-dom';
import { SearchEmptyState, LoadingState } from 'modules/shared/components';
import {
  chain,
  debounce,
  isArray,
  isString,
  has,
  values,
  isEmpty,
  pickBy,
  omit,
  get
} from 'lodash';

const PAGE_LIMIT = 24;
const DEFAULT_SORT_KEY = '-totalSuccessfulParticipations';

class SocialNetworkSearcher extends React.Component {
  constructor(props) {
    super(props);

    this.AVAILABLE_SOCIAL_NETWORKS = chain(getStatics())
      .get('socialNetworks')
      .mapValues('name')
      .value();

    this.state = {
      replacing: true,
      showGrid: true,
      isLoading: true,
      sortKey: DEFAULT_SORT_KEY,
      pageSkip: 0,
      showFilters: true,
      searchFilter: this.getDefaultFilters()
    };
  }

  componentDidMount() {
    this.fetchData(true);
    this.debouncedFetchData = debounce(this.fetchData, 500);
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidUpdate(prevprops) {
    const { history, location } = this.props;
    const newRefresh = get(location, 'state.refresh', false);
    const oldRefresh = get(prevprops.location, 'state.refresh', false);

    if (newRefresh && !oldRefresh) {
      history.replace({
        pathname: location.pathname,
        state: { refresh: false }
      });
      this.handleClearAllFilters();
    }
  }

  fetchData = replace => {
    const { searchFilter, pageSkip, sortKey } = this.state;
    const { brand, campaign } = this.props;
    const primaryFilter = {
      'influencerId!': null,
      canParticipate: 'true'
    };

    const updatedFilters = { ...searchFilter, ...primaryFilter };
    if (updatedFilters.tags)
      updatedFilters.tags = updatedFilters.tags.map(t => t.id).join(',');
    if (updatedFilters['engagementRate>'])
      updatedFilters['engagementRate>'] = (
        Number(updatedFilters['engagementRate>']) / 100
      ).toFixed(4);
    if (updatedFilters['engagementRate<'])
      updatedFilters['engagementRate<'] = (
        Number(updatedFilters['engagementRate<']) / 100
      ).toFixed(4);

    this.setState({ isLoading: true, replacing: replace }, () => {
      const filters = pickBy(updatedFilters, el => {
        const isValidStringParam = isString(el) ? el !== '' : true;
        const isValidArrayParam = isArray(el) ? !isEmpty(el) : true;
        return (
          el !== undefined &&
          el !== 'all' &&
          isValidStringParam &&
          isValidArrayParam
        );
      });

      const query = {
        limit: PAGE_LIMIT,
        skip: pageSkip,
        sort: sortKey,
        brandId: brand._id,
        campaignId: campaign._id,
        ...filters
      };
      return this.props
        .fetchAction(query, replace)
        .then(this.handleSuccessFetch)
        .catch(this.handleFailedFetch);
    });
  };

  handleSuccessFetch = () => {
    if (!this.willUnmount) {
      this.setState({
        isLoading: false,
        replacing: false
      });
    }
  };

  handleFailedFetch = () => {
    if (!this.willUnmount) {
      this.setState({ isLoading: false });
      this.props.showAlert({
        title: i18n.get('DEFAULT_ERROR_TITLE'),
        message: i18n.get('ADV_INFLUENCERS_SEARCH_SEARCH_ERROR_ALERT_MESSAGE')
      });
    }
  };

  handlePageChange = page => {
    this.setState(
      {
        pageSkip: page * PAGE_LIMIT
      },
      () => this.fetchData(true)
    );
  };

  toggleFilters = () => {
    if (this.state.showFilters && this.shouldReset()) {
      this.onChangeSearchFilter(this.getDefaultFilters());
    }
    this.setState({ showFilters: !this.state.showFilters });
  };

  onChangeSearchFilter = searchFilter => {
    const newSearchFilter = {
      q: this.state.searchFilter.q,
      interests: this.state.searchFilter.interests,
      ...searchFilter
    };
    const sortKey = this.getAudienceLocationInfo(searchFilter);
    const resetDefault = this.state.sortKey.includes('audience') && !sortKey;
    this.setState(
      {
        searchFilter: newSearchFilter,
        pageSkip: 0,
        isLoading: true,
        sortKey:
          sortKey || (resetDefault ? DEFAULT_SORT_KEY : this.state.sortKey)
      },
      () => this.debouncedFetchData(true)
    );
  };

  onModifySearchFilter = searchFilter => {
    const newSearchFilter = { ...this.state.searchFilter, ...searchFilter };
    const sortKey = this.getAudienceLocationInfo(searchFilter);
    const resetDefault = this.state.sortKey.includes('audience') && !sortKey;
    this.setState(
      {
        searchFilter: newSearchFilter,
        pageSkip: 0,
        isLoading: true,
        sortKey:
          sortKey || (resetDefault ? DEFAULT_SORT_KEY : this.state.sortKey)
      },
      () => this.debouncedFetchData(true)
    );
  };

  handleClearAllFilters = () => {
    this.refs.searchBar.clear();
    this.setState({ searchFilter: this.getDefaultFilters() }, () =>
      this.debouncedFetchData(true)
    );
  };

  onLoadMore = () => {
    this.setState(
      { pageSkip: this.state.pageSkip + PAGE_LIMIT },
      this.fetchData
    );
  };

  shouldReset = fieldName => {
    const current = omit(this.state.searchFilter, ['type', 'interests', 'q']);

    if (fieldName) return has(current, fieldName);

    const resetType =
      this.state.searchFilter.type !== this.getDefaultFilters().type;
    const hasFilters = Object.keys(current).length > 0;

    return resetType || hasFilters;
  };

  resetFilter = filterName => {
    const { searchFilter } = this.state;
    if (!this.shouldReset(filterName)) return;
    if (filterName)
      return this.onChangeSearchFilter(omit(searchFilter, filterName));

    this.onChangeSearchFilter(this.getDefaultFilters());
  };

  getDefaultFilters = () => {
    const availableSocialNetworks = this.getAvailableSocialNetworks();
    const socialNetworks = [];
    availableSocialNetworks.map(current => socialNetworks.push(current[0]));
    return { type: socialNetworks.join(',') };
  };

  getAvailableSocialNetworks = () => {
    const { campaign } = this.props;
    const socialNetworks = Object.entries(this.AVAILABLE_SOCIAL_NETWORKS);

    return !isEmpty(campaign)
      ? socialNetworks.filter(
          sn =>
            get(campaign[sn[0]], 'post.active') ||
            get(campaign[sn[0]], 'story.active')
        )
      : socialNetworks;
  };

  tableRequestSort = sortOptions => {
    const { sortBy, inverseOrder } = sortOptions;
    const sortKey = (inverseOrder ? '-' : '') + sortBy.value;
    this.setState({ sortKey, pageSkip: 0 }, () =>
      this.debouncedFetchData(true)
    );
  };

  filterBlockedInfluencers = () => {
    const { searchFilter } = this.state;
    const { socialAccounts } = this.props;
    return values(socialAccounts).filter(
      inf => searchFilter.showBlocked || !inf.blocked
    );
  };

  toggleGrid = toggle => {
    this.setState({ showGrid: toggle });
  };

  getAudienceLocationInfo = searchFilter => {
    const audienceLocationFilters = Object.keys(searchFilter).find(
      key =>
        key.includes('audience') &&
        (key.includes('countries') ||
          key.includes('cities') ||
          key.includes('regions'))
    );

    if (!audienceLocationFilters) return;
    const split = splitWithLimit(audienceLocationFilters, '.', 2);
    const id = split[2].replace('>', '');
    const type = split[1];
    return `-audience.${type}.${id}`;
  };

  getDefaultEmptyState = () => (
    <SearchEmptyState onClearFilters={this.handleClearAllFilters} />
  );

  render() {
    const {
      showGrid,
      sortKey,
      showFilters,
      isLoading,
      searchFilter,
      replacing,
      pageSkip
    } = this.state;

    const {
      TableComponent,
      GridComponent,
      ExtraOptionsComponent,
      EmptyStateComponent,
      forceIsLoading,
      forceReplacing,
      paginationData,
      showFavoriteOption,
      showBlockedOption
    } = this.props;

    const filteredAccounts = this.filterBlockedInfluencers();
    const hasInfluencersToShow = !isEmpty(filteredAccounts);
    const rotate = showFilters ? 'rotate(180deg)' : 'rotate(0)';
    const loading = isLoading || forceIsLoading;
    const isReplacing = replacing || forceReplacing;
    const { total: totalEntities } = paginationData;
    const filterWidth = '350px';

    return (
      <div className='padding-1x' style={{ marginBottom: '5rem' }}>
        <SearchBar
          ref='searchBar'
          onRequestChange={this.onModifySearchFilter}
        />
        <div className='flex padding-top-2x padding-bottom-3x'>
          <div
            onClick={this.toggleFilters}
            style={{ cursor: 'pointer', width: 'auto' }}
          >
            <h1 className='flex flex-align-center vf-font-bold padding-left-1x'>
              {i18n.get('ADV_INFLUENCERS_ADVANCE_SEARCH')}
              <span
                className='margin-left-1x'
                style={{
                  fontSize: '2rem',
                  maxHeight: '2rem',
                  transform: rotate
                }}
              >
                <i className='vf-icon icon-arrow-right' />
              </span>
            </h1>
          </div>

          <span
            className='vf-text-gray-dark'
            style={{ marginLeft: 'auto', fontSize: '2rem' }}
          >
            {totalEntities > 0 && <span>{i18n.getNumber(totalEntities)}</span>}
            {i18n.get('ADV_INFLUENCERS_TOTAL_INFLUENCERS', {
              total: totalEntities || 0
            })}
          </span>

          <div
            className='flex flex-justify-right flex-align-center'
            style={{ marginLeft: '20px' }}
          >
            {ExtraOptionsComponent}
            <OrderControl
              showGrid={showGrid}
              onRequestShowGrid={() => this.toggleGrid(true)}
              onRequestShowList={() => this.toggleGrid(false)}
              audienceSortKey={this.getAudienceLocationInfo(searchFilter)}
              onRequestSort={this.tableRequestSort}
              sortKey={sortKey}
            />
          </div>
        </div>

        <div className='flex top-xs' data-uitest='invite_influencers_search'>
          {showFilters && (
            <div className='margin-right-1x' style={{ width: filterWidth }}>
              <SearchFilter
                searchFilter={searchFilter}
                showFavoriteOption={showFavoriteOption}
                showBlockedOption={showBlockedOption}
                onRequestClose={this.toggleFilters}
                onRequestReset={this.resetFilter}
                availableSocialNetworks={this.getAvailableSocialNetworks()}
                onChangeSearchFilter={this.onChangeSearchFilter}
              />
            </div>
          )}

          <div style={{ flexGrow: 1, padding: showGrid ? undefined : '0' }}>
            {loading && isReplacing && <LoadingState />}
            {!loading &&
              !hasInfluencersToShow &&
              React.cloneElement(
                EmptyStateComponent || this.getDefaultEmptyState()
              )}

            {hasInfluencersToShow &&
              !showGrid &&
              !isReplacing &&
              React.cloneElement(TableComponent, {
                influencers: filteredAccounts,
                onRequestSort: this.tableRequestSort,
                sortKey
              })}
            {hasInfluencersToShow &&
              showGrid &&
              !isReplacing &&
              React.cloneElement(GridComponent, {
                influencers: filteredAccounts
              })}
          </div>
        </div>

        {hasInfluencersToShow && (
          <div
            className='center-xs padding-top-2x padding-bottom-1x'
            style={{
              paddingLeft: showFilters ? filterWidth : 0,
              marginTop: 'calc(13rem + 8px)'
            }}
          >
            <Paginator
              totalPages={Math.ceil(totalEntities / PAGE_LIMIT)}
              currentPage={Math.floor(pageSkip / PAGE_LIMIT)}
              onPageChange={this.handlePageChange}
            />
          </div>
        )}
      </div>
    );
  }
}

SocialNetworkSearcher.propTypes = {
  TableComponent: PropTypes.node.isRequired,
  GridComponent: PropTypes.node.isRequired,
  fetchAction: PropTypes.func.isRequired,
  showAlert: PropTypes.func,
  brand: PropTypes.object,
  paginationData: PropTypes.object,
  campaign: PropTypes.object,
  socialAccounts: PropTypes.object,
  ExtraOptionsComponent: PropTypes.node,
  forceIsLoading: PropTypes.bool,
  forceReplacing: PropTypes.bool,
  showFavoriteOption: PropTypes.bool,
  showBlockedOption: PropTypes.bool,
  EmptyStateComponent: PropTypes.node
};

SocialNetworkSearcher.defaultProps = {
  showAlert: () => {},
  brand: {},
  campaign: {},
  socialAccounts: {},
  paginationData: {},
  forceIsLoading: false,
  forceReplacing: false,
  showFavoriteOption: false,
  showBlockedOption: false,
  ExtraOptionsComponent: null,
  EmptyStateComponent: null
};

export default withRouter(SocialNetworkSearcher);
