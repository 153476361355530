"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const images_1 = require("assets/images");
const TeamMember = ({ photo = {
    url: images_1.logoVoxfeedThumbnailBlackImg
}, name, title }) => (React.createElement("li", null,
    React.createElement("img", { src: photo.url, alt: 'Photo' }),
    React.createElement("a", { className: 'team-name' }, name),
    React.createElement("span", { className: 'team-envelope' }, title)));
exports.default = TeamMember;
