"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const listTitles_1 = require("./listTitles");
const styles = require("./Titles.less");
const Titles = () => (React.createElement("div", { className: styles.container }, listTitles_1.default.map(item => (React.createElement("div", { className: styles.item, key: item.id },
    React.createElement("img", { src: item.icon, alt: 'icon' }),
    React.createElement("p", null, i18n.get(item.text)))))));
exports.default = Titles;
