"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_imgix_1 = require("react-imgix");
const lodash_1 = require("lodash");
const Img = (_a) => {
    var { imgixParams, htmlAttributes } = _a, others = __rest(_a, ["imgixParams", "htmlAttributes"]);
    const src = lodash_1.get(others, 'src', '');
    if (src && !src.includes('imgix.net')) {
        return React.createElement("img", Object.assign({}, others, htmlAttributes));
    }
    return (React.createElement(react_imgix_1.default, Object.assign({ htmlAttributes: htmlAttributes, imgixParams: Object.assign({ q: 75, lossless: true }, imgixParams) }, others)));
};
exports.default = Img;
