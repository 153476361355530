"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const PartnerFormUI_1 = require("./PartnerFormUI/PartnerFormUI");
const lodash_1 = require("lodash");
const react_redux_1 = require("react-redux");
const selectors_1 = require("modules/shared/selectors");
class PartnerForm extends React.PureComponent {
    render() {
        const { children, partnerSettings } = this.props;
        return (React.createElement(PartnerFormUI_1.default, { logo: lodash_1.get(partnerSettings, 'ui.logo') }, children));
    }
}
const mapStateToProps = state => ({
    partnerSettings: selectors_1.getAppInfo(state, 'partnerSettings')
});
exports.default = react_redux_1.connect(mapStateToProps)(PartnerForm);
