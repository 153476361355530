import React from 'react';
import { isEmpty, has, get } from 'lodash';
import Helpers from '../lib/helpers';
import { getInstance } from 'modules/legacy/js/lib/datamanager';
import { initializeChat } from 'utils/crispActions';

import App from '../actions/app';
import Auth from '../actions/auth';
import ConfigStore from '../actions/configStore';
import DataStore from '../actions/dataStore';

import configStore from '../stores/configStore';
import dataStore from '../stores/dataStore';
import currentAccount from '../stores/currentAccount';

const DataManager = getInstance();

class AuthContainer extends React.Component {
  state = {
    dataStore: dataStore.getAll(),
    currentAccount: currentAccount.getAll(),
    configStore: configStore.getAll()
  };

  UNSAFE_componentWillMount() {
    const storedSession = configStore.getFromStorage();
    DataManager.setConfiguration({
      headers: { auth: storedSession }
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.signinSuccess(props);
  }

  componentDidMount() {
    const { session = {} } = this.props;
    const storedSession = configStore.getFromStorage();

    if (!storedSession || session.error) return this.redirectToLogin();
    this.signinSuccess(this.props);
  }

  signinSuccess = (props) => {
    // eslint-disable-next-line no-shadow
    const { user, session, location, dataStore } = props;

    const newUserId = get(user, '_id');
    const previousUserId = get(this.props, 'user._id');

    if (!session || !session.token) return Helpers.destroyUserSession(false);
    if (!user || !isEmpty(dataStore.User)) return;
    if (session.error) return Helpers.destroyUserSession();
    if (this.props !== props && newUserId === previousUserId) return;

    const payloadOfSuccesfulLoginEvent = {
      user,
      account: user.account,
      token: session.token
    };

    const { email, profileImage } = user;
    initializeChat(email, profileImage);

    return Auth.signinSuccess(
      payloadOfSuccesfulLoginEvent,
      location.pathname + location.search
    );
  }

  redirectToLogin = () => {
    ConfigStore.destroy();
    DataStore.destroy();
    App.hideLoader();
    const redirectPath = `${this.props.location.pathname || ''}${
      this.props.location.search || ''
    }`;
    App.gotoPage('/login', { redirectTo: redirectPath });

    return false;
  }

  shouldComponentUpdate = () => {
    const activeSession = isEmpty(this.props, ['dataStore', 'User']);
    if (has(this.props, ['user', 'role']) && !activeSession) return true;
    return false;
  }

  render() {
    return <div>{this.props.children}</div>;
  }
};

export default AuthContainer;
