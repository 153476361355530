import firebase from 'firebase';
import { pick, get } from 'lodash';
import store from 'store2';

import { updateData } from '../entities';
import { auth, shared } from '../../../services/voxfeed';
import { setAuthHeader } from '../../../services/voxfeed/configurator';
import * as CampaignCacheService from '../../../services/voxfeed/campaigns/cache';

import {
  handleRequestFail,
  handleRequestSuccess,
  buildAPIRequest
} from '../helpers';

import {
  sendRequest,
  sendRequestSuccess,
  changeLanguage,
  sendFirebaseToken
} from '../app';

import {
  showLoader,
  hideLoader,
  manageAccount as onManageAccount,
  gotoPage as changePage
} from './../../../modules/legacy/js/actions/app';

const LOGIN_SUCCESS = 'voxfeed/shared/LOGIN_SUCCESS';
const LOGIN_FAIL = 'voxfeed/shared/LOGIN_FAIL';
const LOGOUT = 'voxfeed/shared/LOGOUT';
const REGISTRATION_ERROR = 'voxfeed/shared/REGISTRATION_ERROR';
const UPDATE_CURRENT_USER_INFO = 'voxfeed/shared/UPDATE_CURRENT_USER_INFO';

const SESSION_KEY_NAME = 'Session';
const SESSION_EXPIRES_AT = 'ExpiresAt';

const ROOT_PATH = '/';
const LANDING_VIEWS_REGEX =
  /(^\/$|\/login|\/register|\/registration-invite|\/forgot|\/reset-password)/;

const initialState = {
  session: {
    token: store.get(SESSION_KEY_NAME)
  }
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case LOGIN_SUCCESS:
      return buildStateAfterSuccessfulLogin(state, payload);
    case LOGIN_FAIL:
      return {
        ...state,
        session: { error: payload },
        user: undefined
      };
    case LOGOUT:
      return {
        ...state,
        session: undefined,
        user: undefined
      };
    case REGISTRATION_ERROR:
      return {
        ...state,
        session: { error: payload },
        user: undefined
      };
    case UPDATE_CURRENT_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user,
          account: payload.account || state.user.account
        }
      };
    default:
      return state;
  }
}

export function loginRequest(data = {}) {
  const isReLogin = !!store(SESSION_KEY_NAME);
  const { redirectTo, noRedirect, openLoader = true } = data;

  if (openLoader) showLoader();

  return dispatch => {
    const login = data.token
      ? auth.loginWithToken(data.token)
      : auth.loginWithCredentials(data);

    return (
      login
        // eslint-disable-next-line no-shadow
        .then(data => {
          const locale = get(data, 'user.locale');

          dispatch(loginSuccess({ data, isReLogin, redirectTo, noRedirect }));
          dispatch(changeLanguage(locale));
          return Promise.resolve();
        })
        .catch(err => {
          hideLoader();
          dispatch(loginFail(err));
          return Promise.reject();
        })
    );
  };
}

function buildStateAfterSuccessfulLogin(state, payload) {
  const session = {
    ...pick(payload.session, 'token', 'expiresAt'),
    ...pick(payload, 'isReLogin', 'isManagedAccount')
  };

  const user = {
    ...payload.user,
    account: payload.account
  };

  const admin = user.role === 'admin' ? { user, session } : null;
  const newState = { ...state, session, user };

  if (admin) newState.admin = admin;

  return newState;
}

export function loginSuccess(payload = {}) {
  const {
    data,
    isReLogin = false,
    isManagedAccount = false,
    noRedirect = false
  } = payload;
  const redirectionURL = getRedirectionURLAfterLogin(payload);
  const token = get(data, 'session.token');

  if (!isManagedAccount) store.set(SESSION_KEY_NAME, token);

  if (!noRedirect) {
    changePage(redirectionURL, () => hideLoader());
  }

  const response = {
    type: LOGIN_SUCCESS,
    payload: { ...data, isReLogin, isManagedAccount }
  };

  if (firebase.messaging && firebase.messaging.isSupported()) {
    return dispatch => {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => {
          messaging
            .getToken()
            // eslint-disable-next-line no-shadow
            .then(token => {
              if (token) {
                dispatch(sendFirebaseToken(token));
              }
            })
            // eslint-disable-next-line no-console
            .catch(error => console.log(error));
        })
        // eslint-disable-next-line no-console
        .catch(error => console.log(error));
      return dispatch(response);
    };
  }
  return response;
}

function getRedirectionURLAfterLogin({ redirectTo = '', data }) {
  const userRole = get(data, 'user.role');
  const defaultUserRolePath = `/${userRole}`;
  if (typeof window === 'undefined') return defaultUserRolePath;

  const currentSearch = get(window, 'location.search', '');
  const [redirectPath] = redirectTo.split('?');

  if (redirectPath === ROOT_PATH) {
    return `${defaultUserRolePath}${currentSearch}`;
  }

  if (!!redirectPath && redirectPath !== ROOT_PATH) return redirectTo;

  const currentPath = get(window, 'location.pathname', ROOT_PATH);
  const isInLandingViews = LANDING_VIEWS_REGEX.test(currentPath);

  return isInLandingViews
    ? `${defaultUserRolePath}${currentSearch}`
    : `${currentPath}${currentSearch}`;
}

export function loginFail(err) {
  store.remove(SESSION_KEY_NAME);
  return {
    type: LOGIN_FAIL,
    payload: get(err, 'response.data', null)
  };
}

export function logout() {
  store.remove(SESSION_KEY_NAME);

  CampaignCacheService.discardAllData();
  changePage('/login');
  return { type: LOGOUT };
}

export function registrationRequest(userData) {
  return dispatch =>
    auth
      .register(userData)
      .then(({ data }) => dispatch(loginRequest(data)))
      .catch(err => dispatch(registrationFail(err)));
}

export function acceptRegistrationInvite(userData) {
  return dispatch =>
    auth
      .acceptRegistrationInvite(userData)
      .then(({ data }) => dispatch(loginRequest(data)))
      .catch(err => dispatch(registrationFail(err)));
}

export function registrationFail(error) {
  return {
    type: REGISTRATION_ERROR,
    payload: get(error, 'response.data', null)
  };
}

export function startAccountManagement({ user, account = {} }) {
  return dispatch => {
    const userId = get(user, '_id');
    const accountId = get(account, '_id');

    if (!userId) return;

    auth.getUserSession(userId).then(response => {
      const session = response.data;
      const { token, expiresAt } = session;

      setAuthHeader(token);

      store.set(SESSION_EXPIRES_AT, expiresAt);

      onManageAccount({ userId, accountId, token }, () => {
        dispatch(
          loginSuccess({
            data: { user, account, session },
            isManagedAccount: true,
            redirectTo: `/${user.role}`
          })
        );
      });
    });
  };
}

export function stopAccountManagement(admin) {
  return dispatch => {
    const { user, session } = admin;
    const { account } = user;
    const userId = get(user, '_id');
    const accountId = get(account, '_id');
    const { token } = session;

    setAuthHeader(token);

    store.remove(SESSION_EXPIRES_AT);

    onManageAccount({ userId, accountId, token }, () => {
      dispatch(
        loginSuccess({
          data: { user, account, session },
          isManagedAccount: false,
          redirectTo: '/search/users'
        })
      );
    });
  };
}

export function updateCurrentUserInfo({ user, account }) {
  return {
    type: UPDATE_CURRENT_USER_INFO,
    payload: { user, account }
  };
}

export function sendVoxFeedFeedback(params) {
  const REQUEST_ID = 'sendVoxFeedFeedback';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    shared
      .sendVoxFeedFeedback(params)
      .then(response =>
        handleRequestSuccess({ dispatch, response, id: REQUEST_ID })
      )
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export function fetchPlace(id) {
  const REQUEST_ID = `fetchPlace_${id}`;

  return dispatch => {
    shared
      .fetchPlaceById(id)
      .then(response => {
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateData(response.data));
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export function searchPlacesByQuery(query) {
  const REQUEST_ID = 'searchPlacesByQuery';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return shared
      .searchPlacesByQuery(query)
      .then(response => {
        dispatch(updateData(response.data));
        dispatch(
          sendRequestSuccess({
            id: REQUEST_ID,
            response: { data: get(response, 'data.data', {}) }
          })
        );
      })
      .catch(response =>
        handleRequestFail({ dispatch, response, id: REQUEST_ID })
      );
  };
}

export function fetchPlacesByIds(ids) {
  const REQUEST_ID = 'fetchPlacesByIds';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return shared.fetchPlacesByIds(ids).then(response => {
      dispatch(updateData(get(response, 'data', {})));
      handleRequestSuccess({ dispatch, response, id: REQUEST_ID });
    });
  };
}

export const fetchProductsByIds = ids =>
  buildAPIRequest({
    id: 'fetchProductsByIds',
    method: shared.fetchProductsByIds,
    params: ids
  });

export const fetchPartnerById = id =>
  buildAPIRequest({
    id: `fetchPartnerById_${id}`,
    method: shared.fetchPartnerById,
    params: id
  });

export const calculatePayPalDepositFee = amount =>
  buildAPIRequest({
    id: 'calculatePayPalDepositFee',
    method: shared.calculatePayPalDepositFee,
    params: amount,
    callback: ({ dispatch, data }) => {
      dispatch(
        updateData({
          data,
          id: amount,
          modelName: 'PayPalDepositFee'
        })
      );
    }
  });

export const loadVoxFeedPayRequest = userId =>
  buildAPIRequest({
    id: 'loadVoxFeedPayRequest',
    showLoader: false,
    method: () => Promise.resolve(store.get(`vf-pay-${userId}`)),
    callback: ({ dispatch, data }) => {
      if (!data) return;

      dispatch(
        updateData({
          id: userId,
          modelName: 'VoxFeedPayRequest',
          data: {
            id: userId,
            requested: !!data
          }
        })
      );
    }
  });

export const requestAccessToVoxFeedPay = ({ userId, ...params }) =>
  buildAPIRequest({
    params,
    id: 'requestAccessToVoxFeedPay',
    method: shared.requestAccessToVoxFeedPay,
    callback: ({ dispatch }) => {
      store.set(`vf-pay-${userId}`, true);

      dispatch(
        updateData({
          id: userId,
          modelName: 'VoxFeedPayRequest',
          data: {
            requested: true
          }
        })
      );
    }
  });

export const constants = {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REGISTRATION_ERROR,
  UPDATE_CURRENT_USER_INFO
};
