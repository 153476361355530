import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './CustomDropdown.less';

export default class CustomDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setRef = ref => {
    this.ref = ref;
  };

  handleClickOutside = event => {
    if (this.state.showDropdown && !this.ref.contains(event.target)) {
      this.setState({ showDropdown: false });

      if (this.props.onClose) {
        this.props.onClose();
      }
    }
  };

  toggleDropdown = () => {
    if (this.state.showDropdown && this.props.onClose) {
      this.props.onClose();
    }

    this.setState({ showDropdown: !this.state.showDropdown });
  };

  render() {
    const { text, content, onClose, className } = this.props;
    const { showDropdown } = this.state;

    return (
      <div
        className={`vf-select width100 ${className}`}
        style={{ zIndex: 3 }}
        ref={this.setRef}
      >
        <div className='vf-dropdown has-dropdown width100'>
          <button
            className={`vf-btn hoverable vf-btn-default vf-btn--sm ${styles.select}`}
            onClick={this.toggleDropdown}
          >
            <span className={styles.text}>{text}</span>
          </button>
        </div>

        {showDropdown && <div className={styles.dropdown}>{content}</div>}
      </div>
    );
  }
}

CustomDropdown.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  content: PropTypes.element,
  onClose: PropTypes.func
};
