import axios from 'axios';

export {
  sendVerificationRequest,
  fetchVerificationRequests,
  approveVerificationRequest,
  rejectVerificationRequest
} from './verification';

export function verifyUserPhone({ userId, phone, email }) {
  return axios.post(`/users/${userId}/verify-current-phone`, { phone, email });
}

export function sendVerificationEmail(data) {
  return axios.post('/auth/email/verification', data);
}

export function verifyUserEmail({ token }) {
  return axios.put('/auth/email/verification', { token });
}

export function updateUser(data) {
  const { id, _id } = data;
  const { brandName, ...dataAux } = data;
  if (brandName) {
    dataAux.brand = { name: brandName };
  }
  return axios.put(`/users/${id || _id}`, { ...dataAux });
}

export function sendPhoneVericationCode({ userId, ...other }) {
  return axios.post(`/users/${userId}/phone/verification`, {
    ...other,
    id: userId
  });
}

export function verifyPhone({ userId, ...other }) {
  return axios.put(`/users/${userId}/phone/verification`, {
    ...other,
    id: userId
  });
}

export function acceptPrivacyPolicy({ userId }) {
  return axios.post(`/users/${userId}/agree-policies`);
}

export function forgetPassword(data) {
  return axios.post('/auth/password/recover', data);
}

export function setPassword(data) {
  return axios.post('/auth/password/set', data);
}

export function getRegistrationInviteByToken(data) {
  return axios.get(`/registration-invites/${data.token}`);
}

export { deleteUser } from './delete';

export const survey = ({ id, ...data }) =>
  axios.post(`/users/${id}/sales-qualifications`, data);

export function getPayoneerUrl({ id, ...params }) {
  return axios.get(`/users/${id}/integrations/payoneer`, { params });
}

export function requestPaypalPayment({ id, password, ...data }) {
  const config = {
    headers: { 'x-password': password }
  };
  return axios.post(`/influencers/${id}/payments_out/paypal`, data, config);
}

export function requestPayoneerPayment({ id, password, ...data }) {
  const config = {
    headers: { 'x-password': password }
  };
  return axios.post(`/influencers/${id}/payments_out/payoneer`, data, config);
}

export function requestPayment({ id, password, ...data }) {
  const config = {
    headers: { 'x-password': password }
  };
  return axios.post(`/influencers/${id}/payments`, data, config);
}

export function getPaymentsOut(params) {
  return axios.get('/payments_out', { params });
}

export function getPaymentsOutCurrency(params) {
  return axios.get('/payments_out/currency-list', { params });
}

export function getUsers(params) {
  return axios.get('/users', { params });
}

export function getAdmins(params) {
  return axios.get('/users/admins', { params });
}

export function setPaymentOut({ id, ...data }) {
  return axios.put(`/payments_out/${id}/process`, data);
}

export function setManualPaymentOut({ id, ...data }) {
  return axios.put(`/payments_out/${id}/complete`, data);
}

export function cancelPaymentOut({ id, ...data }) {
  return axios.put(`/payments_out/${id}/cancel`, data);
}

export function penalizePaymentOut({ id, ...data }) {
  return axios.put(`/payments_out/${id}/penalize`, data);
}

export function setTipaltiStatus({ id, ...data }) {
  return axios.post(`/users/${id}/set-tipalti-status`, data);
}

export function getPaymentMethods({ id }) {
  return axios.get(`/users/${id}/payment-methods`);
}

export function postPaymentMethod({ id, ...data }) {
  return axios.post(`/users/${id}/payment-methods`, data);
}

export function postShopifyToken({ id, ...data }) {
  return axios.post(`/users/${id}/shopify-create-script`, data);
}

export function editAdmin({ id, ...data }) {
  return axios.put(`/users/admins/${id}`, data);
}

export function createAdmin(data) {
  return axios.post('/admin/create-admin', data);
}

export function getPaymentsReport(params) {
  return axios.get('/payments_out/csv', { params });
}
