"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const InviteDetailHeaderUI_1 = require("./InviteDetailHeaderUI/InviteDetailHeaderUI");
const dateDiff_1 = require("utils/dateDiff");
const withProductsTotalizer_1 = require("apps/shared/hocs/withProductsTotalizer");
class InviteDetailHeader extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.getInviteStatusText = () => {
            const { invite } = this.props;
            if (!invite)
                return;
            const now = new Date();
            const diff = dateDiff_1.default(invite.expiresAt, now);
            const diffHours = Math.floor(diff.hours);
            const diffMins = Math.max(Math.floor(diff.minutes), 1);
            const formatedDiff = diffHours > 0
                ? i18n.get('DATE_COUNTER_HOURS', { hours: diffHours })
                : i18n.get('DATE_COUNTER_MINS', { mins: diffMins });
            const statusTexts = this.getStatusTexts(formatedDiff);
            return statusTexts[invite.status];
        };
        this.getStatusTexts = timeLeft => {
            return {
                created: i18n.get('INF_VOXEAR_PARTICIPATION_STATUSES_CREATED', {
                    timeLeft
                }),
                accepted: i18n.get('INF_VOXEAR_PARTICIPATION_STATUSES_ACCEPTED'),
                finished: i18n.get('INF_VOXEAR_PARTICIPATION_STATUSES_FINISHED'),
                cancelled: i18n.get('INF_VOXEAR_PARTICIPATION_STATUSES_CANCELLED')
            };
        };
    }
    render() {
        return (React.createElement(InviteDetailHeaderUI_1.default, Object.assign({}, this.props, { statusText: this.getInviteStatusText() })));
    }
}
exports.default = withProductsTotalizer_1.default(InviteDetailHeader);
