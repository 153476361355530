import {buildAPIRequest} from '../helpers';
import {places} from '../../../services/voxfeed';


export const fetchPlaces = ({replace, ...params}) => buildAPIRequest({
  params,
  id: 'fetchPlaces',
  method: places.fetchPlaces,
  replace
});

export const fetchPlace = params => buildAPIRequest({
  params,
  id: `fetchPlace_${params.id}`,
  method: places.fetchPlaceById
});

export const fetchCities = ({replace, ...params}) => buildAPIRequest({
  params,
  id: 'fetchCities',
  method: places.fetchCities,
  replace
});

export const fetchCountries = ({replace, ...params}) => buildAPIRequest({
  params,
  id: 'fetchCountries',
  method: places.fetchCountries,
  replace
});
