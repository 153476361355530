"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const styles = require("./InvalidLinkPage.less");
const controllers_1 = require("../../controllers");
const components_1 = require("apps/influencers/CampaignLinkPage/components");
const app_1 = require("config/app");
const InvalidLinkPage = () => (React.createElement("div", { className: styles.wrapper },
    React.createElement("div", { className: classnames_1.default(styles.error, 'flex') },
        React.createElement("span", { className: 'margin-right-1x', style: { height: '3rem' } },
            React.createElement("i", { className: 'vf-icon icon-exclamation-triangle' })),
        i18n.get('INF_CAMPAIGN_BRIEF_ERROR')),
    React.createElement("div", { className: classnames_1.default(styles.container, 'flex flex-justify-center flex-align-top') },
        React.createElement("div", { className: styles['left-container'] },
            React.createElement("h1", { className: 'vf-white-text center-xs' }, i18n.get('INF_CAMPAIGN_BRIEF_ERROR_TITLE')),
            React.createElement("div", { className: 'flex margin-top-3x flex-align-top' },
                React.createElement("span", { className: classnames_1.default(styles.icon, 'flex margin-right-1x') },
                    React.createElement("i", { className: 'vf-icon icon-posts' })),
                React.createElement("p", { className: styles.sub }, i18n.get('INF_CAMPAIGN_BRIEF_ERROR_POST'))),
            React.createElement("div", { className: 'flex margin-top-2x flex-align-top' },
                React.createElement("span", { className: classnames_1.default(styles.icon, 'flex margin-right-1x') },
                    React.createElement("i", { className: 'vf-icon icon-message' })),
                React.createElement("p", { className: styles.sub }, i18n.get('INF_CAMPAIGN_BRIEF_ERROR_MESSAGE'))),
            React.createElement("div", { className: 'flex margin-top-2x flex-align-top' },
                React.createElement("span", { className: classnames_1.default(styles.icon, 'flex margin-right-1x') },
                    React.createElement("i", { className: 'vf-icon icon-ic-money' })),
                React.createElement("p", { className: styles.sub }, i18n.get('INF_CAMPAIGN_BRIEF_ERROR_MONEY', {
                    companyName: app_1.getDefaultTitle()
                })))),
        React.createElement(components_1.ResponsiveDialog, null,
            React.createElement(controllers_1.InfluencerLogin, null)))));
exports.default = InvalidLinkPage;
