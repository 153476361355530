import axios from 'axios';
import {stripePublishableKey} from './../../config/app';
import {get} from 'lodash';

const STRIPE_PAYMENT_METHOD = 'stripe';
const PAYPAL_PAYMENT_METHOD = 'paypal';

const createStripeToken = (tokenRequestData) => new Promise((resolve, reject) => {
  window.Stripe.setPublishableKey(stripePublishableKey);

  window.Stripe.card.createToken(tokenRequestData, (status, resStripe) => {
    if (status !== 200) return reject(resStripe);
    return resolve(resStripe);
  });
});

const getBillingInfo = (billInfo) => {
  return {
    name: get(billInfo, 'name', ''),
    vat: get(billInfo, 'vat', ''),
    address: get(billInfo, 'address', ''),
    zipCode: get(billInfo, 'zipCode', ''),
    city: get(billInfo, 'city', ''),
    state: get(billInfo, 'state', ''),
    country: get(billInfo, 'country', '')
  };
};

export function startStripePayment({data, tokenRequestData}) {
  const {amount, billInfo, name, email, brandId} = data;

  return createStripeToken(tokenRequestData)
    .then((resStripe) => {
      const paymentInData = {
        balance: amount,
        stripeToken: resStripe.id,
        billingInfo: getBillingInfo(billInfo),
        contactInfo: {name, email},
        method: STRIPE_PAYMENT_METHOD
      };

      return createBrandDeposit(brandId, paymentInData);
    });
}

export function startPaypalPayment({data}) {
  const {amount, billingInfo, brandId, paypalData, email} = data;

  const paymentInData = {
    balance: amount,
    paypalData,
    method: PAYPAL_PAYMENT_METHOD,
    contactInfo: {email},
    billingInfo: getBillingInfo(billingInfo)
  };

  return createBrandDeposit(brandId, paymentInData);
}

function createBrandDeposit(brandId, paymentInData) {
  const requestURL = `/brands/${brandId}/deposit`;

  return axios.post(requestURL, paymentInData);
}
