/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import styles from './VideoPreview.less';

import FallbackVideoImage from '../FallbackVideoImage';
import SafeImage from 'modules/shared/components/SafeImage/SafeImage';
import { getPlayableVideoType } from 'config/app';

import { videoIconImg as errorImage } from 'assets/images';

class VideoPreview extends Component {
  willUnmount = false;
  video = null;

  constructor(props) {
    super(props);

    this.state = {
      playable: true,
      hasError: true,
      showControls: false
    };
  }

  componentDidMount() {
    const { originalType, checkType } = this.props;
    const isSupported =
      this.video && this.video.canPlayType(originalType) === 'probably';

    if (checkType && !isSupported) {
      this.setState({ playable: false });
    }
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.srcVideo !== this.props.srcVideo) {
      this.video.load();
    }
  }

  handleLoaded = () => {
    if (!this.willUnmount) this.setState({ hasError: false });
  };

  handleError = err => {
    if (!this.willUnmount) this.setState({ hasError: true });
  };

  handleRemove = evt => {
    evt.stopPropagation();
    if (this.props.onRemove) this.props.onRemove(evt);
  };

  handlePlay = () => {
    const { onPlay } = this.props;
    if (onPlay) onPlay();
    this.setState({ showControls: true });
  };

  handlePause = () => {
    const { onPause } = this.props;
    if (onPause) onPause();
    this.setState({ showControls: false });
  };
  goToPost = () => {
    const { url } = this.props;
    window.open(url, '_blank');
  };

  render = () => {
    const {
      onRemove,
      srcVideo,
      previewStyle = {},
      onPlay,
      onPause
    } = this.props;
    const { hasError, playable, showControls } = this.state;
    const style = {
      width: '100%',
      ...previewStyle,
      display: hasError ? 'none' : 'inherit'
    };

    const crossMarkStyle = {
      position: 'absolute',
      left: '95%',
      top: '5%'
    };

    return (
      <div style={{ height: style.height, width: style.width }}>
        {!showControls && !hasError && (
          <div
            className={classNames('flex flex-justify-center', styles.icon)}
            onClick={() => this.video.play()}
          >
            <i className='vf-icon icon-ic-play icon-fix' />
          </div>
        )}

        {playable && (
          <video
            controls={showControls}
            ref={video => {
              this.video = video;
            }}
            style={style}
            type={getPlayableVideoType()}
            onError={this.handleError}
            onLoadedMetadata={this.handleLoaded}
            onPlay={this.handlePlay}
            onPause={this.handlePause}
          >
            <source src={srcVideo} type={getPlayableVideoType()} />
          </video>
        )}

        {!playable && (
          <FallbackVideoImage
            title={i18n.get(
              'INF_PERFORMANCE_PUBLISH_POST_PREVIEW_PROCESSING_VIDEO'
            )}
            markupContent={i18n.getHTML(
              'INF_PERFORMANCE_PUBLISH_POST_PREVIEW_PROCESSING_VIDEO_DESCRIPTION_EXTERNAL'
            )}
            imageType='lock'
          />
        )}

        {hasError && (
          <SafeImage
            url='error'
            errorImage={errorImage}
            onClickError={this.goToPost}
            style={{ cursor: 'pointer' }}
          />
        )}

        {onRemove && (
          <div style={crossMarkStyle} onClick={this.handleRemove}>
            <i className='vf-icon vf-icon-badge -sm icon-close vf-clickable ' />
          </div>
        )}
      </div>
    );
  };
}

VideoPreview.propTypes = {
  className: PropTypes.string,
  previewStyle: PropTypes.object,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  srcVideo: PropTypes.string,
  onRemove: PropTypes.func,
  originalType: PropTypes.string,
  checkType: PropTypes.bool,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  url: PropTypes.string
};

export default VideoPreview;
