import { getCrispId } from 'config/app';
import { tracking } from 'services/voxfeed';

export const initializeChat = (email, profileImage) => {
  if ($crisp) {
    setTimeout(() => {
      $crisp.push(['set', 'user:email', [email]]);
      $crisp.push(['do', 'chat:hide']);
      if (profileImage) {
        $crisp.push(['set', 'user:avatar', [profileImage]]);
      }
      $crisp.push(['on', 'chat:closed', _ => $crisp.push(['do', 'chat:hide'])]);
    }, 2500);
  }
};

export const registerEvent = (name, properties) => {
  tracking.sendTrackingEvent(name, properties || {});
};

export const openChat = () => {
  $crisp.push(['do', 'chat:show']);
  $crisp.push(['do', 'chat:open']);
};

export const crispUpdateUser = userId => {
  const date = new Date();
  const timestamp = date.getTime();
  window.CRISP_TOKEN_ID = userId || timestamp;
  $crisp.push(['do', 'session:reset', [false]]);
};

export const finalizeChat = () => crispUpdateUser(null);

export const initCrispConfig = function (idUser) {
  const crispId = getCrispId();
  if (!crispId) return;

  window.$crisp = [];
  window.CRISP_WEBSITE_ID = crispId;
  window.CRISP_TOKEN_ID = idUser || null;

  (async function () {
    var d = document;
    var s = d.createElement('script');
    var prevousScript = document.getElementById('crispScript');
    s.src = 'https://client.crisp.chat/l.js';
    s.async = 1;
    s.id = 'crispScript';
    d.getElementsByTagName('head')[0].appendChild(s);
  })();
};
