"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const lists_1 = require("./lists");
const styles = require("./ProfileType.less");
const components_1 = require("./components");
const types_1 = require("modules/shared/types");
const ProfileTypeUI = ({ logout, onFinishDone, selectProfile, handleSelectProfile }) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: styles.info },
        React.createElement("p", { className: `vf-text-gray-dark ${styles.info__text}` }, i18n.getHTML('REGISTER_PROFILE_TYPE_DESCRIPTION'))),
    React.createElement("div", { className: styles.info__cards },
        React.createElement(components_1.Titles, null),
        React.createElement(components_1.CardProfile, { list: lists_1.LiteList, type: types_1.ECategoryType.LITE, selectProfile: selectProfile, handleSelectProfile: handleSelectProfile }),
        React.createElement(components_1.CardProfile, { list: lists_1.ProList, type: types_1.ECategoryType.PRO, selectProfile: selectProfile, handleSelectProfile: handleSelectProfile })),
    React.createElement("div", { className: `width100 flex between-xs ${styles['info__content-buttons']}` },
        React.createElement(ui_library_1.Button, { color: 'flat', onClick: logout, className: 'vf-text-gray-dark vf-font-bold' }, i18n.get('LOGOUT')),
        React.createElement(ui_library_1.Button, { size: 'sm', color: 'primary', onClick: onFinishDone, className: styles.info__buttons }, i18n.get('NEXT')))));
exports.default = ProfileTypeUI;
