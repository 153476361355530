import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

const DEVICE_TYPE = 'web';

const zappier = axios.create({
  baseURL: 'https://hooks.zapier.com',
  transformRequest: [
    (data, headers) => {
      headers.common = {
        'Accept': headers.common.Accept
      };

      return JSON.stringify(data);
    }
  ]
});

export function sendVoxFeedFeedback(params) {
  return axios.post('/feedbacks', params);
}

export function fetchPlaceById(id) {
  return axios.get(`/places/${id}`);
}

export function searchPlacesByQuery(query) {
  return axios.get(`/places?q=${query}`);
}

export function fetchPlacesByIds(ids = []) {
  return axios.get(`/places?ids=${ids.join(',')}`);
}

export function fetchAvailableLanguages() {
  return axios.get('/config/available-languages')
    .then(response => response.data);
}

export function fetchSystemSettings() {
  return axios.get('/config/system')
}

export function fetchProductsByIds(ids = []) {
  if (isEmpty(ids)) return Promise.resolve();
  return axios.get('/products', {params: {ids}});
}

export function fetchPartnerSettings() {
  return axios.get('/partners/settings');
}

export function sendFirebaseToken(token) {
  return axios.post('/users/me/devices', 
                    {token: token, 
                      type: DEVICE_TYPE});
}

export function fetchPartnerById(id) {
  return axios.get(`/partners/${id}`);
}

export function calculatePayPalDepositFee(amount) {
  return axios.post('/deposits/calculate-fee', {
    amount,
    method: 'paypal'
  });
}

export function requestAccessToVoxFeedPay(args) {
  return zappier.post('/hooks/catch/1029072/jx5wf0', {
    ...args,
    source: 'platform'
  });
}
