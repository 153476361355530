import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'ui-library';
import is from 'is_js';
import _ from 'lodash';

import i18n from '../../../../../i18n';
import API from '../../lib/api';

import { logoPaypalImg } from 'assets/images';
import { openChat } from 'utils/crispActions';
import linkHOC from 'apps/shared/hocs/link';

class PaypalForm extends React.Component {
  constructor(props) {
    super(props);
    const { currentUser } = props;
    this.state = {
      paypalAccount: _.get(currentUser, 'paypalAccount', ''),
      validateTypes: {
        validationActive: false,
        paypalAccount: is.email
      }
    };
  }

  onSavePaypal = () => {
    if (!this.props.validateAll.call(this)) {
      return this.setState({
        validationActive: true
      });
    }

    this.setState({
      validationActive: false
    });

    API.auto(
      'updateCurrentUser',
      {
        paypalAccount: this.state.paypalAccount
      },
      {
        successMessage: i18n.get(
          'INF_SETTINGS_MONEY_DEPOSITS_PAYPAL_SAVE_SUCCESS'
        )
      }
    );
  };

  getStatusElement = () => {
    const { currentUser } = this.props;
    const userVerifiedEmails = _.get(currentUser, 'verifiedEmails', []);

    if (userVerifiedEmails.indexOf(this.state.paypalAccount) === -1) {
      return (
        <div>
          <p data-uitest='paypal-status-message'>
            {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYPAL_NOT_VERIFIED')}
          </p>
          <a onClick={openChat}>
            {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYPAL_MORE_INFO')}
          </a>
          .
        </div>
      );
    }

    return (
      <p data-uitest='paypal-status-message'>
        {i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYPAL_EMAIL_VERIFIED')}
      </p>
    );
  };

  render() {
    const statusElement = this.getStatusElement();

    const linkedPaypalAccountValidations = this.props.linkedValidation.call(
      this,
      'paypalAccount'
    );

    const hasPaypalAccountError =
      linkedPaypalAccountValidations.className.includes('error');

    return (
      <div>
        <div className='vf-row'>
          <div className='col-xs-12'>
            <h4>{i18n.get('INF_SETTINGS_MONEY_DEPOSITS_PAYPAL_DEPOSIT')}</h4>
          </div>
        </div>

        <div className='vf-row padding-top-1x'>
          <div className='col-xs-12 col-sm-2 margin-bottom-1x'>
            <img src={logoPaypalImg} alt='Logo paypal' width={75} />
          </div>
          <div className='col-xs-12 col-sm-10'>
            <div className='vf-row reverse-sm'>
              <div className='col-xs-12 col-sm-4'>
                <div className='vf-descriptor'>{statusElement}</div>
              </div>
              <div className='col-xs-12 padding-top-1x show-xs' />
              <div className='col-xs-12 col-sm-8'>
                <Input
                  {...linkedPaypalAccountValidations}
                  placeholder='Email'
                  data-uitest='paypal-email-input'
                />

                <div className='padding-top-Hx' hidden={!hasPaypalAccountError}>
                  <span className='vf-text-danger'>
                    {i18n.get(
                      'INF_SETTINGS_MONEY_DEPOSITS_PAYPAL_INVALID_EMAIL'
                    )}
                  </span>
                </div>

                <Button
                  onClick={this.onSavePaypal}
                  color='default'
                  className='margin-top-1x'
                  size='sm'
                  data-uitest='verify-paypal-email-button'
                >
                  {i18n.get(
                    'INF_SETTINGS_MONEY_DEPOSITS_PAYPAL_VERIFY_AND_SAVE'
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PaypalForm.propTypes = {
  currentUser: PropTypes.object
};

export default linkHOC(PaypalForm);
