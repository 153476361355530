"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const Login_1 = require("modules/shared/screens/Login");
const ShopifyOauth_1 = require("modules/shared/screens/ShopifyOauth");
const Registration_1 = require("modules/shared/screens/Registration/Registration");
const RegistrationInvite_1 = require("modules/shared/screens/RegistrationInvite/RegistrationInvite");
const verify_email_1 = require("modules/legacy/js/views/verify-email");
const ForgottenPassword_1 = require("modules/shared/screens/ForgottenPassword");
const PasswordReset_1 = require("modules/shared/screens/PasswordReset");
const CampaignLinkPage_1 = require("apps/influencers/CampaignLinkPage");
const passParams_1 = require("../hocs/passParams");
const Auth_1 = require("../controllers/Auth");
const ModalAuth_1 = require("modules/shared/screens/ModalAuth");
const PPLogin = passParams_1.default(Login_1.default);
const PPShopifyOauth = passParams_1.default(ShopifyOauth_1.default);
const PPRegistration = passParams_1.default(Registration_1.default);
const PPRegistrationInvite = passParams_1.default(RegistrationInvite_1.default);
const PPForgottenPassword = passParams_1.default(ForgottenPassword_1.default);
const PPPasswordReset = passParams_1.default(PasswordReset_1.default);
const PPVerifyEmail = passParams_1.default(verify_email_1.default);
const PPCampaignLinkPage = passParams_1.default(CampaignLinkPage_1.default);
const CommonRoutes = props => (React.createElement(react_router_dom_1.Switch, null,
    React.createElement(react_router_dom_1.Route, { exact: true, path: '/login', component: PPLogin }),
    React.createElement(react_router_dom_1.Route, { exact: true, path: '/shopifyOAuth', component: PPShopifyOauth }),
    React.createElement(react_router_dom_1.Route, { exact: true, path: '/register', component: PPRegistration }),
    React.createElement(react_router_dom_1.Route, { exact: true, path: '/registration-invite/:token', component: PPRegistrationInvite }),
    React.createElement(react_router_dom_1.Route, { exact: true, path: '/forgot', component: PPForgottenPassword }),
    React.createElement(react_router_dom_1.Route, { exact: true, path: '/reset-password', component: PPPasswordReset }),
    React.createElement(react_router_dom_1.Route, { exact: true, path: '/set-password', component: PPPasswordReset }),
    React.createElement(react_router_dom_1.Route, { exact: true, path: '/verify-email', component: PPVerifyEmail }),
    React.createElement(react_router_dom_1.Route, { exact: true, path: '/campaigns/public/:token', component: PPCampaignLinkPage }),
    React.createElement(react_router_dom_1.Route, { exact: true, path: '/modal-auth', component: ModalAuth_1.default }),
    React.createElement(react_router_dom_1.Route, { path: '/', component: Auth_1.default })));
exports.default = CommonRoutes;
