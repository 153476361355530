"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const lodash_1 = require("lodash");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const brands_1 = require("redux/modules/brands");
const selectors_1 = require("modules/shared/selectors");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const components_1 = require("modules/shared/components");
const SelectableChip_1 = require("modules/shared/components/SelectableChip/SelectableChip");
const AdvertiserChip_1 = require("modules/shared/components/AdvertiserChip/AdvertiserChip");
const app_1 = require("redux/modules/app");
const REQUEST_ID = 'transferBrandOwnership';
const TransferDialog = props => {
    const [newOwnerId, setNewOwnerId] = React.useState(null);
    const { request, hideModal } = props;
    React.useEffect(() => {
        const { brandUsers, fetchBrandUsers, brandId, resetRequest } = props;
        resetRequest(REQUEST_ID);
        if (lodash_1.isEmpty(brandUsers))
            fetchBrandUsers({ brandId });
    }, []);
    React.useEffect(() => {
        if (request.status === 'success')
            onSuccess();
        if (request.status === 'fail')
            onFail();
    }, [request]);
    const onSuccess = () => {
        const { showAlert } = props;
        hideModal();
        showAlert({
            type: 'success',
            message: i18n.get('ADV_BRAND_OWNERSHIP_TRANSFER_DIALOG_SUCCESS_MESSAGE')
        });
    };
    const onFail = () => {
        const { showAlert } = props;
        showAlert({
            type: 'error',
            message: request.response.message
        });
    };
    const buildUserOptions = () => {
        const { brandUsers } = props;
        return brandUsers.map(u => {
            return (React.createElement("div", { className: 'col-xs-4 padding-Hx' },
                React.createElement(SelectableChip_1.default, { isSelected: newOwnerId === u.userId, onClick: () => setNewOwnerId(u.userId) },
                    React.createElement(AdvertiserChip_1.default, { advertiser: u }))));
        });
    };
    const transfer = () => {
        const { brand, transferOwnership } = props;
        transferOwnership({ brand, newOwnerId });
    };
    const getContent = () => {
        const { brandUsers, brand } = props;
        const isLoading = request.status === 'loading';
        if (isLoading)
            return React.createElement(components_1.LoadingState, null);
        if (!isLoading && lodash_1.isEmpty(brandUsers)) {
            return (React.createElement("h3", { className: 'padding-2x text-center' }, i18n.get('ADV_BRAND_OWNERSHIP_TRANSFER_DIALOG_EMPTY_SATE')));
        }
        return (React.createElement("div", { className: 'padding-2x' },
            React.createElement("h5", { className: 'vf-font-normal' },
                i18n.getHTML('ADV_BRAND_OWNERSHIP_TRANSFER_DIALOG_BODY', {
                    brandName: brand.name
                }),
                React.createElement("p", { className: 'vf-font-bold margin-top-2x' }, i18n.get('ADV_BRAND_OWNERSHIP_TRANSFER_DIALOG_CHOOSE_TEXT'))),
            React.createElement("div", { className: 'margin-top-1x vf-scrolly', style: { height: '38rem' } },
                React.createElement("div", { className: 'vf-row no-margin' }, buildUserOptions())),
            React.createElement("div", { className: 'flex end-xs width100 margin-top-2x' },
                React.createElement(ui_library_1.Button, { className: 'margin-right-Hx', onClick: hideModal }, i18n.get('CANCEL')),
                React.createElement(ui_library_1.Button, { color: 'primary', disabled: !newOwnerId || isLoading, onClick: transfer }, isLoading
                    ? i18n.get('ADV_BRAND_OWNERSHIP_TRANSFER_DIALOG_TRANSFER_BUTTON_LOADING')
                    : i18n.get('ADV_BRAND_OWNERSHIP_TRANSFER_DIALOG_TRANSFER_BUTTON')))));
    };
    return (React.createElement(CustomDialog_1.default, { dismissable: false, header: i18n.get('ADV_BRAND_OWNERSHIP_TRANSFER_DIALOG_TITLE'), content: getContent(), onClose: hideModal }));
};
const mapStateToProps = (state, { brandId }) => {
    const { entities, shared, app } = state;
    const { requests } = app;
    const { user } = shared;
    const { Account: brands } = entities;
    const request = requests[REQUEST_ID] || {};
    const brand = brands[brandId];
    const brandUsers = selectors_1.getBrandUsers(state, brandId).filter(u => u.userId !== user._id && u.status === 'active');
    return { request, brand, user, brandUsers };
};
const mapDispatchToProps = dispatch => {
    return redux_1.bindActionCreators({
        fetchBrandUsers: brands_1.fetchBrandUsers,
        transferOwnership: brands_1.transferOwnership,
        hideModal: app_1.hideModal,
        resetRequest: app_1.resetRequest,
        sendRequest: app_1.sendRequest,
        showAlert: app_1.showAlert
    }, dispatch);
};
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(TransferDialog);
