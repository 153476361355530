"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const controllers_1 = require("../controllers");
class PartnerForm extends React.Component {
    render() {
        return (React.createElement(controllers_1.PartnerFormController, null,
            React.createElement(react_router_dom_1.Route, { path: '/login', component: controllers_1.PartnerLoginController }),
            React.createElement(react_router_dom_1.Route, { path: '/register', component: controllers_1.PartnerRegisterController }),
            React.createElement(react_router_dom_1.Route, { path: '/registration-invite/:token', component: controllers_1.PartnerRegisterController }),
            React.createElement(react_router_dom_1.Route, { path: '/forgot', component: controllers_1.PartnerForgotPasswordController }),
            React.createElement(react_router_dom_1.Route, { path: '/reset-password', component: controllers_1.PartnerResetPasswordController }),
            React.createElement(react_router_dom_1.Route, { path: '/verify-email-reminder', component: controllers_1.PartnerVerifyEmailController })));
    }
}
exports.default = PartnerForm;
