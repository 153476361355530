const Actions = {
  App: require('../actions/app'),
  ConfigStore: require('../actions/configStore'),
  DataStore: require('../actions/dataStore')
};

export default {
  dayNames: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado'
  ],
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Deciembre'
  ],

  /**
   * Clean the Stores
   * @method destroyUserSession <public> [Function]
   */
  destroyUserSession(redirect = true) {
    Actions.ConfigStore.destroy();
    Actions.DataStore.destroy();
    Actions.App.hideLoader();
    if (redirect) {
      Actions.App.gotoPage('/login');
    }

    return this;
  }
};
