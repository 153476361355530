"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const withProductsTotalizer_1 = require("apps/shared/hocs/withProductsTotalizer");
const ProductsPaymentUI_1 = require("./ProductsPaymentUI/ProductsPaymentUI");
class ProductsPayment extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.groupProductsByDeliveryType = () => {
            return lodash_1.groupBy(this.props.productsPayment, p => lodash_1.get(p, 'deliveryInfo.type'));
        };
    }
    render() {
        const { onEditShippingAddress, currency } = this.props;
        return (React.createElement(ProductsPaymentUI_1.default, { productsPaymentGroups: this.groupProductsByDeliveryType(), onEditShippingAddress: onEditShippingAddress, currency: currency }));
    }
}
exports.default = withProductsTotalizer_1.default(ProductsPayment);
