import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import Main from 'apps/shared/routes/Main';
import ScrollToTop from 'apps/shared/components/ScrollToTop';

function AppContainer() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Main />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default hot(module)(AppContainer);
