"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const brands_1 = require("redux/modules/brands");
const app_1 = require("redux/modules/app");
const selectors_1 = require("modules/shared/selectors");
const CustomDialog_1 = require("apps/shared/components/CustomDialog/CustomDialog");
const Confirmation = props => {
    const [isLoading, setIsLoading] = React.useState(false);
    const { brand, brandUser, hideModal } = props;
    const onRemoveSuccess = () => {
        const { showAlert, onSuccess } = props;
        if (onSuccess)
            onSuccess();
        hideModal();
        showAlert({
            type: 'success',
            title: i18n.get('DEFAULT_SUCCESS_TITLE'),
            message: i18n.get('ADV_BRANDS_USER_DELETE_REMOVE_SUCCESS_ALERT_MESSAGE', {
                userEmail: brandUser.email
            })
        });
    };
    const onRemoveFail = () => {
        const { showAlert } = props;
        setIsLoading(false);
        showAlert({
            type: 'error',
            message: i18n.get('ADV_BRANDS_USER_DELETE_REMOVE_ERROR_ALERT_MESSAGE')
        });
    };
    const remove = () => {
        const { removeUser } = props;
        setIsLoading(true);
        removeUser({ brandId: brand.id, userId: brandUser.userId })
            .then(onRemoveSuccess)
            .catch(onRemoveFail);
    };
    const content = (React.createElement("div", { className: 'padding-2x' },
        React.createElement("h5", { className: 'vf-font-normal' }, i18n.getHTML('ADV_BRANDS_USER_DELETE_DESCRIPTION', {
            userName: brandUser.name,
            brandName: brand.name
        })),
        React.createElement("div", { className: 'flex width100 end-xs margin-top-2x' },
            React.createElement(ui_library_1.Button, { color: 'flat', className: 'margin-right-Hx vf-font-bold', onClick: hideModal }, i18n.get('CANCEL')),
            React.createElement(ui_library_1.Button, { className: 'margin-right-Hx', color: 'danger', disabled: isLoading, onClick: remove }, isLoading
                ? i18n.get('ADV_BRANDS_USER_DELETE_REMOVING')
                : i18n.get('ADV_BRANDS_USER_DELETE_REMOVE')))));
    return (React.createElement(CustomDialog_1.default, { size: 'sm', header: i18n.get('ADV_BRANDS_USER_DELETE_REMOVE'), content: content, onClose: hideModal }));
};
const mapStateToProps = (state, { brandUser }) => ({
    request: selectors_1.getRequest(state, `removeBrandUser_${brandUser.userId}`)
});
const mapDispatchToProps = dispatch => redux_1.bindActionCreators({
    removeUser: brands_1.removeUser,
    hideModal: app_1.hideModal,
    sendRequest: app_1.sendRequest,
    showAlert: app_1.showAlert
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Confirmation);
