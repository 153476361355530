"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const components_1 = require("apps/shared/components");
const styles = require("./PublicCampaignCardLite.less");
const images_1 = require("assets/images");
const components_2 = require("./components");
const OPENVERIFYMODAL = 'OpenVerifyModal';
const OPENMODAL = 'OpenModal';
const ACCOUNTINFO = 'AccountInfo';
const ACTIVEPOST = 'ActivePost';
const HISTORY = 'History';
const STORY = 'Story';
const PublicCampaignCardLite = ({ campaign, onClick, AvailableParticipation, vox = false, products, history }) => {
    window.localStorage.removeItem(OPENVERIFYMODAL);
    window.localStorage.removeItem(OPENMODAL);
    window.localStorage.removeItem(ACCOUNTINFO);
    window.localStorage.removeItem(STORY);
    const [numberOfPost, setNumberOfPost] = React.useState(0);
    if (!history) {
        window.localStorage.removeItem(HISTORY);
    }
    const containerClassName = classnames_1.default('padding-1x', styles.container, {
        'vf-clickable': !!onClick,
        [styles.vox]: vox
    });
    if (vox) {
        window.localStorage.setItem(ACTIVEPOST, 'true');
    }
    return (React.createElement(React.Fragment, null,
        !vox && (React.createElement("div", { onClick: onClick, className: containerClassName },
            React.createElement(components_1.Img, { className: 'image-cover image-fit-parent', sizes: '600px', src: campaign.coverImage || images_1.defaultCampaignCoverImg }),
            React.createElement("div", { className: 'vf-gradient' }),
            React.createElement(components_2.CampaignAvatar, { campaign: campaign }),
            React.createElement(components_2.RenderBadges, { AvailableParticipation: AvailableParticipation }))),
        vox && (React.createElement("div", { onClick: onClick, className: classnames_1.default(containerClassName, {
                [styles.voxBadges]: vox
            }) },
            React.createElement("div", { className: styles.image },
                React.createElement(components_1.Img, { className: 'image-cover image-fit-parent', sizes: '600px', src: campaign.coverImage || images_1.defaultCampaignCoverImg }),
                React.createElement("div", { className: 'vf-gradient' }),
                React.createElement(components_2.CampaignAvatar, { campaign: campaign })),
            React.createElement("div", { key: campaign.id },
                React.createElement(components_2.RenderBadges, { campaign: campaign.availableParticipations, vox: true, history: history, products: products, setNumberOfPost: setNumberOfPost }),
                !history && (React.createElement("div", { className: styles.postContainer },
                    React.createElement("p", null, `${numberOfPost} pending post`),
                    React.createElement("p", null, "To post"))))))));
};
exports.default = PublicCampaignCardLite;
