"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const components_1 = require("../components");
const ui_library_1 = require("ui-library");
const lodash_1 = require("lodash");
const components_2 = require("apps/advertisers/PartnerForm/components");
class PartnerVerifyEmailUI extends React.Component {
    constructor() {
        super(...arguments);
        this.handleSendAgain = event => {
            event.preventDefault();
            const { onSubmit, defaultEmail } = this.props;
            const { elements } = event.target;
            const email = lodash_1.get(elements, 'email.value', defaultEmail);
            onSubmit({ email });
        };
        this.handleCloseSession = event => {
            event.preventDefault();
            const { onLogout } = this.props;
            onLogout();
        };
    }
    render() {
        const { defaultEmail, errors, loading, onInputBlur } = this.props;
        return (React.createElement("div", null,
            React.createElement(components_2.default, null, i18n.get('VERIFY_EMAIL_VERIFY_YOUR_ACCOUNT_TO_CONTINUE')),
            React.createElement("h5", { className: 'center-xs vf-font-normal', style: { margin: '1rem 0 3rem' } }, i18n.get('VERIFY_EMAIL_YOU_MUST_VERIFY_YOUR_ACCOUNT')),
            React.createElement("form", { action: '#', onSubmit: this.handleSendAgain },
                React.createElement(components_1.default, { type: 'email', name: 'email', defaultValue: defaultEmail, onBlur: onInputBlur }),
                React.createElement("div", { className: 'vf-text-danger center-xs margin-top-1x vf-font-bold', style: { fontSize: '1.8rem' } },
                    React.createElement("div", null, errors.email)),
                React.createElement(ui_library_1.Button, { color: 'primary', size: 'lg', className: 'width100 margin-top-2x', type: 'submit', disabled: loading }, i18n.get('VERIFY_EMAIL_SEND_AGAIN')),
                React.createElement(ui_library_1.Button, { color: 'flat', size: 'lg', className: 'width100 margin-top-1x vf-text-primary', onClick: this.handleCloseSession, disabled: loading }, i18n.get('VERIFY_EMAIL_EMAIL_SIGN_OUT')))));
    }
}
exports.default = PartnerVerifyEmailUI;
