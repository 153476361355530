"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles = require("./AvailableAccountsLiteUI.less");
const DialogContainer_1 = require("./components/DialogContainer");
const SocialNetworkCard_1 = require("./components/SocialNetworkCard");
const OPENMODAL = 'OpenModal';
const ACCOUNTINFO = 'AccountInfo';
const STORY = 'Story';
const AvailableAccountsLiteUI = ({ participationNetworks, setParticipationModal, participationModal, onPost, postLink, onCloseModal, continueToVox, isActivePost, getStoryPost, getStory, brandName, isActiveStory, activePost, ship, onErrorSettingShippingAddress }) => {
    const showParticipacionModal = (data, postStory, isNoActiveParticipationIG) => {
        if (ship) {
            return onErrorSettingShippingAddress();
        }
        if (postStory) {
            window.localStorage.setItem(STORY, 'true');
        }
        window.localStorage.setItem(OPENMODAL, 'true');
        if (data) {
            const res = {
                userPhoto: data.userPhoto,
                socialNetwork: data.socialNetwork,
                username: data.username,
                followers: data.followers,
                type: data.type
            };
            window.localStorage.setItem(ACCOUNTINFO, JSON.stringify(res));
        }
        if (!isActivePost) {
            setParticipationModal(!participationModal);
            onPost(data.socialNetwork, data.id, isNoActiveParticipationIG);
        }
        else {
            setParticipationModal(!participationModal);
        }
    };
    const renderNetwork = item => {
        const noStory = (!isActivePost && item.socialNetwork !== 'instagram') ||
            isActivePost ||
            (!getStory && !getStoryPost && item.socialNetwork === 'instagram');
        const isInstagram = !isActivePost && item.socialNetwork === 'instagram';
        return (React.createElement(React.Fragment, null,
            noStory && (React.createElement(SocialNetworkCard_1.default, { showButton: true, network: item, showParticipacionModal: showParticipacionModal, isStory: isActivePost && item.type === 'story' })),
            isInstagram && (activePost || getStory) && (React.createElement(SocialNetworkCard_1.default, { isStory: true, showButton: true, network: item, showParticipacionModal: showParticipacionModal })),
            isInstagram && isActiveStory && (React.createElement(SocialNetworkCard_1.default, { showButton: true, network: item, isStory: false, showParticipacionModal: showParticipacionModal })),
            isInstagram && !isActiveStory && !activePost && getStoryPost && (React.createElement(SocialNetworkCard_1.default, { showButton: true, network: item, isStory: false, isNoActiveParticipationIG: 'post', showParticipacionModal: showParticipacionModal })),
            isInstagram && !isActiveStory && !activePost && getStoryPost && (React.createElement(SocialNetworkCard_1.default, { isStory: true, showButton: true, network: item, isNoActiveParticipationIG: 'story', showParticipacionModal: showParticipacionModal }))));
    };
    return (React.createElement("div", { className: styles.container },
        participationNetworks &&
            participationNetworks.map((item, i) => (React.createElement(React.Fragment, null, renderNetwork(item)))),
        React.createElement(DialogContainer_1.default, { postLink: postLink, brandName: brandName, onCloseModal: onCloseModal, continueToVox: continueToVox, participationModal: participationModal })));
};
exports.default = AvailableAccountsLiteUI;
