import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isEmpty, last, head, find } from 'lodash';
import classNames from 'classnames';
import { Button } from 'ui-library';
import i18n from 'i18n';

import ensureChildNodeVisibility from 'utils/ensureChildNodeVisibility';

import * as chatsActions from 'redux/modules/chats';

import {
  getChat,
  getCurrentUser,
  getChatMessages,
  getChatMembers
} from 'modules/shared/selectors';

import { ChatBubble, LoadingState } from 'modules/shared/components';

function mapStateToProps(state, { chatId }) {
  return {
    currentUser: getCurrentUser(state),
    chat: getChat(state, chatId),
    messages: getChatMessages(state, chatId),
    members: getChatMembers(state, chatId),
    acceptationRequest: get(state, `app.requests.acceptChat_${chatId}`, {}),
    rejectionRequest: get(state, `app.requests.blockChat_${chatId}`, {})
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      acceptChat: chatsActions.acceptChat,
      rejectChat: chatsActions.blockChat
    },
    dispatch
  );
}

class ChatConversation extends Component {
  static propTypes = {
    chatId: PropTypes.string.isRequired,
    chat: PropTypes.object,
    messages: PropTypes.array.isRequired,
    members: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    onFetchMessages: PropTypes.func,
    onFetchMembers: PropTypes.func,
    onSetLastReadMessage: PropTypes.func,
    isLoading: PropTypes.bool,
    style: PropTypes.object,
    acceptChat: PropTypes.func.isRequired,
    rejectChat: PropTypes.func.isRequired,
    acceptationRequest: PropTypes.object.isRequired,
    rejectionRequest: PropTypes.object.isRequired,
    errors: PropTypes.array
  };

  static defaultProps = {
    chat: {},
    isLoading: false,
    style: {},
    errors: [],
    onFetchMembers: () => {},
    onFetchMessages: () => {},
    onSetLastReadMessage: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      oldestMessage: head(props.messages),
      latestMessage: last(props.messages)
    };
  }

  UNSAFE_componentWillMount = () => {
    this.props.onFetchMembers();
    this.props.onFetchMessages();
  };

  componentDidMount = () => {
    const { members } = this.props;
    const { latestMessage } = this.state;

    if (!!members && latestMessage) this.checkLastReadMessage();
    this.scrollToBottom();
  };

  shouldComponentUpdate(nextProps) {
    const { messages, members, isLoading, errors } = this.props;
    const didMessagesChange = nextProps.messages.length !== messages.length;
    const didMembersChange = nextProps.members !== members;
    const didLoadingChange = nextProps.isLoading !== isLoading;
    const didErrorsChange = nextProps.errors.length !== errors.length;

    return (
      didLoadingChange ||
      didMessagesChange ||
      didMembersChange ||
      didErrorsChange
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { members, chatId, messages } = this.props;
    const prevCurrentMember = find(
      members,
      m => m.userId === nextProps.currentUser._id
    );
    const nextCurrentMember = find(
      nextProps.members,
      m => m.userId === nextProps.currentUser._id
    );

    const didChatChange = chatId !== nextProps.chatId;
    const didMessagesChange = messages.length !== nextProps.messages.length;
    const didLastReadMessageChange =
      get(prevCurrentMember, 'lastReadMessage.index') !==
      get(nextCurrentMember, 'lastReadMessage.index');

    if (didChatChange) this.onChatChange();
    if (didMessagesChange) this.onMessagesChange(nextProps);
    if (didLastReadMessageChange) this.checkLastReadMessage(nextProps);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { errors } = this.props;
    const didErrorsChange = errors.length !== prevProps.errors.length;

    if (didErrorsChange) return this.scrollToBottom();
    this.ensureMessagesVisibility(prevProps, prevState);
  };

  onChatChange = () => {
    this.props.onFetchMembers();
    this.props.onFetchMessages();
  };

  onMessagesChange(nextProps) {
    const { messages = [] } = nextProps;

    this.setState(
      {
        oldestMessage: head(messages),
        latestMessage: last(messages),
        prevLatestMessage: this.state.latestMessage
      },
      () => {
        this.checkLastReadMessage(nextProps);
      }
    );
  }

  onAccept = () => {
    this.props.acceptChat(this.props.chat.id);
  };

  onReject = () => {
    this.props.rejectChat(this.props.chat.id);
  };

  checkLastReadMessage = (props = this.props) => {
    const { members, currentUser, chat } = props;
    const { latestMessage } = this.state;

    const currentMember = find(members, m => m.userId === currentUser._id);
    const lastReadMessageByUser = get(
      currentMember,
      'lastReadMessage.index',
      -1
    );
    const didUserReadANewMessage =
      !!latestMessage &&
      lastReadMessageByUser < latestMessage.index &&
      latestMessage.chatId === chat.id;

    if (didUserReadANewMessage) {
      this.props.onSetLastReadMessage(latestMessage.id);
    }
  };

  ensureMessagesVisibility = (prevProps, prevState) => {
    const didMessagesChange =
      prevProps.messages.length !== this.props.messages.length;
    const didMembersChange = prevProps.members !== this.props.members;
    const prevOldestMessage = get(prevState, 'oldestMessage');
    const nextOldestMessage = get(this.state, 'oldestMessage', {});
    const didOldestMessageChange =
      !!prevOldestMessage &&
      prevOldestMessage.index !== nextOldestMessage.index;

    if (didOldestMessageChange) {
      return this.scrollToPrevOldestMessage(prevOldestMessage.id);
    }

    if (didMessagesChange || didMembersChange) this.scrollToBottom();
  };

  scrollToPrevOldestMessage(messageId) {
    if (!this.container) return;

    const messageNode = this.container.querySelector(`#message${messageId}`);

    ensureChildNodeVisibility(messageNode, this.container);
  }

  scrollToBottom = () => {
    if (this.container) {
      this.container.scrollTop = this.container.scrollHeight;
    }
  };

  getUsername = (chat, message) => {
    const { currentUser, members } = this.props;
    const currentMessageUserId = message.userId;
    const isFromCurrentUser = currentUser._id === currentMessageUserId;
    const isFromInfluencer = chat.influencer.id === currentMessageUserId;

    if (isFromCurrentUser) return;

    if (isFromInfluencer) {
      return `${chat.influencer.name} ${chat.influencer.lastName}`;
    }

    const member = find(members, m => m.userId === currentMessageUserId) || {};

    return i18n.get('CHAT_ADVERTISER_USERNAME', {
      name: member.name,
      brandName: chat.brand.name
    });
  };

  renderBubbles = () => {
    const { messages, currentUser, chat, members } = this.props;
    const { prevLatestMessage } = this.state;

    return messages.map((m, idx) => {
      const nextMessage = messages[idx + 1];
      const nextMessageUserId = get(nextMessage, 'userId');

      const currentMessageUserId = get(m, 'userId');
      const isFromCurrentUser = currentUser._id === currentMessageUserId;
      const isFromInfluencer = chat.influencer.id === currentMessageUserId;

      const isCurrentUserAnInfluencer = currentUser.role === 'influencer';

      const isNextMessageFromSameUser =
        !!nextMessageUserId && nextMessageUserId === currentMessageUserId;

      const user = !isFromCurrentUser
        ? find(members, member => member.userId === currentMessageUserId)
        : undefined;

      const bubbleClassNames = classNames('margin-top-Hx', {
        'margin-bottom-3x': !isNextMessageFromSameUser
      });

      return (
        <ChatBubble
          id={`message${m.id}`}
          key={m.id}
          className={bubbleClassNames}
          position={isFromCurrentUser ? 'right' : 'left'}
          text={m.text}
          attachments={m.attachments}
          date={m.createdAt}
          showTail={!isNextMessageFromSameUser}
          style={{ padding: '1rem 1.5rem' }}
          user={user}
          username={this.getUsername(chat, m)}
          showEmail={
            !isCurrentUserAnInfluencer &&
            !isFromCurrentUser &&
            !isFromInfluencer
          }
          animate={!!prevLatestMessage && m.index > prevLatestMessage.index}
        />
      );
    });
  };

  renderChatAcceptationDisclaimer = () => {
    const { chat, currentUser, acceptationRequest, rejectionRequest } =
      this.props;
    const { latestMessage } = this.state;
    const latestMessageWasWrittenByCurrentUser =
      latestMessage.userId === currentUser._id;
    const shouldShowDisclaimer =
      chat.member.chatStatus === 'pending' &&
      !latestMessageWasWrittenByCurrentUser;

    if (!shouldShowDisclaimer) return <noscript />;

    const isChatWithBrand = chat.influencer.id === currentUser._id;
    const isLoading =
      acceptationRequest.status === 'loading' ||
      rejectionRequest.status === 'loading';

    return (
      <div className='flex center-xs width100 padding-vertical-1x'>
        <div className='col middle-xs center-xs' style={{ width: '70%' }}>
          <h2 className='vf-font-bold'>
            {isChatWithBrand
              ? i18n.get('CHAT_WITH_BRAND_ACCEPTATION', {
                  brandName: chat.brand.name
                })
              : i18n.get('CHAT_WITH_INFLUENCER_ACCEPTATION', {
                  userName: chat.influecer.name
                })}
          </h2>
          <div className='flex center-xs margin-top-1x'>
            <Button
              color='flat'
              className='vf-text-danger vf-font-bold'
              onClick={this.onReject}
              disabled={isLoading}
            >
              {i18n.get('REJECT')}
            </Button>
            <Button
              color='flat'
              className='vf-text-primary vf-font-bold'
              onClick={this.onAccept}
              disabled={isLoading}
            >
              {i18n.get('ACCEPT')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderErrors = () => {
    const hasErrors = !isEmpty(this.props.errors);
    if (!hasErrors) return <noscript />;

    return (
      <div className='width100 col center-xs padding-1X'>
        {this.props.errors.map(err => (
          <h3 key={err} className='vf-text-danger'>
            <i
              className='vf-icon icon-exclamation margin-right-Hx'
              style={{ marginTop: '1px' }}
            />
            {err}
          </h3>
        ))}
      </div>
    );
  };

  render = () => {
    const { messages, isLoading, style, chat } = this.props;
    const hasMessages = !isEmpty(messages);
    const height = get(style, 'height');

    if (isLoading && !hasMessages) return <LoadingState height={height} />;
    if (!hasMessages) return <EmptyState height={height} />;

    const hasPreviousMessages = messages.length < chat.totalMessages;

    const containerStyle = {
      ...style
    };

    return (
      <div
        ref={r => (this.container = r)}
        className='width100 vf-scrolly vf-bg-white-color height100'
        style={containerStyle}
      >
        <div className='col end-xs' style={{ minHeight: '100%' }}>
          {hasPreviousMessages && (
            <div
              className='flex center-xs width100 margin-top-1x'
              style={{ fontSize: '1.375rem' }}
            >
              {!isLoading && (
                <a onClick={this.props.onFetchMessages}>
                  {i18n.get('CHAT_LOAD_PREVIOUS_MESSAGES')}
                </a>
              )}

              {isLoading && (
                <span className='vf-text-gray'>{i18n.get('LOADING')}</span>
              )}
            </div>
          )}

          <div className='padding-Hx'>{this.renderBubbles()}</div>

          {this.renderChatAcceptationDisclaimer()}
          {this.renderErrors()}
        </div>
      </div>
    );
  };
}

function EmptyState({ height }) {
  return (
    <div
      className='width100 flex center-xs middle-xs vf-bg-gray-light-color vf-text-gray'
      style={{ height }}
    >
      {i18n.get('CHAT_NO_MESSAGES')}
    </div>
  );
}

EmptyState.propTypes = {
  height: PropTypes.string
};

EmptyState.defaultProps = {
  height: '100%'
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversation);
