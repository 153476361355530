"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const types_1 = require("modules/shared/types");
const getAudienceAuthenticityLevelByValue_1 = require("utils/getAudienceAuthenticityLevelByValue");
const AudienceAuthenticityUI_1 = require("./AudienceAuthenticityUI");
class AudienceAuthenticity extends React.PureComponent {
    constructor(props) {
        super(props);
        this.getLevels = () => {
            return [
                {
                    description: i18n.get('ADV_INFLUENCER_SEARCH_AUTHENTICITY_EXCELLENT_TOOLTIP'),
                    name: i18n.get('ADV_INFLUENCER_PROFILE_AUTHENTICITY_EXCELLENT'),
                    value: types_1.AudienceAuthenticityLevel.EXCELLENT
                },
                {
                    description: i18n.get('ADV_INFLUENCER_SEARCH_AUTHENTICITY_GOOD_TOOLTIP'),
                    name: i18n.get('ADV_INFLUENCER_PROFILE_AUTHENTICITY_GOOD'),
                    value: types_1.AudienceAuthenticityLevel.GOOD
                },
                {
                    description: i18n.get('ADV_INFLUENCER_SEARCH_AUTHENTICITY_REGULAR_TOOLTIP'),
                    name: i18n.get('ADV_INFLUENCER_PROFILE_AUTHENTICITY_REGULAR'),
                    value: types_1.AudienceAuthenticityLevel.REGULAR
                },
                {
                    description: i18n.get('ADV_INFLUENCER_SEARCH_AUTHENTICITY_BAD_TOOLTIP'),
                    name: i18n.get('ADV_INFLUENCER_PROFILE_AUTHENTICITY_BAD'),
                    value: types_1.AudienceAuthenticityLevel.BAD
                }
            ];
        };
        this.getRanges = () => {
            return {
                [types_1.AudienceAuthenticityLevel.EXCELLENT]: { min: 75, max: 100 },
                [types_1.AudienceAuthenticityLevel.GOOD]: { min: 50, max: 74.99 },
                [types_1.AudienceAuthenticityLevel.REGULAR]: { min: 25, max: 49.99 },
                [types_1.AudienceAuthenticityLevel.BAD]: { min: 0, max: 24.99 }
            };
        };
        this.handleChange = (level) => {
            const { onChange } = this.props;
            onChange(this.ranges[level]);
        };
        this.levels = this.getLevels();
        this.ranges = this.getRanges();
    }
    render() {
        const { min } = this.props;
        return (React.createElement(AudienceAuthenticityUI_1.default, { levels: this.levels, onChange: this.handleChange, selectedLevel: getAudienceAuthenticityLevelByValue_1.default(min) }));
    }
}
exports.default = AudienceAuthenticity;
