/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { Link } from 'react-router-dom';
import { Form, Button } from 'ui-library';

import i18n from 'i18n';
import emailRegex from 'utils/emailRegex';
import passwordRegex from 'utils/passwordRegex';
import { CardForm } from 'modules/shared/components';
import { RolesSelect, RoleFormInputs } from './components';

class RoleForm extends React.Component {
  state = {
    advErrMessage: '',
    infErrMessage: '',
    showPassword: false,
    showConfirmPassword: false,
    name: { value: '', hasError: false },
    password: { value: '', hasError: false },
    lastName: { value: '', hasError: false },
    confirmPassword: { value: '', hasError: false },
    email: { value: get(this.props.query, 'email', ''), hasError: false }
  };

  isFormValid = () => {
    const { email, password, confirmPassword } = this.state;

    const state = {};

    if (
      !passwordRegex.test(confirmPassword.value) ||
      confirmPassword.value !== password.value
    ) {
      state.confirmPassword = { value: confirmPassword.value, hasError: true };
      state.advErrMessage = i18n.get('REGISTER_PASSWORD_DO_NOT_MATCH');
      state.infErrMessage = i18n.get('REGISTER_PASSWORD_DO_NOT_MATCH');
    }

    if (!passwordRegex.test(password.value)) {
      state.password = { value: password.value, hasError: true };
      state.advErrMessage = i18n.get('REGISTER_INVALID_PASSWORD_LENGTH');
      state.infErrMessage = i18n.get('REGISTER_INVALID_PASSWORD_LENGTH');
    }

    if (!emailRegex.test(email.value)) {
      state.email = { value: email.value, hasError: true };
      state.advErrMessage = i18n.get('REGISTER_TYPE_A_VALID_EMAIL');
      state.infErrMessage = i18n.get('REGISTER_TYPE_A_VALID_EMAIL');
    }

    if (isEmpty(state)) return true;

    this.setState(state);

    return false;
  };

  handleStateInputChange = (event, key) => {
    const { value } = event.target;

    this.setState({
      advErrMessage: '',
      infErrMessage: '',
      [key]: { value, hasError: false }
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { onSubmit } = this.props;
    const { email, password, name, lastName } = this.state;

    if (!this.isFormValid()) return;

    const form = {
      name: name.value,
      email: email.value,
      password: password.value,
      lastName: lastName.value
    };

    onSubmit(form);
  };

  onShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  onShowConfirmPassword = () => {
    const { showConfirmPassword } = this.state;
    this.setState({ showConfirmPassword: !showConfirmPassword });
  };

  render() {
    const { role, onChangeRole } = this.props;

    const {
      email,
      password,
      showPassword,
      infErrMessage,
      advErrMessage,
      confirmPassword,
      showConfirmPassword
    } = this.state;

    if (!role) {
      return <RolesSelect onChangeRole={onChangeRole} />;
    }

    const validInf = role === 'influencer';

    return (
      <CardForm
        title={i18n.get('REGISTER_WELCOME')}
        subtitle={i18n.get('REGISTER_EMAIL_AND_PASSWORD')}
      >
        <Form autoComplete='off' onSubmit={this.handleSubmit}>
          <div className='form'>
            <RoleFormInputs
              email={email}
              password={password}
              showPassword={showPassword}
              confirmPassword={confirmPassword}
              onShowPassword={this.onShowPassword}
              showConfirmPassword={showConfirmPassword}
              onInputChange={this.handleStateInputChange}
              onShowConfirmPassword={this.onShowConfirmPassword}
            />

            <div className='vf-text-danger margin-top-1x'>
              {validInf ? infErrMessage : advErrMessage}
            </div>

            <div
              id='recaptcha'
              ref='recaptcha'
              className='recaptcha-container'
            />

            <div className='terms'>{i18n.getHTML('REGISTER_ACCEPT_TOS')}</div>

            <Button
              type='submit'
              color='primary'
              className='width100 button-success'
            >
              {i18n.get('REGISTER_REGISTER_ME')}
            </Button>

            <div className='have-account'>
              {i18n.get('REGISTER_YOU_HAVE_AN_ACCOUNT')}
              <Link to='/login'>{i18n.get('REGISTER_LOG_IN')}</Link>
            </div>
          </div>
        </Form>
      </CardForm>
    );
  }
}

RoleForm.propTypes = {
  role: PropTypes.string,
  query: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired
};

RoleForm.defaultProps = {
  role: null
};

export default RoleForm;
