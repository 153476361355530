import CONFIG from 'config/app';

window.fbAsyncInit = function fbAsyncInit() {
  FB.init({
    appId: CONFIG.facebookAppId,
    xfbml: true,
    version: 'v3.3'
  });
};

export default function configureFacebookIntegration(content) {
  const fjs = content.getElementsByTagName('script')[0];
  if (content.getElementById('facebook-jssdk')) return;

  const js = content.createElement('script');

  js.id = 'facebook-jssdk';
  js.src = '//connect.facebook.net/en_US/sdk.js';
  fjs.parentNode.insertBefore(js, fjs);
}
