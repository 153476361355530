import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { NavLink } from 'react-router-dom';
import { Icon } from 'ui-library';
import qs from 'query-string';

const Actions = {
  Nav: require('../actions/nav')
};

const NavigationTab = createClass({
  displayName: 'CustomTabComponent',

  propTypes: {
    linkTo: PropTypes.string,
    icon: PropTypes.string,
    badge: PropTypes.number,
    query: PropTypes.object,
    label: PropTypes.string.isRequired
  },

  getDefaultProps() {
    return {
      badge: 0
    };
  },

  clickedTab() {
    Actions.Nav.changeView();
  },

  render() {
    const { icon, linkTo, label, badge, query, history, ...other } = this.props;
    const badgeElement = <span className='vf-tab-badge'>{badge}</span>;

    return (
      <NavLink
        to={{
          pathname: linkTo,
          search: qs.stringify(query)
        }}
        onClick={this.clickedTab}
        className='vf-tab vf-tab-secondary'
        activeClassName='active'
        {...other}
      >
        <Icon icon={icon} />
        {badge !== 0 && badgeElement}

        <label>{label}</label>
      </NavLink>
    );
  }
});

export default NavigationTab;
