import axios from 'axios';
import {isNumber} from 'lodash';

export function fetchScoreDetailsBySocialAccountId({id, limit, skip}) {
  const params = {};
  if (isNumber(limit)) params.limit = limit;
  if (isNumber(skip)) params.skip = skip;

  return axios.get(`/social-network-accounts/${id}/score-details`, {params})
    .then(res => res.data);
}
