"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ReactDayPicker = require("react-day-picker");
const moment = require("moment");
const datePickerTranslation_1 = require("utils/datePickerTranslation");
const DayPicker = props => {
    const dayPicker = React.useRef(null);
    const { value, toRange, disableDaysAfter, disableDaysBefore } = props;
    const onDayClick = (day, { selected, disabled }, _) => {
        const { onChange } = props;
        if (selected || disabled)
            return;
        if (onChange)
            onChange(day);
    };
    React.useEffect(() => {
        if (!props.value && props.defaultMonth)
            return dayPicker.current.showMonth(props.defaultMonth);
        if (props.value)
            return dayPicker.current.showMonth(props.value);
    }, [props]);
    const modifiers = value
        ? {
            active: originDay => moment(originDay).isSame(value, 'day'),
            range: originDay => {
                if (!toRange)
                    return false;
                const originTime = originDay.getTime();
                const startTime = moment
                    .min(moment(value), moment(toRange))
                    .toDate()
                    .getTime();
                const endTime = moment
                    .max(moment(value), moment(toRange))
                    .toDate()
                    .getTime();
                return startTime <= originTime && endTime >= originDay;
            }
        }
        : null;
    return (React.createElement(ReactDayPicker, { enableOutsideDays: true, ref: dayPicker, modifiers: modifiers, onDayClick: onDayClick, localeUtils: datePickerTranslation_1.default, disabledDays: d => {
            if (!disableDaysAfter && !disableDaysBefore)
                return false;
            if (disableDaysAfter)
                return moment(d).isAfter(disableDaysAfter, 'day');
            if (disableDaysBefore)
                return moment(d).isBefore(disableDaysBefore, 'day');
        } }));
};
exports.default = DayPicker;
