"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const i18n = require("i18n");
const components_1 = require("modules/shared/components/");
const SocialAvatar_1 = require("apps/shared/components/SocialAvatar/SocialAvatar");
const SocialAvatarLeg = props => {
    const { socialAccount = {}, size = 'sm', className = '', children, showRealiability, publicationTypes } = props;
    const sizes = {
        sm: { size: 40, badgeSize: 13 },
        md: { size: 80, badgeSize: 14 },
        lg: { size: 91, badgeSize: 15 }
    };
    const rating = socialAccount.rating || 0;
    const totalSuccessfulParticipations = socialAccount.totalSuccessfulParticipations || 0;
    const classes = classnames_1.default('vf-social-avatar', className);
    return (React.createElement("div", { className: classes },
        React.createElement("div", { className: 'flex' },
            React.createElement("div", { className: 'avatar-container' },
                React.createElement(SocialAvatar_1.default, Object.assign({ socialAccount: socialAccount, publicationTypes: publicationTypes }, sizes[size]))),
            React.createElement("div", { style: { overflow: 'hidden' } },
                children,
                showRealiability && (React.createElement("div", { className: 'flex middle-xs align-center margin-left-1x' },
                    React.createElement(components_1.RateSelector, { transparent: true, readOnly: true, rate: Math.round(rating), type: 'gold', style: {
                            width: '10rem',
                            padding: '0 1rem 0 0',
                            height: '2rem'
                        } }),
                    React.createElement("div", { style: { height: '2rem', marginTop: '-4px' }, title: i18n.get('ADV_POST_LIST_SOCIAL_AVATAR_RATE') },
                        React.createElement("span", { className: 'vf-font-normal', style: { color: '#FEBE2D' } }, totalSuccessfulParticipations))))))));
};
exports.default = SocialAvatarLeg;
