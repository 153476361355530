import axios from 'axios';

export function getAccount(id) {
  return axios.get(`/accounts/${id}`, {});
}

export function associateUser(id, data) {
  return axios.post(`/accounts/${id}/users`, data);
}

export function getSocialAccounts(id) {
  return axios.get(`/accounts/${id}/social_network_accounts`, {
    params: { removeInstagramPersonal: true }
  });
}

export function linkSocialAccount(id, data) {
  return axios.post(`/accounts/${id}/social-network-accounts/link`, data);
}
