import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ percentage = 0, className = 'vf-bg-primary-color' }) => (
  <div className='vf-bg-white-color' style={{ width: '100%', height: '1rem' }}>
    <div
      className={className}
      style={{
        width: `${percentage}%`,
        height: '100%',
        position: 'absolute',
        left: 0
      }}
    />
  </div>
);

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  className: PropTypes.string
};

export default ProgressBar;
