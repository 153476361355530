"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const styles = require("./Paginator.less");
const react_paginate_1 = require("react-paginate");
class Paginator extends React.PureComponent {
    render() {
        const { totalPages, currentPage, onPageChange } = this.props;
        if (totalPages < 1)
            return React.createElement("noscript", null);
        return (React.createElement(react_paginate_1.default, { previousLabel: React.createElement(PreviousLabel, null), nextLabel: React.createElement(NextLabel, null), breakLabel: '...', containerClassName: styles.pagination, pageClassName: styles.page, activeClassName: styles.active, disabledClassName: styles['arrow-disabled'], pageCount: totalPages, forcePage: currentPage, marginPagesDisplayed: 1, pageRangeDisplayed: 3, onPageChange: ({ selected }) => onPageChange(selected) }));
    }
}
const NextLabel = () => (React.createElement("div", { className: styles['pagination-arrows'], style: { paddingLeft: 2 } },
    React.createElement("i", { className: 'vf-icon icon-arrow-right' })));
const PreviousLabel = () => (React.createElement("div", { className: styles['pagination-arrows'], style: { paddingRight: 2 } },
    React.createElement("i", { className: 'vf-icon icon-arrow-left' })));
exports.default = Paginator;
