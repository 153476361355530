import axios from 'axios';


export const create = params => axios.post('/organizations', params);

export const getUserOrganizations = ({id, ...params}) => axios.get(`/users/${id}/organizations`, {params});

export const update = ({id, ...params}) => axios.put(`/organizations/${id}`, params);

export const leave = ({id}) => axios.post(`/organizations/${id}/leave`);

export const archive = ({id}) => axios.delete(`/organizations/${id}`);

export const getUsers = ({id, ...params}) => axios.get(`/organizations/${id}/users`, {params});

export const removeUser = ({userId, organizationId, ...data}) => {
  return axios.delete(`/organizations/${organizationId}/users/${userId}`, data);
};

export const changeRole = ({userId, organizationId, ...data}) => {
  return axios
    .put(`/organizations/${organizationId}/users/${userId}/permissions`, data);
};

export const inviteUser = ({id, ...data}) => axios.post(`/organizations/${id}/invite`, data);

export const cancelInvite = ({id, ...data}) => axios.post(`/organizations/${id}/cancel`, data);

export const acceptInvite = ({id, ...data}) => axios.post(`/organizations/${id}/accept`, data);

export const rejectInvite = ({id, ...data}) => axios.post(`/organizations/${id}/reject`, data);

export const getCosts = ({id, ...params}) => axios.get(`/organizations/${id}/monthly-costs`, {params});

export const generateReport = ({id, ...params}) => axios.get(`/organizations/${id}/report-monthly-costs`, {params, responseType: 'arraybuffer'});
