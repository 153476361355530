import { users } from '../../../services/voxfeed';
import { sendRequest, sendRequestSuccess } from '../app';
import { updateCurrentUserInfo } from '../shared';
import { updateData, updateLegacy } from '../entities';
import {
  handleRequestFail,
  handleRequestSuccess,
  buildAPIRequest
} from '../helpers';
import { omit } from 'lodash';

const {
  sendVerificationRequest,
  fetchVerificationRequests,
  approveVerificationRequest,
  rejectVerificationRequest,
  verifyUserEmail
} = users;

const START_VERIFICATION_REQUEST = 'voxfeed/users/START_VERIFICATION_REQUEST';
const SEND_VERIFICATION_REQUEST = 'voxfeed/users/SEND_VERIFICATION_REQUEST';
const SEND_VERIFICATION_SUCCESS = 'voxfeed/users/SEND_VERIFICATION_SUCCESS';
const SEND_VERIFICATION_FAIL = 'voxfeed/users/SEND_VERIFICATION_FAIL';
const APPROVE_VERIFICATION = 'voxfeed/users/APPROVE_VERIFICATION';
const APPROVE_VERIFICATION_SUCCESS =
  'voxfeed/users/APPROVE_VERIFICATION_SUCCESS';
const APPROVE_VERIFICATION_FAIL = 'voxfeed/users/APPROVE_VERIFICATION_FAIL';
const REJECT_VERIFICATION = 'voxfeed/users/REJECT_VERIFICATION';
const REJECT_VERIFICATION_SUCCESS = 'voxfeed/users/REJECT_VERIFICATION_SUCCESS';
const REJECT_VERIFICATION_FAIL = 'voxfeed/users/REJECT_VERIFICATION_FAIL';

const initialState = { currentVerification: {} };

export default function reducer(state = initialState, { type }) {
  switch (type) {
    case START_VERIFICATION_REQUEST:
      return {
        ...state,
        currentVerification: { status: null }
      };
    case SEND_VERIFICATION_REQUEST:
      return {
        ...state,
        currentVerification: { status: 'loading' }
      };
    case SEND_VERIFICATION_SUCCESS:
      return {
        ...state,
        currentVerification: { status: 'success' }
      };
    case SEND_VERIFICATION_FAIL:
      return {
        ...state,
        currentVerification: { status: 'fail' }
      };
    case APPROVE_VERIFICATION:
      return {
        ...state,
        currentVerification: { status: 'loading' }
      };
    case APPROVE_VERIFICATION_SUCCESS:
      return {
        ...state,
        currentVerification: { status: 'success' }
      };
    case APPROVE_VERIFICATION_FAIL:
      return {
        ...state,
        currentVerification: { status: 'fail' }
      };
    case REJECT_VERIFICATION:
      return {
        ...state,
        currentVerification: { status: 'loading' }
      };
    case REJECT_VERIFICATION_SUCCESS:
      return {
        ...state,
        currentVerification: { status: 'success' }
      };
    case REJECT_VERIFICATION_FAIL:
      return {
        ...state,
        currentVerification: { status: 'fail' }
      };
    default:
      return state;
  }
}

export function startCurrentUserVerificationRequest() {
  return { type: START_VERIFICATION_REQUEST };
}

export function sendCurrentUserVerification(verification) {
  return dispatch =>
    sendVerificationRequest(verification)
      .then(() => dispatch(sendCurrentUserVerificationSuccess()))
      .catch(() => dispatch(sendCurrentUserVerificationFail()));
}

export function sendCurrentUserVerificationSuccess() {
  return { type: SEND_VERIFICATION_SUCCESS };
}

export function sendCurrentUserVerificationFail() {
  return { type: SEND_VERIFICATION_FAIL };
}

export function sendUserEmailVerification(params) {
  const REQUEST_ID = 'sendUserEmailVerification';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return verifyUserEmail(params)
      .then(() => handleRequestSuccess({ dispatch, id: REQUEST_ID }))
      .catch(res => {
        handleRequestFail({ dispatch, id: REQUEST_ID, response: res });
        return Promise.reject();
      });
  };
}

export function sendVerificationEmail(params) {
  const REQUEST_ID = 'sendVerificationEmail';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return users
      .sendVerificationEmail(params)
      .then(() => handleRequestSuccess({ dispatch, id: REQUEST_ID }))
      .catch(res => {
        handleRequestFail({ dispatch, id: REQUEST_ID, response: res });
        return Promise.reject();
      });
  };
}

export function fetchUserVerificationRequests(options) {
  return dispatch =>
    fetchVerificationRequests(options).then(({ data }) =>
      dispatch(updateData(data))
    );
}

export function approveUserVerification(verificationId) {
  return dispatch => {
    dispatch(approveUserVerificationRequest());

    return approveVerificationRequest(verificationId)
      .then(({ data }) => {
        dispatch(
          updateData({
            data,
            id: data.id,
            modelName: 'UserVerificationRequest'
          })
        );
        dispatch(approveUserVerificationSuccess());
      })
      .catch(() => dispatch(approveUserVerificationFail()));
  };
}

export function rejectUserVerification(verificationId, feedback) {
  return dispatch => {
    dispatch(rejectUserVerificationRequest());

    return rejectVerificationRequest(verificationId, feedback)
      .then(({ data }) => {
        dispatch(
          updateData({
            data,
            id: data.id,
            modelName: 'UserVerificationRequest'
          })
        );
        dispatch(rejectUserVerificationSuccess());
      })
      .catch(() => dispatch(rejectUserVerificationFail()));
  };
}

export function approveUserVerificationRequest() {
  return { type: APPROVE_VERIFICATION };
}

export function approveUserVerificationSuccess() {
  return { type: APPROVE_VERIFICATION_SUCCESS };
}

export function approveUserVerificationFail() {
  return { type: APPROVE_VERIFICATION_FAIL };
}

export function rejectUserVerificationRequest() {
  return { type: REJECT_VERIFICATION };
}

export function rejectUserVerificationSuccess() {
  return { type: REJECT_VERIFICATION_SUCCESS };
}

export function rejectUserVerificationFail() {
  return { type: REJECT_VERIFICATION_FAIL };
}

export function verifyUserPhone(params) {
  const REQUEST_ID = 'verifyUserPhone';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    users
      .verifyUserPhone(params)
      .then(response => {
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateData(response.data));
      })
      .catch(res =>
        handleRequestFail({ dispatch, id: REQUEST_ID, response: res })
      );
  };
}

export function deleteUser(userId, password) {
  const REQUEST_ID = 'deleteUser';

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    users
      .deleteUser(userId, password)
      .then(() => {
        dispatch(sendRequestSuccess(REQUEST_ID));
      })
      .catch(res =>
        handleRequestFail({ dispatch, id: REQUEST_ID, response: res })
      );
  };
}

/**
 * Send data to update a user without updating the local redux store
 */
export const updateUser = params =>
  buildAPIRequest({
    params,
    id: 'updateUser',
    method: users.updateUser,
    callback: ({ dispatch, data }) => {
      dispatch(updateLegacy(data));
    }
  });

/**
 * Send data to update a user updating the local redux store
 */
export const updateCurrentUser = params =>
  buildAPIRequest({
    params,
    id: 'updateCurrentUser',
    method: users.updateUser,
    callback: ({ dispatch, data }) => {
      dispatch(updateLegacy(data));
      dispatch(updateCurrentUserInfo({ user: data.data }));
    }
  });

export function updateUserPhone(userId, phone) {
  const REQUEST_ID = `updateUserPhone_${userId}`;

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    users
      .updateUser({ id: userId, phone })
      .then(({ data }) => {
        const { data: user } = data;
        dispatch(updateCurrentUserInfo({ user }));

        return users
          .sendPhoneVericationCode({ userId, phone })
          .then(() => handleRequestSuccess({ dispatch, id: REQUEST_ID }));
      })
      .catch(res =>
        handleRequestFail({ dispatch, id: REQUEST_ID, response: res })
      );
  };
}

export function sendPhoneVericationCode(userId, phone) {
  const REQUEST_ID = `sendPhoneVericationCode_${userId}`;

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return users
      .sendPhoneVericationCode({ userId, phone })
      .then(() => handleRequestSuccess({ dispatch, id: REQUEST_ID }))
      .catch(res =>
        handleRequestFail({ dispatch, id: REQUEST_ID, response: res })
      );
  };
}

export function verifyPhone(userId, code) {
  const REQUEST_ID = `verifyUserPhoneCode_${userId}`;

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return users
      .verifyPhone({ userId, code })
      .then(({ data }) => {
        const { data: user } = data;
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateCurrentUserInfo({ user }));
      })
      .catch(res =>
        handleRequestFail({ dispatch, id: REQUEST_ID, response: res })
      );
  };
}

export function acceptPrivacyPolicy(userId) {
  const REQUEST_ID = `acceptPrivacyPolicy${userId}`;

  return dispatch => {
    dispatch(sendRequest(REQUEST_ID));

    return users
      .acceptPrivacyPolicy({ userId })
      .then(({ data }) => {
        const { data: user } = data;
        dispatch(sendRequestSuccess(REQUEST_ID));
        dispatch(updateCurrentUserInfo({ user }));
      })
      .catch(res =>
        handleRequestFail({ dispatch, id: REQUEST_ID, response: res })
      );
  };
}

export const forgotPassword = data =>
  buildAPIRequest({
    id: 'forgotPassword',
    method: users.forgetPassword,
    params: data
  });

export const setPassword = data =>
  buildAPIRequest({
    id: 'setPassword',
    method: users.setPassword,
    params: data
  });

export const getRegistrationInviteByToken = data =>
  buildAPIRequest({
    id: `getRegistrationInviteByToken_${data.token}`,
    method: users.getRegistrationInviteByToken,
    params: data
  });

export const survey = params =>
  buildAPIRequest({
    id: `survery_${params.id}`,
    method: users.survey,
    params
  });

export const getPayoneerUrl = params =>
  buildAPIRequest({
    id: 'getPayoneerUrl',
    method: users.getPayoneerUrl,
    params,
    callback: ({ dispatch, data }) => {
      dispatch(
        updateData({
          id: params.id,
          data,
          modelName: 'PayoneerInfo'
        })
      );
    }
  });

export const requestPaypalPayment = params =>
  buildAPIRequest({
    params,
    id: 'requestPaypalPayment',
    method: users.requestPaypalPayment
  });

export const requestPayoneerPayment = params =>
  buildAPIRequest({
    params,
    id: 'requestPayoneerPayment',
    method: users.requestPayoneerPayment
  });

export const requestPayment = params =>
  buildAPIRequest({
    params,
    id: 'requestPayment',
    method: users.requestPayment
  });

export const getPaymentsOut = params =>
  buildAPIRequest({
    params: omit(params, 'replace'),
    id: 'getPaymentsOut',
    method: users.getPaymentsOut,
    replace: params.replace
  });

export const getPaymentsOutCurrency = params =>
  buildAPIRequest({
    params: omit(params, 'replace'),
    id: 'getPaymentsOutCurrency',
    method: users.getPaymentsOutCurrency,
    replace: params.replace
  });

export const getUsers = params =>
  buildAPIRequest({
    params: omit(params, 'replace'),
    id: 'getUsers',
    method: users.getUsers,
    replace: params.replace
  });

export const getAdmins = params =>
  buildAPIRequest({
    params,
    id: 'getAdmins',
    method: users.getAdmins
  });

export const setPaymentOut = params =>
  buildAPIRequest({
    params,
    id: 'setPaymentOut',
    method: users.setPaymentOut
  });

export const setManualPaymentOut = params =>
  buildAPIRequest({
    params,
    id: 'setManualPaymentOut',
    method: users.setManualPaymentOut
  });

export const cancelPaymentOut = params =>
  buildAPIRequest({
    params,
    id: 'cancelPaymentOut',
    method: users.cancelPaymentOut
  });

export const penalizePaymentOut = params =>
  buildAPIRequest({
    params,
    id: 'penalizePaymentOut',
    method: users.penalizePaymentOut
  });

export const setTipaltiStatus = params =>
  buildAPIRequest({
    params,
    id: 'setTipaltiStatus',
    method: users.setTipaltiStatus
  });

export const getPaymentMethods = params =>
  buildAPIRequest({
    params,
    id: 'getPaymentMethods',
    method: users.getPaymentMethods,
    callback({ data, dispatch }) {
      dispatch(
        updateCurrentUserInfo({
          user: { paymentMethods: data.data }
        })
      );
    }
  });

export const postPaymentMethod = params =>
  buildAPIRequest({
    params,
    id: 'postPaymentMethod',
    method: users.postPaymentMethod
  });

export const postShopifyToken = params =>
  buildAPIRequest({
    params,
    id: 'postShopifyToken',
    method: users.postShopifyToken
  });

export const createAdmin = ({ ignoreCodes, ...params }) =>
  buildAPIRequest({
    params,
    id: 'createAdmin',
    method: users.createAdmin,
    ignoreCodes
  });

export const editAdmin = params =>
  buildAPIRequest({
    params,
    id: 'editAdmin',
    method: users.editAdmin
  });

export const constants = {
  SEND_VERIFICATION_REQUEST,
  SEND_VERIFICATION_SUCCESS,
  SEND_VERIFICATION_FAIL,
  APPROVE_VERIFICATION,
  APPROVE_VERIFICATION_SUCCESS,
  APPROVE_VERIFICATION_FAIL,
  REJECT_VERIFICATION,
  REJECT_VERIFICATION_SUCCESS,
  REJECT_VERIFICATION_FAIL
};
