"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const images_1 = require("assets/images");
const styles = require("../../SocialNetworksFormUI/SocialNetworksFormUI.less");
const types_1 = require("modules/shared/types");
const components_1 = require("apps/shared/components");
const TYPESOCIALNETWORK = 'TypeSocialNetwork';
const UnlinkView = ({ infoUser, unlinkAccount, onCancelUnlink, modal = false, onDelete }) => {
    const networkName = window.localStorage.getItem(TYPESOCIALNETWORK) || '';
    return (React.createElement("div", { className: classnames_1.default(styles.dialog, {
            [styles.dialog_unlinkContainer]: !modal,
            [styles.dialog_unlinkContainer_modal]: modal
        }) },
        React.createElement(components_1.DialogHeader, { className: classnames_1.default(styles.header, {
                [styles.instaback]: networkName === types_1.SocialNetwork.INSTAGRAM,
                [styles.youtubeback]: networkName === types_1.SocialNetwork.YOUTUBE,
                [styles.fbkback]: networkName === types_1.SocialNetwork.FACEBOOK
            }) },
            React.createElement("img", { src: images_1.getSocialNetworkIcon(networkName), alt: `${networkName} icon`, className: styles.headerIcon })),
        React.createElement("div", { className: styles.dialogContainer },
            React.createElement("div", { className: styles.unlinkContainer },
                React.createElement("div", { className: styles.unlinkTitle }, i18n.get('INF_LITE_ACCOUNTS_UNlINK_TITLE')),
                React.createElement("div", { className: styles.unlinkName },
                    " ",
                    infoUser.username),
                React.createElement("div", { className: styles.unlinkWarning }, i18n.get('INF_LITE_ACCOUNTS_UNlINK_WARNING'))),
            React.createElement("div", { className: classnames_1.default(styles.buttonContainer, {
                    [styles.buttonContainer_unlink]: unlinkAccount
                }) },
                React.createElement(ui_library_1.Button, { color: 'flat', className: styles.buttonCancel, onClick: () => onCancelUnlink() }, i18n.get('INF_LITE_ACCOUNTS_CANCEL_BUTTON')),
                React.createElement(ui_library_1.Button, { color: 'primary', className: styles.button, onClick: () => onDelete() }, i18n.get('INF_LITE_ACCOUNTS_ACCEPT_BUTTON'))))));
};
exports.default = UnlinkView;
