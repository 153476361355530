"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("apps/shared/components");
const styles = require("./connectionDialogUI.less");
const images_1 = require("assets/images");
const ui_library_1 = require("ui-library");
const React = require("react");
const i18n = require("i18n");
const TiktokModalUI = ({ onClose, onInputchange, username, accountLink, onConnect, errors }) => {
    const { username: usernameError, link: linkError } = errors;
    const buttonDisabled = !username || !accountLink || !!usernameError || !!linkError;
    return (React.createElement("div", { className: styles.dialog },
        React.createElement(components_1.DialogHeader, { onClose: onClose },
            React.createElement("img", { src: images_1.tiktokIcon, alt: 'tiktok icon', className: styles.headerIcon }),
            React.createElement("h2", { className: 'vf-normal margin-left-1x', style: { fontSize: '3rem' } }, i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_LINK_TITTLE'))),
        React.createElement("div", { className: styles.dialogContainer },
            React.createElement("span", { className: 'vf-text-gray' },
                i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_INSTRUCTIONS'),
                "\u00A0",
                React.createElement("a", { href: i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_INSTRUCTIONS_LINK'), target: '_blank', rel: 'noreferrer' }, i18n.get('INF_CAMPAIGNS_ACCOUNTS_UNABLE_TO_PARTICIPATE_HELP_LINK'))),
            React.createElement("div", { className: 'vf-simple-form-group col-md-12' },
                React.createElement("span", { className: styles.formLabel }, i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_LINK_USERNAME')),
                React.createElement(components_1.FormInput, { id: 'name-input', name: 'username', autoComplete: 'off', hasError: usernameError, onChange: onInputchange, placeholder: i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_WRITE_USERNAME'), value: username, disabled: false }),
                React.createElement("span", { className: styles.errorContainer }, usernameError && (React.createElement("p", { className: styles.errorLabel }, usernameError)))),
            React.createElement("div", { className: 'vf-simple-form-group col-md-12' },
                React.createElement("span", { className: styles.formLabel }, i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_LINK_URL')),
                React.createElement(components_1.FormInput, { id: 'name-input', name: 'link', autoComplete: 'off', hasError: linkError, onChange: onInputchange, placeholder: i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_LINK_URL_PASTE'), value: accountLink, disabled: false }),
                React.createElement("span", { className: styles.errorContainer }, linkError && React.createElement("p", { className: styles.errorLabel }, linkError))),
            React.createElement("div", { className: 'vf-simple-form-group col-md-12' },
                React.createElement(ui_library_1.Button, { color: 'primary', disabled: buttonDisabled, className: styles.button, onClick: onConnect }, i18n.get('INF_SOCIAL_ACCOUNTS_TIKTOK_LINK_BUTTON'))))));
};
exports.default = TiktokModalUI;
