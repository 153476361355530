"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const i18n = require("i18n");
const classnames_1 = require("classnames");
const components_1 = require("apps/shared/components");
const styles = require("./LimitationList.less");
function LimitationsList() {
    const title = React.createElement("h3", null, i18n.get('INF_SOCIAL_ACCOUNTS_ADVICE_HEADER'));
    return (React.createElement("div", { className: 'margin-top-1x col-xs-12 col-sm-12' },
        React.createElement("div", { className: classnames_1.default('vf-scrolly', styles.accordions) },
            React.createElement(components_1.Accordion, { title: title, isCollapsed: true },
                React.createElement(SocialAccountAdvices, null)))));
}
exports.default = LimitationsList;
const SocialAccountAdvices = () => (React.createElement("div", null,
    React.createElement("ul", { className: styles.capabilitiesTable },
        React.createElement("li", { className: 'social-account-info-row' }, i18n.get('INF_SOCIAL_ACCOUNTS_ADVICE_CAN_1')),
        React.createElement("li", null, i18n.get('INF_SOCIAL_ACCOUNTS_ADVICE_CAN_2')),
        React.createElement("li", null, i18n.get('INF_SOCIAL_ACCOUNTS_ADVICE_CAN_3')),
        React.createElement("li", null, i18n.get('INF_SOCIAL_ACCOUNTS_ADVICE_CAN_4')),
        React.createElement("li", null, i18n.get('INF_SOCIAL_ACCOUNTS_ADVICE_CAN_5'))),
    React.createElement("ul", { className: styles.limitationsTable },
        React.createElement("li", null, i18n.get('INF_SOCIAL_ACCOUNTS_ADVICE_CAN_NOT_1')),
        React.createElement("li", null, i18n.get('INF_SOCIAL_ACCOUNTS_ADVICE_CAN_NOT_2')),
        React.createElement("li", null, i18n.get('INF_SOCIAL_ACCOUNTS_ADVICE_CAN_NOT_3')),
        React.createElement("li", null, i18n.get('INF_SOCIAL_ACCOUNTS_ADVICE_CAN_NOT_4')))));
