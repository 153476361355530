const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const EMAIL_REGEX = /^[\w-\.-\+]+@([\w-]+\.)+[\w-]{2,4}$/
const YOUTUBE_PATTERN = 'www.youtube.com';

export const validURL = (toCheck) => {
    const regex = new RegExp(URL_REGEX);
    if(typeof toCheck === 'string'){
        return toCheck.match(regex);
    }
    return false;
}

export const isYoutubeUrl = (toCheck) => {
    const inUrlPosition = toCheck.indexOf(YOUTUBE_PATTERN); 
    return inUrlPosition !== -1 ;
}

export const isValidEmail = (toCheck) => {
    const regex = new RegExp(EMAIL_REGEX);
    if(typeof toCheck === 'string'){
        return toCheck.match(regex);
    }
    return false;
}
