import React from 'react';

import Tour from 'modules/shared/tours/BaseTour';
import {
  BrandsSection,
  InfluencersSection,
  HelpSection,
  ProfileSection,
  NewBrandButton,
  ChatGadget
} from './components';

const COOKIE_NAME = 'vf_tour_advertiser_landing';
const DEFAULT_PATH = '/brands/:brandId/campaigns';
const STEPS = [
  {
    selector: '[data-tour-adv-landing="1"]',
    position: 'below',
    className: 'margin-top-1x',
    component: BrandsSection
  },
  {
    selector: '[data-tour-adv-landing="2"]',
    position: 'below',
    className: 'margin-top-1x',
    component: InfluencersSection
  },
  {
    selector: '[data-tour-adv-landing="3"]',
    position: 'left-below',
    className: 'margin-right-3x',
    component: HelpSection
  },
  {
    selector: '[data-tour-adv-landing="4"]',
    position: 'left-below',
    className: 'margin-right-3x',
    component: ProfileSection
  },
  {
    selector: '[data-tour-adv-landing="5"]',
    position: 'left-below',
    component: ChatGadget
  },
  {
    selector: '[data-tour-adv-landing="6"]',
    position: 'left-below',
    component: NewBrandButton
  }
];

const AdvertiserLandingTour = props => (
  <Tour cookieName={COOKIE_NAME} steps={STEPS} path={DEFAULT_PATH} {...props} />
);

export default AdvertiserLandingTour;
