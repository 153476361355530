import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import i18n from 'i18n';
import { deleteUser } from 'redux/modules/users';
import { showAlert, resetRequest } from 'redux/modules/app';
import { logout } from 'redux/modules/shared';
import DeleteOptions from 'modules/accounts/shared/components/DeleteOptions';

const DELETE_USER_REQUEST = 'deleteUser';
const INFLUENCER_ROLE = 'influencer';

function mapStateToProps({ shared, entities, app }) {
  const { Account: accounts } = entities;
  const { user } = shared;
  const { requests } = app;
  const { systemSettings } = app;
  const { currency } = systemSettings;

  return { accounts, user, requests, currency };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { deleteUser, showAlert, logout, resetRequest },
    dispatch
  );
}

const DeleteInfluencerUser = createClass({
  propTypes: {
    user: PropTypes.object,
    accounts: PropTypes.object,
    account: PropTypes.object,
    password: PropTypes.string,
    deleteUser: PropTypes.func,
    requests: PropTypes.object,
    currency: PropTypes.object,
    onCancelDelete: PropTypes.func
  },

  getInitialState() {
    return {
      hoverCancel: false
    };
  },

  UNSAFE_componentWillReceiveProps(newProps) {
    this.handleResponse(newProps);
  },

  handleResponse(newProps) {
    const { requests = {} } = newProps;
    const { showAlert, logout } = this.props;

    const requestStatus = get(requests[DELETE_USER_REQUEST], 'status');
    const requestError = get(requests[DELETE_USER_REQUEST], 'response');

    if (requestStatus === 'success') {
      showAlert({
        type: 'success',
        message: i18n.get('INF_SETTINGS_ACCOUNT_DELETE_DELETE_SUCCESS_MESSAGE')
      });
      resetRequest(DELETE_USER_REQUEST);
      logout();
    }
    if (requestStatus === 'fail') {
      showAlert({ type: 'error', message: requestError.message });
      resetRequest(DELETE_USER_REQUEST);
    }
  },

  onHoverCancel() {
    this.setState({ hoverCancel: true });
  },

  onBlurCancel() {
    this.setState({ hoverCancel: false });
  },

  onDeleteUser() {
    const { deleteUser: deleteUserRedux } = this.props;
    const { user } = this.props;
    const { _id: userId } = user;
    const { password } = this.state;

    deleteUserRedux(userId, password);
  },

  onPasswordChange(evt) {
    const password = evt.target.value;
    this.setState({ password });
  },

  render() {
    const { user = {}, account, onCancelDelete, currency } = this.props;
    const { hoverCancel } = this.state;
    const faceIcon = hoverCancel ? (
      <div className='vf-icon vf-face icon-smile-o' />
    ) : (
      <div className='vf-icon vf-face icon-frown-o' />
    );
    const currencySuffix = get(currency, 'suffix', '');
    const accountBalance = i18n.getMoney({
      amount: account.balance || 0.0,
      currency: currencySuffix
    });

    return (
      <div>
        <div className='vf-row padding-top-2x'>
          <div className='col-xs-12'>
            <h2>{i18n.get('INF_SETTINGS_ACCOUNT_DELETE_ACCOUNT_HEADER')}</h2>
          </div>
        </div>
        <div className='vf-row padding-top-2x'>
          <div className='col-xs-12 show-xs padding-top-2x' />
          <div className='col-xs-12 col-sm-9 col-lg-offset-3 col-lg-6'>
            <DeleteOptions
              faceIcon={faceIcon}
              userName={user.name}
              role={INFLUENCER_ROLE}
              onCancelDelete={onCancelDelete}
              accountBalance={accountBalance}
              onBlurCancel={this.onBlurCancel}
              onDeleteUser={this.onDeleteUser}
              onHoverCancel={this.onHoverCancel}
              onPasswordChange={this.onPasswordChange}
            />
          </div>
        </div>
      </div>
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteInfluencerUser);
