import React from 'react';
import Reflux from 'reflux';
import qs from 'query-string';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createClass from 'create-react-class';
import { compose, bindActionCreators } from 'redux';
import { Form, InputWrapper, Button } from 'ui-library';

import i18n from 'i18n';
import API from 'modules/legacy/js/lib/api';
import passwordRegex from 'utils/passwordRegex';
import recaptcha from 'apps/shared/hocs/recaptcha';
import { loginRequest } from 'redux/modules/shared';
import AppActions from 'modules/legacy/js/actions/app';
import AuthActions from 'modules/legacy/js/actions/auth';
import PartnerFormRoute from 'apps/advertisers/PartnerForm';
import { changePage, languageChange } from 'redux/modules/app';
import { DebounceInput, FormLabel } from 'apps/shared/components';
import { HeroSections, CardForm } from 'modules/shared/components';

const PasswordReset = createClass({
  mixins: [
    Reflux.listenTo(AuthActions.registerError, 'showError'),
    Reflux.listenTo(AppActions.reCAPTCHALoaded, 'updateReCaptchaWidget')
  ],

  getInitialState() {
    return {
      password: '',
      errorMessage: '',
      showPassword: false,
      confirmPassword: '',
      errorPassword: false,
      showConfirmPassword: false,
      errorConfirmPassword: false
    };
  },

  componentDidMount() {
    const { user, location } = this.props;
    const query = qs.parse(location.search);

    if (!query.token) AuthActions.signout();
    if (user) this.loginUser();

    AppActions.hideLoader();
  },

  UNSAFE_componentWillReceiveProps(props) {
    if (props.user) return this.loginUser(props);
    return null;
  },

  onSuccessfulPasswordRecovery() {
    AppActions.displayAlert({
      type: 'success',
      title: i18n.get('DEFAULT_SUCCESS_TITLE'),
      message: i18n.get(
        'RESET_PASSWORD_PASSWORD_RESET_SUCCESS_ALERT_DESCRIPTION'
      )
    });
  },

  onSendData(event) {
    event.preventDefault();

    const { password } = this.state;
    const { loginRequest: loginRequestAction, location } = this.props;
    const query = qs.parse(location.search);

    if (!this.validateForm() && !!event) return event.stopPropagation();

    const params = { password, token: query.token };

    return API.setRecoverPassword(params, (err, res) => {
      const error = err ? res : null;
      if (error) return this.showError(error);

      loginRequestAction(res);
      return this.onSuccessfulPasswordRecovery();
    });
  },

  showError({ message }) {
    AppActions.displayAlert({ type: 'error', message });
    this.props.updateReCaptchaWidget();
  },

  loginUser(props = this.props) {
    const { session, user } = props;

    AuthActions.signinSuccess({
      user,
      token: session.token,
      account: user.account
    });
  },

  togglePassword() {
    const { showPassword } = this.state;
    const inputType = showPassword ? 'password' : 'text';

    this.setState({ showPassword, passwordInputType: inputType });
  },

  validateForm() {
    let valid = true;
    let errorFocused = false;
    const { password, confirmPassword } = this.state;

    const addErrorFn = el => {
      valid = false;
      el.parentNode.classList.add('error');

      if (errorFocused === false) {
        el.focus();
        errorFocused = true;
      }
    };

    if (!passwordRegex.test(password)) {
      addErrorFn(ReactDOM.findDOMNode(this.refs.passwordInput));

      this.setState({
        errorPassword: true,
        errorMessage: i18n.get('RESET_PASSWORD_INVALID_LENGTH')
      });

      return valid;
    }

    if (!passwordRegex.test(confirmPassword)) {
      addErrorFn(ReactDOM.findDOMNode(this.refs.confirmPasswordInput));

      this.setState({
        errorConfirmPassword: true,
        errorMessage: i18n.get('RESET_PASSWORD_INVALID_LENGTH')
      });

      return valid;
    }

    if (password !== confirmPassword) {
      addErrorFn(ReactDOM.findDOMNode(this.refs.passwordInput));
      addErrorFn(ReactDOM.findDOMNode(this.refs.confirmPasswordInput));

      this.setState({
        errorPassword: true,
        errorConfirmPassword: true,
        errorMessage: i18n.get('RESET_PASSWORD_PASSWORDS_MISMATCH')
      });

      return valid;
    }

    return valid;
  },

  handleStateInputChange(event, key) {
    event.target.parentNode.classList.remove('error');

    this.setState({
      errorMessage: '',
      errorPassword: false,
      [key]: event.target.value,
      errorConfirmPassword: false
    });
  },

  render() {
    const { partnerSettings } = this.props;

    const {
      showPassword,
      errorMessage,
      errorPassword,
      showConfirmPassword,
      errorConfirmPassword
    } = this.state;

    if (partnerSettings) return <PartnerFormRoute />;

    return (
      <HeroSections
        buttonPadding
        linkTo='/login'
        buttonText={i18n.get('RESET_PASSWORD_LOG_IN')}
        title={i18n.getHTML('RESET_PASSWORD_RESET_YOUR_PASSWORD')}
      >
        <CardForm subtitle={i18n.get('RESET_PASSWORD_ENTER_YOUR_NEW_PASSWORD')}>
          <div className='form'>
            <Form onSubmit={this.onSendData}>
              <div className='vf-input-group'>
                <FormLabel htmlFor='new-password'>
                  {i18n.get('RESET_PASSWORD_CHOOSE_PASSWORD')}
                </FormLabel>

                <InputWrapper
                  ref='passwordWrapper'
                  className='lg margin-bottom-2x'
                >
                  <i className='vf-icon icon-locked' />

                  <DebounceInput
                    ref='passwordInput'
                    className='login-input'
                    hasError={errorPassword}
                    data-uitest='new-password'
                    type={showPassword ? 'text' : 'password'}
                    placeholder={i18n.get(
                      'RESET_PASSWORD_CHOOSE_PASSWORD_PLACEHOLDER'
                    )}
                    onChange={e => this.handleStateInputChange(e, 'password')}
                  />
                  <i
                    className={`show-password ${
                      showPassword ? 'icon-eye-disabled' : 'icon-eye-1'
                    } show-password`}
                    onClick={() =>
                      this.setState({ showPassword: !showPassword })
                    }
                  />
                </InputWrapper>

                <FormLabel htmlFor='confirm-password'>
                  {i18n.get('RESET_PASSWORD_CONFIRM_PASSWORD')}
                </FormLabel>

                <InputWrapper ref='confirmPasswordWrapper' className='lg'>
                  <i className='vf-icon icon-locked' />

                  <DebounceInput
                    className='login-input'
                    ref='confirmPasswordInput'
                    data-uitest='confirm-password'
                    hasError={errorConfirmPassword}
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder={i18n.get(
                      'RESET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER'
                    )}
                    onChange={e =>
                      this.handleStateInputChange(e, 'confirmPassword')
                    }
                  />
                  <i
                    className={`show-password ${
                      showConfirmPassword ? 'icon-eye-disabled' : 'icon-eye-1'
                    } show-password`}
                    onClick={() =>
                      this.setState({
                        showConfirmPassword: !showConfirmPassword
                      })
                    }
                  />
                </InputWrapper>

                <InputWrapper hidden />
              </div>

              {errorMessage && (
                <div className='margin-top-Hx vf-text-danger'>
                  {errorMessage}
                </div>
              )}

              <div
                id='recaptcha'
                ref='recaptcha'
                className='recaptcha-container'
              />

              <Button
                size='lg'
                type='submit'
                color='primary'
                data-uitest='send-email-button'
                className='width100 button-success margin-top-1x'
              >
                {i18n.get('RESET_PASSWORD_RESET_PASSWORD')}
              </Button>
            </Form>
          </div>
        </CardForm>
      </HeroSections>
    );
  }
});

function mapStateToProps({ app, shared }) {
  const { user, session } = shared;
  const { currentLanguage, partnerSettings } = app;

  return { user, session, currentLanguage, partnerSettings };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { changePage, loginRequest, languageChange },
    dispatch
  );
}

PasswordReset.propTypes = {
  user: PropTypes.object,
  session: PropTypes.object,
  location: PropTypes.object,
  changePage: PropTypes.func,
  loginRequest: PropTypes.func,
  currentLanguage: PropTypes.string.isRequired
};

export default compose(
  recaptcha,
  connect(mapStateToProps, mapDispatchToProps)
)(PasswordReset);
