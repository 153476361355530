const initGtagAnalytics = (trackingId) => {
    var gtagScript = document.createElement('script'); 
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.getElementsByTagName('head')[0].appendChild(gtagScript);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'GA_MEASUREMENT_ID');
  };

  module.exports = initGtagAnalytics;
