export default function ensureChildNodeVisibility(childNode, parentNode) {
  if (!childNode || !parentNode) return;

  const parent = parentNode.getBoundingClientRect();
  const child = childNode.getBoundingClientRect();

  const childTop = child.top - parent.top;
  const childBottom = childTop + child.height;
  const parentHeight = parent.height;

  const isVisible = childTop >= 0 && childBottom <= parentHeight;

  if (isVisible) return;

  parentNode.scrollTop += childBottom - parentHeight;
}
