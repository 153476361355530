"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const classnames_1 = require("classnames");
const styles = require("./StepOne.less");
const i18n = require("i18n");
const MoneyInput_1 = require("apps/shared/components/MoneyInput/MoneyInput");
const ui_library_1 = require("ui-library");
const BrandsSelect_1 = require("../BrandsSelect/BrandsSelect");
class StepOne extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.handleToggleSelect = showSelect => {
            this.setState({ showSelect });
        };
        this.handleBrandClick = event => {
            const { onBrandSelected } = this.props;
            const id = event.currentTarget.getAttribute('data-brandId');
            this.setState({ showSelect: false });
            onBrandSelected(id);
        };
        this.handleChangeStep = () => {
            const { onChangeStep } = this.props;
            onChangeStep('2');
        };
    }
    render() {
        const { brand, searchValue, brands, hasBrands, selectBrand, amount, onChangeAmount, onInputChange, onBrandSelected } = this.props;
        const { currency } = brand;
        const isValidAmount = Number(amount) <= brand.balance;
        return (React.createElement("div", { className: 'vf-bg-white-color', style: { padding: '2rem' } },
            React.createElement("p", { className: 'vf-gray-text' }, i18n.get('ADV_BRANDS_TRANSFER_DIALOG_DISCLAIMER')),
            React.createElement("div", { className: 'flex vf-bold margin-top-1x flex-justify-space-between margin-bottom-Hx' },
                React.createElement("span", null, i18n.get('ADV_BRANDS_BRAND_TO_TRANSFER'))),
            React.createElement(BrandsSelect_1.default, { brands: brands, searchValue: searchValue, selectedBrand: selectBrand, onBrandChange: onBrandSelected, onInputChange: onInputChange }),
            hasBrands && (React.createElement("div", { className: 'flex vf-bold margin-top-1x flex-justify-space-between margin-bottom-Hx' },
                React.createElement("span", null, i18n.get('ADV_BRANDS_AMOUNT_TO_TRANSFER')),
                React.createElement("span", null,
                    i18n.get('ADV_BRANDS_ADD_CREDIT_AVAILABLE'),
                    ' ',
                    i18n.getMoney({ amount: brand.balance, currency })))),
            hasBrands && (React.createElement(MoneyInput_1.default, { className: classnames_1.default({ [styles.error]: !isValidAmount }), value: amount, onChange: onChangeAmount, currency: currency })),
            React.createElement("div", { className: 'end-xs' },
                React.createElement(ui_library_1.Button, { color: 'primary', className: 'margin-top-3x', onClick: this.handleChangeStep, disabled: !isValidAmount ||
                        !hasBrands ||
                        !selectBrand ||
                        Number(amount) <= 0 }, i18n.get('CONTINUE')))));
    }
}
exports.default = StepOne;
