"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const React = require("react");
const classnames_1 = require("classnames");
const ui_library_1 = require("ui-library");
const i18n = require("i18n");
const images_1 = require("assets/images");
const styles = require("./ParticipationModalLite.less");
const handleKeyPressSpace_1 = require("utils/handleKeyPressSpace");
const components_1 = require("apps/shared/components");
const components_2 = require("modules/shared/components");
const SocialNetworkCard_1 = require("../AvailableAccountsLite/components/SocialNetworkCard");
const MAX_IMAGE_STORY = 1;
const STORY = 'Story';
const ParticipationModalLiteUI = ({ onCloseModal, onSave, hanldleInputChange, errors, dataInfo, onVideosChange, videos, onLoadingVideo, onImagesChange, onLoadingImage, images, canUploadImage, canUploadVideo }) => {
    const linkError = lodash_1.get(errors, 'link');
    const story = window.localStorage.getItem(STORY);
    const handleChangeLink = ({ target }) => {
        hanldleInputChange('link', target.value);
    };
    return (React.createElement("div", { className: styles.dialog },
        React.createElement(components_1.DialogHeader, { className: styles.header, onClose: onCloseModal }, i18n.get('INF_LITE_ACCOUNTS_VERIFY_PARTICIPATION_TITLE')),
        React.createElement("div", { className: styles.dialogContainer },
            React.createElement(SocialNetworkCard_1.default, { network: dataInfo, showButton: false, modal: true }),
            !story ? (React.createElement("div", { className: `col-md-12 ${styles.input}` },
                React.createElement("div", { className: styles.labelContainer },
                    React.createElement("span", { className: styles.formLabel }, i18n.get('INF_LITE_ACCOUNTS_POST_URL_PASTE')),
                    React.createElement("b", { className: styles.asterisk }, "*")),
                React.createElement(components_1.FormInput, { hasError: !!linkError, onChange: handleChangeLink, onKeyPress: handleKeyPressSpace_1.default, placeholder: i18n.get('INF_SOCIAL_ACCOUNTS_WRITE_POST') }),
                React.createElement("span", { className: styles.errorContainer },
                    React.createElement("p", { className: styles.errorLabel }, linkError)))) : (React.createElement("div", null,
                React.createElement("div", { className: styles.labelContainer },
                    React.createElement("span", { className: styles.formLabel }, i18n.get('INF_LITE_ACCOUNTS_VIDEO_URL_PASTE')),
                    React.createElement("b", { className: styles.asterisk }, "*")),
                canUploadVideo && (React.createElement(components_2.MediaUploaderCarousel, { mediaType: 'video', maxSizeInMB: 200, onFilesChange: onVideosChange, onLoading: onLoadingVideo, maxFiles: MAX_IMAGE_STORY, urls: videos, className: classnames_1.default('margin-bottom-Hx', styles.media), mediaClassName: styles.thumbnail, lite: true })),
                canUploadImage && (React.createElement(components_2.MediaUploaderCarousel, { mediaType: 'image', maxSizeInMB: 10, onFilesChange: onImagesChange, onLoading: onLoadingImage, maxFiles: MAX_IMAGE_STORY, urls: images, className: classnames_1.default('margin-bottom-Hx', styles.media), mediaClassName: styles.thumbnail, lite: true })))),
            React.createElement("div", { className: styles.disclaimer },
                React.createElement("img", { alt: 'warning', src: images_1.iconAlert, className: 'vf-img-warning' }),
                i18n.get('DISCLAIMER_PUBLIC_ACCOUNT')),
            React.createElement("div", { className: styles.buttonContainer },
                React.createElement(ui_library_1.Button, { color: 'primary', className: styles.button, onClick: onSave }, i18n.get('INF_LITE_ACCOUNTS_VERIFY_BUTTON'))))));
};
exports.default = ParticipationModalLiteUI;
