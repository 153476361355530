import Reflux from 'reflux';
import _ from 'lodash';

const DataStoreActions = Reflux.createActions(['update', 'remove', 'destroy']);

/* ====== Action Hooks ====== */

/**
 * Note: Updates to the dataStore should be done using a specific data-format.
 * That means that every data passed to this method should fullfil the format
 * requirements in order to successfully update the store, otherwise it will
 * throw an error and will not update the store.
 *
 * This format decision was set by the team and we should respect it.
 *
 * This action was desinged to be feeded by our API responses (DataManager).
 *
 * @example Expected format:
 * ```
 * {
 *      "modelName" : "User",
 *      "id" : "54ac7b4e61638112331f246e",
 *      "data" : { ...user data... }
 * }
 * @reference: https://github.com/VoxFeed/VoxFeed_Backend/wiki/VoxFeed-Specs-TechnicalSpec-Api
 */
DataStoreActions.update.shouldEmit = (newData, callback) => {
  newData = newData || {};

  const noModelName = typeof newData.modelName === 'undefined';
  const noID = typeof newData.id === 'undefined';
  const noData = typeof newData.data === 'undefined';
  const noTotal = typeof newData.total === 'undefined';

  if (newData.code) {
    console.warn(newData);
    return false;
  }

  if (noModelName || noData) {
    return false;
  } else if (noID && !_.isArray(newData.data)) {
    return false;
  }

  return true;
};

module.exports = DataStoreActions;
